import { useCallback } from "react";
import { useIntl } from "react-intl";

export const useMediaFileTypeBasedOnMimeType = () => {
  const intl = useIntl();

  const getMediaFileType = useCallback(
    (mimeType?: string) => {
      switch (mimeType?.split("/")?.[0]) {
        case "image":
          return intl.formatMessage({ id: "generic.label.image", defaultMessage: "Image" });
        case "video":
          return intl.formatMessage({ id: "generic.label.video", defaultMessage: "Video" });
        case "application":
          return intl.formatMessage({ id: "generic.label.document", defaultMessage: "Document" });
        default:
          return "";
      }
    },
    [intl],
  );

  return getMediaFileType;
};
