import { FormattedMessage } from "react-intl";

import { Badge } from "@new-black/lyra";

type OrderLineStatusLabelProps = Pick<
  EVA.Core.OrderLineDto,
  "IsShipped" | "IsCompleted" | "IsCancelled" | "IsInvoiced"
>;

const OrderLineStatusLabel = ({
  IsCancelled,
  IsCompleted,
  IsInvoiced,
  IsShipped,
}: OrderLineStatusLabelProps) => {
  if (IsCancelled) {
    return (
      <Badge variant="red">
        <FormattedMessage id="generic.label.cancelled" defaultMessage="Cancelled" />
      </Badge>
    );
  }

  if (IsCompleted) {
    return (
      <Badge variant="green">
        <FormattedMessage id="generic.label.completed" defaultMessage="Completed" />
      </Badge>
    );
  }

  if (IsInvoiced) {
    return (
      <Badge variant="gray">
        <FormattedMessage id="generic.label.invoiced" defaultMessage="Invoiced" />
      </Badge>
    );
  }

  if (IsShipped) {
    return (
      <Badge variant="gray">
        <FormattedMessage id="generic.label.shipped" defaultMessage="Shipped" />
      </Badge>
    );
  }

  return (
    <Badge variant="blue">
      <FormattedMessage id="generic.label.open" defaultMessage="Open" />
    </Badge>
  );
};

export default OrderLineStatusLabel;
