import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

const EditActionButton = forwardRef<HTMLButtonElement, IActionButton & { small?: boolean }>(
  (
    {
      className,
      isDisabled,
      isLoading,
      onPress,
      small,
      tooltip,
    }: IActionButton & { small?: boolean },
    ref,
  ) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        className={className}
        onPress={onPress}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.edit", defaultMessage: "Edit" })
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={small ? "icon-xs" : "icon"}
      >
        <SvgIcon name="edit" />
      </Button>
    );
  },
);

EditActionButton.displayName = "EditActionButton";

export default EditActionButton;
