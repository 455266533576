import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const NumberIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Number</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
      <filter id="filter-2">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
    </defs>
    <g id="Icon-/-Number" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g filter="url(#filter-1)" id="notes_black_24dp">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        </g>
      </g>
      <g filter="url(#filter-2)" id="numeric">
        <g transform="translate(2.000000, 7.000000)">
          <path
            // eslint-disable-next-line max-len
            d="M2,10 L2,2 L0,2 L0,0 L4,0 L4,10 L2,10 M20,8 C20,9.11 19.1,10 18,10 L14,10 L14,8 L18,8 L18,6 L16,6 L16,4 L18,4 L18,2 L14,2 L14,0 L18,0 C19.1045695,0 20,0.8954305 20,2 L20,3.5 C20,4.32842712 19.3284271,5 18.5,5 C19.3284271,5 20,5.67157288 20,6.5 L20,8 M12,8 L12,10 L6,10 L6,6 C6,4.89 6.9,4 8,4 L10,4 L10,2 L6,2 L6,0 L10,0 C11.1045695,0 12,0.8954305 12,2 L12,4 C12,5.11 11.1,6 10,6 L8,6 L8,8 L12,8 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
