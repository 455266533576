import { IntlShape } from "react-intl";

import { EOrderType } from "features/discount-edit/edit-discount.types";

export const getOrderTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<EOrderType, string> = {
    [EOrderType.Delivery]: intl.formatMessage({
      id: "edit-discount.condition.order-type.order-types.1",
      defaultMessage: "Delivery",
    }),
    [EOrderType.CarryOut]: intl.formatMessage({
      id: "edit-discount.condition.order-type.order-types.0",
      defaultMessage: "Carry out",
    }),
    [EOrderType.Reservation]: intl.formatMessage({
      id: "edit-discount.condition.order-type.order-types.2",
      defaultMessage: "Reservation",
    }),
  };

  return (status: EOrderType) => {
    return translationMap[status];
  };
};
