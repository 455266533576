import { FormattedMessage, useIntl } from "react-intl";

import { KeyValueRow } from "@new-black/lyra";

import Grid from "components/suite-ui/grid";
import Text from "components/suite-ui/text";

export interface IProductIdentityInformationProps {
  productId?: number;
  backendId?: string;
  customId?: string;
  barcode?: string;
  variant?: "default" | "lyra";
}

const ProductIdentityInformation = ({
  backendId,
  barcode,
  customId,
  productId,
  variant,
}: IProductIdentityInformationProps) => {
  const intl = useIntl();

  if (variant === "lyra") {
    return (
      <>
        <KeyValueRow
          label={intl.formatMessage({ id: "generic.label.eva-id", defaultMessage: "EVA ID" })}
        >
          {productId}
        </KeyValueRow>
        <KeyValueRow
          label={intl.formatMessage({
            id: "generic.label.backend-id",
            defaultMessage: "Backend ID",
          })}
        >
          {backendId}
        </KeyValueRow>
        <KeyValueRow
          label={intl.formatMessage({ id: "generic.label.custom-id", defaultMessage: "Custom ID" })}
        >
          {customId}
        </KeyValueRow>
        <KeyValueRow
          label={intl.formatMessage({ id: "generic.label.barcode", defaultMessage: "Barcode" })}
        >
          {barcode}
        </KeyValueRow>
      </>
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Text variant="body2">
          <FormattedMessage id="generic.label.eva-id" defaultMessage="EVA ID" />
        </Text>
        <Text variant="body1">{productId ?? "-"}</Text>
      </Grid>
      <Grid item xs={6}>
        <Text variant="body2">
          <FormattedMessage id="generic.label.backend-id" defaultMessage="Backend ID" />
        </Text>
        <Text variant="body1">{backendId ?? "-"}</Text>
      </Grid>
      <Grid item xs={6}>
        <Text variant="body2">
          <FormattedMessage id="generic.label.custom-id" defaultMessage="Custom ID" />
        </Text>
        <Text variant="body1">{customId ?? "-"}</Text>
      </Grid>
      <Grid item xs={6}>
        <Text variant="body2">
          <FormattedMessage id="generic.label.barcode" defaultMessage="Barcode" />
        </Text>
        <Text variant="body1">{barcode ?? "-"}</Text>
      </Grid>
    </Grid>
  );
};

export default ProductIdentityInformation;
