import { ReactNode } from "react";

import { Dialog, DialogProps, GridSize } from "@material-ui/core";
import classNames from "classnames";

import Grid from "../grid";

export interface ISplitDialogProps
  extends Omit<DialogProps, "fullWidth" | "children" | "className"> {
  /** Content displayed in the left panel */
  leftPaneContent: ReactNode;
  leftPaneProportions?: boolean | GridSize;
  /** Content displayed in the right panel */
  rightPaneContent: ReactNode;
  rightPaneProportions?: boolean | GridSize;
  className?: {
    containerDialog?: string;
    leftPane?: string;
    rightPane?: string;
  };
}

const SplitDialog = ({
  className,
  leftPaneContent,
  leftPaneProportions,
  rightPaneContent,
  rightPaneProportions,
  ...props
}: ISplitDialogProps) => (
  <Dialog
    {...props}
    className={classNames(
      "[&_.MuiDialog-paper]:bg-transparent [&_.MuiDialog-paper]:[box-shadow:none]",
      className?.containerDialog,
    )}
    fullWidth
  >
    <Grid container alignItems="stretch" wrap="nowrap">
      <Grid
        item
        xs={leftPaneProportions ?? 8}
        className={classNames(
          "mr-5 rounded bg-[color:var(--eva-color-white,#fff)]",
          className?.leftPane,
        )}
      >
        {leftPaneContent}
      </Grid>
      <Grid
        item
        xs={rightPaneProportions ?? 4}
        className={classNames(
          "rounded bg-[color:var(--eva-color-white,#fff)]",
          className?.rightPane,
        )}
      >
        {rightPaneContent}
      </Grid>
    </Grid>
  </Dialog>
);

export default SplitDialog;
