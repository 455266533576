import { omit } from "lodash";

import { EvaFilterModel } from "components/suite-composite/product-filter/types";
import { TProductSet } from "types/product-sets";

export const mapEVAFilterModelToProductSetFilters = (
  value?: EvaFilterModel,
): TProductSet["Filters"] =>
  Object.entries(value ?? {}).reduce(
    (accumulator, [key, value]) => [
      ...accumulator,
      {
        PropertyName: key,
        ...value,
      },
    ],
    [] as TProductSet["Filters"],
  );

export const mapProductSetsToEVAFilterModel = (
  value?: TProductSet[],
): TMappedProductSetsToEVAFilterModel[] =>
  (value ?? [])?.map((productSet) => ({
    ...productSet,
    Filters: productSet.Filters.reduce(
      (accumulator, current) => ({
        ...accumulator,
        [current.PropertyName]: omit(current, "PropertyName"),
      }),
      {} as EvaFilterModel,
    ),
  }));

export type TMappedProductSetsToEVAFilterModel = Omit<TProductSet, "Filters"> & {
  Filters: EvaFilterModel;
};
