import { createContext, useCallback, useContext, useMemo, useState } from "react";

import { EvaFilterModel, IProductFilterModalValues, IProductFilterProps } from "./types";

export interface IProductFilterContextState
  extends Omit<
    IProductFilterProps,
    "children" | "updateProductFilter" | "updateProductFilterAsync"
  > {
  openProductFilterModal: boolean;
  setOpenProductFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
  openProductOverviewModal: boolean;
  setOpenProductOverviewModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentProductFilterModalValues?: IProductFilterModalValues;
  setCurrentProductFilterModalValues: React.Dispatch<
    React.SetStateAction<IProductFilterModalValues | undefined>
  >;
  updateProductFilter: (filters?: EvaFilterModel) => Promise<any>;
  isUpdateProductFilterLoading: boolean;
}

const ProductFilterContext = createContext<IProductFilterContextState | undefined>(undefined);

const ProductFilterProvider = ({
  children,
  disableAdd,
  disableDelete,
  disableEdit,
  error,
  isCreateModalInitiallyOpen,
  maximumDisplayedValuesInList,
  placeholderImageUrl,
  productFilter,
  updateProductFilter,
  updateProductFilterAsync,
}: IProductFilterProps) => {
  const [openProductFilterModal, setOpenProductFilterModal] = useState(
    isCreateModalInitiallyOpen ?? false,
  );
  const [openProductOverviewModal, setOpenProductOverviewModal] = useState(false);

  const [currentProductFilterModalValues, setCurrentProductFilterModalValues] = useState<
    IProductFilterModalValues | undefined
  >(undefined);

  const [isUpdateProductFilterLoading, setIsUpdateProductFilterLoading] = useState(false);
  const updateProductFilterCallback = useCallback(
    async (filters?: EvaFilterModel) => {
      if (updateProductFilter) {
        updateProductFilter(filters);
      } else if (updateProductFilterAsync) {
        setIsUpdateProductFilterLoading(true);
        await updateProductFilterAsync(filters);
        setIsUpdateProductFilterLoading(false);
      }
    },
    [updateProductFilter, updateProductFilterAsync],
  );

  const value = useMemo<IProductFilterContextState>(
    () => ({
      openProductFilterModal,
      setOpenProductFilterModal,
      openProductOverviewModal,
      setOpenProductOverviewModal,
      currentProductFilterModalValues,
      setCurrentProductFilterModalValues,
      productFilter,
      placeholderImageUrl,
      disableAdd,
      disableEdit,
      disableDelete,
      maximumDisplayedValuesInList,
      error,
      updateProductFilter: updateProductFilterCallback,
      isUpdateProductFilterLoading: isUpdateProductFilterLoading,
    }),
    [
      currentProductFilterModalValues,
      disableAdd,
      disableDelete,
      disableEdit,
      error,
      isUpdateProductFilterLoading,
      maximumDisplayedValuesInList,
      openProductFilterModal,
      openProductOverviewModal,
      placeholderImageUrl,
      productFilter,
      updateProductFilterCallback,
    ],
  );

  return (
    <ProductFilterContext.Provider value={value}>
      {typeof children === "function" ? children(value) : children}
    </ProductFilterContext.Provider>
  );
};

const useProductFilterContext = () => {
  const context = useContext(ProductFilterContext);
  if (context === undefined) {
    throw new Error("useProductFilterContext must be used within a ProductFilterProvider");
  }
  return context;
};
export { ProductFilterProvider, useProductFilterContext };
