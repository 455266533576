export enum OrganizationUnitType {
  None = 0,
  Shop = 1,
  WebShop = 2,
  Container = 4,
  Pickup = 8,
  Warehouse = 16,
  Country = 36,
  Franchise = 64,
  EVA = 128,
  TestOrganizationUnit = 256,
  DisableLogin = 512,
  ExternalSupplier = 1024,
  Consignment = 3072,
  B2b = 4096,
  Region = 8196,
  ReturnsPortal = 16384,
  Restitution = 32768,
  RepairCenter = 65536,
  Mobile = 131072,
  ConcessionStore = 262144,
}

export enum OrganizationUnitTypeName {
  None = "None",
  Shop = "Shop",
  WebShop = "WebShop",
  Container = "Container",
  Pickup = "Pickup",
  Warehouse = "Warehouse",
  Country = "Country",
  Franchise = "Franchise",
  EVA = "EVA",
  TestOrganizationUnit = "TestOrganizationUnit",
  DisableLogin = "DisableLogin",
  Supplier = "Supplier",
  ExternalSupplier = "ExternalSupplier",
  Consignment = "Consignment",
  B2b = "B2b",
  Region = "Region",
  ReturnsPortal = "ReturnsPortal",
  Restitution = "Restitution",
}
