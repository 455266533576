import storage from "@springtree/eva-sdk-react-recoil/lib/helpers/storage";
import { QueryClient } from "@tanstack/react-query";
import { z } from "zod";

import { getCurrentUserLoaderQuery, getCurrentUserQueryKeys } from "models/users";

const ouIDInStorageSchema = z
  .string()
  .nullable()
  .transform((arg) => (arg ? parseInt(arg, 10) : undefined));

const positiveIntegerSchema = z.number().int().positive();

export const getActAsOU = async (queryClient: QueryClient) => {
  const ouID = storage.getItem("evaRequestedOrganizationUnitID");
  const currentUserRequest = getCurrentUserLoaderQuery(
    queryClient,
    {},
    getCurrentUserQueryKeys.withRequest({}),
  );

  const parsedOuID =
    ouIDInStorageSchema.parse(ouID) ??
    positiveIntegerSchema.parse((await currentUserRequest()).value?.User?.CurrentOrganizationID);

  return parsedOuID;
};
