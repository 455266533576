import { useIntl } from "react-intl";

import { Button, ButtonGroup, Menu, MenuTrigger, SvgIcon } from "@new-black/lyra";

import RevisionCommentPopoverButton from "../revision-comment-popover-button";

import { IScriptHistoryItem } from "./script-code-editor.types";
import { ScriptEditorProps } from "./script-editor";

export interface IScriptHistoryButtons
  extends Pick<
    ScriptEditorProps,
    | "hideCurrentRevisionComment"
    | "onLoadMoreHistory"
    | "scriptHistory"
    | "currentRevisionComment"
    | "isHistoryLoading"
  > {
  selectedHistoryItem?: EVA.Core.ListScriptsItem;
  onSelectHistoryItem?: (newValue?: EVA.Core.ListScriptsItem) => void;
}

const ScriptHistoryButtons = ({
  currentRevisionComment,
  hideCurrentRevisionComment,
  isHistoryLoading,
  onLoadMoreHistory,
  onSelectHistoryItem,
  scriptHistory,
  selectedHistoryItem,
}: IScriptHistoryButtons) => {
  return (
    <ButtonGroup>
      {scriptHistory && onSelectHistoryItem ? (
        <ScriptHistoryPicker
          selectedItem={selectedHistoryItem}
          onSelect={onSelectHistoryItem}
          items={scriptHistory}
          onLoadMore={onLoadMoreHistory}
          isLoading={isHistoryLoading}
        />
      ) : null}
      {hideCurrentRevisionComment ? null : (
        <RevisionCommentPopoverButton
          revisionComment={
            selectedHistoryItem ? selectedHistoryItem?.RevisionComment : currentRevisionComment
          }
          popoverPlacement="bottom end"
        />
      )}
    </ButtonGroup>
  );
};

export default ScriptHistoryButtons;

const ScriptHistoryPicker = ({
  isLoading,
  items,
  onLoadMore,
  onSelect,
  selectedItem,
}: {
  items: IScriptHistoryItem[];
  onSelect: (item?: IScriptHistoryItem) => void;
  selectedItem?: IScriptHistoryItem;
  onLoadMore?: () => void;
  isLoading?: boolean;
}) => {
  const intl = useIntl();

  return (
    <MenuTrigger>
      <Button
        variant="icon"
        tooltip={intl.formatMessage({ id: "generic.label.history", defaultMessage: "History" })}
      >
        <SvgIcon name="history" />
      </Button>

      <Menu
        items={items}
        getItemId={(item) => item.Revision ?? ""}
        onAction={(selectedRevision) => {
          onSelect(items.find((item) => item.Revision === selectedRevision));
        }}
        placement="bottom end"
        aria-label="Select history item"
        selectRenderElements={(currentItem) => {
          const isFirstItem =
            items.findIndex((item) => item.Revision === currentItem.Revision) === 0;
          const showCheckmark = selectedItem
            ? currentItem.Revision === selectedItem?.Revision
            : isFirstItem;

          return {
            label: isFirstItem
              ? intl.formatMessage({
                  id: "generic.label.current-version",
                  defaultMessage: "Current version",
                })
              : currentItem.Date
              ? intl.formatDate(currentItem.Date, { dateStyle: "short", timeStyle: "short" })
              : "",
            description: currentItem.UserFullName,
            iconStart: showCheckmark ? "checkmark" : undefined,
          };
        }}
        onLoadMore={onLoadMore}
        isLoading={isLoading}
      />
    </MenuTrigger>
  );
};
