import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const productRequirementsForOrderLineServiceState = builders.buildServiceFamilyState({
  key: "productRequirementsForOrderLine",
  service: Core.GetProductRequirementValuesForOrderLine,
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const productRequirementsForOrderLineValuesSelector = selectorFamily<
  { [key: number]: any } | undefined,
  { orderID: number | undefined; orderLineID: number | undefined }
>({
  key: "productRequirementsForOrderLine:Values",
  get:
    (params) =>
    ({ get }) => {
      const response = get(productRequirementsForOrderLineServiceState.response(params));
      return response?.Requirements;
    },
});
