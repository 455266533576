import { useEffect, useMemo } from "react";

import { TableColumnDef } from "@new-black/lyra";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { LyraProductsOverviewTable } from "./products-overview-table";
import { productsListSelector, productsListTotalSelector, productsState } from "./state";

const DEFAULT_INCLUDED_FIELDS = [
  "product_id",
  "custom_id",
  "backend_id",
  "display_value",
  "primary_image",
];

interface ILyraProductsOverview {
  filter?: { [key: string]: EVA.Core.FilterModel };
  includedFields?: string[];
  tableColumns?: TableColumnDef<any>[];
  placeholderImageUrl?: string;
  query?: string;
  start: number;
  setStart: (newValue: number) => void;
  limit: number;
  setLimit: (newValue: number) => void;
}

export const LyraProductsOverview = ({
  filter,
  includedFields = DEFAULT_INCLUDED_FIELDS,
  limit,
  placeholderImageUrl,
  query,
  setLimit,
  setStart,
  start,
  tableColumns,
}: ILyraProductsOverview) => {
  // TODO: replace Recoil with react-query
  const productsList = hooks.useGetState(productsListSelector);
  const productsListTotal = hooks.useGetState(productsListTotalSelector);

  const newRequest = useMemo<EVA.Core.SearchProducts | undefined>(
    () => ({
      Filters: filter,
      Query: query,
      PageSize: limit,
      Page: start / limit + 1,
      IncludedFields: includedFields,
    }),
    [filter, includedFields, limit, query, start],
  );

  const setProductsListRequest = useSetRecoilState(productsState.request);

  useEffect(() => {
    setProductsListRequest(newRequest);
  }, [newRequest, setProductsListRequest]);

  return (
    <LyraProductsOverviewTable
      data={productsList ?? []}
      limit={limit}
      setLimit={setLimit}
      start={start}
      setStart={setStart}
      total={productsListTotal ?? 0}
      columns={tableColumns}
      placeholderImageUrl={placeholderImageUrl}
    />
  );
};
