import { useMemo } from "react";

import { QueryClient } from "@tanstack/react-query";

import { ICustomField } from "types/custom-field";

import {
  getCustomFieldLabelEntityTranslation,
  useCustomFieldLabelEntityTranslation,
} from "./use-custom-field-label-entity-translation";

interface IUseCustomFieldLabelProps {
  label?: string;
  disableLabel?: boolean;
  customField?: ICustomField;
}

export const useCustomFieldLabel = ({
  customField,
  disableLabel,
  label,
}: IUseCustomFieldLabelProps) => {
  const entityTranslationLabel = useCustomFieldLabelEntityTranslation(customField);

  const customFieldLabel = useMemo(() => {
    if (disableLabel) {
      return undefined;
    }

    return label ?? entityTranslationLabel;
  }, [disableLabel, entityTranslationLabel, label]);

  return customFieldLabel;
};

export const getCustomFieldLabel =
  (queryClient: QueryClient) =>
  async ({ customField, disableLabel, label }: IUseCustomFieldLabelProps) => {
    const entityTranslationLabel = await getCustomFieldLabelEntityTranslation(queryClient)(
      customField,
    );

    return disableLabel ? undefined : label ?? entityTranslationLabel;
  };
