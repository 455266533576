import { useEffect } from "react";

import { useRecoilState } from "recoil";

import {
  DEFAULT_WORKSPACE_ID,
  IWorkspaceSelectorItem,
} from "components/shared/menu/workspace/workspace-selector";

import { shouldInitializeWorkspace } from "../workspaces.state";
import { EKeyName } from "../workspaces.types";

import useManageWorkspace from "./use-manage-workspace";

const useInitializeWorkspace = (
  applyWorkspaceSettings: (workspaceSettings: string) => void,
  setSelectedWorkspaceId: (id: IWorkspaceSelectorItem["ID"]) => void,
  getNextWorkspace: (
    keyName: string,
    workspaces: IWorkspaceSelectorItem[],
    includeActiveWorkspaces?: boolean,
  ) => IWorkspaceSelectorItem,
  workspaces: IWorkspaceSelectorItem[],
  workspacesLoading: boolean,
  keyName?: EKeyName,
) => {
  const [shouldInitWorkspace, setShouldInitWorkspace] = useRecoilState(
    shouldInitializeWorkspace(keyName),
  );

  const { setActiveWorkspace } = useManageWorkspace();

  // Set selected workspace to the isActive or Default one on init
  useEffect(() => {
    if (workspaces?.length && keyName && shouldInitWorkspace && !workspacesLoading) {
      const activeWorkspace = getNextWorkspace(keyName, workspaces, true);

      setSelectedWorkspaceId(activeWorkspace.ID);
      setShouldInitWorkspace(false);
      applyWorkspaceSettings(activeWorkspace.Settings);

      // Edge case where there is a workspace returned by `GetWorkspaces` request, but it is not active.
      // This could happen if, for example, you create a workspace via API / Dora and not through Admin Suite
      if (activeWorkspace.ID !== DEFAULT_WORKSPACE_ID && !activeWorkspace.IsActive) {
        setActiveWorkspace(true)({ ID: activeWorkspace.ID });
      }
    }
  }, [
    applyWorkspaceSettings,
    getNextWorkspace,
    keyName,
    setActiveWorkspace,
    setSelectedWorkspaceId,
    setShouldInitWorkspace,
    shouldInitWorkspace,
    workspaces,
    workspacesLoading,
  ]);
};

export default useInitializeWorkspace;
