import { cva, VariantProps } from "class-variance-authority";
import classNames from "classnames";

const styledGrid = cva([""], {
  variants: {
    alignContent: {
      stretch: "content-stretch",
      center: "content-center",
      "flex-start": "content-start",
      "flex-end": "content-end",
      "space-between": "content-between",
      "space-around": "content-around",
    },
    alignItems: {
      stretch: "items-stretch",
      center: "items-center",
      "flex-start": "items-start",
      "flex-end": "items-end",
      baseline: "items-baseline",
    },
    container: {
      true: "flex flex-wrap w-full",
      false: "flex",
    },
    direction: {
      row: "flex-row",
      "row-reverse": "flex-row-reverse",
      column: "flex-col",
      "column-reverse": "flex-col-reverse",
    },
    item: {
      true: "item",
    },
    justifyContent: {
      "flex-start": "justify-start",
      center: "justify-center",
      "flex-end": "justify-end",
      "space-between": "justify-between",
      "space-around": "justify-around",
      "space-evenly": "justify-evenly",
    },
    lg: {
      true: "",
      false: "",
      auto: "",
      1: "mui-lg:max-w-[8.3333%] mui-lg:basis-1/12",
      2: "mui-lg:max-w-[16.6667%] mui-lg:basis-2/12",
      3: "mui-lg:max-w-[25%] mui-lg:basis-3/12",
      4: "mui-lg:max-w-[33.3333%] mui-lg:basis-4/12",
      5: "mui-lg:max-w-[41.6667%] mui-lg:basis-5/12",
      6: "mui-lg:max-w-[50%] mui-lg:basis-6/12",
      7: "mui-lg:max-w-[58.3333%] mui-lg:basis-7/12",
      8: "mui-lg:max-w-[66.6667%] mui-lg:basis-8/12",
      9: "mui-lg:max-w-[75%] mui-lg:basis-9/12",
      10: "mui-lg:max-w-[83.3333%] mui-lg:basis-10/12",
      11: "mui-lg:max-w-[91.6667%] mui-lg:basis-11/12",
      12: "mui-lg:max-w-[100%] mui-lg:basis-full",
    },
    md: {
      true: "",
      false: "",
      auto: "",
      1: "mui-md:max-w-[8.3333%] mui-md:basis-1/12",
      2: "mui-md:max-w-[16.6667%] mui-md:basis-2/12",
      3: "mui-md:max-w-[25%] mui-md:basis-3/12",
      4: "mui-md:max-w-[33.3333%] mui-md:basis-4/12",
      5: "mui-md:max-w-[41.6667%] mui-md:basis-5/12",
      6: "mui-md:max-w-[50%] mui-md:basis-6/12",
      7: "mui-md:max-w-[58.3333%] mui-md:basis-7/12",
      8: "mui-md:max-w-[66.6667%] mui-md:basis-8/12",
      9: "mui-md:max-w-[75%] mui-md:basis-9/12",
      10: "mui-md:max-w-[83.3333%] mui-md:basis-10/12",
      11: "mui-md:max-w-[91.6667%] mui-md:basis-11/12",
      12: "mui-md:max-w-[100%] mui-md:basis-full",
    },
    sm: {
      true: "",
      false: "",
      auto: "",
      1: "mui-sm:max-w-[8.3333%] mui-sm:basis-1/12",
      2: "mui-sm:max-w-[16.6667%] mui-sm:basis-2/12",
      3: "mui-sm:max-w-[25%] mui-sm:basis-3/12",
      4: "mui-sm:max-w-[33.3333%] mui-sm:basis-4/12",
      5: "mui-sm:max-w-[41.6667%] mui-sm:basis-5/12",
      6: "mui-sm:max-w-[50%] mui-sm:basis-6/12",
      7: "mui-sm:max-w-[58.3333%] mui-sm:basis-7/12",
      8: "mui-sm:max-w-[66.6667%] mui-sm:basis-8/12",
      9: "mui-sm:max-w-[75%] mui-sm:basis-9/12",
      10: "mui-sm:max-w-[83.3333%] mui-sm:basis-10/12",
      11: "mui-sm:max-w-[91.6667%] mui-sm:basis-11/12",
      12: "mui-sm:max-w-[100%] mui-sm:basis-full",
    },
    spacing: {
      0: "[&_.item]:p-0",
      1: "[&_.item]:p-1 -m-1 !w-[calc(100%+0.5rem)]",
      2: "[&_.item]:p-2 -m-2 !w-[calc(100%+1rem)]",
      3: "[&_.item]:p-3 -m-3 !w-[calc(100%+1.5rem)]",
      4: "[&_.item]:p-4 -m-4 !w-[calc(100%+2rem)]",
      5: "[&_.item]:p-5 -m-5 !w-[calc(100%+2.5rem)]",
      6: "[&_.item]:p-6 -m-6 !w-[calc(100%+3rem)]",
      7: "[&_.item]:p-7 -m-7 !w-[calc(100%+3.5rem)]",
      8: "[&_.item]:p-8 -m-8 !w-[calc(100%+4rem)]",
      9: "[&_.item]:p-9 -m-9 !w-[calc(100%+4.5rem)]",
      10: "[&_.item]:p-10 -m-10 !w-[calc(100%+5rem)]",
    },
    wrap: {
      wrap: "flex-wrap",
      nowrap: "flex-nowrap",
      "wrap-reverse": "flex-wrap-reverse",
    },
    xl: {
      true: "",
      false: "",
      auto: "",
      1: "xl:max-w-[8.3333%] xl:basis-1/12",
      2: "xl:max-w-[16.6667%] xl:basis-2/12",
      3: "xl:max-w-[25%] xl:basis-3/12",
      4: "xl:max-w-[33.3333%] xl:basis-4/12",
      5: "xl:max-w-[41.6667%] xl:basis-5/12",
      6: "xl:max-w-[50%] xl:basis-6/12",
      7: "xl:max-w-[58.3333%] xl:basis-7/12",
      8: "xl:max-w-[66.6667%] xl:basis-8/12",
      9: "xl:max-w-[75%] xl:basis-9/12",
      10: "xl:max-w-[83.3333%] xl:basis-10/12",
      11: "xl:max-w-[91.6667%] xl:basis-11/12",
      12: "xl:max-w-[100%] xl:basis-full",
    },
    xs: {
      true: "basis-0 flex-grow",
      false: "",
      auto: "",
      1: "max-w-[8.3333%] basis-1/12",
      2: "max-w-[16.6667%] basis-2/12",
      3: "max-w-[25%] basis-3/12",
      4: "max-w-[33.3333%] basis-4/12",
      5: "max-w-[41.6667%] basis-5/12",
      6: "max-w-[50%] basis-6/12",
      7: "max-w-[58.3333%] basis-7/12",
      8: "max-w-[66.6667%] basis-8/12",
      9: "max-w-[75%] basis-9/12",
      10: "max-w-[83.3333%] basis-10/12",
      11: "max-w-[91.6667%] basis-11/12",
      12: "max-w-[100%] basis-full",
    },
    zeroMinWidth: {
      true: "min-w-0",
    },
  },
  compoundVariants: [
    {
      container: false,
      item: true,
      class: "[&_.item]:p-0",
    },
    {
      container: true,
      wrap: "nowrap",
      class: "flex-nowrap",
    },
  ],
  defaultVariants: {},
});

const Grid = (props: GridProps) => {
  const {
    alignContent,
    alignItems,
    className,
    container,
    direction,
    innerRef,
    item,
    justifyContent,
    lg,
    md,
    sm,
    spacing,
    wrap,
    xl,
    xs,
    zeroMinWidth,
    ...restProps
  } = props;

  return (
    <div
      {...restProps}
      className={classNames(
        styledGrid({
          alignContent: alignContent,
          alignItems: alignItems,
          container: container,
          direction: direction,
          item: !!item,
          justifyContent: justifyContent,
          lg: lg,
          md: md,
          sm: sm,
          spacing: spacing,
          wrap: wrap,
          xl: xl,
          xs: xs,
          zeroMinWidth: zeroMinWidth,
        }),
        className,
      )}
      ref={innerRef}
    />
  );
};

export default Grid;

export interface IGrid extends VariantProps<typeof styledGrid> {
  innerRef?: React.LegacyRef<HTMLDivElement>;
}

export type GridProps = IGrid & React.ComponentPropsWithRef<"div">;
