import { useCallback, useMemo } from "react";

export const useUncountablePaginationWithoutPreviousTokensHelpers = ({
  nextResultToken,
  previousTokens,
  setNextPageToken,
  setPreviousTokens,
  tokenFromCurrentRequest,
}: {
  tokenFromCurrentRequest?: string;
  nextResultToken?: string;
  setNextPageToken: (newValue?: string) => void;
  setPreviousTokens: (newValue: Record<string, string | undefined>) => void;
  previousTokens: Record<string, string | undefined>;
}) => {
  const showNext = useMemo(() => !!nextResultToken, [nextResultToken]);

  const onNext = useCallback(() => {
    setNextPageToken(nextResultToken);

    if (nextResultToken) {
      setPreviousTokens({
        ...previousTokens,
        [nextResultToken]: tokenFromCurrentRequest,
      });
    }
  }, [
    nextResultToken,
    previousTokens,
    setNextPageToken,
    setPreviousTokens,
    tokenFromCurrentRequest,
  ]);

  // show previous page button when the current request contains a token, meaning that we can be sure it's not the first page
  // of the table, because that request is done with an undefined token
  const showPrevious = useMemo(() => !!tokenFromCurrentRequest, [tokenFromCurrentRequest]);

  const onPrevious = useCallback(() => {
    const previousResultToken = tokenFromCurrentRequest
      ? previousTokens[tokenFromCurrentRequest]
      : undefined;
    setNextPageToken(previousResultToken);
  }, [previousTokens, setNextPageToken, tokenFromCurrentRequest]);

  return { onNext, showPrevious, onPrevious, showNext };
};
