import { ReactNode } from "react";

import { SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import Text, { TextProps } from "components/suite-ui/text";
import ToolTip from "components/ui/tooltip";

export interface KeyValueRowProps {
  label: string;
  value?: ReactNode;
  textValueClass?: string;
  keyClass?: string;
  valueClass?: string;
  containerClass?: string;
  overflowEllipsis?: boolean;
  textValueColor?: TextProps["color"];
  keyTextColor?: TextProps["color"];
  keyTextClass?: string;
  isHiddenValue?: boolean;
  hiddenValueTooltip?: string;
}

export function KeyValueRow({
  containerClass,
  hiddenValueTooltip,
  isHiddenValue,
  keyClass,
  keyTextClass,
  keyTextColor = "textSecondary",
  label,
  overflowEllipsis,
  textValueClass,
  textValueColor,
  value,
  valueClass,
}: KeyValueRowProps) {
  return (
    <div className={classNames("flex flex-col gap-x-5 gap-y-1 sm:flex-row", containerClass)}>
      <div className={classNames("w-full md:w-1/2 lg:w-1/3", keyClass)}>
        <Text variant="body1" color={keyTextColor} className={keyTextClass}>
          {label}
        </Text>
      </div>
      <div className={classNames("w-full overflow-hidden md:w-1/2 lg:w-2/3", valueClass)}>
        {isHiddenValue ? (
          <HiddenValue tooltip={hiddenValueTooltip} />
        ) : (
          <>
            {!value || typeof value === "string" || typeof value === "number" ? (
              <Text
                variant="body1"
                color={textValueColor}
                className={classNames(
                  typeof value === "string" &&
                    value.split(splitWordsOnRegex).some((val) => val.length > 50) &&
                    "break-words",

                  overflowEllipsis && "overflow-hidden overflow-ellipsis whitespace-nowrap",
                  textValueClass,
                )}
              >
                {typeof value === "number"
                  ? value.toString()
                  : (value as string | undefined)?.length
                  ? value
                  : "-"}
              </Text>
            ) : (
              value
            )}
          </>
        )}
      </div>
    </div>
  );
}

const splitWordsOnRegex = /[,.!?:; ]/;

const HiddenValue = ({ tooltip }: { tooltip?: string }) => (
  <div className="flex items-center">
    <Text className="leading-none">
      &bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;
    </Text>
    &nbsp;
    {tooltip ? (
      <ToolTip title={tooltip}>
        <div className="flex h-4 w-4 items-center justify-center">
          <SvgIcon name="lock" />
        </div>
      </ToolTip>
    ) : (
      <SvgIcon name="lock" />
    )}
  </div>
);
