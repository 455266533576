import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const getDisputesReasonsForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:GetInvoiceDisputeReasons",
  allowEmptyRequest: false,
  service: Core.GetInvoiceDisputeReasons,
});

export default getDisputesReasonsForInvoiceServiceState;
