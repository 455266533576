import { Fragment } from "react";

export function QueryableLabel({ label, query }: { label: string; query: string }) {
  // Make sure to escape any special characters in the query, before we use it in a regex
  const escapedQuery = query.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const textArray = label.split(new RegExp(escapedQuery, "i"));

  return (
    <span>
      {textArray.map((item, index) => {
        const lengthUntilCurrentQuery =
          textArray.slice(0, index).join("").length + (index - 1) * query.length;

        const start = lengthUntilCurrentQuery;
        const end = lengthUntilCurrentQuery + query.length;

        // make sure we preserve the case of the query as it was included in the label
        const queryMatch = label.substring(start, end);

        return (
          <Fragment key={index}>
            {queryMatch ? <span className="font-bold">{queryMatch}</span> : null}
            {item}
          </Fragment>
        );
      })}
    </span>
  );
}
