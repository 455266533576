import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const discountCouponHandlersServiceState = builders.buildServiceState({
  service: Core.ListDiscountCouponHandlers,
  key: "Core:ListDiscountCouponHandlers",
  allowEmptyRequest: true,
  requireEmployee: true,
});

export const discountCouponHandlersResultSelector = selector({
  key: "Core:ListDiscountCouponHandlers",
  get: ({ get }) => get(discountCouponHandlersServiceState.response)?.Handlers,
});
