import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getDeviceTypesQuery } from "models/devices";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps as ILyraSearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";
import { SearchListFieldGenerator } from "util/search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/search-list-field-generator/search-list-field-generator.types";

interface DeviceTypeLyraSearchListFieldItem {
  ID: number;
  Name: string;
}

type DeviceTypeSearchListFieldItem = Partial<DeviceTypeLyraSearchListFieldItem>;

const useDeviceTypeById = <
  DataType extends DeviceTypeLyraSearchListFieldItem | DeviceTypeSearchListFieldItem,
>(
  id: number | undefined,
  itemsFromResponse: DataType[] | undefined,
) => ({
  data: itemsFromResponse?.find((item) => item.ID === id),
  isLoading: false,
});

function useDeviceTypesById<
  DataType extends DeviceTypeLyraSearchListFieldItem | DeviceTypeSearchListFieldItem,
>(ids: (number | undefined)[] | undefined, itemsFromResponse: DataType[] | undefined) {
  const validIds = useMemo(() => ids?.filter((id): id is number => id !== undefined) ?? [], [ids]);
  return {
    data: itemsFromResponse?.filter((item) => item.ID !== undefined && validIds.includes(item.ID)),
    isLoading: false,
  };
}

export const generateDeviceTypeSearchListField = ({
  frontendFilter,
}: Pick<
  ISearchListFieldGeneratorProps<Core.GetDeviceTypes, DeviceTypeSearchListFieldItem, "ID">,
  "frontendFilter"
>) => {
  const {
    MultiIDSearchListField,
    MultiSearchListField,
    SingleIDSearchListField,
    SingleSearchListField,
  } = SearchListFieldGenerator<Core.GetDeviceTypes, DeviceTypeSearchListFieldItem, "ID">({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.DeviceTypes?.map((device) => ({ ID: device.ID, Name: device.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.device-type",
      defaultMessage: "Device type",
    }),
    useItemByID: useDeviceTypeById<DeviceTypeSearchListFieldItem>,
    useItemsByID: useDeviceTypesById<DeviceTypeSearchListFieldItem>,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getDeviceTypesQuery,
        initialRequest: {},
        cacheTime: ONE_HOUR,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

  return {
    MultiIDSearchListField,
    MultiSearchListField,
    SingleIDSearchListField,
    SingleSearchListField,
  };
};

export const generateDeviceTypeLyraSearchListField = ({
  frontendFilter,
}: Pick<
  ILyraSearchListFieldGeneratorProps<
    Core.GetDeviceTypes,
    DeviceTypeLyraSearchListFieldItem,
    number
  >,
  "frontendFilter"
>) => {
  const {
    MultiIDSearchListField,
    MultiSearchListField,
    SingleIDSearchListField,
    SingleSearchListField,
  } = LyraSearchListFieldGenerator<Core.GetDeviceTypes, DeviceTypeLyraSearchListFieldItem, number>({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) => resp?.DeviceTypes,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.device-type",
      defaultMessage: "Device type",
    }),
    useItemByID: useDeviceTypeById<DeviceTypeLyraSearchListFieldItem>,
    useItemsByID: useDeviceTypesById<DeviceTypeLyraSearchListFieldItem>,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getDeviceTypesQuery,
        initialRequest: {},
        cacheTime: ONE_HOUR,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

  return {
    MultiIDSearchListField,
    MultiSearchListField,
    SingleIDSearchListField,
    SingleSearchListField,
  };
};
