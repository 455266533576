import { useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";
import { isEqual } from "lodash";

import useRecoilDebouncedValue from "hooks/suite-react-hooks/use-recoil-debounced-value";

import { MultiAutocomplete, SpyCallback, SpyFunction } from "./autocomplete-generator.types";

export interface IMultiAutocompleteWrapperReturnValues<Item> {
  Controlled: (inputProps: MultiAutocomplete<Item>["Props"]) => JSX.Element;
  Uncontrolled: (inputProps: MultiAutocomplete<Item>["Uncontrolled"]) => JSX.Element;
  Formik: (inputProps: MultiAutocomplete<Item>["Formik"]) => JSX.Element;
  Recoil: (inputProps: MultiAutocomplete<Item>["Recoil"]) => JSX.Element;
}

/** Wrapper component that based on a basic MultiAutocomplete
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const MultiAutocompleteWrapper = <Item,>(
  Autocomplete: (props: MultiAutocomplete<Item>["Props"]) => JSX.Element,
): IMultiAutocompleteWrapperReturnValues<Item> => {
  const Controlled = (inputProps: MultiAutocomplete<Item>["Props"]) => (
    <Autocomplete {...inputProps} />
  );

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: MultiAutocomplete<Item>["Uncontrolled"]) => {
    const [values, setValues] = useState(defaultValue);
    return <Autocomplete {...inputProps} values={values} onChange={setValues} />;
  };

  const Formik = ({ name, ...inputProps }: MultiAutocomplete<Item>["Formik"]) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[] | undefined>(name);
    return (
      <Autocomplete
        {...inputProps}
        values={field.value}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => setFieldValue(name, newValue)}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: MultiAutocomplete<Item>["Recoil"]) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    return (
      <Controlled
        values={recoilValue}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue)
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};

/** Wrapper component that is based on a basic, ID-based MultiAutocomplete
 * that allows to pass a hook that retrievs an item by ID */
export const MultiAutocompleteIDWrapper = <Item, IDKey extends keyof Item>(
  Autocomplete: (props: MultiAutocomplete<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  idKey: IDKey,
  useItemsByID: (
    ids: Item[IDKey][] | undefined,
    listItems: Item[] | undefined,
  ) => {
    data: Item[] | undefined;
    isLoading?: boolean;
  },
): IMultiAutocompleteWrapperReturnValues<Item[IDKey]> => {
  const Controlled = (inputProps: MultiAutocomplete<Item[IDKey]>["Props"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: items, isLoading } = useItemsByID(inputProps.values, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <Autocomplete
        {...inputProps}
        values={items}
        isLoading={isLoading}
        spyCallback={invokeSpy}
        onChange={(newValue) => inputProps.onChange(newValue?.map((item) => item[idKey]))}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: MultiAutocomplete<Item[IDKey]>["Uncontrolled"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemsIds, setCurrentItemsIds] = useState(defaultValue);

    useEffect(() => setCurrentItemsIds(defaultValue), [defaultValue]);

    const { data: currentItems, isLoading } = useItemsByID(currentItemsIds, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <Autocomplete
        {...inputProps}
        values={currentItems}
        onChange={(newValues) => setCurrentItemsIds(newValues?.map((newValue) => newValue[idKey]))}
        isLoading={isLoading}
        spyCallback={invokeSpy}
      />
    );
  };

  const Formik = ({ name, ...inputProps }: MultiAutocomplete<Item[IDKey]>["Formik"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[IDKey][] | undefined>(name);
    const { data: items, isLoading } = useItemsByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <Autocomplete
        {...inputProps}
        values={items}
        isLoading={isLoading}
        spyCallback={invokeSpy}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) =>
          setFieldValue(
            name,
            newValue?.map((item) => item[idKey]),
          )
        }
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: MultiAutocomplete<Item[IDKey]>["Recoil"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: items, isLoading } = useItemsByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <Autocomplete
        {...inputProps}
        values={items}
        isLoading={isLoading}
        spyCallback={invokeSpy}
        onChange={(newValue) =>
          customOnChange
            ? customOnChange(
                setRecoilValue,
                newValue?.map((item) => item[idKey]),
              )
            : setRecoilValue(newValue?.map((item) => item[idKey]))
        }
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
