import { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Badge, BadgeProps } from "@new-black/lyra";

interface IYesNoStatusLabelProps {
  value: boolean;
  yesLabel?: string | ReactNode;
  noLabel?: string | ReactNode;
  yesColor?: BadgeProps["variant"];
  noColor?: BadgeProps["variant"];
}

export function YesNoStatusLabel({
  noColor = "red",
  noLabel = <FormattedMessage id="generic.label.no" defaultMessage="No" />,
  value,
  yesColor = "green",
  yesLabel = <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />,
}: IYesNoStatusLabelProps) {
  const label = useMemo(() => (value ? yesLabel : noLabel), [noLabel, value, yesLabel]);
  const variant = useMemo(() => (value ? yesColor : noColor), [noColor, value, yesColor]);
  return <Badge variant={variant}>{label}</Badge>;
}
