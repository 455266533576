import { useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete } from "@material-ui/lab";
import { Field, FieldProps } from "formik";

import Chip, { ChipListWrapper } from "components/suite-ui/chip";
import Input from "components/suite-ui/input";

const ValuesField = () => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);

  return (
    <Field name="Values">
      {({ field, form, meta }: FieldProps) => (
        <>
          <Autocomplete
            key="search-values"
            value={field?.value ?? []}
            options={[]}
            onChange={(_, newValue) => {
              form.setFieldValue("Values", newValue);
            }}
            inputValue={inputValue}
            onInputChange={(_, newInputValue) => {
              const options = newInputValue.split(",");
              if (options?.length > 1) {
                const current: string[] = field.value ?? [];

                const newVal = current
                  .concat(options)
                  .map((x) => x.trim())
                  .filter((x) => x);
                form.setFieldValue("Values", newVal);
              } else {
                setInputValue(newInputValue);
              }
            }}
            onFocus={() => {
              setFocused(true);
            }}
            onBlur={() => {
              setFocused(false);
              if (inputValue?.length) {
                const trimmedInput = inputValue.trim();
                form.setFieldValue("Values", field.value.concat(trimmedInput));
              }
            }}
            id="search-values"
            freeSolo
            multiple
            autoComplete
            includeInputInList
            handleHomeEndKeys
            selectOnFocus
            autoHighlight
            renderTags={(value: readonly string[]) => (
              <ChipListWrapper>
                {value.map((option: string, index: number) => (
                  <Chip
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={focused ? "primary" : "default"}
                    onDelete={() => {
                      const filteredField = value.filter((item) => item !== option);
                      form.setFieldValue("Values", filteredField);
                    }}
                  >
                    {option}
                  </Chip>
                ))}
              </ChipListWrapper>
            )}
            renderInput={(params) => (
              <Input
                {...params}
                label={intl.formatMessage({
                  id: "product-filter.modal.values.input.values",
                  defaultMessage: "Values",
                })}
                error={!!meta.error && meta.touched}
                helperText={!!meta.error && meta.touched ? meta.error : undefined}
                placeholder={intl.formatMessage({
                  id: "product-filter.modal.values.input.values.placeholder",
                  defaultMessage: "Add value",
                })}
              />
            )}
          />
        </>
      )}
    </Field>
  );
};

export default ValuesField;
