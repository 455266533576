import { Core } from "@springtree/eva-services-core";
import { Replenishment } from "@springtree/eva-services-replenishment";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getConfigurableProductDetailLoaderQuery,
  serviceQueryKeys: getConfigurableProductDetailQueryKeys,
  useServiceQueryHook: useGetConfigurableProductDetailQuery,
} = createServiceQuery(Core.GetConfigurableProductDetail, true);

export const {
  serviceLoaderQuery: listReplenishmentProductsLoaderQuery,
  serviceQueryKeys: listReplenishmentProductsQueryKeys,
  useServiceQueryHook: useListReplenishmentProductsQuery,
} = createServiceQuery(Replenishment.ListReplenishmentProducts, true);

export const {
  serviceLoaderQuery: listReplenishmentProposalsLoaderQuery,
  serviceQueryKeys: listReplenishmentProposalsQueryKeys,
  useServiceQueryHook: useListReplenishmentProposalsQuery,
} = createServiceQuery(Replenishment.ListReplenishmentProposals, true);

export const {
  serviceLoaderQuery: listReplenishmentProposalResultsLoaderQuery,
  serviceQueryKeys: listReplenishmentProposalResultsQueryKeys,
  useServiceQueryHook: useListReplenishmentProposalResultsQuery,
} = createServiceQuery(Replenishment.ListReplenishmentProposalResults, true);

export const {
  serviceLoaderQuery: getReplenishmentProposalDetailsLoaderQuery,
  serviceQueryKeys: getReplenishmentProposalDetailsQueryKeys,
  useServiceQueryHook: useGetReplenishmentProposalDetailsQuery,
} = createServiceQuery(Replenishment.GetReplenishmentProposal, true);

export const {
  serviceLoaderQuery: getReplenishmentProposalSourcesLoaderQuery,
  serviceQueryKeys: getReplenishmentProposalSourcesQueryKeys,
  useServiceQueryHook: useGetReplenishmentProposalSourcesQuery,
} = createServiceQuery(Replenishment.GetReplenishmentProposalSources, true);

export const {
  serviceLoaderQuery: getReplenishmentProposalTargetsLoaderQuery,
  serviceQueryKeys: getReplenishmentProposalTargetsQueryKeys,
  useServiceQueryHook: useGetReplenishmentProposalTargetsQuery,
} = createServiceQuery(Replenishment.GetReplenishmentProposalSources, true);
