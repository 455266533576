import { useRecoilValue } from "recoil";

import { isAdvancedWorkspacesEnabled } from "../workspaces.state";
import { EKeyName } from "../workspaces.types";

/**
 * Hook that indicates wether the advanced workspace configuration is enabled or not,
 * based on a key name.
 */
export const useAdvancedWorkspace = (keyName: EKeyName) => {
  const isEnabled = useRecoilValue(isAdvancedWorkspacesEnabled(keyName));

  return isEnabled;
};

/**
 * Key names on which the advanced workspaces feature is enabled.
 */
export const ADVANCED_WORKSPACES_SUPPORTED_KEY_NAMES = [EKeyName.Orders];
