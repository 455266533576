import { useCallback, useMemo } from "react";

import { useField } from "formik";

import { Autocomplete, AutocompleteProps } from "components/suite-ui/autocomplete";

interface FormikSingleAutocompleteProps<T>
  extends Omit<AutocompleteProps, "controlledSelectedItem" | "handleSelectedItem" | "multi"> {
  items: T[];
  name: string;
  optionIDKey: keyof T & string;
  matchKeys: (keyof T & string)[];
  renderOptionValueKey: keyof T & string;
  renderOptionsSecondaryValueKey?: keyof T & string;
}

export const FormikSingleAutocomplete = <T,>({
  helperText,
  items,
  name,
  optionIDKey,
  ...props
}: FormikSingleAutocompleteProps<T>) => {
  const [field, meta, { setTouched, setValue }] = useField<T[keyof T] | undefined>(name);

  const handleSelected = useCallback(
    (item: T | undefined) => {
      setValue(item?.[optionIDKey]);
    },
    [optionIDKey, setValue],
  );

  const clearAll = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  const currentlySelectedOption = useMemo(
    () => items.find((item) => item?.[optionIDKey] === field.value) ?? "",
    [field.value, items, optionIDKey],
  );

  return (
    <Autocomplete
      {...props}
      items={items}
      clearAllItems={clearAll}
      optionIDKey={optionIDKey}
      handleDeleteItem={clearAll}
      handleSelectedItem={handleSelected}
      error={!!meta.error && meta.touched}
      onBlur={() => setTouched(true, true)}
      controlledSelectedItem={currentlySelectedOption}
      helperText={meta.error && meta.touched ? meta.error : helperText}
    />
  );
};

FormikSingleAutocomplete.defaultProps = {
  renderOptionsSecondaryValueKey: undefined,
};
