import { useIntl } from "react-intl";

import * as yup from "yup";

const usePrefillTabValidationSchema = () => {
  const intl = useIntl();

  const validationSchema = yup.object().shape({
    templateID: yup.number().required(
      intl.formatMessage({
        id: "product-filter.modal.prefill.product-search-template.validation.required",
        defaultMessage: "This field is required",
      }),
    ),
  });

  return validationSchema;
};

export default usePrefillTabValidationSchema;
