import { useIntl } from "react-intl";

import { KeyValueRow, KeyValueRowList } from "@new-black/lyra";

import { TMappedProductSetsToEVAFilterModel } from "util/product-sets";

export const LoyaltyProgramRuleProductSetConditionKeyValueRows = ({
  identicalProductsOnly,
  productSet,
}: {
  identicalProductsOnly?: boolean;
  productSet: TMappedProductSetsToEVAFilterModel;
}) => {
  const intl = useIntl();

  return (
    <KeyValueRowList>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.identical-products-only",
          defaultMessage: "Identical products only",
        })}
      >
        {identicalProductsOnly
          ? intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" })
          : intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" })}
      </KeyValueRow>

      {productSet?.RequiredQuantityOfProducts ? (
        <KeyValueRow
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-quantity",
            defaultMessage: "Required quantity of products",
          })}
        >
          {productSet?.RequiredQuantityOfProducts}
        </KeyValueRow>
      ) : (
        <KeyValueRow
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-amount-spent",
            defaultMessage: "Required amount spent on products",
          })}
        >
          {productSet?.RequiredProductAmount}
        </KeyValueRow>
      )}
    </KeyValueRowList>
  );
};
