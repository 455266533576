import type { IColumnButtonProps } from "./column-button";
import ColumnButton from "./column-button";

const OverlayedColumnButton = (props: IColumnButtonProps) => (
  <div className="[&_>_div]:cursor-grabbing [&_>_div]:bg-[color:#f6f6f6]">
    <ColumnButton {...props} />
  </div>
);

export default OverlayedColumnButton;
