import { useCallback } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState } from "recoil";

import usePrevious from "hooks/suite-react-hooks/use-previous";

import { selectedOrganizationUnitAtom } from "../organization-unit-selector.state";

import useOrganizationUnitStructure from "./use-organization-unit-structure";
import { useOrganizationUnitSelectorContext } from "../organization-unit-selector-provider";

/**
 * Hooks that provides access to the selected organization unit and callbacks to set or reset it
 */
export const useSelectedOrganizationUnit = () => {
  const { currentUser } = useOrganizationUnitSelectorContext();
  const [selectedOrganizationUnit, setSelectedOrganizationUnit] = useRecoilState(
    selectedOrganizationUnitAtom,
  );
  const previousSelectedOrganizationUnit = usePrevious(selectedOrganizationUnit);
  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();

  // Resets selected organization unit to current user organization unit
  const resetSelectedOrganization = useCallback(() => {
    if (currentUser?.User?.CurrentOrganizationID && flattenedOrganizationUnits) {
      setSelectedOrganizationUnit(
        flattenedOrganizationUnits?.find((ou) => ou.ID === currentUser.User.CurrentOrganizationID),
      );
    }
  }, [
    currentUser?.User?.CurrentOrganizationID,
    flattenedOrganizationUnits,
    setSelectedOrganizationUnit,
  ]);

  const setSelectedOrganizationUnitById = useCallback(
    (id: number) => {
      const organizationUnit = flattenedOrganizationUnits?.find((ou) => ou.ID === id);
      if (organizationUnit) {
        setSelectedOrganizationUnit(organizationUnit);
      }
    },
    [flattenedOrganizationUnits, setSelectedOrganizationUnit],
  );

  return {
    selectedOrganizationUnit,
    previousSelectedOrganizationUnit,
    setSelectedOrganizationUnitById,
    resetSelectedOrganization,
  };
};
