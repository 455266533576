/* eslint-disable react/display-name */
import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { ListChildComponentProps, VariableSizeList } from "react-window";

import { Card } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "classnames";
import { isNil, min } from "lodash";
import moment, { Moment } from "moment";

import Text from "../text";

import useCalendarCellWidth from "./hooks/use-calendar-cell-width";
import useCalendarDisplayedDays from "./hooks/use-calendar-displayed-days";
import useFilteredCalendarItems from "./hooks/use-filtered-calendar-items";
import CalendarHeader from "./calendar-header";
import CalendarItem from "./calendar-item";
import { isDateToday } from "./helpers";
import { IBaseCalendarItem, ICalendarStrings } from "./types";

const DEFAULT_DISPLAYED_DAYS = 34;
const DEFAULT_ROW_HEIGHT = 52;
const DEFAULT_ITEM_HEIGHT = 40;
const HEADER_HEIGHT = 87;

export interface ICalendarProps<T extends IBaseCalendarItem> {
  /**
   * Moment / Date object or a formatted date string containing any day of the month you want to display. Only the month and year are relevant for the calendar.
   * If omitted, the calendar defaults to the current month
   */
  month?: Moment | Date | string;
  /**
   * Callback to update the displayed month when the next / previous buttons are clicked.
   */
  setMonth?: (newValue: Date) => void;
  /**
   * Items displayed in the calendar.
   */
  items: T[];
  /**
   * Function that receives an item and returns the content to be rendered for that item.
   */
  renderItem: (item: T) => ReactNode;
  /** If true, the items will be aligned to the columns that correspond to their start and end date
   * regardless of their start and end times.
   *
   * Otherwise the alignment of the items will take into account their start and end times.
   */
  snapItemsToFullDays?: boolean;
  /**
   * The number of displayed days. If it is greater than the number of days of the displayed month then the calendar will be padded
   * with days at the end of the previous month / start of next month.
   *
   * If the value is less than the number of days in the displayed month, the full month will still be shown.
   */
  numberOfDisplayedDays?: number;
  /**
   * The maximum number of items to display at once. If smaller than the dataset, item rows will be virtualized.
   */
  numberOfDisplayedItems?: number;
  /**
   * The height of each row of the calendar in pixels.
   */
  rowHeight?: number;
  /**
   * The height of each item in the calendar in pixels.
   */
  itemHeight?: number;
  calendarStrings?: ICalendarStrings;
}

interface GridParentProps extends ComponentPropsWithoutRef<"div"> {
  numberOfColumns: number;
}

interface DayCellProps extends ComponentPropsWithoutRef<"div"> {
  isWeekend: boolean;
  column: number;
  isSunday: boolean;
  index: number;
  height: number;
}

const Calendar = <T extends IBaseCalendarItem>({
  calendarStrings,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  items,
  month,
  numberOfDisplayedDays = DEFAULT_DISPLAYED_DAYS,
  numberOfDisplayedItems,
  renderItem,
  rowHeight = DEFAULT_ROW_HEIGHT,
  setMonth,
  snapItemsToFullDays,
}: ICalendarProps<T>) => {
  const intl = useIntl();
  const [localMonth, setLocalMonth] = useState(
    month ? moment(month).utc() : moment.utc().startOf("month"),
  );
  const calendarRef = useRef<HTMLDivElement | null>(null);

  const displayedDays = useCalendarDisplayedDays({
    month: localMonth,
    numberOfDisplayedDays,
  });
  const cellWidth = useCalendarCellWidth({
    numberOfColumns: displayedDays.length,
    calendarRef,
  });

  // filter items for which the start date - end date interval does not intersect with the displayed days so they are not rendered
  const filteredItems = useFilteredCalendarItems({ items, displayedDays });

  useEffect(() => {
    if (!isNil(month)) {
      setLocalMonth(moment(month).utc());
    }
  }, [month]);

  const onPreviousButtonClick = useCallback(() => {
    const newValue = moment(localMonth).subtract(1, "month").startOf("month");
    if (setMonth) {
      setMonth(newValue.toDate());
    } else {
      setLocalMonth(newValue);
    }
  }, [localMonth, setMonth]);

  const onNextButtonClick = useCallback(() => {
    const newValue = moment(localMonth).add(1, "month").startOf("month");
    if (setMonth) {
      setMonth(newValue.toDate());
    } else {
      setLocalMonth(newValue);
    }
  }, [localMonth, setMonth]);

  return (
    <Card>
      <div className="p-5">
        <VariableSizeList
          height={
            (min([filteredItems.length, numberOfDisplayedItems ?? Number.POSITIVE_INFINITY]) ??
              filteredItems.length) *
              rowHeight +
            HEADER_HEIGHT
          }
          width="100%"
          itemSize={(index) => {
            if (index === 0) {
              return HEADER_HEIGHT;
            }

            return rowHeight;
          }}
          itemCount={filteredItems.length + 1}
          itemKey={(index: number) => (index === 0 ? "firstRow" : filteredItems?.[index]?.ID ?? "")}
          innerElementType={forwardRef<HTMLDivElement, any>(({ children, ...rest }, ref) => (
            <div ref={ref} {...rest}>
              <GridParent numberOfColumns={displayedDays.length}>
                <CalendarHeader
                  onNextButtonClick={onNextButtonClick}
                  onPreviousButtonClick={onPreviousButtonClick}
                  title={localMonth.format("MMMM YYYY")}
                  month={localMonth}
                  displayedDays={displayedDays}
                  calendarStrings={calendarStrings}
                />
              </GridParent>
              {children}
            </div>
          ))}
        >
          {({ index, style }: ListChildComponentProps) => (
            <div
              style={{
                ...style,
                display: "grid",
                gridTemplateColumns: `repeat(${displayedDays.length}, minmax(28px, 1fr)`,
              }}
              ref={index === 0 ? calendarRef : undefined}
            >
              {index === 0 ? null : (
                <>
                  {displayedDays.map((day, dayIndex) => (
                    <DayCell
                      key={`day-${day.toDate().getTime()}`}
                      isWeekend={[0, 6].includes(day.day())}
                      column={dayIndex + 1}
                      isSunday={day.day() === 0}
                      index={index}
                      height={rowHeight}
                    >
                      {isDateToday(day) ? <TodayLine /> : null}
                    </DayCell>
                  ))}
                  <CalendarItem
                    key={filteredItems?.[index - 1]?.ID}
                    item={filteredItems?.[index - 1]}
                    displayedDays={displayedDays}
                    snapToFullDay={snapItemsToFullDays}
                    renderItem={renderItem}
                    height={itemHeight}
                    cellWidth={cellWidth}
                  />
                </>
              )}
            </div>
          )}
        </VariableSizeList>
        {filteredItems.length === 0 ? (
          <div className="p-5">
            <Text>
              {calendarStrings?.noItemsMessage ??
                intl.formatMessage({
                  id: "generic.label.no-items-available",
                  defaultMessage: "No items available",
                })}
            </Text>
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export const GridParent = ({ className, numberOfColumns, ...props }: GridParentProps) => (
  <div
    {...props}
    style={{ gridTemplateColumns: `repeat(${numberOfColumns}, minmax(28px, 1fr))` }}
    className={classNames(
      "sticky top-0 z-[13] grid w-full bg-[color:#fff]",
      "[grid-template-rows:_auto_auto]",
      className,
    )}
  />
);

const dayCellClasses = cva(
  [
    "border-l-0 border-b border-r",
    "first-of-type:border-l pointer-events-none row-span-1 row-start-1",
    "box-border border-solid border-[color:var(--legacy-eva-color-light-3)]",
  ],
  {
    variants: {
      isSunday: {
        true: "border-r-2",
      },
      isWeekend: {
        true: "bg-[color:#fafafa]",
        false: "bg-[color:#fff]",
      },
      isFirst: {
        true: "border-t",
        false: "border-t-0",
      },
    },
  },
);

const DayCell = ({
  children,
  className,
  column,
  height,
  index,
  isSunday,
  isWeekend,
}: DayCellProps) => (
  <div
    style={{
      gridColumn: `${column} / ${column + 1}`,
      height: `${height}px`,
    }}
    className={classNames(dayCellClasses({ isSunday, isWeekend, isFirst: index === 1 }), className)}
  >
    {children}
  </div>
);

const TodayLine = ({ children, className }: ComponentPropsWithoutRef<"div">) => (
  <div
    className={classNames(
      "mx-auto my-0 h-[calc(100%+14px)] w-[2px] translate-y-[-1px] bg-[color:var(--legacy-eva-color-primary)] ",
      className,
    )}
  >
    {children}
  </div>
);

export default Calendar;
