import { IntlShape } from "react-intl";

import { RepairShippingOptions } from "types/repair-shipping-options";

export const getRepairShippingOptionsTranslation = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.RepairShippingOptions, string> = {
    [RepairShippingOptions.Delivery]: intl.formatMessage({
      id: "generic.label.delivery",
      defaultMessage: "Delivery",
    }),
    [RepairShippingOptions.Pickup]: intl.formatMessage({
      id: "generic.label.pickup",
      defaultMessage: "Pickup",
    }),
  };

  return (status: EVA.Core.RepairShippingOptions) => {
    return translationMap[status];
  };
};
