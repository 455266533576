import { useCallback, useEffect, useMemo } from "react";
import { useRevalidator } from "react-router-dom";

import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import { helpers, hooks, state } from "@springtree/eva-sdk-react-recoil";
import { defer } from "lodash";

import { useSelectedOrganizationUnit } from "components/suite-composite/organization-unit-selector";
import useNavigationButtons from "components/suite-composite/organization-unit-selector/hooks/use-navigation-buttons";
import useOrganizationMenuItems from "components/suite-composite/organization-unit-selector/hooks/use-organization-menu-items";
import useOrganizationUnitFilter from "components/suite-composite/organization-unit-selector/hooks/use-organization-unit-filter";
import useOrganizationUnitStructure from "components/suite-composite/organization-unit-selector/hooks/use-organization-unit-structure";
import { IOrganizationUnitMenuItem } from "components/suite-composite/organization-unit-selector/organization-unit-selector.types";
import { queryClient } from "util/query-client";

import { useLatestSelectedOrganizationUnitContext } from "./latest-selected-ou-context";

const { storage } = helpers;

export const useOUSelector = () => {
  const currentUser = hooks.useGetState(state.currentUser.currentUserState.response);

  const requestedOUID = hooks.useGetState(state.core.requestedOrganizationUnitIdState);
  //use value form context
  const { latestSelectedOrganizationUnitID, setLatestSelectedOrganizationUnitID } =
    useLatestSelectedOrganizationUnitContext();

  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();
  const { selectedOrganizationUnit, setSelectedOrganizationUnitById } =
    useSelectedOrganizationUnit();

  // const showOrganizationUnitSelector = useRecoilValue(showOrganizationUnitSelectorAtom);
  // const { anchorEl, isMenuOpen, handleClickMenu, handleCloseMenu } = useOrganizationMenu();
  const { menuItems, setMenuItems, setMenuItemsFromSelectedOU /*previousMenuItemsLength*/ } =
    useOrganizationMenuItems();
  const { debouncedInputValue, resetFilter } = useOrganizationUnitFilter(
    setMenuItems,
    setMenuItemsFromSelectedOU,
  );

  const revalidator = useRevalidator();

  // Initialize selected OU with the latest selected OU, otherwise with the current user OU
  useEffect(() => {
    if (latestSelectedOrganizationUnitID !== undefined) {
      setSelectedOrganizationUnitById(latestSelectedOrganizationUnitID);

      storage.setItem(
        "evaRequestedOrganizationUnitID",
        latestSelectedOrganizationUnitID.toString(),
      );

      queryClient.removeQueries();
      defer(() => revalidator.revalidate());
    } else if (currentUser?.User?.CurrentOrganizationID && flattenedOrganizationUnits) {
      setSelectedOrganizationUnitById(requestedOUID ?? currentUser.User.CurrentOrganizationID);

      queryClient.removeQueries();
      defer(() => revalidator.revalidate());
    }
    // not include dependency to revalidator on purpose, because it's changing its refference and causing an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentUser?.User.CurrentOrganizationID,
    flattenedOrganizationUnits,
    latestSelectedOrganizationUnitID,
    setSelectedOrganizationUnitById,
  ]);

  const parentOrganizationUnit = useMemo(
    () =>
      menuItems?.length
        ? flattenedOrganizationUnits?.find(
            (ou) =>
              ou.ID ===
              flattenedOrganizationUnits?.find((menuOU) => menuOU.ID === menuItems[0]?.ID)
                ?.ParentID,
          )
        : undefined,
    [flattenedOrganizationUnits, menuItems],
  );

  const { direction, onGoBack, onGoNext } = useNavigationButtons(
    setMenuItems,
    parentOrganizationUnit,
  );

  const handleItemClick = useCallback(
    (item: IOrganizationUnitMenuItem) => {
      if (flattenedOrganizationUnits?.some((ou) => ou.ID === item.ID)) {
        // handleCloseMenu();
        resetFilter();
        storage.setItem("evaRequestedOrganizationUnitID", "");
        if (item.ID !== selectedOrganizationUnit?.ID) {
          storage.setItem("evaRequestedOrganizationUnitID", item.ID.toString());
          setSelectedOrganizationUnitById(item.ID);

          //remember latest selected OU in order to act as the initial OU when returning to a page with an OU selector
          setLatestSelectedOrganizationUnitID(item.ID);
        }
      }
    },
    [
      flattenedOrganizationUnits,
      resetFilter,
      selectedOrganizationUnit?.ID,
      setSelectedOrganizationUnitById,
      setLatestSelectedOrganizationUnitID,
    ],
  );

  // Execute callback whenever selected organization unit changes
  useEffect(() => {
    if (selectedOrganizationUnit) {
      setServiceSetting("requestedOrganizationUnitID", selectedOrganizationUnit?.ID);
    }
  }, [selectedOrganizationUnit]);

  return {
    selectedOrganizationUnit,
    direction,
    debouncedInputValue,
    handleItemClick,
    onGoBack,
    onGoNext,
    menuItems,
    parentOrganizationUnit,
  };
};
