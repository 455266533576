import { generatePath } from "react-router-dom";

import { CardActions, CardHeader, Table, Text } from "@new-black/lyra";

import OpenInNewTabActionButtonLink from "components/shared/action-button-links/open-in-new-tab-action-button-link";
import routeDefinitions from "routes/route-definitions";

import SubsetTableFilter from "./subset-table-filter";
import useOUSetSubsetsTableConfig from "./use-ou-set-subsets-table-config";

interface IOUSetSubsetsListProps {
  ouSetId: number;
}

/**
 * Component that displays the subsets of a OUSet
 *
 * ## Translations
 * Make sure you add these translations to your translation file.
 * ```json
  {
    "generic.label.yes": "Yes",
    "generic.label.no": "No",
    "generic.action.search": "Search",
    "generic.action.clear": "Clear",
    "ouset-subsets-list.table.title": "Filtered subsets of organization unit set {ouSetName} ({total})",
    "ouset-subsets-list.table.actions.search.label": "Filter on name or ID",
    "generic.label.id": "ID",
    "generic.label.name": "Name",
    "ouset-subsets-list.table.headers.type": "Type",
    "ouset-subsets-list.table.headers.type.0": "System",
    "ouset-subsets-list.table.headers.type.1": "Custom",
    "ouset-subsets-list.table.headers.type.2": "Ad-hoc",
    "ouset-subsets-list.table.headers.has-subsets": "Has Subsets",
    "ouset-subsets-list.table.headers.actions": "Actions",
    "ouset-subsets-list.table.row.open-in-organizations": "Open in organizations",
  }
 * ```
 */
export const OUSetSubsetsList = ({ ouSetId }: IOUSetSubsetsListProps) => {
  const { setStart, setTerm, tableConfig, term, title } = useOUSetSubsetsTableConfig(ouSetId);

  return (
    <div className="flex flex-col">
      <div className="flex items-center justify-between p-4 pb-1">
        <div className="">
          <Text variant="heading-2">{title}</Text>
        </div>
        <div className="flex gap-2">
          <SubsetTableFilter
            term={term}
            setTerm={(newTerm) => {
              setStart(0);
              setTerm(newTerm);
            }}
          />
          <OpenInNewTabActionButtonLink
            to={generatePath(routeDefinitions.organizations.organizationsSets.details.path, {
              id: ouSetId.toString(),
            })}
          />
        </div>
      </div>
      <Table aria-label={title} {...tableConfig} />
    </div>
  );
};

export const LyraOUSetSubsetsList = ({ ouSetId }: IOUSetSubsetsListProps) => {
  const { setStart, setTerm, tableConfig, term, title } = useOUSetSubsetsTableConfig(ouSetId);

  return (
    <>
      <CardHeader
        className="z-50"
        title={title}
        actions={
          <CardActions>
            <SubsetTableFilter
              term={term}
              setTerm={(newTerm) => {
                setStart(0);
                setTerm(newTerm);
              }}
            />
            <OpenInNewTabActionButtonLink
              to={generatePath(routeDefinitions.organizations.organizationsSets.details.path, {
                id: ouSetId.toString(),
              })}
            />
          </CardActions>
        }
      />
      <Table aria-label={title} {...tableConfig} />
    </>
  );
};
