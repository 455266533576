import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";

import {
  IAutocompleteBaseProps,
  IMultiAutocompleteProps,
  ISingleAutocompleteProps,
} from "./autocomplete.types";
import { PassiveAutocomplete } from "./passive-autocomplete";
import useAutocompleteProps from "./use-autocomplete-props";

export * from "./autocomplete.types";

export const Autocomplete = <T,>({
  passive,
  disabled,
  IDKey: idKey,
  LabelKey: labelKey,
  SecondaryLabelKey: secondaryLabelKey,
  filterKeys = [idKey, labelKey],
  endAdornment,
  hideDownArrow,
  placeholder,
  disablePortal,
  ...props
}: (ISingleAutocompleteProps<T> | IMultiAutocompleteProps<T>) &
  IAutocompleteBaseProps<T, keyof T, keyof T, keyof T>) => {
  const autoCompleteProps = useAutocompleteProps({
    ...props,
    filterKeys,
    IDKey: idKey,
    LabelKey: labelKey,
    SecondaryLabelKey: secondaryLabelKey,
    endAdornment,
    hideDownArrow,
    placeholder,
  });

  if (passive) {
    return (
      <PassiveAutocomplete {...props} filterKeys={filterKeys} IDKey={idKey} LabelKey={labelKey} />
    );
  }

  return (
    <div>
      <MuiAutocomplete<T, boolean, boolean, undefined>
        {...autoCompleteProps}
        disabled={disabled}
        filterOptions={autoCompleteProps.filterOptions}
        disablePortal={disablePortal}
      />
    </div>
  );
};
