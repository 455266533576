export enum RepairState {
  WaitingForGoods = 0,
  WaitingForRepair = 1,
  WaitingForPayment = 2,
  WaitingForShipment = 3,
  Finished = 4,
  Cancelled = 5,
  WaitingForPickup = 6,
  Started = 7,
  OnHold = 8,
}
