import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export interface IToggleFullScreenActionButton {
  isFullScreen?: boolean;
  onFullScreenChange?: (isFullScreen: boolean) => void;
}

const ToggleFullScreenActionButton = ({
  isFullScreen,
  onFullScreenChange,
}: IToggleFullScreenActionButton) => {
  const intl = useIntl();

  return (
    <Button
      variant="icon"
      tooltip={
        isFullScreen
          ? intl.formatMessage({ id: "generic.label.close", defaultMessage: "Close" })
          : intl.formatMessage({
              id: "generic.label.open-in-full-screen",
              defaultMessage: "Open in full screen",
            })
      }
      onPress={() => {
        onFullScreenChange?.(!isFullScreen);
      }}
    >
      <SvgIcon name={isFullScreen ? "exit-fullscreen" : "fullscreen"} />
    </Button>
  );
};

export default ToggleFullScreenActionButton;
