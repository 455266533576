import { RecoilState, RecoilValueReadOnly } from "recoil";

/** Each chapter has its own keyname that identifies it within the workspaces. */
export enum EKeyName {
  PurchaseOrders = "purchase-orders",
  FullStockCounts = "full-stock-counts",
  Employees = "employees",
  Consumers = "consumers",
  Orders = "orders",
  Discounts = "discounts",
  FinancialPeriods = "financial-periods",
  Organizations = "organizations",
  Products = "products",
  Replenishments = "replenishments",
  StockOverview = "stock-overview",
  StockMutations = "stock-mutations",
  Watchtower = "watchtower",
  ZonedCycleCounts = "zoned-cycle-counts",
}

/** Type used to declare how a workspace looks like for a given route. */
export interface IPageConfiguration<TWorkspace> {
  /** Path used the identify a workspace for a given route. Can be used in combination with the *keyName* to share the workspace between pages. */
  path: string;
  /** Used to identify a workspace for a given chapter. Can be used in combination with the *path* to share the workspace between pages. */
  keyName: EKeyName;
  /** Which properties are being used within the workspace for a particular page. */
  propertyNames: (keyof TWorkspace)[];
}

export interface IUseWorkspacesProps<TWorkspace> {
  /** State that indicates how the default workspace should look like. */
  defaultSettings: (keyName: EKeyName | undefined) => RecoilValueReadOnly<{
    [key: string]: any;
  }>;
  /** State that holds the active workspace. This could mean the filter blocks, the table columns, the request state and anything else the workspace needs to keep state of. */
  state: (keyName: EKeyName | undefined) => RecoilState<Partial<TWorkspace> | undefined>;
  /** Configurations declared for a given workspace. Links a given route to the right workspace state. */
  pageConfigurations: IPageConfiguration<TWorkspace>[];
}

export interface IAdvancedWorkspaceSettings<T> {
  advancedWorkspaceSettings?: T;
}
