import { ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Badge, TableColumnDef } from "@new-black/lyra";

import { useProductFilterContext } from "../product-filter-context";

const useListTableColumns = (customRowActions?: (rowData: any) => ReactNode) => {
  const intl = useIntl();

  const { maximumDisplayedValuesInList } = useProductFilterContext();

  const createTypeColumn = useCallback(
    (values?: string[]) => {
      return values?.length
        ? intl.formatMessage({
            id: "product-filter.list.header.values",
            defaultMessage: "Values",
          })
        : intl.formatMessage({
            id: "product-filter.list.header.range",
            defaultMessage: "Range",
          });
    },
    [intl],
  );

  const createDataColumn = useCallback(
    (values?: string[], from?: string, to?: string): ReactNode => {
      if (values?.length) {
        const hasEllipsis =
          !!maximumDisplayedValuesInList && values.length > maximumDisplayedValuesInList;

        return (
          <span className="flex flex-wrap gap-2">
            {values?.slice(0, maximumDisplayedValuesInList).map((value, index) => (
              <Badge variant="transparent" key={index}>
                {value}
              </Badge>
            ))}
            {hasEllipsis ? <span className="self-end">...</span> : null}
          </span>
        );
      }

      return intl.formatMessage(
        {
          id: "generic.label.from-value-to-value",
          defaultMessage: "From {fromValue} to {toValue}",
        },
        {
          fromValue: <Badge variant="transparent">{from}</Badge>,
          toValue: <Badge variant="transparent">{to}</Badge>,
        },
      );
    },
    [intl, maximumDisplayedValuesInList],
  );

  const createSettingsColumn = useCallback(
    (exactMatch?: boolean, negation?: boolean, includeMissing?: boolean) => {
      const settings: string[] = [];

      if (exactMatch) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.exact-match",
            defaultMessage: "Exact match",
          }),
        );
      }

      if (negation) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.negation",
            defaultMessage: "Negation",
          }),
        );
      }

      if (includeMissing) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.include-missing",
            defaultMessage: "Include missing",
          }),
        );
      }

      return settings;
    },
    [intl],
  );

  const columns = useMemo(() => {
    const result: TableColumnDef<any>[] = [
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.property-name",
          defaultMessage: "Property name",
        }),
        accessorKey: "keyName",
        id: "keyName",
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.type",
          defaultMessage: "Type",
        }),
        accessorKey: "Values",
        id: "Values",
        cell: ({ getValue }: { getValue: () => string[] }) => (
          <span>{createTypeColumn(getValue())}</span>
        ),
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.data",
          defaultMessage: "Data",
        }),
        accessorKey: "From",
        id: "From",
        cellType: "badge",
        cell: ({ row }) => (
          <span>{createDataColumn(row.original.Values, row.original.From, row.original.To)}</span>
        ),
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.settings",
          defaultMessage: "Settings",
        }),
        accessorKey: "ExactMatch",
        id: "ExactMatch",
        cellType: "badge",
        cell: ({ row }) => (
          <span className="flex flex-wrap gap-2">
            {createSettingsColumn(
              row.original.ExactMatch,
              row.original.Negation,
              row.original.IncludeMissing,
            ).map((setting, index) => (
              <Badge key={index} variant="transparent">
                {setting}
              </Badge>
            ))}
          </span>
        ),
      },
    ];

    if (customRowActions) {
      result.push({
        id: "actions",
        cellType: "button",
        align: "end",
        header: "",
        cell: ({ row }) => customRowActions(row.original),
      });
    }

    return result;
  }, [createDataColumn, createSettingsColumn, createTypeColumn, customRowActions, intl]);

  return columns;
};

export default useListTableColumns;
