export default {
  path: "/profile",
  editPersonalInformation: {
    path: "/profile/edit-personal-information",
  },
  editLanguageAndTimeZone: {
    path: "/profile/edit-language-and-time-zone",
  },
  editPassword: {
    path: "/profile/edit-password",
  },
  activateTwoFactorAuthentication: {
    path: "/profile/activate-two-factor-authentication",
  },
  deactivateTwoFactorAuthentication: {
    path: "/profile/deactivate-two-factor-authentication",
  },
  verifyEmailAddress: {
    path: "/profile/verify-email-address",
  },
} as const;
