export const PRODUCT_BASIC_FIELDS = [
  "product_id",
  "backend_id",
  "custom_id",
  "display_value",
  "barcodes",
  "primitive_name",
  "barcode_cu",
  "product_name",
  "primary_image.blob",
  "product_media.gallery",
  "product_media.primary_image.blob",
  "product_media.primary_image.name",
  "product_media.primary_image.type",
] as const;

export interface IProduct {
  product_id: number;
  backend_id?: string;
  custom_id?: string;
  display_value?: string;
  primary_image?: {
    blob?: string;
  };
  barcodes?: string[];
  primitive_name?: string;
  barcode_cu?: string;
  product_media?: IProductMedia;
  product_name?: string;
}

interface IProductMedia {
  gallery?: string[];
  primary_image?: TProductPrimaryImage;
}

type TProductPrimaryImage = {
  blob?: string;
  name?: string;
  type?: string;
};
