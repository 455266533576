import { useCallback } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useHandleSWR } from "hooks/suite-react-hooks/use-handle-swr";

import {
  countrySubdivisionsListResponseSelector,
  countrySubdivisionsListServiceState,
} from "../../features/tax-management/state";

const useCountrySubdivisions = () => {
  const countrySubdivisionsList = hooks.useGetState(countrySubdivisionsListResponseSelector);
  useHandleSWR(countrySubdivisionsListServiceState);
  const getSubdivisionName = useCallback(
    (subdivisionId: string) =>
      (countrySubdivisionsList ?? []).find((subdivision) => subdivision.ID === subdivisionId)
        ?.Name ?? subdivisionId,
    [countrySubdivisionsList],
  );
  const getCountrySubdivisions = useCallback(
    (countryId?: string) =>
      (countrySubdivisionsList ?? []).filter((subdivision) => subdivision.CountryID === countryId),
    [countrySubdivisionsList],
  );

  return { countrySubdivisionsList, getSubdivisionName, getCountrySubdivisions };
};

export default useCountrySubdivisions;
