import { useEffect, useMemo, useState } from "react";

/**
 * Checks wether a HTML element is in viewport. Implementation based on `InteractionObserver`.
 * https://bobbyhadz.com/blog/react-check-if-element-in-viewport
 */
export const useIsInViewport = <T extends HTMLElement>(
  ref: React.MutableRefObject<T | null>,
  defaultValue?: boolean,
) => {
  const [isIntersecting, setIsIntersecting] = useState(defaultValue ?? false);

  const observer = useMemo(
    () => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
    [],
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [observer, ref]);

  return isIntersecting;
};
