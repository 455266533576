import { useMemo } from "react";

import { Functionalities, FunctionalityScope } from "types/functionalities";

import { useHasFunctionality, useHasFunctionalityWithoutScope } from "../use-has-functionality";

/**
 * Hook that returns current user's functionalities (scoped and unscoped).
 * @param functionality the functionality to be checked
 * @param organizationUnitID the organization unit on which the functionality will be checked
 * (defaults to `CurrentUserOrganizationUnitID` from `currentUserState`)
 */
const useAllowedFunctionalities = (functionality: Functionalities, organizationUnitID?: number) => {
  const {
    functionalityAllowed: createFunctionalityAllowed,
    loading: createFunctionalityLoading,
    loadingWithoutResponse: createFunctionalityLoadingWithoutResponse,
  } = useHasFunctionality(functionality, FunctionalityScope.Create, organizationUnitID);

  const {
    functionalityAllowed: viewFunctionalityAllowed,
    loading: viewFunctionalityLoading,
    loadingWithoutResponse: viewFunctionalityLoadingWithoutResponse,
  } = useHasFunctionality(functionality, FunctionalityScope.View, organizationUnitID);

  const {
    functionalityAllowed: editFunctionalityAllowed,
    loading: editFunctionalityLoading,
    loadingWithoutResponse: editFunctionalityLoadingWithoutResponse,
  } = useHasFunctionality(functionality, FunctionalityScope.Edit, organizationUnitID);

  const {
    functionalityAllowed: deleteFunctionalityAllowed,
    loading: deleteFunctionalityLoading,
    loadingWithoutResponse: deleteFunctionalityLoadingWithoutResponse,
  } = useHasFunctionality(functionality, FunctionalityScope.Delete, organizationUnitID);

  const {
    functionalityAllowed: manageFunctionalityAllowed,
    loading: manageFunctionalityLoading,
    loadingWithoutResponse: manageFunctionalityLoadingWithoutResponse,
  } = useHasFunctionality(functionality, FunctionalityScope.Manage, organizationUnitID);

  const {
    functionalityAllowed: unscopedFunctionalityAllowed,
    loading: unscopedFunctionalityLoading,
    loadingWithoutResponse: unscopedFunctionalityLoadingWithoutResponse,
  } = useHasFunctionalityWithoutScope(functionality, organizationUnitID);

  const isLoading = useMemo(
    () =>
      createFunctionalityLoading ||
      viewFunctionalityLoading ||
      editFunctionalityLoading ||
      deleteFunctionalityLoading ||
      manageFunctionalityLoading ||
      unscopedFunctionalityLoading,
    [
      createFunctionalityLoading,
      viewFunctionalityLoading,
      editFunctionalityLoading,
      deleteFunctionalityLoading,
      manageFunctionalityLoading,
      unscopedFunctionalityLoading,
    ],
  );

  const isLoadingWithoutResponse = useMemo(
    () =>
      createFunctionalityLoadingWithoutResponse ||
      viewFunctionalityLoadingWithoutResponse ||
      editFunctionalityLoadingWithoutResponse ||
      deleteFunctionalityLoadingWithoutResponse ||
      manageFunctionalityLoadingWithoutResponse ||
      unscopedFunctionalityLoadingWithoutResponse,
    [
      createFunctionalityLoadingWithoutResponse,
      viewFunctionalityLoadingWithoutResponse,
      editFunctionalityLoadingWithoutResponse,
      deleteFunctionalityLoadingWithoutResponse,
      manageFunctionalityLoadingWithoutResponse,
      unscopedFunctionalityLoadingWithoutResponse,
    ],
  );

  return {
    createFunctionalityAllowed,
    viewFunctionalityAllowed,
    editFunctionalityAllowed,
    deleteFunctionalityAllowed,
    manageFunctionalityAllowed,
    unscopedFunctionalityAllowed,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export type TAllowedFunctionalities = ReturnType<typeof useAllowedFunctionalities>;

export default useAllowedFunctionalities;
