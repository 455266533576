/* eslint-disable no-nested-ternary */
import { useEffect } from "react";

import {
  Formik,
  FormikConfig,
  FormikProps,
  FormikValues,
  isEmptyChildren,
  isFunction,
  setNestedObjectValues,
  useFormikContext,
} from "formik";

const SetTouched = <TValues extends FormikValues>() => {
  const { initialValues, isSubmitting, isValidating, setTouched } = useFormikContext<TValues>();

  useEffect(() => {
    if (isSubmitting && !isValidating) {
      setTouched(setNestedObjectValues(initialValues, true), false);
    }
  }, [isSubmitting, isValidating, initialValues, setTouched]);

  return null;
};

export const EVAFormik = <TValues extends FormikValues>({
  children,
  ...rest
}: FormikConfig<TValues>) => {
  return (
    <Formik {...rest}>
      {(formikProps) => (
        <>
          {/* need to check the type of the children prop to correctly support both ReactNode and render props ways of working
          https://github.com/jaredpalmer/formik/blob/e677bea8181f40e6762fc7e7fb009122384500c6/packages/formik/src/Formik.tsx#L1014 */}
          {children
            ? isFunction(children)
              ? (children as (formikProps: FormikProps<TValues>) => React.ReactNode)(formikProps)
              : !isEmptyChildren(children)
              ? children
              : null
            : null}
          <SetTouched<TValues> />
        </>
      )}
    </Formik>
  );
};
