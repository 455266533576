export const convertIndexArrayToObject = <T extends string | number>(array: T[]) => {
  const initialValue: Record<T, true> = {} as Record<T, true>;
  return array.reduce(
    (obj, item) =>
      ({
        ...obj,
        [item]: true,
      } as Record<T, true>),
    initialValue,
  );
};
