import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";
import { Button, Card, CardContent, CardHeader, Separator, SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import { Link } from "components/routing";
import Text from "components/suite-ui/text";
import { useGetCurrentUserQuery } from "models/users";
import { useRootRouteData } from "routes/root";
import routeDefinitions from "routes/route-definitions";

import { IdentificationCodeDialog } from "./identification-code-dialog/identification-code-dialog";
import { MenuProfileProps } from "./menu.types";

export const MenuProfile = ({ setIsFocused, setMouseEntered }: MenuProfileProps) => {
  const rootData = useRootRouteData();
  const { data } = useGetCurrentUserQuery(
    rootData.currentUser?.request,
    rootData.currentUser?.queryProps ?? {},
  );

  const user = useMemo(() => data?.User, [data?.User]);

  const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [identificationCodeDialogOpen, setIdentificationCodeDialogOpen] = useState(false);

  return (
    <>
      <Button
        variant="icon"
        className="w-fit"
        tooltip={user?.FullName}
        onPress={() => {
          setProfileDialogOpen(true);
          setMouseEntered(false);
          setIsFocused(false);
        }}
        onHoverStart={() => {
          setMouseEntered(true);
        }}
        onHoverEnd={() => {
          setMouseEntered(false);
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        onBlur={() => {
          setIsFocused(false);
        }}
      >
        <SvgIcon name="profile" />
      </Button>
      <MenuProfileDialog
        open={profileDialogOpen}
        setOpen={setProfileDialogOpen}
        user={user}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
        onOpenIdentificationCodeDialog={() => {
          setProfileDialogOpen(false);
          setIdentificationCodeDialogOpen(true);
        }}
      />
      <IdentificationCodeDialog
        open={identificationCodeDialogOpen}
        setOpen={setIdentificationCodeDialogOpen}
        user={user}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
      />
    </>
  );
};

type MenuProfileDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  user?: EVA.Core.LoggedInUserDto | undefined;
  setMouseEntered: Dispatch<SetStateAction<boolean>>;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
  onOpenIdentificationCodeDialog: () => void;
};

const MenuProfileDialog = ({
  onOpenIdentificationCodeDialog,
  open,
  setIsFocused,
  setMouseEntered,
  setOpen,
  user,
}: MenuProfileDialogProps) => {
  const intl = useIntl();

  const close = () => {
    setOpen(false);
    setMouseEntered(false);
    setIsFocused(false);
  };

  return (
    <Dialog PaperProps={{ className: "max-w-xs w-full" }} open={open} onClose={close}>
      <Card>
        <CardHeader
          title={intl.formatMessage({ id: "generic.label.profile", defaultMessage: "Profile" })}
        />

        <CardContent className="mt-4 flex flex-col items-center justify-center gap-4">
          <MenuProfileAvatar label={user?.FullName ?? ""} />

          <div className="flex flex-col items-center justify-center gap-1">
            <Text variant="h2">{user?.FullName ?? ""}</Text>
            <Text variant="body2">{user?.EmailAddress ?? ""}</Text>
          </div>

          <Separator className="mt-6" />

          <div className="flex w-full flex-col">
            <MenuLink
              icon={<SvgIcon color="#000" name="profile" />}
              label={intl.formatMessage({
                id: "generic.label.manage-profile",
                defaultMessage: "Manage profile",
              })}
              to={routeDefinitions.profile.path}
            />
            <MenuButton
              icon={<SvgIcon color="#000" name="pos" />}
              label={intl.formatMessage({
                id: "generic.label.identification-code",
                defaultMessage: "Identififcation code",
              })}
              onPress={onOpenIdentificationCodeDialog}
            />
            <MenuLink
              icon={<SvgIcon color="#000" name="sign-out" />}
              label={intl.formatMessage({
                id: "generic.label.sign-out",
                defaultMessage: "Sign out",
              })}
              to="/logout"
            />
          </div>
        </CardContent>
      </Card>
    </Dialog>
  );
};

const MenuProfileAvatar = ({ label }: { label: string }) => {
  const [initials, setInitials] = useState<string | undefined>();

  useEffect(() => {
    let newInitials: any = label ? label.match(/\b\w/g) || [] : undefined;

    newInitials = newInitials
      ? ((newInitials.shift() || "") + (newInitials.pop() || "")).toUpperCase()
      : undefined;

    setInitials(newInitials);
  }, [label]);

  return (
    <div className="flex h-20 w-20 items-center justify-center rounded-full bg-action-default font-sans text-3xl uppercase text-inverted">
      {initials}
    </div>
  );
};

const MenuLink = ({ icon, label, to }: { to: string; label: string; icon: JSX.Element }) => {
  return (
    <Link
      to={to}
      className={classNames(
        "flex h-10 items-center gap-2 px-2",
        "text-secondary no-underline outline-none hover:bg-surface-tertiary",
        "rounded-md border-0 focus-visible:ring-1 focus-visible:ring-[color:var(--color-primary-3)]",
      )}
    >
      <div className="flex items-center justify-center">{icon}</div>
      <span className="font-sans text-base font-normal leading-5 tracking-wide text-primary">
        {label}
      </span>
    </Link>
  );
};

const MenuButton = ({
  icon,
  label,
  onPress,
}: {
  label: string;
  onPress: () => void;
  icon: JSX.Element;
}) => {
  return (
    <Button
      variant="secondary"
      onPress={onPress}
      className={classNames(
        "flex h-10 w-full items-center justify-start gap-2 p-0 px-2 duration-0 focus-visible:shadow-transparent",
        "text-secondary no-underline outline-none hover:bg-surface-tertiary",
        "rounded-md border-0 focus-visible:ring-1 focus-visible:ring-[color:var(--color-primary-3)]",
      )}
    >
      <div className="flex items-center justify-center">{icon}</div>
      <span className="font-sans text-base font-normal leading-5 tracking-wide text-primary">
        {label}
      </span>
    </Button>
  );
};
