import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const productsState = builders.buildServiceState({
  key: "Composite:Products",
  service: Core.SearchProducts,
  allowEmptyRequest: false,
  requireLoggedIn: true,
});

export const productsListSelector = selector({
  key: "Composite:Products:List",
  get: ({ get }) => {
    const response = get(productsState.response);
    return response?.Products;
  },
});

export const productsListTotalSelector = selector({
  key: "Composite:Products:List:Total",
  get: ({ get }) => {
    const response = get(productsState.response);
    return response?.Total;
  },
});
