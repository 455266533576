import { listPaymentTypesQueryKeys, useListPaymentTypesQuery } from "models/payment-types";
import { ONE_SECOND } from "util/base-values";

export const useListPaymentTypesByIds = (ids?: number[]) => {
  const { data: paymentTypes, isFetching } = useListPaymentTypesQuery(
    !!ids && ids?.length > 0
      ? {
          PageConfig: {
            Filter: {
              IDs: ids,
            },
          },
        }
      : undefined,
    {
      loaderKey: listPaymentTypesQueryKeys.withKey([ids]),
      staleTime: ONE_SECOND,
      cacheTime: ONE_SECOND,
    },
  );

  return {
    data:
      ids?.length && paymentTypes?.Result.Page && paymentTypes?.Result.Page.length
        ? paymentTypes?.Result.Page
        : undefined,
    isLoading: isFetching,
  };
};
