import LinkButton from "components/suite-ui/link-button";

interface ICollapseButton {
  state: boolean;
  collapsedLabel: string;
  openLabel: string;
  onClick: () => void;
}
const CollapseButton = (props: ICollapseButton) => {
  const { collapsedLabel, onClick, openLabel, state } = props;

  return (
    <div className="my-5 flex justify-end px-5">
      <LinkButton variant="body2" onClick={onClick} type="button">
        {state ? collapsedLabel : openLabel}
      </LinkButton>
    </div>
  );
};

export default CollapseButton;
