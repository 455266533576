import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import IconButton from "components/suite-ui/icon-button";
import Tooltip from "components/ui/tooltip";

export interface IEndIcon {
  enableClearIcon?: boolean;
  value?: any;
  setValue?: (newValue?: any) => void;
  endIcon?: React.ReactNode;
}

export const EndIcon = ({ enableClearIcon, endIcon, setValue, value }: IEndIcon) => {
  const intl = useIntl();

  const displayClearIcon = useMemo<boolean>(
    () => !!enableClearIcon && !!value && !!setValue && !endIcon,
    [enableClearIcon, endIcon, value, setValue],
  );

  return (
    <>
      {displayClearIcon ? (
        <Tooltip
          title={intl.formatMessage({
            id: "generic.label.clear",
            defaultMessage: "Clear",
          })}
        >
          <IconButton size="small" onClick={() => setValue?.(undefined)}>
            <SvgIcon name="alert-error" className="h-5 w-5" />
          </IconButton>
        </Tooltip>
      ) : (
        endIcon
      )}
    </>
  );
};
