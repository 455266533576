import { Dispatch, SetStateAction, useMemo } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup, TableColumnDef } from "@new-black/lyra";
import { isNil } from "lodash";

import { YesNoString } from "components/ui/boolean-string";
import {
  formatCustomFieldValueDisplay,
  getCustomFieldValueFromEVACustomFieldValue,
} from "util/custom-fields";

import { convertEnumValuesTypeToKeyObj } from "../../helpers";
import useCustomFieldByID from "../../use-custom-field-by-id";
import {
  CustomFieldOptionsDeleteAction,
  CustomFieldOptionsEditAction,
} from "../helpers/custom-field-settings-table-actions";
import { UserTypeCell } from "../helpers/user-type-cell";

import useCustomFieldMinMaxField from "./use-custom-field-min-max-field";

interface IUseCustomFieldSettingsTableColumns {
  customFieldId: number | undefined;
  setIsAddEditOpen: Dispatch<SetStateAction<boolean>>;
  setOptionToEdit: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
  setOptionToDelete: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
}

const useCustomFieldSettingsTableColumns = ({
  customFieldId,
  setIsAddEditOpen,
  setOptionToDelete,
  setOptionToEdit,
}: IUseCustomFieldSettingsTableColumns) => {
  const intl = useIntl();

  const { values } = useCustomFieldByID(customFieldId);
  const { maxField, minField } = useCustomFieldMinMaxField(values?.DataType);

  const tableColumns = useMemo<
    TableColumnDef<EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto>[]
  >(
    () => [
      {
        header: intl.formatMessage({
          id: "generic.label.organization-unit-set",
          defaultMessage: "Organization unit set",
        }),
        id: "OrganizationUnitSet.Name",
        accessorKey: "OrganizationUnitSet",
        cell: ({ getValue }) => <span>{getValue()?.Name}</span>,
      },
      {
        header: intl.formatMessage({
          id: "custom-field.options.headers.visible-by-user-types",
          defaultMessage: "Visible by user types",
        }),
        accessorKey: "VisibleByUserTypes",
        cellType: "badge",
        id: "VisibleByUserTypes",
        cell: ({ getValue }) => {
          const value = getValue();
          return isNil(value) ? null : <UserTypeCell value={value} />;
        },
      },
      {
        header: intl.formatMessage({
          id: "custom-field.options.headers.editable-by-user-types",
          defaultMessage: "Editable by user types",
        }),
        accessorKey: "EditableByUserTypes",
        cellType: "badge",
        id: "EditableByUserTypes",
        cell: ({ getValue }) => {
          const value = getValue();
          return isNil(value) ? null : <UserTypeCell value={value} />;
        },
      },

      {
        header: intl.formatMessage({
          id: "generic.label.minimum",
          defaultMessage: "Minimum",
        }),
        id: "Minimum",
        cell: ({ row }) => {
          if (!minField || !row.original[minField]) {
            return null;
          }
          if (minField === "MinimumDate") {
            return (
              <>
                {[
                  intl.formatDate(row.original[minField]),
                  ...(values?.DataType === 6 ? [intl.formatTime(row.original[minField])] : []),
                ].join(" ")}
              </>
            );
          }
          return <>{row.original[minField]}</>;
        },
      },
      {
        header: intl.formatMessage({
          id: "generic.label.maximum",
          defaultMessage: "Maximum",
        }),
        id: "Maximum",
        cell: ({ row }) => {
          if (!maxField || !row.original[maxField]) {
            return null;
          }
          if (maxField === "MaximumDate") {
            return (
              <>
                {[
                  intl.formatDate(row.original[maxField]),
                  ...(values?.DataType === 6 ? [intl.formatTime(row.original[maxField])] : []),
                ].join(" ")}
              </>
            );
          }
          return <>{row.original[maxField]}</>;
        },
      },

      {
        header: intl.formatMessage({
          id: "generic.label.required",
          defaultMessage: "Required",
        }),
        accessorKey: "IsRequired",
        id: "IsRequired",
        width: "5%",
        cell: ({ getValue }) => <YesNoString value={getValue() ?? false} />,
      },

      {
        header: intl.formatMessage({
          id: "generic.label.default-value",
          defaultMessage: "Default value",
        }),
        accessorKey: "CustomFieldDefaultValue",
        id: "CustomFieldDefaultValue",
        width: "12%",
        cell: ({ getValue, row }) => {
          const value = getValue();
          const defaultValue = values?.ID
            ? formatCustomFieldValueDisplay(intl)(
                {
                  ...values,
                  ID: values?.ID,
                  Name: values?.Name,
                  DataType: values.DataType as number,
                  EnumValues: values.EnumValues
                    ? convertEnumValuesTypeToKeyObj(values.EnumValues)
                    : {},
                  Options: {
                    ...row.original,
                    VisibleByUserTypes: row.original.VisibleByUserTypes as number | undefined,
                    EditableByUserTypes: row.original.EditableByUserTypes as number | undefined,
                  },
                },
                getCustomFieldValueFromEVACustomFieldValue(value),
              )
            : undefined;

          return defaultValue && defaultValue !== "-" ? <span>{defaultValue}</span> : null;
        },
      },

      {
        header: intl.formatMessage({
          id: "generic.label.actions",
          defaultMessage: "Actions",
        }),
        id: "Actions",
        align: "end",
        cellType: "button",
        cell: ({ row }) => (
          <ButtonGroup>
            <CustomFieldOptionsEditAction
              handleEdit={() => {
                setOptionToEdit(row.original);
                setIsAddEditOpen(true);
              }}
            />
            <CustomFieldOptionsDeleteAction handleDelete={() => setOptionToDelete(row.original)} />
          </ButtonGroup>
        ),
      },
    ],
    [intl, maxField, minField, setIsAddEditOpen, setOptionToDelete, setOptionToEdit, values],
  );

  return tableColumns;
};

export default useCustomFieldSettingsTableColumns;
