import { Admin } from "@springtree/eva-services-admin";

import { intlAccessor } from "util/intl-accessor";
import { mutate } from "util/mutate";
import { mutatePromiseLike } from "util/mutate-promise-like";

const getWorkspaceByID = mutate({ service: Admin.GetWorkspaceByID, disabledNotifications: true });
const getSharedWorkspaceByID = mutate({
  service: Admin.GetSharedWorkspaceByID,
  disabledNotifications: true,
});
const createWorkspace = (disabledNotifications?: boolean) =>
  mutate({
    service: Admin.CreateWorkspace,
    messages: {
      successMessage: intlAccessor.formatMessage({
        id: "workspaces.add-success",
        defaultMessage: "Workspace added successfully.",
      }),
    },
    disabledNotifications,
  });
const createSharedWorkspace = mutate({
  service: Admin.CreateSharedWorkspace,
  messages: {
    successMessage: intlAccessor.formatMessage({
      id: "workspaces.shared.add-success",
      defaultMessage: "Shared workspace added successfully.",
    }),
  },
});
const updateWorkspace = mutate({
  service: Admin.UpdateWorkspace,
  messages: {
    successMessage: intlAccessor.formatMessage({
      id: "workspaces.save-success",
      defaultMessage: "Workspace saved successfully.",
    }),
  },
});
const updateSharedWorkspace = mutate({
  service: Admin.UpdateSharedWorkspace,
});
const setActiveWorkspace = (disabledNotifications?: boolean) =>
  mutate({
    service: Admin.SetActiveWorkspace,
    messages: {
      successMessage: intlAccessor.formatMessage({
        id: "workspaces.changed-success",
        defaultMessage: "Current workspace has changed successfully.",
      }),
    },
    disabledNotifications,
  });
const deleteWorkspace = mutate({
  service: Admin.DeleteWorkspace,
  messages: {
    successMessage: intlAccessor.formatMessage({
      id: "workspaces.delete-success",
      defaultMessage: "Workspace deleted successfully.",
    }),
  },
});
const deleteSharedWorkspace = mutate({
  service: Admin.DeleteSharedWorkspace,
  messages: {
    successMessage: intlAccessor.formatMessage({
      id: "workspaces.delete-success",
      defaultMessage: "Workspace deleted successfully.",
    }),
  },
});

const removeRolesFromSharedWorkspace = mutatePromiseLike({
  service: Admin.RemoveRolesFromSharedWorkspace,
  disabledNotifications: true,
});
const addRolesToSharedWorkspace = mutatePromiseLike({
  service: Admin.AddRolesToSharedWorkspace,
  disabledNotifications: true,
});

const setDefaultSharedWorkspaceForRoles = (disabledNotifications?: boolean) =>
  mutate({
    service: Admin.SetDefaultSharedWorkspaceForRoles,
    disabledNotifications,
  });

const updateWorkspaceRoles = async (workspaceID: number, roleIDs: number[]) => {
  try {
    const removeRolesResponse = await removeRolesFromSharedWorkspace({
      ID: workspaceID,
      RemoveAllRoles: true,
    });

    const addRolesResponse = await addRolesToSharedWorkspace({
      ID: workspaceID,
      RoleIDs: roleIDs,
    });

    return { removeRolesResponse, addRolesResponse };
  } catch (e) {
    const response = (e as { response: EVA.Core.EmptyResponseMessage | undefined })?.response;

    return (
      response?.Error?.Message ??
      intlAccessor.formatMessage({
        id: "generic.message.something-went-wrong",
        defaultMessage: "Something went wrong.",
      })
    );
  }
};

/**
 * Provides management services for working with workspaces in EVA.
 */
const useManageWorkspace = () => {
  return {
    createWorkspace,
    createSharedWorkspace,
    updateWorkspaceRoles,
    updateSharedWorkspace,
    updateWorkspace,
    deleteWorkspace,
    deleteSharedWorkspace,
    setActiveWorkspace,
    setDefaultSharedWorkspaceForRoles,
    getWorkspaceByID,
    getSharedWorkspaceByID,
  };
};
export default useManageWorkspace;
