import { useMemo } from "react";
import { useIntl } from "react-intl";

import { KeyValueRow } from "@new-black/lyra";
import { state } from "@springtree/eva-sdk-react-recoil";
import classNames from "classnames";
import { useRecoilValue } from "recoil";
import { z } from "zod";
import { zfd } from "zod-form-data";

import Grid from "components/suite-ui/grid";
import Text from "components/suite-ui/text";

import { IProductInformationProps } from "../product-information.types";

const productPropertiesSchema = zfd.json(
  z.object({
    "EVA-Admin": z.array(z.string()).optional(),
  }),
);

const ProductDetails = ({ product, variant }: IProductInformationProps) => {
  const intl = useIntl();

  const appConfig = useRecoilValue(state.core.applicationConfigurationState);

  const propertiesConfig = useMemo(
    () => appConfig?.Configuration?.["App:ProductDetail:Properties"],
    [appConfig?.Configuration],
  );

  const properties = useMemo(() => {
    const parsedPropertiesConfig = productPropertiesSchema.safeParse(propertiesConfig);
    if (!parsedPropertiesConfig.success) {
      return [];
    }
    return parsedPropertiesConfig.data?.["EVA-Admin"];
  }, [propertiesConfig]);

  if (variant === "lyra") {
    return (
      <>
        {properties?.map((prop: string) => (
          <KeyValueRow key={prop} label={prop}>
            {(product as any)?.[prop]}
          </KeyValueRow>
        ))}
      </>
    );
  }

  return (
    <Grid container direction="column">
      {properties?.map((prop: string, index: number) => (
        <Grid item key={prop}>
          <div className={classNames(index < properties?.length - 1 ? "mb-5" : "mb-0")}>
            <Text variant="body2">{prop}</Text>
            <Text variant="body1">
              {(product as any)?.[prop] ??
                intl.formatMessage({
                  id: "generic.label.no-value",
                  defaultMessage: "No value",
                })}
            </Text>
          </div>
        </Grid>
      ))}
    </Grid>
  );
};

export default ProductDetails;
