import { useCallback, useState } from "react";

import { Core } from "@springtree/eva-services-core";

import useCallService from "./suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "./suite-react-hooks/use-handle-error-response";

const useAttachBlobToOrder = () => {
  const [isAttachBlobToOrderLoading, setIsAttachBlobToOrderLoading] = useState(false);
  const handleError = useHandleErrorResponse();

  const { callService: attachBlobToOrderMutation } = useCallService({
    service: Core.AttachBlobToOrder,
    disableNotifications: true,
    options: {
      onError: (error) => {
        setIsAttachBlobToOrderLoading(false);
        if (error) {
          handleError(error, "Core.AttachBlobToOrder");
        }
      },
      onSuccess: () => {
        setIsAttachBlobToOrderLoading(false);
      },
    },
  });

  const attachBlobToOrder = useCallback(
    (name: string, blobId: string, orderId: number, shipmentId?: number) => {
      setIsAttachBlobToOrderLoading(true);
      return attachBlobToOrderMutation({
        Name: name,
        BlobID: blobId,
        OrderID: orderId,
        ShipmentID: shipmentId,
      });
    },
    [attachBlobToOrderMutation],
  );

  return { attachBlobToOrder, isAttachBlobToOrderLoading };
};

export default useAttachBlobToOrder;
