import { Dialog } from "@material-ui/core";

import { TLyraUploadModalProps } from "./lyra-upload-modal.types";
import LyraUploadModalContent from "./lyra-upload-modal-content";

const LyraUploadModal = ({ onClose, open, ...props }: TLyraUploadModalProps) => (
  <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ className: "max-w-[640px]" }}>
    <LyraUploadModalContent onClose={onClose} {...props} />
  </Dialog>
);

export default LyraUploadModal;
