import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";

import Grid from "../grid";
import Select from "../select";

import { Container, Item, ItemText, Panel } from "./table-pagination-helper-components";
import type { ITableUncountablePaginationProps } from "./table-pagination-types";

const TableUncountablePagination = ({
  hidePageOptionsSelect,
  limit,
  onNext,
  onPrevious,
  pageOptionsMenuItems,
  paginationTableStrings,
  setLimit,
  showNext,
  showPrevious,
}: ITableUncountablePaginationProps) => {
  const intl = useIntl();

  return (
    <Panel>
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Container>
            {!hidePageOptionsSelect ? (
              <Item>
                <div>
                  <ItemText>
                    {paginationTableStrings.rowsPerPage ?? (
                      <span>
                        <FormattedMessage
                          id="generic.label.rows-per-page"
                          defaultMessage="rows per page"
                        />
                        :
                      </span>
                    )}
                  </ItemText>
                </div>
                <div>
                  {pageOptionsMenuItems ? (
                    <Select
                      name="selectRowSize"
                      items={pageOptionsMenuItems ?? []}
                      small
                      value={limit}
                      onChange={(e) => {
                        if (setLimit && e.target.value) {
                          setLimit(Number.parseInt(e.target.value as string, 10));
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Item>
            ) : null}
            <Item>
              <ButtonGroup>
                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.previousPage ??
                    intl.formatMessage({
                      id: "generic.label.previous-page",
                      defaultMessage: "Previous page",
                    })
                  }
                  onPress={onPrevious}
                  isDisabled={!showPrevious}
                >
                  <SvgIcon name="chevron-left" />
                </Button>

                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.nextPage ??
                    intl.formatMessage({
                      id: "generic.label.next-page",
                      defaultMessage: "Next page",
                    })
                  }
                  onPress={onNext}
                  isDisabled={!showNext}
                >
                  <SvgIcon name="chevron-right" />
                </Button>
              </ButtonGroup>
            </Item>
          </Container>
        </Grid>
      </Grid>
    </Panel>
  );
};

export default TableUncountablePagination;
