import { OrchestrationOperatorTypes } from "types/translations-enums";

export const orchestrationOperatorTypesDefaultMessages: Record<OrchestrationOperatorTypes, string> =
  {
    [OrchestrationOperatorTypes.Equals]: "equals",
    [OrchestrationOperatorTypes.NotEquals]: "does not equal",
    [OrchestrationOperatorTypes.In]: "is in",
    [OrchestrationOperatorTypes.GreaterThan]: "is greater than",
    [OrchestrationOperatorTypes.LessThan]: "is less than",
    [OrchestrationOperatorTypes.NotIn]: "is not in",
    [OrchestrationOperatorTypes.GreaterThanOrEquals]: "is greater than or equal to",
    [OrchestrationOperatorTypes.LessThanOrEquals]: "is less than or equal to",
  };
