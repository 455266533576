/* eslint-disable max-len */
export const MonitorsInnerIcon = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#A6DC07" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.0427 13.5875H37.0654L37.0634 13.5875C37.0437 13.5873 36.8967 13.5863 36.7507 13.5977C36.5673 13.612 36.179 13.6569 35.7477 13.8673C35.2432 14.1133 34.8331 14.506 34.576 14.9889C34.3563 15.4018 34.3094 15.7735 34.2944 15.9491C34.2825 16.0888 34.2836 16.2296 34.2838 16.2484L34.2838 16.2504V34.1847L34.2838 34.1867C34.2836 34.2055 34.2825 34.3463 34.2944 34.4861C34.3094 34.6616 34.3563 35.0333 34.576 35.4462C34.8331 35.9292 35.2432 36.3218 35.7477 36.5679C36.179 36.7783 36.5673 36.8231 36.7507 36.8375C36.8967 36.8489 37.0437 36.8478 37.0634 36.8476L37.0654 36.8476H39.0427L39.0448 36.8476C39.0644 36.8478 39.2115 36.8489 39.3575 36.8375C39.5408 36.8231 39.9291 36.7783 40.3604 36.5679C40.8649 36.3218 41.2751 35.9292 41.5321 35.4462C41.7519 35.0333 41.7987 34.6616 41.8137 34.4861C41.8256 34.3463 41.8245 34.2055 41.8244 34.1867L41.8243 34.1847V16.2504L41.8244 16.2484C41.8245 16.2296 41.8256 16.0888 41.8137 15.9491C41.7987 15.7735 41.7519 15.4018 41.5321 14.9889C41.2751 14.506 40.8649 14.1133 40.3604 13.8673C39.9291 13.6569 39.5408 13.612 39.3575 13.5977C39.2115 13.5863 39.0644 13.5873 39.0448 13.5875L39.0427 13.5875ZM36.7973 34.2073C36.7975 34.2823 36.7989 34.3225 36.8156 34.3538C36.8316 34.384 36.8573 34.4085 36.8888 34.4239C36.9247 34.4414 36.9716 34.4414 37.0654 34.4414H39.0427C39.1366 34.4414 39.1835 34.4414 39.2193 34.4239C39.2509 34.4085 39.2765 34.384 39.2926 34.3538C39.3092 34.3225 39.3107 34.2823 39.3108 34.2072V16.2279C39.3107 16.1528 39.3092 16.1126 39.2926 16.0813C39.2765 16.0511 39.2509 16.0266 39.2193 16.0112C39.1835 15.9937 39.1366 15.9937 39.0427 15.9937H37.0654C36.9716 15.9937 36.9247 15.9937 36.8888 16.0112C36.8573 16.0266 36.8316 16.0511 36.8156 16.0813C36.7989 16.1126 36.7975 16.1528 36.7973 16.2279V34.2073Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9346 20.9322H16.9573L16.9552 20.9322C16.9356 20.9321 16.7885 20.9309 16.6425 20.9429C16.4592 20.9579 16.0709 21.0047 15.6395 21.2245C15.1351 21.4815 14.7249 21.8917 14.4679 22.3961C14.2481 22.8274 14.2013 23.2157 14.1863 23.3991C14.1744 23.5451 14.1755 23.6922 14.1756 23.7118L14.1756 23.7139V34.0695L14.1756 34.0716C14.1755 34.0912 14.1744 34.2383 14.1863 34.3843C14.2013 34.5677 14.2481 34.9559 14.4679 35.3873C14.7249 35.8917 15.1351 36.3019 15.6395 36.5589C16.0709 36.7787 16.4591 36.8255 16.6425 36.8405C16.7885 36.8525 16.9356 36.8513 16.9552 36.8512L16.9573 36.8512H18.9346L18.9366 36.8512C18.9562 36.8513 19.1033 36.8525 19.2493 36.8405C19.4327 36.8255 19.821 36.7787 20.2523 36.5589C20.7568 36.3019 21.1669 35.8917 21.424 35.3873C21.6437 34.9559 21.6906 34.5677 21.7056 34.3843C21.7175 34.2383 21.7164 34.0912 21.7162 34.0716L21.7162 34.0695V23.7139L21.7162 23.7118C21.7164 23.692 21.7175 23.545 21.7056 23.3991C21.6906 23.2157 21.6437 22.8274 21.424 22.3961C21.1669 21.8917 20.7568 21.4815 20.2523 21.2245C19.821 21.0047 19.4327 20.9579 19.2493 20.9429C19.1033 20.9309 18.9562 20.9321 18.9366 20.9322L18.9346 20.9322ZM16.6892 34.093C16.6893 34.1715 16.6908 34.2134 16.7074 34.2462C16.7235 34.2777 16.7491 34.3033 16.7807 34.3194C16.8165 34.3376 16.8634 34.3376 16.9573 34.3376H18.9346C19.0284 34.3376 19.0753 34.3376 19.1112 34.3194C19.1427 34.3033 19.1683 34.2777 19.1844 34.2462C19.2011 34.2134 19.2025 34.1715 19.2027 34.093V23.6904C19.2025 23.6119 19.2011 23.57 19.1844 23.5372C19.1683 23.5057 19.1427 23.4801 19.1112 23.464C19.0753 23.4458 19.0284 23.4458 18.9346 23.4458H16.9573C16.8634 23.4458 16.8165 23.4458 16.7807 23.464C16.7491 23.4801 16.7235 23.5057 16.7074 23.5372C16.6908 23.57 16.6893 23.6119 16.6892 23.6904L16.6892 23.7139L16.6892 34.093Z"
      fill="white"
    />
    <path
      d="M13.7568 41.8784C13.0627 41.8784 12.5 41.3157 12.5 40.6217C12.5 39.9276 13.0627 39.3649 13.7568 39.3649H42.2432C42.9373 39.3649 43.5 39.9276 43.5 40.6217C43.5 41.3157 42.9373 41.8784 42.2432 41.8784H13.7568Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0115 25.1222H28.9888L28.9908 25.1222C29.0104 25.122 29.1575 25.1209 29.3035 25.1328C29.4869 25.1478 29.8752 25.1946 30.3065 25.4144C30.811 25.6714 31.2211 26.0816 31.4782 26.5861C31.6979 27.0174 31.7448 27.4057 31.7598 27.5891C31.7717 27.735 31.7706 27.882 31.7704 27.9017L31.7704 27.9038V34.0703L31.7704 34.0724C31.7706 34.092 31.7717 34.2391 31.7598 34.385C31.7448 34.5684 31.6979 34.9567 31.4782 35.388C31.2211 35.8925 30.811 36.3026 30.3065 36.5597C29.8752 36.7795 29.4869 36.8263 29.3035 36.8413C29.1575 36.8532 29.0104 36.8521 28.9908 36.8519L28.9888 36.8519H27.0115L27.0094 36.8519C26.9898 36.8521 26.8427 36.8532 26.6967 36.8413C26.5133 36.8263 26.1251 36.7795 25.6937 36.5597C25.1893 36.3026 24.7791 35.8925 24.5221 35.388C24.3023 34.9567 24.2555 34.5684 24.2405 34.385C24.2285 34.239 24.2297 34.092 24.2298 34.0724L24.2298 34.0703V27.9038L24.2298 27.9017C24.2297 27.8821 24.2285 27.735 24.2405 27.5891C24.2555 27.4057 24.3023 27.0174 24.5221 26.5861C24.7791 26.0816 25.1893 25.6714 25.6937 25.4144C26.1251 25.1946 26.5133 25.1478 26.6967 25.1328C26.8427 25.1209 26.9898 25.122 27.0094 25.1222L27.0115 25.1222ZM26.7434 34.0938V27.8803C26.7435 27.8019 26.745 27.7599 26.7616 27.7272C26.7777 27.6957 26.8033 27.67 26.8349 27.654C26.8707 27.6357 26.9176 27.6357 27.0115 27.6357H28.9888C29.0826 27.6357 29.1295 27.6357 29.1654 27.654C29.1969 27.67 29.2225 27.6957 29.2386 27.7272C29.2553 27.7599 29.2567 27.8019 29.2569 27.8803V34.0938C29.2567 34.1722 29.2553 34.2142 29.2386 34.2469C29.2225 34.2784 29.1969 34.3041 29.1654 34.3201C29.1295 34.3384 29.0826 34.3384 28.9888 34.3384H27.0115C26.9176 34.3384 26.8707 34.3384 26.8349 34.3201C26.8033 34.3041 26.7777 34.2784 26.7616 34.2469C26.745 34.2142 26.7435 34.1722 26.7434 34.0938Z"
      fill="white"
    />
  </>
);
