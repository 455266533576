import { CardHeader, TableProps } from "@new-black/lyra";

import ErrorBoundary from "components/suite-ui/error-boundary";
import Text from "components/suite-ui/text";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";
import ProductFilterList from "../list";
import { DefaultProductFilterActions } from "../product-filter-actions";
import { ProductFilterProvider } from "../product-filter-context";
import ProductFilterModal from "../product-filter-modal";
import { DefaultProductFilterRowActions } from "../product-filter-row-actions";
import { EvaFilterModel } from "../types";

export interface IProductFilterTableProps {
  /** Current product property filters */
  productFilter?: EvaFilterModel;
  /** Callback fired when the filter is modified */
  updateProductFilter?: (filters?: EvaFilterModel) => void;
  /** Async callback fired when the filter is modified.*/
  updateProductFilterAsync?: (filters?: EvaFilterModel) => Promise<any>;
  /** Optional error message related to the product filter. If set, a warning icon is displayed in the table and the error is shown when hovering it. */
  error?: string;
  /** If true, the add action will be hidden */
  disableAdd?: boolean;
  /** If true, the edit action will be hidden */
  disableEdit?: boolean;
  /** If true, the delete action will be hidden */
  disableDelete?: boolean;
  /** Image to display as a fallback when the products don't have a primary image. */
  placeholderImageUrl?: string;
  /** Title of the table */
  title: string;
  /** className used on the title container to customize the styling  */
  titleClassName?: string;
  /** Table options */
  tableOptions?: TableProps<IEVAFilterArrayItem>["options"];
  inCard?: boolean;
}

type VariantProps =
  | { variant?: "material" }
  | {
      variant: "lyra";
      /** If `true`, the filter property cannot be changed retroactively when editing a filter (table row)
       *
       * This is needed because if the property is changed, then it will become a new line in the table,
       * so the user is technically no longer editing the same line, which might be confusing, as after saving, the old line will still be in the table.
       * This option prevents the user from changing the property when editing a filter, which is more in line with the expected behavior.
       */
      preventChangingPropertyOnEdit?: boolean;
      /** If `true`, the user will not be able to delete the last filter in the table
       *
       * This is useful when you want to ensure that there is always at least one filter in the table.
       */
      preventDeletingLastFilter?: boolean;
    };

/**
 * Component used to manage a product search filter. Renders a list of filters as well as actions that allow managing the list and previewing the search result.
 */
const ProductFilterTable = ({
  disableAdd,
  disableDelete,
  disableEdit,
  error,
  inCard,
  placeholderImageUrl,
  productFilter,
  tableOptions,
  title,
  titleClassName,
  updateProductFilter,
  updateProductFilterAsync,
  ...props
}: IProductFilterTableProps & VariantProps) => (
  <ErrorBoundary>
    <ProductFilterProvider
      productFilter={productFilter}
      updateProductFilter={updateProductFilter}
      updateProductFilterAsync={updateProductFilterAsync}
      placeholderImageUrl={placeholderImageUrl}
      disableEdit={disableEdit}
      disableDelete={disableDelete}
      disableAdd={disableAdd}
      maximumDisplayedValuesInList={5}
      error={error}
    >
      {inCard ? (
        <CardHeader
          title={title}
          actions={
            <ErrorBoundary>
              <DefaultProductFilterActions variant={props.variant} />
            </ErrorBoundary>
          }
        />
      ) : (
        <div className="p-4 pb-3">
          <div className="flex items-center justify-between">
            <Text variant="h3" className={titleClassName}>
              {title}
            </Text>

            <ErrorBoundary>
              <DefaultProductFilterActions variant={props.variant} />
            </ErrorBoundary>
          </div>
        </div>
      )}
      <ErrorBoundary>
        <ProductFilterList
          customRowActions={
            disableDelete && disableEdit
              ? undefined
              : (rowData, isLastRow) =>
                  DefaultProductFilterRowActions({
                    rowData,
                    deleteModalVariant: "lyra",
                    disableDelete:
                      props.variant === "lyra" && props.preventDeletingLastFilter && isLastRow,
                  })
          }
          options={tableOptions}
        />
      </ErrorBoundary>
      {props.variant === "lyra" ? (
        <ProductFilterModal
          variant={props.variant}
          readOnlyPropertyOnEdit={props.preventChangingPropertyOnEdit}
        />
      ) : (
        <ProductFilterModal variant={props.variant} />
      )}
    </ProductFilterProvider>
  </ErrorBoundary>
);

export default ProductFilterTable;
