import { useMemo } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";

export const useAppSetting = (key: string, fallbackValue?: any) => {
  const applicationConfiguration = hooks.useGetState(
    state.currentUser.currentUserApplicationConfigurationState.response,
  );
  const isLoading = hooks.useIsLoading({
    state: state.currentUser.currentUserApplicationConfigurationState.response,
  });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !applicationConfiguration,
    [applicationConfiguration, isLoading],
  );

  const appSettingValue = useMemo(() => {
    const config = applicationConfiguration?.Configuration;
    if (config) {
      if (Object.keys(config).includes(key)) {
        return config[key];
      }
    }
    return fallbackValue ?? undefined;
  }, [applicationConfiguration?.Configuration, fallbackValue, key]);

  return {
    appSettingValue,
    isLoading,
    isLoadingWithoutPreviousResponse,
  };
};
