import { useEffect, useMemo, useState } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { IServiceFamilyState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-family-state";
import { IServiceState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-state";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import ky from "ky";
import { SerializableParam } from "recoil";

export interface IServiceFamilyStateArgs<T extends IEvaServiceDefinition> {
  serviceFamilyState: IServiceFamilyState<T>;
  parameter: SerializableParam;
  serviceState?: never;
}

export interface IServiceStateArgs<T extends IEvaServiceDefinition> {
  serviceState: IServiceState<T>;
  serviceFamilyState?: never;
  parameter?: never;
}

export const useServiceError = <T extends IEvaServiceDefinition>(
  props: IServiceFamilyStateArgs<T> | IServiceStateArgs<T>,
) => {
  const errorState = props.serviceState
    ? props.serviceState.error
    : props?.serviceFamilyState?.error(props.parameter);
  const serviceError = hooks.useGetState(errorState);

  // KY HTTP Error
  const [httpError, setHttpError] = useState<T["response"] | undefined>();

  useEffect(() => {
    if (serviceError && serviceError.name === "HTTPError") {
      // An HTTP response error (non 2xx/3xx) or
      // an Error/Authentication failure was detected in the payload
      const KyHttpError: ky.HTTPError = serviceError as ky.HTTPError;
      KyHttpError.response
        .clone()
        .json()
        .then((kyError: T["response"]) => setHttpError(kyError));
    }
  }, [serviceError]);

  // Fetch abort controller was used
  const abortError = useMemo(
    () => !!serviceError && serviceError.name === "AbortError",
    [serviceError],
  );

  // Any other error
  const otherError = useMemo(() => serviceError, [serviceError]);

  const error = useMemo(() => {
    if (serviceError) {
      if (serviceError.name === "HTTPError") {
        return httpError;
      }
      if (abortError) {
        return "Abort";
      }
      return otherError;
    }
    return undefined;
  }, [abortError, httpError, otherError, serviceError]);

  return error;
};

export type UseServiceErrorResult<T extends IEvaServiceDefinition> = ReturnType<
  typeof useServiceError<T>
>;
