import { FormattedMessage } from "react-intl";

const NoResultsMenuItem = () => (
  <div className="w-[319px] items-center pb-2 pt-4 text-center">
    <FormattedMessage
      id="organization-unit-selector.no-results.label"
      defaultMessage="No results"
    />
  </div>
);

export default NoResultsMenuItem;
