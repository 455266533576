import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getListStationsForOrganizationUnitLoaderQuery,
  serviceQuery: getListStationsForOrganizationUnitQuery,
  serviceQueryKeys: getListStationsForOrganizationUnitQueryKeys,
  useServiceQueryHook: useGetListStationsForOrganizationUnitQuery,
} = createServiceQuery(Core.ListStationsForOrganizationUnit);

export const {
  serviceLoaderQuery: getOrganizationUnitDetailedLoaderQuery,
  serviceQuery: getOrganizationUnitDetailedQuery,
  serviceQueryKeys: getOrganizationUnitDetailedQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitDetailedQuery,
  useServiceQueryWithRequest: useGetOrganizationUnitDetailedQueryWithRequest,
} = createServiceQuery(Core.GetOrganizationUnitDetailed, true);

export const {
  serviceLoaderQuery: getNonEmptyOrganizationUnitDetailedLoaderQuery,
  serviceQuery: getNonEmptyOrganizationUnitDetailedQuery,
  serviceQueryKeys: getNonEmptyOrganizationUnitDetailedQueryKeys,
  useServiceQueryHook: useGetNonEmptyOrganizationUnitDetailedQuery,
} = createServiceQuery(Core.GetOrganizationUnitDetailed, true);

export const {
  serviceLoaderQuery: getOrganizationUnitSummaryLoaderQuery,
  serviceQuery: getOrganizationUnitSummaryQuery,
  serviceQueryKeys: getOrganizationUnitSummaryQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitSummaryQuery,
} = createServiceQuery(Core.GetOrganizationUnitSummary, true);

export const {
  serviceLoaderQuery: getOrganizationUnitNotesLoaderQuery,
  serviceQuery: getOrganizationUnitNotesQuery,
  serviceQueryKeys: getOrganizationUnitNotesQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitNotesQuery,
} = createServiceQuery(Core.GetOrganizationUnitNotes, true);

export const {
  serviceLoaderQuery: getOrganizationUnitSettingsLoaderQuery,
  serviceQuery: getOrganizationUnitSettingsQuery,
  serviceQueryKeys: getOrganizationUnitSettingsQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitSettingsQuery,
} = createServiceQuery(Core.GetOrganizationUnitSettings, true);

export const {
  serviceLoaderQuery: getOrganizationUnitAppSettingsLoaderQuery,
  serviceQuery: getOrganizationUnitAppSettingsQuery,
  serviceQueryKeys: getOrganizationUnitAppSettingsQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitAppSettingsQuery,
} = createServiceQuery(CoreManagement.GetAppSettingsForOrganizationUnit);

export const {
  serviceLoaderQuery: getAddressRequirementsLoaderQuery,
  serviceQuery: getAddressRequirementsQuery,
  serviceQueryKeys: getAddressRequirementsQueryKeys,
  useServiceQueryWithRequest: useGetAddressRequirementsQueryWithRequest,
} = createServiceQuery(Core.GetAddressRequirements, true);

export const {
  serviceLoaderQuery: listOrganizationUnitLanguagesLoaderQuery,
  serviceQuery: listOrganizationUnitLanguagesQuery,
  serviceQueryKeys: listOrganizationUnitLanguagesQueryKeys,
  useServiceQueryWithRequest: useListOrganizationUnitLanguagesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListOrganizationUnitLanguages);

export const {
  serviceLoaderQuery: ListOrganizationUnitSummariesLoaderQuery,
  serviceQuery: ListOrganizationUnitSummariesQuery,
  serviceQueryKeys: ListOrganizationUnitSummariesQueryKeys,
  useServiceQueryHook: useListOrganizationUnitSummariesQuery,
  useServiceQueryWithRequest: useListOrganizationUnitSummariesQueryWithRequest,
} = createServiceQuery(Core.ListOrganizationUnitSummaries);

export const {
  serviceLoaderQuery: listOrganizationUnitsDetailedLoaderQuery,
  serviceQuery: listOrganizationUnitsDetailedQuery,
  serviceQueryKeys: listOrganizationUnitsDetailedQueryKeys,
  useServiceQueryHook: useListOrganizationUnitsDetailedQuery,
  useServiceQueryWithRequest: useListOrganizationUnitsDetailedQueryWithRequest,
} = createServiceQuery(Core.ListOrganizationUnitsDetailed, true);

export const {
  serviceLoaderQuery: ListOrganizationUnitCountriesLoaderQuery,
  serviceQuery: ListOrganizationUnitCountriesQuery,
  serviceQueryKeys: ListOrganizationUnitCountriesQueryKeys,
  useServiceQueryHook: useListOrganizationUnitCountriesQuery,
  useServiceQueryWithRequest: useListOrganizationUnitCountriesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListOrganizationUnitCountries);

export const {
  serviceLoaderQuery: ListOrganizationUnitShippingMethodsLoaderQuery,
  serviceQuery: ListOrganizationUnitShippingMethodsQuery,
  serviceQueryKeys: ListOrganizationUnitShippingMethodsQueryKeys,
  useServiceQueryHook: useListOrganizationUnitShippingMethodsQuery,
  useServiceQueryWithRequest: useListOrganizationUnitShippingMethodsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListOrganizationUnitShippingMethods);

export const {
  serviceLoaderQuery: ListManagementShippingMethodsLoaderQuery,
  serviceQuery: ListManagementShippingMethodsQuery,
  serviceQueryKeys: ListManagementShippingMethodsQueryKeys,
  useServiceQueryHook: useListManagementShippingMethodsQuery,
  useServiceQueryWithRequest: useListManagementShippingMethodsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListManagementShippingMethods);

export const {
  serviceLoaderQuery: ListShippingCostsLoaderQuery,
  serviceQuery: ListShippingCostsQuery,
  serviceQueryKeys: ListShippingCostsQueryKeys,
  useServiceQueryHook: useListShippingCostsQuery,
  useServiceQueryWithRequest: useListShippingCostsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListShippingCosts);

export const {
  serviceLoaderQuery: ListShippingRestrictionsLoaderQuery,
  serviceQuery: ListShippingRestrictionsQuery,
  serviceQueryKeys: ListShippingRestrictionsQueryKeys,
  useServiceQueryHook: useListShippingRestrictionsQuery,
  useServiceQueryWithRequest: useListShippingRestrictionsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListShippingRestrictions);

export const {
  serviceLoaderQuery: ListOrganizationUnitSuppliersLoaderQuery,
  serviceQuery: ListOrganizationUnitSuppliersQuery,
  serviceQueryKeys: ListOrganizationUnitSuppliersQueryKeys,
  useServiceQueryHook: useListOrganizationUnitSuppliersQuery,
  useServiceQueryWithRequest: useListOrganizationUnitSuppliersQueryWithRequest,
} = createServiceQuery(Core.ListOrganizationUnitSuppliers);

export const {
  serviceLoaderQuery: getPricelistsLoaderQuery,
  serviceQuery: getPricelistsQuery,
  serviceQueryKeys: getPricelistsQueryKeys,
  useServiceQueryHook: useGetPricelistsQuery,
} = createServiceQuery(CoreManagement.ListPriceListsForOrganizationUnit, true);

export const {
  serviceLoaderQuery: getOrganizationCurrenciesLoaderQuery,
  serviceQuery: getOrganizationCurrenciesQuery,
  serviceQueryKeys: getOrganizationCurrenciesKeys,
  useServiceQueryWithRequest: useOrganizationCurrenciesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListOrganizationUnitCurrencies, true);

export const {
  serviceLoaderQuery: getPaymentTypesLoaderQuery,
  serviceQuery: getPaymentTypesQuery,
  serviceQueryKeys: getPaymentTypesQueryKeys,
  useServiceQueryWithRequest: useGetPaymentTypesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPaymentTypes, true);

export const {
  serviceLoaderQuery: getDetailedOUOpeningHoursLoaderQuery,
  serviceQuery: getDetailedOUOpeningHoursQuery,
  serviceQueryKeys: getDetailedOUOpeningHoursQueryKeys,
  useServiceQueryHook: useGetDetailedOUOpeningHoursQuery,
} = createServiceQuery(Core.GetDetailedOrganizationUnitOpeningHours);

export const {
  serviceLoaderQuery: listTaxRatesLoaderQuery,
  serviceQuery: listTaxRatesQuery,
  serviceQueryKeys: listTaxRatesQueryKeys,
  useServiceQueryHook: useListTaxRatesQuery,
} = createServiceQuery(Core.ListTaxRates);

export const {
  serviceLoaderQuery: getOrderFulfillmentSupplierPerformanceLoaderQuery,
  useServiceQueryHook: useGetOrderFulfillmentSupplierPerformanceLoaderQuery,
} = createServiceQuery(Core.GetOrderFulfillmentSupplierPerformance, true);

export const {
  serviceLoaderQuery: getAuditingSettingsLoaderQuery,
  serviceQuery: getAuditingSettingsQuery,
  serviceQueryKeys: getAuditingSettingsQueryKeys,
  useServiceQueryHook: useGetAuditingSettingsQuery,
} = createServiceQuery(CoreManagement.GetAuditingSettings, true);

export const {
  serviceLoaderQuery: getOrganizationUnitsHierarchyLoaderQuery,
  useServiceQueryHook: useGetOrganizationUnitsHierarchyQuery,
} = createServiceQuery(Core.GetOrganizationUnitsHierarchy);

export const {
  serviceLoaderQuery: getOrganizationUnitsStructureLoaderQuery,
  serviceQuery: getOrganizationUnitsStructureQuery,
  serviceQueryKeys: getOrganizationUnitsStructureQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitsStructureQuery,
} = createServiceQuery(Core.GetOrganizationUnitsStructure, true);
