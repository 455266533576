import { ReactNode, useState } from "react";

import { Popover } from "@material-ui/core";
import { Button, ButtonProps } from "@new-black/lyra";

interface IReferencePoint {
  vertical: "top" | "bottom";
  horizontal: "left" | "center" | "right";
}

interface IconButtonPopoverProps extends Omit<ButtonProps, "onPress"> {
  PopoverContent:
    | ReactNode
    | ((props: { closePopover: () => void; anchorEl: HTMLButtonElement | undefined }) => ReactNode);
  anchorOrigin?: IReferencePoint;
  transformOrigin?: IReferencePoint;
}

export const IconButtonPopover = ({
  anchorOrigin = { vertical: "bottom", horizontal: "center" },
  children,
  PopoverContent,
  tooltip,
  transformOrigin = { vertical: "top", horizontal: "center" },
  ...props
}: IconButtonPopoverProps) => {
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined);

  return (
    <>
      <Button
        {...props}
        variant={props.variant ?? "icon"}
        onPress={(event) => {
          setAnchorEl(event.target as HTMLButtonElement);
          setShowPopover(!showPopover);
        }}
        tooltip={tooltip}
      >
        {children}
      </Button>
      <Popover
        anchorReference="anchorEl"
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={showPopover}
        onClick={(e) => e.stopPropagation()}
        onClose={() => setShowPopover(false)}
        onKeyDown={(e) => {
          e.stopPropagation();
          if (e.key === "Enter") {
            setShowPopover(false);
            e.preventDefault();
          }
        }}
      >
        {typeof PopoverContent === "function"
          ? PopoverContent({ anchorEl: anchorEl, closePopover: () => setShowPopover(false) })
          : PopoverContent}
      </Popover>
    </>
  );
};
