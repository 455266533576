import { useMemo } from "react";

import { FuseClause } from "util/fuse-query-builder";

/**
 * Creates a FuseClause that updates based on a filter value.
 * This clause can be used with `fuseQueryBuiler` which aggregates FuseClauses with `and` operations between them.
 * If the filter has the initial value, then `undefined` will be returned (inactive FuseClause)
 * @param filterKey The accessor of the field that the filter applies to
 * @param filterValue Current value of the filter
 * @param filterInitialValue Initial value of the filter
 * @returns FuseClause<T> | undefined
 * ---
 * ### Example
 * ```tsx
 * const Example = () => {
 *   const people = useMemo(() => [
 *     { name: 'John Doe', location: 'San Francisco, CA' },
 *     { name: 'Carl Johnson', location: 'Los Santos, SA' },
 *     { name: 'Michael Scott', location: 'Scranton, PA' },
 *   ], []);
 *   const [nameFilter, setNameFilter] = useState<string | undefined>();
 *   const [locationFilter, setLocationFilter] = useState<string | undefined>();
 *   const fuseInstance = useFuseInstance(['name', 'location'], people);
 *   const nameFilterClause = useFilterClause('name', nameFilter, undefined);
 *   const locationFilterClause = useFilterClause('location', locationFilter, undefined);
 *   const query = useMemo(() => fuseQueryBuiler(nameFilterClause, locationFilterClause), [nameFilterClause, locationFilterClause]);
 *
 *   const filteredPeople = useMemo(
 *     () => fuseInstance && query
 *         ? fuseInstance.search(query).map((match) => match.item)
 *         : people
 *     , [fuseInstance, people, query]);
 *
 *   return (
 *     <div>
 *       <input
 *        id="name-filter"
 *        value={nameFilter ?? ''}
 *        onChange={(e) => setNameFilter(e.target.value !== '' ? e.target.value : undefined)}
 *       />
 *       <input
 *        id="location-filter"
 *        value={locationFilter ?? ''}
 *        onChange={(e) => setLocationFilter(e.target.value !== '' ? e.target.value : undefined)}
 *       />
 *       <ul>
 *        {filteredPeople.map((person) => (
 *          <li key={person.name}>{person.name} from {person.location}</li>
 *         ))}
 *       </ul>
 *     </div>
 *   );
 * }
 * ```
 */
const useFilterClause = <T>(
  filterKey: string,
  filterValue: T,
  filterInitialValue: T,
): FuseClause<T> | undefined => {
  const filterClause = useMemo(
    () =>
      filterValue !== filterInitialValue
        ? ({ key: filterKey, value: filterValue } as FuseClause<T>)
        : undefined,
    [filterInitialValue, filterKey, filterValue],
  );

  return filterClause;
};

export default useFilterClause;
