export enum OrderDetailSidePaneCardsEnum {
  Totals = "totals",
  Customer = "customer",
  Payments = "payments",
  Store = "store",
  Status = "status",
  Order = "order",
  ReceiptRemark = "receiptRemark",
  CustomFields = "customFields",
  OrderFulfillments = "orderFulfillments",
  UserAgent = "userAgent",
  Cases = "cases",
  Company = "company",
  LoyaltyProgram = "loyaltyProgram",
}
