import { Context, createContext, ReactNode, useContext, useMemo } from "react";

/** Simplified API wrapper around vanilla React Context
 *
 * -----
 *
 * ### Example:
 *
 * ```tsx
 *  interface MyContextValue {
 *    value: string | undefined;
 *    setValue: Dispatch<SetStateAction<string | undefined>>;
 *  }
 *
 *  const {
 *    Provider: TestProvider,
 *    useContext: useTestContext,
 *  } = contextGenerator<MyContextValue>();
 * ```
 *
 * ### Usage:
 *
 * ```tsx
 *   const ExampleChild = () => {
 *     const { value } = useTestContext();
 *     return <span>Current value: {value ?? ''}</span>;
 *   };
 *
 *   const useMyContextValue = (
 *     defaultValue?: string,
 *   ): MyContextValue => {
 *     const [value, setValue] = useState(defaultValue);
 *     return { value, setValue };
 *   };
 *
 *   const Example = () => {
 *     const contextData = useMyContextValue();
 *     return (
 *       <TestProvider value={contextData}>
 *         <ExampleChild />
 *       </TestProvider>
 *     );
 *   };
 * ```
 */
export const contextGenerator = <T,>(defaultValue?: T): ContextGeneratorReturnValues<T> => {
  const Context = createContext<T | undefined>(defaultValue);

  const Provider = ({ children, value }: { value: T; children: ReactNode }) => {
    const contextValue = useMemo(() => value, [value]);
    return <Context.Provider value={contextValue}>{children}</Context.Provider>;
  };

  function useCreatedContext() {
    const contextValue = useContext(Context);
    if (!contextValue) {
      throw new Error("Cannot find relevant context - maybe you forgot the provider...");
    }
    return contextValue;
  }

  return {
    Context,
    Provider,
    useContext: useCreatedContext,
  };
};

export interface ContextGeneratorReturnValues<T> {
  Provider: (props: { value: T; children: ReactNode }) => JSX.Element;
  useContext: () => NonNullable<T>;
  Context: Context<T | undefined>;
}
