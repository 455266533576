import { InvoicesOverviewFiltersType } from "../types";

const InvoicesOverviewDefaultFilters: InvoicesOverviewFiltersType = {
  Number: undefined,
  OrderIDs: undefined,
  StartDate: undefined,
  EndDate: undefined,
  OrganizationUnitSetID: undefined,
  SupplierOrganizationUnitSetID: undefined,
  Status: undefined,
  HasSupplierOrganizationUnit: undefined,
  IsMatched: undefined,
  HasUnresolvedDisputes: undefined,
};

export default InvoicesOverviewDefaultFilters;
