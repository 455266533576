import { Params } from "react-router-dom";

import { getParams } from "remix-params-helper";
import { EnumLike, z } from "zod";

export const routeParamsIsEnumMember = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
  enumProp: EnumLike,
) => {
  const schema = z.object({ [routeParamName]: z.nativeEnum(enumProp) });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};
