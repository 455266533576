import { FilterOptionsState } from "@material-ui/lab";
import Fuse from "fuse.js";

export const fuseFilterOptions = <T>(
  availableOptions: T[],
  state: FilterOptionsState<T>,
  items: T[],
  filterKeys: string[],
  getOptionID: (opt: T) => T[keyof T],
) => {
  const fuse = new Fuse(
    items.filter((item) =>
      availableOptions.map((option) => getOptionID(option)).includes(getOptionID(item)),
    ),
    {
      threshold: 0.2,
      keys: filterKeys,
      useExtendedSearch: true,
      ignoreLocation: true,
    },
  );
  return (
    (state.inputValue === ""
      ? items
      : fuse.search(state.inputValue).map((result) => result.item)) ?? []
  );
};
