import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const getInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:GetInvoice",
  allowEmptyRequest: false,
  service: Core.GetInvoice,
});

export const getInvoice = selectorFamily({
  key: "Core:GetInvoice:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(getInvoiceServiceState.response(parameter));

      return response?.Invoice;
    },
});
