import { useCallback } from "react";

import { useGetAddressRequirementsQuery } from "models/address-requirements";
import { isAddressFieldRequired, isAddressFieldVisible } from "util/address-requirements";

const useAddressRequirements = (countryId?: string) => {
  const addressRequirementsQueryResult = useGetAddressRequirementsQuery(
    countryId ? { CountryID: countryId } : undefined,
    { refetchOnMount: false, loaderKey: [countryId] },
  );

  const isFieldRequired = useCallback(
    (field: keyof EVA.Core.AddressDto) =>
      isAddressFieldRequired(addressRequirementsQueryResult.data, field),
    [addressRequirementsQueryResult.data],
  );

  const isFieldVisible = useCallback(
    (field: keyof EVA.Core.AddressDto) =>
      isAddressFieldVisible(addressRequirementsQueryResult.data, field),
    [addressRequirementsQueryResult.data],
  );

  return {
    isLoading: addressRequirementsQueryResult.isFetching,
    isLoadingWithoutResponse: addressRequirementsQueryResult.isLoading,
    addressRequirements: addressRequirementsQueryResult.data,
    isFieldRequired,
    isFieldVisible,
  };
};

export default useAddressRequirements;
