/**
 * A FuseClause is a structure which holds the filter value and the accessor
 * of the field that the filter applies to.
 * @template T
 * @param {T} value
 * @param {string} key
 */
export interface FuseClause<T> {
  value: T;
  key: string;
}

/**
 * Aggregates FuseClauses with `and` operations between them.
 * @param {(FuseClause<any> | undefined)[]} clauses list of FuseClauses to be aggregated
 * @returns Fuse query
 * ---
 * ### Example
 * ```tsx
 * const Example = () => {
 *   const people = useMemo(() => [
 *     { name: 'John Doe', location: 'San Francisco, CA' },
 *     { name: 'Carl Johnson', location: 'Los Santos, SA' },
 *     { name: 'Michael Scott', location: 'Scranton, PA' },
 *   ], []);
 *   const [nameFilter, setNameFilter] = useState<string | undefined>();
 *   const [locationFilter, setLocationFilter] = useState<string | undefined>();
 *   const fuseInstance = useFuseInstance(['name', 'location'], people);
 *   const nameFilterClause = useFilterClause('name', nameFilter, undefined);
 *   const locationFilterClause = useFilterClause('location', locationFilter, undefined);
 *   const query = useMemo(() => fuseQueryBuiler(nameFilterClause, locationFilterClause), [nameFilterClause, locationFilterClause]);
 *
 *   const filteredPeople = useMemo(
 *     () => fuseInstance && query
 *         ? fuseInstance.search(query).map((match) => match.item)
 *         : people
 *     , [fuseInstance, people, query]);
 *
 *   return (
 *     <div>
 *       <input
 *        id="name-filter"
 *        value={nameFilter ?? ''}
 *        onChange={(e) => setNameFilter(e.target.value !== '' ? e.target.value : undefined)}
 *       />
 *       <input
 *        id="location-filter"
 *        value={locationFilter ?? ''}
 *        onChange={(e) => setLocationFilter(e.target.value !== '' ? e.target.value : undefined)}
 *       />
 *       <ul>
 *        {filteredPeople.map((person) => (
 *          <li key={person.name}>{person.name} from {person.location}</li>
 *         ))}
 *       </ul>
 *     </div>
 *   );
 * }
 * ```
 */
const fuseQueryBuiler = (...clauses: (FuseClause<any> | undefined)[]) => {
  const validClauses = [...(clauses.filter((clause) => !!clause) as FuseClause<any>[])].map(
    (clause) => ({ [clause.key]: clause.value }),
  );
  if (validClauses.length === 0) {
    return undefined;
  }
  return { $and: validClauses };
};

export default fuseQueryBuiler;
