import { memo } from "react";

import type { IDraggableCardProps } from "./draggable-card";
import DraggableCard from "./draggable-card";

const OverlayedDraggableCard = memo((props: IDraggableCardProps) => (
  <div className="[&>_div]:bg-[color:#f6f6f6] [&_>_div]:cursor-grabbing">
    <DraggableCard {...props} />
  </div>
));

OverlayedDraggableCard.displayName = "OverlayedDraggableCard";

export default OverlayedDraggableCard;
