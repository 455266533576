import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup, Table, TableColumnDef, TableProps } from "@new-black/lyra";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import EditActionButton from "components/shared/action-buttons/edit-action-button";

import { LoyaltyProgramGroupDeactivationReasonsBadges, LoyaltyProgramStatusBadge } from ".";

type TPickedRequiredTableProps =
  | "data"
  | "onNext"
  | "showNext"
  | "onPrevious"
  | "showPrevious"
  | "limit"
  | "setLimit";

type TRequiredLoyaltyProgramsTableProps = Required<
  Pick<
    TableProps<EVA.Core.Management.ListLoyaltyProgramGroupsResponse.Item>,
    TPickedRequiredTableProps
  >
>;

type TOptionalLoyaltyProgramsTableProps = Pick<
  TableProps<EVA.Core.Management.ListLoyaltyProgramGroupsResponse.Item>,
  "onRowPress"
> & {
  onEdit?: (id: number) => void;
  onDelete?: (id: number) => void;
};

export const LoyaltyProgramGroupsTable = ({
  data,
  limit,
  onDelete,
  onEdit,
  onNext,
  onPrevious,
  onRowPress,
  setLimit,
  showNext,
  showPrevious,
}: TRequiredLoyaltyProgramsTableProps & TOptionalLoyaltyProgramsTableProps) => {
  const intl = useIntl();

  const columns = useMemo(() => {
    const result: TableColumnDef<EVA.Core.Management.ListLoyaltyProgramGroupsResponse.Item>[] = [
      {
        accessorKey: "BackendID",
        id: "BackendID",
        header: intl.formatMessage({ id: "generic.label.backend-id", defaultMessage: "BackendID" }),
      },

      {
        accessorKey: "Name",
        id: "Name",
        header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
      },

      {
        accessorKey: "Status",
        id: "Status",
        header: intl.formatMessage({
          id: "generic.label.status",
          defaultMessage: "Status",
        }),
        cellType: "badge",
        cell: ({ getValue }) => (
          <LoyaltyProgramStatusBadge value={getValue() as number | undefined} />
        ),
      },

      {
        accessorKey: "DeactivationReason",
        id: "DeactivationReason",
        header: intl.formatMessage({
          id: "generic.label.deactivation-reasons",
          defaultMessage: "Deactivation reasons",
        }),
        cellType: "badge",
        cell: ({ getValue }) => (
          <div className="flex flex-wrap gap-2">
            <LoyaltyProgramGroupDeactivationReasonsBadges
              value={getValue() as number | undefined}
            />
          </div>
        ),
      },
    ];

    if (onEdit || onDelete) {
      result.push({
        id: "actions",
        header: "",
        align: "end",
        cellType: "button",
        cell: ({ row }) => (
          <ButtonGroup>
            {row.original.ID !== undefined ? (
              <>
                {onEdit ? <EditActionButton onPress={() => onEdit(row.original.ID!)} /> : null}
                {onDelete ? (
                  <DeleteActionButton onPress={() => onDelete(row.original.ID!)} />
                ) : null}
              </>
            ) : null}
          </ButtonGroup>
        ),
      });
    }

    return result;
  }, [intl, onDelete, onEdit]);

  return (
    <Table
      data={data}
      onNext={onNext}
      showNext={showNext}
      onPrevious={onPrevious}
      showPrevious={showPrevious}
      limit={limit}
      setLimit={setLimit}
      aria-label={intl.formatMessage({
        id: "generic.label.loyalty-programs",
        defaultMessage: "Loyalty programs",
      })}
      columns={columns}
      onRowPress={onRowPress}
    />
  );
};
