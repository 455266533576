import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Separator } from "@new-black/lyra";
import { Field, FieldProps, FormikProps, setNestedObjectValues } from "formik";
import { isNaN, isNil } from "lodash";

import {
  IEvaProductSetOptions,
  IUpdateEvaProductSetOptions,
  ProductSetItem,
} from "components/shared/eva-product-set/eva-product-set.types";
import ProductSetProductFilter from "components/shared/eva-product-set/eva-product-set-product-filters";
import EvaProductSetRequiredAmountInputs from "components/shared/eva-product-set/eva-product-set-required-amount-inputs";
import EvaProductSetRequiredQuantityOfProductsInput from "components/shared/eva-product-set/eva-product-set-required-quantity-of-products-input";
import OnSubmitValidationError from "components/shared/eva-product-set/on-submit-validation-error";
import useProductSetValidation from "components/shared/eva-product-set/use-product-set-validation";
import { EVAFormik } from "components/suite-composite/eva-formik";
import { FormikCheckboxV2 } from "components/suite-composite/formik-inputs";
import { generatePersonalizedPromotionAutocomplete } from "components/suite-composite/generate-personalized-promotion-autocomplete";
import { generateProductSearchTemplateAutocomplete } from "components/suite-composite/generate-product-search-template-autocomplete";
import { ProductAutocomplete } from "components/suite-composite/product-autocomplete";
import Input from "components/suite-ui/input";
import NumberInput from "components/suite-ui/number-input";
import {
  EEvaDiscountProductSetType,
  IEvaDiscountProductFilter,
} from "features/discount-edit/edit-discount.types";
import EditDiscountEdited from "features/discount-edit/edit-discount-edited";
import { useDiscountProductRequirements } from "features/discount-edit/views/conditions/use-discount-product-requirements";
import { ProductRequirementDataTypes } from "types/product-requirement-types";

import { EVAProductSetProductIDsField } from "./eva-product-set-product-ids-field";
import { EvaProductSetProductRequirement } from "./eva-product-set-product-requirement";

const EvaProductSetForm = ({
  amountOfProductsOption,
  defaultProductOption,
  isDiscountableProductSetOption,
  passive,
  requiredAmountOption,
  requiredAmountSpentOption,
  requiredProductSetName,
  setIsEdited,
  setItem,
  shouldFocusInput,
  updateSet,
}: {
  passive?: boolean;
  shouldFocusInput?: boolean;
  setItem: ProductSetItem;
  updateSet: (
    setRef: string,
    options: IUpdateEvaProductSetOptions,
    validationFailed?: boolean,
  ) => void;
  setIsEdited: (newValue: boolean) => void;
} & IEvaProductSetOptions) => {
  const intl = useIntl();

  // Form validation schema
  const validationSchema = useProductSetValidation(
    setItem.type,
    amountOfProductsOption ?? false,
    requiredAmountOption ?? false,
    defaultProductOption ?? false,
    isDiscountableProductSetOption ?? false,
    requiredAmountSpentOption ?? false,
  );

  const onFieldBlur = useCallback((form: FormikProps<any>) => {
    form.submitForm().then(() => form.setTouched(setNestedObjectValues(form.errors, true)));
  }, []);

  const handleUpdateProductFilters = useCallback(
    (filters: IEvaDiscountProductFilter[], setRef: string): void => {
      updateSet(setRef, { filters });
      setIsEdited(true);
    },
    [setIsEdited, updateSet],
  );

  const { productRequirements } = useDiscountProductRequirements(
    !!setItem.set.ProductRequirements?.length,
  );

  const initialValues = useMemo(
    () => ({
      setName: setItem.set.Name ?? "",
      requiredQuantityOfProducts: setItem.set.RequiredQuantityOfProducts ?? "",
      requiredAmountSpentOnProducts: setItem.set.RequiredProductAmount ?? "",
      isDiscountableProductSet: setItem.set.IsDiscountableProductSet ?? false,
      discountableQuantityOfProducts: setItem.set.DiscountableQuantityOfProducts
        ? setItem.set.DiscountableQuantityOfProducts
        : "",
      defaultProduct: setItem.set.DefaultProductToAdd
        ? { product_id: setItem.set.DefaultProductToAdd }
        : undefined,
      personalizedPromotionID: setItem.set.PersonalizedPromotionID,
      productSearchID: setItem.set.ProductSearchID,
      productIDs: setItem.set.ProductIDs,
      productRequirements: setItem.set.ProductRequirements?.map((productRequirement) => {
        const relatedProductRequirement = productRequirements?.find(
          (requirement) => requirement.ID === productRequirement.ProductRequirementID,
        );

        if ((relatedProductRequirement?.DataType as number) === ProductRequirementDataTypes.Enum) {
          // Filter out enum values that are not supported by the BE at the moment.
          // To be supported, the array value members must be in a valid enum key for the product requirement in question (`relatedProductRequirement`)
          const productRequirementValues =
            (productRequirement.Values?.filter(
              (value) =>
                !!relatedProductRequirement?.RequirementOptions?.EnumValues?.[value as string],
            ) as string[]) ?? []; // Enum values for product requirements can hold only strings

          return { ...productRequirement, Values: productRequirementValues };
        }

        return productRequirement;
      }),
    }),
    [
      productRequirements,
      setItem.set.DefaultProductToAdd,
      setItem.set.DiscountableQuantityOfProducts,
      setItem.set.IsDiscountableProductSet,
      setItem.set.Name,
      setItem.set.PersonalizedPromotionID,
      setItem.set.ProductIDs,
      setItem.set.ProductRequirements,
      setItem.set.ProductSearchID,
      setItem.set.RequiredProductAmount,
      setItem.set.RequiredQuantityOfProducts,
    ],
  );

  return (
    <EVAFormik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(form) => {
        if (
          requiredProductSetName ||
          requiredAmountOption ||
          amountOfProductsOption ||
          defaultProductOption
        ) {
          updateSet(setItem.key ?? 0, {
            setName: form.setName,
            requiredQuantityOfProducts:
              !isNil(form?.requiredQuantityOfProducts) &&
              !isNaN(parseInt(form.requiredQuantityOfProducts.toString(), 10))
                ? parseInt(form.requiredQuantityOfProducts.toString(), 10)
                : undefined,
            requiredAmountSpentOnProducts:
              !isNil(form?.requiredAmountSpentOnProducts) &&
              !isNaN(parseFloat(form.requiredAmountSpentOnProducts.toString()))
                ? parseFloat(form.requiredAmountSpentOnProducts.toString())
                : undefined,
            isDiscountableProductSet: form.isDiscountableProductSet,
            discountableQuantityOfProducts: parseInt(
              form.discountableQuantityOfProducts?.toString(),
              10,
            ),
            defaultProduct: form.defaultProduct,
            personalizedPromotionID: form.personalizedPromotionID,
            productSearchID: form.productSearchID,
            productIDs: form.productIDs,
            productRequirements: form.productRequirements,
          });
        }
      }}
      enableReinitialize
    >
      {(form: FormikProps<any>) => (
        <>
          {requiredProductSetName ||
          requiredAmountOption ||
          amountOfProductsOption ||
          defaultProductOption ? (
            <div className="p-5 pt-0">
              <OnSubmitValidationError
                formik={form}
                callback={() => {
                  updateSet(setItem.key ?? 0, {}, true);
                }}
              />
              <div className="flex items-start gap-5">
                {requiredProductSetName && (
                  <div className="h-20 w-full md:w-1/2">
                    <Field name="setName">
                      {(props: FieldProps) => (
                        <Input
                          {...props.field}
                          required
                          label={intl.formatMessage({
                            id: "edit-discount.product-set.from.setname.title",
                            defaultMessage: "Name of product set",
                          })}
                          passive={passive}
                          error={!!props.meta.error && props.meta.touched}
                          helperText={
                            props.meta.error && props.meta.touched ? props.meta.error : undefined
                          }
                          onBlur={() => onFieldBlur(form)}
                          inputProps={{
                            autoFocus: shouldFocusInput,
                          }}
                        />
                      )}
                    </Field>
                  </div>
                )}

                {requiredAmountOption && !requiredAmountSpentOption ? (
                  <div className="h-20 w-full md:w-1/2">
                    <EvaProductSetRequiredQuantityOfProductsInput
                      onFieldBlur={onFieldBlur}
                      passive={passive}
                      required={
                        !defaultProductOption ||
                        (defaultProductOption && !form.values.isDiscountableProductSet)
                      }
                      label={intl.formatMessage({
                        id: "edit-discount.product-set.from.requiredamountofproducts.title",
                        defaultMessage: "Required amount of products",
                      })}
                    />
                  </div>
                ) : null}
              </div>

              {requiredAmountOption && requiredAmountSpentOption ? (
                <div className="w-full md:w-1/2">
                  <EvaProductSetRequiredAmountInputs onFieldBlur={onFieldBlur} passive={passive} />
                </div>
              ) : null}

              <div className="flex items-start gap-5">
                {isDiscountableProductSetOption && (
                  <div className="flex h-20 w-full items-center md:w-1/2">
                    <FormikCheckboxV2
                      name="isDiscountableProductSet"
                      label={intl.formatMessage({
                        id: "edit-discount.product-set.from.isdiscountableproductset.title",
                        defaultMessage: "This product set gets the discount",
                      })}
                      onBlur={() => onFieldBlur(form)}
                      disabled={passive}
                      CheckboxProps={{ name: `isDiscountableProductSet${setItem.key}` }}
                    />
                  </div>
                )}

                {amountOfProductsOption && isDiscountableProductSetOption && (
                  <div className="h-20 w-full md:w-1/2">
                    <Field name="discountableQuantityOfProducts">
                      {(props: FieldProps) => (
                        <NumberInput
                          label={intl.formatMessage({
                            id: "edit-discount.product-set.from.discountableamountofproducts.title",
                            defaultMessage: "This many products get the discount",
                          })}
                          precision={0}
                          {...props.field}
                          onBlur={() => onFieldBlur(props.form)}
                          passive={passive}
                          value={props.field.value ?? ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            form.setFieldValue(
                              "discountableQuantityOfProducts",
                              e.target.value ? parseInt(e.target.value, 10) : undefined,
                            );
                          }}
                          error={props.meta.error && props.meta.touched ? true : undefined}
                          helperText={
                            props.meta.error && props.meta.touched
                              ? props.meta.error
                              : !form.values.isDiscountableProductSet
                              ? intl.formatMessage({
                                  id: "edit-discount.product-set.from.discountableamountofproducts.disabled",
                                  defaultMessage: "Only applicable when checkbox is set.",
                                })
                              : undefined
                          }
                          disabled={!form.values.isDiscountableProductSet}
                          required={form.values.isDiscountableProductSet}
                        />
                      )}
                    </Field>
                  </div>
                )}
              </div>

              {defaultProductOption && (
                <div className="w-full">
                  <Field name="defaultProduct">
                    {(props: FieldProps) => (
                      <ProductAutocomplete
                        familyKey={`Promotions:DiscountDetails:ProductSet:ProductSetAutocomplete:${setItem.key}:${setItem.set.Name}`}
                        label={intl.formatMessage({
                          id: "edit-discount.product-set.from.defaultproduct.title",
                          defaultMessage: "Default product",
                        })}
                        passive={passive}
                        disabled={!form.values.isDiscountableProductSet}
                        selectedProduct={props.field.value ?? undefined}
                        setSelectedProduct={(product) => {
                          form.setFieldValue(props.field.name, product);
                        }}
                        error={props.meta.error && props.meta.touched ? true : undefined}
                        helperText={
                          props.meta.error && props.meta.touched
                            ? props.meta.error
                            : !form.values.isDiscountableProductSet
                            ? intl.formatMessage({
                                id: "edit-discount.product-set.from.defaultproduct.disabled",
                                defaultMessage: "Only applicable when checkbox is set.",
                              })
                            : undefined
                        }
                        onBlur={() => onFieldBlur(form)}
                      />
                    )}
                  </Field>
                </div>
              )}

              <EditDiscountEdited edited={form.dirty} />
            </div>
          ) : null}

          <Separator className="my-1" />

          {setItem.type === EEvaDiscountProductSetType.ProductSet ? (
            <ProductSetProductFilter
              setRef={setItem.key ?? undefined}
              productFilters={setItem.set.Filters}
              productFiltersUpdated={handleUpdateProductFilters}
              passive={passive}
            />
          ) : null}

          {setItem.type === EEvaDiscountProductSetType.PersonalizedPromotion ? (
            <div className="w-full p-5 pt-0 md:w-1/2">
              <Field name="personalizedPromotionID">
                {({ field, meta }: FieldProps) => (
                  <PersonalizedPromotionSingleIDAutocomplete.Controlled
                    required
                    value={field.value}
                    onChange={(newValue) => {
                      if (typeof newValue === "number" || newValue === undefined) {
                        form.setFieldValue(field.name, newValue);
                      }
                    }}
                    onBlur={() => onFieldBlur(form)}
                    error={!!meta.error}
                    helperText={meta.error}
                    passive={passive}
                  />
                )}
              </Field>
            </div>
          ) : null}

          {setItem.type === EEvaDiscountProductSetType.ProductSearch ? (
            <div className="w-full p-5 pt-0 md:w-1/2">
              <Field name="productSearchID">
                {({ field, meta }: FieldProps) => (
                  <ProductSearchTemplateSingleIDAutocomplete.Controlled
                    required
                    value={field.value}
                    onChange={(newValue) => {
                      if (typeof newValue === "number" || newValue === undefined) {
                        form.setFieldValue(field.name, newValue);
                      }
                    }}
                    onBlur={() => onFieldBlur(form)}
                    error={!!meta.error}
                    helperText={meta.error}
                    passive={passive}
                  />
                )}
              </Field>
            </div>
          ) : null}

          {setItem.type === EEvaDiscountProductSetType.ProductIDs ? (
            <>
              <Field name="productIDs">
                {({ field }: FieldProps<number[] | undefined>) => (
                  <EVAProductSetProductIDsField
                    productIDs={field.value}
                    setProductIDs={(newValue) => {
                      form.setFieldValue(field.name, newValue.length ? newValue : undefined);
                      onFieldBlur(form);
                    }}
                    passive={passive}
                  />
                )}
              </Field>
            </>
          ) : null}

          {setItem.type === EEvaDiscountProductSetType.ProductRequirement ? (
            <EvaProductSetProductRequirement passive={passive} onFieldBlur={onFieldBlur} />
          ) : null}
        </>
      )}
    </EVAFormik>
  );
};

export default EvaProductSetForm;

const { PersonalizedPromotionSingleIDAutocomplete } = generatePersonalizedPromotionAutocomplete();
const { ProductSearchTemplateSingleIDAutocomplete } = generateProductSearchTemplateAutocomplete();
