import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCarriersService } from "models/carriers";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import { useCarrierById } from "./use-carrier-by-id";

export const generateListCarriersSearchListField = () =>
  SearchListFieldGenerator<CoreManagement.ListCarriers, EVA.Core.Management.CarrierDto, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listCarriersService,
        refetchOnFocus: false,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.carrier",
      defaultMessage: "Carrier",
    }),
    useItemByID: (id) => {
      const { data, isLoading } = useCarrierById(id);

      return {
        data,
        isLoading,
      };
    },
  });
