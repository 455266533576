import { useIntl } from "react-intl";

import { Badge, KeyValueRow, KeyValueRowList } from "@new-black/lyra";

export const LoyaltyProgramRuleInformationRows = ({
  rule,
}: {
  rule?: EVA.Core.Management.GetPointAwardingRuleResponse;
}) => {
  const intl = useIntl();

  return (
    <KeyValueRowList>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.organization-unit-set-name",
          defaultMessage: "Organization unit set name",
        })}
      >
        {rule?.OrganizationUnitSetName}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.name",
          defaultMessage: "Name",
        })}
      >
        {rule?.Name}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.description",
          defaultMessage: "Description",
        })}
      >
        {rule?.Description}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.marketing-description",
          defaultMessage: "Marketing Description",
        })}
      >
        {rule?.MarketingDescription}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.backend-id",
          defaultMessage: "Backend ID",
        })}
      >
        {rule?.BackendID}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.backend-system-id",
          defaultMessage: "Backend system ID",
        })}
      >
        {rule?.BackendSystemID}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.enabled",
          defaultMessage: "Enabled",
        })}
        type="badge"
      >
        <Badge variant="transparent">
          {intl.formatMessage({
            id: rule?.Enabled ? "generic.label.true" : "generic.label.false",
            defaultMessage: rule?.Enabled ? "True" : "False",
          })}
        </Badge>
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.start-date",
          defaultMessage: "Start date",
        })}
      >
        {rule?.StartDate ? intl.formatDate(rule?.StartDate) : ""}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.end-date",
          defaultMessage: "End date",
        })}
      >
        {rule?.EndDate ? intl.formatDate(rule?.EndDate) : ""}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.apply-recursive",
          defaultMessage: "Apply recursive",
        })}
        type="badge"
      >
        <Badge variant="transparent">
          {intl.formatMessage({
            id: rule?.ApplyRecursive ? "generic.label.true" : "generic.label.false",
            defaultMessage: rule?.ApplyRecursive ? "True" : "False",
          })}
        </Badge>
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.points-to-award",
          defaultMessage: "Points to award",
        })}
      >
        {rule?.PointsToAward?.toString()}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.expiration-span",
          defaultMessage: "Expiration span",
        })}
      >
        {rule?.PointExpirationPolicyType}
      </KeyValueRow>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.pending-points",
          defaultMessage: "Pending points",
        })}
      >
        {rule?.PointPendingPolicyType}
      </KeyValueRow>
    </KeyValueRowList>
  );
};
