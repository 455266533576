import { useCallback } from "react";
import { useIntl } from "react-intl";

import { getLocalTimeZone, now, parseAbsoluteToLocal } from "@internationalized/date";

export const useDateDuration = () => {
  const intl = useIntl();

  const getDateDuration = useCallback(
    (targetDate?: string) => {
      if (!targetDate) {
        return "";
      }

      const currentDateTime = now(getLocalTimeZone());
      const date = parseAbsoluteToLocal(targetDate);

      const dayDiff = date.day - currentDateTime.day;
      const monthDiff = date.month - currentDateTime.month;
      const yearDiff = date.year - currentDateTime.year;
      const hoursDiff = date.hour - currentDateTime.hour;
      const minutesDiff = date.minute - currentDateTime.minute;
      const secondsDiff = date.second - currentDateTime.second;

      if (yearDiff !== 0) {
        return intl.formatRelativeTime(yearDiff, "year");
      } else if (monthDiff !== 0) {
        return intl.formatRelativeTime(monthDiff, "month");
      } else if (dayDiff !== 0) {
        return intl.formatRelativeTime(dayDiff, "day");
      } else if (hoursDiff !== 0) {
        return intl.formatRelativeTime(hoursDiff, "hour");
      } else if (minutesDiff !== 0) {
        return intl.formatRelativeTime(minutesDiff, "minute");
      } else {
        return intl.formatRelativeTime(secondsDiff, "second");
      }
    },
    [intl],
  );

  return getDateDuration;
};
