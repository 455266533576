import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

const { createGetterSetterSelectorFamily } = helpers.createSelectors;

const FAMILY_KEY_PREFIX =
  "composite:ouSetListServiceFamily:CoreManagement.ListOrganizationUnitSets";

const defaultRequest: EVA.Core.Management.ListOrganizationUnitSets = {
  PageConfig: {
    Limit: 10,
    Start: 0,
    Filter: { Name: undefined },
  },
};

const ouSetListServiceFamily = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  requireEmployee: true,
  key: FAMILY_KEY_PREFIX,
  allowEmptyRequest: false,
  service: CoreManagement.ListOrganizationUnitSets,
});

const ouSetListFamilyResponseSelector = selectorFamily({
  key: `${FAMILY_KEY_PREFIX}:selector`,
  get:
    (param) =>
    ({ get }) => {
      if (param) {
        return get(ouSetListServiceFamily.response(param))?.Result?.Page;
      }
      return undefined;
    },
});

const ouSetListFamilyPageConfigSelector = createGetterSetterSelectorFamily({
  state: ouSetListServiceFamily.request,
  defaultValue: defaultRequest.PageConfig,
  key: `${FAMILY_KEY_PREFIX}:selector:PageConfig`,
  getData: (data) => data?.PageConfig ?? defaultRequest.PageConfig!,
  setData: (data, value) => ({
    ...data,
    PageConfig: { ...data?.PageConfig, ...value },
  }),
});

const ouSetListFamilyFilterSelector = createGetterSetterSelectorFamily({
  state: ouSetListFamilyPageConfigSelector,
  defaultValue: defaultRequest.PageConfig?.Filter,
  key: `${FAMILY_KEY_PREFIX}:selector:PageConfig:Filter`,
  getData: (data) => data?.Filter ?? defaultRequest.PageConfig?.Filter!,
  setData: (data, value) => ({
    ...data,
    Filter: { ...data?.Filter, ...value },
  }),
});

const ouSetListFamilyNameFilterSelector = createGetterSetterSelectorFamily({
  state: ouSetListFamilyFilterSelector,
  key: `${FAMILY_KEY_PREFIX}:selector:nameFilter`,
  defaultValue: defaultRequest.PageConfig?.Filter?.Name,
  getData: (data) => data?.Name ?? defaultRequest.PageConfig?.Filter?.Name!,
  setData: (data, value) => ({ ...data, Name: value }),
});

export const ouSetListService = {
  defaultRequest,
  serviceState: ouSetListServiceFamily,
  filter: ouSetListFamilyFilterSelector,
  response: ouSetListFamilyResponseSelector,
  nameFilter: ouSetListFamilyNameFilterSelector,
  pageConfig: ouSetListFamilyPageConfigSelector,
};

const ARRAY_FAMILY_KEY_PREFIX =
  "composite:ouSetListServiceFamily:CoreManagement.GetOrganizationUnitSet:Array";

const ouSetServiceFamily = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
  key: ARRAY_FAMILY_KEY_PREFIX,
  // using ListOrganizationUnitSets with an ID filter as it returns the OrganizationUnitSubsetsCount
  // which is not included in the response of `GetOrganizationUnitSet`
  service: CoreManagement.ListOrganizationUnitSets,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.PageConfig?.Filter?.ID === parameter) {
      return req;
    }
    return {
      ...req,
      PageConfig: {
        Start: 0,
        Limit: 1,
        Filter: {
          ID: parameter,
          Types: [0, 1, 2], // explicitly include ad-hoc sets as well
        },
      },
    };
  },
});

const ouSetFamilyResponseSelector = selectorFamily<
  EVA.Core.ListOrganizationUnitSetsItem | undefined,
  number | undefined
>({
  key: `${ARRAY_FAMILY_KEY_PREFIX}:selector`,
  get:
    (param) =>
    ({ get }) =>
      param ? get(ouSetServiceFamily.response(param))?.Result?.Page?.[0] : undefined,
});

const ouSetArrayFamilySelector = selectorFamily<
  EVA.Core.ListOrganizationUnitSetsItem[] | undefined,
  number[] | undefined
>({
  key: `${ARRAY_FAMILY_KEY_PREFIX}:selector:array`,
  get:
    (param) =>
    ({ get }) => {
      return param
        ? param
            .map((ouSetId) => get(ouSetFamilyResponseSelector(ouSetId)))
            .filter((resp): resp is EVA.Core.ListOrganizationUnitSetsItem => !!resp)
        : undefined;
    },
});

export const ouSetArrayService = {
  serviceState: ouSetServiceFamily,
  ouSetById: ouSetFamilyResponseSelector,
  ouSetIdArray: ouSetArrayFamilySelector,
};
