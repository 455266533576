import { ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Select, SelectProps } from "@new-black/lyra";

import { APPLE_COLORS, AppleColor } from "types/apple-colors";

interface IColorOption<T extends string> {
  value: T;
  label: string;
  icon: ReactNode;
}

type InheritedProps<T extends string> = Pick<
  SelectProps<IColorOption<T>>,
  "name" | "isInvalid" | "isDisabled" | "errorMessage" | "hideHintLabel"
> &
  Partial<Pick<SelectProps<IColorOption<T>>, "label">>;

export interface IColorSelectProps<T extends string> extends InheritedProps<T> {
  /** The list of colors that the user can select from. */
  colors?: T[];
  /** A function that returns the label for a given color. Defaults to the color itself. */
  getColorOptionLabel?: (color: T) => string;
}

export interface IControlledColorSelectProps<T extends string> extends IColorSelectProps<T> {
  /** Whether the component is controlled or uncontrolled. */
  type: "controlled";
  defaultValue?: never;
  /** The value of the component. */
  value: T | undefined;
  /** The function to call when the value of the component changes. */
  onChange: (value: T | undefined) => void;
}

export interface IUncontrolledColorSelectProps<T extends string> extends IColorSelectProps<T> {
  /** Whether the component is controlled or uncontrolled. */
  type: "uncontrolled";
  value?: never;
  onChange?: (value: T | undefined) => void;
  /** The default value of the component. */
  defaultValue: T | undefined;
}

export const ColorIcon = ({ className, colorHex }: { colorHex: string; className?: string }) => (
  <div className={className}>
    <div
      className="h-5 w-5 rounded"
      style={{ backgroundColor: colorHex === "" || colorHex === "none" ? "white" : colorHex }}
    >
      &nbsp;
    </div>
  </div>
);

const defaultGetColorOptionLabel = <T extends string = Lowercase<AppleColor>>(color: T) => color;

/** A select component that allows the user to select a color from a predefined list of colors.
 * It infers the color hex code constants from the colors prop.
 * It also allows the user to select no color as well, and if there is a color selected, it will display an accordingly colored dot next to the label.
 * It also makes sure to lowercase the color hex code before passing it to the onChange function.
 *
 * @param colors The list of colors that the user can select from. Defaults to `APPLE_COLORS`.
 * @param type Whether the component is controlled or uncontrolled.
 * @param name The name of the select component (necessary for form submission).
 * @param label The label of the component. Defaults to "Color".
 * @param isInvalid Whether the component is in an error state.
 * @param isDisabled Whether the component is in a disabled state.
 * @param errorMessage The helper text to display below the component.
 * @param getColorOptionLabel A function that returns the label for a given color. Defaults to the color itself.
 * @param value The value of the component (only for controlled components).
 * @param onChange The function to call when the value of the component changes (only for controlled components).
 * @param defaultValue The default value of the component (only for uncontrolled components).
 */
export function ColorSelect<T extends string = Lowercase<AppleColor>>({
  colors,
  defaultValue,
  errorMessage,
  getColorOptionLabel = defaultGetColorOptionLabel<T>,
  hideHintLabel,
  isDisabled,
  isInvalid,
  label,
  name,
  onChange,
  type,
  value,
}: IControlledColorSelectProps<T> | IUncontrolledColorSelectProps<T>) {
  const intl = useIntl();

  const colorOptions = useMemo<IColorOption<T>[]>(
    () =>
      ((colors ?? [...APPLE_COLORS]) as T[]).map((colorHex) => ({
        value: colorHex.toLowerCase() as T,
        icon: (
          <ColorIcon
            colorHex={colorHex}
            className="pe-2 [&~div]:self-center [div.grow:has(&)]:items-center"
          />
        ),
        label: getColorOptionLabel(colorHex),
      })),
    [colors, getColorOptionLabel],
  );

  const handleSelectChange = useCallback(
    (newColor?: IColorOption<T>) => {
      if (!onChange) {
        return;
      }
      if (newColor && colorOptions.find((colorOption) => colorOption.value === newColor.value)) {
        onChange?.(newColor.value.toLowerCase() as T);
        return;
      }
      onChange?.(undefined);
    },
    [colorOptions, onChange],
  );

  return (
    <Select<IColorOption<T>>
      name={name}
      items={colorOptions}
      isDisabled={isDisabled}
      errorMessage={errorMessage}
      isInvalid={isInvalid}
      hideHintLabel={hideHintLabel}
      onChange={handleSelectChange}
      getLabel={(item) => item.label}
      getItemId={(item) => item.value}
      renderCustomElementInValueButton
      label={label ?? intl.formatMessage({ id: "generic.label.color", defaultMessage: "Color" })}
      selectRenderElements={(item) => ({
        label: item.label,
        customElement: !isDisabled ? item.icon : undefined,
      })}
      value={
        type === "controlled"
          ? colorOptions.find((colorOption) => colorOption.value === value)
          : undefined
      }
      defaultValue={
        type === "uncontrolled"
          ? colorOptions.find((colorOption) => colorOption.value === defaultValue)
          : undefined
      }
    />
  );
}
