import { useMemo } from "react";

import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

const giftCardHandlersServiceState = builders.buildServiceState({
  requireEmployee: true,
  service: CoreManagement.GetGiftCardHandlers,
  key: "Giftcards:Details:CoreManagement.GetGiftCardHandlers",
});

const giftCardHandlersResponseSelector = selector({
  key: "Giftcards:Details:CoreManagement.GetGiftCardHandlers:Response",
  get: ({ get }) => get(giftCardHandlersServiceState.response)?.Handlers,
});

const useGiftcardHandlers = () => {
  const giftcardHandlers = hooks.useGetState(giftCardHandlersResponseSelector);

  const isLoading = hooks.useIsLoading({ state: giftCardHandlersServiceState.response });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !giftcardHandlers,
    [giftcardHandlers, isLoading],
  );

  return { giftcardHandlers, isLoading, isLoadingWithoutPreviousResponse };
};

export default useGiftcardHandlers;
