import { ComponentProps, useEffect, useState } from "react";

import { Core } from "@springtree/eva-services-core";

import { BarcodeFormat } from "types/eva-core";
import { mutate } from "util/mutate";

import { getBarcodeImgProps, getBarcodeSize } from "./helpers";
import { IUseBarcodeProps } from "./types";

/** Async function that generates the img props for a barcode generated by the new [Barcode service](https://dora.on-eva.io/Barcode).
 *
 * @param options see {@link IUseBarcodeProps}
 */
export const getBarcode = async ({
  alt,
  Code,
  Format = BarcodeFormat.QR,
  Height,
  Width,
  ...rest
}: IUseBarcodeProps): Promise<ComponentProps<"img"> | undefined> => {
  if (!Code) return undefined;
  const { height, width } = getBarcodeSize(Width, Height);
  const fetchBarcode = mutate({
    service: Core.Barcode,
    disabledNotifications: true,
    disableErrorNotification: true,
  });
  const result = await fetchBarcode({
    Code,
    Width: width,
    Height: height,
    Format: Format as number,
    ...rest,
  });
  const barcodeImgProps = getBarcodeImgProps(result?.response, { alt, width, height });
  return barcodeImgProps;
};

/** Hook that generates the img props for a barcode generated by the new [Barcode service](https://dora.on-eva.io/Barcode).
 *
 * @param props see {@link IUseBarcodeProps}
 */
export const useBarcode = ({
  alt,
  BackgroundHex,
  BlobID,
  Code,
  ForegroundHex,
  Format,
  Height,
  NoMargin,
  NoText,
  Width,
}: IUseBarcodeProps): ComponentProps<"img"> | undefined => {
  const [barcodeImgProps, setBarcodeImgProps] = useState<ComponentProps<"img"> | undefined>(
    undefined,
  );

  useEffect(() => {
    if (Code) {
      getBarcode({
        Code,
        alt,
        Width,
        Height,
        Format,
        BlobID,
        NoText,
        NoMargin,
        BackgroundHex,
        ForegroundHex,
      }).then(setBarcodeImgProps);
    }
  }, [BackgroundHex, BlobID, Code, ForegroundHex, Format, Height, NoMargin, NoText, Width, alt]);

  return barcodeImgProps;
};
