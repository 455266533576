import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

export const organizationUnitSetDetailsServiceState = builders.buildServiceFamilyState({
  service: CoreManagement.GetOrganizationUnitSetDetails,
  key: "CoreManagement:GetOrganizationUnitSetDetails",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.ID) {
      return req;
    }
    return {
      ...req,
      ID: parameter,
    };
  },
});
