import { ReactNode } from "react";

export const SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD = 56;

export const SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT = 56;
export const SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT =
  SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT * 5;

export const SEARCH_LIST_FIELD_SPACE_BETWEEN_BUTTON_AND_POPOVER = 10;

/**
 * Controlled component props
 */

export interface ISearchListFieldControlledMultipleSelectionProps<T> {
  /** Indicates that multiple values can be selected. */
  multiple: true;
  /** Not available for multiple selection variant. */
  value?: never;
  /** Not available for multiple selection variant. */
  setValue?: never;
  values: T[] | undefined;
  setValues: (newValue?: T[]) => void;
}

export interface ISearchListFieldControlledSingleSelectionProps<T> {
  multiple?: never;
  /** Not available for single selection variant. */
  values?: never;
  /** Not available for single selection variant. */
  setValues?: never;
  value: T | undefined;
  setValue: (newValue?: T) => void;
}

/**
 * Uncontrolled component props
 */

export interface ISearchListFieldUncontrolledMultipleSelectionProps<T> {
  /** Indicates that multiple values can be selected. */
  multiple: true;
  /** Not available for multiple selection variant. */
  defaultValue?: never;
  /** Not available for multiple selection variant. */
  setValue?: never;
  defaultValues?: T[] | undefined;
  setValues?: (newValue?: T[]) => void;
}

export interface ISearchListFieldUncontrolledSingleSelectionProps<T> {
  multiple?: never;
  defaultValue?: T;
  setValue?: (newValue?: T[]) => void;
  /** Not available for single selection variant. */
  defaultValues?: never;
  /** Not available for single selection variant. */
  setValues?: never;
}

export interface ISearchListFieldUncontrolledProps<T> {
  /** Name of the hidden input to be rendered if an uncontrolled value is selected. */
  name: string;
  /** Selector function which controls what should be rendered as the value of the hidden input. */
  uncontrolledValueSelector?: (item: T) => (Partial<T> & object) | string | number | boolean;
}

/**
 * Base component props
 */

export interface ISearchListFieldBaseProps<T> {
  /** Label of the input field. */
  label?: string;
  /** Indicates that the field should focus automatically on initial render. */
  autoFocus?: boolean;
  /**
   * Custom function which determines how the selected value should be rendered. By default, it will render:
   * - on single selection: the `labelKey` of the selected value as a string
   * - on multiple selection: a `Chip` component containing the `labelKey` of the selected value
   */
  renderValue?: (value: T) => ReactNode;
  /** Class names to be applied on different components included in the `SearchListField`. */
  classNames?: {
    /** Class name that applies on the `button` (field) component. */
    field?: string;
    /** Class name that applies on the input border component. */
    border?: string;
  };
  /** Disables interaction with the component. */
  disabled?: boolean;
  /** Component to be rendered at the end of the field. */
  endAdornment?: ReactNode;
  /** Enables error state of the input field. It has a higher priority than the `warning` state. */
  error?: boolean;
  /** Enables warning state of the input field. It has a lower priority than the `error` state. */
  warning?: boolean;
  /** Text to be displayed under the field. */
  helperText?: string;
  /** Indicates that the field is required by adding an `*` after the label. */
  required?: boolean;
  /** Enables small variant of the input. */
  small?: boolean;
  /** Enables loading state for different components of the search list field. */
  isLoading?: {
    /**
     * Shows a progress bar indicator at the top of the options container (inside the popover).
     * If no options are provided, it also displays a "Loading..." string placeholder.
     */
    options?: boolean;
    /** Shows a loading indicator at the end of the field. */
    initialValue?: boolean;
  };
  /** Defaults to `true` on multiple selection. */
  preventPopoverCloseOnChange?: boolean;
  /** Callback triggered when closing the popover. */
  onPopoverOpen?: () => void;
  /** Callback triggered when closing the popover. */
  onPopoverClose?: () => void;
  /** Wether to show the clear icon at the end of the field if it has a value. Defaults to `true`. */
  showClearIcon?: boolean;
  /**
   * Render the popover component in place instead of using a portal. Could be useful if the component is used
   * within a modal with focus trap.
   */
  disablePopoverPortal?: boolean;
}

/**
 * Options (search list) props
 */

export interface ISearchListFieldOptionsProps<T, IDKey extends keyof T, LabelKey extends keyof T> {
  /** List of the available options. */
  items: T[];
  /** Unique identifier for an option. */
  idKey: IDKey;
  /** Key used for labeling an option. */
  labelKey: LabelKey;
  /** Key used for labeling an option. It will be displayed under the primary label (`labelKey`). */
  getSecondaryLabel?: (item: T) => string | undefined;
  query?: string;
  setQuery: (newValue?: string) => void;
  /** Function that indicates if an option should be disabled. */
  isOptionDisabled?: (item: T) => boolean;
  /** Function that shows a ReactNode at the end of the option. */
  showOptionEndAdornment?: (item: T) => ReactNode;
  /**
   * If provided, it shows a "Load more" button at the end of the options list. Clicking the button
   * will trigger the specified function.
   */
  onLoadMore?: () => void;
  /** Indicates that the input should focus automatically on initial render. */
  autoFocus?: boolean;
  /** Options for component's built-in filtering. */
  filterOptions?: {
    /** Setting it to `true` will disable the implicit options filtering. */
    disabled?: boolean;
    /** Defaults to `labelKey`. */
    filterKeys?: (keyof T)[];
  };
}

export type TSearchListFieldKeyType<T> = T extends any[] ? keyof T[number] : keyof T;
