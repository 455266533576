import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { organizationUnitSetDetailsServiceState } from ".";

const useOrganizationUnitSetDetails = (ID?: number) => {
  const organizationUnitSetDetails = hooks.useGetState(
    organizationUnitSetDetailsServiceState.response(ID),
  );

  const isLoading = hooks.useIsLoading({
    state: organizationUnitSetDetailsServiceState.response(ID),
  });
  const isLoadingWithoutResponse = useMemo(
    () => isLoading && !organizationUnitSetDetails,
    [isLoading, organizationUnitSetDetails],
  );

  return {
    organizationUnitSetDetails,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export default useOrganizationUnitSetDetails;
