import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

const getCustomFieldTypesServiceState = builders.buildServiceState({
  service: CoreManagement.GetCustomFieldTypes,
  key: "CoreManagement:GetCustomFieldTypes",
  requireEmployee: true,
});

const useGetCustomFieldTypes = () => {
  const data = hooks.useGetState(getCustomFieldTypesServiceState.response);
  const isLoading = hooks.useIsLoading({ state: getCustomFieldTypesServiceState.response });

  return { data, isLoading };
};

export default useGetCustomFieldTypes;
