import type { Dispatch, SetStateAction } from "react";
import { useEffect } from "react";
import type { ColumnInstance } from "react-table";

import { SvgIcon } from "@new-black/lyra";

import type { ITableColumn } from "./table-types";

interface ITableHeaderSortingIconProps {
  column: ColumnInstance<any>;
  sortingIconHintVisible: boolean;
  setSortingIconHintVisible: Dispatch<SetStateAction<boolean>>;
}

const ARROW_ICON_CLASS_NAME = "h-4 w-4";

const TableHeaderSortingIcon = ({
  column,
  setSortingIconHintVisible: setSortingIconVisible,
  sortingIconHintVisible: sortingIconVisible,
}: ITableHeaderSortingIconProps) => {
  const tableColumn = column as Partial<ITableColumn<any>>;

  useEffect(() => {
    if (tableColumn.sortable && column.isSorted && sortingIconVisible) {
      setSortingIconVisible(false);
    }
  }, [column.isSorted, setSortingIconVisible, sortingIconVisible, tableColumn.sortable]);

  if (tableColumn.sortable) {
    if (column.isSorted) {
      return (
        <span className="absolute ml-[10px] [&_MuiSvgIcon-root]:mt-[2px]">
          {column.isSortedDesc ? (
            <SvgIcon name="arrow-down" className={ARROW_ICON_CLASS_NAME} />
          ) : (
            <SvgIcon name="arrow-up" className={ARROW_ICON_CLASS_NAME} />
          )}
        </span>
      );
    }

    if (sortingIconVisible) {
      return (
        <span className="absolute ml-[10px] [&_MuiSvgIcon-root]:mt-[2px]">
          <span className="opacity-50 ">
            <SvgIcon name="arrow-up" className={ARROW_ICON_CLASS_NAME} />
          </span>
        </span>
      );
    }

    return null;
  }

  return null;
};

export default TableHeaderSortingIcon;
