import { Fragment, MouseEvent, ReactNode } from "react";
import { useIntl } from "react-intl";

import { Popover, Transition } from "@headlessui/react";
import { MenuItem } from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";
import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import classNames from "classnames";

import { Link } from "components/routing";

interface ProfilePopoverLinkProps {
  Icon: ReactNode;
  title: string;
  to: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  close: () => void;
}

const ProfilePopoverLink = ({ close, Icon, title, to }: ProfilePopoverLinkProps) => (
  <Link to={to} className="group text-primary no-underline" onClick={close}>
    <MenuItem className="h-10">
      <div
        className={classNames(
          "h-5 w-5 text-[color:var(--color-primary-3)] group-active:text-[color:var(--color-primary-4)] group-hover:text-[color:var(--color-primary-2)] group-focus-visible:text-[color:var(--color-primary-2)]",
          "transition-colors duration-200 ease-in-out",
        )}
      >
        {Icon}
      </div>
      <div className="ml-3">{title}</div>
    </MenuItem>
  </Link>
);

const ProfilePopoverButton = () => (
  <Popover.Button
    className={classNames(
      "mt-1 h-5 w-5 cursor-pointer rounded-md border-0 bg-transparent p-0 outline-0",
      "text-[color:var(--color-primary-3)] active:text-[color:var(--color-primary-4)] hover:text-[color:var(--color-primary-2)] focus-visible:text-[color:var(--color-primary-2)]",
      "ring-[color:var(--color-primary-3)]-1 ring-offset-4 ring-offset-[color:var(--color-neutral-3)] focus-visible:ring-2",
      "transition-colors duration-200 ease-in-out",
    )}
  >
    <SvgIcon name="profile" className="m-0 h-5 w-5" />
  </Popover.Button>
);

export const ProfileButton = () => {
  const intl = useIntl();
  const user = hooks.useGetState(state.currentUser.currentUserState.response)?.User;
  return (
    <Popover>
      <ProfilePopoverButton />

      <Transition
        as={Fragment}
        enter="[transition:opacity_246ms_ease-in-out,transform_164ms_ease-in-out] origin-top-right"
        enterFrom="opacity-0 scale-x-[0.75] scale-y-[0.5625]"
        enterTo="opacity-100 transform-none"
        leave="[transition:opacity_164ms_ease-in-out,transform_246ms_ease-in-out] origin-top-right"
        leaveFrom="opacity-100 transform-none"
        leaveTo="opacity-0 scale-x-[0.75] scale-y-[0.5625]"
      >
        <Popover.Panel className="fixed right-5 top-[56px] z-10 mt-px w-screen max-w-xs transform">
          {({ close }) => (
            <div className="overflow-hidden rounded bg-surface-primary shadow-[0_1px_1px_0_rgb(0_0_0_/_14%),_0_2px_1px_-1px_rgb(0_0_0_/_12%),_0_1px_3px_0_rgb(0_0_0_/_20%)]">
              <div className="bg-surface-tertiary p-5">
                <span className="text-md block font-sans font-semibold text-primary">
                  {user?.FullName}
                </span>

                <span className="font-sans text-xs text-secondary">{user?.EmailAddress}</span>
              </div>

              <div className="my-2">
                <ProfilePopoverLink
                  to="/profile"
                  close={close}
                  Icon={<SvgIcon name="profile" className="h-5 w-5" />}
                  title={intl.formatMessage({
                    id: "generic.label.profile",
                    defaultMessage: "Profile",
                  })}
                />

                <hr className="my-2 border-0 border-t border-solid border-default" />

                <ProfilePopoverLink
                  to="/logout"
                  close={close}
                  Icon={<SvgIcon name="sign-out" className="h-5 w-5" />}
                  title={intl.formatMessage({
                    id: "generic.label.sign-out",
                    defaultMessage: "Sign out",
                  })}
                />
              </div>
            </div>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};
