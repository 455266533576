export const SortDirection = {
  Ascending: 0 as EVA.Core.SortDirection.Ascending,
  Descending: 1 as EVA.Core.SortDirection.Descending,
};

export enum PaymentCashJournalMethod {
  None = 0,
  Close = 1,
  OpenAndClose = 2,
  Ignore = 3,
}

export enum DeviceType {
  Pin = "Pin",
  ThermalPrinter = "ThermalPrinter",
  Printer = "Printer",
  CashDrawer = "CashDrawer",
  ZebraPrinter = "ZebraPrinter",
  CustomerDisplay = "CustomerDisplay",
  Safe = "Safe",
  Sidekick = "Sidekick",
  Sentinel = "Sentinel",
  FiscalThermalPrinter = "FiscalThermalPrinter",
  TalismanPrinter = "TalismanPrinter",
  Watchtower = "Watchtower",
  Void = "Void",
}

export enum UserTaskTypes {
  ShipFromStore = "ShipFromStore",
  ReceiveShipment = "ReceiveShipment",
}

export enum AuditingSettingType {
  String = 0,
  Bool = 1,
  Integer = 2,
  Decimal = 3,
}

export enum ShipmentLineDeliveryTypes {
  Default = 0,
  Surplus = 1,
  Deficiency = 2,
}

export enum TransputJobStatuses {
  New = 0,
  Running = 1,
  Finished = 2,
  Failed = -1,
}

export enum OrderAddressFieldEnum {
  ShippingAddress = "ShippingAddress",
  BillingAddress = "BillingAddress",
  PickupAddress = "PickupAddress",
}

export enum UserIdentifier {
  Default = "Default",
  Custom = "Custom",
  RequestFromFrontend = "Request from frontend",
}

export enum EResubscribeHandling {
  UpdateUserIdentifier = 0,
  KeepOriginalUserIdentifier = 1,
  Block = 2,
}

export enum RevisionStatus {
  Open = 1,
  Applied = 2,
  Inactive = 4,
}

export enum TaskState {
  Scheduled = 1,
  Running = 2,
  Completed = 3,
}

export enum InvoiceExportExternalStatus {
  None = 0,
  Pending = 1,
  Completed = 2,
  Failed = -1,
}

export enum InvoiceTypes {
  Sales = 0,
  Purchase = 1,
  ControlDocument = 2,
  InitializationDocument = 3,
  ClosingDocument = 4,
  MonthlyControlDocument = 5,
  YearlyControlDocument = 6,
  IntegrityCheckDocument = 7,
  Offer = 8,
}

export enum RepairStatus {
  WaitingForGoods = 0,
  WaitingForRepair = 1,
  WaitingForPayment = 2,
  WaitingForShipment = 3,
  Finished = 4,
  Cancelled = 5,
  WaitingForPickup = 6,
  Started = 7,
  WaitingForGoods_OnHold = 8,
  WaitingForRepair_OnHold = 9,
  WaitingForShipment_OnHold = 10,
}

export enum InteractionType {
  Communication = 0,
  Note = 1,
  Ledger = 2,
}

//TODO: ask BE to provide services for handling this dynamic enum
export enum TransputJobType {
  OrderExport = 1,
  ShipmentExport = 2,
  ReturnOrderExport = 3,
  FinancialPeriodExport = 4,
  InvoiceExport = 5,
  OrderFulfillment = 6,
}
