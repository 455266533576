import { useEffect } from "react";

import { isEqual } from "lodash";
import type { RecoilState } from "recoil";
import { useRecoilState } from "recoil";

/**
 * Handles setting the service request with the @newRequest
 * only when it exists and is differnet than the current request
 * @param serviceRequestState
 * @param newRequest
 */
const useSetRequest = <T = unknown>(serviceRequestState: RecoilState<T>, newRequest: T) => {
  const [request, setRequest] = useRecoilState(serviceRequestState);
  useEffect(() => {
    if (newRequest && !isEqual(request, newRequest)) {
      setRequest(newRequest);
    }
  }, [newRequest, request, setRequest]);
};

export default useSetRequest;
