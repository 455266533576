import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector, selectorFamily } from "recoil";

export const customFieldTypesState = builders.buildServiceState({
  service: CoreManagement.GetCustomFieldTypes,
  key: "CustomFieldTypes",
  allowEmptyRequest: true,
  requireEmployee: true,
  requireLoggedIn: true,
});

export const customFieldTypesSelector = selector({
  key: "CustomFieldTypes",
  get: ({ get }) => get(customFieldTypesState.response)?.CustomFieldTypes,
});

export const customFieldTypeIDSelector = selectorFamily<number | undefined, string | undefined>({
  key: "CustomFieldType",
  get:
    (param) =>
    ({ get }) => {
      const types = get(customFieldTypesSelector);
      return types?.find((item) => item.Name === param)?.ID;
    },
});
