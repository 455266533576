import { useMemo, useState } from "react";

import { SvgIcon, Text } from "@new-black/lyra";

import { readableFileSize } from "util/readable-file-size";

import { MediaFile } from "./media-files-card";

export const MediaFilePreview = ({
  file,
  imageSize = 50,
  placeholderImageUrl,
}: {
  file: MediaFile;
  imageSize?: number;
  placeholderImageUrl?: string;
}) => {
  const [fileError, setFileError] = useState(false);

  const isVideo = useMemo(() => file.MimeType?.startsWith("video/"), [file.MimeType]);

  const src = useMemo(() => {
    if (file.ArrayBufferData) {
      return URL.createObjectURL(new Blob([file.ArrayBufferData as ArrayBuffer]));
    }

    if (file.BlobUrl) {
      return file.BlobUrl;
    }
    return placeholderImageUrl;
  }, [file.ArrayBufferData, file.BlobUrl, placeholderImageUrl]);

  return (
    <div className="flex flex-nowrap items-start">
      <div
        className="me-4 "
        style={{
          width: imageSize,
          height: imageSize,
          backgroundColor: src ? undefined : "var(--legacy-eva-color-transparent-50)",
        }}
      >
        {fileError ? (
          <div className="flex h-full w-full items-center justify-center rounded border border-default bg-surface-secondary">
            <SvgIcon name="document" className="h-6 w-6 text-tertiary" />
          </div>
        ) : isVideo ? (
          <video style={{ width: imageSize, height: imageSize }}>
            <source src={src} type="video/mp4" />
          </video>
        ) : (
          <img
            src={src}
            alt="media-file"
            onError={() => setFileError(true)}
            style={{ width: imageSize, height: imageSize }}
          />
        )}
      </div>

      <div className="flex flex-col gap-1">
        <Text>{file.Name}</Text>
        {file.Size ? (
          <Text variant="body-small" color="secondary">
            {readableFileSize(file.Size)}
          </Text>
        ) : null}
      </div>
    </div>
  );
};
