import { useMemo } from "react";

import { QueryClient } from "@tanstack/react-query";

import { getCurrentUserLoaderQuery } from "models/users";
import { useRootRouteData } from "routes/root";
import { ICustomField } from "types/custom-field";
import { ONE_HOUR } from "util/base-values";

import useEntityTranslation, { getEntityTranslation } from "./use-entity-translation";

export const useCustomFieldLabelEntityTranslation = (customField?: ICustomField) => {
  const { currentUser } = useRootRouteData();
  const languageID = useMemo(
    () => currentUser?.value?.User.LanguageID ?? "en",
    [currentUser?.value?.User.LanguageID],
  );
  const countryID = useMemo(
    () => currentUser?.value?.User.CountryID ?? "US",
    [currentUser?.value?.User.CountryID],
  );

  const entityTranslationLabel = useEntityTranslation({
    countryID,
    languageID,
    entityID: customField?.ID?.toString(),
    fallback: customField?.DisplayName ?? customField?.Name ?? "",
  });

  return entityTranslationLabel;
};

export const getCustomFieldLabelEntityTranslation =
  (queryClient: QueryClient) => async (customField?: ICustomField) => {
    const { value: currentUserData } = await getCurrentUserLoaderQuery(queryClient, {}, ["root"], {
      staleTime: ONE_HOUR,
      cacheTime: ONE_HOUR,
    })();

    const languageID = currentUserData?.User.LanguageID ?? "en";
    const countryID = currentUserData?.User.CountryID ?? "US";

    const entityTranslationLabel = await getEntityTranslation(queryClient)({
      countryID,
      languageID,
      entityID: customField?.ID?.toString(),
      fallback: customField?.DisplayName ?? customField?.Name ?? "",
    });

    return entityTranslationLabel;
  };
