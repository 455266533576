import { useMemo } from "react";

import { Moment } from "moment";

import { isDateBetweenBounds } from "../helpers";
import { IBaseCalendarItem } from "../types";

import useCalendarItemColumns from "./use-calendar-item-columns";
import useCalendarItemMargins from "./use-calendar-item-margins";

interface IUseCalendarItemPosition {
  item: IBaseCalendarItem;
  displayedDays: Moment[];
  snapToFullDay?: boolean;
  cellWidth?: number;
}

const useCalendarItemPosition = ({
  cellWidth,
  displayedDays,
  item,
  snapToFullDay,
}: IUseCalendarItemPosition) => {
  const isStartDateBetweenBounds = useMemo(
    () => isDateBetweenBounds(item.StartDate, displayedDays),
    [displayedDays, item.StartDate],
  );

  const isEndDateBetweenBounds = useMemo(
    () => isDateBetweenBounds(item.EndDate, displayedDays),
    [displayedDays, item.EndDate],
  );

  const { endColumn, startColumn } = useCalendarItemColumns({
    item,
    displayedDays,
    snapToFullDay,
  });
  const { leftMargin, rightMargin } = useCalendarItemMargins({
    item,
    displayedDays,
    cellWidth,
  });

  return {
    startColumn,
    endColumn,
    leftMargin,
    rightMargin,
    isStartDateBetweenBounds,
    isEndDateBetweenBounds,
  };
};

export default useCalendarItemPosition;
