import MinMaxDate from "./field-groups/min-max-date";
import MinMaxLength from "./field-groups/min-max-length";
import MinMaxValues from "./field-groups/min-max-values";

interface IExtraFields {
  dataType?: EVA.Core.CustomFieldDataTypes;
}

const ExtraFields = ({ dataType }: IExtraFields) => {
  switch (dataType) {
    case 0:
    case 5:
      return <MinMaxLength />;
    case 1:
      return null;
    case 2:
    case 3:
      return <MinMaxValues fieldType={dataType === 2 ? "integer" : "decimal"} />;
    case 6:
    case 7:
      return <MinMaxDate fieldType={dataType === 6 ? "datetime" : "date"} />;
    case 4:
    default:
      return null;
  }
};

export default ExtraFields;
