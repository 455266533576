export interface ICustomFieldComponent {
  ID?: number;
  defaultValues: ICustomFieldValues;
  onSuccessCallback?: (id: number | undefined, refetchCustomField: () => void) => void;
  showOptionsTable?: boolean;
}

export interface ICustomFieldValues
  extends Omit<
    EVA.Core.Management.GetCustomFieldByIDResponse,
    "ID" | "Error" | "Metadata" | "TypeName" | "Order" | "Options" | "EnumValues"
  > {
  ID?: number;
  Order?: number;
  EnumValues?: EnumValuesType;
  IsArray?: boolean;
}

export type DataTypes =
  | "line"
  | "multiline"
  | "number"
  | "decimal"
  | "radiobuttons"
  | "checkboxes"
  | "toggle"
  | "date"
  | "datetime";

export type DataTypeProps = {
  label: string;
  value: DataTypes;
  icon: React.ReactNode;
};

export type EnumValuesType = {
  [uuid: string]: {
    key: string;
    value: string;
    error?: string;
  };
};

export const CUSTOM_FIELD_TRANSLATION_ENTITY_TYPE = "CustomField";
export const CUSTOM_FIELD_DISPLAY_NAME_ENTITY_FIELD = "DisplayName";
export const CUSTOM_FIELD_OPTION_ENTITY_FIELD = (value: string) => `Enum_${value}`;
