import type {
  ComponentProps,
  CSSProperties,
  Dispatch,
  MouseEvent,
  MutableRefObject,
  ReactElement,
  ReactNode,
  SetStateAction,
} from "react";
import { Cell, CellProps, Column, HeaderProps, Renderer, Row, SortingRule } from "react-table";

import { TextProps } from "@new-black/lyra";

import type { IPaginationTableKeys } from "./table-pagination-types";

export const TABLE_EXPANDER_COLUMN_ID = "expander";

/**
 * The shape of a value supplied to the table
 *
 * @export
 * @interface ITableValue
 * @template T
 */
export interface ITableValue<V = any> {
  label: string;
  value: V;
}

/**
 * This is the required configuration needed to populate and interact with the
 * table
 *
 * @export
 * @interface ITableConfig
 * @template T
 */
export interface ITableConfig<T extends ITableValue> {
  columns: ITableColumn<T>[];
  options?: ITableOptions;
}

export interface ITableStrings extends IPaginationTableKeys {
  noRowsAvailable?: string;
  nRowsSelected?: string;
  saveRow?: string;
  saveRows?: string;
  editRow?: string;
  editRows?: string;
  addRow?: string;
  deleteRows?: string;
  fold?: string;
  unfold?: string;
  cancel?: string;
  organizeColumns?: string;
  dragColumn?: string;
  removeColumn?: string;
  addColumn?: string;
  actionsColumnHeader?: string;
}

export interface IOrderableColumn {
  label: string;
  value: string;
}

/**
 * These are the options for configuring table
 * They allow you to set behavior and presentation details
 *
 * @export
 * @interface ITableOptions
 */
export interface ITableOptions<T extends object = any> {
  selectRows?: boolean;
  selectAll?: boolean;
  addRow?: boolean;
  /** Delete row by row. Can be optionally disabled for some rows by setting the disableDeleteRow prop in your table data  */
  deleteRow?: boolean;
  /** Edit row by row. Can be optionally disabled for some rows by setting the disableEditRow prop in your table data  */
  editRows?: boolean;
  editAllRows?: boolean;
  title?: string;
  titleSize?: TextProps["variant"];
  expanse?: "open" | "closed";
  showFooter?: boolean;
  hideColumnHeaders?: boolean;
  tableStrings?: ITableStrings;
  hidePagination?: boolean;
  disablePagination?: boolean;
  hideHeader?: boolean;
  initialRowEditable?: boolean;
  intialEditModeState?: boolean;
  showOrganizeColumns?: boolean;
  rowContainerClassName?: string | ((row: Row<T>) => string);
  subRowContainerClassName?: string | ((subRowData: T) => string);
  tableCellContainerClassName?: string | ((row: Row<T>, cell: Cell<T>) => string);
  tableCellClassName?: string | ((row: Row<T>, cell: Cell<T>) => string);
  /** Disable the form wrapper around the table
   * This is useful to avoid errors when using nested tables
   */
  disableFormWrapper?: boolean;
  stickyHeader?: boolean;
  stickyHeaderHeight?: string;
  stickyHeaderMaxHeight?: string;
  actionColumnNoMargin?: boolean;
  actionsColumnAlignent?: TableColumnAlign;
  actionColumnProps?: Partial<ITableColumn<any>>;
  selectionColumnProps?: Partial<ITableColumn<any>>;
  actionColumnHeaderClassName?: React.HTMLAttributes<HTMLDivElement>["className"];
  /** Allow for disabled rows. The property from table data which indicates if a row should be disabled must be provided  */
  disableRow?: (rowData?: T) => boolean;
  columnVerticalAlignment?: ColumnVerticalAlignmentOptions;
  /** Optional function to overwrite the default orderable column value getter, which relies on the column accessor
   * This is useful if some of your accessors are accessor function, which usually messes up the orderable column value
   * @param column The column for which to get the orderable column value
   * @returns The orderable column value (string | undeifned)
   *
   * @default (column) => column.accessor
   */
  getOrderableColumnValue?: (column: Column<T>) => string | undefined;
  noRowsAvailableTextClassname?: string;
  disableHoverHighlightEffect?: boolean | ((rowData: T) => boolean);
  /** Expandable rows
   * Sub row expansion can be controlled by using the dot notation with the row index and sub row index.
   *
   * Example: `"3.2"` will expand the third sub row of the fourth row (indexing starts at 0)
   *
   * Possible values:
   * - `true` - all (top-level) rows are expanded by default
   * - `false` - all (top-level) rows are collapsed by default
   * - `(string | number)[]` - array of row indexes that should be expanded by default
   * - `(rowData: T) => (boolean | Record<string, boolean>)` - function that returns a boolean for each row, indicating if it should be expanded by default; or an object with row indexes as keys and booleans as values, indicating if the row should be expanded by default (useful for nested rows)
   * - `{ [rowIdx: number | string]: boolean }` - object with row indexes as keys and booleans as values, indicating if the row should be expanded by default
   */
  initialExpandedRows?:
    | boolean
    | (string | number)[]
    | ((rowData: T, rowIdx: number) => boolean | Record<string, boolean>)
    | { [rowIdx: number | string]: boolean };
  /** Row footer
   *
   * A function that returns a ReactNode to be rendered as a row footer.
   * If the function returns a falsy value, no footer will be rendered for the row
   *
   * The row footer is rendered as an extra row after the row it is associated with.
   * The returened ReactNode is rendered in a `tr` element, and by default wrapped in a `td` element as well, with the `colSpan` prop set to the number of columns in the table and the padding of it set to 0 (this can be changed by setting the `renderRowFooterWithoutTD` option to `true`)
   *
   * Example:
   * ```tsx
   * const rowFooter: RowFooterFunction<typeof tableData> = (row) => {
   *   if (!hasRowFooter(row.original)) {
   *    return null;
   *   }
   *   return <MyElement />;
   * };
   * ```
   */
  rowFooter?: RowFooterFunction<T>;
  /** Render row footer without `td` element
   * - `true` - the row footer will be rendered without a `td` element wrapping it (useful if you want to render multiple columns in the row footer)
   * - `false | undefined` **(default)** - the row footer will be rendered with a `td` element wrapping it, with the `colSpan` prop set to the number of columns in the table and the padding of it set to 0 (ready to use for a single-column row footer)
   */
  renderRowFooterWithoutTD?: boolean;
  /** Render multiple footer rows
   * - `true` - the row footer will be rendered without a `tr` element wrapping it, enabling you to render multiple rows in the row footer
   * - `false | undefined` **(default)** - the row footer will be rendered with a `tr` element wrapping it
   */
  renderMultipleFooterRows?: boolean;
  /** Row footer class name
   * A class name to be applied to the newly created row footer row
   * This is useful for styling the entire row footer row container with Tailwind
   */
  rowFooterClassName?: string;

  /** Max number of recursive child rows to render on a level before showing a "Show more" button
   *
   * If the number of childrend on a level is greater than this number,
   * only the first `maxRecursiveChildRows` rows will be rendered,
   * and a "Show more" button will be rendered at the end of the rows
   * (can be customized with the `childRowShowMoreLessToggleElement` option)
   */
  maxChildRowsBeforeShowMore?: number;

  /** Custom element to render for toggling the child row "Show more" / "Show less" state
   *
   * If not provided, the default "Show more" / "Show less" LinkButton will be rendered
   */
  childRowShowMoreLessToggleElement?: (props: {
    isExpanded: boolean;
    setIsExpanded: Dispatch<SetStateAction<boolean>>;
  }) => React.ReactNode;

  /** Apply indentation to the child row "Show more" / "Show less" toggle element
   *
   * If `true`, the toggle element will be indented by the same amount as it's dictated by its depth in the tree
   */
  childRowShowMoreLessToggleRowApplyIndent?: boolean;

  /** Indent only the expand / collapse row cell, or all other cells.
   * When all other cells are indented, the expand icon is aligned at the start of the row.
   */
  indentOnlyExpandCell?: boolean;

  expandableRowsIndent?: number;

  /** Maximum depth of recursive child rows to render (root level is 0)
   * Only the first `recursionCutoffDepth` levels of recursive child rows will be rendered
   * The recursion will stop at this level.
   */
  recursionCutoffDepth?: number;
  hidePageOptionsSelect?: boolean;
  headerWrapperClassName?: string;
}

/** Row footer
 *
 * A function that returns a ReactNode to be rendered as a row footer.
 * If the function returns a falsy value, no footer will be rendered for the row
 *
 * The row footer is rendered as an extra row after the row it is associated with.
 * The returened ReactNode is rendered in a `tr` element.
 * By default it is also wrapped in a `td` element, with the `colSpan` prop set to the number of columns
 * in the table and the padding of it set to 0,
 * or not wrapped in a `td` element at all (if the `renderRowFooterWithoutTD` option is set to `true`)
 *
 * Example:
 * ```tsx
 * const rowFooter: RowFooterFunction<typeof tableData> = (row) => {
 *   if (!hasRowFooter(row.original)) {
 *    return null;
 *   }
 *   return <MyElement />;
 * };
 * ```
 */
export type RowFooterFunction<T extends object = any> = (
  row: Row<T>,
  trProps: ComponentProps<"tr">,
) => ReactNode | undefined;

/**
 * A single table row
 *
 * @export
 * @interface ITableRow
 * @template T
 */
export interface ITableRow<T extends ITableValue> {
  cells: ITableCell<T>[];
  allCells: ITableCell<T>[];
  values: { [columnId: string]: any };
  index: number;
  original: T["value"];
  subRows?: ITableRow<T>[];
  state: any;
}

/**
 * A single table cell
 *
 * @export
 * @interface ITableCell
 * @template T
 */
export interface ITableCell<T extends ITableValue> {
  column: ITableColumn<T>;
  value: T["value"];
  row: ITableRow<T>;
}

/**
 * The component properties exposed to table methods
 *
 * @export
 * @interface ITableEditComponentProps
 */
export interface ITableEditComponentProps<T extends ITableValue> {
  cell: ITableCell<T>;
  onFieldUpdate: (state: T["value"] | any) => void;
}

/**
 * A single column configuration item for the EVA table
 *
 * @export
 * @interface ITableColumn
 * @template T
 */
export interface ITableColumn<T extends ITableValue> {
  editComponent?: (props: ITableEditComponentProps<T>) => ReactElement<any>;
  availableOptions?: T[];
  Header: Renderer<HeaderProps<any>> | undefined;
  Footer?: ReactElement;
  Cell?: (props: ITableCell<T>) => ReactElement<any>;
  columnWidth?: CSSProperties["width"];
  accessor?: string;
  id?: string;
  width?: CSSProperties["width"];
  minWidth?: number;
  align?: TableColumnAlign;
  sortable?: boolean;
  background?: CSSProperties["background"];
}

export type SortDirection = "Ascending" | "Descending";

export interface ITableColumnSort {
  columnId: string;
  direction: SortDirection;
}

export interface ITableCustomFooterProps<T = unknown> {
  data: T[];
  updateData?: (data: T[]) => void;
  updateEditMode?: (value: boolean) => void;
  updateAsyncData?: (data: T[]) => Promise<boolean>;
}

export interface ITableNavigationProps {
  limit: number;
  setLimit?: (value: number) => void;
}

export interface ITableCountableNavigation extends ITableNavigationProps {
  skip: number;
  total: number;
  setSkip?: (value: number) => void;
  onNext?: never | undefined;
  showNext?: never | undefined;
  onPrevious?: never | undefined;
  showPrevious?: never | undefined;
}

export interface ITableUncountableNavigation extends ITableNavigationProps {
  skip?: never | undefined;
  total?: never | undefined;
  setSkip?: never | undefined;
  showNext: boolean;
  onNext: () => void;
  showPrevious: boolean;
  onPrevious: () => void;
}

export interface ITableCustomRowActionsProps<T = any> {
  rowData: T;
  index?: number;
  isEditMode: boolean;
}

export type ColumnVerticalAlignmentOptions = "top" | "middle";

// This is the type of the React component properties for the table
// The column type is cast to any here to prevent conversion issues between
// our external interface and the React-table typings
export type ITableProps<T = any, C = Column<any>> = (
  | ITableCountableNavigation
  | ITableUncountableNavigation
) & {
  data: T[];
  columns: C[];
  options?: ITableOptions;
  pageOptions?: number[];
  removeByIndex?: (indexes: number[]) => void;
  /** This prop is used for the Edit All functionality - if you need more granular control over the editMode, please use the `updateAsyncData` prop */
  updateData?: (data: T[]) => void;
  /** This prop is used for the Edit All functionality
   *
   * The promisified boolean return value influences the edit mode:
   *   - `true` -> the edit was successful, and the editMode can be turned off (set to `false`)
   *   - `false` -> the edit had an error / validation issue, and the editMode is turned on (set to `true`) in order for the user to do the necessary fixes
   */
  updateAsyncData?: (data: T[]) => Promise<boolean>;
  updateRow?: (rowData: T, rowIndex: number) => boolean;
  onSelectionChangeRows?: (value: number[]) => void;
  selectedRows?: number[];
  onSelectionChangeData?: (value: T[]) => void;
  selectedData?: T[];
  selectedRow?: number;
  onSelectionChangeRow?: (value?: number) => void;
  selectedRowData?: T;
  /** Use this to omit an undefined sorting state. */
  disableUndefinedSort?: boolean;
  onSelectionChangeRowData?: (value: T) => void;
  /** Use this for adding extra custom buttons on the right in the table header. When using multiple actions, don't forget to wrap everything in a `Fragment` */
  customActions?: ReactNode;
  /**
   * Use this for adding extra custom buttons on each row when using the built in edit/delete row functionality.
   * When using multiple actions, don't forget to wrap everything in a `Fragment`
   */
  customRowActions?: (props: ITableCustomRowActionsProps<T>) => ReactNode;
  /** Custom Footer is a React node you can add that can use the live data of the table and adjust it */
  customFooter?: (props: ITableCustomFooterProps<T>) => ReactNode;
  /** Will only work when it is not possible to edit the row */
  onRowClick?: (rowData: T, index?: number) => void;
  contextMenu?: IRowContextMenuItem<T>[];
  /** Boolean to trigger loading state of table */
  isLoading?: boolean;
  /** Function for creating a new row */
  createRow?: () => void;
  /** initial state for sorting */
  initialSort?: SortingRule<any>[];
  /** Function to change sorting of the table */
  onSortByChange?: (sortBy: ITableColumnSort[]) => void;
  /** List of all columns that can be added and ordered */
  orderableColumns?: IOrderableColumn[];
  /** Callback to change the order of columns */
  onColumnsOrderChange?: (newOrderedColumns: IOrderableColumn[] | undefined) => void;
  /** Option to let the component know, that it is inside a Modal / Dialog, as this will affect the rendering of the sortable columns */
  isInModal?: boolean;
  /** Reference to an add button placed outside the table component. Needed to bind the necessary event handlers */
  externalAddButtonRef?: MutableRefObject<HTMLElement | null>;
  /** Hide Actions Column header when there are no other table headers either */
  hideActionsHeader?: boolean;
  /** Key to use for accessing recursive / nested data
   * It should be present in both the parent and child data
   * It should be an array of the same type as the parent data
   */
  childPropKey?: keyof T;
  /**
   * This prop allows you to pass a custom component to be rendered underneath the reorganizable columns
   * It is useful for adding extra functionality to the reorganize columns menu (e.g. filters)
   */
  reorganizeColumnsChildren?: ReactNode;
};

export interface IRowContextMenuItem<T> {
  label: string;
  id: string;
  icon?: ReactNode;
  onContextItemClick: (rowData: T) => void;
}

export type TAnchorPosition = {
  mouseX: null | number;
  mouseY: null | number;
};

export interface IRowContextMenu<T> {
  items: IRowContextMenuItem<T>[];
  anchorPosition?: TAnchorPosition;
  activeRow?: any;
  handleClose: () => void;
}

export interface ITableLoadingState {
  limit: number;
  footerAvailable: boolean;
}

export type MousePosition = {
  mouseX: null | number;
  mouseY: null | number;
};

export type TableRowEditActionProps = {
  tooltipTitle: string;
  props: CellProps<any>;
  disabled: boolean;
};

export type TableRowDeleteActionProps = {
  tooltipTitle: string;
  disabled: boolean;
  rowIndex: number;
  removeByIndex?: (indexes: number[]) => void;
  onAfterDelete?: () => void;
};

export type TableRowSaveAndCancelActionsProps = {
  props: CellProps<any>;
  rows: Row<any>[];
  firstRowEditable: boolean;
  lastRowEditable: boolean;
  saveTooltipTitle: string;
  cancelTooltipTitle: string;
  setFirstRowEditable: (value: SetStateAction<boolean>) => void;
  setLastRowEditable: (value: SetStateAction<boolean>) => void;
  updateRow?: (rowData: any, rowIndex: number) => boolean;
  removeByIndex?: (indexes: number[]) => void;
};

export type TableRendererOptions<T = any> = {
  data: any[];
  rows: Row<any>[];
  page: Row<any>[];
  columns: Column<any>[];
  editMode: boolean;
  isPaginationNeeded: boolean;
  contextClickState: MousePosition;
  lastRowRef: MutableRefObject<any>;
  setActiveContextRow: Dispatch<any>;
  editAllRows?: boolean | undefined;
  contextMenu?: IRowContextMenuItem<any>[] | undefined;
  tableOptions?: ITableOptions;
  handleContextRightClick: (event: MouseEvent<HTMLDivElement, any>) => void;
  prepareRow: (row: Row<any>) => void;
  onRowClick?: (rowData: any, idx?: number) => void;
  hasCustomRowActions: boolean;
  columnVerticalAlignment?: ColumnVerticalAlignmentOptions;
  childPropKey?: keyof T;
  toggleRowExpanded: (id: string[], value?: boolean | undefined) => void;
  expandedState: Record<string, boolean>;
};

export type TableColumnAlign = "left" | "center" | "right";
export const DEFAULT_TABLE_PAGINATION_OPTIONS = [5, 10, 25, 50, 100];
