import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const countriesListServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
  service: Core.ListCountries,
  defaultRequest: { FetchAll: true },
  key: "TaxManagement:Core.ListCountries",
});

export const countriesListResponseSelector = selector({
  key: "TaxManagement:Core.ListCountries:response",
  get: ({ get }) => get(countriesListServiceState.response)?.Result,
});
