import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getAvailableSettingsQuery } from "models/settings";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";

export type AvailableSettingsSearchListFieldItem =
  EVA.Core.Management.GetAvailableSettingsResponse.Setting;

type BaseSearchListFieldGeneratorProps = ISearchListFieldGeneratorProps<
  CoreManagement.GetAvailableSettings,
  AvailableSettingsSearchListFieldItem,
  string
>;

export const generateAvailableSettingsLyraSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: BaseSearchListFieldGeneratorProps["frontendFilter"];
}) =>
  SearchListFieldGenerator<
    CoreManagement.GetAvailableSettings,
    AvailableSettingsSearchListFieldItem,
    string
  >({
    getItemFromResponse: (response) => response?.Settings ?? [],
    getItemId: (item) => item.Key,
    getLabel: (item) => item.Key,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.setting",
      defaultMessage: "Setting",
    }),
    useItemByID: (key, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.Key === key),
        [key, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (keys, itemsFromList) => {
      const items = useMemo(
        () =>
          keys
            ?.map((key) => itemsFromList?.find((item) => item.Key === key))
            ?.filter((item): item is AvailableSettingsSearchListFieldItem => !!item),
        [keys, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getAvailableSettingsQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const GeneratedAvailableSettingsLyraSearchListField =
  generateAvailableSettingsLyraSearchListField({});
