import { useMemo } from "react";

import useGetAssetsUrl from "hooks/use-get-assets-url";
import { IProduct } from "types/product";

export function useProductImages({ product, size = 500 }: { product?: IProduct; size?: number }) {
  const assetsUrl = useGetAssetsUrl();

  const galleryImageBlobs = useMemo(
    () => product?.product_media?.gallery,
    [product?.product_media?.gallery],
  );

  const primaryImageBlob = useMemo(
    () => product?.product_media?.primary_image?.blob,
    [product?.product_media?.primary_image?.blob],
  );

  const galleryImages = useMemo(() => {
    const allUniqueBlobs = Array.from(
      new Set(
        [...(galleryImageBlobs ?? []), primaryImageBlob].filter((blob): blob is string => !!blob),
      ),
    );

    return allUniqueBlobs.map((blob, idx) => ({
      src: `${assetsUrl}/image/${size}/${size}/${blob}.png`,
      alt: `img-${idx}`,
      name: `${blob}.png`,
    }));
  }, [assetsUrl, galleryImageBlobs, primaryImageBlob, size]);

  return galleryImages;
}
