import { useMemo } from "react";
import { IntlShape } from "react-intl";

import {
  CFCInferredCFType,
  CFCInferredFieldType,
  CFCTypedValueCustomField,
} from "components/suite-composite/custom-field-consumer";
import { ICFCCustomFieldValueTypeWithArrays } from "components/suite-composite/custom-field-consumer/custom-field-consumer.types";
import useListCustomFieldDataTypeOperators from "store/list-custom-field-data-type-operators/use-list-custom-field-data-type-operators";
import {
  CustomFieldPrimitiveType,
  CustomFieldValueOperator,
  ICustomField,
} from "types/custom-field";
import { CustomFieldDataType } from "types/custom-field-data-types";

export const mapCustomFieldConsumerValueToEVACustomFieldValue = <
  Type extends CFCTypedValueCustomField,
  ValueType = CFCInferredFieldType<Type>,
  FieldType = CFCInferredCFType<Type>,
>(
  type: FieldType,
  value: ValueType,
): EVA.Core.CustomFieldValue => {
  const getArrayValue = <T>(
    arr: T[] | undefined,
    key: keyof EVA.Core.CustomFieldValue,
  ): EVA.Core.CustomFieldValue => ({
    ArrayValues: (arr ?? []).map((value) => ({
      [key]: value,
    })),
  });

  switch (type as ICFCCustomFieldValueTypeWithArrays) {
    case "SingleLineStringValue":
    case "MultiLineStringValue":
    case "SingleEnumValue":
      return { StringValue: value as string };
    case "IntegerValue":
    case "DecimalValue":
      return { NumberValue: value as number };
    case "BoolValue":
      return { BoolValue: (value ?? false) as boolean };
    case "DateValue":
    case "DateTimeValue":
      return { DateTimeValue: value as string };
    case "MultiIntegerValue":
    case "MultiDecimalValue":
      return getArrayValue(value as number[], "NumberValue");
    case "MultiBoolValue":
      return getArrayValue(value as boolean[], "BoolValue");
    case "MultiDateValue":
    case "MultiDateTimeValue":
      return getArrayValue(value as string[], "DateTimeValue");
    case "MultiSingleLineStringValue":
    case "MultiMultiLineStringValue":
    case "MultiEnumValue":
      return getArrayValue(value as string[], "StringValue");
    default:
      return {};
  }
};

/**
 * Decodes a custom field value from the API to a value that can be used in a form.
 * @param value The custom field value from the API
 * @returns The decoded value
 * @example
 * getCustomFieldValueFromEVACustomFieldValue({ StringValue: "foo" }); // "foo"
 * getCustomFieldValueFromEVACustomFieldValue({ NumberValue: 42 }); // 42
 * getCustomFieldValueFromEVACustomFieldValue({ BoolValue: true }); // true
 * getCustomFieldValueFromEVACustomFieldValue({ DateTimeValue: "2021-01-01T00:00:00Z" }); // "2021-01-01T00:00:00Z"
 * getCustomFieldValueFromEVACustomFieldValue({ ArrayValues: [{ StringValue: "foo" }, { StringValue: "bar" }] }); // ["foo", "bar"]
 * getCustomFieldValueFromEVACustomFieldValue(); // undefined
 * getCustomFieldValueFromEVACustomFieldValue({}); // undefined
 */
export const getCustomFieldValueFromEVACustomFieldValue = (
  valueObject?: EVA.Core.CustomFieldValue,
  disableArrayValues?: boolean,
): CustomFieldPrimitiveType => {
  if (!valueObject) {
    return undefined;
  }
  if (valueObject?.StringValue) {
    return valueObject.StringValue;
  }
  if (valueObject?.NumberValue) {
    return valueObject.NumberValue;
  }
  if (valueObject?.BoolValue !== undefined) {
    return valueObject.BoolValue ?? false;
  }
  if (valueObject?.DateTimeValue) {
    return valueObject.DateTimeValue;
  }
  if (!disableArrayValues && valueObject?.ArrayValues) {
    return valueObject.ArrayValues.map((v) =>
      getCustomFieldValueFromEVACustomFieldValue(v, true),
    ) as (string | undefined)[] | (boolean | undefined)[] | (number | undefined)[];
  }
  return undefined;
};

export const translateEVACustomFieldResponseToCustomFieldValue = (
  customFieldValue: EVA.Core.CustomFieldResponse,
): EVA.Core.CustomFieldKeyValue | undefined => {
  const translatedValue = ((): EVA.Core.CustomFieldValue | undefined => {
    const getCFValue = (type: keyof EVA.Core.CustomFieldValue) =>
      customFieldValue?.IsArray
        ? {
            ArrayValues: ((customFieldValue?.Value ?? []) as any[]).map((val) => ({
              [type]: type === "BoolValue" ? val ?? false : val,
            })),
          }
        : {
            [type]:
              type === "BoolValue" ? customFieldValue?.Value ?? false : customFieldValue?.Value,
          };

    switch (customFieldValue.DataTypeID as CustomFieldDataType) {
      case CustomFieldDataType.Integer:
      case CustomFieldDataType.Decimal:
        return getCFValue("NumberValue");
      case CustomFieldDataType.Bool:
        return getCFValue("BoolValue");
      case CustomFieldDataType.Date:
      case CustomFieldDataType.DateTime:
        return getCFValue("DateTimeValue");
      case CustomFieldDataType.Enum:
      case CustomFieldDataType.String:
      case CustomFieldDataType.Text:
        return getCFValue("StringValue");
      default:
        return undefined;
    }
  })();
  return { CustomFieldID: customFieldValue.CustomFieldID, ...translatedValue };
};

export const CustomFieldConsumerToEVACustomFieldValueTypeMapper: Record<
  ICFCCustomFieldValueTypeWithArrays,
  | "StringValue"
  | "NumberValue"
  | "BoolValue"
  | "DateTimeValue"
  | ["ArrayValues", "StringValue" | "NumberValue" | "BoolValue" | "DateTimeValue"]
> = {
  SingleLineStringValue: "StringValue",
  MultiLineStringValue: "StringValue",
  IntegerValue: "NumberValue",
  DecimalValue: "NumberValue",
  BoolValue: "BoolValue",
  DateValue: "DateTimeValue",
  DateTimeValue: "DateTimeValue",
  SingleEnumValue: "StringValue",
  MultiEnumValue: ["ArrayValues", "StringValue"],
  MultiBoolValue: ["ArrayValues", "BoolValue"],
  MultiIntegerValue: ["ArrayValues", "NumberValue"],
  MultiDecimalValue: ["ArrayValues", "NumberValue"],
  MultiDateTimeValue: ["ArrayValues", "DateTimeValue"],
  MultiDateValue: ["ArrayValues", "DateTimeValue"],
  MultiMultiLineStringValue: ["ArrayValues", "StringValue"],
  MultiSingleLineStringValue: ["ArrayValues", "StringValue"],
};

export const convertEVACustomFieldTypeToCustomFieldConsumerType = (
  dataType: CustomFieldDataType,
  isArray?: boolean,
): ICFCCustomFieldValueTypeWithArrays => {
  switch (dataType) {
    case CustomFieldDataType.Bool:
      return `${isArray ? "Multi" : ""}BoolValue`;
    case CustomFieldDataType.Decimal:
      return `${isArray ? "Multi" : ""}DecimalValue`;
    case CustomFieldDataType.Integer:
      return `${isArray ? "Multi" : ""}IntegerValue`;
    case CustomFieldDataType.Date:
    case CustomFieldDataType.DateTime:
      return `${isArray ? "Multi" : ""}DateTimeValue`;
    case CustomFieldDataType.Enum:
      return isArray ? "MultiEnumValue" : "SingleEnumValue";
    case CustomFieldDataType.Text:
      return `${isArray ? "Multi" : ""}MultiLineStringValue`;
    default:
      return `${isArray ? "Multi" : ""}SingleLineStringValue`;
  }
};

export const mapCustomFieldPrimitiveValueToEVACustomFieldValue = (
  type: ICFCCustomFieldValueTypeWithArrays,
  value?: CustomFieldPrimitiveType,
  isRequired?: boolean,
  allowUndefinedValues?: boolean,
): EVA.Core.CustomFieldValue | undefined => {
  const mappedType = CustomFieldConsumerToEVACustomFieldValueTypeMapper[type];
  if (Array.isArray(mappedType)) {
    if (value === undefined || !Array.isArray(value)) {
      return undefined;
    }
    return {
      [mappedType[0]]: ((value ?? []) as any[])
        ?.filter((valueItem) => allowUndefinedValues || valueItem !== undefined)
        ?.map((valueItem) => ({
          [mappedType[1]]:
            mappedType[1] === "BoolValue" && isRequired ? valueItem ?? false : valueItem,
        })),
    };
  }
  return { [mappedType]: mappedType === "BoolValue" && isRequired ? value ?? false : value };
};

export const decodeCustomFieldResponse = (customField: EVA.Core.CustomFieldResponse) =>
  mapCustomFieldPrimitiveValueToEVACustomFieldValue(
    convertEVACustomFieldTypeToCustomFieldConsumerType(
      customField.DataTypeID as number,
      customField.IsArray,
    ),
    customField.Value,
    customField.Options.IsRequired,
  );

export const formatCustomFieldValueDisplay =
  (intl: IntlShape) =>
  (
    customField: ICustomField,
    value: CustomFieldPrimitiveType,
    /** Whether to join the array values by comma (which results in a single string) or not. Defaults to `true`. */
    joinArrayValues = true,
  ) => {
    const emptyValue = "-";

    switch (customField.DataType) {
      case CustomFieldDataType.Bool:
        if (customField.IsArray && value && Array.isArray(value)) {
          const mappedValue = value.map((value) =>
            value
              ? intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" })
              : intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" }),
          );

          return joinArrayValues ? mappedValue.join(", ") : mappedValue;
        }

        return value
          ? intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" })
          : intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" });

      case CustomFieldDataType.Decimal:
      case CustomFieldDataType.Integer:
      case CustomFieldDataType.String:
      case CustomFieldDataType.Text: {
        if (customField.IsArray && value && Array.isArray(value)) {
          const mappedValue = value.map((value) => value?.toString() ?? emptyValue);

          return joinArrayValues ? mappedValue.join(", ") : mappedValue;
        }

        return value?.toString() ?? emptyValue;
      }

      case CustomFieldDataType.Date: {
        if (customField.IsArray) {
          if (Array.isArray(value)) {
            const mappedValue = ((value ?? []) as any[])
              .filter((value): value is string | undefined => !value || typeof value === "string")
              .map((value) => (value ? intl.formatDate(value) : emptyValue));

            return joinArrayValues ? mappedValue.join(", ") : mappedValue;
          }

          return emptyValue;
        }

        return typeof value === "string" ? intl.formatDate(value) : emptyValue;
      }

      case CustomFieldDataType.DateTime: {
        if (customField.IsArray) {
          if (Array.isArray(value)) {
            const mappedValue = ((value ?? []) as any[])
              .filter((value): value is string | undefined => !value || typeof value === "string")
              .map((value) =>
                value
                  ? intl.formatDate(value, { dateStyle: "short", timeStyle: "short" })
                  : emptyValue,
              );

            return joinArrayValues ? mappedValue.join(", ") : mappedValue;
          }

          return emptyValue;
        }

        return typeof value === "string"
          ? intl.formatDate(value, { dateStyle: "short", timeStyle: "short" })
          : emptyValue;
      }

      case CustomFieldDataType.Enum: {
        if (value && customField?.EnumValues) {
          const enumValueKeys = Object.keys(customField?.EnumValues);

          const values = enumValueKeys
            .map((key) => {
              if (
                key === value.toString() ||
                (Array.isArray(value) && value.map(String).includes(key))
              ) {
                return customField.EnumValues?.[key];
              }
              return undefined;
            })
            .filter((value): value is string => !!value);

          if (!values.length) return emptyValue;

          return joinArrayValues ? values.join(", ") : values;
        }

        return emptyValue;
      }

      default:
        return emptyValue;
    }
  };

/**
 * Returns a list of operators for custom fields of a specific custom field DataType ID.
 * It is based on `ListCustomFIeldDataTypeOperators` service.
 */
export const useCustomFieldOperators = (dataTypeID?: number) => {
  const { data: customFieldDataTypesOperatorsResponse } = useListCustomFieldDataTypeOperators();

  const availableOperators = useMemo<CustomFieldValueOperator[]>(
    () =>
      customFieldDataTypesOperatorsResponse
        ?.find((item) => item.DataType === dataTypeID)
        ?.Operators?.map((operator) => operator as number) ?? [],
    [customFieldDataTypesOperatorsResponse, dataTypeID],
  );

  return availableOperators;
};
