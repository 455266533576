import useListCustomFields from "store/list-custom-fields/use-list-custom-fields";
import { CustomFieldType } from "types/custom-field";
import { CustomFieldDataType } from "types/custom-field-data-types";

import useCustomFieldsTypeId from "./use-custom-fields-type-id";

const useUserCustomFields = (dataType?: CustomFieldDataType) => {
  const { customFieldTypeID: userCustomFieldsTypeId } = useCustomFieldsTypeId(CustomFieldType.User);

  const { data: userCustomFieldsResponse, isLoading } = useListCustomFields(
    {
      TypeID: userCustomFieldsTypeId,
      DataType: dataType as number,
    },
    !userCustomFieldsTypeId,
  );

  return { userCustomFieldsResponse, isLoading };
};

export default useUserCustomFields;
