import { ComponentPropsWithoutRef } from "react";

// TODO: replace MUI icon
import Brightness1Icon from "@material-ui/icons/Brightness1";
import classNames from "classnames";

const StyledIconBase = ({
  className,
  ...props
}: ComponentPropsWithoutRef<typeof Brightness1Icon>) => (
  <Brightness1Icon
    {...props}
    className={classNames(
      "mb-[7px] h-[7px] w-[7px] text-[7px]",
      "[&_.MuiSvgIcon-colorPrimary]:!text:[color:var(--legacy-eva-color-primary)]",
      "[&_.MuiSvgIcon-colorSecondary]:!text:[color:var(--eva-color-secondary)]",
      "[&_.MuiSvgIcon-colorError]:!text:[color:var(--legacy-eva-color-error)]",
      className,
    )}
  />
);

interface ITabRequiredLabel {
  label: string | JSX.Element;
  color?: "inherit" | "secondary" | "primary" | "action" | "disabled" | "error" | undefined;
}

const TabWithValuesLabel = ({ color, label }: ITabRequiredLabel) => (
  <div>
    {label}
    <StyledIconBase color={color ?? "primary"} />
  </div>
);

export const ErrorDot = () => <StyledIconBase color="error" />;

export default TabWithValuesLabel;
