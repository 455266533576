import { createContext, ReactNode, useContext, useMemo } from "react";

import { EntityTranslationFieldIdentifiers, EntityTranslationFieldTranslations } from "../types";

type LocalizationFieldProviderProps = EntityTranslationFieldIdentifiers & {
  translations?: EntityTranslationFieldTranslations;
  children?: ReactNode;
};

const EntityTranslationFieldContext = createContext({} as LocalizationFieldProviderProps);

const EntityTranslationFieldProvider = ({
  children,
  entityField,
  entityID,
  entityType,
  translations,
}: LocalizationFieldProviderProps) => {
  const value = useMemo(
    () => ({ entityField, entityID, entityType, translations }),
    [entityField, entityID, entityType, translations],
  );
  return (
    <EntityTranslationFieldContext.Provider value={value}>
      {children}
    </EntityTranslationFieldContext.Provider>
  );
};

const useEntityTranslationFieldSettings = () => {
  const context = useContext(EntityTranslationFieldContext);
  if (context === undefined) {
    throw new Error(
      "useEntityTranslationFieldSettings must be used within a EntityTranslationFieldProvider",
    );
  }
  return context;
};

export { EntityTranslationFieldProvider, useEntityTranslationFieldSettings };
