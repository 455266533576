import { useIntl } from "react-intl";

import { FieldGroup, PasswordTextField, TextField } from "@new-black/lyra";

import {
  LoginCredentialsFormProps,
  loginCredentialsFormSchemaKeys,
} from "./login-credentials-form-fields.types";

const formKeys = loginCredentialsFormSchemaKeys;

export const LoginCredentialsFormFields = ({ errors }: LoginCredentialsFormProps) => {
  const intl = useIntl();

  return (
    <FieldGroup>
      <TextField
        name={formKeys.Username}
        label={intl.formatMessage({
          id: "login.username",
          defaultMessage: "Username",
        })}
        isRequired
        autoFocus
        errorMessage={errors?.formErrors?.Username}
      />
      <PasswordTextField
        name={formKeys.Password}
        label={intl.formatMessage({
          id: "login.password",
          defaultMessage: "Password",
        })}
        isRequired
        errorMessage={errors?.formErrors?.Password}
      />
    </FieldGroup>
  );
};
