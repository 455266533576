import { useCallback, useMemo } from "react";

const MIN_SAFE_YEAR = 1900;
const MAX_SAFE_YEAR = 2100;

const useSafeDatePickerDates = ({
  endDate,
  startDate,
}: {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}) => {
  const getSafeDate = useCallback((date: moment.Moment | null) => {
    if (!date) {
      return null;
    }
    const year = date.year();
    // ignore any dates outside the interval
    if (year < MIN_SAFE_YEAR || year > MAX_SAFE_YEAR) {
      return null;
    }

    return date;
  }, []);

  const safeStartDate = useMemo(() => getSafeDate(startDate), [getSafeDate, startDate]);
  const safeEndDate = useMemo(() => getSafeDate(endDate), [endDate, getSafeDate]);

  return {
    safeStartDate,
    safeEndDate,
  };
};

export default useSafeDatePickerDates;
