import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Text as LyraText } from "@new-black/lyra";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useQuery } from "@tanstack/react-query";
import classNames from "classnames";

import Text from "components/suite-ui/text";
import { listOrganizationUnitSetsQuery } from "models/organization-unit-sets";

import SingleMultiSwitch from "../common/single-multi-switch";
import { ouSetArrayService } from "../state";
import {
  IOuAndSetSelectorFormikProps,
  IOuAndSetSelectorOptions,
  TSingleMultiOptionValue,
} from "../types";

import MultiOUSetSelector, { MultiLyraOUSetSelector } from "./multi-ou-set-selector";
import SingleOUSetSelector, { SingleLyraOUSetSelector } from "./single-ou-set-selector";
import { IOUSetMultiSelect, IOUSetSingleSelect } from "./types";

export type IOUSetSelectProps = IOuAndSetSelectorFormikProps & {
  options?: IOuAndSetSelectorOptions;
  initialSwitchOption?: TSingleMultiOptionValue;
  filters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>;
  optionsLabels?: { single?: string; multi?: string };
  inputLabels?: { single?: string; multi?: string };
  singleMultiSwitchHelperText?: string;
  hideMultiDefaultHelperText?: boolean;
  disableSearchListFieldPopoverPortal?: boolean;
  onSingleMultiSegmentedButtonSwitch?: (newValue: TSingleMultiOptionValue) => void;
  componentClassNames?: {
    switch?: {
      container?: string;
    };
  };
  onSingleSelectionOUSetChange?: (value?: EVA.Core.ListOrganizationUnitSetsItem) => void;
  onMultiSelectionOUSetChange?: (value?: EVA.Core.ListOrganizationUnitSetsItem[]) => void;
} & (IOUSetSingleSelect | IOUSetMultiSelect);

/**
 * Component that allows the user to select an OUSet with or without subsets,
 * to create an ad-hoc OUSet and to display the OUs that are included in the selected OUSet
 */
export const OUSetSelect = (props: IOUSetSelectProps) => {
  const {
    componentClassNames,
    helperText,
    hideMultiDefaultHelperText,
    initialSwitchOption,
    inputHelperTexts,
    inputLabels,
    multi,
    onMultiSelectionOUSetChange,
    onSingleMultiSegmentedButtonSwitch,
    onSingleSelectionOUSetChange,
    optionsLabels,
    passive,
    selectedOUSetID,
    selectedOUSetIDs,
    setSelectedOUSetID,
    setSelectedOUSetIDs,
    singleMultiSwitchHelperText,
  } = props;

  // make sure the singleMultiSwitch is updated automatically only when we have an initial value
  const [shouldUpdateSingleMulti, setShouldUpdateSingleMulti] = useState(
    selectedOUSetID !== undefined || !!selectedOUSetIDs?.length,
  );
  const [singleMulti, setSingleMulti] = useState<TSingleMultiOptionValue>(
    initialSwitchOption ?? "single",
  );

  const selectedSingleOUSet = hooks.useGetState(ouSetArrayService.ouSetById(selectedOUSetID));

  const selectedMultiOUSets = hooks.useGetState(ouSetArrayService.ouSetIdArray(selectedOUSetIDs));

  useEffect(() => {
    onSingleSelectionOUSetChange?.(selectedSingleOUSet);
  }, [onSingleSelectionOUSetChange, selectedSingleOUSet]);

  useEffect(() => {
    onMultiSelectionOUSetChange?.(selectedMultiOUSets);
  }, [onMultiSelectionOUSetChange, selectedMultiOUSets]);

  useEffect(() => {
    if (selectedSingleOUSet && shouldUpdateSingleMulti) {
      const singleMultiSwitchValue =
        selectedSingleOUSet.OrganizationUnitSubsetCount > 0 ? "multi" : "single";

      setSingleMulti(singleMultiSwitchValue);
      onSingleMultiSegmentedButtonSwitch?.(singleMultiSwitchValue);
      // make sure the single multi switch is not updated again when selecting a new ouset
      setShouldUpdateSingleMulti(false);
    }
  }, [
    shouldUpdateSingleMulti,
    onSingleMultiSegmentedButtonSwitch,
    selectedSingleOUSet,
    setSingleMulti,
  ]);

  useEffect(() => {
    if (selectedMultiOUSets && shouldUpdateSingleMulti) {
      setSingleMulti(
        selectedMultiOUSets.reduce(
          (prev, current) => prev || current.OrganizationUnitSubsetCount > 0,
          false,
        )
          ? "multi"
          : "single",
      );
      // make sure the single multi switch is not updated again when selecting a new ouset
      setShouldUpdateSingleMulti(false);
    }
  }, [shouldUpdateSingleMulti, selectedMultiOUSets, setSingleMulti]);

  useEffect(() => {
    if (initialSwitchOption) {
      setSingleMulti(initialSwitchOption);
    }
  }, [initialSwitchOption, setSingleMulti]);

  return (
    <>
      {passive ? null : (
        <div className={componentClassNames?.switch?.container}>
          <SingleMultiSwitch
            activeOption={singleMulti}
            setActiveOption={(newValue) => {
              setSingleMulti(newValue);
              onSingleMultiSegmentedButtonSwitch?.(newValue);
            }}
            resetSelection={() => {
              if (multi) {
                setSelectedOUSetIDs?.(undefined);
                return;
              }
              setSelectedOUSetID?.(undefined);
            }}
            optionsLabels={optionsLabels}
          />
        </div>
      )}
      {singleMultiSwitchHelperText ? (
        <div className="mb-5">
          <Text variant="caption">{singleMultiSwitchHelperText}</Text>
        </div>
      ) : null}
      {singleMulti === "single" ? (
        <SingleOUSetSelector
          {...props}
          label={inputLabels?.single}
          helperText={inputHelperTexts?.single ?? helperText}
        />
      ) : (
        <MultiOUSetSelector
          {...props}
          hideDefaultHelperText={hideMultiDefaultHelperText}
          helperText={inputHelperTexts?.multi ?? helperText}
          label={inputLabels?.multi}
        />
      )}
    </>
  );
};

export const OUSetLyraSelect = (
  props: IOUSetSelectProps & {
    hideHintLabel?: boolean;
    hideInputHeader?: boolean;
    disabledItemIdKeys?: string[];
    disableClearLogic?: boolean;
  },
) => {
  const intl = useIntl();

  const {
    componentClassNames,
    helperText,
    hideHintLabel,
    hideInputHeader = false,
    hideMultiDefaultHelperText,
    initialSwitchOption,
    inputHelperTexts,
    inputLabels,
    multi,
    onSingleMultiSegmentedButtonSwitch,
    optionsLabels,
    passive,
    selectedOUSetID,
    selectedOUSetIDs,
    setSelectedOUSetID,
    setSelectedOUSetIDs,
    singleMultiSwitchHelperText,
  } = props;

  // make sure the singleMultiSwitch is updated automatically only when we have an initial value
  const [shouldUpdateSingleMulti, setShouldUpdateSingleMulti] = useState(
    selectedOUSetID !== undefined || !!selectedOUSetIDs?.length,
  );
  const [singleMulti, setSingleMulti] = useState<TSingleMultiOptionValue>(
    initialSwitchOption ?? "single",
  );

  const singleOUSetQuery = useQuery({
    ...listOrganizationUnitSetsQuery(
      selectedOUSetID
        ? {
            PageConfig: {
              Limit: 1,
              Filter: {
                ID: selectedOUSetID,
              },
            },
          }
        : undefined,
    ),
    enabled: !!selectedOUSetID,
  });

  const selectedSingleOUSet = useMemo(
    () => singleOUSetQuery.data?.Result?.Page?.find((ouSet) => ouSet.ID === selectedOUSetID),
    [singleOUSetQuery.data, selectedOUSetID],
  );

  const multiOuSetsQuery = useQuery({
    ...listOrganizationUnitSetsQuery(
      selectedOUSetIDs?.length
        ? {
            PageConfig: {
              Limit: 1,
              Filter: {
                IDs: selectedOUSetIDs,
              },
            },
          }
        : undefined,
    ),
    enabled: !!selectedOUSetIDs?.length,
  });

  const selectedMultiOUSets = useMemo(
    () => multiOuSetsQuery.data?.Result?.Page,
    [multiOuSetsQuery.data?.Result?.Page],
  );

  useEffect(() => {
    if (selectedSingleOUSet && shouldUpdateSingleMulti) {
      const singleMultiSwitchValue =
        selectedSingleOUSet.OrganizationUnitSubsetCount > 0 ? "multi" : "single";

      setSingleMulti(singleMultiSwitchValue);
      onSingleMultiSegmentedButtonSwitch?.(singleMultiSwitchValue);
      // make sure the single multi switch is not updated again when selecting a new ouset
      setShouldUpdateSingleMulti(false);
    }
  }, [
    shouldUpdateSingleMulti,
    onSingleMultiSegmentedButtonSwitch,
    selectedSingleOUSet,
    setSingleMulti,
  ]);

  useEffect(() => {
    if (selectedMultiOUSets && shouldUpdateSingleMulti) {
      setSingleMulti(
        selectedMultiOUSets.reduce(
          (prev, current) => prev || current.OrganizationUnitSubsetCount > 0,
          false,
        )
          ? "multi"
          : "single",
      );
      // make sure the single multi switch is not updated again when selecting a new ouset
      setShouldUpdateSingleMulti(false);
    }
  }, [shouldUpdateSingleMulti, selectedMultiOUSets, setSingleMulti]);

  useEffect(() => {
    if (initialSwitchOption) {
      setSingleMulti(initialSwitchOption);
    }
  }, [initialSwitchOption, setSingleMulti]);

  return (
    <div className={classNames("flex flex-col gap-4", passive ? "" : "min-h-[123px]")}>
      {passive ? null : (
        <div className={componentClassNames?.switch?.container}>
          <SingleMultiSwitch
            activeOption={singleMulti}
            setActiveOption={(newValue) => {
              setSingleMulti(newValue);
              onSingleMultiSegmentedButtonSwitch?.(newValue);
            }}
            resetSelection={() => {
              if (multi) {
                setSelectedOUSetIDs?.(undefined);
                return;
              }
              setSelectedOUSetID?.(undefined);
            }}
            optionsLabels={{
              multi:
                optionsLabels?.multi ??
                intl.formatMessage({ id: "generic.label.set", defaultMessage: "Set" }),
              single:
                optionsLabels?.single ??
                intl.formatMessage({ id: "generic.label.single", defaultMessage: "Single" }),
            }}
            hideHintLabel
            hideInputHeader={hideInputHeader}
            label={intl.formatMessage({
              id: "generic.label.organization-unit",
              defaultMessage: "Organization unit",
            })}
          />
        </div>
      )}
      {singleMultiSwitchHelperText ? (
        <div className="mb-4">
          <LyraText variant="body-small">{singleMultiSwitchHelperText}</LyraText>
        </div>
      ) : null}
      {singleMulti === "single" ? (
        <SingleLyraOUSetSelector
          {...props}
          hideHintLabel={hideHintLabel}
          label={inputLabels?.single}
          helperText={inputHelperTexts?.single ?? helperText}
        />
      ) : (
        <MultiLyraOUSetSelector
          {...props}
          hideHintLabel
          hideDefaultHelperText={hideMultiDefaultHelperText}
          helperText={inputHelperTexts?.multi ?? helperText}
          label={inputLabels?.multi}
        />
      )}
    </div>
  );
};
