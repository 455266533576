import { useIntl } from "react-intl";

import * as yup from "yup";

const useValuePropertyValidationSchema = () => {
  const intl = useIntl();

  const validationSchema = yup.object().shape({
    keyName: yup
      .string()
      .nullable()
      .required(
        intl.formatMessage({
          id: "product-filter.modal.product-property.validation.required",
          defaultMessage: "This field is required",
        }),
      ),
    Values: yup
      .array()
      .min(
        1,
        intl.formatMessage({
          id: "product-filter.modal.values.values.validation.at-least-one",
          defaultMessage: "You should add at lease one value",
        }),
      )
      .required(
        intl.formatMessage({
          id: "product-filter.modal.values.values.validation.required",
          defaultMessage: "This field is required",
        }),
      ),
  });

  return validationSchema;
};

export default useValuePropertyValidationSchema;
