import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listTaxCodesQuery, useListTaxCodesQuery } from "models/tax-codes";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateTaxCodeSearchListField = (defaultRequest: Partial<EVA.Core.ListTaxCodes>) =>
  SearchListFieldGenerator<
    Core.ListTaxCodes,
    {
      ID: number;
      Name: string;
    },
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((taxCode) => ({
        ID: taxCode.ID,
        Name: taxCode.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.tax-code",
      defaultMessage: "Tax code",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listTaxCodesQuery,
        initialRequest: {
          ...defaultRequest,
          PageConfig: {
            Limit: 10,
            ...defaultRequest?.PageConfig,
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          PageConfig: {
            ...prev?.PageConfig,
            Filter: { ...prev?.PageConfig?.Filter, Name: newValue },
          },
        }),
      }),
    useItemByID: (id) => {
      const { data, isFetching } = useListTaxCodesQuery(
        id ? { PageConfig: { Filter: { ID: id } } } : undefined,
        {},
      );

      const taxCode = useMemo(
        () => data?.Result?.Page?.find((item) => item.ID === id),
        [data?.Result?.Page, id],
      );

      return {
        data: taxCode,
        isLoading: isFetching,
      };
    },
  });

export const {
  MultiIDSearchListField: TaxCodeMultiIDSearchListField,
  MultiSearchListField: TaxCodeMultiSearchListField,
  SingleIDSearchListField: TaxCodeSingleIDSearchListField,
  SingleSearchListField: TaxCodeSingleSearchListField,
} = generateTaxCodeSearchListField({});
