import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listScriptsQuery, useGetScriptQuery } from "models/scripts";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

export const useScriptByID = (id?: number) => {
  const request = useMemo(() => (id ? { ID: id } : undefined), [id]);
  const { data, isFetching } = useGetScriptQuery(request, {});
  const script = useMemo(
    () => (id && data?.ID && data?.Name ? { ID: data.ID, Name: data.Name } : undefined),
    [data?.ID, data?.Name, id],
  );
  return {
    data: script,
    isLoading: id ? isFetching : false,
  };
};

export const generateScriptAutocomplete = (initialRequest: EVA.Core.ListScripts = {}) =>
  AutocompleteGenerator<Core.ListScripts, { Name: string; ID: number }, "ID">({
    getItemFromResponse: (response) =>
      response?.Results?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name!,
        ID: item.ID!,
      })),
    idKey: "ID",
    labelKey: "Name",
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.script",
      defaultMessage: "Script",
    }),
    useItemByID: useScriptByID,
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        initialRequest,
        query: listScriptsQuery,
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          InitialPageConfig: {
            ...prev?.InitialPageConfig,
            Filter: { ...prev?.InitialPageConfig?.Filter, Name: newValue },
          },
        }),
      }),
  });

export const {
  MultiAutocomplete: ScriptMultiAutocomplete,
  SingleAutocomplete: ScriptAutocomplete,
} = generateScriptAutocomplete({});

export const generateScriptSearchListField = (initialRequest: EVA.Core.ListScripts = {}) =>
  SearchListFieldGenerator<Core.ListScripts, { Name: string; ID: number }, "ID">({
    getItemFromResponse: (response) =>
      response?.Results?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name!,
        ID: item.ID!,
      })),
    idKey: "ID",
    labelKey: "Name",
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.script",
      defaultMessage: "Script",
    }),
    useItemByID: useScriptByID,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: !!response?.NextPageToken,
          onLoadMore: (request) => ({
            ...request,
            InitialPageConfig: {
              ...request?.InitialPageConfig,
              Limit:
                (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listScriptsQuery,
        initialRequest: {
          ...initialRequest,
          InitialPageConfig: {
            ...initialRequest.InitialPageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          InitialPageConfig: {
            ...prev?.InitialPageConfig,
            Filter: { ...prev?.InitialPageConfig?.Filter, Name: newValue },
          },
        }),
      }),
  });

export const generateLyraScriptSearchListField = (initialRequest: EVA.Core.ListScripts = {}) =>
  LyraSearchListFieldGenerator<Core.ListScripts, { Name: string; ID: number }, number>({
    getItemFromResponse: (response) =>
      response?.Results?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name!,
        ID: item.ID!,
      })),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.script",
      defaultMessage: "Script",
    }),
    useItemByID: useScriptByID,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: !!response?.NextPageToken,
          onLoadMore: (request) => ({
            ...request,
            InitialPageConfig: {
              ...request?.InitialPageConfig,
              Limit:
                (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listScriptsQuery,
        initialRequest: {
          ...initialRequest,
          InitialPageConfig: {
            ...initialRequest.InitialPageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          InitialPageConfig: {
            ...prev?.InitialPageConfig,
            Filter: { ...prev?.InitialPageConfig?.Filter, Name: newValue },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
  });
