import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { listReturnReasonsQuery } from "models/reasons";
import { ALL_ROWS_LIMIT } from "util/base-values";

export const useReturnReasonsById = (id?: number) => {
  const { data, isFetching: isLoading } = useQuery({
    ...listReturnReasonsQuery(
      id
        ? {
            PageConfig: {
              //TODO: remove ALL_ROWS_LIMIT when BE is modified to allow for an ID Filter on ListReturnReasons
              Limit: ALL_ROWS_LIMIT,
            },
          }
        : undefined,
    ),
    enabled: !!id,
  });

  const returnReason = useMemo(
    () => data?.Result?.Page?.find((returnReason) => returnReason.ID === id),
    [data?.Result?.Page, id],
  );

  return { item: returnReason, isLoading };
};
