import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { uniqBy } from "lodash";
import { v4 as uuid } from "uuid";

import { Autocomplete } from "components/suite-ui/autocomplete";

import { ProducRequirementFieldProps } from "./eva-product-set-product-requirement-value-field.tsx";

const DISPLAYED_CHIPS_INCREMENT = 5;

export const EvaProductSetProductRequirementStringValueField = ({
  errorMessage,
  onFieldBlur,
  passive,
  setValue,
  value,
}: Omit<ProducRequirementFieldProps, "productRequirements" | "productRequirement">) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState<string>();
  const [maximumDisplayedSelectedItems, setMaximumDisplayedSelectedItems] =
    useState(DISPLAYED_CHIPS_INCREMENT);

  const items = useMemo(
    () =>
      value
        ? value
            .map((key) => ({
              key,
              value: uuid(),
            }))
            .filter((item) => item.value !== undefined)
        : [],
    [value],
  );

  const controlledSelectedItem = useMemo(
    () => value?.map((entry) => ({ key: entry.toString(), value: uuid() })) ?? [],
    [value],
  );

  const handleSelectedItems = useCallback(
    (selectedItems: { key: string; value: string }[]) => {
      const filteredItems = uniqBy([...controlledSelectedItem, ...selectedItems], "key").map(
        (entry) => entry.key,
      ) as string[];

      setValue(filteredItems);
    },
    [controlledSelectedItem, setValue],
  );

  const handleBlur = useCallback(() => {
    if (inputValue) {
      handleSelectedItems([{ key: inputValue, value: inputValue }]);
      setInputValue(undefined);
    }
    onFieldBlur();
  }, [handleSelectedItems, inputValue, onFieldBlur]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      const index = controlledSelectedItem?.findIndex((item) => item.value === id);

      if (index !== undefined) {
        setValue(value?.toSpliced(index, 1));
      }
    },
    [controlledSelectedItem, setValue, value],
  );

  return (
    <Autocomplete
      passive={passive}
      label={intl.formatMessage({ id: "generic.label.values", defaultMessage: "Values" })}
      multi
      freeOptions
      csv
      items={items}
      controlledInputValue={inputValue ?? ""}
      handleInputChange={setInputValue}
      controlledSelectedItem={controlledSelectedItem?.length ? controlledSelectedItem : ""}
      handleSelectedItems={handleSelectedItems}
      handleDeleteItem={handleDeleteItem}
      clearAllItems={() => setValue([])}
      matchKeys={["value", "key"]}
      optionIDKey="value"
      renderOptionValueKey="key"
      error={!!errorMessage}
      helperText={errorMessage}
      onBlur={handleBlur}
      required
      maximumDisplayedSelectedItems={maximumDisplayedSelectedItems}
      onShowMore={() =>
        setMaximumDisplayedSelectedItems((current) => current + DISPLAYED_CHIPS_INCREMENT)
      }
      showMoreText={intl.formatMessage(
        { id: "generic.label.show-more-total", defaultMessage: "Show more ({total} total)" },
        { total: value?.length },
      )}
    />
  );
};
