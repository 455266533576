import { CoreManagement } from "@springtree/eva-services-core-management";

import { getLoyaltyPointPoliciesQuery } from "models/loyalty-programs";
import { AcceptedPendingPolicies } from "types/loyalty-program-policies";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateLoyaltyPointPendingPolicySearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetLoyaltyPointPolicies,
    {
      Name: string;
    },
    string
  >({
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    getItemFromResponse: (resp) =>
      resp?.PendingPolicies?.filter((policy) =>
        Object.values(AcceptedPendingPolicies).includes(policy),
      )?.map((policy) => ({
        Name: policy,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.pending-points",
      defaultMessage: "Pending points",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getLoyaltyPointPoliciesQuery,
      }),
  });
