import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getUserTaskCountsLoaderQuery,
  serviceQuery: getUserTaskCountsQuery,
  serviceQueryKeys: getUserTaskCountsQueryKeys,
  useServiceQueryHook: useGetUserTaskCountsQuery,
} = createServiceQuery(Core.GetUserTaskCounts);
