import { atom, atomFamily, DefaultValue, selectorFamily, SerializableParam } from "recoil";

import { IDiscount } from "./edit-discount.types";

export const isDiscountTemplateAtom = atom({
  key: "EditDiscount:IsDiscountTemplate",
  default: false,
});

/**
 * State for the template ID when creating a discount based on a template
 */
export const templateIdForDiscountAtom = atom<number | undefined>({
  key: "templateIdForDiscountAtom",
  default: undefined,
});

/** State that hold all information in a discount */
export const discountAtom = atomFamily<IDiscount | undefined, SerializableParam>({
  key: "DiscountDetails:Discount",
  default: undefined,
});

/** State that hold all information in a discount template */
export const discountTemplateAtom = atomFamily<IDiscount | undefined, SerializableParam>({
  key: "DiscountDetails:DiscountTemplate",
  default: undefined,
});

/** Selector that points to either a discount state or a discount template state.
 * This should be used as base for both edit discount and edit discount template flows
 */
export const discountOrTemplateSelector = selectorFamily<IDiscount | undefined, SerializableParam>({
  key: "DiscountDetails:DiscountOrTemplate",
  get:
    (param: SerializableParam) =>
    ({ get }) => {
      const isDiscountTemplate = get(isDiscountTemplateAtom);
      return isDiscountTemplate ? get(discountTemplateAtom(param)) : get(discountAtom(param));
    },
  set:
    (param: SerializableParam) =>
    ({ get, reset, set }, newValue) => {
      const isDiscountTemplate = get(isDiscountTemplateAtom);
      const atomToSet = isDiscountTemplate ? discountTemplateAtom : discountAtom;

      if (!newValue || newValue instanceof DefaultValue) {
        reset(atomToSet(param));
      } else {
        set(atomToSet(param), newValue);
      }
    },
});

export const discountEditedAtom = atomFamily<boolean, SerializableParam>({
  key: "DiscountDetails:DiscountOrTemplate:Edited",
  default: false,
});
