import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { atomFamily } from "recoil";

import { EntityTranslationFieldIdentifiers } from "./types";

export const countriesState = builders.buildServiceState({
  service: Core.ListCountries,
  key: "Composite:Countries",
  defaultRequest: {
    FetchAll: true,
  },
  requireEmployee: true,
  requireLoggedIn: true,
});

export const languagesState = builders.buildServiceState({
  service: CoreManagement.GetLanguages,
  key: "Composite:Languages",
  allowEmptyRequest: true,
  requireEmployee: true,
  requireLoggedIn: true,
});

export const entityTranslationsState = builders.buildServiceFamilyState({
  service: Core.ListEntityTranslations,
  key: "Composite:EntityTranslations",
  allowEmptyRequest: false,
  requireEmployee: true,
});

export const entityTranslationsFilterTermAtom = atomFamily<
  string | undefined,
  EntityTranslationFieldIdentifiers
>({
  key: "Composite:EntityTranslations:Filter",
  default: undefined,
});
