import { FormattedMessage } from "react-intl";

import { SvgIcon, Text } from "@new-black/lyra";

const AvailableUserTasksEmptyViewLyra = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center ">
      <SvgIcon name="done" className="mb-4 h-6 w-6 text-secondary" />
      <Text variant="heading-2" className="mb-2.5 ">
        <FormattedMessage id="generic.label.done-exclamation" defaultMessage="Done!" />
      </Text>
      <Text className="text-center" color="secondary">
        <FormattedMessage
          id="generic.label.no-user-tasks-available.message"
          defaultMessage="All done! There are no user tasks of this type for you."
        />
      </Text>
      <Text className="text-center" color="secondary">
        <FormattedMessage
          id="generic.label.not-what-you-expected.message"
          defaultMessage="Not what you expected? Please check if you have selected the correct store in the left pane."
        />
      </Text>
    </div>
  );
};

export default AvailableUserTasksEmptyViewLyra;
