import { useCallback } from "react";

import { uniqBy } from "lodash";

import { IMultiAutocompleteProps, ISingleAutocompleteProps } from "./autocomplete.types";

interface IUseAutocompleteHandlers<T>
  extends Omit<ISingleAutocompleteProps<T> | IMultiAutocompleteProps<T>, "multi"> {
  items: T[];
  idKey: keyof T;
  labelKey: keyof T;
}

const useAutocompleteHandlers = <T>({
  idKey,
  items,
  setValue,
  setValues,
  values,
}: IUseAutocompleteHandlers<T>) => {
  const handleSingleSelect = useCallback(
    (newSelectedItem: T | undefined) => {
      setValue?.(newSelectedItem);
    },
    [setValue],
  );

  const handleMultiSelect = useCallback(
    (newSelection: T[]) => {
      const newSelectedItems = uniqBy(
        [...(items ?? []), ...(values ?? []), ...newSelection],
        idKey,
      ).filter((item) => newSelection.map((newItem) => newItem[idKey]).includes(item[idKey]));
      setValues?.(newSelectedItems.length ? newSelectedItems : undefined);
    },
    [idKey, items, setValues, values],
  );

  const handleMultiDeselect = useCallback(
    (itemToDeselect: T) => {
      const newSelectedItems = values?.filter((item) => itemToDeselect[idKey] !== item[idKey]);
      setValues?.(newSelectedItems?.length ? newSelectedItems : undefined);
    },
    [idKey, values, setValues],
  );

  return {
    handleMultiDeselect,
    handleSingleSelect,
    handleMultiSelect,
  };
};

export default useAutocompleteHandlers;
