import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { LoyaltyProgramOptions } from "types/eva-core";
import enumToArray from "util/enum-to-array";
import { addSpacingBeforeCapitals } from "util/hooks/use-add-spacing-before-capitals";

interface LoyaltyProgramOptionsSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const LoyaltyProgramOptionsSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: LoyaltyProgramOptionsSearchListField) => {
  const intl = useIntl();

  const items = useMemo(() => enumToArray(LoyaltyProgramOptions), []);

  const selectedValue = useMemo(
    () => items?.filter((item) => value?.includes(item.value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.value}
      getLabel={(item) => addSpacingBeforeCapitals(item.key)}
      selectRenderElements={(item) => ({ label: addSpacingBeforeCapitals(item.key) })}
      value={selectedValue}
      items={items ?? []}
      onChange={(newValue) => onChange(newValue?.map((item) => item.value) ?? [])}
      label={intl.formatMessage({ id: "generic.label.options", defaultMessage: "Options" })}
      errorMessage={errorMessage}
    />
  );
};
