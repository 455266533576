import { useCallback, useEffect, useMemo, useState } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniqBy } from "lodash";

import { MaterialAutocomplete } from "components/suite-ui/autocomplete";
import ErrorBoundary from "components/suite-ui/error-boundary";

import {
  IAutocompleteGeneratorProps,
  ILocalAutocompleteGeneratorProps,
  MultiAutocomplete,
  SpyCallback,
} from "./autocomplete-generator.types";

export const generateMultiAutocomplete = <SVC extends IEvaServiceDefinition, Item>({
  defaultLabel,
  getItemFromResponse,
  idKey,
  initialData,
  labelKey,
  renderOption,
  secondaryLabelFallback = "",
  secondaryLabelKey,
  useServiceQuery,
}: IAutocompleteGeneratorProps<SVC, Item>) => {
  const MultiAutocomplete = ({
    autoFocus,
    disabled,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onBlur,
    onChange,
    passive,
    placeholder,
    required,
    small,
    spyCallback,
    values,
    warning,
    disablePortal
  }: MultiAutocomplete<Item>["Props"] & SpyCallback<Item>) => {
    const [input, setInput, { data, isFetching: isLoading }] = useServiceQuery();

    const items = useMemo(() => {
      return uniqBy(
        [...(initialData ?? []), ...(values ?? []), ...(getItemFromResponse(data) ?? [])],
        idKey,
      );
    }, [data, values]);

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    return (
      <ErrorBoundary>
        <MaterialAutocomplete
          multi
          small={small}
          IDKey={idKey}
          error={error}
          warning={warning}
          values={values}
          LabelKey={labelKey}
          required={required}
          items={items ?? []}
          setValues={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          helperText={helperText}
          onInputChange={setInput}
          controlledInputValue={input}
          label={label ?? defaultLabel}
          SecondaryLabelKey={secondaryLabelKey}
          isLoading={isOutsideLoading || isLoading}
          secondaryLabelFallback={secondaryLabelFallback}
          passive={passive}
          disabled={disabled}
          placeholder={placeholder}
          renderOption={renderOption}
          disablePortal={disablePortal}
        />
        <input
          name={name}
          type="hidden"
          value={values?.length ? JSON.stringify(values.map((value) => value[idKey])) : ""}
        />
      </ErrorBoundary>
    );
  };
  return MultiAutocomplete;
};

export const generateMultiLocalAutocomplete = <Item, IDKey extends keyof Item>({
  defaultLabel,
  idKey,
  items: itemsArray,
  labelKey,
  secondaryLabelFallback = "",
  secondaryLabelKey,
}: ILocalAutocompleteGeneratorProps<Item, IDKey>) => {
  const MultiAutocomplete = ({
    autoFocus,
    disabled,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onBlur,
    onChange,
    passive,
    placeholder,
    required,
    small,
    spyCallback,
    values,
    disablePortal,
  }: MultiAutocomplete<Item>["Props"] & SpyCallback<Item>) => {
    const [input, setLocalQuery] = useState<string | undefined>();
    const setInput = useCallback(
      (newValue: string | undefined) => {
        if (newValue !== input) {
          setLocalQuery(newValue);
        }
      },
      [input],
    );

    const items = useMemo(
      () => uniqBy([...(values ?? []), ...(itemsArray ?? [])], idKey),
      [values],
    );

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    return (
      <ErrorBoundary>
        <MaterialAutocomplete
          multi
          small={small}
          IDKey={idKey}
          error={error}
          values={values}
          passive={passive}
          disabled={disabled}
          LabelKey={labelKey}
          required={required}
          items={items ?? []}
          setValues={onChange}
          onBlur={onBlur}
          autoFocus={autoFocus}
          helperText={helperText}
          onInputChange={setInput}
          isLoading={isOutsideLoading}
          controlledInputValue={input}
          label={label ?? defaultLabel}
          SecondaryLabelKey={secondaryLabelKey}
          secondaryLabelFallback={secondaryLabelFallback}
          placeholder={placeholder}
          disablePortal={disablePortal}
        />
        <input
          name={name}
          type="hidden"
          value={values?.length ? JSON.stringify(values.map((value) => value[idKey])) : ""}
        />
      </ErrorBoundary>
    );
  };
  return MultiAutocomplete;
};
