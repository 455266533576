import { Dialog, DialogCard, DialogProps } from "@new-black/lyra";

import { filterModelToEVAFilterModel, parseEVAFilterModel } from "./helpers";
import { EVAFilterModel, ProductFilterModalMessages } from "./product-filter-modal.types";
import { ProductFilterModalContent } from "./product-filter-modal-content";

interface IProductFilterModalProps
  extends Pick<DialogProps, "isOpen" | "aria-label" | "onOpenChange" | "maxWidth"> {
  initialValues?: EVAFilterModel;
  onChange: (newFilter: EVAFilterModel) => void;
  messages?: ProductFilterModalMessages;
  isLoading?: boolean;
  /** Defaults to true. */
  closeOnSave?: boolean;
  readOnlyPropertyOnEdit?: boolean;
}

export const ProductFilterModal = ({
  closeOnSave = true,
  initialValues,
  isLoading,
  messages,
  onChange,
  readOnlyPropertyOnEdit,
  ...dialogProps
}: IProductFilterModalProps) => (
  <Dialog {...dialogProps}>
    {({ close }) => (
      <DialogCard>
        <ProductFilterModalContent
          close={close}
          messages={messages}
          onChange={onChange}
          initialValues={initialValues}
          isLoading={isLoading}
          closeOnSave={closeOnSave}
          readOnlyPropertyOnEdit={readOnlyPropertyOnEdit}
        />
      </DialogCard>
    )}
  </Dialog>
);

ProductFilterModal.parseEVAFilterModel = parseEVAFilterModel;
ProductFilterModal.filterModelToEVAFilterModel = filterModelToEVAFilterModel;

export * from "./product-filter-modal.types";
