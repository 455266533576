import { FormattedMessage } from "react-intl";

import { Dialog } from "@material-ui/core";
import { Button, CardFooter, Dialog as LyraDialog, DialogCard, Separator } from "@new-black/lyra";

import { LyraOUSetSubsetsList, OUSetSubsetsList } from "../../ouset-subsets-list";

interface ISubsetsModalProps {
  isOpen: boolean;
  ouSetId: number;
  onClose?: () => void;
}

const SubsetsModal = ({ isOpen, onClose, ouSetId }: ISubsetsModalProps) => {
  return (
    <Dialog fullWidth maxWidth="md" open={isOpen} onClose={onClose}>
      <OUSetSubsetsList ouSetId={ouSetId} />

      <Separator />
      <div className="flex items-center justify-end p-4">
        <Button variant="secondary" onPress={onClose}>
          <FormattedMessage id="generic.label.close" defaultMessage="Close" />
        </Button>
      </div>
    </Dialog>
  );
};

export const SubsetsLyraModal = ({ isOpen, onClose, ouSetId }: ISubsetsModalProps) => {
  return (
    <LyraDialog
      maxWidth="4xl"
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose?.();
        }
      }}
    >
      <DialogCard stickyHeader stickyFooter className="overflow-hidden">
        <div className="max-h-[619px] overflow-auto">
          <LyraOUSetSubsetsList ouSetId={ouSetId} />
        </div>
        <CardFooter>
          <Button onPress={onClose}>
            <FormattedMessage id="generic.label.close" defaultMessage="Close" />
          </Button>
        </CardFooter>
      </DialogCard>
    </LyraDialog>
  );
};
export default SubsetsModal;
