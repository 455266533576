import { useProductSearchModalContext } from "../product-search-modal-context";
import type { IProductMultiSearchModalProps, IProductSearchModalResultBase } from "../types";

import { ProductSearchModalRightPaneListItem } from "./product-search-modal-right-pane-list-item";

type ProductSearchModalRightPaneListProps<TProduct extends IProductSearchModalResultBase> = Pick<
  IProductMultiSearchModalProps<TProduct>,
  "rightPaneListItemRenderElements"
>;

export function ProductSearchModalRightPaneList<TProduct extends IProductSearchModalResultBase>({
  rightPaneListItemRenderElements,
}: ProductSearchModalRightPaneListProps<TProduct>) {
  const { selectedProducts } = useProductSearchModalContext<TProduct>();

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="flex max-h-[40rem] flex-1 flex-col gap-4 py-4">
        {selectedProducts.map((product) => (
          <ProductSearchModalRightPaneListItem<TProduct>
            product={product}
            key={product.product_id}
            rightPaneListItemRenderElements={rightPaneListItemRenderElements}
          />
        ))}
      </div>
    </div>
  );
}
