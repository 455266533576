export enum UserType {
  None = 0,
  Employee = 1,
  Customer = 2,
  Anonymous = 4,
  Business = 8,
  System = 17,
  Debtor = 64,
  LimitedTrust = 256,
  Tester = 512,
  RemovedByRequest = 1024,
  Api = 2048,
}
