type TUnauthorizedRequestStrategies = "modal" | "redirect";

/**
 * Strategy class for handling 401 responses (redirecting to the login page or popping a login modal). Defaults to `undefined`.
 *
 * The strategy is set to a specific value based on context (either in the `ValidateToken` flow or by performing fetching / mutations through react-query or
 * the recoil SDK). Setting the strategy to a certain value will prevent the other strategies from happening.
 *
 * This solves the issues that occurs when multiple services are requested simultaneously (`ValidateToken` possibly being one of them), and there is no indication
 * on whether the login modal should be opened or a redirect should be done. For example, it could happen that you're on a page with multiple services that
 * are refetched on window focus, and your token expires: you first get your login modal opened, then after a few moments (or more, depending on your internet
 * connection) you get redirected to the login page, which leads to a bad user experience.
 *
 * Use cases:
 * 1. `ValidateToken` is the first service to return an error:
 * - if the strategy is `undefined`:
 *   - set the strategy to "modal"
 *   - if, by any chance, another service request follows up in the request stack and it returns 401, the redirect will not be performed because the strategy is set to "modal"
 *   - open the login modal
 *   - perform login
 *   - on success or when navigating to a page that doesn't require user tokens, reset the strategy to `undefined`
 * - otherwise, do nothing
 *
 * 2. A service other than `ValidateToken` is the first one to return 401 (if it's a mutation, it is required that the unauthorized response handling is not disabled):
 * - if the strategy is `undefined`:
 *   - set the strategy to "redirect"
 *   - if, by any chance, the `ValidateToken` follows up in the request stack, the modal will not open because the strategy is set to "redirect"
 *   - the redirect will be performed (this will reset the application completely, hence the strategy will reset)
 * - otherwise, do nothing
 *
 * **NOTE**: The strategy is always reset on successful logins.
 *
 * The strategy is used in:
 * - `src/util/http.ts`: `handleUnauthorizedError`
 * - `src/routes/root/root.tsx`: `userExpiredCallback` and `useRedirectOnEmptyUserToken`
 * - `src/features/login/activity-login-dialog.tsx`: `onError`, `onLogin` and in the `useEffect` that automatically closes the login dialog when
 * navigating to a page where the user token is not required
 * - `src/features/login/helpers.ts`: `loginWithCredentials` and `confirm2FA` methods
 */
class UnauthorizedRequestsStrategy {
  private strategy: TUnauthorizedRequestStrategies | undefined = undefined;

  public canApplyStrategy(strategy: TUnauthorizedRequestStrategies) {
    if (!this.strategy) {
      this.strategy = strategy;
    }

    return this.strategy === strategy;
  }

  public reset() {
    this.strategy = undefined;
  }
}

export const unauthorizedRequestsStrategy = new UnauthorizedRequestsStrategy();
