import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { CalendarDate } from "@internationalized/date";
import { Checkbox, DatePicker, FieldGroup, TextField } from "@new-black/lyra";
import moment from "moment";

import { LoyaltyPointUsageOptionsSearchListField } from "components/suite-composite/loyalty-point-usage-options-search-list-field";
import { generateLoyaltyProgramGroupSearchListField } from "components/suite-composite/loyalty-program-group-search-list-field";
import { LoyaltyProgramHandlerSearchListField } from "components/suite-composite/loyalty-program-handler-search-list-field";
import { LoyaltyProgramOptionsSearchListField } from "components/suite-composite/loyalty-program-options-search-list-field";
import { LoyaltyProgramStatusSelect } from "components/suite-composite/loyalty-program-status-select";
import { LoyaltyProgramOptions } from "types/eva-core";

import { shouldEnableLoyaltyProgramLoyaltyPoints } from "../helpers";

import { LoyaltyProgramUserIdentifierFields } from "./loyalty-program-user-identifier-fields";
import {
  loyaltyProgramFormKeys,
  LoyaltyProgramUsageTypes,
  TLoyaltyProgramFormErrors,
} from "./types";

export const LoyaltyProgramForm = ({
  defaultValue,
  errors,
  formID,
  loyaltyPointUsageOptions,
  loyaltyProgramOptions,
}: {
  defaultValue?: EVA.Core.Management.GetLoyaltyProgramResponse;
  formID: string;
  errors?: TLoyaltyProgramFormErrors;
  /** Enum name: `LoyaltyPointUsageOptions`. */
  loyaltyPointUsageOptions: { Name: string; Value: number }[];
  /** Enum name: `LoyaltyProgramOptions`. */
  loyaltyProgramOptions: { Name: string; Value: number }[];
}) => {
  const intl = useIntl();

  const [usageType, setUsageType] = useState(() => {
    if (defaultValue?.LoyaltyPointUsageOptions === undefined) return undefined;

    if ((defaultValue.LoyaltyPointUsageOptions as number) === LoyaltyProgramUsageTypes.None)
      return [0];

    return loyaltyPointUsageOptions
      ?.filter(
        (entry) =>
          entry.Value !== 0 &&
          (entry.Value & defaultValue?.LoyaltyPointUsageOptions!) === entry.Value,
      )
      .map((entry) => entry.Value);
  });

  const computedUsageType = useMemo(
    () =>
      usageType?.length
        ? usageType?.reduce((accumulator, current) => accumulator | current, 0)
        : undefined,
    [usageType],
  );

  const [options, setOptions] = useState(() => {
    if (defaultValue?.Options === undefined) return undefined;

    if ((defaultValue.Options as number) === LoyaltyProgramOptions.None) return [0];

    return loyaltyProgramOptions
      ?.filter(
        (entry) => entry.Value !== 0 && (entry.Value & defaultValue?.Options!) === entry.Value,
      )
      .map((entry) => entry.Value);
  });

  const computedOptions = useMemo(
    () =>
      options?.length
        ? options?.reduce((accumulator, current) => accumulator | current, 0)
        : undefined,
    [options],
  );

  const [handler, setHandler] = useState(defaultValue?.Handler);
  const [status, setStatus] = useState(defaultValue?.Status);

  const [startDate, setStartDate] = useState(() => {
    if (defaultValue?.StartDate) {
      const momentValue = moment(defaultValue?.StartDate);

      return new CalendarDate(
        momentValue.get("y"),
        momentValue.get("M") + 1, // Moment months are counted from 0
        momentValue.get("D"),
      );
    }

    return null;
  });
  const [endDate, setEndDate] = useState(() => {
    if (defaultValue?.EndDate) {
      const momentValue = moment(defaultValue?.EndDate);

      return new CalendarDate(
        momentValue.get("y"),
        momentValue.get("M") + 1, // Moment months are counted from 0
        momentValue.get("D"),
      );
    }

    return null;
  });

  return (
    <Form method="POST" id={formID}>
      <FieldGroup>
        <TextField
          isRequired
          name={loyaltyProgramFormKeys.Name}
          label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
          errorMessage={errors?.Name}
          defaultValue={defaultValue?.Name}
        />

        <TextField
          name={loyaltyProgramFormKeys.Description}
          label={intl.formatMessage({
            id: "generic.label.description",
            defaultMessage: "Description",
          })}
          errorMessage={errors?.Description}
          defaultValue={defaultValue?.Description}
        />

        <LoyaltyProgramOptionsSearchListField
          isRequired
          value={options}
          onChange={setOptions}
          errorMessage={errors?.Options}
        />
        {computedOptions !== undefined ? (
          <input hidden readOnly name={loyaltyProgramFormKeys.Options} value={computedOptions} />
        ) : null}

        {shouldEnableLoyaltyProgramLoyaltyPoints(computedOptions) ? (
          <>
            <LoyaltyPointUsageOptionsSearchListField
              isRequired
              value={usageType}
              onChange={setUsageType}
              errorMessage={errors?.LoyaltyPointUsageOptions}
            />
            {computedUsageType !== undefined ? (
              <input
                hidden
                readOnly
                name={loyaltyProgramFormKeys.LoyaltyPointUsageOptions}
                value={computedUsageType}
              />
            ) : null}
          </>
        ) : null}

        {!defaultValue ? (
          <>
            <LoyaltyProgramHandlerSearchListField.Controlled
              value={handler ? { Name: handler } : undefined}
              onChange={(newValue) => setHandler(newValue?.Name || undefined)}
              errorMessage={errors?.Handler}
              isRequired
            />
            {handler !== undefined ? (
              <input hidden readOnly name={loyaltyProgramFormKeys.Handler} value={handler} />
            ) : null}

            <LoyaltyProgramUserIdentifierFields defaultValue={defaultValue} errors={errors} />
          </>
        ) : null}

        <DatePicker
          value={startDate}
          onChange={setStartDate}
          label={intl.formatMessage({
            id: "generic.label.start-date",
            defaultMessage: "Start date",
          })}
          errorMessage={errors?.StartDate}
        />
        {startDate ? (
          <input
            hidden
            readOnly
            name={loyaltyProgramFormKeys.StartDate}
            value={moment(startDate.toString()).toISOString()}
          />
        ) : null}

        <DatePicker
          value={endDate}
          onChange={setEndDate}
          label={intl.formatMessage({
            id: "generic.label.end-date",
            defaultMessage: "End date",
          })}
          errorMessage={errors?.EndDate}
        />
        {endDate ? (
          <input
            hidden
            readOnly
            name={loyaltyProgramFormKeys.EndDate}
            value={moment(endDate.toString()).toISOString()}
          />
        ) : null}

        <LoyaltyProgramStatusSelect
          value={status}
          onChange={setStatus}
          errorMessage={errors?.Status}
          isRequired
        />
        <input hidden readOnly name={loyaltyProgramFormKeys.Status} value={status} />

        <LoyaltyProgramGroupIDSearchListField.Uncontrolled
          name={loyaltyProgramFormKeys.LoyaltyProgramGroupID}
          defaultValue={defaultValue?.LoyaltyProgramGroupID}
          errorMessage={errors?.LoyaltyProgramGroupID}
        />

        <Checkbox
          name={loyaltyProgramFormKeys.RequiresSubscriptionValidation}
          defaultSelected={defaultValue?.RequiresSubscriptionValidation}
        >
          <FormattedMessage
            id="generic.label.requires-subscription-validation"
            defaultMessage="Requires subscription validation"
          />
        </Checkbox>
      </FieldGroup>
    </Form>
  );
};

const { SingleIDSearchListField: LoyaltyProgramGroupIDSearchListField } =
  generateLoyaltyProgramGroupSearchListField();
