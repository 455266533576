import { useMemo } from "react";
import { IntlFormatters, IntlShape, useIntl } from "react-intl";

import { intlAccessor } from "util/intl-accessor";

export const formatDate = (
  intlObject: IntlShape,
  ...args: Parameters<IntlFormatters<React.ReactNode>["formatDate"]>
) => (args[0] ? intlObject.formatDate(...args) : "");

export const formatDateTime = (date: string) =>
  `${intlAccessor.formatDate(date)} ${intlAccessor.formatTime(date)}`;

export const useFormattedDateTime = (date: string) => {
  const intl = useIntl();
  const dateTimeString = useMemo(
    () => `${intl.formatDate(date)} ${intl.formatTime(date)}`,
    [date, intl],
  );
  return dateTimeString;
};
