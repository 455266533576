import { useCallback, useEffect, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { orderByTaskServiceState } from "store/order-by-task";

import useSetRequest from "../suite-react-hooks/use-set-request";

const useOrderByTask = (ID?: number) => {
  const newOrderByTaskRequest = useMemo<EVA.Core.GetOrder | undefined>(() => {
    if (ID) {
      return {
        OrderID: ID,

        AdditionalOrderDataOptions: {
          ProductProperties: [
            "primary_image.blob",
            "product_requirements",
            "product_types",
            "display_value",
          ],
          IncludeOrganizationUnitData: true,
        } as any,
      };
      // casted to any to avoid having to pass all other options as false,
    }
    return undefined;
  }, [ID]);

  useSetRequest(orderByTaskServiceState.request(ID), newOrderByTaskRequest);

  const orderByTaskResponse = hooks.useGetState(orderByTaskServiceState.response(ID));

  const orderByTaskLoading = hooks.useIsLoading({
    state: orderByTaskServiceState.response(ID),
  });

  const orderByTaskLoadingWithoutStoredResponse = useMemo(
    () => !orderByTaskResponse && orderByTaskLoading,
    [orderByTaskLoading, orderByTaskResponse],
  );

  const orderByTaskUpdatedAt = hooks.useGetState(
    orderByTaskServiceState.stale(ID),
  ) as unknown as Date;

  const setOrderByTaskStale = hooks.useSetStale({
    staleState: orderByTaskServiceState.stale(ID),
  });

  const refetchOrderByTask = useCallback(() => {
    setOrderByTaskStale();
  }, [setOrderByTaskStale]);

  useEffect(() => {
    setOrderByTaskStale();
  }, [setOrderByTaskStale]);

  return {
    orderByTaskResponse,
    orderByTaskLoading,
    orderByTaskLoadingWithoutStoredResponse,
    orderByTaskUpdatedAt,
    refetchOrderByTask,
  };
};

export default useOrderByTask;
