import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Checkbox } from "@material-ui/core";
// TODO: replace MUI icon
import IndeterminateCheckBoxIcon from "@material-ui/icons/IndeterminateCheckBox";
import { differenceBy, uniqBy } from "lodash";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

import { Button } from "@new-black/lyra";
import Grid from "components/suite-ui/grid";
import Text from "components/suite-ui/text";

import { FullHeightGrid } from "../helper-components";
import {
  ProductSetAvailableProductsAtom,
  ProductSetIncludedProductsAtom,
  ProductSetSelectedAvailableProductsAtom,
} from "../state";

const AvailableProductsListHeader = ({
  disabledAddProductsButton,
  hideCheckbox,
  showQuantityHeader,
}: {
  disabledAddProductsButton?: boolean;
  showQuantityHeader?: boolean;
  hideCheckbox?: boolean;
}) => {
  const availableProducts = useRecoilValue(ProductSetAvailableProductsAtom);
  const [includedProducts, setIncludedProducts] = useRecoilState(ProductSetIncludedProductsAtom);
  const [selectedProducts, setSelectedProducts] = useRecoilState(
    ProductSetSelectedAvailableProductsAtom,
  );
  const resetSelectedProducts = useResetRecoilState(ProductSetSelectedAvailableProductsAtom);

  // only products that are not already included in the product set can be selected using the checkbox
  const selectableProducts = useMemo(
    () => differenceBy(availableProducts, includedProducts, "ID"),
    [availableProducts, includedProducts],
  );

  const addAllSelectedProductsToSet = useCallback(() => {
    resetSelectedProducts();
    setIncludedProducts((current) => uniqBy([...current, ...selectedProducts], "ID"));
  }, [resetSelectedProducts, selectedProducts, setIncludedProducts]);

  return (
    <div className="h-[50px] px-5">
      <FullHeightGrid container alignItems="center">
        {!hideCheckbox ? (
          <Grid item>
            <div className="mr-5">
              <Checkbox
                color="primary"
                checked={
                  selectedProducts.length === selectableProducts.length &&
                  selectableProducts.length > 0
                }
                indeterminate={
                  selectedProducts.length > 0 && selectedProducts.length < selectableProducts.length
                    ? true
                    : undefined
                }
                indeterminateIcon={<IndeterminateCheckBoxIcon color="primary" />}
                onChange={() => {
                  // if all products are selected, unselect them all
                  if (selectedProducts.length === selectableProducts.length) {
                    resetSelectedProducts();
                  } else {
                    // otherwise select all products
                    setSelectedProducts(selectableProducts);
                  }
                }}
                disabled={selectableProducts.length === 0}
              />
            </div>
          </Grid>
        ) : null}

        <Grid item xs>
          <Text className="text-[13px]">
            <FormattedMessage
              id="product-set-modal.product-selector.available-products-list.header.title"
              values={{ availableProductsCount: availableProducts.length }}
              defaultMessage={`${availableProducts.length} result(s) found`}
            />
          </Text>
        </Grid>

        {showQuantityHeader && !selectedProducts.length ? (
          <Text className="mr-44 text-[13px]">
            <FormattedMessage id="generic.label.quantity" defaultMessage="Quantity" />
          </Text>
        ) : null}
        {selectedProducts.length > 0 ? (
          <Grid item>
            <div className="ml-5">
              <Button onPress={addAllSelectedProductsToSet} isDisabled={disabledAddProductsButton}>
                <FormattedMessage
                  id="product-set-modal.product-selector.available-products-list.header.add-products"
                  values={{ selectedProductsCount: selectedProducts.length }}
                  defaultMessage={`Add ${selectedProducts.length} item(s)`}
                />
              </Button>
            </div>
          </Grid>
        ) : null}
      </FullHeightGrid>
    </div>
  );
};

export default AvailableProductsListHeader;
