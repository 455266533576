import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Text } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import type { IProductSearchModalResultBase } from "../types";

export function ProductSearchModalRightPaneHeader<
  TProduct extends IProductSearchModalResultBase,
>() {
  const intl = useIntl();
  const { selectedProducts, setSelectedProducts } = useProductSearchModalContext<TProduct>();

  const title = useMemo(() => {
    if (selectedProducts.length) {
      return intl.formatMessage(
        {
          id: "generic.label.total-products-selected",
          defaultMessage: "{total} products selected",
        },
        { total: selectedProducts.length },
      );
    }

    return intl.formatMessage({
      id: "generic.label.no-products-selected",
      defaultMessage: "No products selected",
    });
  }, [intl, selectedProducts.length]);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between gap-4 p-4">
        <Text variant="heading-2">{title}</Text>

        <Button
          variant="secondary"
          onPress={() => {
            setSelectedProducts([]);
          }}
        >
          <FormattedMessage
            id="generic.label.clear-all-products"
            defaultMessage="Clear all products"
          />
        </Button>
      </div>
    </div>
  );
}
