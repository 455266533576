import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, CardContent, CardFooter, FieldGroup } from "@new-black/lyra";
import { Field, FieldProps, yupToFormErrors } from "formik";
import { omit } from "lodash";

import { CustomFieldConsumer } from "components/suite-composite/custom-field-consumer/custom-field-consumer";
import { generateCustomFieldEligibilityScriptLyraSearchListField } from "components/suite-composite/custom-field-eligibility-script-autocomplete";
import { EVAFormik } from "components/suite-composite/eva-formik";
import { FormikCheckboxV2 } from "components/suite-composite/formik-inputs";
import { OUSetLyraSelect } from "components/suite-composite/ou-and-set-selectors/ouset-select";
import { generateLyraScriptSearchListField } from "components/suite-composite/script-autocomplete";
import { CustomFieldPrimitiveType } from "types/custom-field";
import { Functionalities, FunctionalityScope } from "types/functionalities";
import { ScriptDialect, ScriptSource } from "types/script-type";
import {
  convertEVACustomFieldTypeToCustomFieldConsumerType,
  getCustomFieldValueFromEVACustomFieldValue,
  mapCustomFieldPrimitiveValueToEVACustomFieldValue,
} from "util/custom-fields";
import { queryClient } from "util/query-client";
import { validateCustomFieldValuesObject } from "util/validate-custom-field";

import ExtraFields from "../../extra-fields";
import { convertEnumValuesTypeToKeyObj } from "../../helpers";
import useCustomFieldByID from "../../use-custom-field-by-id";

import { ICFSettingFormValue } from "./custom-field-settings-modal-types";
import { FormikUserTypeSelect } from "./formik-user-type-select";
import useCustomFieldSettingsFormValidationSchema from "./use-custom-field-settings-form-validation-schema";
import useSubmitCustomFieldSettingsForm from "./use-submit-custom-field-settings-form";

const ScriptSearchListField = generateLyraScriptSearchListField({
  InitialPageConfig: {
    Filter: {
      Dialect: ScriptDialect.Extension,
      Source: ScriptSource.CustomFieldSecurity,
    },
  },
}).SingleIDSearchListField;

interface ICustomFieldSettingsFormProps {
  closeCFSettingsModal: () => void;
  customFieldId: number | undefined;
  optionToEdit?: EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto;
}

export const CustomFieldSettingsForm = ({
  closeCFSettingsModal,
  customFieldId,
  optionToEdit,
}: ICustomFieldSettingsFormProps) => {
  const intl = useIntl();

  const { values } = useCustomFieldByID(customFieldId);

  const initialFormValues = useMemo<ICFSettingFormValue>(() => {
    return {
      IsRequired: optionToEdit?.IsRequired ?? false,
      OUSetID: optionToEdit?.OrganizationUnitSet?.ID,
      VisibleByUserType: optionToEdit?.VisibleByUserTypes ?? null,
      EditableByUserType: optionToEdit?.EditableByUserTypes ?? null,
      MinimumLength: optionToEdit?.MinimumLength,
      MaximumLength: optionToEdit?.MaximumLength,
      MinimumValue: optionToEdit?.MinimumValue,
      MaximumValue: optionToEdit?.MaximumValue,
      MinimumDate: optionToEdit?.MinimumDate,
      MaximumDate: optionToEdit?.MaximumDate,
      CustomFieldDefaultValue: getCustomFieldValueFromEVACustomFieldValue(
        optionToEdit?.CustomFieldDefaultValue,
      ),
      SecurityScriptID: optionToEdit?.SecurityScriptID,
      EligibilityScriptID: optionToEdit?.EligibilityScriptID,
    };
  }, [optionToEdit]);

  const validationSchema = useCustomFieldSettingsFormValidationSchema();

  const { handleSubmit, isLoading } = useSubmitCustomFieldSettingsForm(
    closeCFSettingsModal,
    customFieldId,
    values?.DataType,
    values?.IsArray,
  );

  const validate = useCallback(
    async (formValues: ICFSettingFormValue) => {
      let errors = {};

      // Add error for CustomFieldDefaultValue if present
      const customFieldErrors = await validateCustomFieldValuesObject(queryClient)(
        customFieldId
          ? {
              [customFieldId]:
                mapCustomFieldPrimitiveValueToEVACustomFieldValue(
                  convertEVACustomFieldTypeToCustomFieldConsumerType(
                    values?.DataType as number,
                    values?.IsArray,
                  ),
                  formValues.CustomFieldDefaultValue,
                ) ?? {},
            }
          : {},
        undefined, // We don't need the OU ID since we always validate with respect to local options object
        false,
        omit(formValues, "IsRequired", "OUSetID", "CustomFieldDefaultValue"),
        true,
      );

      if (customFieldErrors.some((verdict) => !!verdict.error)) {
        errors = {
          ...errors,
          CustomFieldDefaultValue: customFieldErrors.filter((entry) => !!entry.error)?.[0]?.error,
        };
      }

      // Then do yup validation for the form values
      try {
        validationSchema.validateSync(formValues);
      } catch (err) {
        errors = {
          ...errors,
          ...yupToFormErrors(err),
        };
      }
      return errors;
    },
    [customFieldId, validationSchema, values],
  );

  const EligibilityScriptSearchListField = useMemo(
    () =>
      values?.TypeID
        ? generateCustomFieldEligibilityScriptLyraSearchListField({
            TypeID: values?.TypeID,
          }).SingleIDSearchListField
        : null,
    [values?.TypeID],
  );

  return (
    <EVAFormik
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={initialFormValues}
      validate={validate}
      validateOnChange={false}
    >
      {({ isSubmitting, resetForm, submitForm }) => (
        <>
          <CardContent>
            <FieldGroup>
              <Field name="OUSetID">
                {({ field, form, meta }: FieldProps<number | undefined, ICFSettingFormValue>) => (
                  <OUSetLyraSelect
                    passive={!!optionToEdit}
                    selectedOUSetID={field.value}
                    error={meta.touched ? !!meta.error : undefined}
                    helperText={meta.touched ? meta.error : undefined}
                    setSelectedOUSetID={(newId) => form.setFieldValue(field.name, newId)}
                    filters={{
                      Functionality: Functionalities.CUSTOM_FIELDS,
                      FunctionalityScope: FunctionalityScope.Manage as number,
                    }}
                    required
                    disableSearchListFieldPopoverPortal
                  />
                )}
              </Field>
              <FormikUserTypeSelect
                multiple
                name="VisibleByUserType"
                label={intl.formatMessage({
                  id: "custom-field.options.add.visible-by-usertype",
                  defaultMessage: "Visible by user type",
                })}
              />
              <FormikUserTypeSelect
                multiple
                name="EditableByUserType"
                label={intl.formatMessage({
                  id: "custom-field.options.add.editable-by-usertype",
                  defaultMessage: "Editable by user type",
                })}
              />
              <ExtraFields dataType={values?.DataType} />
              <Field name="CustomFieldDefaultValue">
                {({ field, form, meta }: FieldProps<CustomFieldPrimitiveType>) =>
                  values?.ID ? (
                    <CustomFieldConsumer
                      label={intl.formatMessage({
                        id: "generic.label.default-value",
                        defaultMessage: "Default value",
                      })}
                      variant="lyra"
                      customField={{
                        ...values,
                        ID: values.ID,
                        DataType: values.DataType as number,
                        EnumValues: values.EnumValues
                          ? convertEnumValuesTypeToKeyObj(values.EnumValues)
                          : {},
                        Options: {
                          ...optionToEdit,
                          ...omit(form.values, "OUSetID", "CustomFieldDefaultValue"),
                          IsRequired: false,
                          VisibleByUserTypes: optionToEdit?.VisibleByUserTypes as
                            | number
                            | undefined,
                          EditableByUserTypes: optionToEdit?.EditableByUserTypes as
                            | number
                            | undefined,
                        },
                      }}
                      value={field.value}
                      onChange={(newValue) => {
                        form.setFieldValue(field.name, newValue);
                      }}
                      touched={meta.touched}
                      enumDisplay="inline"
                      error={meta.touched ? meta.error : undefined}
                    />
                  ) : null
                }
              </Field>
              <FormikCheckboxV2.Lyra name="IsRequired">
                {intl.formatMessage({
                  id: "custom-field.options.add.is-required",
                  defaultMessage: "Is required",
                })}
              </FormikCheckboxV2.Lyra>
              <ScriptSearchListField.Formik name="SecurityScriptID" />
              {EligibilityScriptSearchListField ? (
                <EligibilityScriptSearchListField.Formik name="EligibilityScriptID" />
              ) : null}
            </FieldGroup>
          </CardContent>
          <CardFooter>
            <Button
              variant="secondary"
              isDisabled={isSubmitting || isLoading}
              onPress={() => {
                resetForm();
                closeCFSettingsModal();
              }}
            >
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>
            <Button variant="primary" onPress={submitForm} isLoading={isSubmitting || isLoading}>
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            </Button>
          </CardFooter>
        </>
      )}
    </EVAFormik>
  );
};

CustomFieldSettingsForm.defaultProps = {
  optionToEdit: undefined,
};
