import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { atomFamily, selectorFamily } from "recoil";

import { mapCustomFieldValueFromResponse } from "./helpers";
import { ICustomFieldValues } from "./types";

export const singleCustomFieldByIDState = builders.buildServiceFamilyState({
  key: "Composite:CustomFieldByID",
  service: CoreManagement.GetCustomFieldByID,
  allowEmptyRequest: false,
  requireLoggedIn: true,
  requireEmployee: true,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.ID === parameter) {
      return req;
    }
    return { ...req, ID: parameter };
  },
});

export const singleCustomFieldValuesSelector = selectorFamily<
  ICustomFieldValues | undefined,
  number | undefined
>({
  key: "Composite:CustomFieldByID:Values",
  get:
    (param) =>
    ({ get }) => {
      const response = get(singleCustomFieldByIDState.response(param));

      if (response) {
        return mapCustomFieldValueFromResponse(response);
      }
      return undefined;
    },
});

export const singleCustomFieldValuesDirty = atomFamily<boolean, number | undefined>({
  key: "Composite:CustomFieldByID:Values:Dirty",
  default: false,
});

export const singleCustomFieldValuesSubmitting = atomFamily<boolean, number | undefined>({
  key: "Composite:CustomFieldByID:Values:Submitting",
  default: false,
});

export const singleCustomFieldState = {
  singleCustomFieldValuesDirty,
  singleCustomFieldValuesSubmitting,
};
