import { useMemo } from "react";

import useFuseInstance from "hooks/use-fuse-instance";

export const useSearchListFieldComboboxOptionsFilter = <T>(
  items: T[],
  labelKey: keyof T,
  query?: string,
  customFilterKeys?: (keyof T)[],
  disableFiltering?: boolean,
) => {
  const fuseInstance = useFuseInstance(
    customFilterKeys?.length ? customFilterKeys : [labelKey],
    items,
  );

  const filteredItems = useMemo(() => {
    if (!disableFiltering && fuseInstance && query) {
      return fuseInstance
        .search({
          $or: customFilterKeys?.length
            ? customFilterKeys.map((filterKey) => ({ [filterKey]: query }))
            : [{ [labelKey]: query }],
        })
        .map((result) => result.item);
    }

    return items;
  }, [customFilterKeys, disableFiltering, fuseInstance, items, labelKey, query]);

  return filteredItems;
};
