import * as React from "react";

const CompleteItemIcon = () => (
  <svg width={17} height={17} viewBox="0 0 17 17">
    <circle cx={8.5} cy={8.5} fill="#007AFF" r={8.5} />
    <path fill="none" stroke="white" strokeWidth="1.5px" d="M3.5 9 L6.5 12 L13.5 4.8" />
  </svg>
);

export default CompleteItemIcon;
