import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { AutocompleteProps, FilterOptionsState } from "@material-ui/lab";
import { cva } from "class-variance-authority";
import classNames from "classnames";
import { uniqBy } from "lodash";

import Text from "components/suite-ui/text";

import Input from "../../input";

import { fuseFilterOptions } from "./autocomplete.helpers";
import {
  IAutocompleteBaseProps,
  IMultiAutocompleteProps,
  ISingleAutocompleteProps,
} from "./autocomplete.types";
import { AutocompleteTag } from "./autocomplete-tag";
import useAutocompleteHandlers from "./use-autocomplete-handlers";

const optionLabelStyle = cva([""], {
  variants: {
    selected: {
      true: "font-bold",
      false: "font-normal",
    },
  },
});

const useAutocompleteProps = <T,>({
  id,
  csv,
  name,
  onCSV,
  multi,
  error,
  warning,
  label,
  value,
  items,
  values,
  onBlur,
  onFocus,
  endIcon,
  required,
  setValue,
  setValues,
  isLoading,
  helperText,
  limitChips,
  placeholder,
  IDKey: idKey,
  onInputChange,
  limitChipsText,
  tooltipMessages,
  small,
  defaultHelperText,
  LabelKey: labelKey,
  controlledInputValue,
  secondaryLabelFallback = "",
  filterKeys = [idKey, labelKey],
  SecondaryLabelKey: secondaryLabelKey,
  autoFocus,
  getOptionDisabled,
  endAdornment,
  hideDownArrow,
  renderOption,
}: (ISingleAutocompleteProps<T> | IMultiAutocompleteProps<T>) &
  IAutocompleteBaseProps<T, keyof T, keyof T, keyof T>) => {
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);

  const [inputValue, setInputValue] = useState<string | undefined>(controlledInputValue);

  useEffect(() => setInputValue(controlledInputValue), [controlledInputValue]);

  const getOptionID = useCallback((option: T) => option[idKey], [idKey]);
  const getOptionLabel = useCallback(
    (option: T) => (option[labelKey] ? `${option[labelKey]}` : ""),
    [labelKey],
  );

  const inputChangeHandlerRef = useRef(onInputChange);
  inputChangeHandlerRef.current = onInputChange;

  useEffect(() => {
    setInputValue(value ? getOptionLabel(value) : undefined);
    inputChangeHandlerRef.current?.(value ? getOptionLabel(value) : undefined);
  }, [getOptionLabel, value]);

  const filterOptions = useCallback(
    (availableOptions: T[], state: FilterOptionsState<T>) =>
      fuseFilterOptions<T>(availableOptions, state, items, filterKeys as string[], getOptionID),
    [filterKeys, getOptionID, items],
  );

  const lookupItems = useMemo(() => {
    const currentlySelectedSingleItem = value ? [value] : [];
    const currentlySelectedMultiItems = values ?? [];
    return uniqBy(
      [...(items ?? []), ...(multi ? currentlySelectedMultiItems : currentlySelectedSingleItem)],
      idKey,
    );
  }, [idKey, items, multi, value, values]);

  // Helper hook to handle selections
  const { handleMultiDeselect, handleMultiSelect, handleSingleSelect } = useAutocompleteHandlers<T>(
    {
      items: lookupItems,
      idKey,
      labelKey,
      setValue,
      setValues,
      value,
      values,
    },
  );

  // Keep a state of previously selected item to prevent deselecting on input change (used in `onBlur`)
  const [prevSelected, setPrevSelected] = useState<T | undefined>();

  useEffect(() => {
    if (value) {
      setPrevSelected(value);
    }
  }, [value]);

  const props = useMemo<AutocompleteProps<T, boolean, boolean, undefined>>(
    () => ({
      size: small ? "small" : "medium",
      filterOptions,
      id: id ?? name,
      getOptionLabel,
      getOptionDisabled,
      clearOnBlur: true,
      renderOption: renderOption
        ? (option, state) => renderOption(option, state)
        : secondaryLabelKey
        ? (option, state) => (
            <div className="-my-[0.375rem]">
              <div className="flex flex-row items-center">
                <Text
                  className={optionLabelStyle({
                    selected: state.selected,
                  })}
                >{`${option[labelKey]}`}</Text>
              </div>
              {secondaryLabelKey ? (
                <Text
                  className={classNames(state.selected ? "font-bold" : "font-normal", "h-5")}
                  variant="body2"
                >{`${option[secondaryLabelKey] ?? secondaryLabelFallback}`}</Text>
              ) : null}
            </div>
          )
        : undefined,
      autoComplete: true,
      loading: isLoading,
      selectOnFocus: true,
      autoHighlight: true,
      options: lookupItems,
      limitTags: limitChips,
      handleHomeEndKeys: true,
      includeInputInList: true,
      multiple: multi ?? false,
      disableCloseOnSelect: multi,
      inputValue: inputValue ?? "",
      openText: tooltipMessages?.open,
      clearText: tooltipMessages?.clear,
      closeText: tooltipMessages?.close,
      onMouseEnter: () => setHovered(true),
      onMouseLeave: () => setHovered(false),
      loadingText: tooltipMessages?.loading,
      noOptionsText: tooltipMessages?.noOptions,
      value: multi ? values ?? [] : value ?? null,
      getLimitTagsText: multi ? (hiddenItems) => limitChipsText?.(hiddenItems) : undefined,
      onFocus: (event) => {
        setFocused(true);
        onFocus?.(event);
      },
      getOptionSelected: (option, currentValue) =>
        getOptionID(option) === getOptionID(currentValue),
      onInputChange: (_, newInputValue, reason) => {
        // Prevent resetting input value on input
        if (reason === "input" || reason === "clear" || (reason === "reset" && newInputValue)) {
          setInputValue(newInputValue);
          onInputChange?.(newInputValue);
        }
        if (multi && csv) {
          const insertedOptions = newInputValue.split(",");
          if (insertedOptions?.length > 1) {
            onCSV?.(insertedOptions, (newItems: T[]) => {
              handleMultiSelect([...(values ?? []), ...newItems]);
            });
            // Reset input value (search term) after selection
            setInputValue(undefined);
            onInputChange?.(undefined);
          }
        }
      },
      onBlur: (e) => {
        onBlur?.(e);
        setFocused(false);
        if (!multi) {
          // On single-autocomplete, prevent deselecting OU if the input has changed
          if (!value && inputValue) {
            setValue?.(prevSelected);
            const newValue = prevSelected?.[labelKey] ? `${prevSelected?.[labelKey]}` : undefined;
            setInputValue?.(newValue);
            onInputChange?.(newValue);
          }
        } else {
          // On multi, reset input value
          setInputValue?.(undefined);
          onInputChange?.(undefined);
        }
      },
      onChange: (_, newValue) => {
        if (!newValue) {
          handleMultiSelect([]);
          handleSingleSelect(undefined);
          return;
        }
        if (Array.isArray(newValue) && multi) {
          handleMultiSelect(newValue ?? []);
          setInputValue?.(undefined);
          onInputChange?.(undefined);
          return;
        }
        if (!multi && !Array.isArray(newValue)) {
          handleSingleSelect(newValue);
        }
      },
      renderTags: (chipValues, getTagProps) =>
        multi ? (
          <AutocompleteTag
            id={id}
            name={name}
            idKey={idKey}
            labelKey={labelKey}
            chipValues={chipValues}
            getTagProps={getTagProps}
            handleMultiDeselect={handleMultiDeselect}
          />
        ) : null,
      renderInput: (params) => (
        <Input
          {...params}
          small={small}
          name={name}
          error={error}
          warning={warning}
          label={
            (!focused || (hovered && !focused)) && (label?.length ?? 0) > 32
              ? `${label?.slice(0, 27)} ...`
              : label
          }
          required={required}
          placeholder={placeholder}
          helperText={helperText ?? defaultHelperText}
          InputProps={{
            autoComplete: "off",
            ...params.InputProps,
            autoFocus,
            endAdornment: (
              <div className="flex flex-row justify-end">
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {!!endAdornment && (
                  <div className="absolute right-0 top-[8.5px]">{endAdornment}</div>
                )}
                {!hideDownArrow ? params.InputProps.endAdornment : null}
                {!isLoading && endIcon && (focused || hovered) ? endIcon : null}
              </div>
            ),
          }}
        />
      ),
    }),
    [
      filterOptions,
      id,
      name,
      getOptionLabel,
      getOptionDisabled,
      renderOption,
      secondaryLabelKey,
      isLoading,
      lookupItems,
      limitChips,
      multi,
      inputValue,
      tooltipMessages?.open,
      tooltipMessages?.clear,
      tooltipMessages?.close,
      tooltipMessages?.loading,
      tooltipMessages?.noOptions,
      values,
      value,
      labelKey,
      secondaryLabelFallback,
      limitChipsText,
      onFocus,
      getOptionID,
      csv,
      onInputChange,
      onCSV,
      handleMultiSelect,
      onBlur,
      setValue,
      prevSelected,
      handleSingleSelect,
      idKey,
      handleMultiDeselect,
      small,
      error,
      warning,
      focused,
      hovered,
      label,
      required,
      placeholder,
      helperText,
      defaultHelperText,
      autoFocus,
      endAdornment,
      hideDownArrow,
      endIcon,
    ],
  );

  return props;
};

export default useAutocompleteProps;
