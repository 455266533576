import { useCallback } from "react";

import { Combobox } from "@headlessui/react";

import { SearchListFieldComboboxInput, SearchListFieldComboboxOptions } from "./helpers";
import {
  ISearchListFieldControlledSingleSelectionProps,
  ISearchListFieldOptionsProps,
  TSearchListFieldKeyType,
} from "./types";
import { useSearchListFieldCombobox } from "./use-search-list-field-combobox";
import { useSearchListFieldComboboxOptionsFilter } from "./use-search-list-field-combobox-options-filter";

export const SearchListFieldSingleSelectionCombobox = <T,>({
  autoFocus,
  filterOptions,
  getSecondaryLabel,
  idKey,
  isLoading,
  isOptionDisabled,
  items,
  labelKey,
  onLoadMore,
  query,
  setQuery,
  setValue,
  showOptionEndAdornment,
  styles,
  value,
}: ISearchListFieldOptionsProps<T, TSearchListFieldKeyType<T>, TSearchListFieldKeyType<T>> &
  ISearchListFieldControlledSingleSelectionProps<T> & {
    styles?: React.CSSProperties;
    isLoading?: boolean;
  }) => {
  const { endOfListRef, inputRef, loadMoreButtonRef, optionsRef, topOfListRef } =
    useSearchListFieldCombobox();

  const filteredItems = useSearchListFieldComboboxOptionsFilter(
    items,
    labelKey,
    query,
    filterOptions?.filterKeys,
    filterOptions?.disabled,
  );

  const isEqual = useCallback((a: T | null, b: T | null) => a?.[idKey] === b?.[idKey], [idKey]);

  return (
    <Combobox
      value={value ?? null}
      by={isEqual}
      onChange={(newValue: T) => {
        if (newValue) {
          setValue?.(newValue);
        }
      }}
      nullable
    >
      <SearchListFieldComboboxInput
        loadMoreButtonRef={loadMoreButtonRef}
        inputRef={inputRef}
        query={query}
        setQuery={setQuery}
        onClear={() => {
          setQuery("");

          // Scroll to top of options list on clear
          if (optionsRef.current) {
            optionsRef.current.scrollTop = 0;
          }
        }}
        autoFocus={autoFocus}
      />

      <SearchListFieldComboboxOptions
        inputRef={inputRef}
        loadMoreButtonRef={loadMoreButtonRef}
        optionsRef={optionsRef}
        topOfListRef={topOfListRef}
        endOfListRef={endOfListRef}
        items={filteredItems}
        labelKey={labelKey}
        getSecondaryLabel={getSecondaryLabel}
        isLoading={isLoading}
        style={styles}
        isOptionDisabled={isOptionDisabled}
        showOptionEndAdornment={showOptionEndAdornment}
        onLoadMore={onLoadMore}
      />
    </Combobox>
  );
};
