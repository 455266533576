import { Dispatch, SetStateAction, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";

import Grid from "components/suite-ui/grid";
import Text from "components/suite-ui/text";

import EntityTranslationFieldTable from "../entity-translation-field-table";

import { useEntityTranslationFieldSettings } from "./entity-translation-field-context";
import { Button } from "@new-black/lyra";

interface EntityTranslationFieldModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const EntityTranslationFieldModal = ({ open, setOpen }: EntityTranslationFieldModalProps) => {
  const intl = useIntl();
  const { entityField, entityID, entityType, translations } = useEntityTranslationFieldSettings();
  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      {open ? (
        <>
          <div className="p-5">
            <Text variant="h2">
              {translations?.editModalTitle ??
                intl.formatMessage({
                  id: "generic.label.translations",
                  defaultMessage: "Translations",
                })}
            </Text>
          </div>
          <EntityTranslationFieldTable
            entityField={entityField}
            entityID={entityID}
            entityType={entityType}
            translations={translations}
          />
          <div className="p-5">
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <Button variant="secondary" onPress={onClose}>
                  <FormattedMessage id="generic.label.done" defaultMessage="Done" />
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      ) : null}
    </Dialog>
  );
};

export default EntityTranslationFieldModal;
