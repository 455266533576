import { EntityTranslationIconButton } from "./entity-translation-icon-button";
import { IEntityTranslationIconProps } from "./types";

export const EntityTranslationButton = ({
  customTranslationButton,
  disableEntityTranslations,
  setOpenModal,
  small,
  translations,
}: Omit<IEntityTranslationIconProps, "insideContainer">) =>
  customTranslationButton ? (
    <>
      {customTranslationButton({
        isDisabled: disableEntityTranslations,
        onPress: () => setOpenModal(true),
      })}
    </>
  ) : (
    <EntityTranslationIconButton
      tooltip={translations?.buttonLabel}
      isDisabled={disableEntityTranslations}
      onPress={() => setOpenModal(true)}
      small={small}
    />
  );
