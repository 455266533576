import { Admin } from "@springtree/eva-services-admin";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getSharedWorkspaceByIDLoaderQuery,
  serviceQuery: getSharedWorkspaceByIDQuery,
} = createServiceQuery(Admin.GetSharedWorkspaceByID, true);

export const {
  serviceLoaderQuery: listSharedWorkspacesLoaderQuery,
  serviceQueryKeys: listSharedWorkspacesQueryKeys,
  useServiceQueryHook: useListSharedWorkspacesQuery,
} = createServiceQuery(Admin.ListSharedWorkspaces, true);

export const {
  serviceQueryKeys: getWorkspacesQueryKeys,
  useServiceQueryHook: useGetWorkspacesQuery,
} = createServiceQuery(Admin.GetWorkspaces, true);
