import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  DialogProps,
  FieldGroup,
  FileInput,
  FileInputUploadItem,
} from "@new-black/lyra";

type MediaFilesUploadModalProps = {
  isOpen: DialogProps["isOpen"];
  onOpenChange: DialogProps["onOpenChange"];
  form?: string;
  onChange?: (newFile?: FileInputUploadItem) => void;
  onSave?: (file?: FileInputUploadItem) => void;
  name?: string;
  readAsDataUrl?: boolean;
  errorMessage?: string;
  isSaveLoading?: boolean;
};

export const MediaFilesUploadModal = ({
  errorMessage,
  form,
  isOpen,
  isSaveLoading,
  name,
  onChange,
  onOpenChange,
  onSave,
  readAsDataUrl,
}: MediaFilesUploadModalProps) => {
  const intl = useIntl();

  const [file, setFile] = useState<FileInputUploadItem>();

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onOpenChange?.(false);
          setFile(undefined);
        }
      }}
    >
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.add-media",
            defaultMessage: "Add media",
          })}
        />

        <CardContent>
          <FieldGroup>
            <FileInput
              onChange={(files) => {
                setFile(files?.[0]);
                onChange?.(files?.[0]);
              }}
              readAsDataUrl={readAsDataUrl}
              errorMessage={errorMessage}
            />
          </FieldGroup>
        </CardContent>

        <CardFooter>
          <ButtonGroup>
            <Button
              variant="secondary"
              onPress={() => {
                onOpenChange?.(false);
                setFile(undefined);
              }}
              isDisabled={isSaveLoading}
            >
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>
            <Button
              type={form ? "submit" : "button"}
              onPress={() => {
                onSave?.(file);
                if (onSave) {
                  setFile(undefined);
                }
              }}
              name={name}
              value={name ? JSON.stringify(file) : undefined}
              form={form}
              isLoading={isSaveLoading}
            >
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};
