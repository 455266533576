import { FormattedMessage } from "react-intl";

import { Button, ButtonGroup, Separator } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import { IProductSearchModalResultBase, IProductSingleSearchModalProps } from "../types";

export function ProductMultiSearchModalLeftPaneFooter() {
  const {
    selectedProductResults,
    selectedProducts,
    setSelectedProductResults,
    setSelectedProducts,
  } = useProductSearchModalContext();

  return selectedProductResults.length ? (
    <>
      <Separator />
      <div className="flex justify-end p-4">
        <ButtonGroup>
          <Button
            variant="secondary"
            onPress={() => {
              setSelectedProductResults([]);
            }}
          >
            <FormattedMessage id="generic.label.clear-selection" defaultMessage="Clear selection" />
          </Button>
          <Button
            variant="primary"
            onPress={() => {
              setSelectedProducts([...selectedProducts, ...selectedProductResults]);
              setSelectedProductResults([]);
            }}
          >
            <FormattedMessage
              id="generic.label.add-number-of-products"
              defaultMessage="Add {total} products"
              values={{ total: selectedProductResults.length }}
            />
          </Button>
        </ButtonGroup>
      </div>
    </>
  ) : null;
}

export function ProductSingleSearchModalLeftPaneFooter<
  TProduct extends IProductSearchModalResultBase,
>({
  form,
  isSaveDisabled,
  isSaveLoading,
  messages,
  onClose,
  onSave,
  saveButtonName,
  saveButtonValue,
}: Pick<
  IProductSingleSearchModalProps<TProduct>,
  | "messages"
  | "onSave"
  | "isSaveDisabled"
  | "isSaveLoading"
  | "saveButtonName"
  | "saveButtonValue"
  | "form"
> & { onClose: () => void }) {
  const { selectedProducts } = useProductSearchModalContext<TProduct>();
  return (
    <>
      <Separator />
      <div className="flex justify-end p-4">
        <ButtonGroup>
          <Button
            variant="secondary"
            onPress={() => {
              onClose();
            }}
          >
            <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
          </Button>
          <Button
            variant="primary"
            isDisabled={isSaveDisabled}
            onPress={() => {
              onSave?.(selectedProducts.length === 1 ? selectedProducts[0] : undefined);
              if (!form) {
                onClose();
              }
            }}
            isLoading={isSaveLoading}
            name={saveButtonName}
            value={saveButtonValue}
            form={form}
            type={form ? "submit" : "button"}
          >
            {messages?.saveButton || (
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            )}
          </Button>
        </ButtonGroup>
      </div>
    </>
  );
}
