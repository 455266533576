import { NumericFormat, NumericFormatProps } from "react-number-format";

import { InputBaseComponentProps } from "@material-ui/core";

export interface NumberFormatCustomProps {
  inputRef: (instance: NumericFormatProps<InputBaseComponentProps> | null) => void;
  onChange: (event: { target: { value: string; floatValue?: number } }) => void;
}

export const NumberInputComponentWrapper = (props: { [x: string]: any; component: any }) => {
  const { component: Component, ...other } = props;

  return <Component {...other} />;
};

export const NumberFormatCustomComponent = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator={(other as any).thousandSeparator ?? undefined}
      valueIsNumericString
      decimalScale={(other as any).decimalScale}
      fixedDecimalScale={(other as any).fixedDecimalScale ?? false}
      decimalSeparator={(other as any).decimalSeparator ?? undefined}
    />
  );
};
