import { useEffect, useMemo, useState } from "react";

import { useMutation } from "@tanstack/react-query";
import { isEmpty } from "lodash";

import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { ParseTypes, ScriptEditorLanguage } from "types/monaco-editor-language";

import { parseScriptMutation } from "../helpers/parse-script";
import { ICodeEditorError } from "../script-code-editor.types";

/**
 * Hook that returns the parsing errors of an script source
 * @param source - Source code for the script
 * @returns the list of errors and a loading indicator
 */
const useGetScriptErrors = (dialect: ScriptEditorLanguage, source?: string) => {
  const debouncedSourceShort = useDebounce(source, 400);
  const debouncedSourceLong = useDebounce(source, 1500);

  const { isLoading, mutateAsync: parseScript } = useMutation((request: EVA.Core.ParseScript) =>
    parseScriptMutation(request, undefined, false),
  );

  const [errors, setErrors] = useState<ICodeEditorError[]>();

  useEffect(() => {
    if (debouncedSourceShort) {
      parseScript({
        Source: debouncedSourceShort,
        Dialect: dialect,
        Type: ParseTypes.Fast as number,
      }) // Check script fast for errors
        .then((data) => {
          setErrors(
            data?.response?.Errors?.map((error) => ({
              startLineNumber: error.SourceLineNumber,
              startColumn: error.SourceColumn,
              message: error.Message,
            })),
          );
        })
        .catch((error) => {
          console.error("[SHEET-EDITOR]: Error parsing sheet", error);
        });
    }
  }, [debouncedSourceShort, dialect, parseScript]);

  useEffect(() => {
    if (debouncedSourceLong) {
      parseScript({
        Source: debouncedSourceLong,
        Dialect: dialect,
        Type: ParseTypes.Full as number,
      }) // Check full script for errors
        .then((data) => {
          setErrors(
            data?.response?.Errors?.map((error) => ({
              startLineNumber: error.SourceLineNumber,
              startColumn: error.SourceColumn,
              message: error.Message,
            })),
          );
        })
        .catch((error) => {
          console.error("[SHEET-EDITOR]: Error parsing sheet", error);
        });
    }
  }, [debouncedSourceLong, dialect, parseScript]);

  // create a tiny helper variable to avoid calling isEmpty in a lot of places
  const hasErrors = useMemo(() => !isEmpty(errors), [errors]);

  return { errors, isLoading, hasErrors };
};

export default useGetScriptErrors;
