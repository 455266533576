import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useHandleSWR } from "hooks/suite-react-hooks/use-handle-swr";

import { getLedgerClassesResultSelector, getLedgerClassesServiceState } from ".";

const useGetLedgerClasses = () => {
  const data = hooks.useGetState(getLedgerClassesResultSelector);

  const isLoading = hooks.useIsLoading({ state: getLedgerClassesServiceState.response });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  useHandleSWR(getLedgerClassesServiceState);

  return { data, isLoading, isLoadingWithoutResponse };
};

export default useGetLedgerClasses;
