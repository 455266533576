import { Core } from "@springtree/eva-services-core";
import { PIM } from "@springtree/eva-services-pim";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: searchProductsLoaderQuery,
  serviceQuery: searchProductsQuery,
  serviceQueryKeys: searchProductsQueryKeys,
  useServiceQueryHook: useSearchProductsQuery,
  useServiceQueryWithRecoilRequest: useSearchProductsQueryWithRecoilRequest,
  useServiceQueryWithRequest: useSearchProductsQueryWithRequest,
} = createServiceQuery(Core.SearchProducts, true);

export const {
  serviceLoaderQuery: getProductsLoaderQuery,
  serviceQuery: getProductsQuery,
  serviceQueryKeys: getProductsQueryKeys,
  useServiceQueryHook: useGetProductsQuery,
} = createServiceQuery(Core.GetProducts, true);

export const {
  serviceLoaderQuery: getProductAvailabilityLoaderQuery,
  serviceQuery: getProductAvailabilityQuery,
  serviceQueryKeys: getProductAvailabilityKeys,
  useServiceQueryHook: useProductAvailabilityQuery,
  useServiceQueryWithRequest: useProductAvailabilityQueryWithRequest,
} = createServiceQuery(Core.GetProductAvailability, true, undefined, true);

export const {
  serviceLoaderQuery: getProductDetailLoaderQuery,
  serviceQuery: getProductDetailQuery,
  serviceQueryKeys: getProductDetailKeys,
  serviceQueryWithOU: getProductDetailQueryWithOU,
  useServiceQueryHook: useProductDetailQuery,
} = createServiceQuery(Core.GetProductDetail, true);

export const { serviceQuery: verifySerialNumberQuery } = createServiceQuery(
  Core.VerifySerialNumber,
  true,
  false,
  true,
  undefined,
);

export const {
  serviceLoaderQuery: getProductPricesLoaderQuery,
  useServiceQueryHook: useGetProductPricesQuery,
} = createServiceQuery(Core.GetProductPrices);

export const {
  serviceLoaderQuery: getProductWarrantyLoaderQuery,
  serviceQuery: getProductWarrantyQuery,
  serviceQueryKeys: getProductWarrantyQueryKeys,
  useServiceQueryHook: useGetProductWarrantyQuery,
} = createServiceQuery(Core.GetProductWarranty, true, false, true, undefined);

export const {
  serviceLoaderQuery: listProductContentEditsLoaderQuery,
  serviceQuery: listProductContentEditsQuery,
  serviceQueryKeys: listProductContentEditsKeys,
  serviceQueryWithOU: listProductContentEditsQueryWithOU,
  useServiceQueryHook: useListProductContentEditsQuery,
  useServiceQueryWithRequest: useListProductContentEditsQueryWithRequest,
} = createServiceQuery(PIM.ListProductContentEdits, true);

export const {
  serviceLoaderQuery: listRevisionsLoaderQuery,
  serviceQuery: listRevisionsQuery,
  useServiceQueryWithRequest: useListRevisionsQueryWithRequest,
} = createServiceQuery(PIM.ListRevisions, true);

export const {
  serviceLoaderQuery: listProductCapacityPlansLoaderQuery,
  serviceQuery: listProductCapacityPlansQuery,
  serviceQueryKeys: listProductCapacityPlansQueryKeys,
  useServiceQueryHook: useListProductCapacityPlansQuery,
  useServiceQueryWithRequest: useListProductCapacityPlansQueryWithRequest,
} = createServiceQuery(PIM.ListProductCapacityPlans, true);

export const {
  serviceQueryKeys: listProductPricesQueryKeys,
  serviceLoaderQuery: listProductPricesLoaderQuery,
  useServiceQueryHook: useListProductPricesQuery,
} = createServiceQuery(Core.ListProductPrices, true);
