import { useCallback, useMemo } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { RecoilState, useRecoilState, useSetRecoilState } from "recoil";

import { IWorkspaceSelectorItem } from "components/shared/menu/workspace/workspace-selector";

import { safelyParseWorkspaceSettings } from "../helpers";
import { selectedWorkspaceIdState } from "../workspaces.state";
import { EKeyName, IPageConfiguration } from "../workspaces.types";

const useSelectedWorkspace = <TWorkspace>(
  workspaceRecoilState: (
    keyName: EKeyName | undefined,
  ) => RecoilState<Partial<TWorkspace> | undefined>,
  defaultWorkspaceSettings: { [key: string]: any },
  keyName?: EKeyName,
  workspaces?: IWorkspaceSelectorItem[],
  currentPageConfiguration?: IPageConfiguration<TWorkspace>,
) => {
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useRecoilState(
    selectedWorkspaceIdState(keyName),
  );

  const selectedWorkspace = useMemo(
    () => workspaces?.find((workspace) => workspace.ID === selectedWorkspaceId),
    [selectedWorkspaceId, workspaces],
  );

  const intl = useIntl();
  const workspaceState = hooks.useGetState(workspaceRecoilState(keyName));
  const setWorkspaceState = useSetRecoilState(workspaceRecoilState(keyName));

  // Apply workspace settings for only the properties you pass in the params.
  const applyWorkSpaceSettingsForProperties = useCallback(
    (workspace: IWorkspaceSelectorItem | undefined, propertyNames: (keyof TWorkspace)[]) => {
      if (workspace && currentPageConfiguration && workspaceState) {
        const workspaceSettingsFromService = safelyParseWorkspaceSettings(
          workspace.Settings,
          defaultWorkspaceSettings,
        );

        const currentWorkspaceSettings = workspaceState;

        const workspaceSettingToResetTo = {
          ...currentWorkspaceSettings,
        };

        propertyNames.forEach((propertyName) => {
          workspaceSettingToResetTo[propertyName] =
            workspaceSettingsFromService.value[propertyName];
        });

        setWorkspaceState(workspaceSettingToResetTo);

        return workspaceSettingsFromService.success;
      }
    },
    [currentPageConfiguration, setWorkspaceState, workspaceState, defaultWorkspaceSettings],
  );

  const applyWorkspaceSettings = useCallback(
    (workspaceSettings: string) => {
      if (workspaceSettings) {
        setWorkspaceState(
          safelyParseWorkspaceSettings(workspaceSettings, defaultWorkspaceSettings).value,
        );
      }
    },
    [setWorkspaceState, defaultWorkspaceSettings],
  );

  const handleResetWorkspace = useCallback(() => {
    if (selectedWorkspace && currentPageConfiguration) {
      const success = applyWorkSpaceSettingsForProperties(
        selectedWorkspace,
        currentPageConfiguration.propertyNames,
      );

      if (success) {
        toast.success(
          intl.formatMessage({
            id: "workspaces.reset-success",
            defaultMessage: "Workspace reset successfully.",
          }),
        );
      }
    }
  }, [applyWorkSpaceSettingsForProperties, currentPageConfiguration, intl, selectedWorkspace]);

  const handleResetToDefaultWorkspace = useCallback(() => {
    if (selectedWorkspace && currentPageConfiguration) {
      const success = applyWorkSpaceSettingsForProperties(
        { ...selectedWorkspace, Settings: JSON.stringify(defaultWorkspaceSettings) },
        currentPageConfiguration.propertyNames,
      );

      if (success) {
        toast.success(
          intl.formatMessage({
            id: "workspaces.reset-success",
            defaultMessage: "Workspace reset successfully.",
          }),
        );
      }
    }
  }, [
    applyWorkSpaceSettingsForProperties,
    currentPageConfiguration,
    defaultWorkspaceSettings,
    intl,
    selectedWorkspace,
  ]);

  return {
    selectedWorkspaceId,
    selectedWorkspace,
    setSelectedWorkspaceId,
    workspaceState,
    handleResetWorkspace,
    handleResetToDefaultWorkspace,
    applyWorkspaceSettings,
  };
};

export default useSelectedWorkspace;
