import { HTMLAttributes, useMemo, useState } from "react";

import classNames from "classnames";

import Tooltip from "components/ui/tooltip";

import Text from "../text";

interface AvatarProps extends HTMLAttributes<HTMLDivElement> {
  imageUrl?: string;
  text?: string;
}

interface AvatarWithLabelProps extends AvatarProps {
  label?: string;
}

export const AvatarWithLabel = ({ imageUrl, label = "-", text }: AvatarWithLabelProps) => {
  return (
    <div className="flex flex-nowrap items-center gap-5">
      <Avatar text={text ?? label} imageUrl={imageUrl} />
      <div>
        <Text>{label}</Text>
      </div>
    </div>
  );
};

const Avatar = ({ className, imageUrl, text, ...props }: AvatarProps) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const initials = useMemo(() => {
    let newInitials: any = text ? text.match(/\b\w/g) || [] : undefined;

    newInitials = newInitials
      ? ((newInitials.shift() || "") + (newInitials.pop() || "")).toUpperCase()
      : undefined;

    return newInitials;
  }, [text]);

  return (
    <Tooltip title={text ?? "-"}>
      <div
        className={classNames(
          "text-legacy-sm inline-flex aspect-square h-10 w-10 items-center justify-center rounded-full bg-overlay-medium p-1 text-inverted",
          className,
        )}
        {...props}
      >
        {imageUrl ? (
          <>
            <img
              src={imageUrl}
              alt={`Avatar ${initials}`}
              className={classNames(
                "aspect-square h-10 w-10 rounded-full",
                "duration-300 animate-in fade-in-20",
                imageLoaded ? "block" : "hidden",
              )}
              onLoad={() => setImageLoaded(true)}
            />
            <div className={imageLoaded ? "hidden" : "block"}>{initials}</div>
          </>
        ) : (
          initials
        )}
      </div>
    </Tooltip>
  );
};

export default Avatar;
