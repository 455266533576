import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

const useCurrencySymbol = (currency: string | undefined) => {
  const intl = useIntl();
  const [symbol, setSymbol] = useState<any>("");

  useEffect(() => {
    if (currency) {
      const formattedNumberParts = intl.formatNumberToParts(1, {
        currency,
        currencyDisplay: "narrowSymbol",
        style: "currency",
      });

      const currencySymbol = formattedNumberParts.find((part) => part.type === "currency");
      if (currencySymbol?.value) {
        setSymbol(currencySymbol?.value);
      }
    }
  }, [intl, currency]);

  return symbol;
};

export default useCurrencySymbol;
