import { FormattedMessage } from "react-intl";

import { Text } from "@new-black/lyra";

import { ClearFiltersButton } from "./clear-filters-button";

export interface IFiltersHeader {
  /** Defaults to `true`. */
  showResetButton?: boolean;
  onReset: () => void;
}

const FiltersHeader = ({ onReset, showResetButton = true }: IFiltersHeader) => {
  return (
    <div className="pb-4">
      <div className="flex items-center justify-between">
        <Text variant="heading-1" elementType="h2">
          <FormattedMessage id="generic.label.filters" defaultMessage="Filters" />
        </Text>
        {showResetButton ? <ClearFiltersButton onPress={onReset} /> : null}
      </div>
    </div>
  );
};

export default FiltersHeader;
