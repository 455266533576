import { ReactNode } from "react";

import { ButtonBase } from "@material-ui/core";
import { Card, CardProps } from "@new-black/lyra";
import classNames from "classnames";

import Grid from "../grid";
import Text from "../text";

interface ICardButtonProps {
  onClick?: () => void;
  icon?: React.ReactNode;
  variant?: CardProps["variant"];
  dashed?: boolean;
  disabled?: boolean;
  children: ReactNode;
  className?: { card?: string; container?: string };
}

const CardButton = ({
  children,
  className,
  dashed,
  disabled,
  icon,
  onClick,
  variant,
}: ICardButtonProps) => (
  <Card
    variant={variant}
    className={classNames(className?.card, dashed && variant !== "secondary" && "bg-transparent")}
    style={
      dashed
        ? {
            backgroundImage:
              "url(\"data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='rgba(0,0,0,0.38)' stroke-width='1' stroke-dasharray='6%2c6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e\")",
            border: "none",
          }
        : undefined
    }
  >
    <ButtonBase onClick={onClick} disabled={disabled} className="h-full w-full">
      <div
        className={classNames("flex w-full items-center justify-between p-5", className?.container)}
      >
        <Text
          variant="h3"
          className={classNames("leading-6", disabled && "text-[color:var(--eva-color-disabled)]")}
          color={dashed ? "secondary" : undefined}
        >
          {children}
        </Text>

        {icon ? (
          <span
            className={classNames(
              "flex h-6 items-center",
              disabled && "text-[var(--eva-color-disabled)]",
            )}
          >
            {icon}
          </span>
        ) : null}
      </div>
    </ButtonBase>
  </Card>
);

export default CardButton;
