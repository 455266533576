import { Params } from "react-router-dom";

import { getParams } from "remix-params-helper";
import { z } from "zod";

export const routeParamsIsPositiveInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.number().int().positive() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsDefiniteInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.number().int() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsOptionalPositiveInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.number().int().positive().optional() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsOptionalPositiveIntegerOrNew = <T = undefined>(
  params: Params<string> | URLSearchParams,
  routeParamName: string,
  newValueFallback?: T,
  newLiteral = "new" as const,
) => {
  const positiveNumberSchema = z.object({
    [routeParamName]: z.number().int().positive().optional(),
  });
  const newStringLiteralSchema = z.object({
    [routeParamName]: z.literal(newLiteral).optional(),
  });
  const parsedNumberParams = getParams(params, positiveNumberSchema);
  const parsedStringLiteralParams = getParams(params, newStringLiteralSchema);

  if (!parsedNumberParams.success && !parsedStringLiteralParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  const numberValue = parsedNumberParams.data?.[routeParamName];
  const stringValue = parsedStringLiteralParams.data?.[routeParamName];

  if (parsedNumberParams.success && numberValue !== undefined) {
    return numberValue;
  }
  if (parsedStringLiteralParams.success && stringValue === newLiteral) {
    return newValueFallback;
  }
  return undefined;
};

export const routeParamsIsOptionalNonNegativeInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.number().int().nonnegative().optional() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsNonNegativeInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.number().int().nonnegative() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsArrayOfPositiveInteger = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.array(z.number().int().positive()) });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};
