import { useEffect } from "react";

const useExternalAddButton = (
  externalAddButtonRef: React.MutableRefObject<HTMLElement | null> | undefined,
  handleAddButtonClick: () => void,
) => {
  // Effect that binds the add row handler to the element the `externalAddButtonRef` is pointing to and unbinds it on cleanup
  useEffect(() => {
    const externalAddButton = externalAddButtonRef?.current;

    if (externalAddButton) {
      externalAddButton.addEventListener("click", handleAddButtonClick);

      return () => {
        externalAddButton.removeEventListener("click", handleAddButtonClick);
      };
    }

    return undefined;
  }, [externalAddButtonRef, handleAddButtonClick]);
};

export default useExternalAddButton;
