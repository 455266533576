import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Autocomplete } from "components/suite-ui/autocomplete/material-autocomplete";
import { ITableCell } from "components/suite-ui/table/table-types";

export interface ITableInlineControlProps {
  cell: ITableCell<any>;
  onFieldUpdate: (state: any) => void;
}

interface ITableInlineAutocompleteItem {
  label: string;
  value: string | number;
}

interface ITableInlineAutocompleteProps extends ITableInlineControlProps {
  items: ITableInlineAutocompleteItem[];
  label: string;
  emptyFieldError?: string;
  required?: boolean;
  shouldValidate?: boolean;
}

const TableInlineAutocomplete = ({
  cell,
  emptyFieldError,
  items,
  label,
  onFieldUpdate,
  required,
  shouldValidate,
}: ITableInlineAutocompleteProps) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(false);

  const [selectedItem, setSelectedItem] = useState<ITableInlineAutocompleteItem | undefined>(
    items?.find((item) => item.value === cell.value),
  );

  const handleChange = useCallback(
    (newValue?: ITableInlineAutocompleteItem) => {
      setSelectedItem(newValue);
      onFieldUpdate(newValue?.value);
    },
    [onFieldUpdate],
  );

  const hasError = useMemo(
    () => !selectedItem && (shouldValidate || touched),
    [touched, selectedItem, shouldValidate],
  );

  return (
    <Autocomplete
      small
      required={required}
      IDKey="value"
      LabelKey="label"
      value={selectedItem}
      setValue={(newValue) => handleChange(newValue)}
      onBlur={() => setTouched(true)}
      label={label}
      items={items}
      error={hasError}
      helperText={
        hasError
          ? emptyFieldError ??
            intl.formatMessage({
              id: "validation.empty",
              defaultMessage: "This field cannot be empty",
            })
          : undefined
      }
    />
  );
};

export default TableInlineAutocomplete;
