import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const countrySubdivisionsListServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: true,
  service: Core.GetCountrySubdivisions,
  key: "TaxManagement:Core.GetCountrySubdivisions",
});

export const countrySubdivisionsListResponseSelector = selector({
  key: "TaxManagement:Core.GetCountrySubdivisions:response",
  get: ({ get }) => get(countrySubdivisionsListServiceState.response)?.CountrySubdivisions,
});
