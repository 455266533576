import { Dialog } from "@material-ui/core";

import { TUploadModalProps } from "./upload-modal.types";
import UploadModalContent from "./upload-modal-content";

const UploadModal = ({ onClose, open, ...props }: TUploadModalProps) => (
  <Dialog open={open} onClose={onClose} fullWidth PaperProps={{ className: "max-w-[640px]" }}>
    <UploadModalContent onClose={onClose} {...props} />
  </Dialog>
);

export default UploadModal;
