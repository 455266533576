import { useCallback, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { IUseCallServiceOptions as IEVAUseCallServiceOptions } from "@springtree/eva-sdk-react-recoil/lib/hooks/use-call-service";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import ky from "ky";

export interface IUseCallServiceMessages {
  /** Shown while the call is in progress */
  loadingMessage?: string;
  /** Shown when the call is successful */
  successMessage?: string;
  /** Called when the call is successful */
  /** Shown when the call fails and a service error message is not available. */
  fallbackErrorMessage?: string;
}

export interface IUseFunctionalCallServiceMessages<SVC extends IEvaServiceDefinition> {
  /** Shown while the call is in progress */
  loadingMessage?: string;
  /** Shown when the call is successful */
  successMessage?: string | ((data: SVC["response"]) => string);
  /** Called when the call is successful */
  /** Shown when the call fails and a service error message is not available. */
  fallbackErrorMessage?: string | ((data: SVC["response"]) => string);
}

export interface IUseCallServiceOptions<SVC extends IEvaServiceDefinition>
  extends IEVAUseCallServiceOptions<SVC> {
  onError?: (error: Error, serviceResponse?: SVC["response"]) => void;
}

export type UseCallServiceProps<SVC extends IEvaServiceDefinition> = {
  service: new () => SVC;
  options?: IUseCallServiceOptions<SVC>;
  disableNotifications?: boolean;
  disableSuccessNotification?: boolean;
  disableLoadingNotification?: boolean;
  /** Pass custom strings to be displayed inside the toast. */
  messages?: IUseCallServiceMessages | IUseFunctionalCallServiceMessages<SVC>;
};

/**
 * Helper hook to call an EVA service. It is based on `hook.useCallService` from `eva-sdk-react-recoil`, but in addition manages
 * loading state and displaying notifications.
 *
 * @param service The EVA definition of the service to call
 * @param options Call options
 * @param disableNotifications If true, no toaster notifications will be displayed
 * @param messages Custom messages to be displayed inside the toast
 * @param translations Custom translation keys to be translated and displayed inside the toast
 *
 * @returns a callback for calling the service with the given options, a response state and a loading state.
 *
 * @translations
 * Make sure to add the default translations used by the hook to your project
 * ```json
 * {
 *   "generic.message.action-success": "Action successfully executed.",
 *   "generic.label.loading": "Loading...",
 *   "generic.message.something-went-wrong": "Something went wrong!"
 * }
 * ```
 */
const useCallService = <SVC extends IEvaServiceDefinition>({
  disableLoadingNotification,
  disableNotifications,
  disableSuccessNotification,
  messages,
  options,
  service,
}: UseCallServiceProps<SVC>) => {
  const [serviceLoading, setServiceLoading] = useState(false);
  const [serviceResponse, setServiceResponse] = useState<SVC["response"] | undefined>();
  const toastID = useRef<string>();
  const intl = useIntl();

  const decoratedOptions = useMemo(
    () => ({
      onSuccess: (data: SVC["response"]) => {
        if (!disableNotifications && !disableSuccessNotification) {
          toast.success(
            (messages?.successMessage && typeof messages?.successMessage === "function"
              ? messages?.successMessage?.(data)
              : messages?.successMessage) ??
              intl.formatMessage({
                id: "generic.message.action-success",
                defaultMessage: "Action successfully executed.",
              }),
            { id: toastID.current },
          );
        }

        options?.onSuccess?.(data);
      },
      onError: async (e: Error) => {
        // eslint-disable-next-line new-cap
        const serviceName = new service().name;

        let serviceResponse = undefined;
        if ((e as Error).name === "HTTPError") {
          const httpError = e as ky.HTTPError;
          serviceResponse = await httpError.response.clone().json();
        }

        console.error(`[Error: ${serviceName}]:`, serviceResponse ?? e);

        if (!disableNotifications) {
          toast.error(
            serviceResponse?.Error?.Message ??
              (messages?.fallbackErrorMessage &&
              typeof messages?.fallbackErrorMessage === "function"
                ? messages?.fallbackErrorMessage?.(serviceResponse)
                : messages?.fallbackErrorMessage) ??
              intl.formatMessage({
                id: "generic.message.something-went-wrong",
                defaultMessage: "Something went wrong!",
              }),
            { id: toastID.current },
          );
        }

        options?.onError?.(e, serviceResponse);
      },
    }),
    [disableNotifications, disableSuccessNotification, intl, messages, options, service],
  );

  const mutate = hooks.useCallService({
    service,
  });

  const callService = useCallback(
    async (req: SVC["request"], callOptions?: IEvaServiceCallOptions) => {
      setServiceLoading(true);

      if (!disableNotifications && !disableLoadingNotification) {
        toastID.current = toast.loading(
          messages?.loadingMessage ??
            intl.formatMessage({
              id: "generic.label.loading",
              defaultMessage: "Loading...",
            }),
        );
      }

      try {
        const response = await mutate(req, callOptions, decoratedOptions);
        setServiceResponse(response);
        return response;
      } catch (e) {
        console.error(e);
        setServiceResponse(undefined);

        if (!disableNotifications) {
          toast.error(
            intl.formatMessage({
              id: "generic.message.something-went-wrong",
              defaultMessage: "Something went wrong!",
            }),
            { id: toastID.current },
          );
        }

        return undefined;
      } finally {
        setServiceLoading(false);
      }
    },
    [
      decoratedOptions,
      disableLoadingNotification,
      disableNotifications,
      intl,
      messages?.loadingMessage,
      mutate,
    ],
  );

  return {
    serviceLoading,
    serviceResponse,
    callService,
  };
};

export default useCallService;
