import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { FormLabel, FormLabelProps } from "@material-ui/core";
import { Checkbox as LyraCheckbox, MultiSelect, Text as LyraText } from "@new-black/lyra";

import { CheckboxIcon } from "assets/icons/components/custom-field-icons";
import Grid from "components/suite-ui/grid";
import Select from "components/suite-ui/select";
import Text from "components/suite-ui/text";
import { Checkbox } from "components/ui/checkbox";
import { emptyArrayToUndefined } from "util/helper";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

interface ICustomMultiEnumFieldConsumerProps
  extends ICustomFieldConsumerProps<string[] | undefined> {
  options: { [key: string]: string };
  enumDisplay?: "expanded" | "inline";
  /** Defaults to `true` */
  allowMultipleValues?: boolean;
}

const StyledFormLabel = (props: FormLabelProps) => (
  <FormLabel
    className="text-legacy-sm  pl-0 leading-7 tracking-[0.15px]"
    classes={{
      focused: "text-[color:var(--legacy-eva-color-dark-2)]",
      disabled: "text-[color:var(--legacy-eva-color-disabled)]",
    }}
    {...props}
  />
);

export const CustomMultiEnumFieldConsumer = ({
  allowMultipleValues = true,
  componentVariant,
  enumDisplay,
  error,
  label,
  name,
  onChange,
  options,
  required,
  value,
}: ICustomMultiEnumFieldConsumerProps) => {
  const intl = useIntl();

  const optionsArrayForCheckboxesList = useMemo(
    () =>
      Object.entries(options).map((entry) => ({
        Label: entry[1],
        BackendID: entry[0],
      })),
    [options],
  );

  const optionsArrayForSelect = useMemo(
    () => Object.entries(options).map((entry) => ({ label: entry[1], value: entry[0] })),
    [options],
  );

  if (componentVariant === "lyra") {
    if (enumDisplay === "inline") {
      return (
        <MultiSelect
          name={name}
          hideHintLabel
          label={label}
          items={optionsArrayForSelect}
          getItemId={(item) => item.value}
          getLabel={(item) => item.label}
          selectRenderElements={(item) => ({ label: item.label })}
          errorMessage={error}
          value={optionsArrayForSelect.filter((option) => value?.includes(option.value))}
          onChange={(newValue) => onChange(newValue?.map((entry) => entry.value))}
        />
      );
    }

    return (
      <>
        <LyraText variant="body-medium">
          <FormattedMessage id="generic.label.value" defaultMessage="Value" />
        </LyraText>

        {optionsArrayForCheckboxesList.map((option, index) => (
          <LyraCheckbox
            key={index}
            isSelected={value?.includes(option.BackendID) ?? false}
            onChange={(newValue) =>
              onChange(
                newValue
                  ? [...(value ?? []), option.BackendID]
                  : value?.filter((entry) => entry !== option.BackendID),
              )
            }
          >
            {option.BackendID}
          </LyraCheckbox>
        ))}

        {error ? (
          <LyraText variant="body-small" color="error">
            {error}
          </LyraText>
        ) : null}
      </>
    );
  }

  return enumDisplay === "inline" ? (
    <Select
      name="Select"
      label={label ?? intl.formatMessage({ id: "generic.label.select", defaultMessage: "Select" })}
      multiple
      items={optionsArrayForSelect}
      value={value ?? []}
      onChange={(event) => {
        if (allowMultipleValues) {
          onChange(event.target.value as string[]);
        } else {
          const newValue = (event.target.value as string[]).filter((key) => !value?.includes(key));
          onChange(newValue);
        }
      }}
      fullWidth
      error={!!error}
      helpertext={error}
      required={required}
      endIcon={<CheckboxIcon fontSize="small" />}
    />
  ) : (
    <Grid container spacing={1} direction="column">
      <Grid item>
        <StyledFormLabel required={required} error={!!error}>
          {label}
        </StyledFormLabel>
      </Grid>

      {optionsArrayForCheckboxesList.map((option) => (
        <Grid key={option.Label} item>
          <div className="pl-[5px]">
            <Checkbox
              name={`${name}-${option.BackendID}`}
              value={(value ?? []).includes(option.BackendID)}
              onChange={(newValue) => {
                if (newValue && !(value ?? []).includes(option.BackendID)) {
                  onChange(emptyArrayToUndefined([...(value ?? []), option.BackendID]));
                } else if (!newValue && (value ?? []).includes(option.BackendID)) {
                  onChange(
                    emptyArrayToUndefined((value ?? []).filter((v) => v !== option.BackendID)),
                  );
                }
              }}
              label={option.Label}
            />
          </div>
        </Grid>
      ))}

      {error ? (
        <Grid item>
          <Text variant="caption" color="error">
            {error}
          </Text>
        </Grid>
      ) : null}
    </Grid>
  );
};
