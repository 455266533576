import { useRecoilState } from "recoil";

import { ControlledEnumSelect, ControlledLyraEnumSelect } from "./controlled-enum-select";
import { IRecoilEnumSelectProps, IRecoilLyraEnumSelectProps } from "./enum-select.types";

/**
 * The props for the Recoil version of the enum select.
 * @param name The name of the select field (optional, if not provided, the recoil key will be used as the name).
 * @param recoilState The recoil state to use for the value.
 * 
 * ### Example:
```tsx
import { EnumSelect } from "components/shared/enum-select";

// replicating EVA.Core.Management.ListSettings.TypedFilters
enum TypedFilters {
  ShowAll = 0,
  ShowTyped = 1,
  ShowUntyped = 2,
}

const typedFilterState = atom<TypedFilters | undefined>({
  key: "typedFilterState",
  default: undefined,
});

const labelLookup = {
  [TypedFilters.ShowAll]: "Show all",
  [TypedFilters.ShowTyped]: "Show typed",
  [TypedFilters.ShowUntyped]: "Show untyped",
};

export const TypedFilter = () => (
  <EnumSelect.Recoil
    enum={TypedFilters}
    label="Setting type"
    name="TypedFiltersSelect"
    recoilState={typedFilterState}
    getOptionLabel={(value) => labelLookup[value]}
  />
);
```
*/
export const RecoilEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  name,
  recoilState,
  ...rest
}: IRecoilEnumSelectProps<EnumType, EnumValue>) => {
  const [value, setValue] = useRecoilState(recoilState);
  return (
    <ControlledEnumSelect
      {...rest}
      value={value}
      name={name ?? recoilState.key}
      onChange={(newValue) => setValue(newValue)}
    />
  );
};

export const RecoilLyraEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  name,
  recoilState,
  ...rest
}: IRecoilLyraEnumSelectProps<EnumType, EnumValue>) => {
  const [value, setValue] = useRecoilState(recoilState);
  return (
    <ControlledLyraEnumSelect
      {...rest}
      value={value}
      name={name ?? recoilState.key}
      onChange={(newValue) => setValue(newValue)}
    />
  );
};
