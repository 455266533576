/* eslint-disable @typescript-eslint/no-unused-vars */
import { CSSProperties, HTMLAttributes } from "react";

import {
  ListItemIcon,
  ListItemIconProps,
  ListItemSecondaryAction,
  ListItemText,
  ListItemTextProps,
  MenuItem,
  MenuItemProps,
  Omit,
} from "@material-ui/core";
import { Button, SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "classnames";

const StyledMenuItem = ({ className, ...props }: Omit<MenuItemProps, "button">) => (
  <MenuItem className={classNames("h-[50px] w-full", className)} {...props} />
);

const StyledListItemIcon = ({
  className,
  selected,
  ...props
}: ListItemIconProps & { selected: boolean }) => (
  <ListItemIcon
    {...props}
    className={classNames(
      "min-w-0 pr-4 text-[color:var(--legacy-eva-color-dark-2)]",
      selected && "text-[color:var(--legacy-eva-color-primary)]",
      className,
    )}
  />
);

const StyledListItemText = ({ className, ...props }: ListItemTextProps) => (
  <ListItemText
    {...props}
    className={classNames(
      "m-0 mr-2.5 flex-grow",
      "[&_span]:overflow-hidden [&_span]:text-ellipsis [&_span]:whitespace-nowrap",
      className,
    )}
  />
);

const styledListItemIDClasses = cva(
  "flex-0 m-0 flex-grow-0 [&_span]:text-xs [&_span]:font-normal [&_span]:text-[color:var(--legacy-eva-color-transparent-500)]",
  {
    variants: {
      textAlign: {
        left: "[&_span]:text-left",
        right: "[&_span]:text-right",
        center: "[&_span]:text-center",
      },
      fullWidth: {
        true: "w-full",
        false: "w-12",
      },
    },
  },
);

const StyledListItemID = ({
  className,
  fullWidth,
  textAlign,
  ...props
}: {
  fullWidth?: boolean;
  textAlign: "right" | "left" | "center";
} & ListItemTextProps) => (
  <ListItemText
    {...props}
    className={classNames(styledListItemIDClasses({ textAlign, fullWidth }), className)}
  />
);

const StyledItemTextContainer = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    {...props}
    className={classNames(
      "flex flex-grow flex-wrap items-baseline justify-between overflow-hidden",
      className,
    )}
  />
);

export interface IOrganizationUnitMenuItemProps {
  name: string;
  id: number;
  isSelected: boolean;
  onClick: () => void;
  onGoNext?: () => void;
  backendId?: string;
  style?: CSSProperties;
  showId?: boolean;
}

const OrganizationUnitMenuItem = ({
  backendId,
  id,
  isSelected,
  name,
  onClick,
  onGoNext,
  showId = false,
  style,
}: IOrganizationUnitMenuItemProps) => (
  <StyledMenuItem
    onClick={onClick}
    ContainerProps={{
      style: { ...style, width: "100%" },
    }}
    ContainerComponent="div"
  >
    <StyledListItemIcon selected={isSelected}>
      {isSelected ? (
        <SvgIcon name="alert-success" className="ml-1" />
      ) : (
        <div className="ml-1 h-4 w-4" />
      )}
    </StyledListItemIcon>
    <StyledItemTextContainer>
      <StyledListItemText>{name}</StyledListItemText>
      {showId ? <StyledListItemID textAlign="right">ID: {id}</StyledListItemID> : null}
      {/* A fullWidth flex item will be forced to wrap to a new line */}
      <StyledListItemID textAlign="left" fullWidth>
        {backendId ? `(${backendId})` : ""}
      </StyledListItemID>
    </StyledItemTextContainer>
    <ListItemSecondaryAction>
      {onGoNext ? (
        <Button variant="icon-xs" onPress={onGoNext}>
          <SvgIcon name="chevron-right" />
        </Button>
      ) : null}
    </ListItemSecondaryAction>
  </StyledMenuItem>
);

export default OrganizationUnitMenuItem;
