import moment from "moment";

export const setMomentLocale = (locale: string) => {
  if (locale.startsWith("no")) {
    moment.locale("nn");
  } else if (locale.startsWith("zh-HK")) {
    moment.locale("zh-hk");
  } else if (locale.startsWith("zh")) {
    moment.locale("zh-cn");
  } else {
    moment.locale(locale.split("-")[0]);
  }
};
