import { IntlShape } from "react-intl";

enum CustomOrderStatus {
  Signed = 1,
  Confirmed = 2,
  NeedsEmployeeVerification = 4,
  VerifiedByEmployee = 8,
}

export const getCustomOrderStatusTranslator = (intl: IntlShape) => {
  const statusMap: Record<CustomOrderStatus, string> = {
    [CustomOrderStatus.Signed]: intl.formatMessage({
      id: "custom-status.Signed",
      defaultMessage: "Signed",
    }),
    [CustomOrderStatus.Confirmed]: intl.formatMessage({
      id: "custom-status.Confirmed",
      defaultMessage: "Confirmed",
    }),
    [CustomOrderStatus.NeedsEmployeeVerification]: intl.formatMessage({
      id: "custom-status.NeedsEmployeeVerification",
      defaultMessage: "Needs verification",
    }),
    [CustomOrderStatus.VerifiedByEmployee]: intl.formatMessage({
      id: "custom-status.VerifiedByEmployee",
      defaultMessage: "Verified",
    }),
  };

  return (value: CustomOrderStatus) => statusMap[value];
};
