import { Dispatch, SetStateAction } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Separator } from "@new-black/lyra";

type ShowMoreButtonProps = {
  limit: number;
  total?: number;
  setLimit: Dispatch<SetStateAction<number>>;
  isLoading?: boolean;
};

export const ShowMoreButton = ({ isLoading, limit, setLimit, total }: ShowMoreButtonProps) => {
  return limit < (total ?? 0) ? (
    <>
      <Separator />
      <div className="p-4">
        <Button
          variant="secondary"
          className="mx-auto"
          onPress={() => setLimit((prev) => prev + 5)}
          isLoading={isLoading}
        >
          <FormattedMessage
            id="generic.label.view-more-results"
            defaultMessage="View more results"
          />
        </Button>
      </div>
    </>
  ) : null;
};
