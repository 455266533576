import { useCallback, useEffect, useState } from "react";

import { differenceBy, uniqBy } from "lodash";

const useMultiAutocomplete = <T>(
  defaultSelectedItems: T[] | undefined,
  uniqBySelector: keyof T,
) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedItems, setSelectedItems] = useState<T[] | undefined>(defaultSelectedItems);

  useEffect(() => {
    if (defaultSelectedItems?.length && !isInitialized) {
      setSelectedItems(defaultSelectedItems);
      setIsInitialized(true);
    }
  }, [defaultSelectedItems, isInitialized]);

  const handleDeselectItem = useCallback(
    (item: T) => {
      setSelectedItems((state) => {
        const newState = state?.filter(
          (selectedItem) => selectedItem[uniqBySelector] !== item[uniqBySelector],
        );

        return newState?.length ? newState : undefined;
      });
    },
    [uniqBySelector],
  );

  const handleSelectedItems = useCallback(
    (items: T[]) => {
      // if the array does not contain anything add the item otherwise remove it
      if (selectedItems && selectedItems.length) {
        // filter out common elements so selecting an already selected item will de-select it
        const remainingSelectedItems = differenceBy(selectedItems, items, uniqBySelector);
        const remainingItems = differenceBy(items, selectedItems, uniqBySelector);

        setSelectedItems(uniqBy([...remainingSelectedItems, ...remainingItems], uniqBySelector));
      } else {
        setSelectedItems(uniqBy(items, uniqBySelector));
      }
    },
    [selectedItems, uniqBySelector],
  );

  const clearAll = useCallback(() => setSelectedItems(undefined), []);

  return {
    selectedItems,
    handleSelectedItems,
    setSelectedItems,
    clearAll,
    handleDeselectItem,
    isInitialized,
  };
};

export default useMultiAutocomplete;
