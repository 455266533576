import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Card, CardActions, CardContent, CardHeader, Text } from "@new-black/lyra";

import { EvaFilterModel } from "components/suite-composite/product-filter/types";

import { TLoyaltyProgramOrderAmountForm } from "./loyalty-program-rule-order-amount-condition/add-edit-loyalty-program-rule-order-amonut-condition.types";
import { ELoyaltyProgramRuleConditionTypes } from "./loyalty-program-rule-conditions.types";
import { LoyaltyProgramRuleConditionsCardAddAction } from "./loyalty-program-rule-conditions-card-add-action";
import { LoyaltyProgramRuleConsumerConditionCard } from "./loyalty-program-rule-consumer-condition";
import { LoyaltyProgramRuleOrderAmountConditionCard } from "./loyalty-program-rule-order-amount-condition";
import { LoyaltyProgramRuleProductSetConditionCard } from ".";

export const LoyaltyProgramRuleConditionsCard = ({
  conditions,
  onAddConsumerCondition,
  onAddOrderAmountCondition,
  onAddProductSetCondition,
  onDeleteCondition,
  onEditConsumerCondition,
  onEditOrderAmountCondition,
  onEditProductSetCondition,
  onUpdateProductSetFilter,
}: {
  onAddProductSetCondition?: () => void;
  onEditProductSetCondition?: (conditionID: number) => void;
  onUpdateProductSetFilter?: (conditionID: number, newValue?: EvaFilterModel) => Promise<void>;
  onAddConsumerCondition?: () => void;
  onAddOrderAmountCondition?: () => void;
  onEditConsumerCondition?: (conditionID: number) => void;
  onEditOrderAmountCondition?: (conditionID: number) => void;
  onDeleteCondition?: (conditionID: number) => void;
  conditions?: EVA.Core.Management.GetPointAwardingRuleResponse.Condition[];
}) => {
  const intl = useIntl();

  const orderAmountCondition = useMemo(
    () =>
      conditions?.find(
        (condition) => condition.Type === ELoyaltyProgramRuleConditionTypes.ORDERAMOUNT,
      ),
    [conditions],
  );

  const productSetConditions = useMemo(
    () =>
      conditions?.filter(
        (condition) => condition.Type === ELoyaltyProgramRuleConditionTypes.PRODUCT,
      ),
    [conditions],
  );

  const consumerConditions = useMemo(
    () =>
      conditions?.filter(
        (condition) => condition.Type === ELoyaltyProgramRuleConditionTypes.USERCUSTOMFIELD,
      ),
    [conditions],
  );

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: "generic.label.conditions", defaultMessage: "Conditions" })}
        actions={
          <CardActions>
            <LoyaltyProgramRuleConditionsCardAddAction
              onAddOrderAmountCondition={
                !orderAmountCondition ? onAddOrderAmountCondition : undefined
              }
              onAddProductSetCondition={onAddProductSetCondition}
              onAddConsumerCondition={onAddConsumerCondition}
            />
          </CardActions>
        }
      />
      <CardContent className="flex flex-col gap-5">
        {conditions?.length ? (
          <>
            {orderAmountCondition ? (
              <LoyaltyProgramRuleOrderAmountConditionCard
                conditionID={orderAmountCondition.ID}
                data={
                  orderAmountCondition.Data?.Amount
                    ? (orderAmountCondition.Data as TLoyaltyProgramOrderAmountForm)
                    : undefined
                }
                onEdit={onEditOrderAmountCondition}
                onDelete={onDeleteCondition}
              />
            ) : null}

            {productSetConditions?.length ? (
              <>
                {productSetConditions?.map((productSetCondition) => (
                  <LoyaltyProgramRuleProductSetConditionCard
                    key={productSetCondition.ID}
                    conditionID={productSetCondition.ID}
                    data={productSetCondition.Data}
                    onEdit={onEditProductSetCondition}
                    onUpdateProductSetFilter={onUpdateProductSetFilter}
                    onDelete={onDeleteCondition}
                    inner
                  />
                ))}
              </>
            ) : null}

            {consumerConditions?.length ? (
              <>
                {consumerConditions?.map((consumerCondition) => (
                  <LoyaltyProgramRuleConsumerConditionCard
                    key={consumerCondition.ID}
                    conditionID={consumerCondition.ID}
                    data={consumerCondition.Data?.Conditions?.[0]}
                    onEdit={onEditConsumerCondition}
                    onDelete={onDeleteCondition}
                    inner
                  />
                ))}
              </>
            ) : null}
          </>
        ) : (
          <Text color="secondary">
            {onAddConsumerCondition ||
            (!orderAmountCondition && onAddOrderAmountCondition) ||
            onAddProductSetCondition ? (
              <FormattedMessage
                id="generic.label.click-to-add-condition"
                defaultMessage="Click the '+' icon to add a condition."
              />
            ) : (
              <FormattedMessage
                id="loyalty-programs.no-conditions"
                defaultMessage="This rule has no conditions attached."
              />
            )}
          </Text>
        )}
      </CardContent>
    </Card>
  );
};
