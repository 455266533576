import { useMemo } from "react";

import LoadingStateBox from "components/suite-ui/loading-state-box";
import useAppConfigCustomFields from "hooks/use-app-config-custom-fields";
import { ICustomField } from "types/custom-field";

import {
  CFCInferredCFType,
  CFCInferredFieldType,
  CFCTypedValueCustomField,
  customFieldChangeToCustomFieldValue,
  ICFCTranslations,
} from "./custom-field-consumer.types";
import CustomFieldDataTypeConsumer from "./custom-field-data-types";
import { useCustomFieldLabel } from "./use-custom-field-label";

type X = CFCTypedValueCustomField;

interface ICustomFieldConsumerProps<U extends X> {
  /** Value of the Custom Field Consumer */
  value: CFCInferredFieldType<U>;
  /** Label of the Custom Field Consumer
   *
   * **Precedence:** label prop > EntityTranslations > `customField.DisplayName` > `customField.Name`
   */
  label?: string;
  /** Callback to be called when the value of the Custom Field Consumer changes
   *
   * @param value New value of the Custom Field Consumer
   * @param type Type of the Custom Field Consumer value (e.g. `StringValue`, `EnumValue`, etc.)
   * @param mappedValue Mapped value of the CustomField to CustomFieldValue (e.g. `SingleLineStringValue` -> `{ StringValue: value }`)
   */
  onChange: (
    value: CFCInferredFieldType<U>,
    type: CFCInferredCFType<U>,
    mappedValue: ReturnType<typeof customFieldChangeToCustomFieldValue<U>>,
    error: string | undefined,
    customFieldData: ICustomField,
    entityTranslatedLabel: string | undefined,
  ) => void;
  /** Customizable translations for the consumer (validation messages, labels, etc.)
   *
   * If this is not provided, the consumer will use the defined EntityTranslations
   * for the selected culture of the current user, and fallback to the default (english) translations.
   */
  translations?: ICFCTranslations;
  placeholder?: string;
  disableLabel?: boolean;
  enumDisplay?: "expanded" | "inline";
  booleanVariant?: "switch" | "segmented" | "radio";
  error?: string;
  touched?: boolean;
  /** Setting it to `false` will hide add / remove controls from multi data types and prevents selecting
   * multiple values for multi enum select. Defaults to `true`.
   */
  allowMultipleValues?: boolean;
  /** Setting it to `true` will show the validation message as helper text even if the field is not touched.
   * Defaults to `false`.
   */
  alwaysShowValidationMessage?: boolean;
  variant?: "material" | "lyra";
}

export interface IRemoteCustomFieldConsumerProps<T extends X> extends ICustomFieldConsumerProps<T> {
  /** ID of the Custom Field to be fetched from EVA */
  customFieldId: number;
  organizationUnitId?: number;
  customField?: never;
}

export interface ILocalCustomFieldConsumerProps<T extends X> extends ICustomFieldConsumerProps<T> {
  customFieldId?: never;
  organizationUnitId?: never;
  /** Local Custom Field to be used by the Consumer */
  customField: ICustomField;
}

/** This Consumer is used whenever we need to fetch the data of the CustomField from EVA */
export const CustomFieldRemoteConsumer = <T extends X>({
  allowMultipleValues = true,
  alwaysShowValidationMessage = false,
  booleanVariant,
  customFieldId,
  disableLabel,
  enumDisplay,
  error,
  label,
  onChange,
  organizationUnitId,
  placeholder,
  touched,
  translations,
  value,
  variant,
}: IRemoteCustomFieldConsumerProps<T>) => {
  const { getCustomFieldById, isLoading } = useAppConfigCustomFields(organizationUnitId);
  const customField = useMemo(
    () => getCustomFieldById(customFieldId),
    [customFieldId, getCustomFieldById],
  );

  const customFieldLabel = useCustomFieldLabel({ customField, label });

  if (isLoading || !customField) {
    return <LoadingStateBox limit={1} />;
  }

  return (
    <CustomFieldDataTypeConsumer
      value={value}
      error={error}
      touched={touched}
      label={customFieldLabel}
      placeholder={placeholder}
      enumDisplay={enumDisplay}
      customField={customField}
      disableLabel={disableLabel}
      translations={translations}
      booleanVariant={booleanVariant}
      onChange={(value, type, error) =>
        onChange(
          value,
          type,
          customFieldChangeToCustomFieldValue(value, type, customField.Options?.IsRequired),
          error,
          customField,
          customFieldLabel,
        )
      }
      allowMultipleValues={allowMultipleValues}
      alwaysShowValidationMessage={alwaysShowValidationMessage}
      variant={variant}
    />
  );
};

/** This Consumer is used whenever we already have the data of the CustomField locally (e.g. from a previous service response) */
export const CustomFieldLocalConsumer = <T extends X>({
  allowMultipleValues = true,
  alwaysShowValidationMessage = false,
  booleanVariant,
  customField,
  disableLabel,
  enumDisplay,
  error,
  label,
  onChange,
  placeholder,
  touched,
  translations,
  value,
  variant,
}: ILocalCustomFieldConsumerProps<T>) => {
  const customFieldLabel = useCustomFieldLabel({ customField, label });

  return (
    <CustomFieldDataTypeConsumer
      value={value}
      error={error}
      touched={touched}
      label={customFieldLabel}
      placeholder={placeholder}
      customField={customField}
      enumDisplay={enumDisplay}
      disableLabel={disableLabel}
      translations={translations}
      booleanVariant={booleanVariant}
      onChange={(value, type, error) =>
        onChange(
          value,
          type,
          customFieldChangeToCustomFieldValue(value, type, customField.Options?.IsRequired),
          error,
          customField,
          customFieldLabel,
        )
      }
      allowMultipleValues={allowMultipleValues}
      alwaysShowValidationMessage={alwaysShowValidationMessage}
      variant={variant}
    />
  );
};

/** This is the main consumer of the CustomField. It will determine if the CustomField is local or remote. */
export const CustomFieldConsumer = <T extends X>({
  customField,
  customFieldId,
  organizationUnitId,
  ...props
}: IRemoteCustomFieldConsumerProps<T> | ILocalCustomFieldConsumerProps<T>) => {
  if (customFieldId !== undefined) {
    return (
      <CustomFieldRemoteConsumer
        customFieldId={customFieldId}
        organizationUnitId={organizationUnitId}
        {...props}
      />
    );
  }
  if (customField !== undefined) {
    return <CustomFieldLocalConsumer customField={customField} {...props} />;
  }
  return null;
};
