import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

const DuplicateActionButton = forwardRef<HTMLButtonElement, IActionButton & { small?: boolean }>(
  (
    {
      className,
      isDisabled,
      isLoading,
      onPress,
      small,
      tooltip,
    }: IActionButton & { small?: boolean },
    ref,
  ) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        className={className}
        onPress={onPress}
        tooltip={
          tooltip ??
          intl.formatMessage({ id: "generic.label.duplicate", defaultMessage: "Duplicate" })
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={small ? "icon-xs" : "icon"}
      >
        <SvgIcon name="copy" />
      </Button>
    );
  },
);

DuplicateActionButton.displayName = "DuplicateActionButton";

export default DuplicateActionButton;
