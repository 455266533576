import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import Input from "components/suite-ui/input";
import { ITableCell } from "components/suite-ui/table/table-types";

interface ITableInlineControlProps {
  cell: ITableCell<any>;
  onFieldUpdate: (state: any) => void;
}

interface ITableInlineTextInputProps extends ITableInlineControlProps {
  emptyFieldError?: string;
  label: string;
  required?: boolean;
  shouldValidate?: boolean;
}

const TableInlineTextInput = ({
  cell,
  emptyFieldError,
  label,
  onFieldUpdate,
  required,
  shouldValidate,
}: ITableInlineTextInputProps) => {
  const intl = useIntl();
  const [touched, setTouched] = useState(false);

  const [value, setValue] = useState<string | undefined>(cell.value);

  useEffect(() => {
    onFieldUpdate(value);
  }, [cell.value, onFieldUpdate, value]);

  const handleChange = useCallback((newValue: string) => setValue(newValue), []);

  const hasError = useMemo(
    () => !value && (shouldValidate || touched),
    [shouldValidate, touched, value],
  );

  return (
    <Input
      required={required}
      small
      value={value ?? ""}
      label={label}
      onBlur={() => setTouched(true)}
      error={hasError}
      onChange={(event) => handleChange(event.target.value)}
      helperText={
        hasError
          ? emptyFieldError ??
            intl.formatMessage({
              id: "validation.empty",
              defaultMessage: "This field cannot be empty",
            })
          : undefined
      }
    />
  );
};

export default TableInlineTextInput;
