import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";

import { organizationUnitSummaryService } from "./organization-unit-summary.state";

export const useOrganizationUnitSummary = (id?: number) => {
  const organizationUnitSummary = hooks.useGetState(organizationUnitSummaryService.response(id));

  const isLoading = hooks.useIsLoading({
    state: organizationUnitSummaryService.serviceState.response(id),
  });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !organizationUnitSummary,
    [isLoading, organizationUnitSummary],
  );

  return {
    isLoading,
    organizationUnitSummary,
    isLoadingWithoutPreviousResponse,
  };
};

useOrganizationUnitSummary.service = organizationUnitSummaryService;

export const useOrganizationUnitSummarySWR = (id?: number) => {
  useHandleSWRFamily(organizationUnitSummaryService.serviceState, id);
};
