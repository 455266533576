import LoadingStateBox from "components/shared/loading-state-box";
import { IProductSummaryProps, ProductSummary } from "components/suite-composite/product-summary";
import { useProductDetailQuery } from "models/products";

export const ProductSummaryBasedOnProductID = ({
  productId,
  ...props
}: { productId: number } & Omit<
  IProductSummaryProps,
  "productName" | "productImageBlobID" | "productID" | "backendID" | "customID"
>) => {
  const { data: productDetails, isFetching: isLoading } = useProductDetailQuery(
    {
      ID: productId,
    },
    {},
  );

  return isLoading ? (
    <LoadingStateBox limit={1} componentClassNames={{ container: "-my-5" }} />
  ) : (
    <ProductSummary
      {...props}
      productName={productDetails?.Result?.display_value ?? "-"}
      productImageBlobID={productDetails?.Result?.primary_image?.blob}
      productID={productDetails?.Result?.product_id}
      backendID={productDetails?.Result?.backend_id}
      customID={productDetails?.Result?.custom_id}
    />
  );
};
