import { BadgeProps } from "@new-black/lyra";

export interface IOrderItem {
  IsCompleted: boolean;
  IsInvoiced: boolean;
  IsPaid: boolean;
  IsPlaced: boolean;
  IsShipped: boolean;
}
export enum EOrderStatus {
  Open = "open",
  Completed = "completed",
  Paid = "paid",
  Shipped = "shipped",
}

export function getOrderStatus(order: IOrderItem | undefined): EOrderStatus | undefined {
  if (!order) {
    return undefined;
  }

  const { IsCompleted, IsPaid, IsShipped } = order;

  if (IsCompleted) {
    return EOrderStatus.Completed;
  }

  if (IsShipped) {
    return EOrderStatus.Shipped;
  }

  return IsPaid ? EOrderStatus.Paid : EOrderStatus.Open;
}

export function getBadgeColor(status?: string): BadgeProps["variant"] {
  switch (status) {
    case EOrderStatus.Completed:
      return "green";
    case EOrderStatus.Shipped:
      return "yellow";
    case EOrderStatus.Paid:
    case EOrderStatus.Open:
    default:
      return "blue";
  }
}
