import { useMemo } from "react";

import { generateCustomFieldLyraSearchListField } from "components/suite-composite/custom-field-lyra-search-list-field";
import useCustomFieldsTypeId from "hooks/use-custom-fields-type-id";
import { CustomFieldType } from "types/custom-field";

import { ILoyaltyProgramCustomFieldInput } from "./types";

export const LoyaltyProgramCustomFieldWithoutOUIDInput = ({
  errorMessage,
  name,
  onChange,
  value,
}: ILoyaltyProgramCustomFieldInput) => {
  const { customFieldTypeID: userCustomFieldsTypeID } = useCustomFieldsTypeId(CustomFieldType.User);

  const CustomFieldSearchListField = useMemo(
    () =>
      generateCustomFieldLyraSearchListField({
        PageConfig: { Filter: { TypeID: userCustomFieldsTypeID } },
      }).SingleSearchListField.Controlled,
    [userCustomFieldsTypeID],
  );

  return (
    <>
      <CustomFieldSearchListField
        value={
          value
            ? {
                ID: value?.CustomFieldID,
                Name: value?.DisplayName ?? value?.Name,
                DataType: value?.DataTypeID,
                IsArray: value?.IsArray,
              }
            : undefined
        }
        onChange={(newValue) =>
          onChange(
            newValue
              ? ({
                  CustomFieldID: newValue?.ID,
                  Name: newValue?.Name,
                  DataTypeID: newValue?.DataType,
                  IsArray: newValue?.IsArray,
                } as EVA.Core.CustomFieldResponse)
              : undefined,
          )
        }
        errorMessage={errorMessage}
      />
      {name && value ? <input type="hidden" name={name} value={value?.CustomFieldID} /> : null}
    </>
  );
};
