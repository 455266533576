import { Suspense, useCallback, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  Outlet,
  useLoaderData,
  useLocation,
  useRevalidator,
  useSearchParams,
} from "react-router-dom";

import { InlineIcons, Provider } from "@new-black/lyra";
import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import {
  EvaRecoilRoot,
  helpers,
  IUserExpiredCallbackParameters,
} from "@springtree/eva-sdk-react-recoil";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { defer } from "lodash";

import EN from "assets/i18n/en.json";
import { BlockedRoutingDialog, useNavigate } from "components/routing";
import AppFallbackScreen from "components/shared/app-fallback-screen";
import { LatestSelectedOrganizationUnitProvider } from "components/shared/menu/ou-selector/latest-selected-ou-context";
import { TitleHelmetContextProvider } from "components/suite-composite/title-helmet/provider";
import { useTranslationProviderContext } from "components/suite-composite/translation-provider";
import { useLyraDictionary } from "components/suite-composite/translation-provider/use-lyra-dictionary";
import { toast, ToastOptions } from "components/suite-ui/toast";
import { ActivityLoginDialog } from "features/login/activity-login-dialog";
import useEventListener from "hooks/suite-react-hooks/use-event-listener";
import usePrevious from "hooks/suite-react-hooks/use-previous";
import { UIProviders } from "providers/ui-providers";
import { unauthorizedRequestsStrategy } from "routes/__auth/unauthorized-requests-strategy";
import routeDefinitions from "routes/route-definitions";
import { DEFAULT_LOCALE } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { queryClient } from "util/query-client";
import { setGlobalValidationErrorMap } from "util/validators/error-map";

import { LOGIN_REDIRECT_SEARCH_PARAM_NAME, RootLoaderData } from "./data/root.loader";
import { routesWithOUSelector } from "./root.types";

export const pathsWithoutUserTokenRequirement = [
  routeDefinitions.auth.logout.path,
  routeDefinitions.auth.login.path,
  routeDefinitions.auth.forgotPassword.path,
  routeDefinitions.auth.selectEndpoint.path,
] as string[];

// Set global validation error map for zod validation
intlAccessor.onInstanceChanged(() => {
  setGlobalValidationErrorMap();
});

const { storage } = helpers;

const useRedirectToLoginPage = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const redirectToLoginPage = useCallback(() => {
    if (!pathsWithoutUserTokenRequirement.some((path) => pathname.startsWith(path))) {
      const newSearchParams = new URLSearchParams(searchParams);

      // Set the `from` param with current pathname
      newSearchParams.set(LOGIN_REDIRECT_SEARCH_PARAM_NAME, window.location.pathname);

      const loginURL = `${routeDefinitions.auth.login.path}?${newSearchParams.toString()}`;

      // Redirect to login page (with replace)
      navigate(loginURL, { replace: true });
    }
  }, [navigate, pathname, searchParams]);

  return redirectToLoginPage;
};

const useHandleRequestedOUID = () => {
  const location = useLocation();
  const previousLocation = usePrevious(location);
  const revalidator = useRevalidator();

  const resetRequestedOUIDAndRevalidate = useCallback(() => {
    setServiceSetting("requestedOrganizationUnitID", "");
    storage.removeItem("evaRequestedOrganizationUnitID");

    defer(() => {
      queryClient.removeQueries();
      revalidator.revalidate();
    });
  }, [revalidator]);

  // Reset the requested organization unit ID as the user navigates from a page that
  // has OU selector to a page that does not
  useEffect(() => {
    if (
      location.pathname !== previousLocation?.pathname &&
      routesWithOUSelector.some((route) => previousLocation?.pathname.startsWith(route)) &&
      !routesWithOUSelector.some((route) => location.pathname.startsWith(route)) &&
      !([routeDefinitions.auth.logout.path, routeDefinitions.auth.login.path] as string[]).includes(
        location.pathname,
      )
    ) {
      resetRequestedOUIDAndRevalidate();
    }
  }, [location.pathname, previousLocation?.pathname, resetRequestedOUIDAndRevalidate]);

  // Reset the requested organization unit ID on mount if the initial route does
  // not have an OU selector
  useEffect(() => {
    if (
      !routesWithOUSelector.some((route) => location.pathname.startsWith(route)) &&
      !([routeDefinitions.auth.logout.path, routeDefinitions.auth.login.path] as string[]).includes(
        location.pathname,
      ) &&
      storage.getItem("evaRequestedOrganizationUnitID")
    ) {
      resetRequestedOUIDAndRevalidate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

/**
 * Automatically redirect if the user token is empty.
 */
const useRedirectOnEmptyUserToken = () => {
  const location = useLocation();
  const revalidator = useRevalidator();

  const previousUserToken = usePrevious(storage.getItem("evaUserToken"));

  const redirectToLoginPage = useRedirectToLoginPage();

  useEventListener("storage", () => {
    // If we're not on pages that necessarily require an user token to be set, redirect
    // to login page and set the `from` search param to be the current location pathname
    if (
      !storage.getItem("evaUserToken") &&
      !pathsWithoutUserTokenRequirement.some((path) => location.pathname.startsWith(path)) &&
      unauthorizedRequestsStrategy.canApplyStrategy("redirect")
    ) {
      redirectToLoginPage();
    }
    // Else if we're on login page and the user token is being set, automatically refresh the page.
    else if (
      location.pathname.startsWith(routeDefinitions.auth.login.path) &&
      !previousUserToken &&
      storage.getItem("evaUserToken")
    ) {
      revalidator.revalidate();
    }
  });
};

function RootChild() {
  useHandleRequestedOUID();
  useRedirectOnEmptyUserToken();

  const [latestSelectedOrganizationUnitID, setLatestSelectedOrganizationUnitID] = useState<
    number | undefined
  >();

  return (
    <LatestSelectedOrganizationUnitProvider
      value={{ latestSelectedOrganizationUnitID, setLatestSelectedOrganizationUnitID }}
    >
      <div className="overflow-x-hidden">
        <Outlet />
        <BlockedRoutingDialog />
      </div>
    </LatestSelectedOrganizationUnitProvider>
  );
}

export default function RootRoute() {
  const { setLocale, setMessages } = useTranslationProviderContext();
  const loaderData = useLoaderData() as RootLoaderData;
  const dictionary = useLyraDictionary();
  const redirectToLoginPage = useRedirectToLoginPage();

  const userExpiredCallback = useCallback(
    (params: IUserExpiredCallbackParameters) => {
      if (
        params.currentToken === params.expiredToken &&
        !pathsWithoutUserTokenRequirement.some((path) => location.pathname.startsWith(path)) &&
        unauthorizedRequestsStrategy.canApplyStrategy("redirect")
      ) {
        redirectToLoginPage();
      }
    },
    [redirectToLoginPage],
  );

  useEffect(() => {
    setLocale(loaderData.locale ?? DEFAULT_LOCALE);
    setMessages(loaderData.translationMessages ?? { ...EN });
  }, [loaderData.locale, loaderData.translationMessages, setLocale, setMessages]);

  const onChangeOrderOfColumnsError = useCallback(
    () =>
      toast.error(
        intlAccessor.formatMessage({
          id: "generic.label.reorganize-columns.error",
          defaultMessage: "Please select at least one column.",
        }),
      ),
    [],
  );

  return (
    <QueryClientProvider client={queryClient}>
      <UIProviders>
        <Provider
          locale={loaderData.locale ?? DEFAULT_LOCALE}
          dictionary={dictionary}
          tableDefaults={{
            onChangeOrderOfColumnsError,
          }}
        >
          <Suspense fallback={<AppFallbackScreen />}>
            <EvaRecoilRoot
              key={storage.getItem("evaUserToken") ?? "no-user"}
              endpointUrl={storage.getItem("evaEndpointUrl") ?? ""}
              userToken={storage.getItem("evaUserToken") ?? ""}
              userExpiredCallback={userExpiredCallback}
            >
              <ActivityLoginDialog
                applicationConfiguration={loaderData.applicationConfiguration?.value?.Configuration}
              />

              <TitleHelmetContextProvider moduleName="EVA Suite">
                <RootChild />
              </TitleHelmetContextProvider>
            </EvaRecoilRoot>
          </Suspense>
          <Toaster toastOptions={ToastOptions} />
          <InlineIcons />
        </Provider>
      </UIProviders>
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}
