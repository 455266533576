export enum OrderBlobTypes {
  None = 0,
  Invoice = 1,
  InvoiceThermal = 2,
  ReturnLabel = 3,
  ReturnForm = 4,
  ShipmentLabel = 5,
  Identification = 6,
  PackingSlip = 7,
  PickSlip = 8,
  ThermalPrint = 9,
  PinReceipt = 10,
  Export = 11,
  ElectronicReceipt = 12,
  TaxFreeForm = 13,
}
