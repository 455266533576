import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const LineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Single line</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
    </defs>
    <g id="Icon-/-Single-line" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g filter="url(#filter-1)" id="notes_black_24dp">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <polygon id="Shape" fill="#000000" fillRule="nonzero" points="21 8 3 8 3 10.01 21 10" />
        </g>
      </g>
    </g>
  </SvgIcon>
);
