import { useEffect, useState } from "react";

// Check if number value is number
const valueIsNumber = (value: any) => typeof value === "number";

const enumToArray = <EnumType extends object>(enumType: EnumType) =>
  Object.entries(enumType)
    .filter(([, value]) => valueIsNumber(value))
    .map(([key, value]) => ({ key, value }));

/**
 * Hook to convert an enum to an array.
 * @param enumType
 */
const useEnumToArray = <EnumType extends object>(enumType: EnumType) => {
  const [enumArray, setEnumArray] = useState<
    { key: string; value: EnumType[keyof EnumType] }[] | undefined
  >();

  useEffect(() => {
    const newEnumArray = enumToArray(enumType);
    if (newEnumArray.length) {
      setEnumArray(newEnumArray);
    }
  }, [enumType]);

  return enumArray;
};

export default useEnumToArray;
