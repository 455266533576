import { Core } from "@springtree/eva-services-core";

import { searchUsersQuery } from "models/users";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import useUserById from "../hooks/use-user-by-id";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";

export const generateUserSearchListField = ({
  defaultRequest = { IncludeCustomers: true, IncludeEmployees: true },
}: {
  defaultRequest?: Partial<EVA.Core.SearchUsers>;
}) =>
  SearchListFieldGenerator<
    Core.SearchUsers,
    {
      ID?: number;
      FullName?: string;
      EmailAddress?: string;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.FullName ?? "",
    selectRenderElements: (item) => ({
      label: item.FullName,
      description: item.EmailAddress,
    }),
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((user) => ({
        ID: user.ID,
        FullName: user.FullName,
        EmailAddress: user.EmailAddress,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user",
      defaultMessage: "User",
    }),

    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: searchUsersQuery,
        initialRequest: {
          ...defaultRequest,
          PageConfig: { Limit: 10, ...defaultRequest?.PageConfig },
        },
        getQueryRequest: (req) => req?.SearchQuery,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          SearchQuery: newValue,
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    useItemByID: (id) => {
      const { isLoading, user } = useUserById(id);
      return {
        data: user,
        isLoading,
      };
    },
  });
