import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Table, TableColumnDef, TableProps } from "@new-black/lyra";

export const CountriesTable = ({
  autoResetPageIndex,
  data,
  limit,
  onRowPress,
  setLimit,
  setStart,
  start,
  total,
}: Pick<
  TableProps<EVA.Core.CountryDto>,
  | "data"
  | "start"
  | "setStart"
  | "limit"
  | "setLimit"
  | "total"
  | "autoResetPageIndex"
  | "onRowPress"
>) => {
  const intl = useIntl();

  const columns = useMemo<TableColumnDef<EVA.Core.CountryDto>[]>(
    () => [
      {
        accessorKey: "Name",
        id: "Name",
        header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
        cell: ({ row }) =>
          intl.formatDisplayName(row.original.ID, { type: "region" }) ?? row.original.Name,
      },
      {
        accessorKey: "ID",
        id: "ID",
        header: intl.formatMessage({
          id: "generic.label.country-id",
          defaultMessage: "Country ID",
        }),
      },
    ],
    [intl],
  );

  return (
    <Table
      aria-label={intl.formatMessage({
        id: "generic.label.countries",
        defaultMessage: "Countries",
      })}
      data={data}
      start={start}
      setStart={setStart}
      limit={limit}
      setLimit={setLimit}
      total={total}
      columns={columns}
      autoResetPageIndex={autoResetPageIndex}
      onRowPress={onRowPress}
    />
  );
};
