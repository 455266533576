import { useField, useFormikContext } from "formik";

import { ControlledEnumSelect, ControlledLyraEnumSelect } from "./controlled-enum-select";
import { IFormikEnumSelectProps, IFormikLyraEnumSelectProps } from "./enum-select.types";

/**
 * The props for the Formik version of the enum select.
 * @param name The name of the select field in Formik.
 * @param rest The rest of the props to pass to the select (`Omit<IControlledEnumSelectProps, "value" | "onChange">`)
 * 
 * ### Example:
```tsx
import { EnumSelect } from "components/shared/enum-select";

// replicating EVA.Core.Management.ListSettings.TypedFilters
enum TypedFilters {
  ShowAll = 0,
  ShowTyped = 1,
  ShowUntyped = 2,
}

const labelLookup = {
  [TypedFilters.ShowAll]: "Show all",
  [TypedFilters.ShowTyped]: "Show typed",
  [TypedFilters.ShowUntyped]: "Show untyped",
};

export const TypedFilter = () => (
  <EnumSelect.Formik
    enum={TypedFilters}
    label="Setting type"
    name="TypedFiltersSelect"
    getOptionLabel={(value) => labelLookup[value]}
  />
);
```
*/
export const FormikEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  name,
  ...rest
}: IFormikEnumSelectProps<EnumType, EnumValue>) => {
  const { setFieldValue } = useFormikContext();
  const [{ value }, meta] = useField<EnumValue | undefined>(name);
  return (
    <ControlledEnumSelect
      {...rest}
      name={name}
      value={value}
      onChange={(newValue) => setFieldValue(name, newValue)}
      error={meta.touched && !!meta.error}
      helpertext={meta.touched ? meta.error : undefined}
    />
  );
};

export const FormikLyraEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  name,
  ...rest
}: IFormikLyraEnumSelectProps<EnumType, EnumValue>) => {
  const { setFieldValue } = useFormikContext();
  const [{ value }, meta] = useField<EnumValue | undefined>(name);
  return (
    <ControlledLyraEnumSelect
      {...rest}
      name={name}
      value={value}
      onChange={(newValue) => setFieldValue(name, newValue)}
      errorMessage={meta.touched && !!meta.error ? meta.error : undefined}
    />
  );
};
