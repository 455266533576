import { useEffect } from "react";

import { useRecoilValue, useResetRecoilState } from "recoil";

import { OrganizationUnitSelectorFilterAtom } from "../organization-unit-selector.state";
import { IOrganizationUnitMenuItem } from "../organization-unit-selector.types";

import useOrganizationUnitStructure from "./use-organization-unit-structure";

const useOrganizationUnitFilter = (
  setMenuItems: React.Dispatch<React.SetStateAction<IOrganizationUnitMenuItem[] | undefined>>,
  setMenuItemsFromSelectedOU: () => void,
) => {
  const debouncedInputValue = useRecoilValue(OrganizationUnitSelectorFilterAtom);
  const resetFilter = useResetRecoilState(OrganizationUnitSelectorFilterAtom);
  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();

  // Set displayed menu items based on input
  useEffect(() => {
    if (debouncedInputValue) {
      // check if searching by ID
      const organizationUnitID = parseInt(debouncedInputValue, 10);

      // The BackendID can be a string, which actually contains a number (e.g. "8005"),
      // in which case we can no longer rely on the separation of
      //    number => search by ID
      //    string => search by name / backendID
      // so we always search by ID (if we can parse the input as an integer), name and backendID (string search)
      setMenuItems(
        flattenedOrganizationUnits
          ?.filter(
            (ou) =>
              ou.Name.toLowerCase().includes(debouncedInputValue.toLowerCase()) ||
              (ou.BackendID &&
                ou.BackendID.toLowerCase().includes(debouncedInputValue.toLowerCase())) ||
              (organizationUnitID && organizationUnitID === ou.ID),
          )
          .map((x) => ({
            ID: x.ID,
            Name: x.Name,
            ParentID: x.ParentID,
            BackendID: x.BackendID,
            HasChildren: flattenedOrganizationUnits.some((ou) => ou.ParentID === x.ID),
          })),
      );
    } else {
      // no input value means we display items based on selected OU
      setMenuItemsFromSelectedOU();
    }
  }, [setMenuItems, debouncedInputValue, flattenedOrganizationUnits, setMenuItemsFromSelectedOU]);

  return { resetFilter, debouncedInputValue };
};

export default useOrganizationUnitFilter;
