import { editor } from "monaco-editor";

export function formatExtendLines(model: editor.ITextModel) {
  const extendLines = model.findMatches("^extend.*$", true, true, false, null, true);

  return (
    extendLines
      // if the next line is empty, then we should remove the extend line from the array that needs to be formatted
      .filter((extendedLine) => {
        return !!model.getLineLength(extendedLine.range.startLineNumber + 1);
      })
      .map((extendLine) => {
        return {
          range: extendLine.range,
          text: `${extendLine.matches?.[0]}\n`,
        };
      })
  );
}
