import { forwardRef, memo } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import type IDraggable from "../../drag-and-drop/drag-and-drop.types";
import Text from "../../text";

export interface IColumnButtonProps extends IDraggable {
  label?: string;
  toggleVisible?: () => void;
  dragColumnTooltip?: string;
  removeColumnTooltip?: string;
}

const ColumnButton = memo(
  forwardRef<HTMLDivElement, IColumnButtonProps>(
    ({ isDragging, label, listeners, removeColumnTooltip, toggleVisible }, ref) => {
      const intl = useIntl();
      return (
        <div
          ref={ref}
          className={classNames(
            "bg-white",
            "hover:cursor-grab",
            "h-[32px]",
            "flex flex-nowrap items-center justify-between",
            "rounded border border-solid [border-color:var(--legacy-eva-color-light-3)]",
            "shadow-[0_1px_1px_0_rgba(0,0,0,.14),0_2px_1px_-1px_rgba(0,0,0,.12),0_1px_3px_0_rgba(0,0,0,.2)]",
          )}
        >
          <div className="py-[9px] pl-2 pr-[15px]" {...listeners}>
            <Text className="h-[14px] w-max text-xs font-medium leading-[14px] tracking-[.4px] text-[color:var(--legacy-eva-color-transparent-500)]">
              {label}
            </Text>
          </div>
          <div className="flex items-center py-1" {...listeners}>
            <SvgIcon
              name="drag-handle"
              className={isDragging ? "text-[var(--legacy-eva-color-primary)]" : undefined}
            />
          </div>
          <div className="py-1">
            <Button
              variant="icon"
              className="flex items-center justify-center rounded-l-[0] border-0 active:!bg-transparent hover:bg-transparent"
              onPress={toggleVisible}
              tooltip={
                removeColumnTooltip ??
                intl.formatMessage({
                  id: "generic.label.remove-column",
                  defaultMessage: "Remove column",
                })
              }
            >
              <SvgIcon name="clear" />
            </Button>
          </div>
        </div>
      );
    },
  ),
);

ColumnButton.displayName = "ColumnButton";

export default ColumnButton;
