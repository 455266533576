import { QueryClient } from "@tanstack/react-query";

import { getCustomFieldTypesLoaderQuery } from "models/custom-fields";
import { CustomFieldType } from "types/custom-field";

export const getCustomFieldTypeID = (
  customFieldType?: CustomFieldType,
  customFieldTypesResponse?: EVA.Core.Management.GetCustomFieldTypesResponse,
) => customFieldTypesResponse?.CustomFieldTypes?.find((type) => type.Name === customFieldType)?.ID;

export const getCustomFieldTypeIDLoaderHelper = async <ThrowOn404 extends boolean>(
  queryClient: QueryClient,
  customFieldType?: CustomFieldType,
  throwOn404?: ThrowOn404,
) => {
  const customFieldTypeID = getCustomFieldTypeID(
    customFieldType,
    (await getCustomFieldTypesLoaderQuery(queryClient, {})()).value,
  );

  // Throw error if the custom field type ID could not be retrieved
  if (!customFieldTypeID) {
    if (throwOn404) {
      throw new Response(`Could not get custom fields for type: ${customFieldType}`, {
        status: 404,
      });
    } else {
      console.error(`Could not get custom fields for type: ${customFieldType}`);
    }
  }

  return customFieldTypeID as ThrowOn404 extends true ? number : number | undefined;
};
