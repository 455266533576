import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

const organizationUnitSummaryServiceState = builders.buildServiceFamilyState({
  allowEmptyRequest: false,
  service: Core.GetOrganizationUnitSummary,
  key: "Composite:Core.GetOrganizationUnitSummary",
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.OrganizationUnitID === parameter) {
      return req;
    }
    return { ...req, OrganizationUnitID: parameter };
  },
});

const organizationUnitSummaryResponseSelector = selectorFamily({
  key: "Composite:Core.GetOrganizationUnitSummary:Result",
  get:
    (param) =>
    ({ get }) =>
      get(organizationUnitSummaryServiceState.response(param)),
});

export const organizationUnitSummaryService = {
  serviceState: organizationUnitSummaryServiceState,
  response: organizationUnitSummaryResponseSelector,
};
