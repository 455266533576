import { useMemo, useReducer } from "react";
import { useIntl } from "react-intl";

import { CardHeader } from "@new-black/lyra";

import { parseEVAFilterModel } from "../helpers";
import { initialProductFilterState, productFilterReducer } from "../product-filter-modal.reducer";
import { EVAFilterModel, ProductFilterModalMessages } from "../product-filter-modal.types";

import { ProductFilterModalFooter } from "./product-filter-modal-footer";
import { ProductFilterModalForm } from "./product-filter-modal-form";

interface IProductFilterModalContentProps {
  close?: () => void;
  initialValues?: EVAFilterModel;
  messages?: ProductFilterModalMessages;
  onChange: (newFilter: EVAFilterModel) => void;
  isLoading?: boolean;
  closeOnSave?: boolean;
  readOnlyPropertyOnEdit?: boolean;
}

export function ProductFilterModalContent({
  close,
  closeOnSave,
  initialValues,
  isLoading,
  messages,
  onChange,
  readOnlyPropertyOnEdit,
}: IProductFilterModalContentProps) {
  const intl = useIntl();

  const initialState = useMemo(
    () =>
      initialValues && Object.values(initialValues).find((value) => !!value)
        ? parseEVAFilterModel(initialValues)
        : initialProductFilterState,
    [initialValues],
  );
  const [state, dispatch] = useReducer(productFilterReducer, initialState);

  const title = useMemo(() => {
    if (messages?.title) {
      return messages.title;
    }
    if (initialValues && Object.values(initialValues).find((value) => !!value)) {
      return intl.formatMessage({
        id: "generic.label.edit-product-filter",
        defaultMessage: "Edit product filter",
      });
    }
    return intl.formatMessage({
      id: "generic.label.add-product-filter",
      defaultMessage: "Add product filter",
    });
  }, [initialValues, intl, messages?.title]);

  return (
    <>
      <CardHeader title={title} />

      <ProductFilterModalForm
        dispatch={dispatch}
        state={state}
        isPropertyReadOnly={
          initialValues &&
          Object.values(initialValues).find((value) => !!value) &&
          readOnlyPropertyOnEdit
        }
      />

      <ProductFilterModalFooter
        state={state}
        close={close}
        messages={messages}
        onChange={onChange}
        dispatch={dispatch}
        isLoading={isLoading}
        closeOnSave={closeOnSave}
      />
    </>
  );
}
