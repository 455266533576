import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getStockLabelsLoaderQuery,
  serviceQuery: getStockLabelsQuery,
  serviceQueryKeys: getStockLabelsQueryKeys,
  useServiceQueryHook: useGetStockLabelsQuery,
} = createServiceQuery(Core.GetStockLabels);

export const {
  serviceLoaderQuery: getStockLabelSettingsForLabelLoaderQuery,
  serviceQuery: getStockLabelSettingsForLabelService,
  serviceQueryKeys: getStockLabelSettingsForLabelQueryKeys,
  useServiceQueryHook: useGetStockLabelSettingsForLabelQuery,
} = createServiceQuery(Core.GetStockLabelSettingsForLabel);

export const {
  serviceLoaderQuery: getStockLabelsForFeatureLoaderQuery,
  serviceQuery: getStockLabelsForFeatureQuery,
  serviceQueryKeys: getStockLabelsForFeatureQueryKeys,
  useServiceQueryHook: useGetStockLabelsForFeatureQuery,
} = createServiceQuery(Core.GetStockLabelsForFeature, true);
