import { useMemo } from "react";

import { EvaFilterModel } from "../types";

export interface IEVAFilterArrayItem {
  keyName: string;
  Values?: any[] | undefined;
  From?: string | undefined;
  To?: string | undefined;
  Negation?: boolean | undefined;
  ExactMatch?: boolean | undefined;
  IncludeMissing?: boolean | undefined;
}

export default function useConvertEVAFilterToArray(productFilter?: EvaFilterModel) {
  const converted = useMemo<IEVAFilterArrayItem[]>(() => {
    if (productFilter) {
      return Object.entries(productFilter)?.map(([key, value]) => ({
        ...value,
        keyName: key,
      }));
    }

    return [];
  }, [productFilter]);

  return converted;
}
