import { useMemo } from "react";

import { range } from "lodash";
import moment, { Moment } from "moment";

export interface IUseCalendarDisplayedDays {
  month: Moment;
  numberOfDisplayedDays: number;
}

/**
 * Hook that generates the list of days that are displayed on the calendar based on the month and the desired number of displayed days.
 * If the month has less days than `numberOfDisplayedDays` then the days of the month are padded with days from the end of the previous month
 * and the start of the next month (distributed evenly) until the required number of days is reached.
 */
const useCalendarDisplayedDays = ({ month, numberOfDisplayedDays }: IUseCalendarDisplayedDays) => {
  const displayedDays = useMemo(() => {
    const startOfDisplayedMonth = moment(month).startOf("month");

    const paddingDays = Math.max(numberOfDisplayedDays - month.daysInMonth(), 0);

    const numberOfLeftPaddingDays = Math.floor(paddingDays / 2);
    const numberOfRightPaddingDays = Math.floor(paddingDays / 2) + (paddingDays % 2);

    const endOfPrevMonth = moment(startOfDisplayedMonth)
      .subtract(1, "months")
      .endOf("month")
      .startOf("day");

    const leftPaddingDays = range(numberOfLeftPaddingDays).map((day) =>
      moment(endOfPrevMonth).subtract(day, "days").startOf("day"),
    );

    const startOfNextMonth = moment(startOfDisplayedMonth).add(1, "months").startOf("month");
    const rightPaddingDays = range(numberOfRightPaddingDays).map((day) =>
      moment(startOfNextMonth).add(day, "days").startOf("day"),
    );

    const displayedMonth = range(month.daysInMonth()).map((monthDay) =>
      moment(startOfDisplayedMonth).add(monthDay, "days"),
    );

    return [...leftPaddingDays, ...displayedMonth, ...rightPaddingDays];
  }, [month, numberOfDisplayedDays]);

  return displayedDays;
};

export default useCalendarDisplayedDays;
