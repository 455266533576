import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import {
  listCustomFieldDataTypesOperatorsResultSelector,
  listCustomFieldDataTypesOperatorsServiceState,
} from ".";

const useListCustomFieldDataTypeOperators = () => {
  const data = hooks.useGetState(listCustomFieldDataTypesOperatorsResultSelector);

  const isLoading = hooks.useIsLoading({
    state: listCustomFieldDataTypesOperatorsServiceState.response,
  });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  return { data, isLoading, isLoadingWithoutResponse };
};

export default useListCustomFieldDataTypeOperators;
