import { ComponentPropsWithoutRef, memo, PropsWithChildren } from "react";
import { useIntl } from "react-intl";

import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";
import classNames from "classnames";
import { Moment } from "moment";

import Grid from "../grid";
import Text from "../text";

import { CalendarHeaderDay } from "./calendar-header-day";
import { ICalendarStrings } from "./types";

interface FirstRowProps extends ComponentPropsWithoutRef<"div"> {
  endColumn: number;
}

export interface ICalendarHeader {
  onNextButtonClick: () => void;
  onPreviousButtonClick: () => void;
  title: string;
  displayedDays: Moment[];
  month: Moment;
  calendarStrings?: ICalendarStrings;
}

const CalendarHeader = memo(
  ({
    calendarStrings,
    displayedDays,
    month,
    onNextButtonClick,
    onPreviousButtonClick,
    title,
  }: ICalendarHeader) => {
    const intl = useIntl();
    return (
      <>
        <FirstRow endColumn={displayedDays.length + 1}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Title>{title}</Title>
            </Grid>
            <Grid item>
              <ButtonGroup>
                <Button
                  variant="icon"
                  onPress={onPreviousButtonClick}
                  tooltip={
                    calendarStrings?.previousMonth ??
                    intl.formatMessage({
                      id: "generic.label.previous-month",
                      defaultMessage: "Previous month",
                    })
                  }
                >
                  <SvgIcon name="chevron-left" />
                </Button>
                <Button
                  variant="icon"
                  onPress={onNextButtonClick}
                  tooltip={
                    calendarStrings?.nextMonth ??
                    intl.formatMessage({
                      id: "generic.label.next-month",
                      defaultMessage: "Next month",
                    })
                  }
                >
                  <SvgIcon name="chevron-right" />
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </FirstRow>
        {displayedDays.map((day, index) => (
          <CalendarHeaderDay
            key={day.toDate().getTime()}
            day={day}
            month={month}
            column={index + 1}
          />
        ))}
      </>
    );
  },
);

CalendarHeader.displayName = "CalendarHeader";

const FirstRow = ({ children, className, endColumn }: FirstRowProps) => (
  <div
    style={{ gridColumnEnd: endColumn }}
    className={classNames(
      "col-start-1 row-span-1 row-start-1 mt-[-12px] overflow-visible bg-[color:#fff] pt-3",
      className,
    )}
  >
    {children}
  </div>
);

const Title = ({ children, ...props }: PropsWithChildren) => (
  <Text {...props} className="text-[15px] font-medium">
    {children}
  </Text>
);

export default CalendarHeader;
