import { Skeleton } from "@material-ui/lab";

export interface ILoadingStateBox {
  limit: number;
  headerAvailable?: boolean;
  footerAvailable?: boolean;
}

const LoadingStateBox: React.FC<ILoadingStateBox> = ({
  footerAvailable,
  headerAvailable,
  limit,
}) => {
  const rows: number[] = Array.from(Array(limit).keys());

  return (
    <div className="py-5">
      {headerAvailable ? (
        <div className="flex justify-between px-5 py-1.5">
          <Skeleton width="40%" height="36px" variant="rect" />
          <Skeleton width="36px" height="36px" variant="circle" />
        </div>
      ) : null}
      {rows.map((row) => (
        <div className="px-5 py-1.5" key={row}>
          <Skeleton width="100%" height="36px" variant="rect" />
        </div>
      ))}
      {footerAvailable ? (
        <div className="flex justify-end px-5 py-1.5">
          <Skeleton width="50%" height="36px" variant="rect" />
        </div>
      ) : null}
    </div>
  );
};

export default LoadingStateBox;
