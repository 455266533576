import { FormControlLabel, Radio } from "@material-ui/core";

export interface IRadioButtonLabel {
  label: string;
  value: string;
  activeValue: string;
  disabled?: boolean;
}

const RadioButtonLabel = ({ activeValue, disabled, label, value }: IRadioButtonLabel) => (
  <FormControlLabel
    className="text-legacy-base -ml-[6px] leading-6 tracking-[0.2px] text-[color:var(--legacy-eva-color-dark-3)]"
    value={value}
    control={
      <Radio
        classes={{ disabled: "!text-[color:var(--eva-color-disabled)]", root: "p-[4px] mr-[24px]" }}
        color={value === activeValue ? "primary" : "default"}
        size="small"
      />
    }
    label={label}
    disabled={disabled}
  />
);

export default RadioButtonLabel;
