import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { intlAccessor } from "util/intl-accessor";
import { mutate } from "util/mutate";

export const mutateCreateCustomField = (
  successMessageOnCreate?: string,
  throwOnError?: boolean,
) => {
  const createCustomFieldHandler = mutate({
    service: CoreManagement.CreateCustomField,
    messages: {
      successMessage:
        successMessageOnCreate ??
        intlAccessor.formatMessage({
          id: "custom-field.actions.create.success",
          defaultMessage: "Custom field successfully created.",
        }),
    },
  });

  const decoratedCreateCustomFieldHandler = (
    request: EVA.Core.Management.CreateCustomField | undefined,
    options?: IEvaServiceCallOptions | undefined,
  ) => createCustomFieldHandler(request, options, throwOnError);

  return decoratedCreateCustomFieldHandler;
};
