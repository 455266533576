import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

const { createGetterSetterSelectorFamily } = helpers.createSelectors;

export const defaultVisibilityGroupsRequest: EVA.Core.Management.ListVisibilityGroups = {
  PageConfig: {
    Filter: {
      Name: undefined,
      IDs: undefined,
      OrganizationUnitID: undefined,
    },
    Limit: 5,
    Start: 0,
    SortProperty: "ID",
    SortDirection: 1,
  },
};

/**
 * Service builder to get the list of visibility groups
 */
export const visibilityGroupsListState = builders.buildServiceFamilyState({
  key: "OrganizationUnitDetails:VisibilityGroupsList",
  service: CoreManagement.ListVisibilityGroups,
  defaultRequest: defaultVisibilityGroupsRequest,
  allowEmptyRequest: false,
  requireEmployee: true,
  requireLoggedIn: true,
});

export const visibilityGroupsListSelector = selectorFamily({
  key: "OrganizationUnitDetails:VisibilityGroupsList:Result",
  get:
    (param) =>
    ({ get }) =>
      get(visibilityGroupsListState.response(param))?.Result?.Page,
});

export const visibilityGroupsListRequestSelector = createGetterSetterSelectorFamily<
  EVA.Core.Management.ListVisibilityGroups,
  EVA.Core.Management.ListVisibilityGroups | undefined
>({
  key: "OrganizationUnitDetails:VisibilityGroupsList:Request",
  state: visibilityGroupsListState.request,
  defaultValue: defaultVisibilityGroupsRequest || undefined,
  getData: (request) => request,
  setData: (_request, newValue) => newValue,
});

export const visibilityGroupsListRequestPageConfigSelector = createGetterSetterSelectorFamily({
  key: "OrganizationUnitDetails:VisibilityGroupsList:Request:PageConfig",
  state: visibilityGroupsListRequestSelector,
  defaultValue: defaultVisibilityGroupsRequest?.PageConfig,
  getData: (request) => request?.PageConfig,
  setData: (request, newValue) => ({ ...request, PageConfig: newValue }),
});

export const visibilityGroupsListRequestFiltersSelector = createGetterSetterSelectorFamily({
  key: "VisibilityGroupsList:Request:PageConfig:Filters",
  state: visibilityGroupsListRequestPageConfigSelector,
  defaultValue: defaultVisibilityGroupsRequest?.PageConfig?.Filter,
  getData: (pageConfig) => pageConfig?.Filter,
  setData: (pageConfig, newValue) => ({ ...pageConfig, Start: 0, Filter: newValue }),
});

export const visibilityGroupsListRequestNameSelector = createGetterSetterSelectorFamily({
  key: "VisibilityGroupsList:Request:PageConfig:Filters:Name",
  state: visibilityGroupsListRequestFiltersSelector,
  defaultValue: defaultVisibilityGroupsRequest?.PageConfig?.Filter?.Name,
  getData: (filters) => filters?.Name,
  setData: (filters, newValue) => ({ ...filters, Name: newValue }),
});

export const visibilityGroupDetailsState = builders.buildServiceFamilyState({
  key: "visibilityGroupDetails:Autocomplete",
  service: CoreManagement.GetVisibilityGroupByID,
  allowEmptyRequest: false,
  requireLoggedIn: true,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.ID) {
      return req;
    }

    return {
      ...req,
      ID: parameter as number,
    };
  },
});
