import type { MousePosition, TableColumnAlign } from "./table-types";

export const initialContextMenuState: MousePosition = {
  mouseX: null,
  mouseY: null,
};

export const textAlignToFlex = (textAlign: TableColumnAlign = "left") => {
  switch (textAlign) {
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return textAlign;
  }
};
