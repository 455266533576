import { DetailedHTMLProps } from "react";

import { SvgIcon } from "@new-black/lyra";

import Grid, { GridProps } from "components/suite-ui/grid";
import Text, { TextProps } from "components/suite-ui/text";

export const FullHeightGrid = (props: GridProps) => <Grid className="h-full" {...props} />;

export const Heading = (props: TextProps) => <Text className="text-legacy-lg" {...props} />;

const GreenBox = (
  props: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => (
  <div
    className="text-legacy-sm flex items-center justify-center rounded border border-solid border-[color:var(--legacy-eva-color-green)] px-2.5 py-[10px] text-[color:var(--legacy-eva-color-green)]"
    {...props}
  />
);

export const ProductIncludedInSetIndicator = () => (
  <GreenBox>
    <SvgIcon name="alert-success" />
  </GreenBox>
);
