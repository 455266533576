import MuiTextField from "@material-ui/core/TextField";
import { cva } from "class-variance-authority";

import { InputProps } from ".";

const textFieldStyles = cva(
  [
    "[&_.MuiInputBase-multiline]:px-4",
    "[&_MuiInputAdornment-root]:[color:var(--legacy-eva-color-transparent-500)] [&_MuiInputAdornment-positionStart]:-ml-2",
    "[&_.Mui-focused_.MuiInputAdornment-positionStart]:[color:var(--legacy-eva-color-primary)]",
  ],
  {
    variants: {
      type: {
        number: [
          "[&_input]:[-moz-appearance:textfield]",
          "[&_input::-webkit-outer-spin-button]:m-0",
          "[&_input::-webkit-inner-spin-button]:m-0",
          "[&_input::-webkit-outer-spin-button]:[-webkit-appearance:none]",
          "[&_input::-webkit-inner-spin-button]:[-webkit-appearance:none]",
        ],
      },
      customStartAdornmentWidth: {
        unset: "",
        true: "[&_.MuiInputLabel-shrink]:[transform:translate(10px,7px)_scale(0.75)]",
        false: "[&_.MuiInputLabel-shrink]:[transform:translate(4px,7px)_scale(0.75)]",
      },
      small: {
        true: [
          "[&_.MuiInput-root]:min-h-[32px] [&_.MuiInput-root]:text-legacy-sm",
          "[&_label+.MuiInput-formControl]:mt-0",
          "[&_.MuiInputLabel-formControl]:text-legacy-sm [&_.MuiInputLabel-formControl]:[transform:unset]",
          "[&_.MuiInputLabel-marginDense]:[transform:translate(0px,4px)]",
          "[&_.MuiInputLabel-shrink]:[transform:translate(2px,-8px)_scale(0.8)]",
          "[&_input]:pb-1",
          "[&_.MuiFormControl-marginDense]:my-0",
        ],
      },
      warning: {
        true: [
          "[&_.MuiFormLabel-root]:[color:var(--legacy-eva-color-red-orange)]",
          "[&_.MuiFormHelperText-root]:[color:var(--legacy-eva-color-red-orange)]",
          "[&_.MuiInput-underline:before]:border-b-2 [&_.MuiInput-underline:before]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
          "[&_.MuiInput-underline:after]:border-b-2 [&_.MuiInput-underline:after]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
          "[&_.MuiInput-underline]:before:after:[transform:scaleX(1)]",
          "hover:[&_.MuiInput-underline:before]:border-b-2 hover:[&_.MuiInput-underline:before]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
        ],
      },
      passive: {
        true: "[&_label.MuiInputLabel-root.Mui-focused]:[color:#666666]",
      },
    },
  },
);

interface ITextFieldProps extends InputProps {
  startAdornmentWidth: number;
}

export const TextField = ({
  className,
  passive,
  small,
  startAdornmentWidth,
  type,
  warning,
  ...props
}: ITextFieldProps) => (
  <MuiTextField
    {...props}
    type={type}
    InputLabelProps={
      (startAdornmentWidth ?? 0) > 0
        ? { style: { paddingLeft: `${startAdornmentWidth + 16}px` } }
        : undefined
    }
    className={textFieldStyles({
      small,
      warning,
      passive,
      type: type === "number" ? "number" : undefined,
      customStartAdornmentWidth: small ? "unset" : (startAdornmentWidth ?? 0) > 0,
      className,
    })}
  />
);
