import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listReturnReasonsLoaderQuery,
  serviceQuery: listReturnReasonsQuery,
  serviceQueryKeys: listReturnReasonsQueryKeys,
  useServiceQueryHook: useListReturnReasonsQuery,
  useServiceQueryWithRequest: useListReturnReasonsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListReturnReasons, true);

export const {
  serviceLoaderQuery: listRefundCorrectionReasonsLoaderQuery,
  serviceQuery: listRefundCorrectionReasonsQuery,
  serviceQueryKeys: listRefundCorrectionReasonsQueryKeys,
  useServiceQueryHook: useListRefundCorrectionReasonsQuery,
  useServiceQueryWithRequest: useListRefundCorrectionReasonsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListRefundCorrectionReasons, true);

export const {
  serviceLoaderQuery: getRefundCorrectionReasonLoaderQuery,
  serviceQuery: getRefundCorrectionReasonQuery,
  serviceQueryKeys: getRefundCorrectionReasonQueryKeys,
  useServiceQueryHook: useGetRefundCorrectionReasonQuery,
  useServiceQueryWithRequest: useGetRefundCorrectionReasonQueryWithRequest,
} = createServiceQuery(CoreManagement.GetRefundCorrectionReason, true);

export const {
  serviceLoaderQuery: listUnitPriceCorrectionReasonsLoaderQuery,
  serviceQueryKeys: listUnitPriceCorrectionReasonsQueryKeys,
  useServiceQueryHook: useListUnitPriceCorrectionReasonsQuery,
  useServiceQueryWithRequest: useListUnitPriceCorrectionReasonsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListUnitPriceCorrectionReasons, true);

export const {
  serviceLoaderQuery: getOpenCashDrawerReasonsLoaderQuery,
  serviceQueryKeys: getOpenCashDrawerReasonsQueryKeys,
  useServiceQueryHook: useGetOpenCashDrawerReasonsQuery,
  useServiceQueryWithRequest: useGetOpenCashDrawerReasonsQueryWithRequest,
} = createServiceQuery(Core.GetOpenCashDrawerReasons, true);

export const {
  serviceLoaderQuery: listManualTaxExemptionReasonsLoaderQuery,
  serviceQueryKeys: listManualTaxExemptionReasonsQueryKeys,
  useServiceQueryHook: useListManualTaxExemptionReasonsQuery,
} = createServiceQuery(Core.ListManualTaxExemptionReasons, true);
