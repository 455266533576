/* eslint-disable max-len */
export const PIMInnerIcon = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#30B0C7" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.9 12.1955C27.1995 11.4452 28.8005 11.4452 30.1 12.1955L40.6368 18.2789C41.9363 19.0292 42.7368 20.4157 42.7368 21.9162V34.0831C42.7368 35.5836 41.9363 36.9701 40.6368 37.7204L30.1 43.8038C28.8005 44.5541 27.1995 44.5541 25.9 43.8038L15.3632 37.7204C14.0637 36.9701 13.2632 35.5836 13.2632 34.0831V21.9162C13.2632 20.4157 14.0637 19.0292 15.3632 18.2789L25.9 12.1955ZM28.7 14.6204C28.2668 14.3703 27.7331 14.3703 27.3 14.6204L18.2226 19.8612L27.2846 25.246C27.7254 25.508 28.2742 25.508 28.715 25.246L37.777 19.861L28.7 14.6204ZM16.0632 21.9162C16.0632 21.8895 16.0639 21.8629 16.0655 21.8364L25.8543 27.6531C26.0935 27.7953 26.3434 27.9118 26.6001 28.0025V40.9749L16.7632 35.2955C16.33 35.0454 16.0632 34.5832 16.0632 34.0831V21.9162ZM29.4001 40.9747L39.2368 35.2955C39.6699 35.0454 39.9368 34.5832 39.9368 34.0831V21.9162C39.9368 21.8894 39.936 21.8626 39.9345 21.836L30.1454 27.6531C29.9062 27.7952 29.6566 27.9116 29.4001 28.0023V40.9747Z"
      fill="white"
    />
  </>
);
