import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const getOrganizationUnitServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  key: "Core.GetOrganizationUnit",
  service: Core.GetOrganizationUnitDetailed,
});

export const getOrganizationUnit = selectorFamily({
  key: "Core.GetOrganizationUnit:selector",
  get:
    (parameter) =>
    ({ get }) =>
      get(getOrganizationUnitServiceState.response(parameter)),
});
