import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { z } from "zod";

import useApplicationConfiguration from "hooks/use-application-configuration";
import { listCulturesQueryWithOU } from "models/cultures";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateCultureSearchListField = (ouId?: number) =>
  SearchListFieldGenerator<
    CoreManagement.ListCultures,
    EVA.Core.Management.ListCulturesResponse.CultureDto,
    string
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        ouId,
        query: listCulturesQueryWithOU,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {},
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.CountryID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              CountryID: newValue === "" ? undefined : newValue,
            },
          },
        }),
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton:
            (resp?.Result.Total ?? 0) > (resp?.Result.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.ID,
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.culture",
      defaultMessage: "Culture",
    }),
  });

const APP_CONFIG_CULTURES_PROPERTY = "Cultures";
const APP_CONFIG_OU_CULTURE_PROPERTY = "OrganizationUnitCulture";

const ouCultureSchema = z.object({
  LanguageID: z.string().optional(),
  CountryID: z.string(),
  Culture: z.string(),
});
export type OUCulture = z.infer<typeof ouCultureSchema>;

const culturesSchema = z.array(ouCultureSchema);

export function useOUCulturesSearchListField(
  ouId?: number,
  onNewDefaultCulture?: (culture: OUCulture | undefined) => void,
) {
  const { data: appConfig } = useApplicationConfiguration(ouId);

  const ouCultures = useMemo(() => {
    const cultures = appConfig?.Configuration[APP_CONFIG_CULTURES_PROPERTY];
    const parsedCultures = culturesSchema.safeParse(cultures);
    if (parsedCultures.success) {
      return parsedCultures.data;
    }
    return undefined;
  }, [appConfig]);

  const ouDefaultCulture = useMemo(() => {
    const ouCulture = appConfig?.Configuration[APP_CONFIG_OU_CULTURE_PROPERTY];
    const parsedOUCulture = ouCultureSchema.safeParse(ouCulture);
    if (parsedOUCulture.success) {
      const defaultCulture = parsedOUCulture.data;
      if (!defaultCulture.LanguageID) {
        onNewDefaultCulture?.(undefined);
        return undefined;
      }
      onNewDefaultCulture?.(defaultCulture);
      return defaultCulture;
    }
    onNewDefaultCulture?.(undefined);
    return undefined;
  }, [appConfig?.Configuration, onNewDefaultCulture]);

  const OUCultureSearchListField = useMemo(
    () =>
      SearchListFieldGenerator.LocalSearchListFieldGenerator({
        items: [...(ouDefaultCulture ? [ouDefaultCulture] : []), ...(ouCultures ?? [])],
        getLabel: (item) => item.Culture,
        getItemId: (item) => item.Culture,
        useItemByID: (id, listItems) => {
          const item = useMemo(() => {
            if (!id) {
              return undefined;
            }
            if (listItems && listItems.some((culture) => culture.Culture === id)) {
              return listItems.find((culture) => culture.Culture === id);
            }
            const [languageId, countryId] = id.split("-");
            return {
              Culture: id,
              CountryID: countryId,
              LanguageID: languageId,
            };
          }, [id, listItems]);
          return { data: item, isLoading: false };
        },
        useItemsByID: (ids, listItems) => {
          const items = useMemo(() => {
            if (!ids) {
              return [];
            }
            return ids
              .map((id) => {
                if (listItems && listItems.some((culture) => culture.Culture === id)) {
                  return listItems.find((culture) => culture.Culture === id);
                }
                const [languageId, countryId] = id.split("-");
                return {
                  Culture: id,
                  CountryID: countryId,
                  LanguageID: languageId,
                };
              })
              .filter((item): item is OUCulture => item !== undefined);
          }, [ids, listItems]);
          return { data: items, isLoading: false };
        },
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.culture",
          defaultMessage: "Culture",
        }),
      }),
    [ouCultures, ouDefaultCulture],
  );

  return { OUCultureSearchListField, ouDefaultCulture, ouCultures };
}
