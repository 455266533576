import { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";

import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { validateFiscalIDQuery } from "models/users";
import { AuditingFiscalIDValidationResult } from "types/eva-core";

/**
 * Check if an fiscal id is available in EVA. It will trigger a request to
 * `ValidateFiscalID` service if the provided value is a valid fiscal id.
 * @param value `string | undefined`
 * @param onAfterValidation `((isValid?: boolean, fiscalId?: string) => void) | undefined`
 * @param validationException `string | string[] | undefined`
 */
const useValidateFiscalID = (
  value?: string,
  onAfterValidation?: (isValid?: boolean, fiscalId?: string) => void,
  validationException?: string | string[],
) => {
  const debouncedValue = useDebounce(value, 500);

  const [isValid, setIsValid] = useState<boolean | undefined>(true);

  // Check if local value is included an exception
  const isValidationException = useMemo(
    () =>
      !!validationException &&
      (!debouncedValue ||
        (typeof validationException === "string"
          ? validationException === debouncedValue
          : validationException?.includes(debouncedValue))),
    [debouncedValue, validationException],
  );

  // Check if we have a valid fiscal id and its value is not an exception
  const shouldValidate = useMemo(
    () => !isValidationException && !!debouncedValue,
    [debouncedValue, isValidationException],
  );

  const onSuccess = useCallback(
    (response?: EVA.Auditing.ValidateFiscalIDResponse) => {
      const isValid =
        response?.Success === (AuditingFiscalIDValidationResult.Unknown as number)
          ? undefined
          : response?.Success === (AuditingFiscalIDValidationResult.Success as number);

      setIsValid(isValid);

      onAfterValidation?.(isValid, debouncedValue);
    },
    [debouncedValue, onAfterValidation],
  );

  const { isFetching: isValidateFiscalIdLoading } = useQuery({
    ...validateFiscalIDQuery(debouncedValue ? { FiscalID: debouncedValue } : undefined, []),
    enabled: !!shouldValidate && !!debouncedValue,
    onSuccess,
    // If the service throws an error, set the fiscal id as valid.
    // This could prevent not being able to submit the form if `ValidateFiscalID`
    // service fails
    onError: () => {
      setIsValid(true);

      onAfterValidation?.(true);
    },
  });

  // Since the query is disabled when the value is an exception, we need to handle this separately
  useEffect(() => {
    if (isValidationException) {
      setIsValid(true);
      onAfterValidation?.(true, debouncedValue);
    }
  }, [debouncedValue, isValidationException, onAfterValidation]);

  return {
    isValid,
    setIsValid,
    shouldValidate,
    isLoading: isValidateFiscalIdLoading,
  };
};

export default useValidateFiscalID;
