import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

export const listCustomFieldsServiceState = builders.buildServiceState({
  service: CoreManagement.ListCustomFields,
  key: "CoreManagement:ListCustomFields",
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const listCustomFieldsResultSelector = selector({
  key: "CoreManagement:ListCustomFields:Result",
  get: ({ get }) => get(listCustomFieldsServiceState.response)?.Result?.Page,
});
