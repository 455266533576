export const MenuLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41 16"
      fill="none"
      className="w-8 text-primary"
    >
      <g>
        <path
          d="M36.211 15.9986H40.8434L31.5786 0L22.3139 15.9986H26.9462L26.9471 15.9971H26.9487L29.2649 11.9975H29.2633L31.5786 7.99928L31.5794 8.00065L31.5798 8.00006L31.5807 8.00293L36.211 15.9986Z"
          fill="currentColor"
        />
        <path
          d="M15.3653 0.00144663L0.310059 0.00144691L0.310059 3.92514L12.9867 3.92514L19.9977 16L29.2624 0.00144663H24.6301L19.9977 8.00072L15.3653 0.00144663Z"
          fill="currentColor"
        />
        <path
          d="M17.6681 16H0.310059L0.310059 12.0763L15.4022 12.0763L17.6681 16Z"
          fill="currentColor"
        />
        <path
          d="M11.9392 6.07685L14.1614 9.9246L0.310059 9.9246L0.310059 6.07685L11.9392 6.07685Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
