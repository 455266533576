import { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Separator, SvgIcon, Text } from "@new-black/lyra";

import { generateLyraProductSearchPropertySearchListField } from "../../generate-product-search-property-search-list-field";
import { useProductSearchModalContext } from "../product-search-modal-context";
import type {
  IProductMultiSearchModalProps,
  IProductSearchModalResultBase,
  IProductSingleSearchModalProps,
} from "../types";

import ProductSearchModalFilters from "./product-search-modal-filters";
import {
  ProductMultiSearchModalLeftPaneFooter,
  ProductSingleSearchModalLeftPaneFooter,
} from "./product-search-modal-left-pane-footer";
import ProductSearchModalProductResults from "./product-search-modal-product-results";

export function ProductMultiSearchModalLeftPane<TProduct extends IProductSearchModalResultBase>({
  leftPaneListItemRenderElements,
  messages,
}: Pick<IProductMultiSearchModalProps<TProduct>, "messages" | "leftPaneListItemRenderElements">) {
  const { filters, setPropertyFilter } = useProductSearchModalContext<TProduct>();

  const ProductSearchPropertySearchListField = useMemo(
    () =>
      generateLyraProductSearchPropertySearchListField({
        frontendFilter: (item) => !filters.some((filter) => filter.ProductProperty === item.ID),
      }),
    [filters],
  );

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-between gap-4 px-4 pt-4">
          <Text variant="heading-2">
            {messages?.leftPaneTitle || (
              <FormattedMessage id="generic.label.product-search" defaultMessage="Product search" />
            )}
          </Text>
          <ProductSearchPropertySearchListField.SingleSearchListField.Controlled
            onChange={(item) => {
              if (item) {
                setPropertyFilter(item.ID, []);
              }
            }}
          >
            <Button variant="secondary" endIcon={<SvgIcon name="plus" />}>
              <FormattedMessage
                id="generic.label.add-search-property"
                defaultMessage="Add search property"
              />
            </Button>
          </ProductSearchPropertySearchListField.SingleSearchListField.Controlled>
        </div>

        <ProductSearchModalFilters />
      </div>
      <Separator />

      <ProductSearchModalProductResults listItemRenderElements={leftPaneListItemRenderElements} />

      <ProductMultiSearchModalLeftPaneFooter />
    </>
  );
}

interface IProductSingleSearchLeftPaneProps<TProduct extends IProductSearchModalResultBase>
  extends Pick<
      IProductSingleSearchModalProps<TProduct>,
      | "messages"
      | "onSave"
      | "isSaveDisabled"
      | "isSaveLoading"
      | "saveButtonName"
      | "saveButtonValue"
      | "form"
    >,
    Pick<IProductMultiSearchModalProps<TProduct>, "messages" | "leftPaneListItemRenderElements"> {
  onClose: () => void;
  customContent?: ReactNode;
}

export function ProductSingleSearchModalLeftPane<TProduct extends IProductSearchModalResultBase>({
  customContent,
  leftPaneListItemRenderElements,
  messages,
  ...singleLefPaneFooterProps
}: IProductSingleSearchLeftPaneProps<TProduct>) {
  const { filters, setPropertyFilter } = useProductSearchModalContext<TProduct>();

  const ProductSearchPropertySearchListField = useMemo(
    () =>
      generateLyraProductSearchPropertySearchListField({
        frontendFilter: (item) => !filters.some((filter) => filter.ProductProperty === item.ID),
      }),
    [filters],
  );

  return (
    <>
      <div className="space-y-4">
        <div className="flex items-center justify-between gap-4 px-4 pt-4">
          <Text variant="heading-2">
            {messages?.leftPaneTitle || (
              <FormattedMessage id="generic.label.product-search" defaultMessage="Product search" />
            )}
          </Text>
          <ProductSearchPropertySearchListField.SingleSearchListField.Controlled
            onChange={(item) => {
              if (item) {
                setPropertyFilter(item.ID, []);
              }
            }}
          >
            <Button variant="secondary" endIcon={<SvgIcon name="plus" />}>
              <FormattedMessage
                id="generic.label.add-search-property"
                defaultMessage="Add search property"
              />
            </Button>
          </ProductSearchPropertySearchListField.SingleSearchListField.Controlled>
        </div>

        <ProductSearchModalFilters />
      </div>

      <ProductSearchModalProductResults listItemRenderElements={leftPaneListItemRenderElements} />

      {customContent}
      <ProductSingleSearchModalLeftPaneFooter {...singleLefPaneFooterProps} />
    </>
  );
}
