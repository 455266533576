import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from "react";

import { BadgeProps } from "@new-black/lyra";
import { cva, VariantProps } from "class-variance-authority";

import Tooltip from "components/ui/tooltip";

export type IconButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "ref"
> &
  VariantProps<typeof iconButtonStyles> & {
    label?: string;
    bubble?: {
      color: Exclude<BadgeProps["variant"], undefined>;
      position?: `${"top" | "bottom"}-${"left" | "right"}`;
    };
  };

const bubbleStyles = cva(["absolute w-[6px] h-[6px] rounded-full"], {
  variants: {
    color: {
      blue: "bg-[color:var(--legacy-eva-color-blue)]",
      orange: "bg-[color:var(--legacy-eva-color-orange)]",
      green: "bg-[color:var(--legacy-eva-color-green)]",
      red: "bg-[color:var(--legacy-eva-color-red)]",
      gray: "bg-[color:var(--legacy-eva-color-gray)]",
      white: "bg-[color:var(--eva-color-white)]",
      pink: "bg-[color:var(--legacy-eva-color-pink)]",
      "status-orange": "bg-[color:var(--legacy-eva-color-status-orange)]",
      cyan: "bg-[color:var(--legacy-eva-color-cyan)]",
      "dark-blue": "bg-[color:var(--legacy-eva-color-dark-blue)]",
      "apple-color-pink": "bg-[color:var(--legacy-eva-apple-color-pink)]",
      "apple-color-red": "bg-[color:var(--legacy-eva-apple-color-red)]",
      "apple-color-purple": "bg-[color:var(--legacy-eva-apple-color-purple)]",
      "apple-color-orange": "bg-[color:var(--legacy-eva-apple-color-orange)]",
      "apple-color-teal-blue": "bg-[color:var(--legacy-eva-apple-color-teal-blue)]",
      "apple-color-green": "bg-[color:var(--legacy-eva-apple-color-green)]",
      "apple-color-yellow": "bg-[color:var(--legacy-eva-apple-color-yellow)]",
      // Added Lyra colors to satisfy TypeScript
      brown: "bg-inidication-brown",
      indigo: "bg-inidication-indigo",
      lime: "bg-inidication-lime",
      purple: "bg-inidication-purple",
      teal: "bg-inidication-teal",
      transparent: "bg-transparent",
      yellow: "bg-inidication-yellow",
      mint: "bg-inidication-mint",
      black: "bg-[color:#000]",
    },
    position: {
      "top-left": "top-[7.5px] left-[7.5px]",
      "top-right": "top-[7.5px] right-[7.5px]",
      "bottom-left": "bottom-[10px] left-[7.5px]",
      "bottom-right": "bottom-[10px] right-[7.5px]",
    },
  },
});

export const iconButtonStyles = cva(
  [
    "bg-transparent rounded-full border-0 p-0 text-center overflow-visible relative",
    "hover:bg-overlay-subtle focus-visible:bg-overlay-subtle focus-visible:ring-1 ring-[color:var(--color-primary-3)]",
    "flex justify-center items-center",
  ],
  {
    variants: {
      disabled: {
        true: "cursor-not-allowed opacity-50 hover:bg-transparent",
      },
      size: {
        small: "w-6 h-6 min-w-[24px]",
        medium: "w-12 h-12 min-w-[48px]",
      },
      color: {
        primary: "text-[color:var(--color-primary-3)]",
        inherit: "text-current",
        secondary: "text-tertiary",
        default: "text-secondary",
        error: "text-error",
      },
      edge: {
        start: "",
        end: "",
        false: "",
      },
    },
    compoundVariants: [
      {
        size: "small",
        edge: "start",
        class: "ml-[-3px]",
      },
      {
        size: "medium",
        edge: "start",
        class: "-ml-3",
      },
      {
        size: "small",
        edge: "end",
        class: "mr-[-3px]",
      },
      {
        size: "medium",
        edge: "end",
        class: "-mr-3",
      },
    ],
  },
);

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      bubble,
      children,
      className,
      color = "default",
      disabled,
      edge,
      label,
      size = "medium",
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    if (label) {
      return (
        <Tooltip title={label}>
          <button
            type="button"
            ref={ref}
            {...props}
            disabled={disabled}
            className={iconButtonStyles({ color, edge, size, disabled, class: className })}
          >
            {children}
            {bubble ? (
              <div
                className={bubbleStyles({
                  color: bubble.color,
                  position: bubble.position ?? "top-right",
                })}
              />
            ) : null}
          </button>
        </Tooltip>
      );
    }

    return (
      <button
        type="button"
        ref={ref}
        disabled={disabled}
        className={iconButtonStyles({ color, edge, size, disabled, class: className })}
        {...props}
      >
        {children}
        {bubble ? (
          <div
            className={bubbleStyles({
              color: bubble.color,
              position: bubble.position ?? "top-right",
            })}
          />
        ) : null}
      </button>
    );
  },
);

IconButton.displayName = "IconButton";

export default IconButton;
