import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

export const getLedgerClassesServiceState = builders.buildServiceState({
  service: CoreManagement.GetLedgerClasses,
  key: "CoreManagement:GetLedgerClasses",
  requireEmployee: true,
  allowEmptyRequest: true,
});

export const getLedgerClassesResultSelector = selector({
  key: "CoreManagement:GetLedgerClasses:Result",
  get: ({ get }) => get(getLedgerClassesServiceState.response)?.LedgerClasses,
});
