import { ReactNode, useMemo } from "react";

import { Card } from "@new-black/lyra";
import { isEmpty } from "lodash";

import LoadingStateBox from "components/shared/loading-state-box";
import { IEVAFilterArrayItem } from "components/suite-composite/product-filter/hooks/use-convert-filter-to-array";
import { getProductLimitationProductInformation } from "features/loyalty-programs";
import { removeEmptyObjects, removeUndefinedValues } from "util/helper";

import { LoyaltyProgramProductLimitationDataEmptyStateCard } from "./loyalty-program-product-limitation-data-empty-state-card";
import { LoyaltyProgramProductLimitationDataProductFiltersCard } from "./product-filters";
import { LoyaltyProgramProductLimitationDataProductIDsCard } from "./product-ids";
import { LoyaltyProgramProductLimitationDataProductSearchTemplateCard } from "./product-search-template";

export const LoyaltyProgramProductLimitationDataCard = ({
  emptyMessage,
  isLoadingWithoutPreviousResponse,
  productFiltersProps,
  productLimitationData,
  productsBulkProps,
  productSearchTemplateProps,
  productsProps,
}: {
  productLimitationData?: EVA.Core.Management.LoyaltyProgramProductLimitationData;
  isLoadingWithoutPreviousResponse?: boolean;
  emptyMessage?: string;
} & {
  productSearchTemplateProps: {
    onAdd?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
  };
  productsProps: {
    onAdd?: () => void;
    onEdit?: () => void;
    onDelete?: () => void;
    onDeleteProduct?: (productID: number) => void;
    queryKey: string | number;
  };
  productsBulkProps: {
    onAdd?: () => void;
  };
  productFiltersProps: {
    onAdd?: () => void;
    onAddProductsBulk?: () => void;
    customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode;
    onDelete?: () => void;
  };
}) => {
  const hasProductLimitationData = useMemo(
    () =>
      !isEmpty(
        removeEmptyObjects(
          removeUndefinedValues(getProductLimitationProductInformation(productLimitationData)),
        ),
      ),
    [productLimitationData],
  );

  if (isLoadingWithoutPreviousResponse) {
    return (
      <Card>
        <LoadingStateBox limit={1} />
      </Card>
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {!hasProductLimitationData ? (
        <LoyaltyProgramProductLimitationDataEmptyStateCard
          onAddProductSearchTemplate={productSearchTemplateProps?.onAdd}
          onAddProducts={productsProps?.onAdd}
          onAddProductFilters={productFiltersProps?.onAdd}
          onAddProductsBulk={productsBulkProps?.onAdd}
          emptyMessage={emptyMessage}
        />
      ) : null}

      {productLimitationData?.ProductSearchID !== undefined ? (
        <LoyaltyProgramProductLimitationDataProductSearchTemplateCard
          productSearchTemplateID={productLimitationData?.ProductSearchID}
          {...productSearchTemplateProps}
        />
      ) : null}

      {productLimitationData?.ProductIDs?.length ? (
        <LoyaltyProgramProductLimitationDataProductIDsCard
          productIDs={productLimitationData?.ProductIDs}
          {...productsProps}
        />
      ) : null}

      {!isEmpty(productLimitationData?.Filters) ? (
        <LoyaltyProgramProductLimitationDataProductFiltersCard
          filters={productLimitationData?.Filters}
          {...productFiltersProps}
        />
      ) : null}
    </div>
  );
};
