import type Monaco from "monaco-editor";

export const BASE_SCRIPT_EDITOR_OPTIONS:
  | Monaco.editor.IStandaloneEditorConstructionOptions
  | Monaco.editor.IDiffEditorConstructionOptions = {
  padding: { top: 20, bottom: 20 },
  wordWrap: "on",
  scrollBeyondLastLine: false,
  tabSize: 2,
  detectIndentation: true,
  autoIndent: "full",
  formatOnPaste: true,
  formatOnType: true,
  automaticLayout: true,
  scrollbar: {
    vertical: "visible",
    verticalScrollbarSize: 6,
  },
  // renderOverviewRuler: false, Uncomment this to hide the overview ruler in diff mode
};

export const DEFAULT_SCRIPT_EDITOR_IN_MODAL_HEIGHT = "88vh" as const;
export const DEFAULT_SCRIPT_EDITOR_WITH_REVISION_COMMENT_HEADER_IN_MODAL_HEIGHT = "84vh" as const;
