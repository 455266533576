import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import enumToArray from "util/enum-to-array";
import { LoyaltyProgramOptions } from "types/eva-core";
import { addSpacingBeforeCapitals } from "util/hooks/use-add-spacing-before-capitals";

export function LoyaltyProgramOptionsBadges({ value }: { value: number }) {
  const valuesArray = useMemo(() => enumToArray(LoyaltyProgramOptions), []);

  const usageTypes = useMemo(() => {
    if (!valuesArray?.length) return [];

    if (value === 0) {
      return [LoyaltyProgramOptions[value]];
    }

    return valuesArray
      ?.filter((entry) => entry.value !== 0 && (value & entry.value) === entry.value)
      .map((entry) => addSpacingBeforeCapitals(entry.key));
  }, [value, valuesArray]);

  return (
    <>
      {usageTypes.length
        ? usageTypes.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
}
