import { ReactNode } from "react";

import { useDroppable } from "@dnd-kit/core";

function DroppableContainer({ children, id }: { children: ReactNode; id: any }) {
  const { setNodeRef } = useDroppable({ id });
  return <div ref={setNodeRef}>{children}</div>;
}

export default DroppableContainer;
