export enum MessageTemplateTypes {
  Template = 1,
  Partial = 2,
  Layout = 3,
}

export enum MessageTemplateTypesAsStringValues {
  Template = "template",
  Partial = "partial",
  Layout = "layout",
}
