import { ComponentPropsWithoutRef } from "react";

import FormControl from "@material-ui/core/FormControl";
import { cva } from "class-variance-authority";

const formControlStyles = cva([], {
  variants: {
    small: {
      true: [
        "[&_.MuiInput-root]:text-legacy-sm [&_.MuiInput-root]:min-h-[32px] [&_.MuiInput-root]:pl-3.5",
        "[&_.MuiSelect-root]:pt-[3px] [&_.MuiSelect-root]:pb-1",
        "[&_label_+_.MuiInput-formControl]:mt-0",
        "[&_.MuiInputLabel-formControl]:translate-y-1 [&_.MuiInputLabel-formControl]:translate-x-0",
        "[&_.MuiInputLabel-formControl]:text-legacy-sm",
        "[&_.MuiInputLabel-marginDense]:translate-y-1 [&_.MuiInputLabel-marginDense]:translate-x-0",
        "[&_.MuiInputLabel-shrink]:-translate-y-2 [&_.MuiInputLabel-shrink]:translate-x-0",
        "[&_.MuiInputLabel-shrink]:scale-[0.8]",
        "[&_input]:pb-1",
        "[&_.MuiFormControl-root]:my-0",
      ],
      false: "",
    },
    multi: {
      true: "[&_.MuiSelect-root]:h-[unset]",
      false: "",
    },
    disabled: {
      true: "[&_.MuiInputLabel-root]:text-[rgba(0,0,0,0.38)]",
      false: "",
    },
    passive: {
      true: [
        "[&_label.MuiInputLabel-root.Mui-focused]:text-[#666666]",
        "[&_.MuiSelect-root]:cursor-default",
      ],
      false: "",
    },
    warning: {
      true: [
        "[&_.MuiInput-underline:before]:border-b-2 [&_.MuiInput-underline:before]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
        "[&_.MuiInput-underline:after]:border-b-2 [&_.MuiInput-underline:after]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
        "hover:[&_.MuiInput-underline:before]:border-b-2 hover:[&_.MuiInput-underline:before]:[border-bottom-color:var(--legacy-eva-color-red-orange)]",
      ],
      false: "",
    },
  },
});

interface IStyledFormControlProps extends ComponentPropsWithoutRef<typeof FormControl> {
  small?: boolean;
  disabled?: boolean;
  multi?: boolean;
  passive?: boolean;
  warning?: boolean;
}

export const StyledFormControl = ({
  className,
  disabled,
  multi,
  passive,
  small,
  warning,
  ...props
}: IStyledFormControlProps) => (
  <FormControl
    {...props}
    className={formControlStyles({
      small: small ?? false,
      disabled: disabled ?? false,
      multi: multi ?? false,
      passive: passive ?? false,
      warning: warning ?? false,
      className,
    })}
  />
);
