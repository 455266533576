import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listShippingRestrictionsLoaderQuery,
  serviceQueryKeys: listShippingRestrictionsQueryKeys,
  useServiceQueryHook: useListShippingRestrictionsQuery,
} = createServiceQuery(CoreManagement.ListShippingRestrictions);

export const {
  serviceLoaderQuery: getShippingRestrictionByIdLoaderQuery,
  serviceQueryKeys: getShippingRestrictionByIdQueryKeys,
  useServiceQueryHook: useGetShippingRestrictionByIdQuery,
} = createServiceQuery(CoreManagement.GetShippingRestrictionByID);
