import { ChangeEvent } from "react";

import { NumberField, NumberFieldProps } from "@new-black/lyra";
import { useField } from "formik";

import NumberInput, { INumberInput } from "components/suite-ui/number-input";
import { numberOrNullToUndefined } from "util/helper";

export interface IFormikNumberInputProps extends Exclude<INumberInput, "onChange" | "value"> {
  name: string;
  /** Value set when the input is cleared / cannot be parsed to a number. Defaults to `undefined`. */
  fallbackValue?: null | undefined;
}

export const FormikNumberInput = ({
  fallbackValue = undefined,
  helperText,
  name,
  ...props
}: IFormikNumberInputProps) => {
  const [field, meta, { setValue }] = useField<number | null | undefined>(name);

  return (
    <NumberInput
      {...props}
      {...field}
      value={field?.value ?? ""}
      error={!!meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value ? parseFloat(`${event.target.value}`) : fallbackValue);
        props.onChange?.(event);
      }}
    />
  );
};

export interface ILyraFormikNumberInputProps
  extends Omit<NumberFieldProps, "onChange" | "value" | "defaultValue" | "name"> {
  name: string;
  /** Value set when the input is cleared / cannot be parsed to a number. Defaults to `undefined`. */
  fallbackValue?: null | undefined;
}

function LyraFormikNumberInput({
  errorMessage,
  fallbackValue = undefined,
  name,
  ...props
}: ILyraFormikNumberInputProps) {
  const [field, meta, { setValue }] = useField<number | null | undefined>(name);

  return (
    <NumberField
      {...props}
      {...field}
      value={field?.value ?? null}
      errorMessage={meta.error && meta.touched ? meta.error : errorMessage}
      onChange={(newValue) => {
        const parsedValue = numberOrNullToUndefined(newValue) ?? fallbackValue;
        setValue(parsedValue);
      }}
    />
  );
}

FormikNumberInput.Lyra = LyraFormikNumberInput;
