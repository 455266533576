import { useEffect, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { isEqual } from "lodash";
import { useRecoilState } from "recoil";

import useDebounce from "hooks/suite-react-hooks/use-debounce";

import { SearchProductsService, searchProductsServiceState } from "./search-products.state";
import { ISearchProductFilter } from "./search-products.types";

export { SearchProductsService } from "./search-products.state";

interface IUseProductSetSelectorSearch {
  filters: ISearchProductFilter[];
  defaultFilters: ISearchProductFilter[];
  /**
   * IncludedFields to pass to the underlying `SearchProducts` service request.
   * `backend_id`, `custom_id` `display_value`, `primary_image.blob` are default
   * but they can be overwritten by this prop
   */
  includedFields?: string[];
  availabilityFilters?: EVA.Core.GetProductAvailabilityOptions;
  querySearch?: string;
}

export const useSearchProducts = ({
  availabilityFilters,
  defaultFilters,
  filters,
  includedFields,
  querySearch,
}: IUseProductSetSelectorSearch) => {
  const products = hooks.useGetState(SearchProductsService.result);
  const options = hooks.useGetState(SearchProductsService.resultOptions);
  const isLoading = hooks.useIsLoading({
    state: searchProductsServiceState.response,
  });
  const isLoadingWithoutResponse = useMemo(() => !products && isLoading, [isLoading, products]);
  const [request, setRequest] = useRecoilState(searchProductsServiceState.request);

  const debouncedQuerySearchValue = useDebounce(querySearch, 500);

  useEffect(() => {
    const filtersWithValues = filters.filter((x) => !!x.ProductProperty && !!x.Values?.length);
    const defaultFiltersWithValues = defaultFilters.filter(
      (x) => !!x.ProductProperty && !!x.Values?.length,
    );

    const newRequest =
      filtersWithValues.length > 0 || debouncedQuerySearchValue
        ? ({
            Filters: {
              ...defaultFiltersWithValues.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.ProductProperty!]: {
                    Values: curr.Values!,
                    ExactMatch: curr.ExactMatch,
                    IncludeMissing: curr.IncludeMissing,
                    Negation: curr.Negation,
                    From: curr.From,
                    To: curr.To,
                  },
                }),
                {} as EVA.Core.SearchProducts["Filters"],
              ),
              ...filtersWithValues.reduce(
                (acc, curr) => ({
                  ...acc,
                  [curr.ProductProperty!]: {
                    Values: curr.Values!,
                    ExactMatch: curr.ExactMatch,
                    IncludeMissing: curr.IncludeMissing,
                    Negation: curr.Negation,
                    From: curr.From,
                    To: curr.To,
                  },
                }),
                {} as EVA.Core.SearchProducts["Filters"],
              ),
            },

            PageSize: 9999,
            IncludedFields: includedFields || [
              "display_value",
              "primary_image.blob",
              "custom_id",
              "backend_id",
            ],
            Options: {
              AvailabilityOptions: availabilityFilters,
            },
            Query: debouncedQuerySearchValue,
          } as EVA.Core.SearchProducts)
        : undefined;

    if (!isEqual(request, newRequest)) {
      setRequest(newRequest);
    }
  }, [
    availabilityFilters,
    debouncedQuerySearchValue,
    defaultFilters,
    filters,
    includedFields,
    request,
    setRequest,
  ]);

  return { products, isLoading, isLoadingWithoutResponse, options };
};
