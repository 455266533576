import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getAvailablePaymentSettlementFileHandlersQuery } from "models/payments";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

type SettlementHandler = {
  ID: string;
  Name: string;
};

export const generatePaymentSettlementFileHandlersSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetAvailablePaymentSettlementFileHandlers,
    SettlementHandler,
    string
  >({
    getItemFromResponse: (response) =>
      response?.Handlers?.map((handler) => ({
        ID: handler,
        Name: handler,
      })) ?? [],
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.handler",
      defaultMessage: "Handler",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getAvailablePaymentSettlementFileHandlersQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
