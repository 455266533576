import { FormattedMessage } from "react-intl";

import { Badge } from "@new-black/lyra";

export enum OrganizationUnitSetTypes {
  System = 0,
  Custom = 1,
  AdHoc = 2,
}

export default function OUSetTypeBadge({ type }: { type: OrganizationUnitSetTypes }) {
  switch (type) {
    case OrganizationUnitSetTypes.System:
      return (
        <Badge variant="blue">
          <FormattedMessage id="organization-unit-set-types.label.0" defaultMessage="System" />
        </Badge>
      );
    case OrganizationUnitSetTypes.Custom:
      return (
        <Badge variant="green">
          <FormattedMessage id="organization-unit-set-types.label.1" defaultMessage="Custom" />
        </Badge>
      );
    case OrganizationUnitSetTypes.AdHoc:
      return (
        <Badge variant="orange">
          <FormattedMessage id="organization-unit-set-types.label.2" defaultMessage="Custom" />
        </Badge>
      );
    default:
      return null;
  }
}
