import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const countriesListServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
  service: Core.ListCountries,
  defaultRequest: { FetchAll: true },
  key: "Composite:useCountryList:ServiceState:Core.ListCountries",
});

export const countriesListResponseSelector = selectorFamily({
  key: "Composite:useCountryList:ServiceState:Core.ListCountries:response",
  get:
    (param) =>
    ({ get }) =>
      get(countriesListServiceState.response(param))?.Result,
});

export const ListCountriesServiceState = {
  serviceState: countriesListServiceState,
  responseSelector: countriesListResponseSelector,
};
