export interface IGeneralInfoFormValues {
  creditor: number | undefined;
  debtor: number | undefined;
  invoiceNumber: string;
  invoiceDate: string;
  invoiceAmount: number | string;
  invoiceDescription: string;
  dateOfBooking: string;
  taxReverseCharge: boolean;
}

export interface IInvoiceFile {
  active: boolean;
  data?: ArrayBuffer | string | null;
  id?: string;
  name?: string;
  path: string;
  type?: string;
}

export interface IMatchShipmentLineFormValues {
  QuantityAvailableForInvoice?: number;
  UnitPrice?: number;
  ExpectedQuantity?: number;
}
export interface IAdditionalAmountTableRowDto {
  ID: number | undefined;
  TypeID: number;
  TaxCodeID: number;
  Amount: number;
  TotalAmount: number;
  VatAmount: number;
  OriginalAmount: number | undefined;
}

export interface IInvoiceAdditionalAmounts
  extends Partial<EVA.Core.GetInvoiceAdditionalAmountsForInvoiceResponse.InvoiceAdditionalAmountDto> {
  ID: number | undefined;
}

export enum EvaDisputeType {
  INVOICE = 1,
  INVOICE_LINE = 2,
  ADDITIONAL_AMOUNT = 3,
}

export enum EvaInvoiceDisputeTypes {
  Original = 0,
  Expected = 1,
}

export interface IDisputeWithLedgerClassDto extends EVA.Core.InvoiceDisputeDto {
  LedgerClass: string;
}

export interface IInvoiceDetailLineWithDisputeDto extends EVA.Core.InvoiceDetailLineDto {
  HasDispute: boolean;
}

export interface IResolveActionTableRow extends EVA.Core.CreateInvoiceDisputeResolveAction {
  ID?: number;
}

export enum EvaOrganizationUnitsTypes {
  Creditor = 255,
  Debtor = 1024,
}
