import { generatePath, Navigate, useLocation, useParams } from "react-router-dom";

export interface INavigateWithParams {
  path: string;
  replace?: boolean;
}
/** Navigate to a path preserving all route params of the current route */
export const NavigateWithParams = ({ path, replace }: INavigateWithParams) => {
  const params = useParams();
  const { search } = useLocation();

  return <Navigate to={`${generatePath(path, { ...params })}${search}`} replace={replace} />;
};
