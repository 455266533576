import { useCallback, useState } from "react";

const useScrollToLast = () => {
  const [enabled, setEnabled] = useState(false);

  const enableScrollToLast = () => setEnabled(true);

  const scrollToLast = useCallback(
    (element: HTMLElement | null) => {
      if (enabled && element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    [enabled],
  );

  const getScrollToLast = useCallback(
    (index: number, length: number) => {
      if (index === length - 1 && enabled) {
        return scrollToLast;
      }

      return null;
    },
    [enabled, scrollToLast],
  );

  return { getScrollToLast, enableScrollToLast };
};

export default useScrollToLast;
