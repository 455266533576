import { v4 as uuidV4 } from "uuid";

import { EnumValuesType } from "./types";

const removeEmptyKeysFromEnum = (obj: { [key: string]: string }) => {
  return Object.entries(obj)
    .filter(([k, v]) => {
      return v !== null && k !== null && v !== "" && k !== "";
    })
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const convertEnumValuesTypeToKeyObj = (obj: EnumValuesType): { [key: string]: string } => {
  const newEnums: { [key: string]: string } = {};

  Object.entries(obj).forEach(([, { key, value }]) => {
    newEnums[key] = value;
  });

  return removeEmptyKeysFromEnum(newEnums);
};

export const convertKeyObjToEnumValuesType = (obj?: { [key: string]: string }): EnumValuesType => {
  const valueEnums: EnumValuesType = {};
  Object.entries(obj ?? {}).forEach(([key, value]) => {
    const uuidKey = uuidV4();
    valueEnums[uuidKey] = {
      key,
      value,
    };
  });

  return valueEnums;
};

export const mapCustomFieldValueFromResponse = (
  response: Omit<EVA.Core.Management.GetCustomFieldByIDResponse, "Metadata">,
) => ({
  Name: response.Name,
  DataType: response.DataType,
  DisplayName: response.DisplayName,
  TypeID: response.TypeID,
  BackendID: response.BackendID,
  ID: response.ID,
  EnumValues: convertKeyObjToEnumValuesType(response.EnumValues),
  IsArray: response.IsArray,
  TypeKey: response.TypeKey,
});
