import { useState } from "react";

import { SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import IconButton from "../icon-button";
import Input, { InputProps } from "../input";

export type TimePickerFieldProps = Omit<InputProps, "type" | "endIcon"> & { onClear?: () => void };

const TimePickerField = ({
  className,
  label,
  onBlur,
  onClear,
  onFocus,
  small,
  value,
  ...props
}: TimePickerFieldProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  return (
    <Input
      {...props}
      type={focused || (value && value !== "") || !label ? "time" : "text"}
      value={value}
      label={label}
      className={classNames(
        '[&_input[type="time"]::-webkit-calendar-picker-indicator]:hidden',
        '[&_input[type="time"]::-webkit-datetime-edit-hour-field]:focus:p-1',
        '[&_input[type="time"]::-webkit-datetime-edit-hour-field]:focus:bg-[color:var(--legacy-eva-color-primary-transparent-40)]',
        '[&_input[type="time"]::-webkit-datetime-edit-minute-field]:focus:p-1',
        '[&_input[type="time"]::-webkit-datetime-edit-minute-field]:focus:bg-[color:var(--legacy-eva-color-primary-transparent-40)]',
        '[&_input[type="time"]::-webkit-datetime-edit-second-field]:focus:p-1',
        '[&_input[type="time"]::-webkit-datetime-edit-second-field]:focus:bg-[color:var(--legacy-eva-color-primary-transparent-40)]',
        className,
      )}
      onFocus={(e) => {
        if (onFocus) {
          onFocus(e);
        }
        setFocused(true);
      }}
      onBlur={(e) => {
        if (onBlur) {
          onBlur(e);
        }
        setFocused(false);
      }}
      small={small}
      endIcon={
        <div className="flex items-center gap-3">
          {onClear && value ? (
            <IconButton onClick={onClear} size={small ? "small" : undefined}>
              <SvgIcon name="alert-error" className="h-5 w-5 text-secondary" />
            </IconButton>
          ) : null}

          <div className="text-[rgba(0,0,0,0.6)]">
            <SvgIcon name="opening-hours-templates" className="h-5 w-5 text-secondary" />
          </div>
        </div>
      }
    />
  );
};

export default TimePickerField;
