import { DetailedHTMLProps } from "react";

import classNames from "classnames";

const getTemplateColumns = (parentWidth: number) => {
  if (parentWidth > 768) {
    return 8;
  }
  if (parentWidth > 640) {
    return 7;
  }
  if (parentWidth > 520) {
    return 6;
  }
  if (parentWidth > 400) {
    return 5;
  }
  return 4;
};

export const Previews = ({
  parentWidth,
  ...props
}: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  width: React.CSSProperties["width"];
  parentWidth: number;
}) => (
  <div
    {...props}
    className="grid gap-1 py-1"
    style={{
      width: `${props.width}px`,
      gridTemplateColumns: `repeat(${getTemplateColumns(parentWidth)}, 1fr)`,
    }}
  />
);

export const ImageButton = (
  props: DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => (
  <button
    {...props}
    className={classNames(
      props.className,
      "cursor-pointer border-none p-0 shadow-none [&_img]:w-full ",
    )}
  />
);
