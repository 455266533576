import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup, Separator } from "@new-black/lyra";

import Switch from "components/ui/switch";
import { TAllowedFunctionalities } from "hooks/suite-react-hooks/use-allowed-functionalities";

import { WorkspaceSelectorSection } from "./workspace-selector-section";

export const DEFAULT_WORKSPACE_ID = "default_workspace" as const;

export interface IWorkspaceSelectorItem
  extends Omit<EVA.Admin.GetWorkspacesResponse.Workspace, "ID"> {
  ID: number | typeof DEFAULT_WORKSPACE_ID;
}

export interface IWorkspaceSelectorStrings {
  resetWorkspaceActionLabel?: string;
  resetToDefaultWorkspaceActionLabel?: string;
  saveWorkspaceActionLabel?: string;
  deleteWorkspaceModalTitle?: string;
  deleteWorkspaceModalDescription?: string;
  deleteWorkspaceModalCancelLabel?: string;
  deleteWorkspaceModalConfirmLabel?: string;
  unsavedChangesLabel?: string;
  // Private workspace
  newWorkspaceActionLabel?: string;
  newWorkspaceModalTitle?: string;
  newWorkspaceModalInputLabel?: string;
  newWorkspaceModalCancelLabel?: string;
  newWorkspaceModalConfirmLabel?: string;
  // Shared workspace
  newSharedWorkspaceActionLabel?: string;
  newSharedWorkspaceModalTitle?: string;
  newSharedWorkspaceModalInputLabel?: string;
  newSharedWorkspaceModalCancelLabel?: string;
  newSharedWorkspaceModalConfirmLabel?: string;
  // Edit share permissions
  editWorkspacePermissionsActionLabel?: string;
  editWorkspacePermissionsModalTitle?: string;
  editWorkspacePermissionsModalInputLabel?: string;
  editWorkspacePermissionsModalCancelLabel?: string;
  editWorkspacePermissionsModalConfirmLabel?: string;
}

export interface IWorkspaceSelectorProps {
  /** List of available workspace items from EVA. */
  items: IWorkspaceSelectorItem[];
  /** The currently selected workspace item. */
  selectedItem?: IWorkspaceSelectorItem;
  /** Whether or not there are unsaved changes on the current workspace item. */
  hasUnsavedChanges: boolean;
  /** Callback that handles changing the selected workspace item. */
  onSelectedItemChange: (item: IWorkspaceSelectorItem) => void;
  /** Callback that handles discarding the changes to the selected workspace item. */
  onReset?: () => void;
  /** Callback that resets current workspace settings to the default workspace settings. */
  onResetToDefault?: () => void;
  /** Callback that handles saving the changes for the current workspace item. */
  onSave?: (workspace: IWorkspaceSelectorItem) => void;
  /** Callback that handles saving the changes for the selected workspace item. */
  onSaveAsNewWorkspace?: (workspace: IWorkspaceSelectorItem) => void;
  /** Callback that handles creating a new workspace item with current settings. */
  onAdd?: () => void;
  /** Callback that handles creating a new workspace item from default settings. */
  onAddFromDefault?: () => void;
  /** Callback that handles creating a new shared workspace item with current settings. */
  onAddShared?: () => void;
  /** Callback that handles creating a new shared workspace item from default settings. */
  onAddSharedFromDefault?: () => void;
  /** Callback that handles editing workspace permissions for a shared workspace. */
  onEditSharePermissions?: (workspace: IWorkspaceSelectorItem) => void;
  /** Callback that handles deleting a workspace item. */
  onDelete?: (item: IWorkspaceSelectorItem) => void;
  /** Callback that handles closing the workspaces modal. */
  onClose?: () => void;
  /** Translations for all labels and messages within the component. */
  selectorStrings?: IWorkspaceSelectorStrings;
  /** `SharedWorkspace` functionality of current user. */
  sharedWorkspaceFunctionality?: TAllowedFunctionalities;
  isAdvancedWorkspaceEnabled: boolean;
  setIsAdvancedWorkspaceEnabled: (newValue: boolean) => void;
  /**
   * Indicates wether the advanced workspace feature is implemented in the workspace related to
   * current key name.
   */
  hasAdvancedWorkspaceFunctionality?: boolean;
  localSelectedWorkspace?: IWorkspaceSelectorItem;
  setLocalSelectedWorkspace: (newValue: IWorkspaceSelectorItem) => void;
  isLoading?: boolean;
}

const WorkspaceSelector = ({
  hasAdvancedWorkspaceFunctionality,
  hasUnsavedChanges,
  isAdvancedWorkspaceEnabled,
  isLoading,
  items,
  localSelectedWorkspace,
  onAdd,
  onAddFromDefault,
  onAddShared,
  onAddSharedFromDefault,
  onClose,
  onDelete,
  onEditSharePermissions,
  onReset,
  onResetToDefault,
  onSave,
  onSaveAsNewWorkspace,
  onSelectedItemChange,
  selectedItem,
  selectorStrings,
  setIsAdvancedWorkspaceEnabled,
  setLocalSelectedWorkspace,
  sharedWorkspaceFunctionality,
}: IWorkspaceSelectorProps) => {
  const intl = useIntl();

  const handleItemClick = useCallback(
    (item: IWorkspaceSelectorItem) => {
      setLocalSelectedWorkspace(item);
    },
    [setLocalSelectedWorkspace],
  );

  const personalWorkspaces = useMemo(
    () => items.filter((item) => !item.IsShared && item.ID !== DEFAULT_WORKSPACE_ID),
    [items],
  );

  const sharedWorkspaces = useMemo(
    () =>
      sharedWorkspaceFunctionality?.viewFunctionalityAllowed
        ? items.filter((item) => item.IsShared && item.ID !== DEFAULT_WORKSPACE_ID)
        : [],
    [items, sharedWorkspaceFunctionality],
  );

  const defaultWorkspaces = useMemo(
    () => items.filter((item) => item.ID === DEFAULT_WORKSPACE_ID),
    [items],
  );

  return selectedItem ? (
    <>
      <div className="flex h-[calc(90dvh-77px)] flex-col gap-5 overflow-y-auto p-5">
        {defaultWorkspaces.length ? (
          <WorkspaceSelectorSection
            title={intl.formatMessage({
              id: "generic.label.default-workspace",
              defaultMessage: "Default workspace",
            })}
            workspaces={defaultWorkspaces}
            hasUnsavedChanges={hasUnsavedChanges}
            selectedWorkspaceID={selectedItem.ID}
            localSelectedWorkspace={localSelectedWorkspace}
            onWorkspaceClick={handleItemClick}
            onReset={onReset}
            onSaveAsNewWorkspace={onSaveAsNewWorkspace}
            sharedWorkspaceFunctionality={sharedWorkspaceFunctionality}
            isLoading={isLoading}
            selectorStrings={selectorStrings}
          />
        ) : null}

        <WorkspaceSelectorSection
          title={intl.formatMessage({
            id: "generic.label.personal-workspaces",
            defaultMessage: "Personal workspaces",
          })}
          workspaces={personalWorkspaces}
          hasUnsavedChanges={hasUnsavedChanges}
          selectedWorkspaceID={selectedItem.ID}
          localSelectedWorkspace={localSelectedWorkspace}
          onWorkspaceClick={handleItemClick}
          onDeleteWorkspace={onDelete}
          onAdd={onAdd}
          onAddFromDefault={onAddFromDefault}
          onReset={onReset}
          onResetToDefault={onResetToDefault}
          onSave={onSave}
          onSaveAsNewWorkspace={onSaveAsNewWorkspace}
          sharedWorkspaceFunctionality={sharedWorkspaceFunctionality}
          isLoading={isLoading}
          selectorStrings={selectorStrings}
          hideDeleteOnActiveWorkspace
        />

        {!sharedWorkspaceFunctionality?.viewFunctionalityAllowed ? null : (
          <WorkspaceSelectorSection
            title={intl.formatMessage({
              id: "generic.label.shared-workspaces",
              defaultMessage: "Shared workspaces",
            })}
            workspaces={sharedWorkspaces}
            hasUnsavedChanges={hasUnsavedChanges}
            selectedWorkspaceID={selectedItem.ID}
            localSelectedWorkspace={localSelectedWorkspace}
            onWorkspaceClick={handleItemClick}
            onDeleteWorkspace={onDelete}
            onEditSharePermissions={onEditSharePermissions}
            onReset={onReset}
            onResetToDefault={onResetToDefault}
            onAdd={onAddShared}
            onAddFromDefault={onAddSharedFromDefault}
            onSave={onSave}
            onSaveAsNewWorkspace={onSaveAsNewWorkspace}
            sharedWorkspaceFunctionality={sharedWorkspaceFunctionality}
            isLoading={isLoading}
            selectorStrings={selectorStrings}
            hideDeleteOnActiveWorkspace
            addWorkspaceDescriptionMessage={intl.formatMessage({
              id: "workspaces.add-shared-description",
              defaultMessage: "Click the '+' icon to add a shared workspace",
            })}
          />
        )}
      </div>

      <Separator />

      <div className="flex items-center justify-between gap-5 p-5">
        <div className="flex items-center justify-between">
          {hasAdvancedWorkspaceFunctionality ? (
            <Switch
              checked={isAdvancedWorkspaceEnabled}
              onChange={setIsAdvancedWorkspaceEnabled}
              label={intl.formatMessage({
                id: "generic.label.enable-advanced-workspace-settings",
                defaultMessage: "Enable advanced workspace settings",
              })}
              labelClassName="text-legacy-sm"
            />
          ) : null}
        </div>

        <ButtonGroup>
          <Button variant="secondary" onPress={onClose}>
            <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            variant="primary"
            onPress={() => localSelectedWorkspace && onSelectedItemChange(localSelectedWorkspace)}
          >
            <FormattedMessage id="generic.label.select" defaultMessage="Select" />
          </Button>
        </ButtonGroup>
      </div>
    </>
  ) : null;
};

export default WorkspaceSelector;
