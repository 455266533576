import { useIntl } from "react-intl";

import { FieldGroup, NumberField } from "@new-black/lyra";
import { Field, FieldProps } from "formik";
import { isNil } from "lodash";

const MinMaxLength = () => {
  const intl = useIntl();
  return (
    <FieldGroup className="flex-row">
      <Field name="MinimumLength">
        {({ field, form }: FieldProps) => (
          <NumberField
            label={intl.formatMessage({
              id: "custom-field.input.minimum-length.label",
              defaultMessage: "Minimum characters",
            })}
            {...field}
            value={field.value ?? null}
            onChange={(newValue) => {
              if (isNil(newValue) || Number.isNaN(newValue) || newValue < 0) {
                form.setFieldValue("MinimumLength", undefined);
                return;
              }
              form.setFieldValue("MinimumLength", newValue);
            }}
          />
        )}
      </Field>
      <Field name="MaximumLength">
        {({ field, form }: FieldProps) => (
          <NumberField
            label={intl.formatMessage({
              id: "custom-field.input.maximum-length.label",
              defaultMessage: "Maximum characters",
            })}
            {...field}
            value={field.value ?? null}
            onChange={(newValue) => {
              if (isNil(newValue) || Number.isNaN(newValue) || newValue < 0) {
                form.setFieldValue("MaximumLength", undefined);
                return;
              }
              form.setFieldValue("MaximumLength", newValue);
            }}
          />
        )}
      </Field>
    </FieldGroup>
  );
};

export default MinMaxLength;
