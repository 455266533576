export default {
  entityTranslations: {
    addEdit: {
      path: "/resource/entity-translations/add-edit",
    },
    delete: {
      path: "/resource/entity-translations/delete",
    },
  },
  addEditCustomField: {
    add: {
      path: "/resource/add-edit-custom-field/add",
    },
    edit: {
      path: "/resource/add-edit-custom-field/edit/:id",
    },
  },
};
