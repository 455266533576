import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

export const OpenInNewTabActionButton = ({
  className,
  iconClassName,
  isDisabled,
  isLoading,
  onPress,
  small,
  tooltip,
}: IActionButton & { iconClassName?: string; small?: boolean }) => {
  const intl = useIntl();

  return (
    <Button
      variant={small ? "icon-xs" : "icon"}
      tooltip={
        tooltip ??
        intl.formatMessage({
          id: "generic.label.open-in-new-tab",
          defaultMessage: "Open in new tab",
        })
      }
      onPress={onPress}
      className={className}
      isLoading={isLoading}
      isDisabled={isDisabled}
    >
      <SvgIcon name="open-in-new-tab" className={iconClassName} />
    </Button>
  );
};
