import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const organizationUnitDetailedByIdState = builders.buildServiceFamilyState({
  key: "GetOrganizationUnitDetailedByIdState",
  service: Core.GetOrganizationUnitDetailed,
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const organizationUnitDetailedById = selectorFamily({
  key: "GetOrganizationUnitDetailedByIdState:selector",
  get:
    (param) =>
    ({ get }) =>
      get(organizationUnitDetailedByIdState.response(param)),
});
