import type { ButtonHTMLAttributes, ReactNode } from "react";
import { Button, ButtonProps } from "react-aria-components";

import { Text, TextProps } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "classnames";

export interface LinkButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "color"> {
  variant?: "body1" | "body2";
  color?: "blue" | "black";
}

const linkButtonVariants = cva(
  "cursor-pointer border-none bg-transparent p-0 font-sans tracking-[0.4px] focus-visible:rounded-md focus-visible:ring-1",
  {
    variants: {
      color: {
        blue: "text-[color:var(--legacy-eva-color-primary)] hover:text-[color:var(--legacy-eva-color-primary-hover-focus,_#005bbf)] focus:text-[color:var(--legacy-eva-color-primary-hover-focus,_#005bbf)] focus-visible:ring-[color:var(--color-primary-3)]",
        black:
          "text-[color:var(--legacy-eva-color-dark-3,_#212121)] hover:text-[#202020] focus:text-[#202020] focus-visible:ring-[#202020] underline",
      },
    },
  },
);

/**
 * Component that looks like a link, but in reality is a button
 *
 */
const LinkButton = ({
  children,
  className,
  color = "blue",
  variant,
  ...props
}: LinkButtonProps) => (
  <button
    className={classNames(
      linkButtonVariants({ color }),
      variant === "body2" ? "text-xs" : "text-legacy-sm",
      className,
    )}
    {...props}
  >
    {children}
  </button>
);

type LyraLinkButtonProps = Omit<ButtonProps, "children"> & {
  variant?: TextProps["variant"];
  children: ReactNode;
};
export const LyraLinkButton = ({ children, variant, ...props }: LyraLinkButtonProps) => {
  return (
    <Button
      {...props}
      className="cursor-pointer rounded-xs outline-0 focus-visible:ring-1 focus-visible:ring-default"
    >
      <Text variant={variant} className="underline">
        {children}
      </Text>
    </Button>
  );
};

export default LinkButton;
