import { Dispatch, SetStateAction, useState } from "react";
import { useIntl } from "react-intl";

import { Button, SearchField, SvgIcon } from "@new-black/lyra";

interface ISubsetTableFilterProps {
  term: string | undefined;
  setTerm: Dispatch<SetStateAction<string | undefined>>;
}

const SubsetTableFilter = ({ setTerm, term }: ISubsetTableFilterProps) => {
  const intl = useIntl();
  const [isSearchOpen, setIsSearchOpen] = useState<boolean>(false);

  return isSearchOpen ? (
    <SearchField
      autoFocus
      hideInputHeader
      label={intl.formatMessage({
        defaultMessage: "Filter on name or ID",
        id: "ouset-subsets-list.table.actions.search.label",
      })}
      placeholder={intl.formatMessage({
        defaultMessage: "Filter on name or ID",
        id: "ouset-subsets-list.table.actions.search.label",
      })}
      value={term ?? ""}
      onChange={(value) => {
        const newValue = value === "" ? undefined : value;
        if (newValue !== term) {
          setTerm(newValue);
        }
      }}
      onBlur={() => {
        if (!term) {
          setIsSearchOpen(false);
        }
      }}
      className="min-w-[200px]"
    />
  ) : (
    <Button
      variant="icon"
      tooltip={intl.formatMessage({ id: "generic.label.search", defaultMessage: "Search" })}
      onPress={() => setIsSearchOpen(true)}
    >
      <SvgIcon name="search" />
    </Button>
  );
};

export default SubsetTableFilter;
