import { useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";

import { TableColumnDef } from "@new-black/lyra";

import { YesNoStatusLabel } from "components/shared/yes-no-status-label";
import { ProductSummary } from "components/suite-composite/product-summary/lyra";

export const useOwnedProductsTableColumns = () => {
  const intl = useIntl();

  const columns = useMemo<
    TableColumnDef<EVA.Core.Management.ListUserBoughtProductsResponse.BoughtProduct>[]
  >(
    () => [
      {
        id: "ProductID",
        header: intl.formatMessage({ id: "generic.label.product", defaultMessage: "Product" }),
        accessorKey: "ProductID",
        cell: ({ row }: any) => (
          <ProductSummary
            productID={row?.original?.ProductID}
            backendID={row?.original?.ProductBackendID}
            productName={row?.original?.ProductName}
            productImageBlobID={row?.original?.PrimaryImage?.Blob}
            imageSize={38}
          />
        ),
      },
      {
        header: intl.formatMessage({
          id: "generic.label.serial-number",
          defaultMessage: "Serial number",
        }),
        id: "SerialNumber",
        accessorKey: "SerialNumber",
      },
      {
        header: intl.formatMessage({
          id: "generic.label.last-purchase-date",
          defaultMessage: "Last purchase date",
        }),
        accessorKey: "LastPurchaseDate",
        id: "LastPurchaseDate",
        cell: ({ getValue }) => <FormattedDate value={getValue()} />,
      },
      {
        header: intl.formatMessage({
          id: "generic.label.last-purchase-quantity",
          defaultMessage: "Last purchase quantity",
        }),
        accessorKey: "LastPurchasedQuantity",
        id: "LastPurchasedQuantity",
      },
      {
        header: intl.formatMessage({
          id: "generic.label.total-purchased-quantity",
          defaultMessage: "Total purchased quantity",
        }),
        accessorKey: "TotalPurchasedQuantity",
        id: "TotalPurchasedQuantity",
      },
      {
        id: "Warranty",
        accessorKey: "ID",
        header: intl.formatMessage({
          id: "generic.label.warranty",
          defaultMessage: "Warranty",
        }),
        cellType: "badge",
        cell: ({ row }) => <YesNoStatusLabel value={row.original.IsUnderWarranty} />,
      },
    ],
    [intl],
  );
  return columns;
};
