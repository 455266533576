import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useSyncExternalStore,
} from "react";

/**
 * Creates a context that is fast to read from and write to. When using the selectors smartly, you can avoid unnecessary re-renders in the child components.
 * This prevents re-rendering all children of your context provider when the context value changes.
 * ### Usage:
 * ```tsx
 * const { Provider, useStore } = createFastContext({ foo: "bar", baz: "qux" });
 *
 * const Component = () => {
 * return (
 *  <Provider>
 *   <Child type="foo" />
 *   <Child type="baz" />
 * </Provider>
 * )}
 *
 * const Child = ({ type }: { type: "foo" | "baz" }) => {
 * const [value, setValue] = useStore((state) => state[type]);
 *
 * return (
 *  <input value={value} onChange={(e) => setValue(e.target.value)} />
 * )}
 * ```
 * @param initialState
 * @returns { Provider, useStore}
 */
export default function createFastContext<Store>(initialState: Store) {
  // Hook that stores values in a ref and returns a get, set and subscribe function.
  // Using the ref values enables you to only update/rerender the part of the React tree that needs to rerender.
  //
  function useStoreData(): {
    get: () => Store;
    set: (value: Partial<Store>) => void;
    subscribe: (callback: () => void) => () => void;
  } {
    const store = useRef(initialState);

    const get = useCallback(() => store.current, []);

    const subscribers = useRef(new Set<() => void>());

    const set = useCallback((value: Partial<Store>) => {
      store.current = { ...store.current, ...value };
      subscribers.current.forEach((callback) => callback());
    }, []);

    const subscribe = useCallback((callback: () => void) => {
      subscribers.current.add(callback);
      return () => subscribers.current.delete(callback);
    }, []);

    return {
      get,
      set,
      subscribe,
    };
  }

  type UseStoreDataReturnType = ReturnType<typeof useStoreData>;

  // Create the context that can be distributed to by the Provider
  //
  const StoreContext = createContext<UseStoreDataReturnType | null>(null);

  function Provider({ children }: { children: ReactNode }) {
    return <StoreContext.Provider value={useStoreData()}>{children}</StoreContext.Provider>;
  }

  // Hook that can be used like a useState hook, but does read the value and write the value to context.
  //
  function useStore<SelectorOutput>(
    selector: (store: Store) => SelectorOutput,
  ): [SelectorOutput, (value: Partial<Store>) => void] {
    const store = useContext(StoreContext);
    if (!store) {
      throw new Error("No store found. Did you forget to wrap your component in a <Provider />?");
    }

    const state = useSyncExternalStore(store.subscribe, () => selector(store.get()));

    return [state, store.set];
  }

  return {
    Provider,
    useStore,
  };
}
