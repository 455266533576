export enum PaperFormats {
  A3 = 1,
  A4 = 2,
  A5 = 3,
  Legal = 4,
  Letter = 5,
  Tabloid = 6,
  Auto = 7,
  Ledger = 8,
  A0 = 9,
  A1 = 10,
  A2 = 11,
  A6 = 12,
}

export enum PaperOrientations {
  Portrait = 1,
  Landscape = 2,
}
