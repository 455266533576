import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export const EntityTranslationIconButton = ({
  isDisabled,
  onPress,
  small,
  tooltip,
}: {
  tooltip?: string;
  onPress?: () => void;
  isDisabled?: boolean;
  small?: boolean;
}) => {
  const intl = useIntl();

  return (
    <Button
      isDisabled={isDisabled}
      onPress={onPress}
      variant={small ? "icon-xs" : "icon"}
      tooltip={
        tooltip ??
        intl.formatMessage({
          id: "generic.label.entity-translation",
          defaultMessage: "Entity translation",
        })
      }
    >
      <SvgIcon name="translations" />
    </Button>
  );
};
