import { builders } from "@springtree/eva-sdk-react-recoil";
import { Blobs } from "@springtree/eva-services-blobs";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const listBlobsForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  key: "Core.ListBlobsForInvoice",
  service: Core.ListBlobsForInvoice,
});

export const getBlobInformationServiceState = builders.buildServiceFamilyState({
  beforeRequest: ({ parameter, req }) => {
    if (typeof parameter !== "string") {
      return req;
    }

    return {
      ...req,
      Guid: parameter,
    };
  },
  requireLoggedIn: true,
  allowEmptyRequest: false,
  key: "Core.GetBlobInfo",
  service: Blobs.GetBlobInfo,
});

export const listBlobsForInvoice = selectorFamily({
  key: "Core.ListBlobsForInvoice:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(listBlobsForInvoiceServiceState.response(parameter));

      const blobIds = response?.Result?.map((blob) => blob.BlobID) ?? [];

      const blobInfos = blobIds.map((blobId) =>
        get(getBlobInformationServiceState.response(blobId)),
      );

      return response?.Result.map((blob) => {
        const blobInfo = blobInfos.find((info) => info?.Guid === blob.BlobID);
        return {
          ...blob,
          Name: blobInfo?.OriginalName ?? blob.Name,
        };
      });
    },
});
