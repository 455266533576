import { useState } from "react";
import { useIntl } from "react-intl";

import { Card, CardActions, CardHeader, CardProps, Table } from "@new-black/lyra";

import { DeleteModal } from "components/suite-composite/confirmation-modal/delete-modal";
import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";

import { CustomFieldOptionsCreateAction } from "./helpers/custom-field-settings-table-actions";
import useCustomFieldOptions from "./hooks/use-custom-field-options";
import useCustomFieldSettingsTableConfig from "./hooks/use-custom-field-settings-table-config";
import { CustomFieldSettingsModal } from "./custom-field-settings-modal";

export const CustomFieldSettings = ({
  customFieldId,
  variant = "secondary",
}: {
  customFieldId?: number;
  variant?: CardProps["variant"];
}) => {
  const intl = useIntl();
  const [optionToDelete, setOptionToDelete] = useState<
    EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
  >(undefined);

  const [isAddEditOpen, setIsAddEditOpen] = useState(false);
  const [optionToEdit, setOptionToEdit] = useState<
    EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
  >(undefined);

  const { handleUnset, isUnsetLoading, tableConfig } = useCustomFieldSettingsTableConfig({
    customFieldId,
    optionToDelete,
    setOptionToEdit,
    setIsAddEditOpen,
    setOptionToDelete,
  });

  useHandleSWRFamily(useCustomFieldOptions.service.serviceState, customFieldId);

  return (
    <>
      <Card variant={variant} className="overflow-hidden">
        <CardHeader
          title={intl.formatMessage({ id: "generic.label.settings", defaultMessage: "Settings" })}
          actions={
            <CardActions>
              <CustomFieldOptionsCreateAction handleAdd={() => setIsAddEditOpen(true)} />
            </CardActions>
          }
        />

        <Table {...tableConfig} options={{ ...tableConfig.options, backgroundColor: variant }} />
      </Card>

      <CustomFieldSettingsModal
        isOpen={isAddEditOpen}
        optionToEdit={optionToEdit}
        setIsOpen={setIsAddEditOpen}
        customFieldId={customFieldId}
        setOptionToEdit={setOptionToEdit}
      />
      <DeleteModal
        onConfirm={handleUnset}
        isOpen={!!optionToDelete}
        isLoading={isUnsetLoading}
        onCancel={() => setOptionToDelete(undefined)}
      />
    </>
  );
};
