import { ComponentPropsWithRef, MouseEvent, useEffect, useState } from "react";
import { Controlled as ControlledImageZoom } from "react-medium-image-zoom";

import NoIamge from "assets/images/no_image.svg?react";
import usePrevious from "hooks/suite-react-hooks/use-previous";

export interface ImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
  enlarge?: boolean;
  srcBig?: string;
  onZoomEvent?: () => boolean;
  onError?: ComponentPropsWithRef<"img">["onError"];
  errorFallbackSrc?: string;
  onClick?: (e: MouseEvent<HTMLImageElement>) => void;
}

const Image = ({
  enlarge = true,
  errorFallbackSrc,
  onClick,
  onError,
  onZoomEvent,
  srcBig,
  ...props
}: ImageProps) => {
  const { alt, className, src, style, title } = props;
  const [isZoomed, setIsZoomed] = useState(false);
  const [errorFetching, setErrorFetching] = useState(false);

  const previousSrc = usePrevious(src);

  // reset errorFetching state when src changes
  useEffect(() => {
    if (src !== previousSrc) {
      setErrorFetching(false);
    }
  }, [previousSrc, src]);

  if (enlarge && !errorFetching && src !== "placeholder") {
    return (
      <ControlledImageZoom
        isZoomed={isZoomed}
        onZoomChange={(value) => {
          if (onZoomEvent) {
            setIsZoomed(onZoomEvent() && value);
          } else {
            setIsZoomed(value);
          }
        }}
        zoomImg={srcBig ? { src: srcBig, alt } : undefined}
        zoomMargin={100}
      >
        <img
          src={src}
          alt={alt}
          className={className}
          style={{ width: "100%", display: "block", ...style }}
          onError={(e) => {
            onError?.(e);
            setErrorFetching(true);
          }}
        />
      </ControlledImageZoom>
    );
  }

  return !errorFetching && src !== "no-image" ? (
    <img
      title={title}
      src={errorFetching && errorFallbackSrc ? errorFallbackSrc : src}
      alt={alt ?? "image"}
      className={className}
      style={{ display: "block", ...style }}
      onError={(e) => {
        onError?.(e);
        setErrorFetching(true);
      }}
      onClick={onClick}
    />
  ) : (
    <NoIamge title={title} className={className} style={{ display: "block", ...style }} />
  );
};

export default Image;
