import { useCallback, useState } from "react";

import { z } from "zod";

import useEventListener from "hooks/suite-react-hooks/use-event-listener";
import { SafeLocalStorage } from "hooks/suite-react-hooks/use-local-storage";
import { localStorageKeys } from "types/local-storage";

const editorThemeValidationSchema = z.enum(["light", "vs-dark"]);

const getStorageEditorTheme = (defaultValue: "light" | "vs-dark" = "light") =>
  SafeLocalStorage.get(localStorageKeys.EditorTheme, defaultValue, editorThemeValidationSchema);

const setStorageEditorTheme = (newValue: "light" | "vs-dark") =>
  SafeLocalStorage.set(localStorageKeys.EditorTheme, newValue, editorThemeValidationSchema);

export const useEditorTheme = () => {
  const [editorTheme, _setEditorTheme] = useState<"light" | "vs-dark">(getStorageEditorTheme());

  // Listen on storage events for changes on `editorTheme`
  useEventListener("storage", (event: StorageEvent) => {
    if (event.key === localStorageKeys.EditorTheme && event.newValue !== editorTheme) {
      _setEditorTheme(getStorageEditorTheme());
    }
  });

  const setEditorTheme = useCallback((newValue: "light" | "vs-dark") => {
    setStorageEditorTheme(newValue);

    // Dispatching the event is required since the storage listeners are triggered only when the storage is modified in the
    // context of another document (so it won't work if we update the storage from the current application instance itself)
    window.dispatchEvent(
      new StorageEvent("storage", { key: localStorageKeys.EditorTheme, newValue }),
    );
  }, []);

  return { editorTheme, setEditorTheme };
};
