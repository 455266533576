export type IWatchtowerDevice = EVA.Core.LiveGuard.ListWatchtowerDevicesResponse.Watchtower;

export type IWatchtowerDeviceNetworkInterface =
  EVA.Core.LiveGuard.WatchtowerGetDeviceDetailsResponse.NetworkInterface;

export const enum FleetManagerDeviceStatus {
  Accepted = 1,
  Pending = 2,
  Rejected = 3,
  Preauthorized = 4,
  Noauth = 5,
}
