import { Dispatch, SetStateAction, useState } from "react";

export const StatefulParent = <T,>({
  children,
  defaultValue,
}: {
  defaultValue: T;
  children: (props: { value: T; setValue: Dispatch<SetStateAction<T>> }) => JSX.Element;
}) => {
  const [value, setValue] = useState<T>(defaultValue);
  return <>{children({ value, setValue })}</>;
};
