import { useCallback, useMemo } from "react";

import moment, { Moment } from "moment";

import { IBaseCalendarItem } from "../types";

interface IUseCalendarItemColumns {
  displayedDays: Moment[];
  item: IBaseCalendarItem;
  snapToFullDay?: boolean;
}

/**
 * Hook that computes the start and end column of an item in the calendar grid
 */
const useCalendarItemColumns = ({
  displayedDays,
  item,
  snapToFullDay,
}: IUseCalendarItemColumns) => {
  // the column for a date is based on the index of that date in the displayedDays array
  // after we find that index, we need to increase it by 1 to get the grid column (css grid columns are indexed from 1 unlike arrays)
  // and if we're working with an end date we want the item to also cover the column of the end day,
  // so we need to add 1 to the index once more so the end column is the next column after the column of the end date
  const getColumnForDate = useCallback(
    (date: Moment, type: "end" | "start") => {
      const offset = type === "end" ? 2 : 1;

      // handle dates before the first displayed day
      if (date.isBefore(displayedDays[0])) {
        return 1; // start of first column
      }

      // handle dates after the last displayed day
      if (date.isAfter(moment(displayedDays[displayedDays.length - 1]).endOf("day"))) {
        return displayedDays.length + 1; // end of last column
      }

      if (!snapToFullDay) {
        const dayIndex = displayedDays.findIndex(
          (day, index) => date.isSameOrAfter(day) && date.isBefore(displayedDays[index + 1]),
        );

        return dayIndex + offset;
      }

      // when snapping to full days, midnights need to be handled differently for start vs end dates
      // for example we don't want to cover the column of January 3rd if the item ends at January 3rd at midnight,
      // but we want to cover it if the item starts on January 3rd at midnight
      const dayIndex =
        type === "end"
          ? displayedDays.findIndex(
              (day, index) => date.isAfter(day) && date.isSameOrBefore(displayedDays[index + 1]),
            )
          : displayedDays.findIndex(
              (day, index) => date.isSameOrAfter(day) && date.isBefore(displayedDays[index + 1]),
            );

      return offset + dayIndex;
    },
    [displayedDays, snapToFullDay],
  );

  const startColumn = useMemo(
    () => (item.StartDate ? getColumnForDate(item.StartDate, "start") : 1),
    [getColumnForDate, item.StartDate],
  );

  const endColumn = useMemo(
    () => (item.EndDate ? getColumnForDate(item.EndDate, "end") : displayedDays.length + 1),
    [displayedDays.length, getColumnForDate, item.EndDate],
  );

  return {
    startColumn,
    endColumn,
  };
};

export default useCalendarItemColumns;
