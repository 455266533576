import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const listShipmentLinesToInvoice = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:ListShipmentLinesToInvoice",
  allowEmptyRequest: false,
  service: Core.ListShipmentLinesToInvoice,
});

export default listShipmentLinesToInvoice;
