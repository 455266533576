import { useCallback, useMemo } from "react";

import { ServiceQueryWithRequest } from "types/query.types";

interface IMinimalRequest {
  InitialPageConfig?: { Limit?: number };
}

interface IMinimalResponse<T> {
  Results: T[];
  NextPageToken?: string;
  PreviousPageToken?: string;
}

type UncountableServiceQuery<Item> = ServiceQueryWithRequest<
  IMinimalRequest | undefined,
  IMinimalResponse<Item> | undefined
>;

export interface IUncountableTablePaginationProps<Item> {
  data: Item[];
  limit: number;
  setLimit: (newLimit: number) => void;
  onNext: () => void;
  showNext: boolean;
  onPrevious: () => void;
  showPrevious: boolean;
  isLoading?: boolean;
}

/**
 * This hook can only be used when a serviceQueryWithRequest is available
 * @param serviceQueryWithRequest
 * @param defaultInitialPageConfig
 * @param includeLoading If you want to include the loading state, you can choose between "isLoading" and "isLoadingWithoutPreviousResponse" or if you don't want to include it, you can leave it `undefined`. (`undefined` by default for backwards compatibility)
 */
export const useUncountablePaginationHelper = <Item>(
  serviceQueryWithRequest: UncountableServiceQuery<Item>,
  defaultInitialPageConfig?: IMinimalRequest,
  includeLoading?: "isLoading" | "isLoadingWithoutPreviousResponse",
) => {
  const setRequest = useMemo(
    () => serviceQueryWithRequest.setRequest,
    [serviceQueryWithRequest.setRequest],
  );

  const data = useMemo(
    () => serviceQueryWithRequest.queryResult.data?.Results ?? [],
    [serviceQueryWithRequest.queryResult.data?.Results],
  );

  const isLoading = useMemo(() => {
    if (!includeLoading) {
      return undefined;
    }
    return includeLoading === "isLoading"
      ? serviceQueryWithRequest?.queryResult.isFetching
      : serviceQueryWithRequest?.queryResult.isLoading;
  }, [
    includeLoading,
    serviceQueryWithRequest?.queryResult.isFetching,
    serviceQueryWithRequest?.queryResult.isLoading,
  ]);

  const limit = useMemo(
    () =>
      serviceQueryWithRequest.request?.InitialPageConfig?.Limit ??
      defaultInitialPageConfig?.InitialPageConfig?.Limit!,
    [
      defaultInitialPageConfig?.InitialPageConfig?.Limit,
      serviceQueryWithRequest.request?.InitialPageConfig?.Limit,
    ],
  );

  const setLimit = useCallback(
    (newLimit: number) =>
      setRequest((current) => ({
        ...current,
        InitialPageConfig: { ...current?.InitialPageConfig, Limit: newLimit },
        PageToken: undefined,
      })),
    [setRequest],
  );

  const nextPageToken = useMemo(
    () => serviceQueryWithRequest.queryResult.data?.NextPageToken,
    [serviceQueryWithRequest.queryResult.data?.NextPageToken],
  );

  const onNext = useCallback(
    () => setRequest((current) => ({ ...current, PageToken: nextPageToken })),
    [nextPageToken, setRequest],
  );

  const showNext = useMemo(() => !!nextPageToken, [nextPageToken]);

  const previousPageToken = useMemo(
    () => serviceQueryWithRequest.queryResult.data?.PreviousPageToken,
    [serviceQueryWithRequest.queryResult.data?.PreviousPageToken],
  );

  const onPrevious = useCallback(
    () =>
      setRequest((current) => ({
        ...current,
        PageToken: previousPageToken,
      })),
    [previousPageToken, setRequest],
  );

  const showPrevious = useMemo(() => !!previousPageToken, [previousPageToken]);

  return {
    data,
    limit,
    isLoading,
    setLimit,
    onNext,
    showNext,
    onPrevious,
    showPrevious,
  };
};
