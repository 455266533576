import {
  ChangeEvent,
  CSSProperties,
  FocusEventHandler,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  MenuItem as MuiMenuItem,
  Select as MuiSelect,
  SelectProps as MuiSelectProps,
} from "@material-ui/core";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/InputLabel";
import { SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import Chip from "../chip";
import IconButton from "../icon-button";
import type { InputProps } from "../input";
import Input from "../input";

import { StyledFormControl } from "./styled-form-control";

export interface SelectProps {
  name: string;
  label?: string;
  fullWidth?: boolean;
  autoWidth?: boolean;
  autoFocus?: boolean;
  displayEmpty?: boolean;
  disabled?: boolean;
  passive?: boolean;
  items: MenuItemProps[];
  helpertext?: string;
  size?: number;
  error?: boolean;
  warning?: boolean;
  small?: boolean;
  multiple?: boolean;
  required?: boolean;
  variant?: MuiSelectProps["variant"];
  onBlur?: FocusEventHandler<any>;
  onChange?: (event: ChangeEvent<{ name?: string; value: unknown }>, child: ReactNode) => void;
  onClear?: () => void;
  onClose?: (event: ChangeEvent<unknown>) => void;
  onFocus?: FocusEventHandler<any>;
  onOpen?: (event: ChangeEvent<unknown>) => void;
  open?: boolean;
  value?: any;
  defaultValue?: any;
  className?: string;
  placeholder?: string;
  style?: CSSProperties;
  formID?: string;
  inputRef?: ((instance: any) => void) | React.RefObject<any> | null | undefined;
  endIcon?: ReactNode;
}

export interface MenuItemProps<T = string | number> {
  value: T;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
}

const Select = ({
  autoFocus,
  autoWidth,
  disabled,
  displayEmpty,
  endIcon,
  error,
  formID,
  fullWidth = true,
  helpertext,
  inputRef,
  items,
  label,
  multiple,
  name,
  onClear,
  passive,
  required,
  small,
  value,
  variant = "standard",
  warning,
  ...props
}: SelectProps) => {
  const [focused, setFocused] = useState(false);
  const [, setUncontrolledValue] = useState(value);

  useEffect(() => {
    setUncontrolledValue(value);
  }, [value]);

  const displayEndAdornmentClearIcon = useMemo(
    () =>
      ((Array.isArray(value) ? value?.length : value) || value === 0) &&
      !passive &&
      !disabled &&
      onClear,
    [disabled, onClear, passive, value],
  );

  return (
    <>
      {
        /** passive field with maximum one value so can render a passive Input component */
        passive && (!multiple || !value.length) ? (
          <Input
            passive={passive}
            label={label}
            required={required}
            error={error}
            warning={warning}
            helperText={helpertext}
            fullWidth={fullWidth}
            autoFocus={autoFocus}
            small={small}
            value={items?.find((item) => item.value === value)?.label ?? ""}
            {...(props as InputProps)}
          />
        ) : (
          <StyledFormControl
            fullWidth={fullWidth}
            error={!!error}
            warning={warning}
            small={small}
            disabled={disabled}
            passive={passive}
            multi={multiple}
          >
            <InputLabel
              id={name}
              className={classNames(
                !!warning && "!text-[color:var(--legacy-eva-color-red-orange)] ",
              )}
            >
              {required ? `${label} *` : label}
            </InputLabel>
            <MuiSelect
              inputRef={inputRef}
              inputProps={{
                form: formID,
                style: {
                  paddingBottom: multiple ? "6px" : undefined,
                },
                readOnly: passive,
              }}
              disableUnderline={passive}
              autoFocus={autoFocus}
              fullWidth={fullWidth}
              disabled={disabled}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
              variant={variant}
              name={name}
              required={required}
              autoWidth={autoWidth}
              displayEmpty={displayEmpty}
              multiple={multiple}
              labelId={name}
              className={classNames(
                "[&_MuiInput-underline]:hover:not(.Mui-disabled):before:!border-b-0",
              )}
              IconComponent={passive ? "span" : undefined}
              endAdornment={
                displayEndAdornmentClearIcon || !!endIcon ? (
                  <span className={classNames("absolute right-6 flex items-center gap-2")}>
                    {displayEndAdornmentClearIcon ? (
                      <IconButton
                        size="small"
                        onClick={(event) => {
                          event.stopPropagation();
                          onClear?.();
                        }}
                      >
                        <SvgIcon name="alert-error" className={small ? undefined : "h-5 w-5"} />
                      </IconButton>
                    ) : null}
                    {endIcon}
                  </span>
                ) : undefined
              }
              renderValue={(selected: any) => {
                if (multiple) {
                  const mappedItems = items.filter((item) => selected.includes(item.value));
                  return (
                    <div className="flex flex-wrap">
                      {mappedItems.map((val, index) => (
                        <span key={index} className="p-[4px_8px_0px_0px]">
                          <Chip
                            color={focused && !passive ? "primary" : "default"}
                            classNames={{ parent: "flex items-center gap-1" }}
                          >
                            <span className="whitespace-normal">{val.label}</span>
                            {val.icon || null}
                          </Chip>
                        </span>
                      ))}
                    </div>
                  );
                }

                const item = items.find((item) => item.value === selected);

                return item?.icon ? (
                  <div className="flex w-full items-center justify-between">
                    <span>{item?.label ?? ""}</span>
                    {item?.icon}
                  </div>
                ) : (
                  item?.label
                );
              }}
              value={value}
              {...props}
            >
              {items && items.length
                ? items.map((menuItem, index) => (
                    <MuiMenuItem
                      key={index}
                      style={{
                        fontSize: props.size ? `${props.size}px` : "16px",
                      }}
                      value={menuItem.value}
                      disabled={menuItem.disabled}
                      className="w-full !justify-start !px-4 !py-2 !text-left"
                    >
                      {menuItem.icon ? (
                        <div className="flex w-full items-center justify-between">
                          <span>{menuItem.label ?? ""}</span>
                          {menuItem.icon}
                        </div>
                      ) : (
                        menuItem.label
                      )}
                    </MuiMenuItem>
                  ))
                : null}
            </MuiSelect>
            {/* <input
              type="hidden"
              name={name}
              value={uncontrolledValue}
              onChange={setUncontrolledValue}
            /> */}
            {/** Show hint or error message */}
            {helpertext ? (
              <FormHelperText
                error={error}
                id={name}
                className={classNames(
                  !!warning && "text-[color:var(--legacy-eva-color-red-orange)]",
                )}
              >
                {helpertext}
              </FormHelperText>
            ) : null}
          </StyledFormControl>
        )
      }
    </>
  );
};

Select.mapArrayToSelectItems = <T, SelectValue = number | string>(
  items: T[],
  mapperFn: (item: T) => MenuItemProps<SelectValue>,
) => items.map(mapperFn);

Select.useMapArrayToSelectItems = <T, SelectValue = number | string>(
  items: T[],
  mapperFn: (item: T) => MenuItemProps<SelectValue>,
) =>
  useMemo(() => Select.mapArrayToSelectItems<T, SelectValue>(items, mapperFn), [items, mapperFn]);

export default Select;
