import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Field, FieldProps } from "formik";

import Input from "components/suite-ui/input";

import useProductSearchTemplates from "../../hooks/use-product-search-templates";

const ProductSearchTemplateField = () => {
  const intl = useIntl();

  const { productSearchTemplates, productSearchTemplatesResponseLoading } =
    useProductSearchTemplates();

  return (
    <Field name="templateID">
      {({ field, form, meta }: FieldProps) => (
        <>
          <Autocomplete
            key="search-product-search-template"
            value={
              productSearchTemplates?.find((template) => template.ID === field.value)?.ID ?? null
            }
            options={productSearchTemplates?.map((template) => template.ID) ?? []}
            loading={productSearchTemplatesResponseLoading}
            onChange={(_, newValue) => {
              form.setFieldValue("templateID", newValue);
            }}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option ?? "";
              }
              // Regular option
              return productSearchTemplates?.find((template) => template.ID === option)?.Name ?? "";
            }}
            getOptionSelected={(option, value) => option === value}
            id="search-product-search-template"
            autoComplete
            includeInputInList
            handleHomeEndKeys
            clearOnBlur
            selectOnFocus
            autoHighlight
            renderInput={(params) => (
              <Input
                {...params}
                label={intl.formatMessage({
                  id: "product-filter.modal.prefill.product-search-template",
                  defaultMessage: "Product search template",
                })}
                error={!!meta.error && meta.touched}
                helperText={!!meta.error && meta.touched ? meta.error : undefined}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {productSearchTemplatesResponseLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </>
      )}
    </Field>
  );
};

export default ProductSearchTemplateField;
