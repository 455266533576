import { useCallback, useMemo } from "react";

import libPhoneNumber, { getExampleNumber } from "libphonenumber-js";
import examples from "libphonenumber-js/mobile/examples";

import { getPhoneNumberWithoutPrefix } from "./helpers";
import { ICountryPhoneCode } from "./types";

export const usePhoneNumberSelectorHelpers = (
  value: string,
  setValue: (newValue?: string) => void,
  countryPhoneCode?: ICountryPhoneCode,
) => {
  const isPhoneNumberValid = useMemo(
    () => (value ? libPhoneNumber(value, countryPhoneCode?.country_code)?.isValid() : true),
    [countryPhoneCode?.country_code, value],
  );

  const phoneNumberPlaceholder = useMemo(() => {
    if (!countryPhoneCode) {
      return undefined;
    }

    const exampleNumber = getExampleNumber(countryPhoneCode?.country_code, examples);

    return exampleNumber
      ? getPhoneNumberWithoutPrefix(
          exampleNumber?.format("INTERNATIONAL"),
          countryPhoneCode?.phone_code,
        )
      : undefined;
  }, [countryPhoneCode]);

  const suggestedFormattedNumber = useMemo(() => {
    if (!countryPhoneCode?.phone_code || !value || !isPhoneNumberValid) {
      return undefined;
    }

    const formattedNumber = libPhoneNumber(value, countryPhoneCode?.country_code)?.format(
      "INTERNATIONAL",
    );

    return formattedNumber &&
      getPhoneNumberWithoutPrefix(formattedNumber, countryPhoneCode?.phone_code) !==
        getPhoneNumberWithoutPrefix(value, countryPhoneCode?.phone_code)
      ? formattedNumber
      : undefined;
  }, [value, isPhoneNumberValid, countryPhoneCode?.country_code, countryPhoneCode?.phone_code]);

  const onAutoFormatNumber = useCallback(
    () =>
      suggestedFormattedNumber && countryPhoneCode?.phone_code
        ? setValue(
            getPhoneNumberWithoutPrefix(suggestedFormattedNumber, countryPhoneCode?.phone_code),
          )
        : undefined,
    [countryPhoneCode?.phone_code, setValue, suggestedFormattedNumber],
  );

  return {
    isPhoneNumberValid,
    phoneNumberPlaceholder,
    suggestedFormattedNumber,
    onAutoFormatNumber,
  };
};
