import { Checkbox, RadioCheckbox } from "@new-black/lyra";

import { useProductSearchModalContext } from "../../product-search-modal-context";
import type { IProductSearchModalResultBase } from "../../types";

export function ProductSearchModalProductResultsListItemCheckbox<
  TProduct extends IProductSearchModalResultBase,
>({ product }: { product: TProduct }) {
  const {
    isProductSelectable,
    selectedProductResults,
    selectedProducts,
    setSelectedProductResults,
    setSelectedProducts,
    type,
  } = useProductSearchModalContext<TProduct>();

  if (type === "multi") {
    return (
      <Checkbox
        isSelected={selectedProductResults.some((x) => x.product_id === product.product_id)}
        onChange={(checked) => {
          if (checked) {
            setSelectedProductResults([...selectedProductResults, product]);
          } else {
            setSelectedProductResults(
              selectedProductResults.filter((x) => x.product_id !== product.product_id),
            );
          }
        }}
        isDisabled={
          selectedProducts.some(
            (selectedProduct) => selectedProduct.product_id === product.product_id,
          ) || !(isProductSelectable ?? (() => true))(product)
        }
      />
    );
  }

  return (
    <RadioCheckbox
      isSelected={
        selectedProducts.some((x) => x.product_id === product.product_id) ||
        !(isProductSelectable ?? (() => true))(product)
      }
      onChange={(checked) => {
        if (checked) {
          setSelectedProducts([product]);
          return;
        }
        setSelectedProducts([]);
      }}
    />
  );
}
