import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Card, CardContent, Menu, MenuTrigger, Separator } from "@new-black/lyra";

import AddActionButton from "components/shared/action-buttons/add-action-button";
import LoadingStateBox from "components/shared/loading-state-box";
import ExpanseCard from "components/suite-ui/expanse-card";
import Text from "components/suite-ui/text";
import { TAllowedFunctionalities } from "hooks/suite-react-hooks/use-allowed-functionalities";

import { IWorkspaceSelectorItem, IWorkspaceSelectorStrings } from "./workspace-selector";
import WorkspaceSelectorItem from "./workspace-selector-item";
import { WorkspaceSelectorItemActions } from "./workspace-selector-item-actions";

export const WorkspaceSelectorSection = ({
  addWorkspaceDescriptionMessage,
  hasUnsavedChanges,
  hideDeleteOnActiveWorkspace,
  isLoading,
  localSelectedWorkspace,
  onAdd,
  onAddFromDefault,
  onDeleteWorkspace,
  onEditSharePermissions,
  onReset,
  onResetToDefault,
  onSave,
  onSaveAsNewWorkspace,
  onWorkspaceClick,
  selectedWorkspaceID,
  selectorStrings,
  sharedWorkspaceFunctionality,
  title,
  workspaces,
}: {
  title: string;
  workspaces: IWorkspaceSelectorItem[];
  selectedWorkspaceID: IWorkspaceSelectorItem["ID"];
  localSelectedWorkspace?: IWorkspaceSelectorItem;
  hasUnsavedChanges: boolean;
  sharedWorkspaceFunctionality?: TAllowedFunctionalities;
  onReset?: () => void;
  onResetToDefault?: () => void;
  onSave?: (workspace: IWorkspaceSelectorItem) => void;
  onSaveAsNewWorkspace?: (workspace: IWorkspaceSelectorItem) => void;
  onAdd?: () => void;
  onAddFromDefault?: () => void;
  onEditSharePermissions?: (workspace: IWorkspaceSelectorItem) => void;
  onWorkspaceClick: (workspace: IWorkspaceSelectorItem) => void;
  onDeleteWorkspace?: (workspace: IWorkspaceSelectorItem) => void;
  isLoading?: boolean;
  selectorStrings?: IWorkspaceSelectorStrings;
  addWorkspaceDescriptionMessage?: string;
  hideDeleteOnActiveWorkspace?: boolean;
}) => {
  const intl = useIntl();

  const addWorkspaceItems = useMemo(() => {
    const result = [];

    if (onAdd) {
      result.push({
        id: "onAdd",
        name: intl.formatMessage({
          id: "generic.label.create-workspace-from-current-layout",
          defaultMessage: "Create workspace from current layout",
        }),
        action: onAdd,
      });
    }

    if (onAddFromDefault) {
      result.push({
        id: "onAddFromDefault",
        name: intl.formatMessage({
          id: "generic.label.create-default-workspace",
          defaultMessage: "Create default workspace",
        }),
        action: onAddFromDefault,
      });
    }

    return result;
  }, [intl, onAdd, onAddFromDefault]);

  const canDeleteWorkspace = useCallback(
    (item: IWorkspaceSelectorItem) => {
      if (item.ID === selectedWorkspaceID && hideDeleteOnActiveWorkspace) {
        return false;
      }

      if (item.IsShared && !sharedWorkspaceFunctionality?.deleteFunctionalityAllowed) {
        return false;
      }

      return true;
    },
    [
      hideDeleteOnActiveWorkspace,
      selectedWorkspaceID,
      sharedWorkspaceFunctionality?.deleteFunctionalityAllowed,
    ],
  );

  return (
    <div className="flex list-none flex-col gap-2">
      <Text variant="h3" className="text-legacy-lg pb-2 leading-6">
        {title}
      </Text>

      {isLoading ? (
        <LoadingStateBox
          limit={workspaces.length || 1}
          componentClassNames={{ skeletonRow: "!px-0", container: "!py-0" }}
        />
      ) : (
        workspaces.map((item) => (
          <Card key={item.ID} variant="secondary" className="!mb-0">
            <WorkspaceSelectorItem
              label={item.Name}
              isSelected={localSelectedWorkspace?.ID === item.ID}
              hasUnsavedChanges={selectedWorkspaceID === item.ID && hasUnsavedChanges}
              isCurrentWorkspace={selectedWorkspaceID === item.ID}
              onClick={() => onWorkspaceClick(item)}
              onDelete={
                canDeleteWorkspace(item) &&
                onDeleteWorkspace &&
                localSelectedWorkspace?.ID !== item.ID
                  ? () => onDeleteWorkspace(item)
                  : undefined
              }
            />

            <ExpanseCard
              disableInitialAnimation
              componentClassNames={{ expanseCard: "border-0 !mb-0" }}
              variant="secondary"
              initialOpen={selectedWorkspaceID === item.ID}
              open={localSelectedWorkspace?.ID === item.ID}
              withoutContentPadding
            >
              <div className="px-5">
                <Separator />
              </div>

              <div className="py-2">
                <WorkspaceSelectorItemActions
                  workspaceItem={item}
                  hasUnsavedChanges={selectedWorkspaceID === item.ID ? hasUnsavedChanges : true}
                  onEditSharePermissions={onEditSharePermissions}
                  onReset={item.ID === selectedWorkspaceID ? onReset : undefined}
                  onResetToDefault={item.ID === selectedWorkspaceID ? onResetToDefault : undefined}
                  onSaveAsNewWorkspace={onSaveAsNewWorkspace}
                  onSave={onSave}
                  sharedWorkspaceFunctionality={sharedWorkspaceFunctionality}
                  selectorStrings={selectorStrings}
                  onDelete={
                    canDeleteWorkspace(item) &&
                    onDeleteWorkspace &&
                    localSelectedWorkspace?.ID === item.ID
                      ? () => onDeleteWorkspace(item)
                      : undefined
                  }
                />
              </div>
            </ExpanseCard>
          </Card>
        ))
      )}

      {addWorkspaceItems?.length ? (
        <Card
          variant="primary"
          className="h-[56px] border border-dashed border-[color:var(--color-neutral-4)] "
        >
          <CardContent className="flex h-full items-center justify-between pb-0">
            <div className="w-[34px]" />

            <Text variant="body2">
              {addWorkspaceDescriptionMessage || (
                <FormattedMessage
                  id="workspaces.add-description"
                  defaultMessage="Click the '+' icon to add a workspace"
                />
              )}
            </Text>

            <MenuTrigger>
              <AddActionButton />
              <Menu
                aria-label="Add workspace options"
                items={addWorkspaceItems}
                getItemId={(item) => item.id}
                selectRenderElements={(item) => ({ label: item.name })}
                onAction={(id) => addWorkspaceItems.find((item) => item.id === id)?.action()}
              />
            </MenuTrigger>
          </CardContent>
        </Card>
      ) : null}
    </div>
  );
};
