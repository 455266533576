import { ReactNode } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup, Card } from "@new-black/lyra";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import { IEVAFilterArrayItem } from "components/suite-composite/product-filter/hooks/use-convert-filter-to-array";
import { LyraProductFilterTable } from "components/suite-composite/product-filter/lyra-table/product-filter-table";
import { LyraProductOverviewAction } from "components/suite-composite/product-filter/lyra-table/product-overview-action";

import { LoyaltyProgramProductLimitationProductFiltersCardAddAction } from "./loyalty-program-product-limitation-product-filters-card-add-action";

export const LoyaltyProgramProductLimitationDataProductFiltersCard = ({
  customRowActions,
  filters,
  onAdd,
  onAddProductsBulk,
  onDelete,
}: {
  filters: EVA.Core.Management.LoyaltyProgramProductLimitationData["Filters"];
  onAdd?: () => void;
  onAddProductsBulk?: () => void;
  onDelete?: () => void;
  customRowActions?: (row: IEVAFilterArrayItem) => ReactNode;
}) => {
  const intl = useIntl();

  return (
    <Card>
      <LyraProductFilterTable
        title={intl.formatMessage({
          id: "generic.label.product-filters",
          defaultMessage: "Product filters",
        })}
        productFilter={filters}
        disableAdd
        disableEdit
        disableDelete
        customTableActions={
          <ButtonGroup>
            <LyraProductOverviewAction />

            {onDelete || onAdd || onAddProductsBulk ? (
              <>
                {onDelete ? <DeleteActionButton onPress={onDelete} /> : null}

                {onAdd || onAddProductsBulk ? (
                  <LoyaltyProgramProductLimitationProductFiltersCardAddAction
                    onAddProductFilters={onAdd}
                    onAddProductsBulk={onAddProductsBulk}
                  />
                ) : null}
              </>
            ) : null}
          </ButtonGroup>
        }
        customRowActions={customRowActions}
      />
    </Card>
  );
};
