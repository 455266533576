import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Separator } from "@new-black/lyra";
import { Form, Formik } from "formik";

import usePrefillTabValidationSchema from "../../hooks/use-prefill-tab-validation-schema";
import useProductSearchTemplates from "../../hooks/use-product-search-templates";
import { useProductFilterContext } from "../../product-filter-context";
import {
  PropertyClashConfirmationModal,
  usePropertyClashConfirmationModalState,
} from "../../property-clash-confirmation-modal";

import ProductSearchTemplateField from "./product-search-template-field";

const DialogTabPrefill = () => {
  const intl = useIntl();
  const {
    isUpdateProductFilterLoading,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();

  const { productSearchTemplates } = useProductSearchTemplates();

  const { propertyClashConfirmationModalState, setPropertyClashConfirmationModalState } =
    usePropertyClashConfirmationModalState();

  const initialValues = useMemo(() => ({ templateID: undefined }), []);
  const validationSchema = usePrefillTabValidationSchema();

  const onSubmit = useCallback(
    (values: { templateID?: number }) => {
      const templateFilters = productSearchTemplates?.find(
        (productSearchTemplate) => productSearchTemplate.ID === values.templateID,
      )?.Filters;

      const newFilters = { ...productFilter, ...templateFilters };
      updateProductFilter(newFilters).then(() => {
        setCurrentProductFilterModalValues(undefined);
        setOpenProductFilterModal(false);
      });
    },
    [
      productFilter,
      productSearchTemplates,
      setCurrentProductFilterModalValues,
      setOpenProductFilterModal,
      updateProductFilter,
    ],
  );

  const checkClashingProperties = useCallback(
    (values: { templateID?: number }) => {
      const templateFilters = productSearchTemplates?.find(
        (productSearchTemplate) => productSearchTemplate.ID === values.templateID,
      )?.Filters;

      // If there is no template selected, we don't need to check for clashing properties.
      if (values.templateID) {
        const templateFilterKeys = Object.keys(templateFilters ?? {});
        const clashingProperties = Object.keys(productFilter ?? {}).filter((key) =>
          templateFilterKeys.includes(key),
        );
        if (clashingProperties.length > 0) {
          const msg =
            clashingProperties.length === 1
              ? intl.formatMessage(
                  {
                    id: "generic.question.overwrite-clashing-properties.singular",
                    defaultMessage: "Are you sure you want to overwrite this property: {property}?",
                  },
                  { property: clashingProperties[0] },
                )
              : intl.formatMessage(
                  {
                    id: "generic.question.overwrite-clashing-properties.plural",
                    defaultMessage:
                      "Are you sure you want to overwrite these properties: {properties}?",
                  },
                  { properties: clashingProperties.join(", ") },
                );
          setPropertyClashConfirmationModalState({
            show: true,
            message: msg,
            onSubmit: () => {
              onSubmit(values);
              setPropertyClashConfirmationModalState({ show: false });
            },
            onCancel: () => {
              setPropertyClashConfirmationModalState({ show: false });
            },
          });
          return;
        }
      }
      onSubmit(values);
    },
    [intl, onSubmit, productFilter, productSearchTemplates, setPropertyClashConfirmationModalState],
  );

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={checkClashingProperties}
      >
        <Form>
          <div className="p-5">
            <ProductSearchTemplateField />
          </div>
          <Separator />
          <div className="flex justify-end p-5">
            <div className="mr-5">
              <Button
                variant="secondary"
                onPress={() => {
                  setCurrentProductFilterModalValues(undefined);
                  setOpenProductFilterModal(false);
                }}
                isDisabled={isUpdateProductFilterLoading}
              >
                <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
              </Button>
            </div>
            <Button variant="primary" type="submit" isLoading={isUpdateProductFilterLoading}>
              <FormattedMessage id="generic.button.save" defaultMessage="Save" />
            </Button>
          </div>
        </Form>
      </Formik>
      <PropertyClashConfirmationModal {...propertyClashConfirmationModalState} />
    </>
  );
};

export default DialogTabPrefill;
