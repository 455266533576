import { forwardRef, useCallback } from "react";
import { useIntl } from "react-intl";

import { Button, ButtonProps, SvgIcon } from "@new-black/lyra";

import { toast } from "components/suite-ui/toast";
import { SafeClipboard } from "util/clipboard";

import { IActionButton } from "./types";

const CopyToClipboardActionButton = forwardRef<
  HTMLButtonElement,
  IActionButton & { small?: boolean; text: string }
>(
  (
    {
      className,
      isDisabled,
      isLoading,
      onPress,
      small,
      text,
      tooltip,
    }: IActionButton & { small?: boolean; text: string },
    ref,
  ) => {
    const intl = useIntl();

    const handlePress = useCallback(
      (event: Parameters<NonNullable<ButtonProps["onPress"]>>[0]) => {
        SafeClipboard.writeText(text)
          .then(() =>
            toast.success(
              intl.formatMessage({
                id: "generic.label.copied",
                defaultMessage: "Copied to clipboard",
              }),
            ),
          )
          .catch((err) => console.error(err));

        onPress?.(event);
      },
      [intl, onPress, text],
    );

    return (
      <Button
        ref={ref}
        className={className}
        onPress={handlePress}
        tooltip={
          tooltip ??
          intl.formatMessage({
            id: "generic.label.copy-to-clipboard",
            defaultMessage: "Copy to clipboard",
          })
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant={small ? "icon-xs" : "icon"}
      >
        <SvgIcon name="clipboard" />
      </Button>
    );
  },
);

CopyToClipboardActionButton.displayName = "CopyToClipboardActionButton";

export default CopyToClipboardActionButton;
