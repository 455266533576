import { IProduct } from "types/product";

export interface IOrganizationUnitDetailed {
  Name?: string;
  OrganizationUnitID?: number;
}

export type TOrganizationProductOptionValue = "ouView" | "productView";

export const AVAILABILITY_LIMIT = 100;

export interface IProductDetailed {
  ProductID: number;
  ProductName: string;
}

export interface IProductInformationDetails extends IProduct {
  BackendID?: string;
  Barcodes?: string[];
  CustomID?: string;
  ID?: number;
  Name?: string;
  BlobID?: string;
  display_value?: string;
}
