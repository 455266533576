import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { ButtonLink } from "components/routing";

import { IActionButtonLink } from "./types";

interface IDeleteActionButtonLink extends Omit<IActionButtonLink, "variant"> {
  variant?: IActionButtonLink["variant"] | "danger";
}

const DeleteActionButtonLink = forwardRef<HTMLAnchorElement, IDeleteActionButtonLink>(
  (
    {
      className,
      force,
      isDisabled,
      keepSearchParams,
      onLinkClick,
      state,
      target,
      to,
      tooltip,
      variant,
    }: IDeleteActionButtonLink,
    ref,
  ) => {
    const intl = useIntl();

    return (
      <ButtonLink
        to={to}
        state={state}
        keepSearchParams={keepSearchParams}
        target={target}
        onClick={onLinkClick}
        force={force}
        variant={variant ?? "icon"}
        ref={ref}
        className={className}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })
        }
        isDisabled={isDisabled}
      >
        {variant === "danger" ? (
          tooltip ?? intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })
        ) : (
          <SvgIcon name="delete" />
        )}
      </ButtonLink>
    );
  },
);

DeleteActionButtonLink.displayName = "DeleteActionButtonLink";

export default DeleteActionButtonLink;
