import * as RadixTooltip from "@radix-ui/react-tooltip";
import classNames from "classnames";

import type { TooltipProps } from "./tooltip.types";

export default function Tooltip({
  align = "center",
  children,
  className,
  onOpenChange,
  open,
  side = "bottom",
  title,
}: TooltipProps & { open?: boolean; onOpenChange?: (open: boolean) => void }) {
  return (
    <RadixTooltip.Root open={open} onOpenChange={onOpenChange}>
      <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>
      <RadixTooltip.Portal>
        <RadixTooltip.Content
          align={align}
          aria-label={title}
          side={side}
          className={classNames(
            "z-[10000] rounded-[3px] bg-surface-contrast px-2 pb-0.5 pt-0.5 text-[11px] text-inverted duration-200 animate-in fade-in zoom-in-75",

            className,
          )}
        >
          <RadixTooltip.Arrow className="fill-[var(--color-neutral-9)]" />
          {title}
        </RadixTooltip.Content>
      </RadixTooltip.Portal>
    </RadixTooltip.Root>
  );
}
