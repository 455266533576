import { ComponentProps, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigation } from "react-router-dom";

import { Button, ButtonGroup, Separator } from "@new-black/lyra";

import { useNavigate } from "components/routing";

interface FormSubmitDialogFooterProps {
  formID: string;
  onCancel?: () => void;
  onDelete?: () => void;
  isDeleteLoading?: boolean;
  isDeleteDisabled?: boolean;
  cancelLabel?: string;
  saveLabel?: string;
  keepSearchParamsOnNavigation?: boolean;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
  buttonVariants?: {
    cancel?: ComponentProps<typeof Button>["variant"];
    save?: ComponentProps<typeof Button>["variant"];
    delete?: ComponentProps<typeof Button>["variant"];
  };
}

export function FormSubmitDialogFooter({
  buttonVariants,
  cancelLabel,
  formID,
  isDeleteDisabled,
  isDeleteLoading,
  isLoading,
  isSaveDisabled,
  keepSearchParamsOnNavigation,
  onCancel,
  onDelete,
  saveLabel,
}: FormSubmitDialogFooterProps) {
  const navigate = useNavigate();
  const { state } = useNavigation();

  const canNavigate = useMemo(() => {
    return state === "idle" && !isLoading;
  }, [isLoading, state]);

  return (
    <div className="sticky bottom-0 rounded bg-surface-primary">
      <Separator />
      <div className="flex items-center justify-end gap-4 p-4">
        {onDelete ? (
          <div className="me-auto">
            <Button
              variant={buttonVariants?.delete ?? "danger"}
              onPress={onDelete}
              isLoading={isDeleteLoading}
              isDisabled={isDeleteDisabled || !canNavigate}
            >
              <FormattedMessage id="generic.label.delete" defaultMessage="Delete" />
            </Button>
          </div>
        ) : null}
        <ButtonGroup>
          <Button
            variant={buttonVariants?.cancel ?? "secondary"}
            isDisabled={!canNavigate || isDeleteLoading}
            onPress={() => {
              if (canNavigate) {
                if (onCancel) {
                  onCancel();
                } else {
                  navigate("../", { keepSearchParams: keepSearchParamsOnNavigation });
                }
              }
            }}
          >
            {cancelLabel ?? <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />}
          </Button>
          <Button
            variant={buttonVariants?.save ?? "primary"}
            type="submit"
            form={formID}
            isDisabled={!canNavigate || isDeleteLoading || isSaveDisabled}
            isLoading={state === "submitting"}
          >
            {saveLabel ?? <FormattedMessage id="generic.label.save" defaultMessage="Save" />}
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}
