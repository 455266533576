import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  SvgIcon,
  Table,
  TableColumnDef,
} from "@new-black/lyra";

import OpenInNewTabActionButtonLink from "components/shared/action-button-links/open-in-new-tab-action-button-link";
import AddActionButton from "components/shared/action-buttons/add-action-button";
import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import DownloadActionButton from "components/shared/action-buttons/download-action-button";
import ImageGalleryDialog, { IImageGalleryItem } from "components/shared/image-gallery-dialog";
import { saveResource } from "util/save-resource";

import { MediaFilePreview } from "./media-file-preview";
import { useMediaFileTypeBasedOnMimeType } from "./use-case-media-file-type";

export type MediaFile = {
  Name?: string;
  MimeType?: string;
  BlobID?: string;
  BlobUrl: string;
  ArrayBufferData?: string | ArrayBuffer | null;
  Size?: number;
};

export type MediaFilesCardProps = {
  onAdd?: () => void;
  start?: number;
  setStart: (newValue: number) => void;
  limit?: number;
  setLimit: (newValue: number) => void;
  data?: MediaFile[];
  showOpenInNewTabOption?: boolean;
  onDelete?: (file: MediaFile) => void;
  title?: string;
};

export const MediaFilesCard = ({
  data,
  limit,
  onAdd,
  onDelete,
  setLimit,
  setStart,
  showOpenInNewTabOption,
  start,
  title,
}: MediaFilesCardProps) => {
  const intl = useIntl();

  const getMediaFileType = useMediaFileTypeBasedOnMimeType();

  const [imageGalleryDialogOpen, setImageGalleryDialogOpen] = useState<boolean>(false);
  const [initialSelectedIndex, setInitialSelectedIndex] = useState<number>();

  const imageGalleryData = useMemo(
    () =>
      (data
        ?.filter((file) => file.MimeType?.startsWith("image/"))
        .map((file) => ({
          name: file.Name,
          src: file.ArrayBufferData
            ? URL.createObjectURL(
                new Blob([file.ArrayBufferData as ArrayBuffer], {
                  type: file.MimeType,
                }),
              )
            : file.BlobUrl,
          alt: "case-image",
        })) ?? []) as IImageGalleryItem[],
    [data],
  );

  const columns = useMemo<TableColumnDef<MediaFile>[]>(
    () => [
      {
        id: "Name",
        header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
        cell: ({ row }) => <MediaFilePreview file={row.original} />,
      },
      {
        id: "Type",
        header: intl.formatMessage({ id: "generic.label.type", defaultMessage: "Type" }),
        cell: ({ row }) => getMediaFileType(row.original.MimeType),
      },
      {
        id: "Actions",
        header: "",
        cellType: "button",
        align: "end",
        cell: ({ row }) => {
          const src = row.original.ArrayBufferData
            ? URL.createObjectURL(
                new Blob([row.original.ArrayBufferData as ArrayBuffer], {
                  type: row.original.MimeType,
                }),
              )
            : row.original.BlobUrl;

          return (
            <ButtonGroup>
              {row.original.MimeType?.startsWith("image/") ? (
                <Button
                  variant="icon"
                  onPress={() => {
                    setInitialSelectedIndex(
                      imageGalleryData.findIndex((item) => item.name === row.original.Name),
                    );
                    setImageGalleryDialogOpen(true);
                  }}
                  tooltip={intl.formatMessage({
                    id: "generic.label.preview",
                    defaultMessage: "Preview",
                  })}
                >
                  <SvgIcon name="preview" />
                </Button>
              ) : (
                <DownloadActionButton
                  onPress={() => saveResource(row.original.BlobUrl, row.original.Name)}
                />
              )}

              {showOpenInNewTabOption && src ? (
                <OpenInNewTabActionButtonLink to={src} target="_blank" />
              ) : null}

              {onDelete ? <DeleteActionButton onPress={() => onDelete(row.original)} /> : null}
            </ButtonGroup>
          );
        },
      },
    ],
    [getMediaFileType, imageGalleryData, intl, onDelete, showOpenInNewTabOption],
  );

  return (
    <Card>
      <CardHeader
        title={title ?? intl.formatMessage({ id: "generic.label.media", defaultMessage: "Media" })}
        actions={
          onAdd ? (
            <CardActions>
              <AddActionButton onPress={onAdd} />
            </CardActions>
          ) : null
        }
      />

      <Table
        aria-label={intl.formatMessage({
          id: "generic.label.media-files-table",
          defaultMessage: "Media files table",
        })}
        data={data ?? []}
        columns={columns}
        start={start}
        setStart={setStart}
        limit={limit}
        setLimit={setLimit}
        options={{
          emptyMessage: intl.formatMessage({
            id: "generic.label.media-table-empty-message",
            defaultMessage: "Click the '+' icon to add media",
          }),
          hidePagination: !data?.length,
        }}
      />

      <ImageGalleryDialog
        isOpen={imageGalleryDialogOpen}
        items={imageGalleryData}
        initialSelectedIndex={initialSelectedIndex}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            setImageGalleryDialogOpen(false);
          }
        }}
      />
    </Card>
  );
};
