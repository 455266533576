import { ComponentProps, useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";
import { Dialog as LyraDialog } from "@new-black/lyra";

import { ConfirmationModalContent } from "./confirmation-modal-content";
import { ConfirmationModalContentLyra } from "./confirmation-modal-content-lyra";
import { IConfirmationModalProps, IConfirmationModalWithSubmitProps } from ".";

export type IDeleteModalProps = Omit<
  IConfirmationModalProps | IConfirmationModalWithSubmitProps,
  | "cancelButtonColor"
  | "cancelButtonVariant"
  | "confirmButtonColor"
  | "confirmButtonVariant"
  | "fullWidth"
  | "messages"
  | "textDescriptionVariant"
  | "translations"
  | "maxWidth"
>;

/**
 * Component used to display a delete confirmation popup.
 */
export const DeleteModal = ({
  children,
  form,
  isLoading,
  isOpen,
  name,
  onCancel,
  onConfirm,
  type = "callback",
  value,
  variant = "material",
  ...props
}: Omit<
  IConfirmationModalProps | IConfirmationModalWithSubmitProps,
  | "cancelButtonColor"
  | "cancelButtonVariant"
  | "confirmButtonColor"
  | "confirmButtonVariant"
  | "fullWidth"
  | "messages"
  | "textDescriptionVariant"
  | "translations"
  | "maxWidth"
> & { variant?: "material" | "lyra"; "aria-label"?: string }) => {
  const intl = useIntl();
  return variant === "material" ? (
    <Dialog open={isOpen ?? false} onClose={onCancel} maxWidth="sm" fullWidth>
      <DeleteModalContent
        form={form}
        isLoading={isLoading}
        name={name}
        onCancel={onCancel}
        onConfirm={onConfirm}
        type={type}
        value={value}
      />
    </Dialog>
  ) : (
    <LyraDialog
      aria-label={
        props?.["aria-label"] ??
        intl.formatMessage({ id: "generic.label.delete-item-s", defaultMessage: "Delete items" })
      }
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onCancel();
        }
      }}
      maxWidth="lg"
    >
      <DeleteModalContentLyra
        form={form}
        isLoading={isLoading}
        name={name}
        onCancel={onCancel}
        onConfirm={onConfirm}
        type={type}
        value={value}
      >
        {children ? children : null}
      </DeleteModalContentLyra>
    </LyraDialog>
  );
};

export const DeleteModalContent = ({
  form,
  isLoading,
  name,
  onCancel,
  onConfirm,
  type,
  value,
}: Pick<
  IDeleteModalProps,
  "onCancel" | "onConfirm" | "form" | "isLoading" | "name" | "type" | "value"
>) => {
  const intl = useIntl();

  return (
    <ConfirmationModalContent
      onCancel={onCancel}
      onConfirm={onConfirm}
      form={form}
      cancelButtonVariant="secondary"
      confirmButtonVariant="primary"
      isLoading={isLoading}
      messages={{
        confirmButtonMessage: intl.formatMessage({
          id: "generic.label.delete",
          defaultMessage: "Delete",
        }),
        titleMessage: intl.formatMessage({
          id: "generic.label.delete-item-s",
          defaultMessage: "Delete item(s)",
        }),
        descriptionMessage: intl.formatMessage({
          id: "generic.message.delete-item-s.confirm",
          defaultMessage: "Are you sure you want to delete the selected item(s)?",
        }),
      }}
      name={name}
      textDescriptionVariant="subtitle1"
      type={type}
      value={value}
    />
  );
};

export const DeleteModalContentLyra = ({
  children,
  form,
  isLoading,
  name,
  onCancel,
  onConfirm,
  type,
  value,
}: Pick<
  IDeleteModalProps,
  "onCancel" | "onConfirm" | "form" | "isLoading" | "name" | "type" | "value" | "children"
>) => {
  const intl = useIntl();

  const baseModalProps = useMemo(() => getBaseDeleteModalContentLyraProps(intl), [intl]);

  return (
    <ConfirmationModalContentLyra
      {...baseModalProps}
      onCancel={onCancel}
      onConfirm={onConfirm}
      form={form}
      isLoading={isLoading}
      name={name}
      type={type}
      value={value}
    >
      {children ? children : null}
    </ConfirmationModalContentLyra>
  );
};

export const getBaseDeleteModalContentLyraProps = (
  intl: IntlShape,
): Partial<ComponentProps<typeof ConfirmationModalContentLyra>> => ({
  cancelButtonVariant: "secondary",
  confirmButtonVariant: "primary",
  textDescriptionVariant: "body-large",
  messages: {
    confirmButtonMessage: intl.formatMessage({
      id: "generic.label.delete",
      defaultMessage: "Delete",
    }),
    titleMessage: intl.formatMessage({
      id: "generic.label.delete-item-s",
      defaultMessage: "Delete item(s)",
    }),
    descriptionMessage: intl.formatMessage({
      id: "generic.message.delete-item-s.confirm",
      defaultMessage: "Are you sure you want to delete the selected item(s)?",
    }),
  },
});
