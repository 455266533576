import { CSSProperties } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import type IDraggable from "./drag-and-drop.types";

interface IDraggableItemProps<T> {
  draggableId: string;
  ItemComponent: React.ComponentType<T & IDraggable>;
  itemComponentProps?: Partial<T>;
}

const DraggableItem = <T,>({
  draggableId,
  ItemComponent,
  itemComponentProps,
}: IDraggableItemProps<T>) => {
  const { isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id: draggableId,
  });

  const style: CSSProperties = {
    transform: transform
      ? CSS.Transform.toString({
          x: transform?.x,
          y: transform?.y,
          scaleX: 1,
          scaleY: 1,
        })
      : undefined,
    transition: transition ?? undefined,
    visibility: isDragging ? "hidden" : "visible",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <ItemComponent isDragging={isDragging} listeners={listeners} {...(itemComponentProps as T)} />
    </div>
  );
};

export default DraggableItem;
