import { Dispatch, SetStateAction } from "react";

import { ProductSetModal, useProductSetModal } from "components/suite-composite/product-set-modal";
import { useInitializeProductSetModalIncludedProducts } from "components/suite-composite/product-set-modal/hooks/use-initialize-product-set-modal-included-products";
import { IProductSetFilter } from "components/suite-composite/product-set-modal/types";

type SidePaneProductsModalProps = {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  productsIDs?: number[];
  setProductsIDs: (newValues?: number[]) => void;
  initialQuerySearchValue?: string;
  setInitialQuerySearchValue?: Dispatch<SetStateAction<string | undefined>>;
  initialFilters?: IProductSetFilter[];
  setInitialFilters?: Dispatch<SetStateAction<IProductSetFilter[] | undefined>>;
};

export const SidePaneProductsModal = ({
  initialFilters,
  initialQuerySearchValue,
  open,
  productsIDs,
  setInitialFilters,
  setInitialQuerySearchValue,
  setOpen,
  setProductsIDs,
}: SidePaneProductsModalProps) => {
  const {
    availableProducts,
    filters,
    inlcudedProducts,
    isLoading,
    querySearchValue,
    searchProductPropertyTypes,
    setFilters,
    setIncludedProducts,
    setQuerySearchValue,
  } = useProductSetModal(undefined, undefined, undefined, initialQuerySearchValue, initialFilters);

  useInitializeProductSetModalIncludedProducts({
    productIDs: productsIDs,
    setIncludedProducts: setIncludedProducts,
  });

  return open ? (
    <ProductSetModal
      availableProducts={availableProducts}
      productProperties={searchProductPropertyTypes?.map((item) => item.type_id) ?? []}
      filters={filters}
      setFilters={(newFilters) => {
        setFilters(newFilters);
        setInitialFilters?.(newFilters);
      }}
      querySearchValue={querySearchValue}
      setQuerySearchValue={(newQuerySearchValue) => {
        setQuerySearchValue(newQuerySearchValue);
        setInitialQuerySearchValue?.(newQuerySearchValue);
      }}
      includedProducts={inlcudedProducts}
      closeModal={() => {
        setOpen(false);
      }}
      availableProductsLoading={isLoading}
      setIncludedProducts={(newProducts) => {
        const productIds = newProducts?.map((product) => product.ID);
        setProductsIDs(productIds);
        setOpen(false);
      }}
      enableClearAllProductsButton
    />
  ) : null;
};
