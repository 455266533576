import { ReactNode } from "react";

import classNames from "classnames";

export interface IExpanseCardHeader {
  startContent?: ReactNode;
  endContent?: ReactNode;
  canToggleOnClick?: boolean;
  onClick?: () => void;
}
export const ExpanseCardHeader = ({
  canToggleOnClick,
  endContent,
  onClick,
  startContent,
}: IExpanseCardHeader) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-between",
        canToggleOnClick ? "cursor-auto" : "cursor-default",
      )}
      onClick={onClick}
    >
      {startContent}
      {endContent}
    </div>
  );
};
