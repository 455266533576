import React from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";
import classNames from "classnames";

import { iconButtonStyles } from "components/suite-ui/icon-button";

export const SearchListFieldClearIcon = ({
  disabled,
  onClear,
  show,
}: {
  disabled?: boolean;
  show?: boolean;
  onClear: (
    event?: React.MouseEvent<HTMLSpanElement, MouseEvent> | React.KeyboardEvent<HTMLSpanElement>,
  ) => void;
}) => {
  const intl = useIntl();

  return (
    <span className={classNames("flex items-center", !disabled && show && "h-[24px] w-[24px]")}>
      {show ? (
        <span
          tabIndex={0}
          className={classNames(
            "hidden items-center justify-center group-hover:flex",
            iconButtonStyles({ size: "small", color: "default" }),
          )}
          onClickCapture={onClear}
          aria-label={intl.formatMessage({
            id: "generic.label.clear",
            defaultMessage: "Clear",
          })}
          onKeyDown={(event) => {
            if (event.code === "Enter" || event.code === "Space") {
              onClear(event);
            }
          }}
        >
          <SvgIcon name="alert-error" className="h-5 w-5" />
        </span>
      ) : null}
    </span>
  );
};
