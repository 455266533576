import { useIntl } from "react-intl";

import { KeyValueRow, KeyValueRowList } from "@new-black/lyra";

export const LoyaltyProgramProductLimitationInformationRows = ({
  productLimitation,
}: {
  productLimitation?: EVA.Core.Management.GetLoyaltyProgramProductLimitationResponse;
}) => {
  const intl = useIntl();

  return (
    <KeyValueRowList>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.id",
          defaultMessage: "ID",
        })}
      >
        {productLimitation?.ID}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.name",
          defaultMessage: "Name",
        })}
      >
        {productLimitation?.Name}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.backend-id",
          defaultMessage: "Backend ID",
        })}
      >
        {productLimitation?.BackendID}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.quantity-limit-per-user",
          defaultMessage: "Quantity limit per user",
        })}
      >
        {productLimitation?.ProductLimitation.QuantityLimitPerUser}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-user",
          defaultMessage: "Overall quantity limit per user",
        })}
      >
        {productLimitation?.ProductLimitation.OverallQuantityLimitPerUser}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.quantity-limit-per-order",
          defaultMessage: "Quantity limit per order",
        })}
      >
        {productLimitation?.ProductLimitation.QuantityLimitPerOrder}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-order",
          defaultMessage: "Overall quantity limit per order",
        })}
      >
        {productLimitation?.ProductLimitation.OverallQuantityLimitPerOrder}
      </KeyValueRow>
    </KeyValueRowList>
  );
};
