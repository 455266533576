import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const DecimalIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Number comma</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
      <filter id="filter-2">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
    </defs>
    <g id="Icon-/-Number-comma" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g filter="url(#filter-1)" id="notes_black_24dp">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        </g>
      </g>
      <g filter="url(#filter-2)" id="numeric">
        <g transform="translate(3.000000, 7.000000)">
          <path
            // eslint-disable-next-line max-len
            d="M10,10 L9,11 L9,10 L10,10 Z M16,0 C17.1045695,0 18,0.8954305 18,2 L18,3.5 C18,4.32842712 17.3284271,5 16.5,5 C17.3284271,5 18,5.67157288 18,6.5 L18,8 C18,9.11 17.1,10 16,10 L12,10 L12,8 L16,8 L16,6 L14,6 L14,4 L16,4 L16,2 L12,2 L12,0 L16,0 Z M8,8 L10,8 L10,10 L8,10 L8,8 Z M6,8 L6,10 L0,10 L0,6 C0,4.89 0.9,4 2,4 L4,4 L4,2 L0,2 L0,0 L4,0 C5.1045695,0 6,0.8954305 6,2 L6,4 C6,5.11 5.1,6 4,6 L2,6 L2,8 L6,8 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
