import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ButtonBase } from "@material-ui/core";
import { isNumber } from "lodash";

import Input from "components/suite-ui/input";

import "./style.css";
import { SvgIcon } from "@new-black/lyra";

interface NumberPickerProps {
  name: string;
  value: number;
  setValue?: (newValue: number) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  passive?: boolean;
  small?: boolean;
  disableButtons?: boolean;
}

const IntPicker = ({
  disableButtons,
  disabled,
  max,
  min,
  name,
  onFocus,
  passive,
  setValue,
  small,
  value,
}: NumberPickerProps) => {
  const [inputValue, setInputValue] = useState<number | undefined>(value ?? 0);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => setInputValue(value), [value]);

  const updateValue = useCallback(
    (val?: number) => {
      if (isNumber(val)) {
        if (isNumber(max) && val > max) {
          setValue?.(max);
          setInputValue(max);
        } else if (isNumber(min) && val < min) {
          setValue?.(min);
          setInputValue(min);
        } else {
          setValue?.(val);
          setInputValue(val);
        }
      } else {
        setValue?.(0);
        setInputValue(0);
      }
    },
    [max, min, setValue],
  );

  // increase the input value and value by 1 as log as it does not exceed the max value
  //
  const increaseValue = useCallback(() => {
    if (isNumber(max) && (inputValue || 0) + 1 > max) {
      setInputValue(max);
      setValue?.(max);
    } else {
      setInputValue((inputValue || 0) + 1);
      setValue?.((inputValue || 0) + 1);
    }
  }, [inputValue, max, setValue]);

  // decrease the input value and value by 1 as log as it does not exceed the min value
  //
  const decreaseValue = useCallback(() => {
    if (isNumber(min) && (inputValue || 0) - 1 < min) {
      setInputValue(min);
      setValue?.(min);
    } else {
      setInputValue((inputValue || 0) - 1);
      setValue?.((inputValue || 0) - 1);
    }
  }, [inputValue, min, setValue]);

  // minus button should be disabled if the input value is equal to the min value
  //
  const minusButtonShouldBeDisabled = useMemo(() => {
    if (disabled) {
      return true;
    }
    return isNumber(min) && inputValue === min;
  }, [disabled, inputValue, min]);

  // plus button should be disabled if the input value is equal to the max value
  //
  const plusButtonShouldBeDisabled = useMemo(() => {
    if (disabled) {
      return true;
    }
    return isNumber(max) && inputValue === max;
  }, [disabled, inputValue, max]);

  return (
    <div className="flex items-center">
      {!disableButtons ? (
        <ButtonBase
          disabled={minusButtonShouldBeDisabled || passive}
          disableRipple
          disableTouchRipple
          onClick={decreaseValue}
        >
          <SvgIcon name="minus" />
        </ButtonBase>
      ) : null}
      <Input
        name={name}
        className="int-picker mx-2 w-[60px]"
        type="number"
        passive={passive}
        value={isNumber(inputValue) ? inputValue : ""}
        disabled={disabled}
        small={small}
        inputRef={inputRef}
        onFocus={onFocus}
        onChange={(e) => {
          const val = e.target?.value ? parseInt(e.target.value, 10) : undefined;
          if (isNumber(val)) {
            setInputValue(val);
          } else {
            setInputValue(undefined);
          }
        }}
        onBlur={() => {
          updateValue(inputValue);
        }}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            updateValue(inputValue);
            inputRef.current?.blur();
          }
        }}
      />
      {!disableButtons ? (
        <ButtonBase
          disableRipple
          disableTouchRipple
          disabled={plusButtonShouldBeDisabled || passive}
          onClick={increaseValue}
        >
          <SvgIcon name="plus" />
        </ButtonBase>
      ) : null}
    </div>
  );
};

export default IntPicker;
