import Downshift from "downshift";

export default function stateReducer(state: any, changes: any) {
  switch (changes.type) {
    case Downshift.stateChangeTypes.keyDownEnter:
    case Downshift.stateChangeTypes.clickItem:
      return {
        ...changes,
        isOpen: state.isOpen,
        inputValue: "",
        highlightedIndex: state.highlightedIndex,
      };
    case Downshift.stateChangeTypes.keyDownEscape:
      return {
        isOpen: false,
        keySoFar: "",
      };
    default:
      return changes;
  }
}
