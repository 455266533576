import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listGiftCardConfigurationsLoaderQuery,
  serviceQueryKeys: listGiftCardConfigurationsLoaderQueryKeys,
  useServiceQueryHook: useListGiftCardConfigurationsQuery,
} = createServiceQuery(CoreManagement.ListGiftCardConfigurations);

export const {
  serviceLoaderQuery: getGiftCardConfigurationLoaderQuery,
  serviceQueryKeys: getGiftCardConfigurationQueryKeys,
  useServiceQueryHook: useGetGiftCardConfigurationQuery,
} = createServiceQuery(CoreManagement.GetGiftCardConfiguration);
