import { FormattedMessage } from "react-intl";

import { Button, Separator } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import type { IProductSearchModalResultBase } from "../types";

export function ProductSearchModalProductResultsShowMoreButton<
  TProduct extends IProductSearchModalResultBase,
>() {
  const { incrementLimit, isProductResultsLoading, limit, productsTotal } =
    useProductSearchModalContext<TProduct>();

  if (productsTotal > limit) {
    return (
      <>
        <Separator />
        <div className="px-4">
          <Button
            variant="secondary"
            className="mx-auto"
            onPress={incrementLimit}
            isLoading={isProductResultsLoading}
          >
            <FormattedMessage
              id="generic.label.view-more-results"
              defaultMessage="View more results"
            />
          </Button>
        </div>
      </>
    );
  }

  return null;
}
