import { Dispatch, useEffect, useRef } from "react";
import { useRevalidator } from "react-router-dom";

import { state } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { LoginAction } from "./login.state";

export const useHandleLoginResponse = (
  dispatchLoginState: Dispatch<LoginAction>,
  loginResponse?: EVA.Core.LoginResponse,
  chosenAuthenticatorName?: string,
) => {
  const setUserTokenState = useSetRecoilState(state.currentUser.currentUserTokenState);
  const availableAuthenticators = useRef(loginResponse?.TwoFactorAuthenticators ?? []);
  const revalidator = useRevalidator();

  useEffect(() => {
    if (loginResponse?.Authentication === 2) {
      setUserTokenState(loginResponse.User?.AuthenticationToken ?? "");
      revalidator.revalidate();
    } else if (loginResponse?.Authentication === 3) {
      if (loginResponse?.TwoFactorAuthenticators?.length) {
        availableAuthenticators.current = loginResponse.TwoFactorAuthenticators;
      }

      if (chosenAuthenticatorName) {
        dispatchLoginState({
          type: "setAll",
          state: {
            view: "2faConfirmation",
            twoFactorMethod: chosenAuthenticatorName,
            twoFactorMethodsAvailable: availableAuthenticators.current,
            loginData: {
              AuthenticationToken: loginResponse?.User?.AuthenticationToken,
              EmailAddress: loginResponse?.User?.EmailAddress,
              Nickname: loginResponse?.User?.Nickname,
            },
          },
        });
      } else {
        dispatchLoginState({
          type: "setAll",
          state: {
            view: "2faChooseMethod",
            loginData: {
              AuthenticationToken: loginResponse?.User?.AuthenticationToken,
              EmailAddress: loginResponse?.User?.EmailAddress,
              Nickname: loginResponse?.User?.Nickname,
            },
            twoFactorMethodsAvailable: availableAuthenticators.current,
          },
        });
      }
    }
  }, [dispatchLoginState, loginResponse, setUserTokenState, chosenAuthenticatorName, revalidator]);
};
