import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export const ThemeSwitch = ({
  editorTheme,
  setEditorTheme,
}: {
  editorTheme: "light" | "vs-dark";
  setEditorTheme: (item: "light" | "vs-dark") => void;
}) => {
  const intl = useIntl();

  return (
    <Button
      variant="icon"
      tooltip={
        editorTheme === "vs-dark"
          ? intl.formatMessage({
              id: "generic.label.light-theme",
              defaultMessage: "Light theme",
            })
          : intl.formatMessage({
              id: "generic.label.dark-theme",
              defaultMessage: "Dark theme",
            })
      }
      onPress={() => {
        if (editorTheme === "light") {
          setEditorTheme("vs-dark");
        } else {
          setEditorTheme("light");
        }
      }}
    >
      {editorTheme === "vs-dark" ? <SvgIcon name="light-mode" /> : <SvgIcon name="dark-mode" />}
    </Button>
  );
};
