import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  CellProps,
  Column,
  HeaderProps,
  useExpanded,
  usePagination as useReactTablePagination,
  useRowSelect,
  useRowState,
  useSortBy,
  useTable,
} from "react-table";

import {
  Radio as RadioButton,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableFooter,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from "@material-ui/core";
import { Button, ButtonGroup, Separator, SvgIcon, Text } from "@new-black/lyra";
import classNames from "classnames";
import { motion } from "framer-motion";
import { defer, isEmpty, isEqual, isFunction, keys, size } from "lodash";

import AddActionButton from "components/shared/action-buttons/add-action-button";
import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import ReorganizeAction from "components/shared/reorganize-action";
import BlurOverlay from "components/suite-ui/blur-overlay";

import UnblurredContainer from "../blur-overlay/unblurred-container";
import DragDropContainer from "../drag-and-drop/drag-drop-container";
import Grid from "../grid";

import useContextMenu from "./hooks/use-context-menu";
import useEditModeState from "./hooks/use-edit-mode-state";
import useExpandedState from "./hooks/use-expanded-state";
import useExternalAddButton from "./hooks/use-external-add-button";
import useNewRowEditable from "./hooks/use-new-row-editable";
import useOrganizeColumns from "./hooks/use-organize-columns";
import usePagination from "./hooks/use-pagination";
import useRenderTableRows from "./hooks/use-render-table-rows";
import useSorting from "./hooks/use-sorting";
import OrganizeColumnsContainer from "./organize-columns/organize-columns-container";
import OverlayedColumnButton from "./organize-columns/overlayed-column-button";
import IndeterminateCheckbox from "./indeterminate-checkbox";
import RowContextMenu from "./row-context-menu";
import TableHeaderCell from "./table-header-cell";
import { CustomRowActionsWrapper, TableBox, TableWrapper } from "./table-helper-components";
import { initialContextMenuState } from "./table-helpers";
import TableLoadingState from "./table-loading-state";
import TablePagination from "./table-pagination";
import TableRowDeleteAction from "./table-row-delete-action";
import TableRowEditAction from "./table-row-edit-action";
import TableRowSaveAndCancelActions from "./table-row-save-and-cancel-actions";
import { DEFAULT_TABLE_PAGINATION_OPTIONS, ITableColumn, ITableProps } from "./table-types";
import { convertIndexArrayToObject } from "./utils";

const Table = ({
  childPropKey,
  columns,
  contextMenu,
  createRow,
  customActions,
  customFooter,
  customRowActions,
  data,
  disableUndefinedSort,
  externalAddButtonRef,
  hideActionsHeader,
  initialSort,
  isInModal = false,
  isLoading = false,
  onColumnsOrderChange,
  onRowClick,
  onSelectionChangeData,
  onSelectionChangeRow,
  onSelectionChangeRowData,
  onSelectionChangeRows,
  onSortByChange,
  options,
  orderableColumns,
  pageOptions = DEFAULT_TABLE_PAGINATION_OPTIONS,
  removeByIndex,
  reorganizeColumnsChildren,
  selectedData,
  selectedRow,
  selectedRowData,
  selectedRows,
  updateAsyncData,
  updateData,
  updateRow,
  ...paginationProps
}: ITableProps) => {
  const intl = useIntl();
  // State to determine if the first row should be editable
  const [firstRowEditable, setFirstRowEditable] = useState(options?.initialRowEditable ?? false);
  const [lastRowEditable, setLastRowEditable] = useState(options?.initialRowEditable ?? false);
  const lastRowRef = useRef<any>(null);
  const isUncountablePaginationNeeded = useMemo(
    () => !options?.hidePagination && !!paginationProps?.onNext && !!paginationProps.onPrevious,
    [options, paginationProps],
  );
  const isCountablePaginationNeeded = useMemo(
    () =>
      !options?.hidePagination &&
      paginationProps.total !== undefined &&
      paginationProps.skip !== undefined &&
      paginationProps.limit !== undefined &&
      data &&
      (paginationProps.total > data.length || data.length > pageOptions[0]),
    [data, paginationProps, options, pageOptions],
  );
  const isPaginationNeeded = useMemo(
    () => isCountablePaginationNeeded || isUncountablePaginationNeeded,
    [isCountablePaginationNeeded, isUncountablePaginationNeeded],
  );
  const {
    expandedButtonAnimationVariants: expanseButtonAnimationVariants,
    expandedState,
    toggleExpandedState,
  } = useExpandedState(options?.expanse);
  const { editMode, toggleEditMode, updateEditMode } = useEditModeState(
    options?.intialEditModeState,
  );
  const isSingleSelection = useMemo(
    () => !!onSelectionChangeRow || !!onSelectionChangeRowData,
    [onSelectionChangeRow, onSelectionChangeRowData],
  );

  const tableColumns = useMemo<Column<any>[]>(() => {
    let localColumns = [...columns];

    /**
     * Add the checkboxes on the left of the table row
     * Only when the table option 'selectRows' is true
     */
    if (options?.selectRows) {
      localColumns = [
        {
          id: "selection",
          width: "4%",
          Header: ({ getToggleAllRowsSelectedProps }: HeaderProps<any>) =>
            options.selectAll !== false && !isSingleSelection ? (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ) : (
              ""
            ),
          Cell: ({ row }: CellProps<any>) => {
            return (
              <div>
                {isSingleSelection ? (
                  <RadioButton
                    className="p-0"
                    color="primary"
                    checked={
                      (!!selectedRowData && isEqual(selectedRowData, row.original)) ||
                      (!!selectedRow && selectedRow === parseInt(row.id, 10))
                    }
                    onChange={() => {
                      onSelectionChangeRow?.(parseInt(row.id, 10));
                      onSelectionChangeRowData?.(row.original);
                    }}
                  />
                ) : (
                  <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                )}
              </div>
            );
          },
          ...options?.selectionColumnProps,
        } as Column<any>,
        ...localColumns,
      ];
    }

    /**
     * Add the action buttons (edit and save and delete) on the right of the table row
     * Only when the table option 'editRows' or 'deleteRow' is true
     */
    if ((options?.editRows && !options?.editAllRows) || options?.deleteRow || customRowActions) {
      localColumns = [
        ...localColumns,
        {
          id: "actions",
          width: "8%",
          Header: () =>
            hideActionsHeader ? null : (
              <div
                className={
                  options?.actionColumnHeaderClassName ??
                  (options?.actionColumnNoMargin ? "" : "mr-1")
                }
              >
                {options?.tableStrings?.actionsColumnHeader ?? (
                  <FormattedMessage id="generic.label.actions" defaultMessage="Actions" />
                )}
              </div>
            ),
          align: options?.actionsColumnAlignent ?? "right",
          Cell: (props: CellProps<any>) => {
            const {
              row: {
                index,
                original,
                state: { editing },
              },
            } = props;
            if (editing) {
              return (
                <div>
                  {options?.actionColumnNoMargin ? (
                    customRowActions?.({
                      rowData: original,
                      index,
                      isEditMode: true,
                    })
                  ) : (
                    <CustomRowActionsWrapper>
                      {customRowActions?.({
                        rowData: original,
                        index,
                        isEditMode: true,
                      })}
                    </CustomRowActionsWrapper>
                  )}

                  <TableRowSaveAndCancelActions
                    rows={data}
                    props={props}
                    updateRow={updateRow}
                    removeByIndex={removeByIndex}
                    lastRowEditable={lastRowEditable}
                    firstRowEditable={firstRowEditable}
                    setLastRowEditable={setLastRowEditable}
                    setFirstRowEditable={setFirstRowEditable}
                    saveTooltipTitle={
                      options?.tableStrings?.saveRow ??
                      intl.formatMessage({
                        id: "generic.label.save-row",
                        defaultMessage: "Save row",
                      })
                    }
                    cancelTooltipTitle={
                      options?.tableStrings && options.tableStrings?.cancel
                        ? options.tableStrings?.cancel
                        : intl.formatMessage({
                            id: "generic.label.cancel",
                            defaultMessage: "Cancel",
                          })
                    }
                  />
                </div>
              );
            }

            return (
              <div>
                {options?.actionColumnNoMargin ? (
                  customRowActions?.({ rowData: original, index, isEditMode: false })
                ) : (
                  <CustomRowActionsWrapper>
                    {customRowActions?.({ rowData: original, index, isEditMode: false })}
                  </CustomRowActionsWrapper>
                )}

                <ButtonGroup>
                  {options?.editRows && !original.disableEditRow ? (
                    <TableRowEditAction
                      {...{
                        props,
                        disabled: firstRowEditable || lastRowEditable,
                        tooltipTitle:
                          options?.tableStrings?.editRow ??
                          intl.formatMessage({
                            id: "generic.label.edit-row",
                            defaultMessage: "Edit row",
                          }),
                      }}
                    />
                  ) : null}
                  {options?.deleteRow && !original.disableDeleteRow ? (
                    <TableRowDeleteAction
                      {...{
                        disabled: firstRowEditable || lastRowEditable,
                        tooltipTitle:
                          options?.tableStrings?.editRow ??
                          intl.formatMessage({
                            id: "generic.label.delete",
                            defaultMessage: "Delete",
                          }),
                        removeByIndex,
                        rowIndex: props?.row?.index,
                        onAfterDelete: () => {
                          const isSelectedRow =
                            (!!selectedRow && parseInt(props.row.id, 10) === selectedRow) ||
                            (!!selectedRowData && isEqual(selectedRowData, props.row.original));

                          if (isSelectedRow) {
                            onSelectionChangeRow?.(undefined);
                            onSelectionChangeRowData?.(undefined);
                          }
                        },
                      }}
                    />
                  ) : null}
                </ButtonGroup>
              </div>
            );
          },
          ...options?.actionColumnProps,
        } as any,
      ];
    }

    return localColumns;
  }, [
    columns,
    options?.selectRows,
    options?.editRows,
    options?.editAllRows,
    options?.deleteRow,
    options?.selectionColumnProps,
    options?.selectAll,
    options?.actionColumnProps,
    options?.actionsColumnAlignent,
    options?.actionColumnHeaderClassName,
    options?.actionColumnNoMargin,
    options?.tableStrings,
    customRowActions,
    isSingleSelection,
    selectedRowData,
    selectedRow,
    onSelectionChangeRow,
    onSelectionChangeRowData,
    hideActionsHeader,
    firstRowEditable,
    lastRowEditable,
    intl,
    removeByIndex,
    data,
    updateRow,
  ]);
  const {
    isOrganizeColumnsActive,
    localOrderedColumns,
    setLocalOrderedColumns,
    toggleOrganizeColumns,
  } = useOrganizeColumns(
    columns,
    onColumnsOrderChange,
    options?.showOrganizeColumns,
    options?.getOrderableColumnValue,
  );
  const {
    activeContextRow,
    contextClickState,
    handleContextMenuClose,
    handleContextRightClick,
    setActiveContextRow,
  } = useContextMenu(initialContextMenuState);

  const getInitialSelectedRowIds = () => {
    if (selectedRows) {
      return convertIndexArrayToObject(selectedRows);
    }

    if (selectedData) {
      const selectedRowIds = data
        .map((rowData, rowId) =>
          selectedData.some((selectedItem) => isEqual(selectedItem, rowData)) ? rowId : undefined,
        )
        .filter((selectedRowId) => selectedRowId !== undefined) as number[];

      return convertIndexArrayToObject(selectedRowIds);
    }

    return {};
  };

  const isCountablePaginationPossible = useMemo(
    () =>
      paginationProps.total !== undefined &&
      paginationProps.limit !== undefined &&
      paginationProps.skip !== undefined,
    [paginationProps],
  );

  const initialExpandedState = useMemo<Record<string, boolean>>(() => {
    const optionsInitializer = options?.initialExpandedRows;
    if (optionsInitializer === undefined || optionsInitializer === false) {
      return {};
    }
    if (optionsInitializer === true) {
      return convertIndexArrayToObject(data.map((_, index) => index.toString()));
    }
    if (Array.isArray(optionsInitializer)) {
      return convertIndexArrayToObject(optionsInitializer.map(String));
    }
    if (typeof optionsInitializer === "function") {
      return data.reduce((prev, curr, idx) => {
        const rowState = optionsInitializer(curr, idx);
        return {
          ...prev,
          ...(typeof rowState === "boolean" ? { [`${idx}`]: rowState } : rowState),
        };
      }, {} as Record<string, boolean>);
    }
    return optionsInitializer;
  }, [data, options?.initialExpandedRows]);

  const tablePlugins = useMemo(
    () => [
      useSortBy,
      useExpanded,
      ...(isCountablePaginationPossible ? [useReactTablePagination] : []),
      useRowSelect,
      useRowState,
    ],
    [isCountablePaginationPossible],
  );

  // Use the state and functions returned from useTable to build your UI
  //
  const {
    canNextPage,
    canPreviousPage,
    dispatch,
    footerGroups,
    getTableBodyProps,
    getTableProps,
    // Pagination
    //
    gotoPage: goToPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows,
    rowsById,
    setPageSize,
    state: { expanded, pageIndex, pageSize, selectedRowIds, sortBy },
    toggleRowExpanded,
    toggleRowSelected,
  } = useTable(
    {
      columns: tableColumns,
      data,
      initialState: {
        selectedRowIds: getInitialSelectedRowIds(),
        pageIndex:
          paginationProps.skip !== undefined && paginationProps.limit !== undefined
            ? Math.floor(paginationProps.skip / Math.max(paginationProps.limit, 5))
            : 0,
        pageSize: Math.max(paginationProps.limit ?? data?.length ?? 5, 5),
        sortBy: initialSort ?? [],
        expanded: initialExpandedState,
      },
      disableSortRemove: disableUndefinedSort,
      pageCount:
        paginationProps.total !== undefined && paginationProps.limit !== undefined
          ? Math.ceil(paginationProps.total / Math.max(paginationProps.limit, 5))
          : 1,
      manualPagination:
        data && paginationProps.total ? data.length !== paginationProps.total : false,
      manualSortBy: true,
      autoResetPage: true,
    },
    ...tablePlugins,
  );

  useEffect(() => {
    if (onSelectionChangeRows) {
      const indexes = keys(selectedRowIds)
        .filter((key) => selectedRowIds[key])
        .map((index) => parseInt(index, 10));
      if (!isEqual(indexes, selectedRows)) {
        onSelectionChangeRows(indexes);
      }
    }
    if (onSelectionChangeData) {
      const indexes = keys(selectedRowIds)
        .filter((key) => selectedRowIds[key])
        .map((index) => parseInt(index, 10));
      const newSelectedData = data.filter((_, index) => indexes.includes(index));
      if (!isEqual(newSelectedData, selectedData)) {
        onSelectionChangeData(newSelectedData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRowIds]);

  useEffect(() => {
    if (selectedRows) {
      keys(rowsById).forEach((rowId) =>
        toggleRowSelected(rowId, selectedRows.includes(parseInt(rowId, 10))),
      );
    }

    if (selectedData) {
      const selectedIds = data
        .map((rowData, rowId) =>
          selectedData.some((selectedItem) => isEqual(selectedItem, rowData)) ? rowId : undefined,
        )
        .filter((selectedRowId) => selectedRowId !== undefined) as number[];
      keys(rowsById).forEach((rowId) =>
        toggleRowSelected(rowId, selectedIds.includes(parseInt(rowId, 10))),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedData, selectedRows]);

  const handleAddButtonClick = useCallback(() => {
    if (!firstRowEditable && !lastRowEditable) {
      createRow?.();
      // If pagination is disabled we add to the end of the rows
      //
      if (options?.hidePagination) {
        setLastRowEditable(true);
        // scroll the new tier in to view.
        //
        defer(() => {
          if (lastRowRef.current) {
            lastRowRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        });
      } else {
        setFirstRowEditable(true);
      }
    }
  }, [createRow, firstRowEditable, lastRowEditable, options]);

  // Whenever the ref changes, make sure to bind / unbind the click handler
  useExternalAddButton(externalAddButtonRef, handleAddButtonClick);

  // Effect to turn the newly created row editable
  useNewRowEditable(page, rows, firstRowEditable, lastRowEditable);

  const renderTableRows = useRenderTableRows({
    data,
    rows,
    page,
    columns,
    editMode,
    childPropKey,
    isPaginationNeeded,
    contextClickState,
    lastRowRef,
    setActiveContextRow,
    contextMenu,
    tableOptions: options,
    handleContextRightClick,
    prepareRow,
    onRowClick,
    hasCustomRowActions: !!customRowActions,
    columnVerticalAlignment: options?.columnVerticalAlignment,
    expandedState: expanded,
    toggleRowExpanded,
  });

  usePagination(
    dispatch,
    pageSize,
    pageIndex,
    paginationProps.limit,
    paginationProps.skip,
    paginationProps.setLimit,
    paginationProps.setSkip,
  );

  useSorting(sortBy, onSortByChange);

  const tableContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="relative">
      {isOrganizeColumnsActive && <BlurOverlay />}
      <TableWrapper
        onSubmit={(event) => event.preventDefault()}
        disableFormWrapper={options?.disableFormWrapper}
      >
        <MuiTableContainer ref={tableContainerRef}>
          {!options?.hideHeader ? (
            <UnblurredContainer
              className={classNames("bg-transparent", options?.headerWrapperClassName)}
            >
              <Grid className="min-h-[64px] justify-between p-4" container alignItems="center">
                <TableBox>
                  <TableBox flex flexColumn>
                    <Text variant={options?.titleSize ?? "heading-2"}>
                      {isOrganizeColumnsActive
                        ? options?.tableStrings?.organizeColumns ??
                          intl.formatMessage({
                            id: "generic.label.reorganize-columns",
                            defaultMessage: "Reorganize columns",
                          })
                        : options?.title}
                    </Text>
                    {!isEmpty(selectedRowIds) ? (
                      <Text color="secondary">
                        <span>
                          {options?.tableStrings?.nRowsSelected ? (
                            `${size(selectedRowIds)} options?.tableStrings?.nRowsSelected`
                          ) : (
                            <FormattedMessage
                              id="generic.label.rows-selected"
                              defaultMessage="{count} row(s) selected"
                              values={{ count: size(selectedRowIds) }}
                            />
                          )}
                        </span>
                      </Text>
                    ) : null}
                  </TableBox>
                </TableBox>
                <TableBox flex className="h-8">
                  <ButtonGroup>
                    {options?.editAllRows === true && expandedState !== "closed" ? (
                      <Button
                        variant="icon"
                        tooltip={
                          editMode
                            ? options.tableStrings?.saveRows ??
                              intl.formatMessage({
                                id: "generic.label.save-rows",
                                defaultMessage: "Save rows",
                              })
                            : options.tableStrings?.editRows ??
                              intl.formatMessage({
                                id: "generic.label.edit-rows",
                                defaultMessage: "Edit rows",
                              })
                        }
                        aria-label="save"
                        onPress={() => {
                          if (updateData && editMode) {
                            updateData(data);
                            toggleEditMode();
                            return;
                          }
                          if (updateAsyncData && editMode) {
                            updateAsyncData(data).then((newEditMode) =>
                              updateEditMode(!newEditMode),
                            );
                            return;
                          }
                          toggleEditMode();
                        }}
                      >
                        {editMode ? <SvgIcon name="alert-success" /> : <SvgIcon name="edit" />}
                      </Button>
                    ) : (
                      <div />
                    )}
                    {!isEmpty(selectedRowIds) && removeByIndex ? (
                      <DeleteActionButton
                        tooltip={
                          (options && options.tableStrings?.deleteRows) ??
                          intl.formatMessage({
                            id: "generic.label.delete-rows",
                            defaultMessage: "Delete row(s)",
                          })
                        }
                        aria-label="delete"
                        onPress={() => {
                          if (isFunction(removeByIndex)) {
                            setFirstRowEditable(false);
                            const rowsToDelete = keys(selectedRowIds)
                              .filter((key) => selectedRowIds[key])
                              .map((index) => parseInt(index, 10));
                            removeByIndex(rowsToDelete);
                            if (onSelectionChangeRows) {
                              onSelectionChangeRows([]);
                            }
                            if (onSelectionChangeData) {
                              onSelectionChangeData([]);
                            }
                          }
                        }}
                      />
                    ) : (
                      <div />
                    )}

                    {orderableColumns?.length &&
                    onColumnsOrderChange &&
                    options?.showOrganizeColumns === undefined ? (
                      <ReorganizeAction
                        tooltipTitle={
                          (options && options.tableStrings?.organizeColumns) ??
                          intl.formatMessage({
                            id: "generic.label.reorganize-columns",
                            defaultMessage: "Reorganize columns",
                          })
                        }
                        isReorganizeActive={isOrganizeColumnsActive}
                        setIsReorganizeActive={() => toggleOrganizeColumns()}
                      />
                    ) : null}
                  </ButtonGroup>

                  {customActions}

                  <ButtonGroup>
                    {options?.addRow && createRow ? (
                      <span className="self-center">
                        <AddActionButton
                          tooltip={
                            (options && options.tableStrings?.addRow) ??
                            intl.formatMessage({
                              id: "generic.label.add-row",
                              defaultMessage: "Add row",
                            })
                          }
                          isDisabled={firstRowEditable || lastRowEditable}
                          onPress={handleAddButtonClick}
                        />
                      </span>
                    ) : null}
                    {options?.expanse ? (
                      <motion.div animate={expandedState} variants={expanseButtonAnimationVariants}>
                        <Button
                          tooltip={
                            expandedState === "closed"
                              ? options.tableStrings?.unfold ??
                                intl.formatMessage({
                                  id: "generic.label.unfold",
                                  defaultMessage: "Unfold",
                                })
                              : options.tableStrings?.fold ??
                                intl.formatMessage({
                                  id: "generic.label.fold",
                                  defaultMessage: "Fold",
                                })
                          }
                          onPress={toggleExpandedState}
                        >
                          <SvgIcon name="chevron-down" />
                        </Button>
                      </motion.div>
                    ) : null}
                  </ButtonGroup>
                </TableBox>
              </Grid>
            </UnblurredContainer>
          ) : null}
          {!isLoading ? (
            <>
              {isOrganizeColumnsActive ? (
                <UnblurredContainer
                  className={classNames("w-full", columns?.length > 0 ? "absolute" : undefined)}
                >
                  <DragDropContainer
                    items={localOrderedColumns ?? []}
                    setItems={setLocalOrderedColumns}
                    OverlayComponent={OverlayedColumnButton}
                    parentNode={isInModal ? tableContainerRef.current : undefined}
                  >
                    <OrganizeColumnsContainer
                      columns={localOrderedColumns ?? []}
                      setColumns={setLocalOrderedColumns}
                      allColumns={orderableColumns ?? []}
                      dragColumnTooltip={options?.tableStrings?.dragColumn}
                      removeColumnTooltip={options?.tableStrings?.removeColumn}
                      addColumnTooltip={options?.tableStrings?.addColumn}
                      className={classNames(options?.hideHeader && "pt-4")}
                    />
                  </DragDropContainer>
                  {reorganizeColumnsChildren}
                  <Separator className="my-0" />
                </UnblurredContainer>
              ) : null}
              <div
                className="overflow-x-auto"
                style={{
                  height: options?.stickyHeaderHeight,
                  maxHeight: options?.stickyHeaderMaxHeight,
                }}
              >
                <MuiTable stickyHeader={options?.stickyHeader} {...getTableProps()}>
                  {!options?.hideColumnHeaders &&
                  (expandedState !== "closed" || options?.showFooter) ? (
                    <MuiTableHead>
                      {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <MuiTableRow {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <TableHeaderCell
                              key={column.id}
                              column={column}
                              defaultBackgroundColor={
                                options?.stickyHeader ? "white" : "transparent"
                              }
                            />
                          ))}
                        </MuiTableRow>
                      ))}
                    </MuiTableHead>
                  ) : null}
                  {expandedState !== "closed" ? (
                    <MuiTableBody {...getTableBodyProps()}>{renderTableRows()}</MuiTableBody>
                  ) : null}
                  {options?.showFooter ? (
                    <TableFooter>
                      {footerGroups.map((group) => (
                        // eslint-disable-next-line react/jsx-key
                        <MuiTableRow {...group.getFooterGroupProps()}>
                          {group.headers.map((column) => (
                            // eslint-disable-next-line react/jsx-key
                            <MuiTableCell
                              className="leading text-[0.8125rem] font-medium tracking-[0.0075rem] text-[color:rgba(0,0,0,.87)]"
                              style={{
                                ...(column.footerStyle ? column.footerStyle : {}),
                                textAlign: (column as Partial<ITableColumn<any>>).align ?? "left",
                              }}
                              {...column.getFooterProps()}
                            >
                              {column.render("Footer")}
                            </MuiTableCell>
                          ))}
                        </MuiTableRow>
                      ))}
                    </TableFooter>
                  ) : null}
                </MuiTable>
              </div>
            </>
          ) : (
            <TableLoadingState
              limit={paginationProps.limit ?? 5}
              footerAvailable={isPaginationNeeded}
            />
          )}
        </MuiTableContainer>
        <TablePagination
          pageOptions={pageOptions}
          paginationTableStrings={{
            nextPage: options && options.tableStrings?.nextPage,
            lastPage: options && options.tableStrings?.lastPage,
            firstPage: options && options.tableStrings?.firstPage,
            rowsPerPage: options && options.tableStrings?.rowsPerPage,
            previousPage: options && options.tableStrings?.previousPage,
            numberOfNumberSplit: options && options.tableStrings?.numberOfNumberSplit,
          }}
          hidePageOptionsSelect={options?.hidePageOptionsSelect}
          uncountablePaginationProps={{
            ...paginationProps,
            limit: paginationProps.limit ?? 15,
          }}
          countablePaginationProps={{
            goToPage,
            pageSize,
            nextPage,
            pageCount,
            pageIndex,
            canNextPage,
            setPageSize,
            previousPage,
            canPreviousPage,
            dataSize: paginationProps.total ?? data.length,
          }}
          isCountablePaginationPossible={isCountablePaginationPossible}
          showPagination={isPaginationNeeded && !isLoading && expandedState !== "closed"}
          disablePagination={options?.disablePagination}
        />
        {customFooter &&
          customFooter({
            data,
            updateData,
            updateEditMode,
          })}

        {contextMenu && (
          <RowContextMenu
            items={contextMenu}
            activeRow={activeContextRow}
            anchorPosition={contextClickState}
            handleClose={handleContextMenuClose}
          />
        )}
      </TableWrapper>
    </div>
  );
};

Table.defaultProps = {
  options: {
    selectAll: true,
  },
};

export default Table;
