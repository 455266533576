import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

const getLedgerClassesState = builders.buildServiceState({
  requireEmployee: true,
  key: "get-ledger-classes",
  allowEmptyRequest: true,
  service: CoreManagement.GetLedgerClasses,
});

export default getLedgerClassesState;
