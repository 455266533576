import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Menu, MenuTrigger } from "@new-black/lyra";

import AddActionButton from "components/shared/action-buttons/add-action-button";

export const LoyaltyProgramRuleConditionsCardAddAction = ({
  onAddConsumerCondition,
  onAddOrderAmountCondition,
  onAddProductSetCondition,
}: {
  onAddConsumerCondition?: () => void;
  onAddOrderAmountCondition?: () => void;
  onAddProductSetCondition?: () => void;
}) => {
  const intl = useIntl();

  const menuItems = useMemo(() => {
    const items = [];

    if (onAddOrderAmountCondition) {
      items.push({
        id: "order_amount",
        label: intl.formatMessage({
          id: "generic.label.order-amount",
          defaultMessage: "Order amount",
        }),
        onContextItemClick: onAddOrderAmountCondition,
      });
    }

    if (onAddProductSetCondition) {
      items.push({
        id: "product-set",
        label: intl.formatMessage({
          id: "generic.label.product-set",
          defaultMessage: "Product set",
        }),
        onContextItemClick: onAddProductSetCondition,
      });
    }

    if (onAddConsumerCondition) {
      items.push({
        id: "consumer",
        label: intl.formatMessage({
          id: "generic.label.consumer",
          defaultMessage: "Consumer",
        }),
        onContextItemClick: onAddConsumerCondition,
      });
    }

    return items;
  }, [intl, onAddConsumerCondition, onAddOrderAmountCondition, onAddProductSetCondition]);

  const AddAction = useMemo(
    () =>
      menuItems?.length ? (
        <MenuTrigger>
          <AddActionButton />

          <Menu
            aria-label={intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })}
            items={menuItems}
            getItemId={(item) => item.id}
            selectRenderElements={(item) => ({ label: item.label })}
            onAction={(key) => menuItems.find((item) => item.id === key)?.onContextItemClick()}
          />
        </MenuTrigger>
      ) : null,
    [menuItems, intl],
  );

  return AddAction;
};
