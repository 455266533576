import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { isEqual } from "lodash";
import { selectorFamily } from "recoil";

import { removeUndefinedValues } from "util/helper";

export const DEFAULT_INCLUDED_FIELDS = [
  "product_id",
  "display_value",
  "primary_image.blob",
  "backend_id",
  "custom_id",
];

export const productAutocompleteServiceState = builders.buildServiceFamilyState({
  service: Core.SearchProducts,
  key: "Composite:ProductAutocomplete:ProductSearch",
  allowEmptyRequest: false,
});

export const productAutocompleteResultSelector = selectorFamily({
  key: "Composite:ProductAutocomplete:ProductSearch:Result",
  get:
    (familyKey) =>
    ({ get }) =>
      get(productAutocompleteServiceState.response(familyKey))?.Products,
});

export const productAutocompleteRequestSelector =
  helpers.createSelectors.createGetterSetterSelectorFamily<
    EVA.Core.SearchProducts,
    EVA.Core.SearchProducts | undefined
  >({
    key: "Composite:ProductAutocomplete:ProductSearch:Request",
    state: productAutocompleteServiceState.request,
    defaultValue: undefined,
    getData: (request) => request,
    setData: (request, newValue) => {
      if (!isEqual(removeUndefinedValues(request), removeUndefinedValues(newValue))) {
        return newValue;
      }
      return request;
    },
  });
