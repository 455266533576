import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCaseStatusesQuery } from "models/cases";
import { CaseStatusAction } from "types/eva-core";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

export interface ICaseStatusAutocompleteItem {
  ID: number;
  Name?: string;
  Action?: CaseStatusAction;
  IsClosed?: boolean;
  Color?: string;
}

export const {
  MultiAutocomplete: CaseStatusMultiAutocomplete,
  SingleAutocomplete: CaseStatusSingleAutocomplete,
} = AutocompleteGenerator<CoreManagement.ListCaseStatuses, ICaseStatusAutocompleteItem>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (response) =>
    response?.Results?.map((caseStatus) => ({
      ID: caseStatus.ID,
      Name: caseStatus.Name,
      Color: caseStatus.Color,
      Action: caseStatus?.Action as number,
    })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.case-status",
    defaultMessage: "Case status",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: listCaseStatusesQuery,
      initialRequest: { InitialPageConfig: { Limit: 10 } },
      getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
      setQueryRequest: (req, newValue) => ({
        ...req,
        InitialPageConfig: { Filter: { Name: newValue ?? undefined } },
      }),
    }),
});

export const { SingleSearchListField: CaseStatusSearchListField } = SearchListFieldGenerator<
  CoreManagement.ListCaseStatuses,
  ICaseStatusAutocompleteItem
>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (response) =>
    response?.Results?.map((item) => ({
      ID: item.ID,
      Name: item.Name,
      Action: item.Action as number,
      IsClosed: item.IsClosed,
      Color: item.Color,
    })),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: listCaseStatusesQuery,
      getQueryRequest: (request) => request?.InitialPageConfig?.Filter?.Name,
      initialRequest: {
        InitialPageConfig: {
          Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          Filter: {
            Name: undefined,
          },
        },
      },
      queryKey: ["ListCaseStatuses:SearchListField"],
      configureLoadMoreButton: (response) => ({
        shouldShowLoadMoreButton: !!response?.NextPageToken,
        onLoadMore: (request) => ({
          ...request,
          InitialPageConfig: {
            ...request?.InitialPageConfig,
            Limit:
              (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
              DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
      setQueryRequest: (request, newValue) => ({
        ...request,
        InitialPageConfig: {
          ...request?.InitialPageConfig,
          Filter: { Name: newValue || undefined },
          Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
        },
      }),
    }),
});

export const { SingleSearchListField: CaseStatusLyraSearchListField } =
  LyraSearchListFieldGenerator<
    CoreManagement.ListCaseStatuses,
    ICaseStatusAutocompleteItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (response) =>
      response?.Results?.map((item) => ({
        ID: item.ID,
        Name: item.Name,
        Action: item.Action as number,
        IsClosed: item.IsClosed,
        Color: item.Color,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.case-status",
      defaultMessage: "Case status",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        query: listCaseStatusesQuery,
        getQueryRequest: (request) => request?.InitialPageConfig?.Filter?.Name,
        initialRequest: {
          InitialPageConfig: {
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              Name: undefined,
            },
          },
        },
        queryKey: ["ListCaseStatuses:SearchListField"],
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: !!response?.NextPageToken,
          onLoadMore: (request) => ({
            ...request,
            InitialPageConfig: {
              ...request?.InitialPageConfig,
              Limit:
                (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        setQueryRequest: (request, newValue) => ({
          ...request,
          InitialPageConfig: {
            ...request?.InitialPageConfig,
            Filter: { Name: newValue || undefined },
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
  });
