import { useCallback, useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";
import type { FallbackRender } from "@sentry/react/types/errorboundary";
import { motion } from "framer-motion";

import Grid from "../grid";
import Text from "../text";

const ErrorFallback: FallbackRender = ({ error }) => <FallbackComponent error={error} />;

const FallbackComponent = ({ error }: { error: Error }) => {
  const intl = useIntl();
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const setClipboard = useCallback(
    (text: string) => {
      if (navigator.clipboard.writeText) {
        navigator.clipboard
          .writeText(text)
          .then(() =>
            toast.success(
              intl.formatMessage({
                id: "generic.label.copied",
                defaultMessage: "Copied to clipboard",
              }),
            ),
          )
          .catch((err) => console.error(err));
      }
    },
    [intl],
  );

  return (
    <div className="bg-[color:rgba(176,_0,_32,_0.15)] px-5 py-[8px]">
      <div>
        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Text variant="h3" className="text-[color:var(--legacy-eva-color-error)]">
              <FormattedMessage
                id="generic.label.error-fallback-text"
                defaultMessage="Something went wrong. We will process this error as soon as we can."
              />
            </Text>
          </Grid>
          <Grid item>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item>
                <ButtonGroup>
                  <Button
                    variant="icon"
                    onPress={() => setClipboard(error?.message ?? "")}
                    tooltip={intl.formatMessage({
                      id: "generic.label.copy-to-clipboard",
                      defaultMessage: "Copy to clipboard",
                    })}
                  >
                    <SvgIcon name="copy" className="text-[var(--legacy-eva-color-error)]" />
                  </Button>
                  <Button
                    variant="icon"
                    onPress={() => setShowErrorMessage((state) => !state)}
                    tooltip={intl.formatMessage({
                      id: "generic.label.expand",
                      defaultMessage: "Expand",
                    })}
                  >
                    <motion.div animate={{ rotate: showErrorMessage ? 180 : 0 }}>
                      <SvgIcon
                        name="chevron-down"
                        className="text-[var(--legacy-eva-color-error)]"
                      />
                    </motion.div>
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      {showErrorMessage ? (
        <div className="bg-[color:rgb(41,_1,_1)] p-5 text-[color:rgb(254,_130,_131)]">
          {error?.message}
        </div>
      ) : null}
    </div>
  );
};

export default ErrorFallback;
