import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listPaymentMethodsLoaderQuery,
  serviceQuery: listPaymentMethodsQuery,
  serviceQueryKeys: listPaymentMethodsQueryKeys,
  useServiceQueryHook: useListPaymentMethodsQuery,
} = createServiceQuery(CoreManagement.ListPaymentMethods, true);

export const LIST_CUSTOM_PAYMENT_METHODS_QUERY_KEY = "PaymentMethods:Custom";

export const {
  serviceLoaderQuery: getPaymentMethodByIDLoaderQuery,
  useServiceQueryHook: useGetPaymentMethodByIDQuery,
} = createServiceQuery(CoreManagement.GetPaymentMethodByID, true);
