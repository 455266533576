import { Button } from "@new-black/lyra";

export type ChooseTwoFactorAuthenticatorListProps = {
  twoFactorAuthenticators?: EVA.Core.TwoFactorAuthenticatorData[];
  onClick: (authenticator: EVA.Core.TwoFactorAuthenticatorData) => void;
};

export const ChooseTwoFactorAuthenticatorList = ({
  onClick,
  twoFactorAuthenticators,
}: ChooseTwoFactorAuthenticatorListProps) => {
  return (
    <div className="mb-2 flex w-full flex-col gap-y-2">
      {twoFactorAuthenticators?.map((authenticator) => (
        <ListButton key={authenticator.Name} authenticator={authenticator} onClick={onClick} />
      ))}
    </div>
  );
};

const ListButton = ({
  authenticator,
  onClick,
}: {
  authenticator: EVA.Core.TwoFactorAuthenticatorData;
  onClick: ChooseTwoFactorAuthenticatorListProps["onClick"];
}) => {
  return (
    <Button fullWidth variant="secondary" onPress={() => onClick(authenticator)}>
      {authenticator.Name}
    </Button>
  );
};
