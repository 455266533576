import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import { IProductSetProduct } from "components/suite-composite/product-set-modal/types";
import { ProductSummary } from "components/suite-composite/product-summary";
import ProgressBar from "components/suite-ui/progress-bar";
import Table from "components/suite-ui/table";
import { ITableProps } from "components/suite-ui/table/table-types";
import { searchProductsQuery } from "models/products";

import AddActionButton from "../action-buttons/add-action-button";
import EditActionButton from "../action-buttons/edit-action-button";
import SearchActionButton from "../action-buttons/search-action-button";

import { EvaProductSetProductIDsModal } from "./eva-product-set-product-ids-modal";
import { EvaProductSetProductIDsPreviewModal } from "./eva-product-set-product-ids-preview-modal";

export const EVAProductSetProductIDsField = ({
  passive,
  productIDs,
  setProductIDs,
}: {
  productIDs?: number[];
  setProductIDs: (newValue: number[]) => void;
  passive?: boolean;
}) => {
  const intl = useIntl();

  const [isSelectProductIDsModalOpen, setIsSelectProductIDsModalOpen] = useState(false);
  const [isProductsPreviewModalOpen, setIsProductsPreviewModalOpen] = useState(false);

  const { data, isFetching: isLoading } = useQuery({
    ...searchProductsQuery({
      PageSize: productIDs?.length,
      IncludedFields: ["product_id", "backend_id", "custom_id", "display_value", "primary_image"],
      Filters: {
        product_id: {
          Values: productIDs,
        },
      },
    }),
    refetchOnWindowFocus: false,
    enabled: !!productIDs?.length,
    keepPreviousData: !!productIDs?.length,
  });

  const isLoadingWithoutPreviousResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  const includedProducts = useMemo(
    () =>
      data?.Products?.map((product) => ({
        ID: product.product_id,
        Name: product.display_value,
        ImageBlobID: product.primary_image?.blob,
        CustomID: product.custom_id,
        BackendID: product.backend_id,
      })) ?? [],
    [data?.Products],
  );

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);

  const tableConfig = useMemo<ITableProps<IProductSetProduct>>(
    () => ({
      data: includedProducts,
      skip,
      setSkip,
      limit,
      setLimit,
      total: includedProducts.length,
      columns: [
        {
          id: "product",
          Cell: ({ row }) => (
            <ProductSummary
              productName={row?.original?.Name}
              productID={row?.original?.ID}
              backendID={row?.original?.BackendID}
              customID={row?.original?.CustomID}
              productImageBlobID={row?.original?.ImageBlobID}
              imageSize={37}
            />
          ),
        },
      ],
      options: {
        title: intl.formatMessage({
          id: "generic.label.selected-products",
          defaultMessage: "Selected products",
        }),
        hideColumnHeaders: true,
      },
      customActions: !passive ? (
        <ButtonGroup className="items-center">
          {productIDs?.length ? (
            <>
              <SearchActionButton
                onPress={() => setIsProductsPreviewModalOpen(true)}
                tooltip={intl.formatMessage({
                  id: "product-filter.actions.show-selected-products",
                  defaultMessage: "Show selected products",
                })}
              />
              <EditActionButton
                isDisabled={isLoading}
                onPress={() => setIsSelectProductIDsModalOpen(true)}
              />
            </>
          ) : (
            <AddActionButton
              isDisabled={isLoading}
              onPress={() => setIsSelectProductIDsModalOpen(true)}
            />
          )}
        </ButtonGroup>
      ) : undefined,
      isLoading: isLoadingWithoutPreviousResponse,
    }),
    [
      includedProducts,
      intl,
      isLoading,
      isLoadingWithoutPreviousResponse,
      limit,
      passive,
      productIDs?.length,
      skip,
    ],
  );

  return (
    <>
      <div className="relative mt-1">
        <ProgressBar top={-4} loading={isLoading} />
        <Table {...tableConfig} />
      </div>

      {isSelectProductIDsModalOpen ? (
        <EvaProductSetProductIDsModal
          includedProducts={includedProducts}
          productIDs={productIDs ?? []}
          setProductIDs={setProductIDs}
          onClose={() => setIsSelectProductIDsModalOpen(false)}
        />
      ) : null}

      {includedProducts?.length ? (
        <EvaProductSetProductIDsPreviewModal
          products={includedProducts}
          open={isProductsPreviewModalOpen}
          onClose={() => setIsProductsPreviewModalOpen(false)}
        />
      ) : null}
    </>
  );
};
