import { useCallback, useMemo } from "react";

export interface IPageInfo {
  currentPage?: number;
  numberOfPages?: number;
}

export interface IUncountablePaginationWithoutTotal {
  start: number;
  limit: number;
  setStart: (newValue: number) => void;
  pageInfo?: IPageInfo;
}

const useUncountablePaginationWithoutTotal = ({
  limit,
  pageInfo,
  setStart,
  start,
}: IUncountablePaginationWithoutTotal) => {
  const onNext = useCallback(() => {
    setStart(start + limit);
  }, [limit, setStart, start]);

  const onPrevious = useCallback(() => {
    setStart(start - limit);
  }, [limit, setStart, start]);

  const showNext = useMemo(
    () => pageInfo?.currentPage !== pageInfo?.numberOfPages && !!pageInfo?.numberOfPages,
    [pageInfo?.currentPage, pageInfo?.numberOfPages],
  );

  const showPrevious = useMemo(() => start !== 0, [start]);

  return {
    onNext,
    onPrevious,
    showNext,
    showPrevious,
  };
};

export default useUncountablePaginationWithoutTotal;
