import { atomFamily } from "recoil";

// TODO: this should be removed when migrating incoming shipments, reservations etc to react router loaders; the assignee check should be done in a loader instead
/**
 * Atom family used to track the assignee user task id for the current task we're trying to access.
 * Stores the path of the overview page as the family key and the user id as the value.
 */
export const UserIdAssignedToTask = atomFamily<number | undefined | null, string | undefined>({
  key: "Task:CurrentlyAssignedUserId",
  default: null,
});
