import { useMemo } from "react";

import { useListAccountsQuery } from "models/general-ledger-accounts";
import { ONE_SECOND } from "util/base-values";

export const useAccountByObjectAccount = (objectAccount?: string) => {
  const { data: accountsResponse, isFetching } = useListAccountsQuery(
    {
      PageConfig: {
        Filter: {
          ObjectAccount: objectAccount,
        },
      },
    },
    {
      staleTime: ONE_SECOND,
      cacheTime: ONE_SECOND,
    },
  );

  const data = useMemo(
    () => (objectAccount !== "" ? accountsResponse?.Result?.Page?.[0] : undefined),
    [accountsResponse?.Result?.Page, objectAccount],
  );

  return {
    data,
    isLoading: isFetching,
  };
};
