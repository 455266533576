import { useCallback, useLayoutEffect, useState } from "react";

export interface IBoundingRectangle {
  top: number;
  left: number;
  right: number;
  width: number;
  bottom: number;
  height: number;
}

const useBoundingRectangle = (domElement: HTMLElement | null) => {
  const getBoundingRectangle = useCallback((element: HTMLElement | null) => {
    if (!element) {
      return {
        top: 0,
        left: 0,
        right: 0,
        width: 0,
        bottom: 0,
        height: 0,
      } as IBoundingRectangle;
    }
    const elementBoundingBox = element.getBoundingClientRect();
    return {
      top: elementBoundingBox.top,
      left: elementBoundingBox.left,
      right: elementBoundingBox.right,
      width: elementBoundingBox.width,
      bottom: elementBoundingBox.bottom,
      height: elementBoundingBox.height,
    } as IBoundingRectangle;
  }, []);

  const [boundingRectangle, setBoundingRectangle] = useState<IBoundingRectangle>(
    getBoundingRectangle(domElement),
  );

  const handleResize = useCallback(() => {
    if (domElement) {
      setBoundingRectangle(getBoundingRectangle(domElement));
    }
  }, [getBoundingRectangle, domElement]);

  useLayoutEffect(() => {
    if (!domElement) {
      return;
    }
    handleResize();
    if (typeof ResizeObserver === "function") {
      const resizeObserver = new ResizeObserver(() => handleResize());
      resizeObserver.observe(domElement);
      return () => {
        if (resizeObserver) {
          resizeObserver.disconnect();
        }
      };
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize, domElement]);

  return boundingRectangle;
};

export default useBoundingRectangle;
