import { createIntl, createIntlCache, IntlShape } from "react-intl";

import EN from "assets/i18n/en.json";
import { DEFAULT_LOCALE } from "util/base-values";

export const intlCache = createIntlCache();

/**
 * Helper that allows accessing the intl object outside the react context.
 * The `TranslationProvider` will update this with the current intl object whenever it is changed.
 */
export const intlAccessor = (() => {
  // start with an intl instance configured with the default EN locale
  let instance: IntlShape = createIntl(
    {
      locale: DEFAULT_LOCALE,
      messages: EN,
    },
    intlCache,
  );

  let changeListeners: Array<() => void> = [];

  /**
   * Register a callback that will be triggered whenever a new intl instance is set.
   * @returns a function that can be called to remove the listener
   */
  const onInstanceChanged = (listener: () => void) => {
    changeListeners.push(listener);

    // return an unsubscribe callback to remove the listener
    return () => {
      changeListeners = changeListeners.filter((item) => item !== listener);
    };
  };

  const setInstance = (intl: IntlShape) => {
    instance = intl;

    Object.assign(returnValue, instance);

    // notify all listeners
    changeListeners.forEach((listener) => {
      listener();
    });
  };

  const returnValue = {
    ...instance,
    setInstance,
    onInstanceChanged,
  };

  return returnValue;
})();
