import AuthRouteDefinitions from "./auth";
import ComplianceRouteDefinitions from "./compliance";
import ControlRoomDefinitions from "./control-room";
import DashboardRouteDefinitions from "./dashboard";
import FinancialsRouteDefinitions from "./financials";
import ordersRouteDefinitions from "./orders";
import OrganizationsRouteDefinitions from "./organizations";
import PeopleRouteDefinitions from "./people";
import PIMRouteDefinitions from "./pim";
import ProfileRouteDefinitions from "./profile";
import promotionsRouteDefinitions from "./promotions";
import ResourceRouteDefinitions from "./resource";
import StockManagementRouteDefinitions from "./stock-management";
import TasksRouteDefinitions from "./tasks";

const routeDefinitions = {
  auth: AuthRouteDefinitions,
  dashboard: DashboardRouteDefinitions,
  financials: FinancialsRouteDefinitions,
  controlRoom: ControlRoomDefinitions,
  orders: ordersRouteDefinitions,
  organizations: OrganizationsRouteDefinitions,
  people: PeopleRouteDefinitions,
  pim: PIMRouteDefinitions,
  profile: ProfileRouteDefinitions,
  promotions: promotionsRouteDefinitions,
  stockManagement: StockManagementRouteDefinitions,
  compliance: ComplianceRouteDefinitions,
  tasks: TasksRouteDefinitions,
  resource: ResourceRouteDefinitions,
};

export default routeDefinitions;
