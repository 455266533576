import { useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { useListPersonalizedPromotionsQuery } from "models/personalized-promotions";

import AddActionButton from "../action-buttons/add-action-button";
import ContextMenu from "../context-menu";

const EvaProductSetActions = ({
  disabled,
  onAddCustomProductSet,
  onAddPersonalizedPromotion,
  onAddProductRequirement,
  onAddProducts,
  onAddProductSearch,
}: {
  onAddProductSearch?: () => void;
  onAddProducts?: () => void;
  onAddCustomProductSet?: () => void;
  onAddPersonalizedPromotion?: () => void;
  onAddProductRequirement?: () => void;
  disabled?: boolean;
}) => {
  const intl = useIntl();

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const { data: listPersonalizedPromotions } = useListPersonalizedPromotionsQuery(
    { InitialPageConfig: { Limit: 1 } },
    {},
  );

  const contextMenuItems = useMemo(() => {
    const menuItems = [];

    if (onAddProductSearch) {
      menuItems.push({
        itemData: undefined,
        id: "add-product-search",
        label: intl.formatMessage({
          id: "generic.label.add-product-search",
          defaultMessage: "Add product search",
        }),
        onContextItemClick: onAddProductSearch,
      });
    }

    if (onAddProducts) {
      menuItems.push({
        itemData: undefined,
        id: "add-products",
        label: intl.formatMessage({
          id: "generic.label.add-products",
          defaultMessage: "Add products",
        }),
        onContextItemClick: onAddProducts,
      });
    }

    if (onAddCustomProductSet) {
      menuItems.push({
        itemData: undefined,
        id: "add-custom-product-set",
        label: intl.formatMessage({
          id: "generic.label.add-custom-product-set",
          defaultMessage: "Add custom product set",
        }),
        onContextItemClick: onAddCustomProductSet,
      });
    }

    if (onAddPersonalizedPromotion && listPersonalizedPromotions?.Results?.length) {
      menuItems.push({
        itemData: undefined,
        id: "add-personalized-promotion",
        label: intl.formatMessage({
          id: "generic.label.add-personalized-promotion",
          defaultMessage: "Add personalized promotion",
        }),
        onContextItemClick: onAddPersonalizedPromotion,
      });
    }

    if (onAddProductRequirement) {
      menuItems.push({
        itemData: undefined,
        id: "add-product-requirement",
        label: intl.formatMessage({
          id: "generic.label.add-product-requirement",
          defaultMessage: "Add product requirement",
        }),
        onContextItemClick: onAddProductRequirement,
      });
    }

    return menuItems;
  }, [
    onAddProductSearch,
    onAddProducts,
    onAddCustomProductSet,
    onAddPersonalizedPromotion,
    listPersonalizedPromotions?.Results?.length,
    onAddProductRequirement,
    intl,
  ]);

  return (
    <>
      <AddActionButton ref={ref} onPress={() => setIsContextMenuOpen(true)} isDisabled={disabled} />

      {disabled ? null : (
        <ContextMenu
          open={isContextMenuOpen}
          onClose={() => setIsContextMenuOpen(false)}
          anchor={{
            type: "anchorEl",
            anchorReferenceElement: ref.current,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          items={contextMenuItems}
        />
      )}
    </>
  );
};

export default EvaProductSetActions;
