import { useEffect } from "react";

import { useRecoilState } from "recoil";

import useParsedRouteParam from "hooks/suite-react-hooks/use-parsed-route-param";

import { discountEditedAtom } from "./edit-discount.state";

interface IEditDiscountEdited {
  edited: boolean;
}

/**
 * Component that can be used to update the editMode in store if an discount has been edited by the user.
 * This component is comes in quite handy when you are working inside a Formik component and you get the `dirty`
 * render prop from the Formik component to determine if something is changed. If you are not working inside a Formik component you could use
 * `useEditDiscountEditMode()`
 */
const EditDiscountEdited = ({ edited }: IEditDiscountEdited) => {
  const discountId = useParsedRouteParam();
  const [currentEditModeInStore, setCurrentEditModeInStore] = useRecoilState(
    discountEditedAtom(discountId),
  );

  useEffect(() => {
    if (edited && !currentEditModeInStore) {
      setCurrentEditModeInStore(true);
    }
  }, [currentEditModeInStore, edited, setCurrentEditModeInStore]);

  // Don't render an element.
  return null;
};

export default EditDiscountEdited;
