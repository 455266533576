import { PIM } from "@springtree/eva-services-pim";

import { listApplicationCulturesQuery } from "models/cultures";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";

export type TCultureSearchListFieldItem = {
  Culture: string;
  LanguageID: string;
  CountryID: string;
};

export const generateLyraCultureSearchListField = ({
  frontendFilter,
}: Partial<
  Pick<
    ISearchListFieldGeneratorProps<
      PIM.ListApplicationCultures,
      TCultureSearchListFieldItem,
      string
    >,
    "frontendFilter"
  >
>) =>
  SearchListFieldGenerator<PIM.ListApplicationCultures, TCultureSearchListFieldItem, string>({
    getItemId: (item) => item.Culture,
    getLabel: (item) => item.Culture,
    getItemFromResponse: (response) =>
      response?.Result?.map((item) => ({
        Culture: item.Culture,
        LanguageID: item.LanguageID,
        CountryID: item.CountryID,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.culture",
      defaultMessage: "Culture",
    }),
    frontendFilter,
    useItemByID: (id, currentListItems) => {
      const itemFromList = currentListItems?.find((item) => item.Culture === id);

      return { data: itemFromList };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listApplicationCulturesQuery,

        initialRequest: {},
        queryKey: ["SearchProductPropertyTypes:SearchListField"],
      }),
  });
