import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";

import Tooltip from "components/ui/tooltip";

import { PaneSidebarToggleProps } from "./pane.types";

export const SidebarToggle = ({ setSidebarOpen, sidebarOpen }: PaneSidebarToggleProps) => {
  const intl = useIntl();
  return (
    <Button
      tooltip={
        sidebarOpen
          ? intl.formatMessage({
              id: "generic.label.collapse-sidebar",
              defaultMessage: "Collapse sidebar",
            })
          : intl.formatMessage({
              id: "generic.label.expand-sidebar",
              defaultMessage: "Expand sidebar",
            })
      }
      onPress={() => setSidebarOpen(!sidebarOpen)}
      variant="icon"
    >
      <SvgIcon name={sidebarOpen ? "sidebar-right" : "sidebar-right-collapsed"} />
    </Button>
  );
};

const attachedSidebarToggleStyle = cva(
  [
    "absolute top-4 -left-10 z-30",
    "flex h-10 w-10 items-center justify-center xl:hidden",
    "bg-surface-tertiary backdrop-blur-[10px] cursor-pointer",
    "rounded-tl-md rounded-bl-md border border-solid border-default",
  ],
  {
    variants: {
      sidebarOpen: {
        true: [],
        false: ["hidden"],
      },
    },
  },
);

export const AttachedSidebarToggle = ({ setSidebarOpen, sidebarOpen }: PaneSidebarToggleProps) => {
  const intl = useIntl();
  return (
    <Tooltip
      title={intl.formatMessage({
        id: sidebarOpen ? "generic.label.collapse-sidebar" : "generic.label.expand-sidebar",
        defaultMessage: sidebarOpen ? "Collapse sidebar" : "Expand sidebar",
      })}
    >
      <button
        className={attachedSidebarToggleStyle({ sidebarOpen })}
        type="button"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        <SvgIcon name={sidebarOpen ? "sidebar-right" : "sidebar-right-collapsed"} />
      </button>
    </Tooltip>
  );
};
