import { useCallback, useState } from "react";

/* State to determine if editAllRows must show the Edit component or the default fallback
 *
 */
const useEditModeState = (initialValue?: boolean) => {
  const [editMode, setEditMode] = useState(initialValue ?? false);

  const toggleEditMode = useCallback(() => setEditMode((state) => !state), []);

  const updateEditMode = useCallback((value: boolean) => setEditMode(value), []);

  return {
    editMode,
    toggleEditMode,
    updateEditMode,
  };
};

export default useEditModeState;
