import { useState } from "react";
import { useIntl } from "react-intl";

import { useFormikContext } from "formik";

import { IRequiredAmountRadioOption } from "./eva-product-set.types";
import EvaProductSetRequiredAmountRadioButtonList from "./eva-product-set-required-amount-radio-button-list";
import EvaProductSetRequiredAmountSpentOnProductsInput from "./eva-product-set-required-amount-spent-on-products-input";
import EvaProductSetRequiredQuantityOfProductsInput, {
  IEvaProductSetRequiredQuantityOfProductsInput,
} from "./eva-product-set-required-quantity-of-products-input";

const EvaProductSetRequiredAmountInputs = ({
  onFieldBlur,
  passive,
}: Omit<IEvaProductSetRequiredQuantityOfProductsInput, "label">) => {
  const intl = useIntl();
  const { values } = useFormikContext<any>();

  const [selectedOption, setSelectedOption] = useState(
    values.requiredAmountSpentOnProducts
      ? IRequiredAmountRadioOption.AmountSpentOnProduct
      : IRequiredAmountRadioOption.QuantityOfProducts,
  );
  return (
    <>
      <div className="mb-5 mt-4">
        <EvaProductSetRequiredAmountRadioButtonList
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      </div>

      {selectedOption === IRequiredAmountRadioOption.QuantityOfProducts ? (
        <EvaProductSetRequiredQuantityOfProductsInput
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-quantity",
            defaultMessage: "Required quantity of products",
          })}
          onFieldBlur={onFieldBlur}
          passive={passive}
          required
        />
      ) : (
        <EvaProductSetRequiredAmountSpentOnProductsInput
          passive={passive}
          onFieldBlur={onFieldBlur}
        />
      )}
    </>
  );
};

export default EvaProductSetRequiredAmountInputs;
