import {
  ComponentPropsWithoutRef,
  DetailedHTMLProps,
  FormEvent,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
} from "react";
import { IntlShape } from "react-intl";
import { CellProps, Column, Row } from "react-table";

import type { MenuItemProps, TableCellProps } from "@material-ui/core";
import {
  MenuItem as MuiMenuItem,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
} from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "classnames";

import IconButton from "../icon-button";

import {
  ColumnVerticalAlignmentOptions,
  TABLE_EXPANDER_COLUMN_ID,
  TableColumnAlign,
} from "./table-types";

export const TableWrapper = ({
  children,
  disableFormWrapper,
  onSubmit,
}: {
  children: ReactNode;
  disableFormWrapper?: boolean;
  onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
}) => (!disableFormWrapper ? <form onSubmit={onSubmit}>{children}</form> : <div>{children}</div>);

interface ITableBoxProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  flex?: boolean;
  flexColumn?: boolean;
  justifyEnd?: boolean;
}

const tableBoxStyles = cva("flex", {
  variants: {
    flexColumn: {
      true: "flex-col",
      false: "flex-row",
    },
    justifyEnd: {
      true: "justify-end",
      false: "justify-start",
    },
  },
});

export const TableBox = ({ className, flex, flexColumn, justifyEnd, ...props }: ITableBoxProps) => (
  <div
    {...props}
    className={classNames(flex && tableBoxStyles({ flexColumn, justifyEnd }), className)}
  />
);

interface BodyTableRowProps extends ComponentPropsWithoutRef<typeof MuiTableRow> {
  selected?: boolean;
  isPaginationNeeded?: boolean;
  disabled?: boolean;
  disableHoverHighlightEffect?: boolean;
  className?: string;
}

const bodyTableStyles = cva(
  [
    "[color:inherit] [outline:0] align-middle",
    "[&>td]:[border-bottom:1px_solid_var(--legacy-eva-color-light-3)]",
  ],
  {
    variants: {
      disableHoverHighlightEffect: {
        false: "[&:hover]:bg-[rgba(0,0,0,0.03)]",
      },
      isPaginationNeeded: {
        true: "",
        false: "[&:last-child>td]:[border-bottom:none]",
      },
      selected: {
        true: ["!bg-overlay-subtle", "hover:!bg-overlay-medium"],
        false: "",
      },
      disabled: {
        true: "!bg-[color:var(--legacy-eva-color-light-2)]",
        false: "",
      },
    },
    defaultVariants: {
      selected: false,
      disabled: false,
      isPaginationNeeded: false,
      disableHoverHighlightEffect: false,
    },
  },
);

export const BodyTableRow = forwardRef<HTMLTableRowElement, BodyTableRowProps>(
  (
    { className, disabled, disableHoverHighlightEffect, isPaginationNeeded, selected, ...props },
    ref,
  ) => (
    <MuiTableRow
      {...props}
      ref={ref}
      className={bodyTableStyles({
        disabled,
        selected,
        className,
        isPaginationNeeded,
        disableHoverHighlightEffect,
      })}
    />
  ),
);

BodyTableRow.displayName = "BodyTableRow";

interface BodyTableCellContainerProps extends TableCellProps {
  clickable?: boolean;
  minWidth?: number;
  align: TableColumnAlign;
  columnVerticalAlignment?: ColumnVerticalAlignmentOptions;
}

const bodyTableCellContainerStyles = cva(["border-0"], {
  variants: {
    columnVerticalAlignment: {
      middle: "py-2",
      top: "py-4 align-top",
    },
    clickable: {
      true: "cursor-pointer",
      false: "",
    },
    align: {
      left: "text-left",
      right: "text-right",
      center: "text-center",
    },
  },
  defaultVariants: {
    clickable: false,
  },
});

export const BodyTableCellContainer = ({
  align,
  className,
  clickable,
  columnVerticalAlignment = "middle",
  minWidth,
  style,
  ...props
}: BodyTableCellContainerProps) => (
  <MuiTableCell
    {...props}
    style={{ minWidth: minWidth ? `${minWidth}px` : undefined, ...style }}
    className={bodyTableCellContainerStyles({
      align,
      clickable,
      className,
      columnVerticalAlignment,
    })}
  />
);

interface BodyTableCellProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  align: TableColumnAlign;
  columnVerticalAlignment?: ColumnVerticalAlignmentOptions;
  disabled?: boolean;
}

const bodyTableCellStyles = cva("flex", {
  variants: {
    align: {
      left: "justify-start",
      right: "justify-end",
      center: "justify-center",
    },
    columnVerticalAlignment: {
      middle: "-my-1.5 min-h-[44px] items-center",
      top: "items-start",
    },
    disabled: {
      true: "text-[color:#9b9b9b]",
      false: "",
    },
  },
  defaultVariants: {
    disabled: false,
  },
});

export const BodyTableCell = ({
  align,
  className,
  columnVerticalAlignment = "middle",
  disabled,
  ...props
}: BodyTableCellProps) => (
  <div
    {...props}
    className={bodyTableCellStyles({
      align,
      columnVerticalAlignment,
      disabled,
      className,
    })}
  />
);

export const MenuItem = forwardRef((props: MenuItemProps, ref: ForwardedRef<HTMLLIElement>) => (
  <MuiMenuItem {...props} ref={ref} button={true} className="w-full px-[20px] py-[8px]" />
));

MenuItem.displayName = "MenuItem";

export const CustomRowActionsWrapper = ({ children }: { children: ReactNode }) => (
  <div className="[&_.MuiIconButton-root]:-mr-[8px]">{children}</div>
);

type ExpandRowToggleOptions<T extends object = any> = Omit<
  Partial<Column<T>>,
  "Cell" | "id" | "accessor"
> & {
  /** IntlShape object */
  intl: IntlShape;
  /** `"disable" | "hide"` (defaults to `"disable"`)
   *
   * - `"disable"` - the toggle button will be disabled if the row has no children.
   * - `"hide"` - the toggle button will not be rendered if the row has no children.
   */
  leafStrategy?: "disable" | "hide";
  /** function that returns true if the row has children / can be expanded */
  rowHasChildren: (row: Row<T>) => boolean;
  isRowDisabled?: (row: Row<T>) => boolean;
  renderAdditionalContent?: (rowData: T) => ReactNode;
};

/** Creates a column that can be used to toggle row expansion.
 *
 * @param intl - IntlShape object
 * @param rowHasChildren - function that returns true if the row has children / can be expanded
 * @param leafStrategy - `"disable" | "hide"` (defaults to `"disable"`)
 *     - `"disable"` - the toggle button will be disabled if the row has no children.
 *     - `"hide"` - the toggle button will not be rendered if the row has no children.
 */
export const expandRowToggleColumn = <T extends object = any>({
  intl,
  isRowDisabled,
  leafStrategy = "hide",
  renderAdditionalContent,
  rowHasChildren,
  ...props
}: ExpandRowToggleOptions<T>): Column<T> => ({
  width: "1px",
  Header: () => null,
  ...props,
  id: TABLE_EXPANDER_COLUMN_ID,
  Cell: ({ row, state }: CellProps<T>) => (
    <div className="flex items-center gap-5">
      {leafStrategy === "hide" && !rowHasChildren(row) ? null : (
        <IconButton
          edge="start"
          className="-mx-4"
          label={
            state.expanded?.[row.id]
              ? intl.formatMessage({
                  id: "generic.label.close",
                  defaultMessage: "Close",
                })
              : intl.formatMessage({
                  id: "generic.label.open",
                  defaultMessage: "Open",
                })
          }
          disabled={isRowDisabled?.(row) || (leafStrategy === "disable" && !rowHasChildren(row))}
          onClick={(e) => {
            e.stopPropagation();
            row.toggleRowExpanded();
          }}
        >
          <div className={classNames("duration-200", !state.expanded?.[row.id] && "-rotate-180")}>
            <SvgIcon name="chevron-up" />
          </div>
        </IconButton>
      )}
      {renderAdditionalContent ? renderAdditionalContent(row.original) : null}
    </div>
  ),
});
