import { useEffect, useState } from "react";

export function useScreenWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  // Use the useEffect hook to add a listener for the 'resize' event
  // and update the width in state whenever the event is triggered
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return width;
}

// Hook that returns a boolean value indicating whether the screen width is greater than the threshold
export function useScreenIsWiderThan(threshold: number) {
  const width = useScreenWidth();
  return width > threshold;
}

// Hook that returns a boolean value indicating whether the screen width is less than the threshold
export function useScreenIsNarrowerThan(threshold: number) {
  const width = useScreenWidth();
  return width < threshold;
}
