import { useMemo, useRef, useState } from "react";

import NoImage from "assets/images/no_image.svg?react";
import { useRootRouteData } from "routes/root";

/** Implementation carried over from Admin 1.0 Angular code: https://github.dev/new-black/eva-admin/blob/4c3df6bac4dd59eabf1ffc3ce404971a1a9a371f/src/core/components/product-image-angular1.component.ts */
export function EvaProductImage({
  className,
  height = 56,
  productId,
  width = 56,
}: {
  width?: number;
  height?: number;
  productId?: number;
  className?: string;
}) {
  const [productImageError, setProductImageError] = useState(false);
  const imgRef = useRef<HTMLImageElement>(null);
  const { applicationConfiguration } = useRootRouteData();

  const assetsUrl = useMemo(
    () => applicationConfiguration?.value?.Configuration["Urls:Assets"] as string | undefined,
    [applicationConfiguration],
  );

  const productImageUrl = useMemo(
    () =>
      assetsUrl && productId
        ? `${assetsUrl}/productimage/${width}/${height}/${productId}.jpg`
        : undefined,
    [assetsUrl, width, height, productId],
  );

  return (
    <div className={className}>
      {productImageUrl && !productImageError ? (
        <img
          ref={imgRef}
          alt="product"
          src={productImageUrl}
          style={{ width, height }}
          onError={() => {
            if (imgRef.current) {
              setProductImageError(true);
            }
          }}
        />
      ) : (
        <NoImage width={width} height={height} />
      )}
    </div>
  );
}
