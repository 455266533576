import { ReactNode, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { ConfirmationModal } from "components/suite-composite/confirmation-modal";

import useCompleteTasks from "./use-complete-tasks";
import useWarningDialog from "./use-warning-dialog";

interface ITasksTableCompleteAction<T> {
  selectedLines: T[];
  refetchTasks: () => void;
  emptySelectedLines: () => void;
  completeTasksCustomIcon?: ReactNode;
  completeMultipleAtOnce?: boolean;
}

const TasksTableCompleteAction = <T extends EVA.Core.AvailableUserTaskDto>({
  completeMultipleAtOnce,
  completeTasksCustomIcon,
  emptySelectedLines,
  refetchTasks,
  selectedLines,
}: ITasksTableCompleteAction<T>) => {
  const intl = useIntl();
  const completeTasksService = useCompleteTasks(
    emptySelectedLines,
    refetchTasks,
    completeMultipleAtOnce,
  );

  const handleCompleteTask = useCallback(() => {
    completeTasksService(selectedLines.map((item) => item.ID));
  }, [completeTasksService, selectedLines]);

  const { onCloseWarningDialog, setShowWarningDialog, showWarningDialog } =
    useWarningDialog(handleCompleteTask);

  return (
    <>
      <Button
        tooltip={intl.formatMessage({
          id: "complete-tasks.label",
          defaultMessage: "Complete task(s)",
        })}
        isDisabled={!selectedLines.length}
        onPress={() => setShowWarningDialog(true)}
      >
        {completeTasksCustomIcon ?? <SvgIcon name="shipping" />}
      </Button>
      <ConfirmationModal
        isOpen={showWarningDialog}
        onCancel={() => onCloseWarningDialog(false)}
        onConfirm={() => onCloseWarningDialog(true)}
        maxWidth="xs"
        messages={{
          titleMessage: (
            <FormattedMessage id="complete-tasks.label" defaultMessage="Complete task(s)" />
          ),
          descriptionMessage: (
            <FormattedMessage
              id="complete-tasks.warning-dialog.description"
              defaultMessage="Are you sure you want to complete {numberOfTasks} task(s)?"
              values={{ numberOfTasks: selectedLines?.length ?? 0 }}
            />
          ),
        }}
      />
    </>
  );
};

export default TasksTableCompleteAction;
