import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { TagInput } from "@new-black/lyra";
import { isEmpty } from "lodash";

export interface ICashHandlerDenominationsFormField {
  name: string;
  label: string;
  error?: string;
  initialDenominations?: number[];
  required?: boolean;
}

const CashHandlerDenominationsFormField = ({
  error,
  initialDenominations = [],
  label,
  name,
  required,
}: ICashHandlerDenominationsFormField) => {
  const intl = useIntl();

  const [denominations, setDenominations] = useState<string[]>(
    initialDenominations?.sort((a, b) => a - b)?.map((denomination) => denomination.toString()),
  );

  const handleSelectedItems = useCallback((selectedDenominations: string[]) => {
    setDenominations(() => {
      const uniqueValues = Array.from(new Set([...selectedDenominations]))
        .map((selectedDenomination) => parseFloat(selectedDenomination))
        .filter((parsedValue) => !isNaN(parsedValue) && parsedValue > 0)
        .sort((a, b) => a - b)
        .map((denomination) => denomination.toString());

      return uniqueValues;
    });
  }, []);

  return (
    <>
      <TagInput
        fullWidth
        label={label}
        inputMode="numeric"
        errorMessage={error}
        value={denominations}
        isRequired={required}
        description={intl.formatMessage({
          id: "generic.label.tag-input-positive-nonzero",
          defaultMessage: "Press enter or comma to add a value. All values must be greater than 0",
        })}
        onKeyDown={(e) => {
          const decimalNumberRegex = /^[0-9.]$/;
          if (!decimalNumberRegex.test(e.key) && e.key !== "Backspace" && e.key !== "Tab")
            e.preventDefault();
        }}
        onChange={handleSelectedItems}
      />
      {isEmpty(denominations) ? null : (
        <input name={name} type="hidden" value={JSON.stringify(denominations)} />
      )}
    </>
  );
};

export default CashHandlerDenominationsFormField;
