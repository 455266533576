import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Text, TextField } from "@new-black/lyra";
import { produce } from "immer";
import { v4 as uuidV4 } from "uuid";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";

import { EnumValuesType } from "../types";

interface IEnumOptionsControlled {
  multiple?: boolean;
  passive?: boolean;
  enums?: EnumValuesType;
  setEnums: (newValue?: EnumValuesType) => void;
  error?: string;
}

const EnumOptionsControlled = ({
  enums,
  error,
  multiple,
  passive,
  setEnums,
}: IEnumOptionsControlled) => {
  const intl = useIntl();

  const enumEntries = useMemo(() => {
    return !passive
      ? Object.entries({
          ...enums,
          [uuidV4()]: { key: "", value: "", error: undefined },
        })
      : Object.entries({
          ...enums,
        });
  }, [enums, passive]);

  const setEnumValue = useCallback(
    (uuidKey: string, newValue?: string) => {
      const newObj = produce(enums ?? {}, (draft) => {
        if (draft[uuidKey]) {
          draft[uuidKey].value = newValue ?? "";
        } else {
          draft[uuidKey] = {
            value: newValue ?? "",
            key: "",
          };
        }
      });

      setEnums(newObj);
    },
    [enums, setEnums],
  );

  const setEnumKey = useCallback(
    (uuidKey: string, newKey?: string) => {
      const hasDuplicates = Object.values(enums ?? {}).some(({ key }) => key === newKey);

      const newObj = produce(enums ?? {}, (draft) => {
        if (draft[uuidKey]) {
          draft[uuidKey].key = newKey ?? "";
        } else {
          draft[uuidKey] = {
            value: "",
            key: newKey ?? "",
          };
        }

        draft[uuidKey].error = hasDuplicates
          ? intl.formatMessage(
              {
                id: "validation.duplicate-backend-id",
                defaultMessage: "Backend ID {value} is duplicate.",
              },
              { value: newKey },
            )
          : undefined;
      });

      setEnums(newObj);
    },
    [enums, intl, setEnums],
  );

  const deleteEnumRow = useCallback(
    (uuidKey: string) => {
      const newEnums = produce(enums ?? {}, (draft) => {
        delete draft[uuidKey];
        return draft;
      });

      setEnums(newEnums ?? {});
    },
    [enums, setEnums],
  );

  return (
    <>
      {enumEntries.map(([uuidKey, { error, key, value }], index) => (
        <div key={uuidKey} className="flex w-full items-center gap-4">
          <span>
            {multiple ? (
              <div className="solid h-4 w-4 rounded-xs border border-default" />
            ) : (
              <div className="solid h-4 w-4 rounded-full border border-default" />
            )}
          </span>

          <TextField
            value={key}
            placeholder={intl.formatMessage({
              id: "generic.label.backend-id",
              defaultMessage: "Backend ID",
            })}
            hideInputHeader
            onChange={(value) => {
              setEnumKey(uuidKey, value);
            }}
            errorMessage={error}
            isReadOnly={passive}
          />
          <TextField
            value={value}
            placeholder={intl.formatMessage({
              id: "custom-field.input.enum-options.value",
              defaultMessage: "Fill in to create another option",
            })}
            hideInputHeader
            onChange={(value) => {
              setEnumValue(uuidKey, value);
            }}
            isReadOnly={passive}
          />
          {!passive ? (
            <div>
              {index !== enumEntries.length - 1 ? (
                <DeleteActionButton onPress={() => deleteEnumRow(uuidKey)} />
              ) : (
                <div className="w-[36px]" />
              )}
            </div>
          ) : null}
        </div>
      ))}

      {error ? (
        <Text variant="body-small" color="error">
          {error}
        </Text>
      ) : null}
    </>
  );
};

export default EnumOptionsControlled;
