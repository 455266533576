export default {
  module: {
    path: "/organizations",
  },
  organizations: {
    overview: {
      path: "/organizations/organizations",
    },
    create: {
      path: "/organizations/organizations/new",
    },
    details: {
      path: "/organizations/organizations/:id",
      basicInformation: {
        path: "/organizations/organizations/:id/basic-information",
        editBasicInformation: {
          path: "/organizations/organizations/:id/basic-information/edit-basic-information",
        },
        editContactInformation: {
          path: "/organizations/organizations/:id/basic-information/edit-contact-information",
        },
        editAdvancedInformation: {
          path: "/organizations/organizations/:id/basic-information/edit-advanced-information",
        },
        addOrganizationLanguage: {
          path: "/organizations/organizations/:id/basic-information/add-organization-language",
        },
        deleteOrganizationLanguage: {
          path: "/organizations/organizations/:id/basic-information/delete-organization-language",
        },
      },
      customFields: {
        path: "/organizations/organizations/:id/custom-fields",
      },
      financial: {
        path: "/organizations/organizations/:id/financial",
        editFinancialInformation: {
          path: "/organizations/organizations/:id/financial/edit-financial-information",
        },
        addCurrency: {
          path: "/organizations/organizations/:id/financial/add-currency",
        },
        editCurrency: {
          path: "/organizations/organizations/:id/financial/edit-currency/:currencyID",
        },
      },
      logistics: {
        path: "/organizations/organizations/:id/logistics",
        addAvailableCountry: {
          path: "/organizations/organizations/:id/logistics/add-available-country",
        },
        deleteAvailableCountry: {
          path: "/organizations/organizations/:id/logistics/delete-available-country",
        },
        addShippingMethod: {
          path: "/organizations/organizations/:id/logistics/add-shipping-method",
        },
        deleteShippingMethod: {
          path: "/organizations/organizations/:id/logistics/delete-shipping-method",
        },
      },
      openingHours: {
        path: "/organizations/organizations/:id/opening-hours",
        editRegularOpeningHours: {
          path: "/organizations/organizations/:id/opening-hours/edit-regular-opening-hours",
        },
        addSpecialOpeningHours: {
          path: "/organizations/organizations/:id/opening-hours/add-special-opening-hours",
        },
        editSpecialOpeningHours: {
          path: "/organizations/organizations/:id/opening-hours/edit-special-opening-hours",
        },
        deleteSpecialOpeningHours: {
          path: "/organizations/organizations/:id/opening-hours/delete-special-opening-hours",
        },
        restoreTemplate: {
          path: "/organizations/organizations/:id/opening-hours/restore-template",
        },
      },
      sets: {
        path: "/organizations/organizations/:id/sets",
      },
      flights: {
        path: "/organizations/organizations/:id/flights",
        importFlights: {
          path: "/organizations/organizations/:id/flights/import-flights",
        },
        downloadTemplate: {
          path: "/organizations/organizations/:id/flights/download-flights-template",
        },
      },
      fiscalSettings: {
        path: "/organizations/organizations/:id/fiscal-settings",
        editFiscalSetting: {
          path: "/organizations/organizations/:id/fiscal-settings/:key/edit",
        },
      },
      organizationUnitStructure: {
        path: "/organizations/organizations/:id/organization-unit-structure",
      },
    },
  },
  openingHours: {
    path: "/organizations/opening-hours",
    templates: {
      path: "/organizations/opening-hours/templates",
      details: {
        path: "/organizations/opening-hours/templates/:id",
        editGeneralInformation: {
          path: "/organizations/opening-hours/templates/:id/edit-general-information",
        },
        attachOrganizationUnitSets: {
          path: "/organizations/opening-hours/templates/:id/attach-ou-set",
        },
        detachOrganizationUnitSet: {
          path: "/organizations/opening-hours/templates/:id/detach-ou-set/:ouSetId",
        },
        editRegularOpeningHours: {
          path: "/organizations/opening-hours/templates/:id/edit-regular-opening-hours/:dayIndex",
        },
        editSpecialOpeningHours: {
          path: "/organizations/opening-hours/templates/:id/edit-special-opening-hours/:date",
        },
        addSpecialOpeningHours: {
          path: "/organizations/opening-hours/templates/:id/add-special-opening-hours",
        },
        deleteSpecialOpeningHours: {
          path: "/organizations/opening-hours/templates/:id/delete-special-opening-hours/:date",
        },
      },
      create: {
        path: "/organizations/opening-hours/templates/create",
      },
      duplicate: {
        path: "/organizations/opening-hours/templates/duplicate/:id",
      },
    },
    types: {
      path: "/organizations/opening-hours/types",
      edit: {
        path: "/organizations/opening-hours/types/:id/edit",
      },
      delete: {
        path: "/organizations/opening-hours/types/:id/delete",
      },
      create: {
        path: "/organizations/opening-hours/types/new",
      },
    },
  },
  organizationsSets: {
    overview: {
      path: "/organizations/organization-sets",
      createSet: {
        path: "/organizations/organization-sets/create",
      },
    },
    details: {
      path: "/organizations/organization-sets/:id",
      updateSetModal: {
        path: "/organizations/organization-sets/:id/update-set",
      },
      addLanguageFilter: {
        path: "/organizations/organization-sets/:id/add-language-filter",
      },
      addCountryFilter: {
        path: "/organizations/organization-sets/:id/add-country-filter",
      },
      addTypeFilter: {
        path: "/organizations/organization-sets/:id/add-type-filter",
      },
      editLanguageFilter: {
        path: "/organizations/organization-sets/:id/edit-language-filter",
      },
      editCountryFilter: {
        path: "/organizations/organization-sets/:id/edit-country-filter",
      },
      editTypeFilter: {
        path: "/organizations/organization-sets/:id/edit-type-filter",
      },
      deleteLanguageFilter: {
        path: "/organizations/organization-sets/:id/delete-language-filter",
      },
      deleteCountryFilter: {
        path: "/organizations/organization-sets/:id/delete-country-filter",
      },
      deleteTypeFilter: {
        path: "/organizations/organization-sets/:id/delete-type-filter",
      },
    },
  },
  companies: {
    overview: {
      path: "/organizations/companies",
    },
    new: {
      path: "/organizations/companies/new",
    },
    details: {
      path: "/organizations/companies/:id",
      information: {
        path: "/organizations/companies/:id/information",

        visitorsAddress: {
          path: "/organizations/companies/:id/information/visitors-address",
        },
      },
      organizationUnits: {
        path: "/organizations/companies/:id/organization-units",
      },
      auditingFields: {
        path: "/organizations/companies/:id/auditing-fields",
      },
      countries: {
        path: "/organizations/companies/:id/countries",
      },
    },
  },
  visibilityGroups: {
    overview: {
      path: "/organizations/visibility-groups",
    },
    new: {
      path: "/organizations/visibility-groups/new",
    },
    details: {
      path: "/organizations/visibility-groups/:id",
    },
  },
} as const;
