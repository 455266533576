import { IntlShape } from "react-intl";

import { AmountType } from "../amount-types";

export const getAmountTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<AmountType, string> = {
    [AmountType.AdministrativeCosts]: intl.formatMessage({
      id: "invoice.details.edit.payments.amount-type.administrativecosts",
      defaultMessage: "Administration costs",
    }),
    [AmountType.CostOfGoods]: intl.formatMessage({
      id: "invoice.details.edit.payments.amount-type.costofgoods",
      defaultMessage: "Cost of goods",
    }),
    [AmountType.OtherCosts]: intl.formatMessage({
      id: "invoice.details.edit.payments.amount-type.othercosts",
      defaultMessage: "Other",
    }),
    [AmountType.ShippingCosts]: intl.formatMessage({
      id: "invoice.details.edit.payments.amount-type.shippingcosts",
      defaultMessage: "Shipping costs",
    }),
  };

  return (amountType: AmountType) => {
    return translationMap[amountType];
  };
};
