import { useCallback, useEffect, useMemo, useState } from "react";

import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { ServiceQueryWithRequest } from "types/query.types";

interface IMinimalRequest<Filters> {
  InitialPageConfig?: EVA.Core.PageTokenConfig<Filters>;
}

const useUncountablePaginationFilters = <Filters>(
  serviceQueryWithRequest: ServiceQueryWithRequest<IMinimalRequest<Filters> | undefined, unknown>,
  initialFilters?: Filters,
) => {
  const [filterState, setFilterState] = useState(
    serviceQueryWithRequest.request?.InitialPageConfig?.Filter,
  );

  const debouncedFilterState = useDebounce(filterState, 500);

  const setRequest = useMemo(
    () => serviceQueryWithRequest.setRequest,
    [serviceQueryWithRequest.setRequest],
  );

  // Set request when debounced filter state changes
  useEffect(() => {
    setRequest((current) => ({
      ...current,
      InitialPageConfig: { ...current?.InitialPageConfig, Filter: debouncedFilterState },
    }));
  }, [debouncedFilterState, setRequest]);

  const getFilter = useCallback(
    <T extends keyof Filters>(filterName: T) => filterState?.[filterName],
    [filterState],
  );

  const setFilter = useCallback(
    <T extends keyof Filters>(filterName: T, newValue?: Filters[T]) =>
      setFilterState((current) => ({ ...current, [filterName]: newValue })),
    [],
  );

  const resetFilters = useCallback(() => setFilterState(initialFilters), [initialFilters]);

  return { getFilter, setFilter, resetFilters };
};

export default useUncountablePaginationFilters;
