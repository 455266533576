import { useCallback, useMemo, useState } from "react";
import { FixedSizeList, FixedSizeListProps, ListOnScrollProps } from "react-window";

import { cva } from "class-variance-authority";

import { IProductSetProduct } from "./types";

const boxWithShadowStyles = cva([], {
  variants: {
    showTopShadow: {
      true: "shadow-virtualized-list-top",
      false: [],
    },
    showBottomShadow: {
      true: "shadow-virtualized-list-bottom",
      false: [],
    },
  },
  compoundVariants: [
    {
      showTopShadow: true,
      showBottomShadow: true,
      class: "shadow-virtualized-list-both",
    },
  ],
});

export interface IProductsList {
  products: IProductSetProduct[];
  listHeight: number;
  itemSize: number;
  itemComponent: FixedSizeListProps["children"];
}

const ProductsList = ({ itemComponent, itemSize, listHeight, products }: IProductsList) => {
  const [scrollOffset, setScrollOffset] = useState(0);

  const totalItemsHeight = useMemo(() => itemSize * products.length, [itemSize, products.length]);

  const maxScrollOffset = useMemo(
    () => totalItemsHeight - listHeight,
    [listHeight, totalItemsHeight],
  );

  const showBottomShadow = useMemo(
    () => scrollOffset < maxScrollOffset,
    [maxScrollOffset, scrollOffset],
  );
  const showTopShadow = useMemo(() => scrollOffset > 0, [scrollOffset]);

  const handleScroll = useCallback((values: ListOnScrollProps) => {
    setScrollOffset(values.scrollOffset);
  }, []);

  return (
    <div className={boxWithShadowStyles({ showTopShadow, showBottomShadow })}>
      <FixedSizeList
        height={listHeight}
        itemSize={itemSize}
        itemCount={products.length}
        width="100%"
        itemData={products}
        onScroll={handleScroll}
      >
        {itemComponent}
      </FixedSizeList>
    </div>
  );
};

export default ProductsList;
