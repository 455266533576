import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

export const getUserTaskDetailsServiceState = builders.buildServiceFamilyState({
  key: "Core:GetUserTaskDetails",
  service: Core.GetUserTaskDetails,
  allowEmptyRequest: false,
  requireLoggedIn: true,
  requireEmployee: true,
});
