import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getCountrySubdivisionsQuery } from "models/countries";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateCountrySubdivisionAutocomplete = ({ CountryID }: { CountryID?: string }) =>
  AutocompleteGenerator<
    Core.GetCountrySubdivisions,
    {
      ID: string;
      Name: string;
    }
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (response) =>
      response?.CountrySubdivisions?.map((caseTopic) => ({
        ID: caseTopic.ID,
        Name: caseTopic.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.region",
      defaultMessage: "Region",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: getCountrySubdivisionsQuery,
        initialRequest: CountryID ? { CountryID } : undefined,
      }),
    useItemByID: (id, itemsFromResponse) => {
      const subdivision = useMemo(
        () => itemsFromResponse?.find((item) => item.ID === id),
        [id, itemsFromResponse],
      );

      return {
        data: subdivision,
      };
    },
  });

export const generateCountrySubdivisionSearchListField = ({ CountryID }: { CountryID?: string }) =>
  SearchListFieldGenerator<
    Core.GetCountrySubdivisions,
    {
      ID: string;
      Name: string;
    },
    string
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    getItemFromResponse: (response) =>
      response?.CountrySubdivisions?.map((caseTopic) => ({
        ID: caseTopic.ID,
        Name: caseTopic.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.region",
      defaultMessage: "Region",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getCountrySubdivisionsQuery,
        initialRequest: CountryID ? { CountryID } : undefined,
      }),
    useItemByID: (id, itemsFromResponse) => {
      const subdivision = useMemo(
        () => itemsFromResponse?.find((item) => item.ID === id),
        [id, itemsFromResponse],
      );

      return {
        data: subdivision,
      };
    },
  });
