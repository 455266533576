import { useCallback } from "react";

import { useMonaco } from "@monaco-editor/react";
import type Monaco from "monaco-editor";

import { ParseTypes, ScriptEditorLanguage } from "types/monaco-editor-language";

import { parseScriptMutation } from "../helpers/parse-script";

/**
 * Returns a callback that can be used to register the code hover provider for the Script language on the monaco editor
 */
const useRegisterScriptLanguageHover = (dialect: ScriptEditorLanguage) => {
  const monaco = useMonaco();

  /**
   * Configures the code completion provider used by the monaco editor for the Script language
   *
   * @returns an `IDisposable` object that can be used to un-register the provider
   */
  const registerScriptLanguageHoverProvider = useCallback(() => {
    return monaco?.languages?.registerHoverProvider(dialect, {
      provideHover: async (model, position) => {
        let range;
        // Start with no possible content
        //
        const contents: Monaco.IMarkdownString[] = [];
        if (model) {
          const scriptResponse = await parseScriptMutation({
            Source: model.getValue(),
            Dialect: dialect,
            Type: ParseTypes.Full as number,
            DocumentationRequest: {
              Column: position.column,
              Line: position.lineNumber,
            },
          });

          // Configure the range where the hover applies
          //
          range = new monaco.Range(
            position.lineNumber,
            position.column,
            model.getLineCount(),
            model.getLineMaxColumn(model.getLineCount()),
          );

          // If there is any content to show add it to the list
          //
          const docs = scriptResponse?.response?.Documentation?.Documentation;
          if (docs) {
            contents.push({
              value: docs,
            });
          }
        }
        return { range, contents };
      },
    });
  }, [dialect, monaco]);

  return registerScriptLanguageHoverProvider;
};

export default useRegisterScriptLanguageHover;
