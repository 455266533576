import EditActionButton from "components/shared/action-buttons/edit-action-button";

import type { TableRowEditActionProps } from "./table-types";

const TableRowEditAction = ({ disabled, props, tooltipTitle }: TableRowEditActionProps) => (
  <EditActionButton
    tooltip={tooltipTitle}
    isDisabled={disabled}
    onPress={() => {
      props.row.setState(() => ({
        editing: !props.row.state.editing,
      }));
    }}
  />
);

export default TableRowEditAction;
