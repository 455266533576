import { useMemo } from "react";

import ImageSlider from "components/suite-ui/image-slider";
import useGetAssetsUrl from "hooks/use-get-assets-url";
import { useBarcode } from "util/barcode";

import { IProductInformationProps } from "../product-information.types";

const ProductImageGallery = ({ product }: IProductInformationProps) => {
  const assetsUrl = useGetAssetsUrl();

  const barcodeImageProps = useBarcode({
    Width: 500,
    Height: 500,
    alt: "img-barcode",
    Code: product?.barcode_cu,
  });

  const galleryImages = useMemo(() => {
    const galleryImageBlobs = product?.product_media?.gallery;

    const primaryImageBlob = product?.product_media?.primary_image?.blob;

    const galleryImageUrls =
      galleryImageBlobs
        ?.filter((blob) => !!blob)
        ?.map((blob: string) => `${assetsUrl}/image/500/500/${blob}.png`) ??
      (primaryImageBlob ? [`${assetsUrl}/image/500/500/${primaryImageBlob}.png`] : []) ??
      [];

    const images = galleryImageUrls.map((img: string, idx: number) => ({
      src: img,
      alt: `img-${idx}`,
    }));

    if (barcodeImageProps) {
      images.push(barcodeImageProps as { src: string; alt: string });
    }

    return images;
  }, [
    assetsUrl,
    barcodeImageProps,
    product?.product_media?.gallery,
    product?.product_media?.primary_image?.blob,
  ]);

  if (galleryImages?.length) {
    return (
      <div style={{ maxWidth: "400px", width: "100%" }}>
        <ImageSlider images={galleryImages} />
      </div>
    );
  }

  return null;
};

export default ProductImageGallery;
