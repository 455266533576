navigator.serviceWorker
  .getRegistrations()
  .then(function (registrations) {
    console.log("Found " + registrations.length + " service worker registrations");
    for (const registration of registrations) {
      registration.unregister();
      console.log("Unregistered service worker");
    }
  })
  .catch(function (err) {
    console.log("Service Worker registration failed: ", err);
  });
