import { ReactNode, useCallback, useMemo } from "react";

import { Text as LyraText, TextProps as LyraTextProps } from "@new-black/lyra";
import classNames from "classnames";
import { sortBy } from "lodash";
import { atom } from "recoil";

import {
  ILyraProductSummaryProps,
  IProductSummaryProps,
  LyraProductSummary,
  ProductSummary,
} from "components/suite-composite/product-summary";
import Grid from "components/suite-ui/grid";
import Text, { TextProps } from "components/suite-ui/text";
import useProductIDProperty from "hooks/suite-react-hooks/use-product-id-property";

import useGenerateOrderlineImage from "./use-generate-orderline-image";

type OrderLineImageDescriptionProps = {
  blob?: string;
  disableImageClick?: boolean;
  type: number;
  description?: string;
  children?: ReactNode;
  productID?: number | string;
  backendID?: number | string;
  customID?: number | string;
  additionalProductRequirements?: EVA.Core.ProductRequirementModel;
  verticalPlacement?: "top" | "center";
  disableVerticalPadding?: boolean;
  descriptionVariant?: TextProps["variant"];
  /** Makes the description render as an ExpandableText component */
  expandableDescription?: boolean;
  /** Max characters to show when expandableProductName is true */
  expandableDescriptionProps?: IProductSummaryProps["expandableProductNameProps"];
  imageSize?: number;
};

const OrderLineImageIsZoomedAtom = atom({
  key: "orderLineImageIsZoomed:Index",
  default: false,
});

/**
 * @returns
 */
const OrderLineImageDescription = ({
  additionalProductRequirements,
  backendID,
  blob,
  children,
  customID,
  description,
  descriptionVariant = "body1",
  disableImageClick,
  disableVerticalPadding,
  expandableDescription = false,
  expandableDescriptionProps,
  imageSize,
  productID,
  type,
  verticalPlacement,
}: OrderLineImageDescriptionProps) => {
  const generateImage = useGenerateOrderlineImage();
  const { getProperty: getIdProp } = useProductIDProperty();

  const itemID = useMemo(
    () => getIdProp([productID, backendID, customID]),
    [backendID, customID, getIdProp, productID],
  );

  const productRequirements = useMemo(
    () =>
      additionalProductRequirements?.RequirementModels.filter(
        (requirementModel) => requirementModel.Value,
      ),
    [additionalProductRequirements?.RequirementModels],
  );

  const getChipText = useCallback(
    ({ ID, Name, Value }: EVA.Core.ProductRequirementLineModel, id?: boolean) =>
      id ? `${ID}-${Name}-${Value}` : `${Name}: ${Value}`,
    [],
  );

  const mappedProductRequirements = useMemo(() => {
    const sorted = sortBy(productRequirements, (productRequirement) =>
      getChipText(productRequirement),
    );
    return sorted.map((productRequirement) => getChipText(productRequirement));
  }, [getChipText, productRequirements]);

  return (
    <Grid
      container
      wrap="nowrap"
      className={disableVerticalPadding ? "py-0" : "py-2"}
      alignItems={verticalPlacement === "top" ? "flex-start" : "center"}
    >
      {type === 0 ? (
        <ProductSummary
          customID={customID}
          backendID={backendID}
          productID={productID}
          productImageBlobID={blob}
          productName={description ?? ""}
          enlargeImage={!disableImageClick}
          expandableProductName={expandableDescription}
          expandableProductNameProps={expandableDescriptionProps}
          className={verticalPlacement === "top" ? "items-start pt-0.5" : "items-center"}
          imageSize={imageSize}
        >
          {mappedProductRequirements?.map((productRequirement, idx) => (
            <Text key={idx} variant="body2">
              {productRequirement}
            </Text>
          ))}
          {children}
        </ProductSummary>
      ) : (
        <>
          <Grid item>{generateImage(type)}</Grid>
          <Grid item>
            <div className="ml-5">
              <Text variant={descriptionVariant}>{description}</Text>
              {itemID ? <Text variant="body2">{itemID}</Text> : null}
              {mappedProductRequirements?.map((productRequirement, idx) => (
                <Text key={idx} variant="body2">
                  {productRequirement}
                </Text>
              ))}
              {children}
            </div>
          </Grid>
        </>
      )}
    </Grid>
  );
};

const LyraOrderLineImageDescription = ({
  additionalProductRequirements,
  backendID,
  blob,
  children,
  customID,
  description,
  descriptionVariant = "body-medium",
  disableImageClick,
  disableVerticalPadding,
  expandableDescription = false,
  expandableProductNameProps,
  imageSize,
  productID,
  type,
  verticalPlacement,
}: Omit<OrderLineImageDescriptionProps, "descriptionVariant" | "expandableDescriptionProps"> & {
  descriptionVariant?: LyraTextProps["variant"];
  expandableProductNameProps?: ILyraProductSummaryProps["expandableProductNameProps"];
}) => {
  const generateImage = useGenerateOrderlineImage();
  const { getProperty: getIdProp } = useProductIDProperty();

  const itemID = useMemo(
    () => getIdProp([productID, backendID, customID]),
    [backendID, customID, getIdProp, productID],
  );

  const productRequirements = useMemo(
    () =>
      additionalProductRequirements?.RequirementModels.filter(
        (requirementModel) => requirementModel.Value,
      ),
    [additionalProductRequirements?.RequirementModels],
  );

  const getChipText = useCallback(
    ({ ID, Name, Value }: EVA.Core.ProductRequirementLineModel, id?: boolean) =>
      id ? `${ID}-${Name}-${Value}` : `${Name}: ${Value}`,
    [],
  );

  const mappedProductRequirements = useMemo(() => {
    const sorted = sortBy(productRequirements, (productRequirement) =>
      getChipText(productRequirement),
    );
    return sorted.map((productRequirement) => getChipText(productRequirement));
  }, [getChipText, productRequirements]);

  return (
    <div
      className={classNames(
        "flex",
        disableVerticalPadding ? "py-0" : "py-2",
        verticalPlacement === "top" ? "items-start" : "items-center",
      )}
    >
      {type === 0 ? (
        <LyraProductSummary
          customID={customID}
          backendID={backendID}
          productID={productID}
          productImageBlobID={blob}
          productName={description ?? ""}
          enlargeImage={!disableImageClick}
          expandableProductName={expandableDescription}
          expandableProductNameProps={expandableProductNameProps}
          className={verticalPlacement === "top" ? "items-start pt-0.5" : "items-center"}
          imageSize={imageSize}
        >
          {mappedProductRequirements?.map((productRequirement, idx) => (
            <LyraText key={idx} variant="body-small">
              {productRequirement}
            </LyraText>
          ))}
          {children}
        </LyraProductSummary>
      ) : (
        <>
          <Grid item>{generateImage(type)}</Grid>
          <Grid item>
            <div className="ml-5">
              <LyraText variant={descriptionVariant}>{description}</LyraText>
              {itemID ? <LyraText variant="body-small">{itemID}</LyraText> : null}
              {mappedProductRequirements?.map((productRequirement, idx) => (
                <LyraText key={idx} variant="body-small">
                  {productRequirement}
                </LyraText>
              ))}
              {children}
            </div>
          </Grid>
        </>
      )}
    </div>
  );
};

OrderLineImageDescription.defaultProps = {
  blob: undefined,
  description: undefined,
  productID: undefined,
};

export { OrderLineImageDescription, OrderLineImageIsZoomedAtom, LyraOrderLineImageDescription };
