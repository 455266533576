import { useCallback, useMemo } from "react";

import { uniqBy } from "lodash";

import { useCountries } from "../hooks/use-country-list";

import {
  ICountrySelectorMultiSelectStateProps,
  ICountrySelectorSingleSelectStateProps,
} from "./types";

const useCountrySelector = ({
  familyKey = "Composite:CountrySelector:useCountries-family-state",
  multi,
  selectedCountryID,
  selectedCountryIDs,
  setSelectedCountryID,
  setSelectedCountryIDs,
}: ICountrySelectorSingleSelectStateProps | ICountrySelectorMultiSelectStateProps) => {
  const { countriesList, isLoading } = useCountries(familyKey);

  const countrySelectorItems = useMemo<EVA.Core.CountryDto[] | undefined>(
    () =>
      countriesList?.map((country) => ({
        ID: country.ID,
        Name: country.Name,
      })),
    [countriesList],
  );

  const handleSelectSingleCountry = useCallback(
    (countryID: string | undefined) => {
      if (!countryID) {
        setSelectedCountryID?.(undefined);
      } else {
        setSelectedCountryID?.(countryID);
      }
    },
    [setSelectedCountryID],
  );

  const handleSelectMultiCountry = useCallback(
    (newSelectionIDs: string[]) => {
      const newSelection = uniqBy(
        [
          ...(
            newSelectionIDs.map((countryId) =>
              countrySelectorItems?.find((country) => country.ID === countryId),
            ) ?? []
          ).filter((country): country is EVA.Core.CountryDto => country !== undefined),
        ],
        "ID",
      ).map((ouSet) => ouSet.ID);
      setSelectedCountryIDs?.(newSelection.length ? newSelection : undefined);
    },
    [countrySelectorItems, setSelectedCountryIDs],
  );

  const handleDeselectMultiCountry = useCallback(
    (countryID: string) => {
      const newSelection = selectedCountryIDs?.filter((countryId) => countryId !== countryID);
      setSelectedCountryIDs?.(newSelection?.length ? newSelection : undefined);
    },
    [selectedCountryIDs, setSelectedCountryIDs],
  );

  const selectedSingleCountry = useMemo(() => {
    if (selectedCountryID) {
      return countrySelectorItems?.find((country) => country.ID === selectedCountryID);
    }
    return undefined;
  }, [countrySelectorItems, selectedCountryID]);

  const selectedMultiCountries = useMemo(() => {
    if (selectedCountryIDs?.length && countrySelectorItems?.length) {
      return selectedCountryIDs
        .map((countryID) => countrySelectorItems.find((country) => country.ID === countryID))
        .filter((country): country is EVA.Core.CountryDto => !!country);
    }
    return undefined;
  }, [countrySelectorItems, selectedCountryIDs]);

  const clear = useCallback(() => {
    (multi ? setSelectedCountryIDs : setSelectedCountryID)?.(undefined);
  }, [multi, setSelectedCountryID, setSelectedCountryIDs]);

  return {
    clear,
    isLoading,
    countrySelectorItems,
    selectedSingleCountry,
    selectedMultiCountries,
    handleSelectMultiCountry,
    handleSelectSingleCountry,
    handleDeselectMultiCountry,
  };
};

export default useCountrySelector;
