import { useCallback, useState } from "react";

import EditActionButton from "components/shared/action-buttons/edit-action-button";
import { AddBulkProductsModal } from "components/suite-composite/add-bulk-products-modal";

import { useProductFilterContext } from "../product-filter-context";
import { IProductFilterModalValues } from "../types";

export interface IProductFilterEditRowActionProps {
  rowData?: IProductFilterModalValues;
}

/**
 * Component that can be used to trigger the edit of a product filter using either the bulk products modal or the regular product filter modal.
 */
const ProductFilterEditRowAction = ({ rowData }: IProductFilterEditRowActionProps) => {
  const {
    isUpdateProductFilterLoading,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();
  const [showBulkProductsModal, setShowBulkProductsModal] = useState(false);

  const handleEdit = useCallback(() => {
    if (rowData?.Values && rowData.Values.length > 20) {
      setShowBulkProductsModal(true);
    } else {
      setOpenProductFilterModal(true);
      setCurrentProductFilterModalValues(rowData);
    }
  }, [rowData, setCurrentProductFilterModalValues, setOpenProductFilterModal]);

  const handleSaveBulkProducts = useCallback(
    (propertyName: string, values: string[]) => {
      const newProductFilter = { ...productFilter };
      if (!!rowData?.keyName && propertyName !== rowData.keyName) {
        delete newProductFilter[rowData.keyName];
      }
      newProductFilter[propertyName] = {
        Values: values,
        ExactMatch: rowData?.ExactMatch,
        Negation: rowData?.Negation,
        IncludeMissing: rowData?.IncludeMissing,
      };
      updateProductFilter(newProductFilter).then(() => {
        setShowBulkProductsModal(false);
      });
    },
    [
      productFilter,
      rowData?.ExactMatch,
      rowData?.IncludeMissing,
      rowData?.Negation,
      rowData?.keyName,
      updateProductFilter,
    ],
  );

  return (
    <>
      <EditActionButton onPress={handleEdit} />

      {showBulkProductsModal ? (
        <AddBulkProductsModal
          onSave={handleSaveBulkProducts}
          closeModal={() => setShowBulkProductsModal(false)}
          initialValues={{
            productProperty: rowData?.keyName!,
            values: rowData?.Values?.map((value) => `${value}`) ?? [],
          }}
          isLoading={isUpdateProductFilterLoading}
        />
      ) : null}
    </>
  );
};

export default ProductFilterEditRowAction;
