import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSelect } from "@new-black/lyra";
import { useField, useFormikContext } from "formik";

import { GiftCardConfigurationUsageTypes } from "types/eva-core";
import { getGiftCardConfigurationUsageTypesTranslator } from "types/translations-enums/gift-card-configuration-usage-types-translator";

interface IGiftCardUsageTypesSelectProps {
  name?: string;
  label?: string;
  value: number | undefined;
  setValue: (newValue: number | undefined) => void;
  isRequired?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
  hideHintLabel?: boolean;
}

export const GiftCardUsageTypesSelect = ({
  label,
  name,
  setValue,
  value,
  ...props
}: IGiftCardUsageTypesSelectProps) => {
  const intl = useIntl();

  const translateUsageType = useMemo(
    () => getGiftCardConfigurationUsageTypesTranslator(intl),
    [intl],
  );

  const selectItems = useMemo(
    () => [
      {
        label: translateUsageType(GiftCardConfigurationUsageTypes.Payment as number),
        value: GiftCardConfigurationUsageTypes.Payment,
      },
      {
        label: translateUsageType(GiftCardConfigurationUsageTypes.Sale as number),
        value: GiftCardConfigurationUsageTypes.Sale,
      },
    ],
    [translateUsageType],
  );

  const values = useMemo(
    () =>
      selectItems
        .filter(
          (usageType) =>
            (Number(usageType.value) & (Number(value) ?? 0)) === Number(usageType.value),
        )
        .map((usageType) => usageType.value),
    [selectItems, value],
  );

  return (
    <>
      <MultiSelect
        {...props}
        label={label}
        items={selectItems}
        value={selectItems?.filter((item) => values.includes(item.value))}
        onChange={(newValue) => {
          const mapped = newValue?.map((val) => Number(val.value) ?? 0) ?? [];
          const val = mapped.reduce((acc, curr) => acc + curr, 0);

          setValue(val === 0 ? undefined : val);
        }}
        getItemId={(item) => item.value}
        getLabel={(item) => item.label}
        selectRenderElements={(item) => ({ label: item.label })}
      />
      {name && <input type="hidden" name={name} value={value} />}
    </>
  );
};

GiftCardUsageTypesSelect.defaultProps = {
  name: undefined,
  label: undefined,
  error: undefined,
  passive: undefined,
  disabled: undefined,
  required: undefined,
  helperText: undefined,
};

export const FormikGiftCardUsageTypesSelect = ({
  label,
  name,
  ...props
}: Omit<IGiftCardUsageTypesSelectProps, "value" | "setValue" | "error" | "helperText"> & {
  name: string;
}) => {
  const { setFieldValue } = useFormikContext();

  const [field, meta] = useField(name);

  return (
    <GiftCardUsageTypesSelect
      {...props}
      name={name}
      label={label}
      value={field.value}
      errorMessage={meta.touched ? meta.error : undefined}
      setValue={(newValue) => setFieldValue(name, newValue)}
    />
  );
};
