import { ComponentProps } from "react";

export const getBarcodeImageSrc = (barcodeData: EVA.Core.BarcodeResponse | undefined) =>
  barcodeData ? `data:${barcodeData.MimeType};base64,${barcodeData.Data}` : undefined;

export const getBarcodeImgProps = (
  barcodeData: EVA.Core.BarcodeResponse | undefined,
  { alt, height, width }: { alt?: string; width?: number; height?: number },
) => {
  const barcodeImgSrc = getBarcodeImageSrc(barcodeData);
  const barcodeImgProps = barcodeImgSrc
    ? ({
        alt,
        src: barcodeImgSrc,
        width,
        height,
      } as ComponentProps<"img">)
    : undefined;
  return barcodeImgProps;
};

export const getBarcodeSize = (width: number | undefined, height: number | undefined) => ({
  width: Math.max(1, Math.min(width ?? height ?? 100, 1000)),
  height: Math.max(1, Math.min(height ?? width ?? 100, 1000)),
});
