import { IActionType } from "features/discount-edit/edit-discount.types";

export const discountActionTypesDefaultMessages: Record<IActionType, string> = {
  [IActionType.DEFAULT]: "Default",
  [IActionType.BUNDLE]: "Bundle",
  [IActionType.PRODUCTSETDISCOUNTAMOUNT]: "Product set discount amount",
  [IActionType.PRODUCTSETDISCOUNTPERCENTAGE]: "Product set discount percentage",
  [IActionType.PRODUCTSETDISCOUNTAMOUNTSETPRICE]: "Product set discount unit price",
  [IActionType.PRODUCTSETFIXEDPRICE]: "Product set fixed price",
  [IActionType.PRODUCTSETFREEPRODUCT]: "Product set free product",
  [IActionType.DISCOUNTPERCENTAGE]: "Order discount percentage",
  [IActionType.DISCOUNTAMOUNT]: "Order fixed amount",
  [IActionType.GETAPRODUCT]: "Get a product",
  [IActionType.OTHERCOSTS]: "Other costs",
  [IActionType.RITUALSEMPLOYEE]: "Rituals employee discount",
  [IActionType.TIEREDPICKAPRODUCT]: "Pick a product",
  [IActionType.ORDERTIEREDDISCOUNTPERCENTAGE]: "Sliding discount percentage",
  [IActionType.ORDERTIEREDDISCOUNTAMOUNT]: "Sliding discount amount",
  [IActionType.PRODUCTTIEREDDISCOUNTPERCENTAGE]: "Sliding discount percentage product",
  [IActionType.PRODUCTTIEREDDISCOUNTAMOUNT]: "Sliding discount amount product",
  [IActionType.CUSTOMERAGE]: "Customer age",
  [IActionType.TIEREDCUSTOMERAGE]: "Tiered customer age",
  [IActionType.ORIGINATINGORDERDISCOUNT]: "Original order discount",
  [IActionType.CUSTOMFIELDDISCOUNT]: "User custom field discount",
  [IActionType.GENERATECOUPON]: "Generated coupon",
  [IActionType.LOYALTYTIEREDDISCOUNTAMOUNT]: "Sliding points amount",
  [IActionType.LOYALTYTIEREDDISCOUNTPERCENTAGE]: "Sliding points percentage",
};
