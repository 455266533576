import { Button } from "@new-black/lyra";

import { menuItemStyle } from "../menu-item-link";

interface IWorkspaceSelectorActionItemProps {
  label: string;
  onClick: () => void;
  icon: JSX.Element;
  disabled?: boolean;
}

const WorkspaceSelectorActionItem = ({
  disabled,
  icon,
  label,
  onClick,
}: IWorkspaceSelectorActionItemProps) => (
  <Button
    className={menuItemStyle({ className: "flex justify-start border-0" })}
    variant="secondary"
    onPress={onClick}
    isDisabled={disabled}
    startIcon={icon}
  >
    {label}
  </Button>
);

export default WorkspaceSelectorActionItem;
