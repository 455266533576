import { useEffect, useMemo, useState } from "react";

import { Core } from "@springtree/eva-services-core";
import { isEqual } from "lodash";

import usePrevious from "hooks/suite-react-hooks/use-previous";
import { listOrganizationUnitsDetailedQuery } from "models/organization-units";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { removeUndefinedValues } from "util/helper";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import { useOrganizationUnitDetailsById } from "../organinzation-unit-autocomplete/use-organization-unit-details-by-id-query";

export interface OrganizationUnitSearchListFieldItem {
  Name?: string;
  BackendID?: string;
  ID?: number;
}

export const generateOrganizationUnitLyraSearchListField = (
  baseRequest?: EVA.Core.ListOrganizationUnitsDetailed,
) =>
  SearchListFieldGenerator<
    Core.ListOrganizationUnitsDetailed,
    OrganizationUnitSearchListFieldItem,
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        query: listOrganizationUnitsDetailedQuery,
        refetchOnFocus: false,
        initialRequest: {
          ...baseRequest,
          PageConfig: {
            ...baseRequest?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Start: 0,
            Filter: {
              ...baseRequest?.PageConfig?.Filter,
              NameOrBackendID: "",
            },
          },
        },

        getQueryRequest: (req) => req?.PageConfig?.Filter?.NameOrBackendID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              NameOrBackendID: newValue,
            },
          },
        }),
      }),
    getItemId: (item) => item?.ID!,
    getLabel: (item) => item.Name ?? item.ID?.toString() ?? "",
    getDescription: (item) => item.BackendID ?? "",
    getItemFromResponse: (response) => response?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit",
      defaultMessage: "Organization unit",
    }),
    useItemByID: (id) => {
      const { isLoading, item: data } = useOrganizationUnitDetailsById(
        typeof id !== "string" ? id : undefined,
      );

      return { data, isLoading };
    },
    useItemsByID: (ids) => {
      const { isLoading, items: data } = useOrganizationUnitDetailsById(
        ids?.filter((id): id is number => !!id && typeof id !== "string"),
      );

      return { data, isLoading };
    },
    selectRenderElements: (item) => ({
      label: item.Name,
      description: item.BackendID ?? "-",
    }),
  });

export const useGenerateLyraOUSearchListField = (
  request?: Partial<EVA.Core.ListOrganizationUnitsDetailed>,
) => {
  const [updatedRequest, setUpdatedRequest] = useState(request);

  const previousRequest = usePrevious(request);
  useEffect(() => {
    if (!isEqual(removeUndefinedValues(previousRequest), removeUndefinedValues(request))) {
      setUpdatedRequest(request);
    }
  }, [request, previousRequest]);

  const GeneratedLyraOUSearchListField = useMemo(() => {
    return generateOrganizationUnitLyraSearchListField(updatedRequest);
  }, [updatedRequest]);

  return GeneratedLyraOUSearchListField;
};
