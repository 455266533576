import { isNumber } from "lodash";

const enumToArray = <EnumType extends object>(enumType: EnumType) =>
  Object.entries(enumType)
    .filter(([, value]) => isNumber(value))
    .map(([key, value]) => ({ key, value } as { key: string; value: number }));

export type EnumArray = ReturnType<typeof enumToArray>;
export type EnumArrayItem = EnumArray[number];

export default enumToArray;
