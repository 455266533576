import { ListItemIcon, ListItemText as MuiListItemText, Menu } from "@material-ui/core";

import { MenuItem } from "./table-helper-components";
import type { IRowContextMenu } from "./table-types";

const RowContextMenu = ({
  activeRow,
  anchorPosition,
  handleClose,
  items,
}: IRowContextMenu<Record<string, unknown>>) => (
  <Menu
    elevation={1}
    keepMounted
    open={anchorPosition !== undefined && anchorPosition.mouseY !== null}
    onClose={handleClose}
    anchorReference="anchorPosition"
    anchorPosition={
      anchorPosition && anchorPosition.mouseY !== null && anchorPosition.mouseX !== null
        ? { top: anchorPosition.mouseY, left: anchorPosition.mouseX }
        : undefined
    }
    PaperProps={{
      className:
        "shadow-[0_1px_1px_0_rgba(0,_0,_0,_0.14),_0_2px_1px_-1px_rgba(0,_0,_0,_0.12),0_1px_3px_0_rgba(0,_0,_0,_0.2)]",
    }}
    MenuListProps={{
      className: "p-0",
    }}
  >
    {items.map((item) => (
      <MenuItem
        key={item.id}
        onClick={() => {
          item.onContextItemClick(activeRow);
          handleClose();
        }}
      >
        {item.icon && <ListItemIcon className="min-w-[30px]">{item.icon}</ListItemIcon>}
        <MuiListItemText primary={item.label} />
      </MenuItem>
    ))}
  </Menu>
);

export default RowContextMenu;
