import { memo } from "react";
import { areEqual, ListChildComponentProps } from "react-window";

import { Separator } from "@new-black/lyra";

import { IProductSetProduct } from "../types";

import AvailableProductsListItem from "./available-products-list-item";

export interface IAvailableProductsListItemWrapper
  extends ListChildComponentProps<IProductSetProduct[]> {
  placeholderImageUrl?: string;
  disabledAddProductsButton?: boolean;
  showQuantityInput?: boolean;
  hideCheckbox?: boolean;
}

const AvailableProductsListItemWrapper = memo(
  ({
    data,
    disabledAddProductsButton,
    hideCheckbox,
    index: currentIndex,
    placeholderImageUrl,
    showQuantityInput,
    style,
  }: IAvailableProductsListItemWrapper) => (
    <div style={style}>
      <AvailableProductsListItem
        product={data[currentIndex]}
        placeholderImageUrl={placeholderImageUrl}
        addButtonDisabled={disabledAddProductsButton}
        showQuantityInput={showQuantityInput}
        hideCheckbox={hideCheckbox}
      />
      {currentIndex !== data.length - 1 ? <Separator className="my-0" /> : null}
    </div>
  ),
  areEqual,
);

AvailableProductsListItemWrapper.displayName = "AvailableProductsListItemWrapper";

export default AvailableProductsListItemWrapper;
