/**
 * The clipboard is only available in secure contexts,
 * so we always need to check if it is actually available
 * before using it
 */
const safeGetClipboard = async () => {
  if (
    !navigator?.clipboard ||
    typeof navigator.clipboard.readText !== "function" ||
    typeof navigator.clipboard.writeText !== "function"
  ) {
    return undefined;
  }
  return navigator.clipboard;
};

/** Safe wrapper over `navigator.clipboard.writeText` */
const safeWriteText = async (text: string) => {
  const clipboard = await safeGetClipboard();
  if (!clipboard) {
    return undefined;
  }
  await clipboard.writeText(text);
  return text;
};

/** Safe wrapper over `navigator.clipboard.readText` */
const safeReadText = async () => {
  const clipboard = await safeGetClipboard();
  if (!clipboard) {
    return undefined;
  }
  return await clipboard.readText();
};

/** Safe wrappers around `navigator.clipboard` */
export const SafeClipboard = {
  get: safeGetClipboard,
  readText: safeReadText,
  writeText: safeWriteText,
};
