import { isEmpty, uniq } from "lodash";

/**
 * Helper that returns the unique comma or newline separated values within a string.
 * @param rawValuesString
 * @returns Array of unique values extracted from the comma or newline delimited input string, with zero-width characters, leading and trailing whitespaces removed.
 */
export const extractPropertyValues = (rawValuesString: string): string[] =>
  uniq(
    rawValuesString
      .split(/[,\n]/) // split by coma or new line
      .map((value) =>
        value
          .replace(/[\u200B-\u200D\uFEFF]/g, "") // remove zero-width characters
          .trim(),
      )
      .filter((value) => !isEmpty(value)),
  );
