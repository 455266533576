import { useEffect, useState } from "react";

import type Monaco from "monaco-editor";

export const useInitialEditorModels = (cleanupDeps?: any) => {
  const [initialNormalEditorModel, setInitialNormalEditorModel] =
    useState<Monaco.editor.ITextModel | null>(null);
  const [initialDiffEditorModel, setInitialDiffEditorModel] =
    useState<Monaco.editor.IDiffEditorModel | null>(null);

  // Make sure we dispose the normal / diff models (if applicable).
  useEffect(
    () => {
      const dispose = () => {
        if (initialNormalEditorModel) {
          const temp = initialNormalEditorModel;
          temp.dispose();
          setInitialNormalEditorModel(null);
        }

        if (initialDiffEditorModel) {
          const temp = initialDiffEditorModel;
          temp?.modified?.dispose();
          temp?.original?.dispose();
          setInitialDiffEditorModel(null);
        }
      };

      dispose();

      return () => dispose();
    },
    // Only execute this cleanup effect on unmount and when the cleanup deps change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cleanupDeps],
  );

  return {
    initialNormalEditorModel,
    setInitialNormalEditorModel,
    initialDiffEditorModel,
    setInitialDiffEditorModel,
  };
};
