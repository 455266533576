import type { MouseEvent } from "react";
import { useState } from "react";

import type { MousePosition } from "../table-types";

/**
 * Hook for context menu
 * @param initialContextMenuState
 */
const useContextMenu = (initialContextMenuState: MousePosition) => {
  // Mouse position of right click to context menu state.
  const [contextClickState, setContextClickState] =
    useState<MousePosition>(initialContextMenuState);

  // State for setting the active context row, so it can be used in the context menu items
  const [activeContextRow, setActiveContextRow] = useState<any>(null);

  const handleContextRightClick = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setContextClickState({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
    });
  };

  const handleContextMenuClose = () => {
    setContextClickState(initialContextMenuState);
    setActiveContextRow(null);
  };

  return {
    activeContextRow,
    contextClickState,
    setActiveContextRow,
    handleContextMenuClose,
    handleContextRightClick,
  };
};

export default useContextMenu;
