import { CoreManagement } from "@springtree/eva-services-core-management";

import { ListTemplateHandlersServiceQuery } from "models/template-handlers";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateListTemplateHandlersSearchListField = () =>
  SearchListFieldGenerator<CoreManagement.ListTemplateHandlers, { Name: string }, string>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: ListTemplateHandlersServiceQuery,
        refetchOnFocus: false,
      }),
    getItemId: (item) => item?.Name,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (response) => response?.Handlers.map((handler) => ({ Name: handler })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.name",
      defaultMessage: "Name",
    }),
    useItemByID: (id, items) => {
      const item = items?.find((item) => item.Name === id);
      return { data: item, isLoading: false };
    },
  });
