import { LinearProgress } from "@material-ui/core";
import classNames from "classnames";

export interface ProgressBarProps {
  loading: boolean;
  position?: "top" | "bottom";
  top?: number;
  className?: string;
}

const ProgressBar = ({ className, loading, position, top }: ProgressBarProps) => {
  if (loading) {
    return (
      <div
        className={classNames(
          "absolute left-0 right-0 w-full",
          position === "bottom" && "bottom-0",
          className,
        )}
        style={{
          top: position !== "bottom" ? top || 0 : undefined,
        }}
      >
        <LinearProgress />
      </div>
    );
  }

  return null;
};

ProgressBar.defaultProps = {
  position: "top" as const,
  top: 0,
};

export default ProgressBar;
