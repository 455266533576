import { useEffect } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { getOrganizationUnit, getOrganizationUnitServiceState } from "store/get-organization-unit";

const useOrganizationUnit = (id?: number) => {
  const organizationUnit = hooks.useGetState(getOrganizationUnit(id));
  const setGetOrganizationUnitRequest = useSetRecoilState(
    getOrganizationUnitServiceState.request(id),
  );

  useEffect(() => {
    if (id) {
      setGetOrganizationUnitRequest({ ID: id });
    }
  }, [id, setGetOrganizationUnitRequest]);

  return organizationUnit;
};

export default useOrganizationUnit;
