import { useIntl } from "react-intl";

import * as yup from "yup";

const useRangePropertyValidationSchema = () => {
  const intl = useIntl();

  const validationSchema = yup.object().shape(
    {
      keyName: yup.string().required(
        intl.formatMessage({
          id: "product-filter.modal.product-property.validation.required",
          defaultMessage: "This field is required",
        }),
      ),
      From: yup.string().when("To", {
        is: (toField) => !!toField,
        then: yup.string().nullable(),
        otherwise: yup.string().required(
          intl.formatMessage({
            id: "product-filter.modal.from.validation.required",
            defaultMessage: "This field is required",
          }),
        ),
      }),
      To: yup.string().when("From", {
        is: (fromField) => !!fromField,
        then: yup.string().nullable(),
        otherwise: yup.string().required(
          intl.formatMessage({
            id: "product-filter.modal.to.validation.required",
            defaultMessage: "This field is required",
          }),
        ),
      }),
    },
    [["To", "From"]],
  );

  return validationSchema;
};

export default useRangePropertyValidationSchema;
