import { useEffect, useMemo, useState } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { isEqual } from "lodash";

import usePrevious from "hooks/suite-react-hooks/use-previous";
import {
  listOrganizationUnitSetsQuery,
  useListOrganizationUnitSetsQuery,
} from "models/organization-unit-sets";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { removeUndefinedValues } from "util/helper";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

import { useOrganizationUnitSetDetailsById } from "../hooks/use-organization-unit-set-details-by-id-query";

import { IGeneratedOUSetSearchListFieldOptions } from "./types";

export interface IOrganizationUnitSetSearchListFieldItem {
  ID?: number;
  Name?: string;
  BackendID?: string;
  OrganizationUnitID?: number;
  OrganizationUnitBackendID?: string;
  Type?: EVA.Core.OrganizationUnitSetTypes;
}

export const useGenerateOUSetSearchListField = ({
  endAdornment,
  filters,
  frontendFilter,
  getSecondaryLabel,
}: {
  filters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>;
  endAdornment?: React.ReactNode;
  frontendFilter?: (item: IOrganizationUnitSetSearchListFieldItem) => boolean;
  getSecondaryLabel?: (item: IOrganizationUnitSetSearchListFieldItem) => string | undefined;
}) => {
  const [requestFilters, setRequestFilters] = useState(filters);

  const previousFilters = usePrevious(filters);
  useEffect(() => {
    if (!isEqual(removeUndefinedValues(previousFilters), removeUndefinedValues(filters))) {
      setRequestFilters(filters);
    }
  }, [filters, previousFilters]);

  const {
    GeneratedMultiOUSetIDSearchListFieldWithFilters,
    GeneratedMultiOUSetSearchListFieldWithFilters,
    GeneratedSingleOUSetIDSearchListFieldWithFilters,
    GeneratedSingleOUSetSearchListFieldWithFilters,
  } = useMemo(() => {
    return generateOUSetSearchListFieldWithFilters(
      requestFilters,
      {
        endAdornment,
        getSecondaryLabel:
          getSecondaryLabel ?? ((item) => item.BackendID ?? item.OrganizationUnitBackendID),
      },
      frontendFilter,
    );
  }, [endAdornment, frontendFilter, getSecondaryLabel, requestFilters]);

  const LyraOUSetSearchListFieldWithFilters = useMemo(
    () => generateOUSetLyraSearchListFieldWithFilters(requestFilters, frontendFilter),
    [frontendFilter, requestFilters],
  );

  return {
    LyraOUSetSearchListFieldWithFilters,
    GeneratedSingleOUSetSearchListFieldWithFilters,
    GeneratedSingleOUSetIDSearchListFieldWithFilters,
    GeneratedMultiOUSetSearchListFieldWithFilters,
    GeneratedMultiOUSetIDSearchListFieldWithFilters,
  };
};

function useOUSetById(id: number | undefined) {
  const { isLoading, item: data } = useOrganizationUnitSetDetailsById(id);
  return { data, isLoading };
}

function useOUSetsById(ids: (number | undefined)[] | undefined) {
  const nonNullIds = ids?.filter((id): id is number => id !== undefined);
  const { data, isFetching: isLoading } = useListOrganizationUnitSetsQuery(
    nonNullIds?.length
      ? {
          PageConfig: {
            Limit: nonNullIds.length,
            Filter: {
              IDs: nonNullIds,
            },
          },
        }
      : undefined,
    {
      refetchOnWindowFocus: false,
    },
  );
  return { data: nonNullIds?.length ? data?.Result?.Page : undefined, isLoading };
}

export const generateOUSetSearchListFieldWithFilters = (
  filters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>,
  options?: IGeneratedOUSetSearchListFieldOptions<IOrganizationUnitSetSearchListFieldItem>,
  frontendFilter?: (item: IOrganizationUnitSetSearchListFieldItem) => boolean,
) => {
  const {
    MultiIDSearchListField: GeneratedMultiOUSetIDSearchListFieldWithFilters,
    MultiSearchListField: GeneratedMultiOUSetSearchListFieldWithFilters,
    SingleIDSearchListField: GeneratedSingleOUSetIDSearchListFieldWithFilters,
    SingleSearchListField: GeneratedSingleOUSetSearchListFieldWithFilters,
  } = SearchListFieldGenerator<
    CoreManagement.ListOrganizationUnitSets,
    IOrganizationUnitSetSearchListFieldItem,
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getSecondaryLabel: options?.getSecondaryLabel,
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((ou) => ({
        ID: ou.ID,
        Name: ou.Name,
        BackendID: ou.BackendID,
        OrganizationUnitID: ou.OrganizationUnitID,
        OrganizationUnitBackendID: ou.OrganizationUnitBackendID,
        Type: ou.Type,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit-set",
      defaultMessage: "Organization unit set",
    }),
    rightAdornment: options?.endAdornment,
    useItemByID: useOUSetById,
    useItemsByID: useOUSetsById,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listOrganizationUnitSetsQuery,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { ...filters } },
        },
        getQueryRequest: (req) =>
          req?.PageConfig?.Filter?.Name ?? req?.PageConfig?.Filter?.BackendID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              NameOrBackendID: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
    frontendFilter,
  });

  return {
    GeneratedSingleOUSetSearchListFieldWithFilters,
    GeneratedSingleOUSetIDSearchListFieldWithFilters,
    GeneratedMultiOUSetSearchListFieldWithFilters,
    GeneratedMultiOUSetIDSearchListFieldWithFilters,
  };
};

export const generateOUSetLyraSearchListFieldWithFilters = (
  filters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>,
  frontendFilter?: (item: IOrganizationUnitSetSearchListFieldItem) => boolean,
) => {
  const OUSetSearchListFieldWithFilters = LyraSearchListFieldGenerator<
    CoreManagement.ListOrganizationUnitSets,
    IOrganizationUnitSetSearchListFieldItem,
    number
  >({
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((ou) => ({
        ID: ou.ID,
        Name: ou.Name,
        BackendID: ou.BackendID,
        OrganizationUnitID: ou.OrganizationUnitID,
        OrganizationUnitBackendID: ou.OrganizationUnitBackendID,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit-set",
      defaultMessage: "Organization unit set",
    }),
    useItemByID: useOUSetById,
    useItemsByID: useOUSetsById,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listOrganizationUnitSetsQuery,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { ...filters } },
        },
        getQueryRequest: (req) =>
          req?.PageConfig?.Filter?.Name ?? req?.PageConfig?.Filter?.BackendID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              NameOrBackendID: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
    frontendFilter,
    getLabel: (item) => item.Name!,
    getDescription: (item) => item.BackendID ?? "",
    getItemId: (item) => item.ID!,
    selectRenderElements: (item) => ({
      label: item.Name!,
      description: item.BackendID ?? item.OrganizationUnitBackendID ?? "-",
    }),
  });
  return OUSetSearchListFieldWithFilters;
};

export const useGenerateLyraOUSetSearchListField = ({
  filters,
  frontendFilter,
}: {
  filters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>;
  frontendFilter?: (item: IOrganizationUnitSetSearchListFieldItem) => boolean;
}) => {
  const [requestFilters, setRequestFilters] = useState(filters);

  const previousFilters = usePrevious(filters);
  useEffect(() => {
    if (!isEqual(removeUndefinedValues(previousFilters), removeUndefinedValues(filters))) {
      setRequestFilters(filters);
    }
  }, [filters, previousFilters]);

  const OUSetLyraSearchListFieldWithFilters = useMemo(() => {
    return generateOUSetLyraSearchListFieldWithFilters(requestFilters, frontendFilter);
  }, [frontendFilter, requestFilters]);

  return OUSetLyraSearchListFieldWithFilters;
};
