import { useEffect } from "react";

import { usePrompt } from "./provider";

export const useBlockedRouting = (when = false) => {
  const { setWhen } = usePrompt();

  useEffect(() => {
    setWhen(when);
    return () => setWhen(false);
  }, [setWhen, when]);
};
