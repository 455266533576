import { CSSProperties, useState } from "react";
import type { ColumnInstance } from "react-table";

import { TableCell } from "@material-ui/core";

import TableHeaderSortingIcon from "./table-header-sorting-icon";
import type { ITableColumn } from "./table-types";

interface ITableHeaderCell {
  column: ColumnInstance<any>;
  defaultBackgroundColor?: CSSProperties["background"];
}

const TableHeaderCell = ({ column, defaultBackgroundColor }: ITableHeaderCell) => {
  const [sortingIconHintVisible, setSortingIconHintVisible] = useState(false);

  return (
    <TableCell
      className="group leading-[16px] text-[color:rgba(0,0,0,.6)]"
      style={{
        width: column.width,
        textAlign: (column as Partial<ITableColumn<any>>).align ?? "left",
        background: (column as Partial<ITableColumn<any>>).background ?? defaultBackgroundColor,
      }}
      {...column.getHeaderProps(
        (column as Partial<ITableColumn<any>>).sortable ? column.getSortByToggleProps() : undefined,
      )}
      onMouseEnter={() => setSortingIconHintVisible(true)}
    >
      <div className="relative">
        {column.render("Header")}
        <TableHeaderSortingIcon
          column={column}
          sortingIconHintVisible={sortingIconHintVisible}
          setSortingIconHintVisible={setSortingIconHintVisible}
        />
      </div>
    </TableCell>
  );
};

export default TableHeaderCell;
