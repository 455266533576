import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

export function getUserTypeLabelTranslationFunction(intl: IntlShape) {
  const translationMap: Record<EVA.Core.UserTypes | 32, string> = {
    0: intl.formatMessage({ id: "generic.label.none", defaultMessage: "None" }),
    1: intl.formatMessage({
      id: "user-type-select.enum-value-labels.1",
      defaultMessage: "Employee",
    }),
    2: intl.formatMessage({
      id: "user-type-select.enum-value-labels.2",
      defaultMessage: "Customer",
    }),
    4: intl.formatMessage({
      id: "user-type-select.enum-value-labels.4",
      defaultMessage: "Anonymous",
    }),
    8: intl.formatMessage({
      id: "user-type-select.enum-value-labels.8",
      defaultMessage: "Business",
    }),
    17: intl.formatMessage({
      id: "user-type-select.enum-value-labels.17",
      defaultMessage: "System",
    }),
    32: intl.formatMessage({
      id: "user-type-select.enum-value-labels.32",
      defaultMessage: "Migrated",
    }),
    64: intl.formatMessage({
      id: "user-type-select.enum-value-labels.64",
      defaultMessage: "Debtor",
    }),
    256: intl.formatMessage({
      id: "user-type-select.enum-value-labels.256",
      defaultMessage: "Limited Trust",
    }),
    512: intl.formatMessage({
      id: "user-type-select.enum-value-labels.512",
      defaultMessage: "Tester",
    }),
    1024: intl.formatMessage({
      id: "user-type-select.enum-value-labels.1024",
      defaultMessage: "Removed By Request",
    }),
    2048: intl.formatMessage({
      id: "user-type-select.enum-value-labels.2048",
      defaultMessage: "Api",
    }),
    4096: intl.formatMessage({
      id: "user-type-select.enum-value-labels.4096",
      defaultMessage: "Impersonated",
    }),
  };

  return (userType: EVA.Core.UserTypes) => translationMap[userType];
}

export function useGetUserTypeLabel() {
  const intl = useIntl();

  const getUserTypeLabel = useMemo(() => getUserTypeLabelTranslationFunction(intl), [intl]);

  return getUserTypeLabel;
}
