import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Card, CardActions, CardContent, CardHeader, Text } from "@new-black/lyra";
import { isEmpty } from "lodash";

import {
  ILoyaltyProgramProductLimitationDataAddAction,
  LoyaltyProgramProductLimitationDataAddAction,
} from "./loyalty-program-product-limitation-data-add-action";

export const LoyaltyProgramProductLimitationDataEmptyStateCard = ({
  emptyMessage,
  ...props
}: ILoyaltyProgramProductLimitationDataAddAction & { emptyMessage?: string }) => {
  const intl = useIntl();

  const canEditProductLimitation = useMemo(() => !isEmpty(props), [props]);

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.products",
          defaultMessage: "Products",
        })}
        actions={
          canEditProductLimitation ? (
            <CardActions>
              <LoyaltyProgramProductLimitationDataAddAction {...props} />
            </CardActions>
          ) : undefined
        }
      />

      <CardContent>
        <Text color="secondary">
          {emptyMessage ?? (
            <FormattedMessage
              id="generic.label.no-data-available"
              defaultMessage="No data available."
            />
          )}
        </Text>
      </CardContent>
    </Card>
  );
};
