import libPhoneNumber from "libphonenumber-js";

import { countriesPhoneNumbers, ICountryPhoneCode } from "./types";

export const getInitialCountryPhoneCode = (
  initialPhoneNumber?: string,
  initialCountryCode?: string,
) => {
  if (!initialPhoneNumber) {
    return initialCountryCode
      ? countriesPhoneNumbers.find((el) => el.country_code === initialCountryCode)
      : undefined;
  }

  // libPhoneNumber is not able to parse phone numbers with more than 20 characters
  const libPhoneNumberCountry = libPhoneNumber(initialPhoneNumber.substring(0, 20))?.country;

  return libPhoneNumberCountry
    ? countriesPhoneNumbers.find((el) => el.country_code === libPhoneNumberCountry)
    : undefined;
};

export const getPhoneNumberWithoutPrefix = (
  phoneNumber: string,
  phoneCode: ICountryPhoneCode["phone_code"],
) => phoneNumber.split(phoneCode)[1]?.trim();
