import { useCallback } from "react";
import { useIntl } from "react-intl";

import { KeyValueRow, KeyValueRowList } from "@new-black/lyra";

import { YesNoString } from "components/ui/boolean-string";
import { LoyaltyPaymentTaxHandlingType } from "types/eva-core";
import { getLoyaltyPaymentTaxHandlingTranslator } from "types/translations-enums/loyalty-payment-tax-handling";

import { LoyaltyProgramUserBudgetDeductionBadge } from ".";

export const LoyaltyProgramPaymentTypeInformationRows = ({
  paymentType,
}: {
  paymentType?: EVA.Core.Management.GetLoyaltyProgramPaymentTypeResponse;
}) => {
  const intl = useIntl();

  const translateLoyaltyPaymentTaxHandling = useCallback(
    (value: number) =>
      getLoyaltyPaymentTaxHandlingTranslator(intl)(value) ?? LoyaltyPaymentTaxHandlingType[value],
    [intl],
  );

  return (
    <KeyValueRowList>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.payment-type-id",
          defaultMessage: "Payment type ID",
        })}
      >
        {paymentType?.PaymentTypeID}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.backend-id",
          defaultMessage: "Backend ID",
        })}
      >
        {paymentType?.BackendID}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.sequence",
          defaultMessage: "Sequence",
        })}
      >
        {paymentType?.Sequence}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.tax-handling",
          defaultMessage: "Tax handling",
        })}
      >
        {paymentType?.TaxHandling !== undefined
          ? translateLoyaltyPaymentTaxHandling(paymentType?.TaxHandling)
          : null}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.user-budget-deduction",
          defaultMessage: "User budget deduction",
        })}
        type="badge"
        classes={{ valueContainer: "flex flex-wrap gap-2" }}
      >
        {paymentType?.UserBudgetDeduction !== undefined ? (
          <LoyaltyProgramUserBudgetDeductionBadge value={paymentType?.UserBudgetDeduction} />
        ) : null}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.budget",
          defaultMessage: "Budget",
        })}
      >
        {paymentType?.Budget}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.user-budget",
          defaultMessage: "User budget",
        })}
      >
        {paymentType?.UserBudget}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.maximum-amount-per-order",
          defaultMessage: "Maximum amount per order",
        })}
      >
        {paymentType?.MaximumAmountPerOrder}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.maximum-percentage-of-user-budget-per-order",
          defaultMessage: "Maximum percentage of user budget per order",
        })}
      >
        {paymentType?.MaximumPercentageOfUserBudgetPerOrder}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-user",
          defaultMessage: "Overall quantity limit per user",
        })}
      >
        {paymentType?.ProductLimitation?.OverallQuantityLimitPerUser}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-order",
          defaultMessage: "Overall quantity limit per order",
        })}
      >
        {paymentType?.ProductLimitation?.OverallQuantityLimitPerOrder}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.excluded-from-program-budget",
          defaultMessage: "Excluded from program budget",
        })}
      >
        <YesNoString value={paymentType?.ExcludeFromProgramBudget} />
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.required",
          defaultMessage: "Required",
        })}
      >
        <YesNoString value={paymentType?.Required} />
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.allow-partial-payment",
          defaultMessage: "Allow partial payment",
        })}
      >
        <YesNoString value={paymentType?.AllowPartialPayment} />
      </KeyValueRow>
    </KeyValueRowList>
  );
};
