import { useState } from "react";

import { cva } from "class-variance-authority";
import classNames from "classnames";

import { useSwipe } from "hooks/use-swipe";

import { PaneSidebarProps } from "./pane.types";
import { PaneHeader } from "./pane-header";
import { AttachedSidebarToggle } from "./side-bar-toggle";

const sidebarStyles = cva(
  [
    "absolute right-0 z-30 h-full xl:relative",
    "bg-surface-tertiary backdrop-blur-[10px]",
    "h-full border-y-0 border-e-0 border-s border-solid border-default",
    "transition-all duration-300 ease-in-out",
    "flex flex-col",
    "min-h-[100vh]",
  ],
  {
    variants: {
      sidebarOpen: {
        true: ["w-80", "sm:w-[423px]"],
        false: ["w-0", "border-0"],
      },
    },
  },
);

export const PaneSidebar = ({
  expandFooterOnSidebar,
  setSidebarOpen,
  sideBarBottomContent,
  sideBarContent,
  sideBarHeader,
  sidebarOpen,
}: PaneSidebarProps) => {
  const [sideIsScrolled, setSideIsScrolled] = useState(false);
  const swipeElementProps = useSwipe({
    onSwipeRight: () => setSidebarOpen(false),
  });
  if (sideBarContent) {
    return (
      <aside className={sidebarStyles({ sidebarOpen })} {...swipeElementProps}>
        <PaneHeader isScrolled={sideIsScrolled}>{sideBarHeader}</PaneHeader>
        <div
          className={classNames(
            // To do: At one point we must get rid of pt-5, but for now it is needed to align it properly.
            "max-h-[100vh] flex-1 overflow-y-auto overflow-x-hidden p-4 pt-5",
            expandFooterOnSidebar && "pb-[90px]",
          )}
          onScroll={(event) => {
            setSideIsScrolled(event.currentTarget.scrollTop > 0);
          }}
        >
          {sideBarContent}
        </div>
        {sideBarBottomContent ? (
          <div className="sticky bottom-0 max-h-[400px] border-0 border-t border-solid border-default">
            {sideBarBottomContent}
          </div>
        ) : null}
        <AttachedSidebarToggle sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      </aside>
    );
  }
  return null;
};
