import { useCallback } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { countriesListResponseSelector, countriesListServiceState } from "./use-country-list.state";

const useCountries = (familyKey: string) => {
  const countriesList = hooks.useGetState(countriesListResponseSelector(familyKey));
  const isLoading = hooks.useIsLoading({
    state: countriesListServiceState.response(familyKey),
  });

  const getCountryName = useCallback(
    (countryId: string) =>
      (countriesList ?? []).find((country) => country.ID === countryId)?.Name ?? countryId,
    [countriesList],
  );

  return { countriesList, getCountryName, isLoading };
};

export default useCountries;
