import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getCheckoutOptionHandlersQuery } from "models/checkout";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const {
  MultiAutocomplete: CheckoutOptionHandleMultiAutocomplete,
  SingleAutocomplete: CheckoutOptionHandlerAutocomplete,
} = AutocompleteGenerator<CoreManagement.GetCheckoutOptionHandlers, { ID: string; Name: string }>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (resp) => resp?.Handlers?.map((handler) => ({ ID: handler, Name: handler })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.checkout-option-handler",
    defaultMessage: "Checkout option handler",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: getCheckoutOptionHandlersQuery,
      initialRequest: {},
    }),
});

export const CheckoutOptionHandlersSearchListField = SearchListFieldGenerator<
  CoreManagement.GetCheckoutOptionHandlers,
  { ID: string; Name: string },
  string
>({
  getItemFromResponse: (resp) => resp?.Handlers?.map((handler) => ({ ID: handler, Name: handler })),
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.checkout-option-handler",
    defaultMessage: "Checkout option handler",
  }),
  useItemByID: (id, items) => {
    const data = useMemo(() => {
      if (!id) {
        return undefined;
      }
      return items?.find((item) => item.ID === id);
    }, [id, items]);
    return { data, loading: false };
  },
  useItemsByID: (ids, items) => {
    const data = useMemo(() => {
      if (!ids || !ids.length) {
        return undefined;
      }
      return ids
        .map((id) => items?.find((item) => item.ID === id))
        .filter((item): item is NonNullable<typeof item> => item !== undefined);
    }, [ids, items]);
    return { data, loading: false };
  },
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      refetchOnFocus: false,
      query: getCheckoutOptionHandlersQuery,
      initialRequest: {},
    }),
});
