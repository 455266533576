import { useEffect, useMemo, useState } from "react";

import { Core } from "@springtree/eva-services-core";
import { isEqual } from "lodash";

import usePrevious from "hooks/suite-react-hooks/use-previous";
import { listOrganizationUnitsDetailedQuery } from "models/organization-units";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { removeUndefinedValues } from "util/helper";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

import { useOrganizationUnitDetailsById } from "../organinzation-unit-autocomplete/use-organization-unit-details-by-id-query";

export interface IOrganizationUnitSearchListFieldItem {
  ID?: number;
  Name?: string;
  BackendID?: string;
}

export const useGenerateOUSearchListField = ({
  enabled,
  filters,
}: {
  filters?: Partial<EVA.Core.ListOrganizationUnitsFilter>;
  enabled?: boolean;
}) => {
  const [requestFilters, setRequestFilters] = useState(filters);

  const previousFilters = usePrevious(filters);
  useEffect(() => {
    if (!isEqual(removeUndefinedValues(previousFilters), removeUndefinedValues(filters))) {
      setRequestFilters(filters);
    }
  }, [filters, previousFilters]);

  const {
    GeneratedMultiOUIDSearchListFieldWithFilters,
    GeneratedMultiOUSearchListFieldWithFilters,
    GeneratedSingleOUIDSearchListFieldWithFilters,
    GeneratedSingleOUSearchListFieldWithFilters,
  } = useMemo(() => {
    return GeneratedOUSearchListFieldWithFilters(requestFilters, enabled);
  }, [enabled, requestFilters]);

  const memoizedFilters = useMemo(() => filters, [filters]);

  const LyraOUSearchListFieldWithFilters = useMemo(
    () => GeneratedLyraOUSearchListFieldWithFilters(memoizedFilters, enabled),
    [enabled, memoizedFilters],
  );

  return {
    LyraOUSearchListFieldWithFilters,
    GeneratedMultiOUSearchListFieldWithFilters,
    GeneratedSingleOUSearchListFieldWithFilters,
    GeneratedMultiOUIDSearchListFieldWithFilters,
    GeneratedSingleOUIDSearchListFieldWithFilters,
  };
};

function useOrganizationUnitById(id: number | undefined) {
  const { isLoading, item: data } = useOrganizationUnitDetailsById(id);
  return { data, isLoading };
}

function useOrganizationUnitsById(ids: (number | undefined)[] | undefined) {
  const { isLoading, items: data } = useOrganizationUnitDetailsById(
    ids?.filter((id): id is number => !!id),
  );
  return { data, isLoading };
}

export const GeneratedOUSearchListFieldWithFilters = (
  filters?: Partial<EVA.Core.ListOrganizationUnitsFilter>,
  enabled?: boolean,
) => {
  const {
    MultiIDSearchListField: GeneratedMultiOUIDSearchListFieldWithFilters,
    MultiSearchListField: GeneratedMultiOUSearchListFieldWithFilters,
    SingleIDSearchListField: GeneratedSingleOUIDSearchListFieldWithFilters,
    SingleSearchListField: GeneratedSingleOUSearchListFieldWithFilters,
  } = SearchListFieldGenerator<
    Core.ListOrganizationUnitsDetailed,
    IOrganizationUnitSearchListFieldItem,
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getSecondaryLabel: (item) => item.BackendID,
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name, BackendID: ou.BackendID })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit",
      defaultMessage: "Organization unit",
    }),
    useItemByID: useOrganizationUnitById,
    useItemsByID: useOrganizationUnitsById,
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listOrganizationUnitsDetailedQuery,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { ...filters } },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.NameOrBackendID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              NameOrBackendID: newValue === "" ? undefined : newValue,
            },
          },
        }),
        enabled,
      }),
  });

  return {
    GeneratedMultiOUSearchListFieldWithFilters,
    GeneratedSingleOUSearchListFieldWithFilters,
    GeneratedMultiOUIDSearchListFieldWithFilters,
    GeneratedSingleOUIDSearchListFieldWithFilters,
  };
};

export const GeneratedLyraOUSearchListFieldWithFilters = (
  filters?: Partial<EVA.Core.ListOrganizationUnitsFilter>,
  enabled?: boolean,
) => {
  const GeneratedOUSearchListField = LyraSearchListFieldGenerator<
    Core.ListOrganizationUnitsDetailed,
    IOrganizationUnitSearchListFieldItem,
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name ?? "",
    getDescription: (item) => item.BackendID ?? "",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name, BackendID: ou.BackendID })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit",
      defaultMessage: "Organization unit",
    }),
    useItemByID: useOrganizationUnitById,
    useItemsByID: useOrganizationUnitsById,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listOrganizationUnitsDetailedQuery,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { ...filters } },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.NameOrBackendID,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              NameOrBackendID: newValue === "" ? undefined : newValue,
            },
          },
        }),
        enabled,
      }),
  });

  return GeneratedOUSearchListField;
};
