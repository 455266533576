import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const listInvoiceLedgerServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  defaultRequest: undefined,
  key: "Core.ListInvoiceLedger",
  service: Core.ListInvoiceLedger,
});

export const listInvoiceLedger = selectorFamily({
  key: "Core.ListInvoiceLedger:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(listInvoiceLedgerServiceState.response(parameter));

      return response?.Result?.Page;
    },
});
