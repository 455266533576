// Temporary fix for Strict mode errors
// From: https://stackoverflow.com/questions/61220424/material-ui-drawer-finddomnode-is-deprecated-in-strictmode
//
import { unstable_createMuiStrictModeTheme as createMuiTheme } from "@material-ui/core";

/**
 * Global styling based on the Material-UI framework.
 * @url https://material-ui.com/customization/theming/
 */
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#007AFF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.6)",
      contrastText: "#ffffff",
    },
    error: {
      main: "#B00020",
      contrastText: "#FFFFFF",
    },
    text: { primary: "#000000", secondary: "#66666A" },
    action: {
      active: "#212121",
    },
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(63, 63, 66, 0.3)",
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: "0.625rem",
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: "16px",
        letterSpacing: "0.15px",
        lineHeight: "24px",
        color: "#212121",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "16px",
        color: "#666666",
        lineHeight: "24px",
        letterSpacing: "0.15px",
        paddingLeft: "16px",
      },
    },
    MuiInputBase: {
      root: {
        minHeight: "45px",
        paddingLeft: "16px",
        fontSize: "16px",
        "&$focused": {
          backgroundColor: "transparent",
        },
        // width: '100%',
      },
    },
    MuiFormHelperText: {
      root: {
        paddingLeft: "16px",
        paddingRight: "16px",
      },
    },
    MuiList: {
      root: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "transparent",
        },
      },
      icon: {
        color: "#666666",
      },
    },
    MuiRadio: {
      root: {
        padding: "4.5px",
      },
    },
    MuiCheckbox: {
      root: {
        padding: "4.5px",
      },
      colorPrimary: {
        color: "#666666",
        "&$checked": {
          color: "#007AFF",
        },
      },
    },
    MuiIconButton: {
      root: {
        color: "#666666",
      },
    },
    MuiTabs: {
      scroller: {
        borderLeft: "1px solid #E3E3E3",
        borderRight: "1px solid #E3E3E3",
      },
    },
    MuiTab: {
      root: {
        textTransform: "uppercase",
        fontSize: "14px",
        letterSpacing: "1.25px",
        color: "#666666",
      },
    },
    MuiDrawer: {
      paper: {
        boxSizing: "border-box",
      },
    },
    MuiButton: {
      outlined: {
        padding: "10px 16px",
      },
      text: {
        padding: "10px 16px",
      },
      contained: {
        padding: "10px 16px",
        boxShadow: "none",
        "&:hover": {
          boxShadow: "none",
        },
        "&:focus": {
          boxShadow: "none",
        },
      },
    },
    MuiPopover: {
      paper: {
        boxShadow:
          "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)",
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        width: "100%",
        paddingLeft: "0",
        paddingRight: "0",
        "&$focused": {
          backgroundColor: "transparent",
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        width: "100%",
      },
    },
    MuiTablePagination: {
      input: {
        width: "auto",
      },
    },
    MuiInput: {
      root: {
        fontSize: 16,
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: "translate(0, 7px) scale(0.75)",
      },
    },
  },

  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Inter', 'Helvetica Neue', sans-serif",
    body1: {
      color: "#212121",
      fontSize: 14,
      letterSpacing: 0.25,
      lineHeight: "24px",
    },
    body2: {
      fontSize: 12,
      letterSpacing: 0.21,
      color: "#66666a",
      lineHeight: "20px",
    },
    caption: {
      fontSize: 12,
      letterSpacing: 0.4,
      color: "#666666",
      lineHeight: "16px",
    },
    button: {
      fontSize: 14,
      letterSpacing: "1.25px",
      lineHeight: "16px",
      fontWeight: 500,
    },
    h1: {
      fontSize: 24,
      fontWeight: 600,
    },
    h2: {
      fontSize: 20,
      letterSpacing: "0.5px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      letterSpacing: "0.5px",
    },
    h4: {
      fontSize: 16,
      fontWeight: 600,
    },
    h5: {
      fontSize: 14,
      fontWeight: 500,
    },
    h6: {
      fontSize: 12,
      fontWeight: 500,
    },
  },
});

export default theme;
