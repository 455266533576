import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listRecurringTasksLoaderQuery,
  serviceQueryKeys: listRecurringTasksQueryKeys,
  useServiceQueryHook: useListRecurringTasksQuery,
} = createServiceQuery(Core.ListRecurringTasks2);

export const {
  serviceLoaderQuery: listAvailableRecurringTasksLoaderQuery,
  serviceQueryKeys: listAvailableRecurringTasksQueryKeys,
  useServiceQueryHook: useListAvailableRecurringTasksQuery,
} = createServiceQuery(Core.ListAvailableRecurringTasks);

export const {
  serviceLoaderQuery: getRecurringTaskLoggingLoaderQuery,
  serviceQueryKeys: getRecurringTaskLoggingQueryKeys,
  useServiceQueryHook: useGetRecurringTaskLoggingTasksQuery,
} = createServiceQuery(Core.GetRecurringTaskLogging);
