import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listCashHandlersLoaderQuery,
  serviceQuery: listCashHandlersQuery,
  serviceQueryKeys: listCashHandlersQueryKeys,
  useServiceQueryHook: useListCashHandlersQuery,
  useServiceQueryWithRequest: useListCashHandlersQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCashHandlers);

export const {
  serviceLoaderQuery: getCashHandlerByIdLoaderQuery,
  serviceQuery: getCashHandlerByIdQuery,
  serviceQueryKeys: getCashHandlerByIdQueryKeys,
  useServiceQueryHook: useGetCashHandlerByIdQuery,
} = createServiceQuery(CoreManagement.GetCashHandlerByID);
