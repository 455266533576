import { useEffect, useState } from "react";

import { isInteger } from "lodash";

const useParseStringToInteger = (text?: string) => {
  const [parsed, setParsed] = useState<number | undefined>();

  useEffect(() => {
    // first check if the text can be parsed, then set state accordingly
    if (text && isInteger(parseInt(text, 10))) {
      setParsed(parseInt(text, 10));
    } else {
      setParsed(undefined);
    }
  }, [text]);

  return parsed;
};

export default useParseStringToInteger;
