import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listAssortmentsLoaderQuery,
  serviceQuery: listAssortmentsQuery,
  serviceQueryKeys: listAssortmentsQueryKeys,
  useServiceQueryHook: useListAssortmentsQuery,
} = createServiceQuery(CoreManagement.ListAssortments, true);

export const {
  serviceLoaderQuery: getAssortmentByIDLoaderQuery,
  serviceQuery: getAssortmentByIDQuery,
  serviceQueryKeys: getAssortmentByIDQueryKeys,
  useServiceQueryHook: useGetAssortmentByIDQuery,
} = createServiceQuery(CoreManagement.GetAssortmentByID, true);

export const {
  serviceLoaderQuery: getAssortmentProductTreeLoaderQuery,
  serviceQuery: getAssortmentProductTreeQuery,
  serviceQueryKeys: getAssortmentProductTreeQueryKeys,
  useServiceQueryHook: useGetAssortmentProductTreeQuery,
} = createServiceQuery(CoreManagement.GetAssortmentProductTree, true);

export const {
  serviceLoaderQuery: getAssortmentRootProductsLoaderQuery,
  serviceQuery: getAssortmentRootProductsQuery,
  serviceQueryKeys: getAssortmentRootProductsQueryKeys,
  useServiceQueryHook: useGetAssortmentRootProductsQuery,
} = createServiceQuery(CoreManagement.GetAssortmentRootProducts, true);

export const {
  serviceLoaderQuery: getAssortmentOrganizationUnitsLoaderQuery,
  serviceQuery: getAssortmentOrganizationUnitsQuery,
  serviceQueryKeys: getAssortmentOrganizationUnitsQueryKeys,
  useServiceQueryHook: useGetAssortmentOrganizationUnitsQuery,
} = createServiceQuery(CoreManagement.GetAssortmentOrganizationUnits, true);
