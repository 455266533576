/* eslint-disable max-len */
export const PromotionsInnerIcon = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#FF315F" />
    <path
      d="M19.4999 21.0001C20.3284 21.0001 20.9999 20.3285 20.9999 19.5001C20.9999 18.6717 20.3284 18.0001 19.4999 18.0001C18.6715 18.0001 17.9999 18.6717 17.9999 19.5001C17.9999 20.3285 18.6715 21.0001 19.4999 21.0001Z"
      fill="white"
    />
    <path
      d="M25.7069 22.7072C26.0974 22.3167 26.7306 22.3167 27.1211 22.7072L35.6064 31.1925C35.9969 31.583 35.9969 32.2162 35.6064 32.6067C35.2159 32.9972 34.5827 32.9972 34.1922 32.6067L25.7069 24.1214C25.3164 23.7309 25.3164 23.0977 25.7069 22.7072Z"
      fill="white"
    />
    <path
      d="M24.1211 25.7072C23.7306 25.3167 23.0974 25.3167 22.7069 25.7072C22.3164 26.0977 22.3164 26.7309 22.7069 27.1214L26.239 30.6535C26.6295 31.0441 27.2627 31.0441 27.6532 30.6535C28.0438 30.263 28.0438 29.6298 27.6532 29.2393L24.1211 25.7072Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.729 28.4369C13.0945 27.8024 12.728 26.9486 12.705 26.0517L12.4482 16.037C12.3968 14.0342 14.034 12.3971 16.0368 12.4484L26.0515 12.7052C26.9484 12.7282 27.8022 13.0948 28.4366 13.7292L44.874 30.1665C46.2408 31.5334 46.2408 33.7494 44.874 35.1163L35.116 44.8742C33.7492 46.241 31.5331 46.241 30.1663 44.8742L13.729 28.4369ZM26.5274 15.6384L42.9648 32.0757C43.2772 32.3881 43.2772 32.8947 42.9648 33.2071L33.2068 42.965C32.8944 43.2774 32.3879 43.2774 32.0755 42.965L15.6381 26.5277C15.4931 26.3827 15.4094 26.1875 15.4041 25.9825L15.1473 15.9678C15.1356 15.51 15.5098 15.1358 15.9676 15.1476L25.9823 15.4043C26.1873 15.4096 26.3824 15.4934 26.5274 15.6384Z"
      fill="white"
    />
  </>
);
