import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { useQuery } from "@tanstack/react-query";

import {
  getLoyaltyProgramGroupQuery,
  listLoyaltyProgramGroupsQuery,
} from "models/loyalty-programs";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateLoyaltyProgramGroupSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.ListLoyaltyProgramGroups,
    {
      Name: string;
      ID: number;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    getItemFromResponse: (response) =>
      response?.Results?.map((result) => ({ ID: result.ID!, Name: result.Name! })) ?? [],
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.loyalty-program-group",
      defaultMessage: "Loyalty program group",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listLoyaltyProgramGroupsQuery,
        initialRequest: {
          InitialPageConfig: {
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, value) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: value,
            },
          },
        }),
      }),
    useItemByID: (ID, itemsFromList) => {
      const itemInList = useMemo(
        () => itemsFromList?.find((item) => item.ID === ID),
        [ID, itemsFromList],
      );

      const { data, isFetching: isLoading } = useQuery({
        ...getLoyaltyProgramGroupQuery(ID && typeof ID === "number" ? { ID } : undefined),
        refetchOnWindowFocus: false,
        enabled: !!ID && typeof ID === "number" && !itemInList,
      });

      return {
        isLoading,
        data:
          itemInList ??
          (data && ID && typeof ID === "number" ? { ID, Name: data.Name! } : undefined),
      };
    },
  });
