import { memo } from "react";

import Grid from "components/suite-ui/grid";
import { CardHeader, Text } from "@new-black/lyra";
import classNames from "classnames";

export interface IInfoHeader {
  title: string;
}

const InfoHeader = memo(({ title }: IInfoHeader) => <CardHeader title={title} />);
InfoHeader.displayName = "InfoHeader";

export default InfoHeader;
