import { useMemo } from "react";

import Fuse from "fuse.js";

/**
 * Creates a memoized FuseJS instance
 * @param keys keys of the collection that are going to be filtered
 * @param collection the collection of items to be filtered
 * @returns Fuse instance
 */
const useFuseInstance = <T>(keys: (keyof T)[], collection: T[] | undefined) => {
  const fuseInstance = useMemo(() => {
    if (collection) {
      return new Fuse(collection, {
        keys: keys as string[],
        threshold: 0.2,
        useExtendedSearch: true,
      });
    }
    return undefined;
  }, [collection, keys]);

  return fuseInstance;
};

export default useFuseInstance;
