import { Params } from "react-router-dom";

import { getParams } from "remix-params-helper";
import { z } from "zod";

export const routeParamsIsDefiniteString = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.string() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export function routeParamIsSpecificString<T extends string>(
  params: Params,
  paramName: string,
  validOptions: readonly T[],
) {
  const param = routeParamsIsDefiniteString(params, paramName);
  if (typeof param !== "string") {
    throw new Response(`Invalid param type of ${paramName}`, { status: 400 });
  }
  if (!(validOptions as readonly string[]).includes(param)) {
    throw new Response(`Invalid param value of ${paramName}`, { status: 400 });
  }
  return param as T;
}

export const routeParamsIsOptionalString = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.string().optional() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsArrayOfStrings = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.array(z.string()) });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsOptionalArrayOfStrings = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const schema = z.object({ [routeParamName]: z.array(z.string()).optional() });
  const parsedParams = getParams(params, schema);

  if (!parsedParams.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedParams.data[routeParamName];
};

export const routeParamsIsDefiniteArrayOfNumericStrings = (
  params: Params<string> | URLSearchParams,
  routeParamName: string,
) => {
  const definiteStringSchema = z.object({ [routeParamName]: z.string() });
  const parsedString = getParams(params, definiteStringSchema);

  if (!parsedString.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  const splitString = parsedString.data[routeParamName].split(",");

  const arrayOfNumericStringsSchema = z.array(
    z.string().transform((x) => {
      const integerValue = Number(x);
      if (!integerValue) {
        throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
      }
      return integerValue;
    }),
  );
  const parsedArrayOfIntegers = arrayOfNumericStringsSchema.safeParse(splitString);

  if (!parsedArrayOfIntegers.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  const arrayOfPositiveIntegersSchema = z.array(z.number().int().positive());
  const parsedArrayOfPositiveIntegers = arrayOfPositiveIntegersSchema.safeParse(
    parsedArrayOfIntegers.data,
  );

  if (!parsedArrayOfPositiveIntegers.success) {
    throw new Response(`Invalid param type of ${routeParamName}`, { status: 400 });
  }

  return parsedArrayOfPositiveIntegers.data;
};
