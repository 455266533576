import type { ReactNode } from "react";

import { FormControl, FormLabel, RadioGroup } from "@material-ui/core";

export interface IRadioButtonList {
  label?: string;
  name: string;
  selectedValue?: string;
  disabled?: boolean;
  handleSelectedValueChange?:
    | ((event: React.ChangeEvent<HTMLInputElement>, value: string) => void)
    | undefined;
  children: ReactNode;
  required?: boolean;
  error?: boolean;
  className?: string;
  defaultValue?: string;
}

const RadioButtonList = ({
  children,
  className,
  defaultValue,
  disabled,
  error,
  handleSelectedValueChange,
  label,
  name,
  required,
  selectedValue,
}: IRadioButtonList) => (
  <FormControl error={error} component="fieldset" disabled={disabled} className={className}>
    {label ? (
      <FormLabel
        className="text-legacy-sm pl-0 leading-7 tracking-[0.15px]"
        classes={{
          focused: "text-[color:var(--legacy-eva-color-dark-2)]",
          disabled: "text-[color:var(--eva-color-disabled)]",
        }}
        required={required}
      >
        {label}
      </FormLabel>
    ) : null}
    <RadioGroup
      aria-label={label}
      name={name}
      value={selectedValue}
      defaultValue={defaultValue}
      onChange={handleSelectedValueChange}
    >
      {children}
    </RadioGroup>
  </FormControl>
);

export default RadioButtonList;
