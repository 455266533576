import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { isEqual } from "lodash";
import { selectorFamily } from "recoil";

export const prefigureDiscountsServiceState = builders.buildServiceFamilyState({
  allowEmptyRequest: false,
  defaultRequest: undefined,
  requireLoggedIn: true,
  service: Core.PrefigureDiscounts,
  key: "Core.PrefigureDiscounts",
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || !Array.isArray(parameter) || parameter.length !== 2) {
      return req;
    }
    const orderId = parameter[0];
    const productId = parameter[1];
    if (
      [orderId, productId].includes(undefined) ||
      typeof orderId !== "number" ||
      typeof productId !== "number" ||
      (req?.OrderID === orderId && isEqual(req?.ProductIDs, [productId]))
    ) {
      return req;
    }
    return { ...req, OrderID: orderId, ProductIDs: [productId] };
  },
});

export const prefigureDiscounts = selectorFamily({
  key: "Core.PrefigureDiscounts:selector",
  get:
    (param: number[] | undefined) =>
    ({ get }) =>
      get(prefigureDiscountsServiceState.response(param))?.Results,
});
