type LoginData = {
  AuthenticationToken?: string;
  Nickname?: string;
  EmailAddress?: string;
};

export type LoginState = {
  status: "idle" | "processing";
  view: "main" | "credentialsLogin" | "ssoLogin" | "2faChooseMethod" | "2faConfirmation";
  processingSSOProvider?: string;
  loginData?: LoginData;
  twoFactorMethod?: EVA.Core.TwoFactorAuthenticatorData["Name"];
  twoFactorMethodsAvailable?: EVA.Core.TwoFactorAuthenticatorData[];
};

export type LoginAction =
  | { type: "setView"; view: LoginState["view"] }
  | { type: "setStatus"; status: LoginState["status"] }
  | { type: "setAll"; state: Partial<LoginState> };

export function loginStateReducer(state: LoginState, action: LoginAction): LoginState {
  switch (action.type) {
    case "setView": {
      return {
        ...state,
        view: action.view,
      };
    }
    case "setStatus": {
      return {
        ...state,
        status: action.status,
      };
    }
    case "setAll": {
      return {
        ...state,
        ...action.state,
      };
    }
    default: {
      return state;
    }
  }
}
