import { ReactNode } from "react";

import { Separator, Text } from "@new-black/lyra";

export const TextDivider = ({ children }: { children: ReactNode }) => (
  <div className="flex items-center">
    <div className="flex-grow">
      <Separator className="my-2" />
    </div>
    <div className="px-2">
      <Text variant="body-medium" color="secondary" className="lowercase">
        {children}
      </Text>
    </div>
    <div className="flex-grow">
      <Separator className="my-2" />
    </div>
  </div>
);
