import { z } from "zod";

export const confirmTwoFactorAuthenticationTokenSchema = z.object({
  TwoFactorAuthenticator: z.string(),
  TwoFactorAuthenticationToken: z.string(),
  TwoFactorAuthenticationRememberMe: z.coerce.boolean().optional(),
  AuthenticationToken: z.string(),
  UserName: z.string(),
});

export type ConfirmTwoFactorAuthenticationTokenSchema = z.infer<
  typeof confirmTwoFactorAuthenticationTokenSchema
>;

export const confirmTwoFactorAuthenticationTokenFormKeys =
  confirmTwoFactorAuthenticationTokenSchema.keyof().Values;

export const confirmTwoFactorAuthenticationTokenErrors = z
  .record(confirmTwoFactorAuthenticationTokenSchema.keyof(), z.string().optional())
  .optional();

export type ConfirmTwoFactorAuthenticationTokenFormErrors = z.infer<
  typeof confirmTwoFactorAuthenticationTokenErrors
>;

export type ConfirmTwoFactorAuthenticationTokenFormAndServiceErrors = {
  serviceError?: string;
  formErrors?: ConfirmTwoFactorAuthenticationTokenFormErrors;
};

export type LoginData = {
  Nickname?: string;
  EmailAddress?: string;
  AuthenticationToken?: string;
};

export type ConfirmTwoFactorAuthenticationTokenFormFieldsProps = {
  loginData?: LoginData;
  authenticatorProviderName?: string;
  errors?: ConfirmTwoFactorAuthenticationTokenFormAndServiceErrors;
};
