import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getCheckoutOptionHandlersLoaderQuery,
  serviceQuery: getCheckoutOptionHandlersQuery,
  serviceQueryKeys: getCheckoutOptionHandlersQueryKeys,
  useServiceQueryHook: useGetCheckoutOptionHandlersQuery,
} = createServiceQuery(CoreManagement.GetCheckoutOptionHandlers);

export const {
  serviceLoaderQuery: getCheckoutOptionsLoaderQuery,
  serviceQuery: getCheckoutOptionsQuery,
  serviceQueryKeys: getCheckoutOptionsQueryKeys,
  useServiceQueryHook: useGetCheckoutOptionsQuery,
} = createServiceQuery(Core.GetCheckoutOptions);
