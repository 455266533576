import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { SearchField, SvgIcon } from "@new-black/lyra";

import SearchActionButton from "components/shared/action-buttons/search-action-button";
import IconButton from "components/suite-ui/icon-button";
import Input from "components/suite-ui/input";
import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { emptyStringToUndefined } from "util/helper";

interface CustomQueryProps {
  query?: string;
  variant?: "lyra" | "material";
  updateQuery: (value: string | undefined) => void;
}

export default function CustomQuery({
  query,
  updateQuery,
  variant = "material",
}: CustomQueryProps) {
  const intl = useIntl();
  const [showQueryField, setShowQueryField] = useState(false);
  const [localQuery, setLocalQuery] = useState(query ?? "");
  const debouncedQuery = useDebounce(localQuery, 500);

  useEffect(() => {
    const newQuery = emptyStringToUndefined(debouncedQuery);
    updateQuery(newQuery);
  }, [debouncedQuery, updateQuery]);

  if (!showQueryField) {
    return (
      <SearchActionButton
        onPress={() => setShowQueryField(true)}
        tooltip={intl.formatMessage({
          id: "composite.products-overview.list.search.button",
          defaultMessage: "Search product",
        })}
      />
    );
  }

  if (variant === "lyra") {
    return (
      <div className="min-w-[220px]">
        <SearchField
          autoFocus
          hideHintLabel
          hideInputHeader
          value={localQuery}
          onClear={() => setLocalQuery("")}
          onChange={(newValue) => setLocalQuery(newValue ?? "")}
          onBlur={() => {
            if (!localQuery) {
              setShowQueryField(false);
            }
          }}
          label={intl.formatMessage({
            id: "composite.products-overview.list.search.input.placeholder",
            defaultMessage: "Search product",
          })}
          placeholder={intl.formatMessage({
            id: "composite.products-overview.list.search.input.placeholder",
            defaultMessage: "Search product",
          })}
        />
      </div>
    );
  }

  return (
    <div className="min-w-[220px]">
      <Input
        small
        placeholder={intl.formatMessage({
          id: "composite.products-overview.list.search.input.placeholder",
          defaultMessage: "Search product",
        })}
        value={localQuery}
        onChange={(event) => setLocalQuery(event.target.value ?? "")}
        onBlur={() => {
          if (!localQuery) {
            setShowQueryField(false);
          }
        }}
        endIcon={
          localQuery?.length ? (
            <IconButton
              size="small"
              onClick={() => {
                setLocalQuery("");
                setShowQueryField(false);
              }}
            >
              <SvgIcon name="alert-error" className="h-5 w-5" />
            </IconButton>
          ) : null
        }
        autoFocus
      />
    </div>
  );
}
