import { PaneFallback } from "components/suite-ui/fallbacks";

import useResetOrganizationUnitHeader from "./use-reset-organization-unit-header";

export interface IOrganizationUnitProvider {
  children: React.ReactNode;
  useOUSelector?: boolean;
}

const OrganizationUnitProvider = ({ children, useOUSelector }: IOrganizationUnitProvider) => {
  const isReset = useResetOrganizationUnitHeader(useOUSelector);

  if (isReset) {
    return <>{children}</>;
  }

  return <PaneFallback />;
};

export default OrganizationUnitProvider;
