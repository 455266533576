import { useIntl } from "react-intl";

import { z } from "zod";

interface StatusMessagesProps {
  errorMessage?: string;
  loadingMessage?: string;
  successMessage?: string;
  prefix?: string;
}

export const statusMessagesSchema = z.object({
  errorMessage: z.string(),
  loadingMessage: z.string(),
  successMessage: z.string(),
});

export const StatusMessages = ({
  errorMessage,
  loadingMessage,
  prefix = "statusMessages",
  successMessage,
}: StatusMessagesProps) => {
  const intl = useIntl();
  return (
    <>
      <input
        type="hidden"
        name={`${prefix}.errorMessage`}
        value={
          errorMessage ??
          intl.formatMessage({
            id: "generic.message.something-went-wrong",
            defaultMessage: "Something went wrong.",
          })
        }
      />
      <input
        type="hidden"
        name={`${prefix}.loadingMessage`}
        value={
          loadingMessage ??
          intl.formatMessage({ id: "generic.label.loading", defaultMessage: "Loading..." })
        }
      />
      <input
        type="hidden"
        name={`${prefix}.successMessage`}
        value={
          successMessage ??
          intl.formatMessage({
            id: "generic.message.action-success",
            defaultMessage: "Action successfully executed.",
          })
        }
      />
    </>
  );
};
