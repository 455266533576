import { useCallback, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";

import { CalendarDate, CalendarDateTime } from "@internationalized/date";
import { DatePicker as LyraDatePicker } from "@new-black/lyra";
import { isNil } from "lodash";
import moment from "moment";

import DatePicker from "components/suite-ui/date-picker/date-picker";
import DatePickerField from "components/suite-ui/date-picker/date-picker-field";
import TimePickerField from "components/suite-ui/time-picker";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

interface ICustomDateFieldConsumerProps extends ICustomFieldConsumerProps<string | undefined> {
  time?: boolean;
  timeLabel?: string;
  minimum?: string;
  maximum?: string;
  minimumMessage?: string;
  maximumMessage?: string;
  outOfRangeMessage?: string;
}

interface ICustomDateFieldValidatorOptions {
  time?: boolean;
  required?: boolean;
  minimum?: string;
  maximum?: string;
  minimumMessage?: string;
  maximumMessage?: string;
  requiredMessage?: string;
  outOfRangeMessage?: string;
  validateNullishValues?: boolean;
}

// If the time is not specified, we want to set the time to 00:00:00
// If the time is specified, we want to set the time to the specified time
function timeOffset(dateTime: string, isTime?: boolean) {
  if (isTime) {
    return moment(dateTime);
  }
  return moment(dateTime).startOf("day");
}

const validateSingleDateValue =
  (intl: IntlShape) =>
  (
    valueToValidate: string | undefined,
    {
      maximum,
      maximumMessage,
      minimum,
      minimumMessage,
      outOfRangeMessage,
      required,
      requiredMessage,
      time,
      validateNullishValues,
    }: ICustomDateFieldValidatorOptions,
  ) => {
    if (required && isNil(valueToValidate)) {
      return (
        requiredMessage ??
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        })
      );
    }

    const momentValue = valueToValidate ? timeOffset(valueToValidate, time) : undefined;

    if (!isNil(momentValue) || validateNullishValues) {
      if (
        minimum !== undefined &&
        maximum !== undefined &&
        (validateNullishValues ||
          (momentValue &&
            (momentValue.isBefore(timeOffset(minimum, time)) ||
              momentValue.isAfter(timeOffset(maximum, time)))))
      ) {
        return (
          outOfRangeMessage ??
          intl.formatMessage(
            {
              id: "validation.dateRange",
              defaultMessage: "Value must be between {min} and {max}",
            },
            {
              min: `${intl.formatDate(minimum)}${time ? ` ${intl.formatTime(minimum)}` : ""}`,
              max: `${intl.formatDate(maximum)}${time ? ` ${intl.formatTime(maximum)}` : ""}`,
            },
          )
        );
      }

      if (
        minimum !== undefined &&
        (validateNullishValues || (momentValue && momentValue.isBefore(timeOffset(minimum, time))))
      ) {
        return (
          minimumMessage ??
          intl.formatMessage(
            {
              id: "validation.min-date",
              defaultMessage: "The value must be after {min}",
            },
            {
              min: `${intl.formatDate(minimum)}${time ? ` ${intl.formatTime(minimum)}` : ""}`,
            },
          )
        );
      }

      if (
        maximum !== undefined &&
        (validateNullishValues || (momentValue && momentValue.isAfter(timeOffset(maximum, time))))
      ) {
        return (
          maximumMessage ??
          intl.formatMessage(
            {
              id: "validation.max-date",
              defaultMessage: "The value must be before {max}",
            },
            {
              max: `${intl.formatDate(maximum)}${time ? ` ${intl.formatTime(maximum)}` : ""}`,
            },
          )
        );
      }
    }

    return undefined;
  };

const validateMultiDateValue =
  (intl: IntlShape) =>
  (
    valueToValidate: (string | undefined)[] | undefined,
    options: ICustomDateFieldValidatorOptions,
  ) => {
    const validateSingleValue = validateSingleDateValue(intl);
    return valueToValidate?.map?.((value) => validateSingleValue(value, options));
  };

const validateDateValue =
  (intl: IntlShape) =>
  (
    valueToValidate: string | (string | undefined)[] | undefined,
    options: ICustomDateFieldValidatorOptions,
  ) => {
    if (typeof valueToValidate === "string" || typeof valueToValidate === "undefined") {
      return validateSingleDateValue(intl)(valueToValidate, options);
    }
    if (Array.isArray(valueToValidate)) {
      return validateMultiDateValue(intl)(valueToValidate, options);
    }
    return undefined;
  };

export const CustomDateFieldConsumer = ({
  alwaysShowValidationMessage,
  componentVariant,
  error: parentError,
  label,
  maximum,
  maximumMessage,
  minimum,
  minimumMessage,
  name,
  onChange,
  outOfRangeMessage,
  placeholder,
  required,
  requiredMessage,
  time,
  timeLabel,
  touched: parentTouched,
  value,
}: ICustomDateFieldConsumerProps) => {
  const intl = useIntl();

  const [touched, setTouched] = useState(parentTouched ?? false);

  const validateValue = useCallback(
    (valueToValidate: string | undefined, validateNullishValues?: boolean) =>
      validateDateValue(intl)(valueToValidate, {
        time,
        required,
        minimum,
        maximum,
        minimumMessage,
        maximumMessage,
        requiredMessage,
        outOfRangeMessage,
        validateNullishValues,
      }),
    [
      intl,
      maximum,
      maximumMessage,
      minimum,
      minimumMessage,
      outOfRangeMessage,
      required,
      requiredMessage,
      time,
    ],
  );

  const error = useMemo(() => validateValue(value), [validateValue, value]);
  const helperText = useMemo(
    () => validateValue(value, alwaysShowValidationMessage),
    [alwaysShowValidationMessage, validateValue, value],
  );

  const fieldValue = useMemo(() => {
    if (value) {
      if (componentVariant === "lyra") {
        const momentValue = moment(value);

        if (time) {
          return new CalendarDateTime(
            momentValue.get("y"),
            momentValue.get("M") + 1, // Moment months are counted from 0
            momentValue.get("D"),
            momentValue.get("hour"),
            momentValue.get("minute"),
          );
        }

        return new CalendarDate(
          momentValue.get("y"),
          momentValue.get("M") + 1, // Moment months are counted from 0
          momentValue.get("D"),
        );
      }

      return moment(value).format("YYYY-MM-DD");
    }

    return undefined;
  }, [componentVariant, time, value]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);

  if (componentVariant === "lyra") {
    return (
      <>
        <LyraDatePicker
          hideHintLabel
          label={label}
          value={(fieldValue ?? null) as CalendarDate | null}
          onChange={(value) => {
            if (value && moment(value.toString())) {
              const newValue = moment(value.toString());
              const newValueString = time
                ? newValue.toISOString()
                : newValue.startOf("day").format("YYYY-MM-DD");

              onChange(newValueString, validateValue(newValueString) as string | undefined);
              setTouched(true);
            } else {
              onChange(undefined, validateValue(undefined) as string | undefined);
              setTouched(true);
            }
          }}
          onBlur={() => setTouched(true)}
          granularity={time ? "minute" : undefined}
          errorMessage={
            parentError ??
            (touched || parentTouched || alwaysShowValidationMessage
              ? Array.isArray(helperText)
                ? helperText.map(Boolean).join(", ")
                : helperText
              : undefined)
          }
        />
      </>
    );
  }

  return (
    <>
      <div className="flex w-full items-center gap-5">
        <DatePickerField
          name={name}
          label={label}
          placeholder={placeholder}
          required={required}
          value={fieldValue ?? ""}
          onBlur={() => setTouched(true)}
          error={parentError ? !!parentError : (touched || parentTouched) && !!error}
          helperText={
            parentError ??
            (touched || parentTouched || alwaysShowValidationMessage ? helperText : undefined)
          }
          clear={() => {
            onChange(undefined, validateValue(undefined) as string | undefined);
            setTouched(true);
          }}
          onChange={(event) => {
            if (moment(event.target.value)) {
              const newValue = moment(event.target.value).startOf("day");
              const newValueString = time ? newValue.toISOString() : newValue.format("YYYY-MM-DD");

              onChange(newValueString, validateValue(newValueString) as string | undefined);
              setTouched(true);
            } else {
              onChange(undefined, validateValue(undefined) as string | undefined);
              setTouched(true);
            }
          }}
          handleClick={(event) => {
            if (event && event.currentTarget) {
              setAnchorEl(event.currentTarget);
            }
            setOpenPopover(true);
          }}
        />
        {value && time ? (
          <div className="w-full">
            <TimePickerField
              required={required}
              error={parentError ? !!parentError : (touched || parentTouched) && !!error}
              onBlur={() => setTouched(true)}
              label={timeLabel ?? `${label} time`}
              helperText={parentError ?? (touched || parentTouched ? error : undefined)}
              value={value ? moment(value).format("HH:mm") : ""}
              onChange={(event) => {
                const timeValue = moment(event.target.value, "HH:mm");
                const currentDate = moment(value);

                const newDate = moment(currentDate)
                  .set({
                    hour: timeValue.get("hour"),
                    minute: timeValue.get("minute"),
                  })
                  .toISOString();

                onChange(newDate, validateValue(newDate) as string | undefined);
                setTouched(true);
              }}
            />
          </div>
        ) : null}
      </div>
      <DatePicker
        open={openPopover}
        numberOfMonths={1}
        anchorEl={anchorEl}
        date={value ? moment(value) : null}
        focused={openPopover}
        id={`${name}-popover`}
        setOpen={setOpenPopover}
        onBlur={() => setTouched(true)}
        onFocusChange={() => setOpenPopover(false)}
        onDateChange={(newValue) => {
          if (newValue) {
            const newDate = newValue.startOf("day");
            const newValueString = time ? newDate.toISOString() : newDate.format("YYYY-MM-DD");

            onChange(newValueString, validateValue(newValueString) as string | undefined);
            setTouched(true);
            return;
          }
          onChange(undefined, validateValue(undefined) as string | undefined);
          setTouched(true);
        }}
      />
    </>
  );
};

CustomDateFieldConsumer.validate = validateDateValue;
