import { Dialog } from "@material-ui/core";
import { Card } from "@new-black/lyra";

import { ChapterFinderComboBox } from "features/chapter-finder/chapter-finder-combo-box";

export const ChapterFinderDialog = ({
  closeModal,
  isOpen,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  return (
    <Dialog fullWidth open={isOpen} onClose={closeModal} PaperProps={{ className: "max-w-2xl" }}>
      <Card className="h-[90vh] w-full overflow-auto md:h-[620px]">
        <div className="h-[calc(100%-67px)] w-full">
          <ChapterFinderComboBox isInModal onSelect={closeModal} />
        </div>
      </Card>
    </Dialog>
  );
};
