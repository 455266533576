import { useMemo } from "react";

const useAnimationProps = (
  direction: number,
  hasParent: boolean,
  previousMenuItemsLength?: number,
) => {
  const variants = useMemo(
    () => ({
      enter: () => ({
        x: direction > 0 ? 100 : -100,
        opacity: 0,
        height:
          // height is the number of items previously shown plus one if parent label was also shown times the height of each item
          ((previousMenuItemsLength ?? 0) + (hasParent ? 1 : 0)) * 50,
        overflow: "hidden",
      }),
      center: {
        x: 0,
        opacity: 1,
        height: "auto",
        overflow: "unset",
      },
      // eslint-disable-next-line @typescript-eslint/no-shadow
      exit: (direction: number) => ({
        x: direction < 0 ? 100 : -100,
        opacity: 0,
      }),
    }),
    [direction, hasParent, previousMenuItemsLength],
  );

  const animationProps = useMemo(
    () => ({
      variants,
      initial: "enter",
      animate: "center",
      exit: "exit",
      transition: { duration: 0.1 },
    }),
    [variants],
  );

  return { animationProps };
};

export default useAnimationProps;
