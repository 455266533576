import { useMemo } from "react";
import { useIntl } from "react-intl";

import * as yup from "yup";

const useCustomFieldSettingsFormValidationSchema = () => {
  const intl = useIntl();

  const validationSchema = useMemo(
    () =>
      yup.object().shape(
        {
          OUSetID: yup.number().required(
            intl.formatMessage({
              id: "validation.required",
              defaultMessage: "This field is required",
            }),
          ),
          IsRequired: yup.boolean(),
          VisibleByUserType: yup.number().nullable(),
          EditableByUserType: yup.number().nullable(),
          MinimumValue: yup.number(),
          MaximumValue: yup.number(),
          MinimumLength: yup.number(),
          MaximumLength: yup.number(),
          MinimumDate: yup.date().when(
            "MaximumDate",
            (maxDate: any, schema: any) =>
              maxDate &&
              schema.max(
                maxDate,
                intl.formatMessage({
                  id: "custom-field.input.min-date.error",
                  defaultMessage: "Date must be earlier than maximum date",
                }),
              ),
          ),
          MaximumDate: yup.date().when(
            "MinimumDate",
            (minDate: any, schema: any) =>
              minDate &&
              schema.min(
                minDate,
                intl.formatMessage({
                  id: "custom-field.input.max-date.error",
                  defaultMessage: "Date must be later than minimum date",
                }),
              ),
          ),
          SecurityScriptID: yup.number().optional(),
          EligibilityScriptID: yup.number().optional(),
        },
        [["MinimumDate", "MaximumDate"]],
      ),
    [intl],
  );

  return validationSchema;
};

export default useCustomFieldSettingsFormValidationSchema;
