import { useCallback, useState } from "react";

import { SearchListFieldControlled } from "./search-list-field-controlled";
import {
  ISearchListFieldBaseProps,
  ISearchListFieldOptionsProps,
  ISearchListFieldUncontrolledMultipleSelectionProps,
  ISearchListFieldUncontrolledProps,
  ISearchListFieldUncontrolledSingleSelectionProps,
  TSearchListFieldKeyType,
} from "./types";

type IUncontrolledSearchListFieldHiddenInputsProps<T> = ISearchListFieldUncontrolledProps<T> &
  (
    | { value?: T; values?: never; multiple?: never }
    | { value?: never; multiple: true; values?: T[] | undefined }
  ) & { idKey: keyof T };

/** Curried function for generating a default uncontrolled value selector */
const defaultUncontrolledValueSelector =
  <T,>(idKey: keyof T) =>
  (item: T) => {
    const val = item[idKey];

    return typeof val === "string" || typeof val === "number" ? val : JSON.stringify(val);
  };

export const UncontrolledSearchListFieldHiddenInputs = <T,>({
  idKey,
  multiple,
  name,
  uncontrolledValueSelector,
  value,
  values,
}: IUncontrolledSearchListFieldHiddenInputsProps<T>) => {
  const localUncontrolledValueSelector = useCallback(
    (item: T) => {
      const selectorFunction = uncontrolledValueSelector ?? defaultUncontrolledValueSelector(idKey);

      const result = selectorFunction(item);

      switch (typeof result) {
        case "number":
        case "string":
          return result;
        default:
          return JSON.stringify(result);
      }
    },
    [idKey, uncontrolledValueSelector],
  );

  if (multiple && Array.isArray(values)) {
    return (
      <>
        {values?.map((el, index) =>
          el ? (
            <input
              hidden
              readOnly
              key={index}
              name={name}
              value={localUncontrolledValueSelector(el)}
            />
          ) : null,
        )}
      </>
    );
  }

  if (value) {
    return <input name={name} hidden readOnly value={localUncontrolledValueSelector(value)} />;
  }

  return <>{null}</>;
};

export const SearchListFieldUncontrolled = <T,>({
  defaultValue,
  defaultValues,
  name,
  uncontrolledValueSelector,
  ...props
}: ISearchListFieldOptionsProps<T, TSearchListFieldKeyType<T>, TSearchListFieldKeyType<T>> &
  ISearchListFieldUncontrolledProps<T> &
  (
    | ISearchListFieldUncontrolledSingleSelectionProps<T>
    | ISearchListFieldUncontrolledMultipleSelectionProps<T>
  ) &
  ISearchListFieldBaseProps<T>) => {
  const [localValue, setLocalValue] = useState(defaultValue);
  const [localValues, setLocalValues] = useState(defaultValues);

  return (
    <>
      {props.multiple ? (
        <SearchListFieldControlled {...props} values={localValues} setValues={setLocalValues} />
      ) : (
        <SearchListFieldControlled {...props} value={localValue} setValue={setLocalValue} />
      )}

      {props.multiple ? (
        <UncontrolledSearchListFieldHiddenInputs
          key="UncontrolledSearchListFieldMultiHiddenInputs"
          multiple
          name={name}
          idKey={props.idKey}
          values={localValues}
          uncontrolledValueSelector={uncontrolledValueSelector}
        />
      ) : (
        <UncontrolledSearchListFieldHiddenInputs
          key="UncontrolledSearchListFieldSingleHiddenInputs"
          name={name}
          idKey={props.idKey}
          multiple={props.multiple}
          value={localValue}
          uncontrolledValueSelector={uncontrolledValueSelector}
        />
      )}
    </>
  );
};
