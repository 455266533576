import type { Dispatch, SetStateAction } from "react";
import { useEffect, useState } from "react";

import type { RecoilState, Resetter } from "recoil";
import { useRecoilState } from "recoil";

import useDebounce from "../use-debounce";

/**
 * Use this hook when you need to debounce a Recoil value.
 * @param recoilState the Recoil state to debounce (atom or selector)
 * @param debounceTime in milliseconds (the default is 500ms)
 * @param resetRecoilState optional resetter to run after every debounced set operation
 */
const useRecoilDebouncedValue = <T>(
  recoilState: RecoilState<T>,
  debounceTime = 500,
  resetRecoilState?: Resetter,
): [T, Dispatch<SetStateAction<T>>] => {
  // Recoil state
  const [valueInStore, setValueInStore] = useRecoilState(recoilState);
  // Local state foir storing the value that is not yet debounced
  const [value, setValue] = useState<T>(valueInStore);
  // Debounced value to listen to
  const debouncedValue = useDebounce(value, debounceTime);

  // Local State -> Store State

  // Whenever the debounced value changes
  useEffect(() => {
    // Update the value in store
    setValueInStore(debouncedValue);
    // If a resetter is defined, run it after every debounced state change
    if (resetRecoilState) {
      resetRecoilState();
    }
  }, [debouncedValue, resetRecoilState, setValueInStore]);

  // Store Stae -> Local State

  // Whenever the value in store changes
  useEffect(() => {
    // Update the local value
    setValue(valueInStore);
  }, [valueInStore]);

  // Provide the local state as interface for the consumer
  return [value, setValue];
};

export default useRecoilDebouncedValue;
