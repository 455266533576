import { useIntl } from "react-intl";

import { Button, ButtonProps, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

function DeleteActionButton({
  className,
  isDisabled,
  isLoading,
  onPress,
  slot,
  tooltip,
  variant = "icon",
}: IActionButton & Pick<ButtonProps, "variant" | "slot">) {
  const intl = useIntl();

  if (variant === "icon" || variant === "icon-xs")
    return (
      <Button
        className={className}
        onPress={onPress}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })
        }
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        variant={variant}
        slot={slot}
      >
        <SvgIcon name="delete" />
      </Button>
    );

  return (
    <Button
      className={className}
      onPress={onPress}
      isDisabled={isDisabled || isLoading}
      isLoading={isLoading}
      variant={variant ?? "danger"}
      slot={slot}
    >
      {tooltip ?? intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })}
    </Button>
  );
}

export default DeleteActionButton;
