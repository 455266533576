import { forwardRef } from "react";

import { SvgIcon, SvgIconProps } from "@material-ui/core";

const CompletedTasksIcon = forwardRef<SVGSVGElement, SvgIconProps>((props: SvgIconProps, ref) => (
  <SvgIcon width="64" height="65" viewBox="0 0 64 65" ref={ref} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M54.4 6.49766H9.6C7.83269 6.49766 6.4 7.93035 6.4 9.69765V54.4977C6.4 56.265 7.83269 57.6977 9.6 57.6977H54.4C56.1673 57.6977 57.6 56.265 57.6 54.4977V9.69766C57.6 7.93034 56.1673 6.49766 54.4 6.49766ZM9.6 0.0976562C4.29807 0.0976562 0 4.39572 0 9.69765V54.4977C0 59.7996 4.29807 64.0977 9.6 64.0977H54.4C59.7019 64.0977 64 59.7996 64 54.4977V9.69766C64 4.39572 59.7019 0.0976562 54.4 0.0976562H9.6Z"
      fill="black"
      fillOpacity="0.1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.4144 21.5156C42.4431 20.2812 44.2772 20.1145 45.5116 21.1432C46.7459 22.1716 46.9126 24.006 45.8839 25.2404L31.7856 42.1585C30.3898 43.8332 27.8176 43.8332 26.4221 42.1585L19.5962 33.9676C18.5676 32.7333 18.7346 30.8988 19.9686 29.8705C21.2029 28.8418 23.0374 29.0085 24.0658 30.2428L29.1037 36.2882L41.4144 21.5156Z"
      fill="black"
      fillOpacity="0.1"
    />
  </SvgIcon>
));

CompletedTasksIcon.displayName = "CompletedTasksIcon";

export default CompletedTasksIcon;
