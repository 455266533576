import { IntlShape } from "react-intl";

import { ZonedCycleCountResultStatus } from "types/zoned-cycle-count-result-status";

export const getZonedCycleCountResultStatusTranslator = (
  intl: IntlShape,
): ((status: ZonedCycleCountResultStatus) => string | undefined) => {
  const translationMap: Record<ZonedCycleCountResultStatus, string> = {
    [ZonedCycleCountResultStatus.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [ZonedCycleCountResultStatus.Correct]: intl.formatMessage({
      id: "generic.label.correct",
      defaultMessage: "Correct",
    }),
    [ZonedCycleCountResultStatus.Deviated]: intl.formatMessage({
      id: "generic.label.deviated",
      defaultMessage: "Deviated",
    }),
    [ZonedCycleCountResultStatus.Modified]: intl.formatMessage({
      id: "generic.label.modified",
      defaultMessage: "Modified",
    }),
    [ZonedCycleCountResultStatus.RecountRequested]: intl.formatMessage({
      id: "generic.label.recount-requested",
      defaultMessage: "Recount requested",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
