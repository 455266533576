import type Monaco from "monaco-editor";

export interface ICodeEditorError {
  message: string;
  startLineNumber: number;
  startColumn: number;
  endLineNumber?: number;
  endColumn?: number;
}

export interface IScriptCodeEditorBaseProps {
  /* If true, the editor source code can't be modified */
  readonly?: boolean;
  /* Editor height, defaults to 100% */
  height?: number | string;
  /* Color theme of the editor */
  theme?: "light" | "vs-dark";
  /* Errors for the modified source code within the editor */
  errors?: ICodeEditorError[];
  /* Callback fired when the save (CMD + S) shortcut is pressed */
  onSaveShortcut?: () => void;
  /* Callback fired when the source code within the editor is changed */
  onChange?: (value?: string) => void;
  /* If true, the first line will not be editable by the user  */
  disableFirstLineEditing?: boolean;
}

/**
 * Enables providing initial models to the editors. The models are attached on mount.
 *
 * **Notice**: providing initial models gives developers the responsibility of disposing the models in order
 * to prevent memory leaks. Please check out the `useInitialEditorModels` helper hook (`./hooks`) which is also
 * used internally in this projects' editor components.
 */
export interface IMonacoEditorModelProps {
  normalEditor?: {
    /** Initial model for the normal editor. */
    initialModel?: Monaco.editor.ITextModel | null;
    /** Callback fired when blurring the normal editor. */
    onBlur?: (model?: Monaco.editor.ITextModel | null) => void;
  };
  diffEditor?: {
    /** Initial model for the diff editor. */
    initialModel?: Monaco.editor.IDiffEditorModel | null;
    /** Callback fired when blurring the diff editor. */
    onBlur?: (model?: Monaco.editor.IDiffEditorModel | null) => void;
  };
}

export interface IScriptEditorModelProps {
  showFullScreenEditor: boolean;
  setShowFullScreenEditor: (newValue: boolean) => void;
  ModelProps?: IMonacoEditorModelProps;
}

export enum CompletionCategory {
  Keyword = 0,
  Member = 1,
  Snippet = 2,
}

export type IScriptHistoryItem = Pick<
  EVA.Core.ListScriptsItem,
  "Revision" | "Date" | "Source" | "UserFullName"
>;
