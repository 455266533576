import { useEffect } from "react";

import { useRecoilState, useResetRecoilState } from "recoil";

import { isWorkspaceVisibleState } from "../workspaces.state";
import { EKeyName } from "../workspaces.types";

/**
 * Use this hook to manually manage the visibility of the workspace dropdown for a given
 * keyname. This could be useful if, for example, you want to conditionally show the workspace
 * dropdown for a given route.
 * @param keyname key name of the workspace (see `PageConfiguration` in ./workspace.ts)
 * @returns value / setter / resetter of the visible state for the given `keyname`
 */
export const useWorkspaceVisibility = (keyname: EKeyName) => {
  const [isWorkspaceVisible, setIsWorkspaceVisible] = useRecoilState(
    isWorkspaceVisibleState(keyname),
  );

  // Equivalent to `setIsWorkspaceVisible(true)`
  const resetIsWorkspaceVisible = useResetRecoilState(isWorkspaceVisibleState(keyname));

  return {
    isWorkspaceVisible,
    setIsWorkspaceVisible,
    resetIsWorkspaceVisible,
  };
};

/**
 * Use this hook to automatically hide workspace dropdown on mount and show it again on unmount.
 * @param keyname key name of the workspace (see `PageConfiguration` in ./workspace.ts)
 */
export const useHideWorkspace = (keyname: EKeyName) => {
  const { setIsWorkspaceVisible } = useWorkspaceVisibility(keyname);

  // Disable the workspace on mount
  useEffect(() => setIsWorkspaceVisible(false), [setIsWorkspaceVisible]);

  // Enable it again on unmount
  useEffect(() => () => setIsWorkspaceVisible(true), [setIsWorkspaceVisible]);
};
