import { useMemo } from "react";

import { round } from "lodash";
import moment, { Moment } from "moment";

import { endOfDayWithoutMilliseconds } from "../helpers";
import { IBaseCalendarItem } from "../types";

const SECONDS_IN_DAY = 60 * 60 * 24;

export interface IUseCalendarItemMargins {
  displayedDays: Moment[];
  cellWidth?: number;
  item: IBaseCalendarItem;
}
/**
 * Hook that computes the left and right margin that needs to be applied to a calendar item to align it to its start and end date.
 * It ensures that the margins are not negative in the end, so the item does not exceed the column of its start and end date,
 * and also ensures that the final width of the item after applying the margins will not be lower than the width of a cell in the calendar.
 */
const useCalendarItemMargins = ({ cellWidth, displayedDays, item }: IUseCalendarItemMargins) => {
  const preferredLeftMargin = useMemo(() => {
    const elapsedSeconds = item.StartDate
      ? item.StartDate.unix() - moment(item.StartDate).startOf("day").unix()
      : 0;

    const elapsedPercentage = elapsedSeconds / SECONDS_IN_DAY;

    return round(elapsedPercentage * (cellWidth ?? 0), 2);
  }, [cellWidth, item.StartDate]);

  const preferredRightMargin = useMemo(() => {
    const remainingSeconds = item.EndDate
      ? moment(item.EndDate).endOf("day").unix() - item.EndDate.unix()
      : 0;

    const remainingPercentage = remainingSeconds / SECONDS_IN_DAY;

    return round(remainingPercentage * (cellWidth ?? 0), 2);
  }, [cellWidth, item.EndDate]);

  // adjust margins so that the item has at least the width of a cell and there are no negative margins
  const adjustedMargins = useMemo(() => {
    const totalDaysSpanned = moment(
      item.EndDate ?? endOfDayWithoutMilliseconds(displayedDays[displayedDays.length - 1]),
    )
      .startOf("day")
      .add(1, "days")
      .diff(moment(item.StartDate ?? displayedDays[0]).startOf("day"), "days");

    // make sure the width after applying the margin will be at least the width of a cell
    const maxTotalMargins = Math.max(0, (cellWidth ?? 0) * totalDaysSpanned - (cellWidth ?? 0));

    if (preferredLeftMargin + preferredRightMargin < maxTotalMargins) {
      return {
        leftMargin: preferredLeftMargin,
        rightMargin: preferredRightMargin,
      };
    }

    // adjust the margins so the item has the minimum width of a cell; the difference is distributed evenly to left / right margin
    const difference = preferredLeftMargin + preferredRightMargin - maxTotalMargins;

    const adjustedLeftMargin = preferredLeftMargin - difference / 2;
    const adjustedRightMargin = preferredRightMargin - difference / 2;

    // if both adjusted margins have the same sign then just make sure they are never negative so the item does not exceed the start day and end day columns
    if (Math.sign(adjustedLeftMargin) === Math.sign(adjustedRightMargin)) {
      return {
        leftMargin: round(Math.max(adjustedLeftMargin, 0), 2),
        rightMargin: round(Math.max(adjustedRightMargin, 0), 2),
      };
    }

    // if only the adjusted right margin is negative, shift both margins so the right margin becomes 0 and the width of the item is unchanged
    if (adjustedRightMargin < 0) {
      return {
        leftMargin: round(adjustedLeftMargin + Math.min(adjustedRightMargin, 0), 2),
        rightMargin: round(adjustedRightMargin - Math.min(adjustedRightMargin, 0), 2),
      };
    }

    // if only the adjusted left margin is negative, shift both margins so the left margin becomes 0 and the width of the item is unchanged
    return {
      leftMargin: round(adjustedLeftMargin - Math.min(adjustedLeftMargin, 0), 2),
      rightMargin: round(adjustedRightMargin + Math.min(adjustedLeftMargin, 0), 2),
    };
  }, [
    cellWidth,
    displayedDays,
    item.EndDate,
    item.StartDate,
    preferredLeftMargin,
    preferredRightMargin,
  ]);

  return { ...adjustedMargins };
};

export default useCalendarItemMargins;
