import { useCallback, useEffect, useState } from "react";

// Helper hook that abstracts boolean state with easy update/toggle helpers.
//
const useBoolean = (initial?: boolean) => {
  const [isTrue, setIsTrue] = useState(initial ?? false);

  const toggle = useCallback(() => {
    setIsTrue((state) => !state);
  }, []);

  const update = useCallback((value: boolean) => {
    setIsTrue(value);
  }, []);

  useEffect(() => {
    if (initial !== undefined && initial !== null) {
      update(initial);
    }
  }, [initial, update]);

  return {
    isTrue,
    toggle,
    update,
  };
};

export default useBoolean;
