export const getFlattenedChildren = (
  configurableProduct?: EVA.Core.ConfigurableProductDto,
): EVA.Core.ConfigurableProductDto[] => {
  if (!configurableProduct?.Children) {
    return [];
  }

  return configurableProduct.Children.flatMap((firstLevelChild) =>
    firstLevelChild.Children.length ? firstLevelChild.Children : firstLevelChild,
  );
};
