import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Core } from "@springtree/eva-services-core";

import { toast } from "components/suite-ui/toast";
import useCallService from "hooks/suite-react-hooks/use-call-service";

const useDeclineTasks = (deselectRows: () => void, refetchTasks: () => void) => {
  const intl = useIntl();
  const { callService: deactivateUserTasks } = useCallService({
    service: Core.DeactivateUserTask,
    disableNotifications: true,
    options: {
      onSuccess: (data) => {
        if (data) {
          // deselect the selected rows
          //
          deselectRows();

          refetchTasks();

          // Notify the user of the succesful generated document.
          //
          toast.success(
            intl.formatMessage({
              id: "decline-tasks.success",
              defaultMessage: "Successfully declined tasks",
            }),
          );
        } else {
          console.error("no response");

          // Notify the user that something went wrong
          //
          toast.error(
            intl.formatMessage({
              id: "decline-tasks.error",
              defaultMessage: "Something went wrong while declining tasks",
            }),
          );
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: "decline-tasks.error",
            defaultMessage: "Something went wrong while declining tasks",
          }),
        );
      },
    },
  });

  const declineTasks = useCallback(
    (userTaskIds?: number[]) => {
      if (userTaskIds?.length) {
        deactivateUserTasks({
          UserTaskIDs: userTaskIds,
        });
      }
    },
    [deactivateUserTasks],
  );

  return declineTasks;
};

export default useDeclineTasks;
