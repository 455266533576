import routeDefinitions from "routes/route-definitions";

export const routesWithOUSelector = [
  routeDefinitions.stockManagement.replenishments.replenishmentProducts.overview.path,
  routeDefinitions.financials.financialPeriods.overview.path,
  routeDefinitions.people.consumers.overview.path,
  routeDefinitions.organizations.companies.overview.path,
  routeDefinitions.tasks.incomingShipment.overview.path,
  routeDefinitions.tasks.reservation.overview.path,
  routeDefinitions.tasks.print.overview.path,
  routeDefinitions.tasks.reservationCleanup.overview.path,
  routeDefinitions.tasks.shipFromStore.overview.path,
  routeDefinitions.orders.orders.overview.path,
  routeDefinitions.orders.checkoutOptions.overview.path,
  routeDefinitions.pim.plugin.path,
  routeDefinitions.stockManagement.shipping.shippingMethodsAndCosts.overview.path,
  routeDefinitions.compliance.manualInvoices.new.path,
  routeDefinitions.controlRoom.cultures.overview.path,
];
