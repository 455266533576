import { useMemo } from "react";

import { CustomFieldType } from "types/custom-field";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import { useCustomFieldSearchListFieldItems } from "../generate-custom-field-search-list-field";

export const useGenerateCustomFieldLyraSearchListField = (
  customFieldType: CustomFieldType,
  organizationUnitID?: number,
  externalCustomFieldOptions?: {
    [key: number]: EVA.Core.CustomFieldOptions;
  },
) => {
  const { isLoading, items } = useCustomFieldSearchListFieldItems(
    customFieldType,
    organizationUnitID,
    externalCustomFieldOptions,
  );

  const {
    MultiIDSearchListField: CustomFieldMultiIDSearchListField,
    MultiSearchListField: CustomFieldMultiSearchListField,
    SingleIDSearchListField: CustomFieldSingleIDSearchListField,
    SingleSearchListField: CustomFieldSearchListField,
  } = useMemo(
    () =>
      SearchListFieldGenerator.LocalSearchListFieldGenerator({
        items: items,
        getItemId: (item) => item.CustomFieldID,
        getLabel: (item) => item.DisplayName ?? item.Name,
        selectRenderElements: (item) => ({ label: item.DisplayName ?? item.Name }),
        defaultLabel: intlAccessor.formatMessage({
          id: "generic.label.custom-field",
          defaultMessage: "Custom field",
        }),
        useItemByID: (id) => {
          const data = items?.find((customField) => customField.CustomFieldID === id);

          return { data };
        },
        useItemsByID(ids) {
          const data = items?.filter((customField) => ids?.includes(customField.CustomFieldID));

          return { data };
        },
      }),
    [items],
  );

  return {
    CustomFieldMultiIDSearchListField,
    CustomFieldMultiSearchListField,
    CustomFieldSingleIDSearchListField,
    CustomFieldSearchListField,
    items,
    isLoading,
  };
};
