import { Core } from "@springtree/eva-services-core";
import { CoreLiveGuard } from "@springtree/eva-services-core-live-guard";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

// GetDevice
export const {
  serviceLoaderQuery: getDeviceLoaderQuery,
  serviceQuery: getDeviceQuery,
  serviceQueryKeys: getDeviceQueryKeys,
  useServiceQueryHook: useGetDeviceQuery,
} = createServiceQuery(Core.GetDevice);

// ListDevices
export const {
  serviceLoaderQuery: listDevicesLoaderQuery,
  serviceQuery: listDevicesQuery,
  serviceQueryKeys: listDevicesQueryKeys,
  useServiceQueryHook: useListDevicesQuery,
} = createServiceQuery(Core.ListDevices);

// GetDeviceTypes
export const {
  serviceLoaderQuery: getDeviceTypesLoaderQuery,
  serviceQuery: getDeviceTypesQuery,
  serviceQueryKeys: getDeviceTypesQueryKeys,
  useServiceQueryHook: useGetDeviceTypesQuery,
} = createServiceQuery(Core.GetDeviceTypes, false);

// GetPrinterTypes
export const {
  serviceLoaderQuery: getPrinterTypesLoaderQuery,
  serviceQuery: getPrinterTypesQuery,
  serviceQueryKeys: getPrinterTypesQueryKeys,
  useServiceQueryHook: useGetPrinterTypesQuery,
} = createServiceQuery(CoreManagement.GetPrinterTypes, false);

// ListWatchtowerDevices

export const {
  serviceLoaderQuery: listWatchtowerDevicesLoaderQuery,
  serviceQuery: listWatchtowerDevicesQuery,
  serviceQueryKeys: listWatchtowerDevicesQueryKeys,
  useServiceQueryHook: useListWatchtowerDevicesQuery,
  useServiceQueryWithRecoilRequest: useListWatchtowerDevicesWithRecoilRequest,
} = createServiceQuery(CoreLiveGuard.ListWatchtowerDevices);

// WatchtowerGetDeviceDetails
export const {
  serviceLoaderQuery: watchtowerGetDeviceDetailsLoaderQuery,
  serviceQuery: watchtowerGetDeviceDetailsQuery,
  serviceQueryKeys: watchtowerGetDeviceDetailsQueryKeys,
  useServiceQueryHook: useWatchtowerGetDeviceDetailsQuery,
} = createServiceQuery(CoreLiveGuard.WatchtowerGetDeviceDetails);

export const {
  serviceLoaderQuery: getAssemblyNameForDeviceTypesLoaderQuery,
  serviceQuery: getAssemblyNameForDeviceTypesQuery,
  serviceQueryKeys: getAssemblyNameForDeviceTypesQueryKeys,
  useServiceQueryHook: useGetAssemblyNameForDeviceTypesQuery,
} = createServiceQuery(Core. GetAssemblyNamesForDeviceTypes)