import { memo, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { areEqual, ListChildComponentProps } from "react-window";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import { Separator } from "@new-black/lyra";
import Grid from "components/suite-ui/grid";
import Input from "components/suite-ui/input";

import { ProductSummary } from "../product-summary";

import { IProductSetProduct } from "./types";

export interface IProductSetListItem extends ListChildComponentProps<IProductSetProduct[]> {
  onDelete: (ID: number) => void;
  placeholderImageUrl?: string;
  listHeight: number;
  itemSize: number;
  showQuantity?: boolean;
  showBarcodeInput?: boolean;
  onAddBarcode: (productID: number, barcode?: string) => void;
}

const ProductSetListItem = memo(
  ({
    data,
    index: currentIndex,
    itemSize,
    listHeight,
    onAddBarcode,
    onDelete,
    placeholderImageUrl,
    showBarcodeInput,
    showQuantity,
    style,
  }: IProductSetListItem) => {
    const intl = useIntl();

    const displayDivider = useMemo(
      () =>
        currentIndex !== data.length - 1 ||
        (currentIndex === data.length - 1 && itemSize * data.length < listHeight),
      [currentIndex, data.length, itemSize, listHeight],
    );

    const [barcodeValue, setBarcodeValue] = useState<string | undefined>(
      data[currentIndex].Barcode,
    );

    return (
      <div style={style}>
        <div className="px-5">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs zeroMinWidth>
              <div className="py-[5px]">
                <ProductSummary
                  productID={data[currentIndex].ID}
                  customID={data[currentIndex].CustomID}
                  backendID={data[currentIndex].BackendID}
                  productName={data[currentIndex].Name}
                  productImageBlobID={data[currentIndex].ImageBlobID}
                  placeholderImageUrl={placeholderImageUrl}
                >
                  {showQuantity ? (
                    <div className="text-xs">
                      <FormattedMessage
                        id="generic.key-value.quantity"
                        defaultMessage="Quantity: {quantity}"
                        values={{ quantity: data[currentIndex].Quantity }}
                      />
                    </div>
                  ) : null}
                </ProductSummary>
              </div>
            </Grid>
            <Grid item>
              <DeleteActionButton onPress={() => onDelete(data[currentIndex].ID)} />
            </Grid>
            {showBarcodeInput ? (
              <Input
                label={intl.formatMessage({
                  id: "generic.label.barcode",
                  defaultMessage: "Barcode",
                })}
                small
                className="my-3"
                value={barcodeValue}
                onChange={(event) => setBarcodeValue(event.target.value)}
                onBlur={() => onAddBarcode(data[currentIndex].ID, barcodeValue)}
              />
            ) : null}
          </Grid>
        </div>
        {displayDivider ? <Separator className="my-0" /> : null}
      </div>
    );
  },
  areEqual,
);

ProductSetListItem.displayName = "ProductSetListItem";

export default ProductSetListItem;
