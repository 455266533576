import { ComponentPropsWithoutRef, memo, useMemo } from "react";

import classNames from "classnames";
import moment, { Moment } from "moment";

import Text, { TextProps } from "../text";

import { isDateToday } from "./helpers";

export interface ICalendarHeaderDay {
  day: Moment;
  month: Moment;
  column: number;
}

interface WrapperProps extends ComponentPropsWithoutRef<"div"> {
  column: number;
}

interface StyledTextProps extends TextProps {
  isToday: boolean;
}

export const CalendarHeaderDay = memo(({ column, day, month }: ICalendarHeaderDay) => {
  const isToday = useMemo(() => isDateToday(day), [day]);
  const textColor = useMemo(
    () =>
      day.isSameOrAfter(moment(month).startOf("month")) &&
      day.isSameOrBefore(moment(month).endOf("month"))
        ? undefined
        : "textSecondary",
    [day, month],
  );

  return (
    <Wrapper column={column}>
      <Bubble isToday={isToday}>
        <StyledText
          variant="overline"
          color={isToday ? "inherit" : textColor}
          align="center"
          isToday={isToday}
        >
          {day.format("D")}
        </StyledText>
      </Bubble>
    </Wrapper>
  );
});

CalendarHeaderDay.displayName = "CalendarHeaderDay";

const Wrapper = ({ children, className, column }: WrapperProps) => (
  <div
    style={{ gridColumnStart: column }}
    className={classNames(
      "col-span-1 row-span-1 row-start-2 mx-auto mr-[100px] mt-0 h-[38px] w-[28px] bg-[color:#fff] pb-[10px]",
      className,
    )}
  >
    {children}
  </div>
);

const Bubble = ({
  children,
  className,
  isToday,
  ...props
}: ComponentPropsWithoutRef<"div"> & { isToday: boolean }) => (
  <div
    {...props}
    className={classNames(
      "flex h-full w-full items-center justify-center rounded-full",
      isToday && "bg-[color:var(--legacy-eva-color-primary)]",
      className,
    )}
  >
    {children}
  </div>
);

const StyledText = ({ className, isToday, ...props }: StyledTextProps) => (
  <Text
    {...props}
    className={classNames("font-medium", isToday ? "text-[#fff]" : "", className)}
  ></Text>
);
