import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import Autocomplete from "@material-ui/lab/Autocomplete";
import classNames from "classnames";

import AddActionButton from "components/shared/action-buttons/add-action-button";
import DraggableItem from "components/suite-ui/drag-and-drop/draggable-item";
import Input from "components/suite-ui/input";

import type { IOrderableColumn } from "../table-types";

import ColumnButton from "./column-button";

interface IOrganizeColumnsContainerProps {
  columns: IOrderableColumn[];
  setColumns: (newValue: IOrderableColumn[]) => void;
  allColumns: IOrderableColumn[];
  dragColumnTooltip?: string;
  removeColumnTooltip?: string;
  addColumnTooltip?: string;
  className?: string;
}

const OrganizeColumnsContainer = ({
  addColumnTooltip,
  allColumns,
  className,
  columns,
  dragColumnTooltip,
  removeColumnTooltip,
  setColumns,
}: IOrganizeColumnsContainerProps) => {
  const intl = useIntl();
  const [showColumnSelect, setShowColumnSelect] = useState(false);

  const selectColumnOptions = useMemo(
    () =>
      allColumns
        .filter(
          (columnOption) =>
            columnOption !== undefined &&
            !columns.map((x) => x?.value).includes(columnOption?.value),
        )
        .map((columnOption) => ({
          label: columnOption.label,
          value: columnOption.value,
        })),
    [allColumns, columns],
  );

  const removeColumn = useCallback(
    (column: IOrderableColumn) =>
      setColumns(columns.filter((filteredColumn) => filteredColumn.value !== column.value)),
    [columns, setColumns],
  );

  return (
    <div className={classNames("flex flex-wrap items-center gap-3 p-4 pt-0", className)}>
      {columns.map((column) => (
        <DraggableItem
          key={column.value}
          draggableId={column.value}
          ItemComponent={ColumnButton}
          itemComponentProps={{
            label: column.label,
            toggleVisible: () => removeColumn(column),
            dragColumnTooltip,
            removeColumnTooltip,
          }}
        />
      ))}

      {selectColumnOptions && selectColumnOptions.length ? (
        <>
          {!showColumnSelect ? (
            <AddActionButton
              onPress={() => setShowColumnSelect(true)}
              tooltip={
                addColumnTooltip ??
                intl.formatMessage({
                  id: "generic.label.add-column",
                  defaultMessage: "Add column",
                })
              }
            />
          ) : (
            <div className="min-w-[300px]">
              <Autocomplete
                options={selectColumnOptions ?? []}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option.value === value.value}
                onBlur={() => setShowColumnSelect(false)}
                value={null}
                onChange={(_event, newValue) => {
                  if (newValue) {
                    setColumns([...columns, newValue]);
                    setShowColumnSelect(false);
                  }
                }}
                renderInput={(params) => (
                  <Input
                    {...params}
                    small
                    fullWidth
                    autoFocus
                    placeholder={
                      addColumnTooltip ??
                      intl.formatMessage({
                        id: "generic.label.add-column",
                        defaultMessage: "Add column",
                      })
                    }
                  />
                )}
              />
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

export default OrganizeColumnsContainer;
