import type { Dispatch, SetStateAction } from "react";
import type { DayPickerSingleDateControllerShape } from "react-dates";
import { DayPickerSingleDateController } from "react-dates";

import type { PopoverOrigin } from "@material-ui/core/Popover";
import Popover from "@material-ui/core/Popover";
import { omit } from "lodash";

export interface IDatePicker extends DayPickerSingleDateControllerShape {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  id: string;
  absolutePosition?: { top: number; left: number };
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableAutoClose?: boolean;
}

const DatePicker = (props: IDatePicker) => {
  const {
    absolutePosition,
    anchorEl,
    anchorOrigin,
    disableAutoClose,
    id,
    open,
    setOpen,
    transformOrigin,
  } = props;

  const datePickerRangeControllerProps = omit(props, [
    "open",
    "setOpen",
    "anchorEl",
    "id",
    "anchorOrigin",
    "transformOrigin",
    "disableAutoClose",
  ]);

  return (
    <Popover
      id={id}
      open={open}
      anchorPosition={absolutePosition}
      anchorEl={!absolutePosition ? anchorEl : undefined}
      anchorReference={absolutePosition ? "anchorPosition" : undefined}
      anchorOrigin={
        !absolutePosition
          ? anchorOrigin ?? {
              vertical: "bottom",
              horizontal: "right",
            }
          : undefined
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: "top",
          horizontal: "right",
        }
      }
      onClose={() => {
        setOpen(false);
      }}
      disableRestoreFocus
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="date-picker-wrapper">
        <DayPickerSingleDateController
          hideKeyboardShortcutsPanel
          weekDayFormat="dd"
          daySize={34}
          {...datePickerRangeControllerProps}
          onFocusChange={({ focused }) => {
            props?.onFocusChange?.({ focused });

            if (focused === false && !disableAutoClose) {
              setOpen(false);
            }
          }}
        />
      </div>
    </Popover>
  );
};

export default DatePicker;
