import type { ReactNode } from "react";
import { createContext } from "react";
import { HelmetProvider } from "react-helmet-async";

export interface ITitleHelmetContext {
  moduleName: string;
}

const TitleHelmetContext = createContext<ITitleHelmetContext>({
  moduleName: "unset",
});

export default TitleHelmetContext;

export const TitleHelmetContextProvider = ({
  children,
  moduleName,
}: ITitleHelmetContext & { children: ReactNode }) => (
  <HelmetProvider>
    <TitleHelmetContext.Provider value={{ moduleName }}>{children}</TitleHelmetContext.Provider>
  </HelmetProvider>
);
