import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const userByIdServiceState = builders.buildServiceFamilyState({
  service: Core.GetUser,
  key: "Composite:Core.GetUser",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.ID) {
      return req;
    }

    return { ...req, ID: parameter };
  },
});

export const userByIdSelector = selectorFamily({
  key: "Composite:Core.GetUser:Result",
  get:
    (ID: number | undefined) =>
    ({ get }) =>
      get(userByIdServiceState.response(ID)),
});
