import { IntlShape } from "react-intl";

import { UserBudgetDeduction } from "types/eva-core";

export const getUserBudgetDeductionTranslator = (
  intl: IntlShape,
): ((status: number) => string | undefined) => {
  const translationMap: Record<EVA.Core.UserBudgetDeduction | number, string> = {
    [UserBudgetDeduction.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [UserBudgetDeduction.Both]: intl.formatMessage({
      id: "generic.label.both",
      defaultMessage: "Both",
    }),
    [UserBudgetDeduction.PaymentMethod]: intl.formatMessage({
      id: "generic.label.payment-method",
      defaultMessage: "Payment method",
    }),
    [UserBudgetDeduction.Program]: intl.formatMessage({
      id: "generic.label.program",
      defaultMessage: "Program",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
