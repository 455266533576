import { useEffect } from "react";

import { useMonaco } from "@monaco-editor/react";
import type Monaco from "monaco-editor";

import { ICodeEditorError } from "../script-code-editor.types";

/**
 * Helper hook that updates a monaco model with a set of errors
 *
 */
const useSetErrorsOnModel = (
  model?: Monaco.editor.ITextModel | null,
  errors?: ICodeEditorError[],
) => {
  const monaco = useMonaco();

  // whenever the errors change, map them to the interface used by monaco and update the provided model
  useEffect(() => {
    if (model && monaco) {
      monaco.editor.setModelMarkers(
        model,
        "owner",
        errors?.map(
          (error) =>
            ({ ...error, severity: monaco.MarkerSeverity.Error } as Monaco.editor.IMarkerData),
        ) ?? [],
      );
    }
  }, [errors, model, monaco]);
};

export default useSetErrorsOnModel;
