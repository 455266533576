import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import {
  generateMultiLocalSearchListField,
  generateMultiSearchListField,
} from "./generate-multi-search-list-field";
import {
  generateSingleLocalSearchListField,
  generateSingleSearchListField,
} from "./generate-single-search-list-field";
import {
  IMultiSearchListFieldWrapperReturnValues,
  IMultiSearchListFieldWrapperWithIDReturnValues,
  MultiSearchListFieldIDWrapper,
  MultiSearchListFieldWrapper,
} from "./multi-search-list-field-wrapper";
import {
  ILocalSearchListFieldGeneratorProps,
  ISearchListFieldGeneratorProps,
} from "./search-list-field-generator.types";
import {
  ISingleSearchListFieldWrapperReturnValues,
  ISingleSearchListFieldWrapperWithIDReturnValues,
  SingleSearchListFieldIDWrapper,
  SingleSearchListFieldWrapper,
} from "./single-search-list-field-wrapper";
import { useSearchListFieldService } from "./use-search-list-field-service";

export interface ISearchListFieldGeneratorReturnValues<
  Item = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
> {
  SingleSearchListField: ISingleSearchListFieldWrapperReturnValues<Item>;
  SingleIDSearchListField: ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey>;
  MultiSearchListField: IMultiSearchListFieldWrapperReturnValues<Item>;
  MultiIDSearchListField: IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey>;
}

/** Utility for generating **Single** and a **Multi SearchListField** components based on a service, with filtering capabilities
 * Both of these SearchListField have different Wrapped variants as well:
 * - Single
 *     - Controlled
 *     - Uncontrolled
 *     - Formik
 *     - Recoil
 * - Multi
 *     - Controlled
 *     - Uncontrolled
 *     - Formik
 *     - Recoil
 * 
 * ### Example
 * 
 * ```tsx
  export const {
      MultiSearchListField: GeneratedMultiOUSearchListField,
        // GeneratedMultiOUSearchListField.Controlled
        // GeneratedMultiOUSearchListField.Uncontrolled
        // GeneratedMultiOUSearchListField.Formik
        // GeneratedMultiOUSearchListField.Recoil
      SingleSearchListField: GeneratedSingleOUSearchListField,
        // GeneratedSingleOUSearchListField.Controlled
        // GeneratedSingleOUSearchListField.Uncontrolled
        // GeneratedSingleOUSearchListField.Formik
        // GeneratedSingleOUSearchListField.Recoil
        // GeneratedSingleOUSearchListField.RecoilIDOnlyWithQuery
      MultiIDSearchListField: GeneratedMultiIDOUSearchListField,
        // GeneratedMultiIDOUSearchListField.Controlled
        // GeneratedMultiIDOUSearchListField.Uncontrolled
        // GeneratedMultiIDOUSearchListField.Formik
        // GeneratedMultiIDOUSearchListField.Recoil
      SingleIDSearchListField: GeneratedSingleIDOUSearchListField,
        // GeneratedSingleIDOUSearchListField.Controlled
        // GeneratedSingleIDOUSearchListField.Uncontrolled
        // GeneratedSingleIDOUSearchListField.Formik
        // GeneratedSingleIDOUSearchListField.Recoil
  } = SearchListFieldGenerator<Core.ListOrganizationUnitSummaries, { ID: number; Name: string }>({
    // The generic parameters are optional and automatically inferred by the values provided (service typing), but they can also be overriden manually
    // The `{ ID: number; Name: string }` structure is the default type of the SearchListField Items - of course, you can override this as needed
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit",
      defaultMessage: "Organization unit",
    }),
    useItemByID: (id) => {
      const { item: data, isLoading } = useOrganizationUnitDetailsById(id);
      return { data, isLoading };
    },
    useItemsByID: (ids) => {
      const { items: data, isLoading } = useOrganizationUnitDetailsById(
        ids?.filter((id): id is number => !!id),
      );
      return { data, isLoading };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: ListOrganizationUnitSummariesQuery,
        initialRequest: { PageConfig: { Start: 0, Limit: 10 } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });
 * ```
 */
const SearchListFieldGenerator = <
  SVC extends IEvaServiceDefinition,
  Item = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
>({
  useItemByID,
  useItemsByID,
  ...props
}: ISearchListFieldGeneratorProps<SVC, Item, IDKey>): ISearchListFieldGeneratorReturnValues<
  Item,
  IDKey
> => {
  const fallbackGetItemHook = (type: "useItemByID" | "useItemsByID") => () => {
    throw new Error(
      `You must provide the ${type} hook as a prop to the generator if you want to use the ${
        type === "useItemByID" ? "SingleIDSearchListField" : "MultiIDSearchListField"
      } component`,
    );
  };

  const SingleSearchListField = generateSingleSearchListField<SVC, Item>(props);
  const WrappedSingleSearchListField = SingleSearchListFieldWrapper(SingleSearchListField);
  const WrappedSingleIDSearchListField = SingleSearchListFieldIDWrapper(
    SingleSearchListField,
    props.idKey,
    useItemByID ?? fallbackGetItemHook("useItemByID"),
  );

  const MultiSearchListField = generateMultiSearchListField<SVC, Item>(props);
  const WrappedMultiSearchListField = MultiSearchListFieldWrapper(MultiSearchListField);
  const WrappedMultiIDSearchListField = MultiSearchListFieldIDWrapper(
    MultiSearchListField,
    props.idKey,
    useItemsByID ?? fallbackGetItemHook("useItemsByID"),
  );

  return {
    SingleSearchListField: WrappedSingleSearchListField,
    SingleIDSearchListField: WrappedSingleIDSearchListField,
    MultiSearchListField: WrappedMultiSearchListField,
    MultiIDSearchListField: WrappedMultiIDSearchListField,
  };
};

SearchListFieldGenerator.useSearchListFieldService = useSearchListFieldService;

/** Utility for generating **Single** and a **Multi SearchListField** components based on a local array, with filtering capabilities
 * Both of these SearchListFields have different Wrapped variants, as well as ID-based variants (so that you can work with the SearchListField based on the ID only):
 * - Single
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 * - Single (ID-based)
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 * - Multi
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 * - Multi (ID-based)
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 *
 * ### Example
 *
 * ```tsx
  const list = [
      { ID: 1, Name: "Item 1" },
      { ID: 2, Name: "Item 2" },
      { ID: 3, Name: "Item 3" },
      { ID: 4, Name: "Item 4" },
      { ID: 5, Name: "Item 5" },
      { ID: 6, Name: "Item 6" },
      { ID: 7, Name: "Item 7" },
      { ID: 8, Name: "Item 8" },
      { ID: 9, Name: "Item 9" },
      { ID: 10, Name: "Item 10" },
    ];

  function useGetExampleItemByID(
    id: number | undefined,
    searchListFieldList: IExampleItem[] | undefined,
  ) {
    const item = useMemo(
      () => searchListFieldList?.find((item) => item.ID === id),
      [searchListFieldList, id],
    );
    return { data: item, isLoading: false };
  }

  function useGetExampleItemsByID(
    ids: number[] | undefined,
    searchListFieldList: IExampleItem[] | undefined,
  ) {
    const items = useMemo(
      () =>
        ids
          ?.map((id) => searchListFieldList?.find((item) => item.ID === id))
          .filter((item): item is IExampleItem => !!item),
      [searchListFieldList, ids],
    );
    return { data: items, isLoading: false };
  }

  export const {
   MultiSearchListField: GeneratedMultiItemSearchListField,
    // GeneratedMultiItemSearchListField.Controlled
    // GeneratedMultiItemSearchListField.Uncontrolled
    // GeneratedMultiItemSearchListField.Formik
    // GeneratedMultiItemSearchListField.Recoil
   MultiIDSearchListField: GeneratedMultiIDItemSearchListField,
    // GeneratedMultiIDItemSearchListField.Controlled
    // GeneratedMultiIDItemSearchListField.Uncontrolled
    // GeneratedMultiIDItemSearchListField.Formik
    // GeneratedMultiIDItemSearchListField.Recoil
  SingleSearchListField: GeneratedSingleItemSearchListField,
    // GeneratedSingleItemSearchListField.Controlled
    // GeneratedSingleItemSearchListField.Uncontrolled
    // GeneratedSingleItemSearchListField.Formik
    // GeneratedSingleItemSearchListField.Recoil
  SingleIDSearchListField: GeneratedSingleIDItemSearchListField,
    // GeneratedSingleIDItemSearchListField.Controlled
    // GeneratedSingleIDItemSearchListField.Uncontrolled
    // GeneratedSingleIDItemSearchListField.Formik
    // GeneratedSingleIDItemSearchListField.Recoil
  } = SearchListFieldGenerator.LocalSearchListFieldGenerator({
  // The generic parameters are optional and automatically inferred by the values provided, but they can also be overriden manually
  // The `{ ID: number; Name: string }` structure is the default type of the SearchListField Items - of course, you can override this as needed
    idKey: "ID",
    items: list,
    labelKey: "Name",
    defaultLabel: "Item",
    // The following hooks are optional, but if you want to use the ID-based variants, you must provide them
    getItemByID: useGetExampleItemByID, // needed for SingleIDSearchListField
    getItemsByIDs: useGetExampleItemsByID, // needed for MultiIDSearchListField
  });
 * ```
 **/
SearchListFieldGenerator.LocalSearchListFieldGenerator = <
  Item extends object = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
>({
  useItemByID,
  useItemsByID,
  ...props
}: ILocalSearchListFieldGeneratorProps<Item, IDKey>): ISearchListFieldGeneratorReturnValues<
  Item,
  IDKey
> => {
  const fallbackGetItemHook = (type: "useItemByID" | "useItemsByID") => () => {
    throw new Error(
      `You must provide the ${type} hook as a prop to the generator if you want to use the ${
        type === "useItemByID" ? "SingleIDSearchListField" : "MultiIDSearchListField"
      } component`,
    );
  };

  const SingleSearchListField = generateSingleLocalSearchListField(props);
  const WrappedSingleSearchListField = SingleSearchListFieldWrapper(SingleSearchListField);
  const WrappedSingleIDSearchListField = SingleSearchListFieldIDWrapper(
    SingleSearchListField,
    props.idKey,
    useItemByID ?? fallbackGetItemHook("useItemByID"),
  );

  const MultiSearchListField = generateMultiLocalSearchListField(props);
  const WrappedMultiSearchListField = MultiSearchListFieldWrapper(MultiSearchListField);
  const WrappedMultiIDSearchListField = MultiSearchListFieldIDWrapper(
    MultiSearchListField,
    props.idKey,
    useItemsByID ?? fallbackGetItemHook("useItemsByID"),
  );

  return {
    SingleSearchListField: WrappedSingleSearchListField,
    SingleIDSearchListField: WrappedSingleIDSearchListField,
    MultiSearchListField: WrappedMultiSearchListField,
    MultiIDSearchListField: WrappedMultiIDSearchListField,
  };
};

export { SearchListFieldGenerator };
