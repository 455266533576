import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const listTaxRatesState = builders.buildServiceState({
  requireEmployee: true,
  key: "list-tax-rates",
  allowEmptyRequest: true,
  service: Core.ListTaxRates,
});

export default listTaxRatesState;
