import { useQuery } from "@tanstack/react-query";

import { getCarrierByIDService } from "models/carriers";

export const useCarrierById = (id?: number) => {
  const { data, isFetching: isLoading } = useQuery({
    ...getCarrierByIDService(
      id
        ? {
            ID: id,
          }
        : undefined,
    ),
    enabled: !!id,
  });

  return { data: id ? data : undefined, isLoading };
};
