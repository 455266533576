import { useCallback, useState } from "react";

import type { DragEndEvent, DragOverEvent, DragStartEvent, UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";

const useDragEvents = <TItemProps extends { value: string; items?: TItemProps[] }>(
  items: TItemProps[],
  setItems: (newItems: TItemProps[]) => void,
  onDragStartCallback?: () => void,
  onDragEndCallback?: () => void,
) => {
  const [activeItem, setActiveItem] = useState<TItemProps>();
  const [clonedItems, setClonedItems] = useState<TItemProps[] | null>(null);

  const findContainer = useCallback(
    (id: UniqueIdentifier) => {
      const itemOrContainer = items.find(
        (item) => item.value === id || item.items?.find((y) => y.value === id),
      );

      if (!itemOrContainer) {
        const dropppableContainerItem = id.toString().split("-");
        if (dropppableContainerItem?.length > 0) {
          const containerId = items.find((x) => `${x.value}` === dropppableContainerItem[0]);

          return containerId;
        }
      }

      if (itemOrContainer?.value === id) {
        // top level so we don't have an inner container
        return undefined;
      }

      // it's a container
      return itemOrContainer;
    },
    [items],
  );

  const onDragStart = useCallback(
    (event: DragStartEvent) => {
      const itemOrContainer = items.find(
        (item) =>
          item.value === event.active.id || item.items?.find((y) => y.value === event.active.id),
      );

      setActiveItem(
        itemOrContainer?.value === event.active.id
          ? itemOrContainer
          : itemOrContainer?.items?.find((y) => y.value === event.active.id),
      );
      setClonedItems(items);
      onDragStartCallback?.();
    },
    [items, onDragStartCallback],
  );

  const onDragCancel = useCallback(() => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containrs
      setItems(clonedItems);
    }

    setActiveItem(undefined);
    setClonedItems(null);
  }, [clonedItems, setItems]);

  const onDragOver = useCallback(
    ({ active, over }: DragOverEvent) => {
      const overId = over?.id;
      if (!overId) {
        return;
      }

      const overContainer = findContainer(overId);
      const activeContainer = findContainer(active.id);

      if (activeContainer !== overContainer) {
        // moved to another container
        const activeItems = activeContainer?.items || items;
        const overItems = overContainer?.items || items;
        const overIndex = overItems?.findIndex((item) => item.value === overId);
        const activeIndex = activeItems?.findIndex((item) => item.value === active.id);

        let newIndex: number;
        const isOverTopLevel = items.find((item) => item.value === overId);
        if (overItems && overIndex && !overItems?.find((y) => y.value === active.id)) {
          if (isOverTopLevel) {
            newIndex = overIndex;
          } else {
            const isBelowLastItem =
              over &&
              overIndex === overItems.length - 1 &&
              (active as any)?.rect?.current?.translated &&
              (active as any)?.rect.current.translated.offsetTop > over.rect.top + over.rect.height;

            const modifier = isBelowLastItem ? 1 : 0;

            newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
          }

          let newItems = items.map((prevItem) => {
            if (prevItem.value === activeContainer?.value) {
              // remove from inner container
              return {
                ...activeContainer,
                items: activeContainer?.items?.filter((item) => item.value !== active.id),
              };
            }

            if (prevItem.value === overContainer?.value) {
              // add to inner container
              return {
                ...overContainer,
                items: overContainer.items
                  ?.slice(0, newIndex)
                  .concat(
                    activeContainer?.items
                      ? activeContainer.items[activeIndex]
                      : items[activeIndex],
                  )
                  .concat(overContainer.items.slice(newIndex, overContainer.items.length)),
              };
            }

            return prevItem;
          });

          if (!activeContainer) {
            // moved from top level to inner, need to remove item from top level
            newItems = newItems.filter((item) => item.value !== active.id);
          }

          if (!overContainer && !items.find((item) => item.value === active.id)) {
            // moved from inner to top, need to add item to top level
            newItems = newItems
              ?.slice(0, newIndex)
              .concat(
                activeContainer?.items ? activeContainer.items[activeIndex] : items[activeIndex],
              )
              .concat(newItems.slice(newIndex, newItems.length));
          }
          setItems(newItems);
        }
      }
    },
    [findContainer, items, setItems],
  );

  const onDragEnd = useCallback(
    ({ active, over }: DragEndEvent) => {
      if (active && over && active.id !== over.id) {
        const activeContainer = findContainer(active.id);
        const overContainer = findContainer(over.id);
        if (activeContainer === overContainer) {
          // reorder in the same container
          let newColumns: TItemProps[] | undefined;
          if (activeContainer?.items) {
            const oldIndex = activeContainer.items?.findIndex((item) => item.value === active.id);
            const newIndex = activeContainer.items?.findIndex((item) => item.value === over.id);
            newColumns = arrayMove(activeContainer.items, oldIndex, newIndex);

            setItems(
              items.map((prevItem) => {
                if (prevItem.value === activeContainer.value) {
                  return { ...activeContainer, items: newColumns };
                }

                return prevItem;
              }),
            );
          } else {
            // top level
            const oldIndex = items.findIndex((item) => item.value === active.id);
            const newIndex = items.findIndex((item) => item.value === over.id);
            if (oldIndex > -1 && newIndex > -1) {
              newColumns = arrayMove(items, oldIndex, newIndex);
              setItems(newColumns);
            }
          }
        }
      }

      setActiveItem(undefined);
      onDragEndCallback?.();
    },
    [findContainer, items, onDragEndCallback, setItems],
  );

  return {
    activeItem,
    onDragStart,
    onDragCancel,
    onDragOver,
    onDragEnd,
  };
};

export default useDragEvents;
