import { ReactNode, useCallback } from "react";

import { SvgIcon } from "@new-black/lyra";

import NoImage from "assets/images/no_image.svg?react";

const IconContainer = ({ children }: { children: ReactNode }) => (
  <div className="flex h-12 w-12 items-center justify-center bg-[color:#EDEDED]">{children}</div>
);

const useGenerateOrderlineImage = () => {
  const generateImage = useCallback((type?: number) => {
    switch (type) {
      case 1:
        return (
          <IconContainer>
            <SvgIcon name="promotion-engine" className="text-secondary" />
          </IconContainer>
        );
      case 2:
        return (
          <IconContainer>
            <SvgIcon name="star-filled" color="secondary" />
          </IconContainer>
        );
      case 5:
        return (
          <IconContainer>
            <SvgIcon name="shipping" className="text-secondary" />
          </IconContainer>
        );
      case 6:
        return (
          <IconContainer>
            <SvgIcon name="return" className="text-secondary" />
          </IconContainer>
        );
      case 7:
        return (
          <IconContainer>
            <SvgIcon name="eraser" color="secondary" />
          </IconContainer>
        );
      case 8:
        return (
          <IconContainer>
            <SvgIcon name="hand" className="text-secondary" />
          </IconContainer>
        );
      case 9:
        return (
          <IconContainer>
            <SvgIcon name="giftcards" className="text-secondary" />
          </IconContainer>
        );
      default:
        return <NoImage width={50} height={50} />;
    }
  }, []);

  return generateImage;
};

export default useGenerateOrderlineImage;
