import { useEffect, useState } from "react";

import { isNaN } from "lodash";

const useParseStringToFloat = (text?: string) => {
  const [parsed, setParsed] = useState<number | undefined>();

  useEffect(() => {
    // first check if the text can be parsed, then set state accordingly
    if (text && !isNaN(parseFloat(text))) {
      setParsed(parseFloat(text));
    } else {
      setParsed(undefined);
    }
  }, [text]);

  return parsed;
};

export default useParseStringToFloat;
