import { useIntl } from "react-intl";

import { ButtonGroup, Text, TextField } from "@new-black/lyra";

import { EditorType, SegmentedButtonEditorSwitch } from "../segmented-button-editor-switch";
import { ThemeSwitch } from "../theme-switch";
import ToggleFullScreenActionButton from "../toggle-full-screen-action-button";

import { ScriptEditorProps } from "./script-editor";

export type ScriptEditorHeaderClassNames = {
  title?: string;
};

type ScriptEditorHeaderProps = Pick<
  ScriptEditorProps,
  | "revisionCommentValue"
  | "readonly"
  | "customActions"
  | "customActionsPosition"
  | "onRevisionCommentValueChange"
  | "hideFullScreenAction"
  | "onShowFullScreenEditor"
  | "title"
> & {
  editorType: EditorType;
  setEditorType: (item: EditorType) => void;
  editorTheme: "light" | "vs-dark";
  setEditorTheme: (newValue: "light" | "vs-dark") => void;
  showFullScreenEditor: boolean;
  setShowFullScreenEditor: (newValue: boolean) => void;
  isEditorTypeSwitchDisabled?: boolean;
  /** Defaults to `true`. */
  showEditorTypeSwitch?: boolean;
  classNames?: ScriptEditorHeaderClassNames;
};

export const ScriptEditorHeader = ({
  classNames,
  customActions,
  customActionsPosition,
  editorTheme,
  editorType,
  hideFullScreenAction,
  isEditorTypeSwitchDisabled,
  onRevisionCommentValueChange,
  onShowFullScreenEditor,
  readonly,
  revisionCommentValue,
  setEditorTheme,
  setEditorType,
  setShowFullScreenEditor,
  showEditorTypeSwitch = true,
  showFullScreenEditor,
  title,
}: ScriptEditorHeaderProps) => {
  const intl = useIntl();

  return (
    <div className="flex items-center gap-4 p-4">
      {title ? (
        <Text variant="heading-2" className={classNames?.title}>
          {title}
        </Text>
      ) : null}

      {readonly || !showEditorTypeSwitch ? null : (
        <SegmentedButtonEditorSwitch
          editorType={editorType}
          setEditorType={setEditorType}
          isDisabled={isEditorTypeSwitchDisabled}
        />
      )}

      {onRevisionCommentValueChange ? (
        <TextField
          hideInputHeader
          placeholder={intl.formatMessage({
            id: "generic.label.add-a-revision-comment",
            defaultMessage: "Add a revision comment",
          })}
          value={revisionCommentValue}
          onChange={onRevisionCommentValueChange}
        />
      ) : null}

      <ButtonGroup className="ml-auto shrink-0">
        {customActions && customActionsPosition !== "end" ? customActions : null}

        {readonly ? null : (
          <>
            <ThemeSwitch editorTheme={editorTheme} setEditorTheme={setEditorTheme} />

            {hideFullScreenAction ? null : (
              <ToggleFullScreenActionButton
                isFullScreen={showFullScreenEditor}
                onFullScreenChange={() => {
                  if (onShowFullScreenEditor) {
                    onShowFullScreenEditor();
                  } else {
                    setShowFullScreenEditor(true);
                  }
                }}
              />
            )}
          </>
        )}

        {customActions && customActionsPosition === "end" ? customActions : null}
      </ButtonGroup>
    </div>
  );
};
