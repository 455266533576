import { Dispatch, SetStateAction, useCallback } from "react";
import { useIntl } from "react-intl";

import { CoreManagement } from "@springtree/eva-services-core-management";

import useCallService from "hooks/suite-react-hooks/use-call-service";

import useCustomFieldOptions from "./use-custom-field-options";

interface IUseUnsetCustomFieldSettings {
  customFieldId?: number;
  optionToDelete:
    | EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto
    | undefined;
  setOptionToDelete: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
}

const useUnsetCustomFieldSettings = ({
  customFieldId,
  optionToDelete,
  setOptionToDelete,
}: IUseUnsetCustomFieldSettings) => {
  const intl = useIntl();

  const { refetch } = useCustomFieldOptions(customFieldId);

  const { callService: unsetCFOption, serviceLoading: isUnsetLoading } = useCallService({
    service: CoreManagement.UnsetCustomFieldOptions,
    messages: {
      successMessage: intl.formatMessage({
        id: "custom-field.options.unset.success",
        defaultMessage: "Custom field option successfully unset",
      }),
      fallbackErrorMessage: intl.formatMessage({
        id: "custom-field.options.unset.error",
        defaultMessage: "Failed to unset custom field option",
      }),
      loadingMessage: intl.formatMessage({
        id: "custom-field.options.unset.loading",
        defaultMessage: "Unsetting custom field option",
      }),
    },
  });

  const handleUnset = useCallback(() => {
    if (customFieldId && optionToDelete?.OrganizationUnitSet) {
      unsetCFOption({
        CustomFieldID: customFieldId,
        OrganizationUnitSetID: optionToDelete.OrganizationUnitSet.ID,
      }).then(() => {
        setOptionToDelete(undefined);
        refetch();
      });
    }
  }, [customFieldId, optionToDelete, refetch, setOptionToDelete, unsetCFOption]);

  return { isUnsetLoading, handleUnset };
};

export default useUnsetCustomFieldSettings;
