import { toast } from "react-hot-toast";

import saveAs from "file-saver";

import { intlAccessor } from "./intl-accessor";

export const saveResource = async (
  resourceUrl?: string,
  filename?: string,
  openInNewTab?: boolean,
) => {
  if (resourceUrl) {
    const resourceData = await fetch(resourceUrl);

    if (resourceData.ok) {
      if (resourceData.url) {
        if (openInNewTab) {
          window.open(resourceData.url, "_blank");
        }

        saveAs(await resourceData.blob(), filename);
      }
    } else {
      toast.error(
        intlAccessor.formatMessage({
          id: "generic.message.something-went-wrong",
          defaultMessage: "Something went wrong!",
        }),
      );
    }
  }
};
