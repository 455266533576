import { IProduct } from "types/product";
import { useBarcodes } from "util/barcode";

import { ImageCarousel } from "./image-carousel";
import { useProductImages } from "./use-product-images";

export { ImageCarousel } from "./image-carousel";

export function ProductImageCarousel({
  product,
  size = 120,
}: {
  product?: IProduct;
  size?: number;
}) {
  const productImages = useProductImages({ product, size });
  return <ImageCarousel images={productImages} size={size} />;
}

export function BarcodeCarousel({ barcodes }: { barcodes?: string[] }) {
  const barcodeImgProps = useBarcodes(barcodes, {
    Width: 120,
    Height: 120,
    alt: "img-barcode",
    NoMargin: true,
  });
  return (
    <ImageCarousel
      size={120}
      images={barcodeImgProps}
      altFallback="img-barcode"
      getImageText={(_, idx) => barcodes?.find((_, barcodeIdx) => barcodeIdx === idx)}
    />
  );
}
