import { useMemo } from "react";
import { useParams } from "react-router-dom";

import { isInteger } from "lodash";

/**
 * Get route param as number.
 * @param param string, defaults to `id`
 * @returns number value of the requested param if it exists or undefined, otherwise
 */
const useParsedRouteParam = (param?: string) => {
  const params = useParams<{ [key: string]: string }>();

  const parsedParam = useMemo(() => {
    const routeParam = param ? params[param] : params.id;

    // check if the text can be parsed
    if (routeParam && isInteger(parseInt(routeParam, 10))) {
      return parseInt(routeParam, 10);
    }

    return undefined;
  }, [param, params]);

  return parsedParam;
};

export default useParsedRouteParam;
