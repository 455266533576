import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { getUserTaskTypesQuery } from "models/user-tasks";

export const useUserTaskTypeById = (id?: number) => {
  const { data, isFetching: isLoading } = useQuery({
    ...getUserTaskTypesQuery({}),
    enabled: !!id,
  });

  const userTaskTypeById = useMemo(
    () => data?.Types?.find((userTaskType) => userTaskType.ID === id),
    [data?.Types, id],
  );

  return { data: userTaskTypeById, isLoading };
};
