import { ReactNode } from "react";

import {
  ListItemIcon,
  ListItemText as MuiListItemText,
  Menu,
  PopoverOrigin,
} from "@material-ui/core";

import { MenuItem } from "components/suite-ui/table/table-helper-components";

export interface IRowContextMenuItem<T> {
  id: string;
  itemData: T;
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  onContextItemClick: (rowData: T) => void;
}

export type TAnchorPosition = {
  anchorX: number;
  anchorY: number;
};

export interface IAnchorPositioning {
  type: "anchorPosition";
  anchorPosition: TAnchorPosition;
  anchorOrigin?: never;
  anchorReferenceElement?: never;
}

export interface IAnchorReferenceElement {
  type: "anchorEl";
  anchorPosition?: never;
  anchorOrigin?: PopoverOrigin;
  anchorReferenceElement: HTMLElement | null;
}

export interface IRowContextMenu<T> {
  open: boolean;
  onClose: () => void;
  items: IRowContextMenuItem<T>[];
  anchor: IAnchorPositioning | IAnchorReferenceElement;
}

const ContextMenu = <T = unknown,>({ anchor, items, onClose, open }: IRowContextMenu<T>) => (
  <Menu
    open={open}
    keepMounted
    elevation={1}
    onClose={onClose}
    getContentAnchorEl={null}
    anchorReference={anchor.type}
    anchorEl={anchor.anchorReferenceElement}
    anchorOrigin={
      anchor.anchorOrigin ?? {
        vertical: "bottom",
        horizontal: "left",
      }
    }
    anchorPosition={
      anchor.anchorPosition && anchor.type === "anchorPosition"
        ? { top: anchor.anchorPosition.anchorY, left: anchor.anchorPosition.anchorX }
        : undefined
    }
    PaperProps={{
      className:
        "shadow-[0_1px_1px_0_rgba(0,_0,_0,_0.14),_0_2px_1px_-1px_rgba(0,_0,_0,_0.12),0_1px_3px_0_rgba(0,_0,_0,_0.2)]",
    }}
    MenuListProps={{
      className: "p-0",
    }}
  >
    {items.map((item) => (
      <MenuItem
        key={item.id}
        className="w-full"
        disabled={item.disabled ?? false}
        onClick={() => {
          item.onContextItemClick(item.itemData);
          onClose();
        }}
      >
        {item.icon ? <ListItemIcon className="min-w-[30px]">{item.icon}</ListItemIcon> : null}
        <MuiListItemText primary={item.label} />
      </MenuItem>
    ))}
  </Menu>
);

export default ContextMenu;
