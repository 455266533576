import { CoreManagement } from "@springtree/eva-services-core-management";

import { getLoyaltyProgramHandlersQuery } from "models/loyalty-programs";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const { SingleSearchListField: LoyaltyProgramHandlerSearchListField } =
  SearchListFieldGenerator<CoreManagement.GetLoyaltyProgramHandlers, { Name: string }, string>({
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    getItemFromResponse: (resp) =>
      resp?.Handlers?.map((handler) => ({
        Name: handler,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.handler",
      defaultMessage: "Handler",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getLoyaltyProgramHandlersQuery,
      }),
  });
