import { useCallback, useRef } from "react";

import { useMonaco } from "@monaco-editor/react";
import Monaco from "monaco-editor";

import { ScriptEditorLanguage } from "types/monaco-editor-language";

import useRegisterScriptLanguageAutocomplete from "./use-register-script-language-autocomplete";
import { useRegisterScriptLanguageDocumentFormatter } from "./use-register-script-language-document-formatter";
import useRegisterScriptLanguageHover from "./use-register-script-language-hover";
import useRegisterScriptLanguageTokens from "./use-register-script-language-tokens";
import { useSetScriptLanguageConfiguration } from "./use-set-script-language-configuration";

const useConfigureScriptLanguage = (dialect: ScriptEditorLanguage, dialectKeywords: string[]) => {
  const monaco = useMonaco();

  const tokenProviderDisposableRef = useRef<Monaco.IDisposable>();
  const autocompleteProviderDisposableRef = useRef<Monaco.IDisposable>();
  const hoverProviderDisposableRef = useRef<Monaco.IDisposable>();
  const documentFormatterProviderDisposableRef = useRef<Monaco.IDisposable>();
  const setScriptLanguageConfigurationDisposableRef = useRef<Monaco.IDisposable>();

  const registerScriptLanguageTokens = useRegisterScriptLanguageTokens(dialect, dialectKeywords);
  const registerScriptLanguageAutocompleteProvider = useRegisterScriptLanguageAutocomplete(dialect);
  const registerScriptLanguageHoverProvider = useRegisterScriptLanguageHover(dialect);
  const registerScriptLanguageDocumentFormatter =
    useRegisterScriptLanguageDocumentFormatter(dialect);
  const setScriptLanguageConfiguration = useSetScriptLanguageConfiguration(dialect);

  const registerScriptLanguage = useCallback(() => {
    if (monaco) {
      // Register the Script language id
      monaco.languages.register({ id: dialect });

      // Setup configuration for the Script language
      setScriptLanguageConfigurationDisposableRef.current = setScriptLanguageConfiguration();

      // Register token provider and store disposable object for later cleanup
      tokenProviderDisposableRef.current = registerScriptLanguageTokens();

      // Register autocomplete provider and store disposable object for later cleanup
      autocompleteProviderDisposableRef.current = registerScriptLanguageAutocompleteProvider();

      // Register hover provider and store disposable object for later cleanup
      hoverProviderDisposableRef.current = registerScriptLanguageHoverProvider();

      // Register formatter provider and store disposable object for later cleanup
      documentFormatterProviderDisposableRef.current = registerScriptLanguageDocumentFormatter();
    }
  }, [
    dialect,
    monaco,
    registerScriptLanguageAutocompleteProvider,
    registerScriptLanguageDocumentFormatter,
    registerScriptLanguageHoverProvider,
    registerScriptLanguageTokens,
    setScriptLanguageConfiguration,
  ]);

  const disposeScriptLanguage = useCallback(() => {
    // Dispose all registered providers
    tokenProviderDisposableRef.current?.dispose();
    autocompleteProviderDisposableRef.current?.dispose();
    hoverProviderDisposableRef.current?.dispose();
    documentFormatterProviderDisposableRef.current?.dispose();
    setScriptLanguageConfigurationDisposableRef.current?.dispose();
  }, []);

  return {
    registerScriptLanguage,
    disposeScriptLanguage,
  };
};

export default useConfigureScriptLanguage;
