import { useCallback } from "react";

import { useAppSetting } from "util/application-configuration";

const useApplicationConfigurationAddressSettings = () => {
  const houseNumberVisibleCountries = useAppSetting(
    "Addresses:HouseNumberVisibleCountries",
    [],
  ) as string[];

  const houseNumberRequiredCountries = useAppSetting(
    "Addresses:HouseNumberRequiredCountries",
    [],
  ) as string[];

  const address2VisibleCountries = useAppSetting(
    "Addresses:Address2VisibleCountries",
    [],
  ) as string[];

  const address1OptionalCountries = useAppSetting(
    "Addresses:Address1OptionalCountries",
  ) as string[];

  const zipCodeOptionalCountries = useAppSetting(
    "Addresses:ZipCodeOptionalCountries",
    [],
  ) as string[];

  const cityOptionalCountries = useAppSetting("Addresses:CityOptionalCountries", []) as string[];

  const stateVisibleCountries = useAppSetting("Addresses:StateVisibleCountries", []) as string[];

  const stateLength2Countries = useAppSetting("Addresses:StateLength2Countries", []) as string[];

  const stateLength3Countries = useAppSetting("Addresses:StateLength3Countries", []) as string[];

  const getAddressSettings = useCallback(
    (countryId: string) => ({
      cityOptional: cityOptionalCountries?.includes(countryId),
      stateVisible: stateVisibleCountries?.includes(countryId),
      stateLength2: stateLength2Countries?.includes(countryId),
      stateLength3: stateLength3Countries?.includes(countryId),
      address2Visible: address2VisibleCountries?.includes(countryId),
      zipCodeOptional: zipCodeOptionalCountries?.includes(countryId),
      address1Optional: address1OptionalCountries?.includes(countryId),
      houseNumberVisible: houseNumberVisibleCountries?.includes(countryId),
      houseNumberRequired: houseNumberRequiredCountries?.includes(countryId),
    }),
    [
      cityOptionalCountries,
      stateLength2Countries,
      stateLength3Countries,
      stateVisibleCountries,
      address2VisibleCountries,
      zipCodeOptionalCountries,
      address1OptionalCountries,
      houseNumberVisibleCountries,
      houseNumberRequiredCountries,
    ],
  );

  return getAddressSettings;
};

export default useApplicationConfigurationAddressSettings;
