import { CoreManagement } from "@springtree/eva-services-core-management";
import { getAppSettingsAutocompleteInfoServiceQuery } from "models/app-settings";
import { FIVE_MINUTES } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateAppSettingSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetAppSettingsAutocompleteInfo,
    {
      Name: string;
    },
    string
  >({
    getLabel: (item) => item.Name,
    getItemId: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    defaultLabel: intlAccessor.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
    getItemFromResponse: (response) => response?.Settings,
    useItemByID: (id, items) => {
      const item = items?.find((appSetting) => appSetting.Name === id);
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, items) => {
      const data = items?.filter((item) => ids?.includes(item.Name));
      return { data, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getAppSettingsAutocompleteInfoServiceQuery,
        staleTime: FIVE_MINUTES,
        initialRequest: {},
      }),
  });
