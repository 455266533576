import { builders } from "@springtree/eva-sdk-react-recoil";
import { PIM } from "@springtree/eva-services-pim";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "../../utils/types";

const searchProductPropertyTypesDefaultRequest = {
  PageConfig: {
    Limit: ALL_ROWS_LIMIT,
  },
};

const searchProductPropertyTypesServiceState = builders.buildServiceState({
  service: PIM.SearchProductPropertyTypes,
  key: "Composite:SearchProductPropertyTypes",
  defaultRequest: searchProductPropertyTypesDefaultRequest,
  requireEmployee: true,
});

const searchProductPropertyTypesResultSelector = selector({
  key: "Composite:SearchProductPropertyTypes:Result",
  get: ({ get }) => get(searchProductPropertyTypesServiceState.response)?.Result?.Page,
});

export const SearchProductPropertyTypesService = {
  serviceState: searchProductPropertyTypesServiceState,
  result: searchProductPropertyTypesResultSelector,
  defaultRequest: searchProductPropertyTypesDefaultRequest,
};
