import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { singleCustomFieldByIDState, singleCustomFieldValuesSelector } from "./state";

const useCustomFieldByID = (ID?: number) => {
  const values = hooks.useGetState(singleCustomFieldValuesSelector(ID));

  const isLoading = hooks.useIsLoading({ state: singleCustomFieldByIDState.response(ID) });
  const isLoadingWithoutPreviousResponse = useMemo(() => !values && isLoading, [isLoading, values]);

  const refetchCustomFieldByID = hooks.useSetStale({
    staleState: singleCustomFieldByIDState.stale(ID),
  });

  return {
    values,
    refetchCustomFieldByID,
    isLoading,
    isLoadingWithoutPreviousResponse,
  };
};

useCustomFieldByID.service = {
  values: singleCustomFieldValuesSelector,
  serviceState: singleCustomFieldByIDState,
};

export default useCustomFieldByID;
