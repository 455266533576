import { CoreManagement } from "@springtree/eva-services-core-management";

import { intlAccessor } from "util/intl-accessor";
import { mutate } from "util/mutate";

export const mutateUpdatePointAwardingRuleCondition = () =>
  mutate({
    service: CoreManagement.UpdatePointAwardingRule,
    messages: {
      loadingMessage: intlAccessor.formatMessage({
        id: "generic.label.save-condition.loading",
        defaultMessage: "Saving condition...",
      }),
      successMessage: intlAccessor.formatMessage({
        id: "generic.label.save-condition.success",
        defaultMessage: "Condition saved successfully.",
      }),
    },
  });
