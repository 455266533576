import { ReactNode, useEffect } from "react";

export const SearchListFieldPortalWrapper = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  // Hide overflow on the page as the dialog is opened. Restore it on unmount.
  useEffect(() => {
    const previousHTMLElementOverflow = document.documentElement.style.overflow;
    const previousHTMLElementPaddingRight = document.documentElement.style.paddingRight;

    document.documentElement.style.overflow = "hidden";
    document.documentElement.style.paddingRight = "0px";

    return () => {
      document.documentElement.style.overflow = previousHTMLElementOverflow;
      document.documentElement.style.paddingRight = previousHTMLElementPaddingRight;
    };
  }, []);

  return <div className={className}>{children}</div>;
};
