import * as React from "react";
import { useIntl } from "react-intl";
import { FixedSizeList, ListChildComponentProps } from "react-window";

import {
  InputAdornment,
  InputAdornmentProps,
  ListItem,
  ListItemProps,
  ListItemText,
  OutlinedInputProps,
  Paper,
  Popper,
  TextField,
  TextFieldProps,
} from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "classnames";
import DownShift, {
  Callback,
  GetToggleButtonPropsOptions,
  StateChangeFunction,
  StateChangeOptions,
} from "downshift";
import { isArray, isEmpty, isEqual } from "lodash";
import matchSorter from "match-sorter";
import { v4 as uuid } from "uuid";

import useMeasure from "hooks/use-measure";

import Chip from "../chip";
import IconButton from "../icon-button";
import LinkButton from "../link-button";

import stateReducer from "./state-reducer";
import { ArrowDropDown } from "@material-ui/icons";

export { Autocomplete as MaterialAutocomplete } from "./material-autocomplete";
export type {
  IAutocompleteBaseProps as IMaterialAutocompleteBaseProps,
  IMultiAutocompleteProps as IMaterialMultiAutocompleteProps,
  ISingleAutocompleteProps as IMaterialSingleAutocompleteProps,
} from "./material-autocomplete";

export interface IMenuCard {
  position?: "absolute" | "relative";
  card?: "normal" | "inner";
}

export interface AutocompleteProps {
  /** Array of items */
  items: any[];
  /** Label of textfield */
  label?: string;
  /** Propegates the selected item up */
  handleSelectedItem?: (selectedItem: any) => void;
  /** Propegates the selected items up */
  handleSelectedItems?: (selectedItem: any[]) => void;
  /** Propegates the inputchange event up */
  handleInputChange?: (input?: string) => void;
  /** Keys of item you want to search by */
  matchKeys: string[];
  /** Key of item which displays the name of the item */
  renderOptionValueKey: string;
  /** Key of item which you possibly want to display under the name of the item in the menu. */
  renderOptionsSecondaryValueKey?: string;
  /** Key of item which will operate as ID */
  optionIDKey: string;
  /** Input value set in the parent component */
  controlledInputValue?: string;
  /** Selected item set in the parent component */
  controlledSelectedItem?: any;
  /** Make text field required */
  required?: boolean;
  /** Make text field passive */
  passive?: boolean;
  /** Make text field disabled */
  disabled?: boolean;
  /** Helper text under field */
  helperText?: string;
  /** Error state */
  error?: boolean;
  /** Free Options, add custom inputs to the field value by using the enter button */
  freeOptions?: boolean;
  /** Delete item handler */
  handleDeleteItem?: (id: string) => void;
  /** Text input props */
  textInputProps?: TextFieldProps;
  /** Property that sets the input to full-width */
  fullWidth?: boolean;
  /** Property for custom width of input */
  width?: React.CSSProperties["width"];
  /** Focus event when input field gets focus */
  onFocus?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /** Blur event when input field gets blurred */
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  /** Placeholder text */
  placeholder?: string;
  /** If `true`, value must be an array and the menu will support multiple selections. */
  multi?: boolean;
  /** If multi is `true` you must add a function to this prop that removes all values in the array. */
  clearAllItems?: () => void;
  /** If multi is `true` it is possible to change the render of the selected items */
  renderTag?: (item: any) => React.ReactNode;
  /** If it supports comma separated values. Should be used together with multi and freeOptions. */
  csv?: boolean;
  /** renders a smaller input */
  small?: boolean;
  /** Maximum number of selected items shown at once. Useful when expecting the user to add a large number of free options */
  maximumDisplayedSelectedItems?: number;
  /**
   * Callback fired when clicking the `Show more` button that appears when not all selected items are displayed when using
   * the `maximumDisplayedSelectedItems` prop
   */
  onShowMore?: () => void;
  /** Label of the show more button. Defaults to `Show more` */
  showMoreText?: string;
  /** If `true`, the dropdown button is hidden */
  hideDropDownButton?: boolean;
  /** If `true`, the clear button is hidden */
  hideClearButton?: boolean;
  /** Custom component to render as part of the right adornment */
  endAdornment?: React.ReactNode;
}

export const Autocomplete = ({
  clearAllItems,
  controlledInputValue,
  controlledSelectedItem,
  csv,
  disabled,
  endAdornment,
  error,
  freeOptions,
  fullWidth,
  handleDeleteItem,
  handleInputChange,
  handleSelectedItem,
  handleSelectedItems,
  helperText,
  hideClearButton = false,
  hideDropDownButton = false,
  items,
  label,
  matchKeys,
  maximumDisplayedSelectedItems,
  multi,
  onBlur,
  onFocus,
  onShowMore,
  optionIDKey,
  passive,
  placeholder,
  renderOptionsSecondaryValueKey,
  renderOptionValueKey,
  renderTag,
  required,
  showMoreText,
  small,
  textInputProps,
  width,
}: AutocompleteProps) => {
  const intl = useIntl();
  const getItems = React.useCallback(
    (value: string | null) => (value ? matchSorter(items, value, { keys: matchKeys }) : items),
    [items, matchKeys],
  );
  const SHOWN_ITEMS_IN_LIST = 5;
  const ITEM_HEIGHT_IN_LIST = 46;
  const RETURN_KEYCODE = 13;
  const COMMA_KEYCODE = 188;

  const rootElementRef = React.useRef<HTMLDivElement | null>(null);

  // Render value as title of item in input
  const itemToString = (item: any) => (item ? item[renderOptionValueKey] : "");

  const localHandleItems = React.useCallback(
    (localItems: any) => {
      if (isArray(localItems)) {
        if (handleSelectedItems) {
          handleSelectedItems(localItems);
        } else if (handleSelectedItem) {
          localItems.forEach((item) => handleSelectedItem(item));
        }
      } else if (handleSelectedItems) {
        handleSelectedItems([localItems]);
      } else if (handleSelectedItem) {
        handleSelectedItem(localItems);
      }
    },
    [handleSelectedItem, handleSelectedItems],
  );

  const handleFreeOptionsSelectItems = React.useCallback(
    (
      inputValue: string,
      setState: (
        stateToSet: Partial<StateChangeOptions<any>> | StateChangeFunction<any>,
        cb?: Callback | undefined,
      ) => void,
    ) => {
      if (csv) {
        const splitValues = inputValue
          ? inputValue
              .split(",")
              // remove zero width characters
              .map((x) => x.replace(/[\u200B-\u200D\uFEFF]/g, ""))
              .filter((x) => x.length > 0)
              .map((x) => x.trimLeft().trimRight())
          : [];

        const mappedItems = splitValues.map((splitValue) => ({
          [renderOptionValueKey]: splitValue,
          [optionIDKey]: uuid(),
        }));

        localHandleItems(mappedItems);
      } else {
        const mappedItem = {
          [renderOptionValueKey]: inputValue,
          [optionIDKey]: uuid(),
        };

        localHandleItems(mappedItem);
      }

      setState((state) => ({
        ...state,
        isOpen: false,
        inputValue: "",
      }));
    },
    [csv, localHandleItems, renderOptionValueKey, optionIDKey],
  );

  // Render List item in menu
  const renderListItem = (props: ListChildComponentProps) => {
    const { data, index, style } = props;

    const { getItemProps, highlightedIndex, items: listItems, selectedItem } = data;

    const item = listItems[index];

    return (
      <AutocompleteListItem
        button
        style={style}
        isActive={highlightedIndex === index}
        isSelected={
          multi && selectedItem
            ? selectedItem.some((selected: any) => isEqual(selected, item))
            : selectedItem === item
        }
        {...getItemProps({
          item,
          key: item[optionIDKey],
          style,
        })}
      >
        <ListItemText
          primary={item[renderOptionValueKey]}
          secondary={renderOptionsSecondaryValueKey && item[renderOptionsSecondaryValueKey]}
        />
      </AutocompleteListItem>
    );
  };

  const renderSelectedItem = React.useCallback(
    (item: any) => {
      if (renderTag) {
        return <div>{renderTag(item)}</div>;
      }

      if (freeOptions) {
        return (
          <div className="my-[3px] mr-1.5">
            <Chip
              onDelete={
                handleDeleteItem && !passive && !disabled
                  ? () => {
                      const itemToDeleteKey = item[optionIDKey];
                      handleDeleteItem(itemToDeleteKey);
                    }
                  : undefined
              }
            >
              {item[renderOptionValueKey]}
            </Chip>
          </div>
        );
      }
      return (
        <div className="mr-1">
          {item[renderOptionValueKey]}
          {", "}
        </div>
      );
    },
    [
      renderTag,
      freeOptions,
      renderOptionValueKey,
      handleDeleteItem,
      passive,
      disabled,
      optionIDKey,
    ],
  );

  const renderStartAdornment = React.useCallback(
    (selectedItems: any[]) => {
      if (selectedItems) {
        if (onShowMore && maximumDisplayedSelectedItems) {
          const displayedItems = selectedItems.slice(0, maximumDisplayedSelectedItems);
          return (
            <>
              {displayedItems.map((item) => (
                <React.Fragment key={item[optionIDKey]}>{renderSelectedItem(item)}</React.Fragment>
              ))}
              {selectedItems.length > maximumDisplayedSelectedItems ? (
                <LinkButton onClick={onShowMore}>{showMoreText || "Show more"}</LinkButton>
              ) : null}
            </>
          );
        }
        return selectedItems.map((item) => (
          <React.Fragment key={item[optionIDKey]}>{renderSelectedItem(item)}</React.Fragment>
        ));
      }
      return undefined;
    },
    [maximumDisplayedSelectedItems, onShowMore, renderSelectedItem, optionIDKey, showMoreText],
  );

  const renderEndAdornment = React.useCallback(
    (
      selectedItem: any[],
      setState: (
        stateToSet: Partial<StateChangeOptions<any>> | StateChangeFunction<any>,
        cb?: Callback | undefined,
      ) => void,
      clearSelection: (cb?: Callback) => void,
      getToggleButtonProps: (options?: GetToggleButtonPropsOptions | undefined) => any,
      inputValue: string | null,
    ) => {
      const renderAddButton = () => {
        if (multi && freeOptions && inputValue?.length) {
          return (
            <IconButton
              onClick={() => {
                handleFreeOptionsSelectItems(inputValue, setState);
              }}
              disabled={textInputProps?.disabled}
              aria-label="add free option"
              size="small"
              label={intl.formatMessage({
                id: "generic.label.add-value",
                defaultMessage: "Add value",
              })}
            >
              <SvgIcon name="plus" className="h-5 w-5" />
            </IconButton>
          );
        }
        return null;
      };

      const renderClearButton = () => {
        if (
          ((!multi && selectedItem) || (multi && selectedItem && selectedItem.length)) &&
          !hideClearButton
        ) {
          return (
            <IconButton
              onClick={() => {
                if (multi) {
                  if (clearAllItems) {
                    clearAllItems();
                  }
                } else {
                  clearSelection();
                  if (handleInputChange) {
                    handleInputChange(undefined);
                  }
                }
              }}
              disabled={textInputProps?.disabled}
              aria-label="toggle menu"
              size="small"
              label={
                clearAllItems
                  ? intl.formatMessage({
                      id: "generic.label.clear-all",
                      defaultMessage: "Clear all",
                    })
                  : intl.formatMessage({ id: "generic.label.clear", defaultMessage: "Clear" })
              }
            >
              <SvgIcon name="alert-error" className="h-5 w-5" />
            </IconButton>
          );
        }
        return null;
      };

      const renderMenuButton = () => {
        if (items?.length && !hideDropDownButton) {
          return (
            <IconButton
              {...getToggleButtonProps()}
              disabled={textInputProps?.disabled}
              aria-label="toggle menu"
              size="small"
              edge="end"
              label={intl.formatMessage({
                id: "generic.label.open-menu",
                defaultMessage: "Open menu",
              })}
            >
              <ArrowDropDown />
            </IconButton>
          );
        }
        return null;
      };

      return (
        <>
          {endAdornment ? endAdornment : null}
          {!passive && renderAddButton()}
          {!passive && renderClearButton()}
          {!passive && renderMenuButton()}
        </>
      );
    },
    [
      endAdornment,
      passive,
      multi,
      freeOptions,
      textInputProps?.disabled,
      intl,
      handleFreeOptionsSelectItems,
      hideClearButton,
      clearAllItems,
      handleInputChange,
      items?.length,
      hideDropDownButton,
    ],
  );

  const { width: inputWidth } = useMeasure<HTMLDivElement>(rootElementRef);

  return (
    <div ref={rootElementRef}>
      <DownShift
        itemToString={itemToString}
        inputValue={controlledInputValue}
        selectedItem={controlledSelectedItem}
        onChange={(selectedItem) => localHandleItems(selectedItem)}
        stateReducer={multi ? stateReducer : undefined}
        onInputValueChange={(inputValue) => handleInputChange && handleInputChange(inputValue)}
        defaultHighlightedIndex={!freeOptions ? 0 : undefined}
      >
        {({
          clearSelection,
          closeMenu,
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          getToggleButtonProps,
          highlightedIndex,
          inputValue,
          isOpen,
          openMenu,
          selectedItem,
          setState,
        }) => (
          <div>
            <StyledTextField
              {...getInputProps()}
              value={
                getInputProps().value || (passive && !controlledSelectedItem?.length ? "-" : "")
              }
              fullWidth={fullWidth}
              small={small ?? false}
              required={required}
              error={error}
              helperText={helperText}
              label={label}
              passive={passive}
              disabled={disabled}
              InputLabelProps={{
                ...getLabelProps(),
                style: {
                  top: (renderTag || freeOptions) && selectedItem.length > 0 ? "-5px" : undefined,
                },
              }}
              placeholder={!passive ? placeholder : undefined}
              onFocus={(event: any) => {
                if (onFocus) {
                  onFocus(event);
                }
                if (!passive) {
                  openMenu();
                }
              }}
              onBlur={onBlur}
              width={width}
              onKeyDownCapture={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (
                  multi &&
                  (!inputValue || inputValue === "") &&
                  e.key === "Backspace" &&
                  handleDeleteItem &&
                  selectedItem?.length > 0
                ) {
                  handleDeleteItem(selectedItem[selectedItem.length - 1][optionIDKey]);
                }
              }}
              onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
                if (
                  freeOptions &&
                  inputValue?.length &&
                  (e.keyCode === RETURN_KEYCODE || (csv && e.keyCode === COMMA_KEYCODE))
                ) {
                  handleFreeOptionsSelectItems(inputValue, setState);
                }
              }}
              onPaste={(e: any) => {
                if (freeOptions) {
                  e.preventDefault();

                  if (e.clipboardData) {
                    const text = e.clipboardData.getData("Text");
                    if (text) {
                      handleFreeOptionsSelectItems(text, setState);
                    }
                  }
                }
              }}
              inputProps={{
                style: {
                  flexGrow: 1,
                  minWidth: "100px",
                  width: 0,
                  paddingBottom: multi ? "6px" : undefined,
                },
              }}
              InputProps={
                {
                  readOnly: passive,
                  disableUnderline: passive,
                  startAdornment: multi ? renderStartAdornment(selectedItem) : undefined,
                  endAdornment:
                    !disabled || !!endAdornment ? (
                      <EndAdornment position="end">
                        {renderEndAdornment(
                          selectedItem,
                          setState,
                          clearSelection,
                          getToggleButtonProps,
                          inputValue,
                        )}
                      </EndAdornment>
                    ) : null,
                  // the input value is reset when using multiple selection + chips, resulting in a required input element with an empty value even if we have options selected
                  // this makes sure that the required prop on the text field works based on the autocomplete value (controlledSelectedItem) instead of the input value
                  // meaning that we don't trigger the browser validation when there are chips indicating the selection
                  required: isEmpty(controlledSelectedItem) && required,
                } as OutlinedInputProps
              }
              {...textInputProps}
            />
            {isOpen && (
              <Popper
                open
                onBlur={() => closeMenu()}
                anchorEl={rootElementRef.current}
                placement="bottom-start"
                style={{
                  zIndex: 1301,
                  width: inputWidth ?? "100px",
                }}
              >
                <Paper {...getMenuProps(undefined, { suppressRefError: true })}>
                  <FixedSizeList
                    height={
                      getItems(inputValue).length < SHOWN_ITEMS_IN_LIST
                        ? getItems(inputValue).length * ITEM_HEIGHT_IN_LIST
                        : SHOWN_ITEMS_IN_LIST * ITEM_HEIGHT_IN_LIST
                    }
                    width="100%"
                    itemSize={ITEM_HEIGHT_IN_LIST}
                    itemCount={getItems(inputValue).length}
                    itemData={{
                      items: getItems(inputValue),
                      getItemProps,
                      highlightedIndex,
                      selectedItem,
                    }}
                  >
                    {renderListItem}
                  </FixedSizeList>
                </Paper>
              </Popper>
            )}
          </div>
        )}
      </DownShift>
    </div>
  );
};

type StyledTextFieldProps = TextFieldProps & {
  small: boolean;
  passive?: boolean;
  required?: boolean;
  width?: React.CSSProperties["width"];
};

const textFieldClassName = cva(
  "[&_.MuiInput-root]:relative [&_.MuiInput-root]:flex-wrap [&_.MuiInput-root]:!pr-[65px]",
  {
    variants: {
      passive: {
        true: "[&_.MuiInputLabel-root.Mui-focused]:text-[#666666]",
      },
      required: {
        true: "[&_label]:text-[#666666] [&_label]:after:inline-block [&_label]:after:whitespace-pre [&_label]:after:content-['_*']",
      },
      small: {
        true: [
          "[&_input]:pb-1",
          "[&_.MuiSelect-root]:pt-[3px] [&_.MuiSelect-root]:pb-1",
          "[&_.MuiFormControl-root]:my-0 ",
          "[&_label_+_.MuiInput-formControl]:mt-0",
          "[&_.MuiInput-root]:min-h-[32px] [&_.MuiInput-root]:pl-3.5 [&_.MuiInput-root]:text-legacy-sm",
          "[&_.MuiInputLabel-marginDense]:translate-x-0 [&_.MuiInputLabel-marginDense]:translate-y-[4px]",
          "[&_.MuiInputLabel-shrink]:translate-x-[0px] [&_.MuiInputLabel-shrink]:translate-y-[-8px] [&_.MuiInputLabel-shrink]:scale-[.8]",
          "[&_.MuiInputLabel-formControl]:translate-x-0 [&_.MuiInputLabel-formControl]:translate-y-[4px] [&_.MuiInputLabel-formControl]:text-legacy-sm",
        ],
        false:
          "[&_.MuiInputLabel-shrink]:translate-x-[4px] [&_.MuiInputLabel-shrink]:translate-y-[7px] [&_.MuiInputLabel-shrink]:scale-75",
      },
    },
  },
);

export const autocompleteListItemClassName = cva(
  [
    "hover:bg-[color:var(--legacy-eva-color-light-2)]",
    "[&_.MuiListItemText-root]:pl-4 [&_.MuiListItemText-root]:pr-4",
    "[&_.MuiListItemText-primary]:text-ellipsis [&_.MuiListItemText-secondary]:text-ellipsis",
    "[&_.MuiListItemText-primary]:overflow-hidden [&_.MuiListItemText-secondary]:overflow-hidden",
    "[&_.MuiListItemText-secondary]:whitespace-nowrap [&_.MuiListItemText-primary]:whitespace-nowrap",
  ],
  {
    variants: {
      isActive: {
        true: "bg-[color:var(--legacy-eva-color-light-2)]",
      },
      isSelected: {
        true: "bg-[color:var(--legacy-eva-color-light-3)] hover:bg-[color:#dedede] [&_.MuiTypography-body1]:font-medium",
        false: "[&_.MuiTypography-body1]:font-regular",
      },
    },
    compoundVariants: [
      {
        isActive: true,
        isSelected: true,
        class: "bg-[color:#dedede]",
      },
    ],
  },
);

const StyledTextField = ({
  children,
  className,
  passive,
  required,
  small,
  width,
  ...props
}: StyledTextFieldProps) => (
  <TextField
    {...props}
    className={classNames(textFieldClassName({ small, passive, required }), className)}
    style={{ width: width }}
  >
    {children}
  </TextField>
);

interface AutocompleteListItemProps extends ListItemProps<"div"> {
  isActive: boolean;
  isSelected: boolean;
}

export const AutocompleteListItem = ({
  children,
  className,
  isActive,
  isSelected,
  ...props
}: AutocompleteListItemProps) => (
  <ListItem
    {...props}
    className={classNames(autocompleteListItemClassName({ isActive, isSelected }), className)}
    button
  >
    {children}
  </ListItem>
);

const EndAdornment = ({ className, position, ...props }: InputAdornmentProps) => (
  <InputAdornment
    {...props}
    position={position}
    className={classNames(position === "end" && "absolute bottom-[22px] right-0", className)}
  />
);

Autocomplete.defaultProps = {
  fullWidth: true,
};
