// Flagged enum EVA.Core.DiscountDeactivationReasons
export enum DiscountDeactivationReason {
  None = 0,
  Unverified = 1,
  DisabledByUser = 2,
  ByDiscountUsage = 4,
  ByDiscountBudget = 8,
  ByDiscountTimeFrame = 16,
  ByDiscountCampaignBudget = 32,
  ByDiscountCampaignTimeFrame = 64,
}
