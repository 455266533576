import { DEFAULT_LOCALE } from "./base-values";

/**
 * Construct locale string from country and language IDs. If at least one of them is missing, it falls back to `DEFAULT_LOCALE` base value.
 * @param countryID string | undefined
 * @param languageID string | undefined
 * @returns string
 */
export const getLocale = (languageID?: string, countryID?: string) =>
  countryID && languageID ? `${languageID}-${countryID}` : DEFAULT_LOCALE;
