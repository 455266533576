import { useCallback } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import ky from "ky";

/**
 * Hook the returns an error handler for service error.
 * Pass the error to add a notification with the service error message
 * Check out the [docs](https://eva-suite-docs.netlify.app/error-handling).
 */
export const useHandleErrorResponse = () => {
  const intl = useIntl();

  const handleError = useCallback(
    async (e: Error, serviceName: string) => {
      if (e.name === "HTTPError") {
        const httpError = e as ky.HTTPError;
        const error = await httpError.response.clone().json();
        console.error(`[Error: ${serviceName}]:`, error);
        toast.error(error.Error.Message);
      } else {
        console.error(`[Error: ${serviceName}]:`, e);
        toast.error(
          intl.formatMessage({
            id: "generic.message.something-went-wrong",
            defaultMessage: "Something went wrong",
          }),
        );
      }
    },
    [intl],
  );

  return handleError;
};
