import { useCallback, useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { z } from "zod";

import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { checkEmailAddressAvailabilityQuery } from "models/users";

const emailAddressSchema = z.string().email();

/**
 * Check if an email address is available in EVA. It will trigger a request to
 * `CheckEmailAddressAvailability` service if the provided value is a valid email address.
 * @param value `string | undefined`
 * @param onAfterValidation `((isValid?: boolean, emailAddress?: string) => void) | undefined`
 * @param validationException `string | string[] | undefined`
 * @param asEmployee `boolean | undefined`
 */
const useCheckEmailAddressAvailability = (
  value?: string,
  onAfterValidation?: (isValid?: boolean, emailAddress?: string) => void,
  validationException?: string | string[],
  asEmployee?: boolean,
) => {
  const debouncedValue = useDebounce(value, 500);

  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(true);

  // Check if local value is included an exception
  const isValidationException = useMemo(
    () =>
      !!validationException &&
      (!debouncedValue ||
        (typeof validationException === "string"
          ? validationException === debouncedValue
          : validationException?.includes(debouncedValue))),
    [debouncedValue, validationException],
  );

  // Check if we have a valid email address and its value is not an exception
  const shouldValidate = useMemo(
    () => !isValidationException && emailAddressSchema.safeParse(debouncedValue).success,
    [debouncedValue, isValidationException],
  );

  const onSuccess = useCallback(
    (response?: EVA.Core.CheckUsernameAvailabilityResponse) => {
      // If the service throws an error, set the email address as valid.
      // This could prevent not being able to submit the form if `CheckEmailAddressAvailability`
      // service fails
      if (response?.Error) {
        setIsAvailable(true);

        onAfterValidation?.(true, debouncedValue);
      } else {
        setIsAvailable(response?.IsAvailable);

        onAfterValidation?.(response?.IsAvailable, debouncedValue);
      }
    },
    [debouncedValue, onAfterValidation],
  );

  const { isFetching: isCheckEmailAddressLoading } = useQuery({
    ...checkEmailAddressAvailabilityQuery(
      debouncedValue ? { EmailAddress: debouncedValue, AsEmployee: asEmployee } : undefined,
      [],
    ),
    enabled: !!shouldValidate,
    onSuccess,
    // If the service throws an error, set the email address as valid.
    // This could prevent not being able to submit the form if `CheckEmailAddressAvailability`
    // service fails
    onError: () => {
      setIsAvailable(true);

      onAfterValidation?.(true);
    },
  });

  // Since the query is disabled when the value is an exception, we need to handle this separately
  useEffect(() => {
    if (isValidationException) {
      setIsAvailable(true);
      onAfterValidation?.(true, debouncedValue);
    }
  }, [debouncedValue, isValidationException, onAfterValidation]);

  return {
    isAvailable,
    setIsAvailable,
    shouldValidate,
    isLoading: isCheckEmailAddressLoading,
  };
};

export default useCheckEmailAddressAvailability;
