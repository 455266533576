import { atom, selector, selectorFamily } from "recoil";

import {
  IProductSetProduct,
  PRODUCT_SET_MODAL_AVAILABLE_ITEMS_HEADER_HEIGHT,
  PRODUCT_SET_MODAL_HEIGHT,
  PRODUCT_SET_MODAL_TITLE_HEIGHT,
} from "./types";

export const ProductSetIncludedProductsAtom = atom<IProductSetProduct[]>({
  key: "Composite:ProductSetModal:IncludedProducts",
  default: [],
});

export const ProductSetAvailableProductsAtom = atom<IProductSetProduct[]>({
  key: "Composite:ProductSetModal:AvailableProducts",
  default: [],
});

export const IsProductIncludedInSetSelector = selectorFamily<boolean, number>({
  key: "Composite:ProductSetModal:IsProductIncludedInSet",
  get:
    (productID: number) =>
    ({ get }) =>
      get(ProductSetIncludedProductsAtom).some(
        (selectedProduct) => selectedProduct.ID === productID,
      ),
});

export const ProductSetSelectedAvailableProductsAtom = atom<IProductSetProduct[]>({
  key: "Composite:ProductSetModal:SelectedAvailableProducts",
  default: [],
});

export const IsProductSelectedSelector = selectorFamily<boolean, number>({
  key: "Composite:ProductSetModal:IsProductSelected",
  get:
    (productID: number) =>
    ({ get }) =>
      get(ProductSetSelectedAvailableProductsAtom).some(
        (selectedProduct) => selectedProduct.ID === productID,
      ),
});

export const productSetModalFiltersHeightAtom = atom<number>({
  key: "Composite:ProductSetModal:FiltersHeight",
  default: 0,
});

export const productSetModalAvailableItemsListHeightSelector = selector<number>({
  key: "Composite:ProductSetModal:AvailableItemsListHeight",
  get: ({ get }) =>
    PRODUCT_SET_MODAL_HEIGHT -
    PRODUCT_SET_MODAL_TITLE_HEIGHT -
    PRODUCT_SET_MODAL_AVAILABLE_ITEMS_HEADER_HEIGHT -
    get(productSetModalFiltersHeightAtom),
});
