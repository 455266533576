import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { listOrganizationUnitSetsQuery } from "models/organization-unit-sets";

export const useOrganizationUnitSetDetailsById = (id?: number) => {
  const { data, isFetching: isLoading } = useQuery({
    ...listOrganizationUnitSetsQuery(
      id
        ? {
            PageConfig: {
              Limit: 1,
              Filter: {
                ID: id,
              },
            },
          }
        : undefined,
    ),
    enabled: !!id,
    refetchOnWindowFocus: false,
    staleTime: 1000,
  });

  const organizationUnitSet = useMemo(() => {
    if (!data || data.Result?.Total !== 1) {
      return undefined;
    }

    const ouSetsList = data?.Result?.Page;
    if (ouSetsList?.length !== 1) {
      return undefined;
    }
    const ou = ouSetsList?.[0];

    return ou;
  }, [data]);

  return { item: organizationUnitSet, isLoading };
};
