import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { organizationUnitDetailsFamilyState } from ".";

const useOrganizationUnitDetailed = (id?: number) => {
  const response = hooks.useGetState(organizationUnitDetailsFamilyState.response(id));

  const isLoading = hooks.useIsLoading({
    state: organizationUnitDetailsFamilyState.response(id),
  });

  const isLoadingWithoutResponse = useMemo(() => {
    return isLoading && !response;
  }, [isLoading, response]);

  const refetch = hooks.useSetStale({
    staleState: organizationUnitDetailsFamilyState.stale(id),
  });

  return {
    response,
    isLoading,
    isLoadingWithoutResponse,
    refetch,
  };
};

export default useOrganizationUnitDetailed;
