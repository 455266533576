import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import {
  CheckboxIcon,
  DateIcon,
  DecimalIcon,
  LineIcon,
  MultiLineIcon,
  NumberIcon,
  RadioButtonIcon,
  TimeIcon,
  ToggleIcon,
} from "assets/icons/components/custom-field-icons";

import { DataTypeProps, DataTypes } from "../../types";

export const useDataTypeSelectorMenuItems = () => {
  const intl = useIntl();
  const lineOrNumberMenuItems = useMemo<DataTypeProps[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.line",
          defaultMessage: "Line",
        }),
        value: "line",
        icon: <LineIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.multiline",
          defaultMessage: "Multi line",
        }),
        value: "multiline",
        icon: <MultiLineIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.number",
          defaultMessage: "Number",
        }),
        value: "number",
        icon: <NumberIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.decimal",
          defaultMessage: "Decimal",
        }),
        value: "decimal",
        icon: <DecimalIcon fontSize="inherit" />,
      },
    ],
    [intl],
  );

  const boolOrMultiOptionMenuItems = useMemo<DataTypeProps[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.radio-buttons",
          defaultMessage: "Radio buttons",
        }),
        value: "radiobuttons",
        icon: <RadioButtonIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.checkboxes",
          defaultMessage: "Checkboxes",
        }),
        value: "checkboxes",
        icon: <CheckboxIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.toggle",
          defaultMessage: "Toggle",
        }),
        value: "toggle",
        icon: <ToggleIcon fontSize="inherit" />,
      },
    ],
    [intl],
  );

  const dateMenuItems = useMemo<DataTypeProps[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.date",
          defaultMessage: "Date",
        }),
        value: "date",
        icon: <DateIcon fontSize="inherit" />,
      },
      {
        label: intl.formatMessage({
          id: "custom-field.input.data-type.options.date-time",
          defaultMessage: "Time",
        }),
        value: "datetime",
        icon: <TimeIcon fontSize="inherit" />,
      },
    ],
    [intl],
  );

  return {
    lineOrNumberMenuItems,
    boolOrMultiOptionMenuItems,
    dateMenuItems,
  };
};

export const useDatatypeResolvers = (isArray?: boolean) => {
  const resolveDataTypeToMenuItem = useCallback(
    (type: number): DataTypes => {
      switch (type) {
        case 0:
          return "line";
        case 1:
          return "toggle";
        case 2:
          return "number";
        case 3:
          return "decimal";
        case 4:
          if (isArray) {
            return "checkboxes";
          }
          return "radiobuttons";
        case 5:
          return "multiline";
        case 6:
          return "datetime";
        case 7:
          return "date";
        default:
          return "line";
      }
    },
    [isArray],
  );

  const resolveMenuItemToDataType = useCallback((type: DataTypes): number => {
    switch (type) {
      case "line":
        return 0;
      case "toggle":
        return 1;
      case "number":
        return 2;
      case "decimal":
        return 3;
      case "checkboxes":
      case "radiobuttons":
        return 4;
      case "multiline":
        return 5;
      case "datetime":
        return 6;
      case "date":
        return 7;
      default:
        return 0;
    }
  }, []);

  return {
    resolveMenuItemToDataType,
    resolveDataTypeToMenuItem,
  };
};
