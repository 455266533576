import { QueryClient } from "@tanstack/react-query";

import { Functionalities, FunctionalityScope } from "types/functionalities";

import { FunctionalityRequirements } from "./functionalities.types";
import { hasFunctionality, hasFunctionalityWithoutScope } from "./has-functionality";

export const getFunctionalityRequirements = async (
  queryClient: QueryClient,
  functionality: Functionalities,
  ouId?: number,
): Promise<FunctionalityRequirements> => {
  const hasNoneRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.None,
    ouId,
  );
  const hasViewRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.View,
    ouId,
  );
  const hasEditRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Edit,
    ouId,
  );
  const hasCreateRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Create,
    ouId,
  );
  const hasDeleteRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Delete,
    ouId,
  );
  const hasManageRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Manage,
    ouId,
  );
  const hasSettingsRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Settings,
    ouId,
  );
  const hasScriptingRights = await hasFunctionality(
    queryClient,
    functionality,
    FunctionalityScope.Scripting,
    ouId,
  );
  const hasUnscopedRights = await hasFunctionalityWithoutScope(queryClient, functionality, ouId);

  return {
    hasNoneRights,
    hasViewRights,
    hasEditRights,
    hasCreateRights,
    hasDeleteRights,
    hasManageRights,
    hasSettingsRights,
    hasScriptingRights,
    hasUnscopedRights,
  };
};
