import { useCallback, useEffect, useMemo, useState } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniqBy } from "lodash";

import { MaterialAutocomplete } from "components/suite-ui/autocomplete";
import ErrorBoundary from "components/suite-ui/error-boundary";

import { SingleAutocomplete } from "./autocomplete-generator.single.types";
import {
  IAutocompleteGeneratorProps,
  ILocalAutocompleteGeneratorProps,
  SpyCallback,
} from "./autocomplete-generator.types";

export const generateSingleAutocomplete = <SVC extends IEvaServiceDefinition, Item>({
  defaultLabel,
  filterKeys,
  frontendFilter,
  getItemFromResponse,
  hideDownArrow,
  idKey,
  initialData,
  labelKey,
  rightAdornment,
  secondaryLabelFallback = "",
  secondaryLabelKey,
  useServiceQuery,
}: IAutocompleteGeneratorProps<SVC, Item>) => {
  const SingleAutocomplete = ({
    autoFocus,
    disabled,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onBlur,
    onChange,
    passive,
    placeholder,
    required,
    small,
    spyCallback,
    value,
    warning,
    disablePortal,
  }: SingleAutocomplete<Item>["Props"] & SpyCallback<Item>) => {
    const [input, setInput, { data, isFetching: isLoading }] = useServiceQuery();

    const items = useMemo(
      () =>
        frontendFilter
          ? uniqBy(
              [
                ...(initialData ?? []),
                ...(value ? [value] : []),
                ...(getItemFromResponse(data) ?? []),
              ],
              idKey,
            ).filter(frontendFilter)
          : uniqBy(
              [
                ...(initialData ?? []),
                ...(value ? [value] : []),
                ...(getItemFromResponse(data) ?? []),
              ],
              idKey,
            ),
      [data, value],
    );

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    return (
      <ErrorBoundary>
        <MaterialAutocomplete
          small={small}
          value={value}
          IDKey={idKey}
          error={error}
          warning={warning}
          items={items ?? []}
          setValue={onChange}
          onBlur={onBlur}
          LabelKey={labelKey}
          required={required}
          autoFocus={autoFocus}
          helperText={helperText}
          onInputChange={setInput}
          controlledInputValue={input}
          label={label ?? defaultLabel}
          SecondaryLabelKey={secondaryLabelKey}
          secondaryLabelFallback={secondaryLabelFallback}
          isLoading={isOutsideLoading || isLoading}
          passive={passive}
          disabled={disabled}
          endAdornment={rightAdornment}
          hideDownArrow={hideDownArrow}
          filterKeys={filterKeys}
          placeholder={placeholder}
          disablePortal={disablePortal}
        />
        <input type="hidden" name={name} value={value?.[idKey] ? `${value?.[idKey]}` : ""} />
      </ErrorBoundary>
    );
  };
  return SingleAutocomplete;
};

export const generateSingleLocalAutocomplete = <Item, IDKey extends keyof Item>({
  defaultLabel,
  idKey,
  items: itemsArray,
  labelKey,
  secondaryLabelFallback = "",
  secondaryLabelKey,
}: ILocalAutocompleteGeneratorProps<Item, IDKey>) => {
  const SingleAutocomplete = ({
    autoFocus,
    disabled,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onBlur,
    onChange,
    passive,
    placeholder,
    required,
    small,
    spyCallback,
    value,
    disablePortal,
  }: SingleAutocomplete<Item>["Props"] & SpyCallback<Item>) => {
    const [input, setLocalQuery] = useState<string | undefined>();
    const setInput = useCallback(
      (newValue: string | undefined) => {
        if (newValue !== input) {
          setLocalQuery(newValue);
        }
      },
      [input],
    );

    const items = useMemo(
      () => uniqBy([...(value ? [value] : []), ...(itemsArray ?? [])], idKey),
      [value],
    );

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    return (
      <ErrorBoundary>
        <MaterialAutocomplete
          small={small}
          value={value}
          IDKey={idKey}
          error={error}
          passive={passive}
          disabled={disabled}
          items={items ?? []}
          setValue={onChange}
          onBlur={onBlur}
          LabelKey={labelKey}
          required={required}
          autoFocus={autoFocus}
          helperText={helperText}
          onInputChange={setInput}
          controlledInputValue={input}
          isLoading={isOutsideLoading}
          label={label ?? defaultLabel}
          SecondaryLabelKey={secondaryLabelKey}
          secondaryLabelFallback={secondaryLabelFallback}
          placeholder={placeholder}
          disablePortal={disablePortal}
        />
        <input type="hidden" name={name} value={value?.[idKey] ? `${value?.[idKey]}` : ""} />
      </ErrorBoundary>
    );
  };
  return SingleAutocomplete;
};
