import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

// TODO: ask BE for service
export const getInvoiceAdditionalAmountTypes = builders.buildServiceState({
  service: Core.GetEnumValues,
  beforeRequest: () => ({
    Name: "InvoiceAdditionalAmountTypes",
  }),
  key: "Core.GetEnumValues:InvoiceAdditionalAmountTypes:selector",
});
