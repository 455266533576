import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listAvailableUserTasksLoaderQuery,
  serviceQuery: listAvailableUserTasksQuery,
  serviceQueryKeys: listAvailableUserTasksQueryKeys,
  useServiceQueryHook: useListAvailableUserTasksQuery,
} = createServiceQuery(Core.ListAvailableUserTasks);

export const {
  serviceQuery: getShipFromStoreTaskByBarcodeQuery,
  useServiceQueryHook: useGetShipFromStoreTaskByBarcodeQuery,
} = createServiceQuery(Core.GetShipFromStoreTaskByBarcode);

export const {
  serviceLoaderQuery: startCompositeUserTaskLoaderQuery,
  serviceQuery: startCompositeUserTaskQuery,
  serviceQueryKeys: startCompositeUserTaskQueryKeys,
  useServiceQueryHook: useStartCompositeUserTaskQuery,
} = createServiceQuery(Core.StartCompositeUserTask, true);

export const {
  serviceLoaderQuery: startShipFromStoreTaskLoaderQuery,
  serviceQuery: startShipFromStoreTaskQuery,
  serviceQueryKeys: startShipFromStoreTaskQueryKeys,
  useServiceQueryHook: useStartShipFromStoreTaskQuery,
} = createServiceQuery(Core.StartShipFromStoreTask, true);

export const {
  serviceLoaderQuery: startReceiveShipmentLoaderQuery,
  serviceQuery: startReceiveShipmentQuery,
  serviceQueryKeys: startReceiveShipmentQueryKeys,
  useServiceQueryHook: useStartReceiveShipmentQuery,
} = createServiceQuery(Core.StartReceiveShipment, true);

export const {
  serviceLoaderQuery: getPackagesForCarrierLoaderQuery,
  serviceQuery: getPackagesForCarrierQuery,
  serviceQueryKeys: getPackagesForCarrierQueryKeys,
  useServiceQueryHook: useGetPackagesForCarrierQuery,
} = createServiceQuery(CoreManagement.GetPackagesForCarrier, true);

export const {
  serviceLoaderQuery: getUserTaskDetailsLoaderQuery,
  serviceQuery: getUserTaskDetailsQuery,
  serviceQueryKeys: getUserTaskDetailsQueryKeys,
  useServiceQueryHook: useGetUserTaskDetailsQuery,
} = createServiceQuery(Core.GetUserTaskDetails, true);

export const { serviceQuery: getUserTaskTypesQuery } = createServiceQuery(Core.GetUserTaskTypes);

export const { serviceQuery: listUserTaskTypeOrganizationUnitSetsQuery } = createServiceQuery(
  Core.ListUserTaskTypeOrganizationUnitSets,
);
export const {
  serviceLoaderQuery: getUserTaskNotCompletedReasonsLoaderQuery,
  serviceQuery: getUserTaskNotCompletedReasonsQuery,
  serviceQueryKeys: getUserTaskNotCompletedReasonsQueryKeys,
  useServiceQueryHook: useGetUserTaskNotCompletedReasonsQuery,
} = createServiceQuery(Core.GetUserTaskNotCompletedReasons, true);
