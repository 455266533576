import { useCallback } from "react";
import {
  NavigateOptions,
  To,
  useNavigate as useRouterNavigate,
  useSearchParams,
} from "react-router-dom";

import { usePrompt } from "./provider";

export type NavigateOptionsWithForce = NavigateOptions & {
  force?: boolean;
  keepSearchParams?: boolean;
};

export function useNavigate() {
  const { setConfirmRoute, when } = usePrompt();
  const navigate = useRouterNavigate();
  const [searchParams] = useSearchParams();

  const blockedNavigate = useCallback(
    (to: To | number, options?: NavigateOptionsWithForce) => {
      let newRoute = to;
      if (options?.keepSearchParams) {
        newRoute = `${newRoute}?${searchParams.toString()}`;
      }
      if (when && !options?.force) {
        setConfirmRoute(newRoute);
      } else {
        if (typeof newRoute === "number") {
          navigate(newRoute);
        } else {
          navigate(newRoute, options);
        }
      }
    },
    [navigate, searchParams, setConfirmRoute, when],
  );

  return blockedNavigate;
}
