import { Core } from "@springtree/eva-services-core";

import { getStockLabelsQuery } from "models/stock-locations";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const {
  SingleAutocomplete: StockLabelsAutocomplete,
  SingleIDAutocomplete: StockLabelsIDAutocomplete,
} = AutocompleteGenerator<Core.GetStockLabels, { ID: number; Name: string }, "ID">({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (resp) =>
    resp?.StockLabels?.map((stockLabel) => ({ ID: stockLabel.ID, Name: stockLabel.DisplayName })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.stock-label",
    defaultMessage: "Stock label",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: getStockLabelsQuery,
    }),
  useItemByID: (id, stockLabels) => {
    const stockLabel = stockLabels?.find((stockLabel) => stockLabel.ID === id);

    return {
      data: stockLabel,
    };
  },
});

export const StockLabelsSearchListField = SearchListFieldGenerator<
  Core.GetStockLabels,
  {
    ID: number;
    Name?: string;
  },
  number
>({
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: getStockLabelsQuery,
      refetchOnFocus: false,
    }),
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name ?? "",
  getItemFromResponse: (resp) =>
    resp?.StockLabels?.map((stockLabel) => ({ ID: stockLabel.ID, Name: stockLabel.DisplayName })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.stock-label",
    defaultMessage: "Stock label",
  }),
  useItemByID: (id, itemsFromList) => {
    const item = itemsFromList?.find((item) => item.ID === id);
    return {
      data: item,
    };
  },
});
