import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getEventExportConfigurationsLoaderQuery,
  serviceQuery: getEventExportConfigurationsQuery,
  serviceQueryKeys: getEventExportConfigurationsQueryKeys,
  useServiceQueryHook: useGetEventExportConfigurationsQuery,
} = createServiceQuery(Core.GetEventExportConfigurations);

export const {
  serviceLoaderQuery: getEventExportConfigurationLoaderQuery,
  serviceQuery: getEventExportConfigurationQuery,
  serviceQueryKeys: getEventExportConfigurationQueryKeys,
  useServiceQueryHook: useGetEventExportConfigurationQuery,
} = createServiceQuery(Core.GetEventExportConfiguration);

export const {
  serviceLoaderQuery: getEventExportTargetTypesLoaderQuery,
  serviceQuery: getEventExportTargetTypesQuery,
  serviceQueryKeys: getEventExportTargetTypesQueryKeys,
  useServiceQueryHook: useGetEventExportTargetTypesQuery,
} = createServiceQuery(Core.GetEventExportTargetTypes);
