import { useMemo } from "react";

import { first, last } from "lodash";
import { Moment } from "moment";

import { IBaseCalendarItem } from "../types";

export interface IUseFilteredCalendarItems<T extends IBaseCalendarItem> {
  items: T[];
  displayedDays: Moment[];
}
/**
 * Hook that filters out the items for which the start date - end date interval does not intersect with the displayed days
 */
const useFilteredCalendarItems = <T extends IBaseCalendarItem>({
  displayedDays,
  items,
}: IUseFilteredCalendarItems<T>) => {
  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        // keep the item if it has no start date and no end date
        if (!item.StartDate && !item.EndDate) {
          return true;
        }

        // keep the item if it only has an end date and the end date is after the first displayed day
        if (!item.StartDate && item.EndDate && item.EndDate.isSameOrAfter(first(displayedDays))) {
          return true;
        }

        // keep the item if it only has a start date and the start date is before the last displayed day
        if (item.StartDate && !item.EndDate && item.StartDate.isSameOrBefore(last(displayedDays))) {
          return true;
        }
        // keep the item if it has both a start date and an end date and the start date - end date interval intersects with or includes all the displayed days
        const intersectsDisplayedDays =
          (item.StartDate &&
            item.StartDate.isBetween(first(displayedDays), last(displayedDays), undefined, "()")) ||
          (item.EndDate &&
            item.EndDate.isBetween(first(displayedDays), last(displayedDays), undefined, "()"));

        const includesAllDisplayedDays =
          item.StartDate?.isBefore(first(displayedDays)) &&
          item.EndDate?.isAfter(last(displayedDays));

        return includesAllDisplayedDays || intersectsDisplayedDays;
      }),
    [items, displayedDays],
  );

  return filteredItems;
};

export default useFilteredCalendarItems;
