import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCashHandlersQuery, useGetCashHandlerByIdQuery } from "models/cash-handlers";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { emptyStringToUndefined } from "util/helper";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateCashHandlersSearchListField = (
  initialRequest?: EVA.Core.Management.ListCashHandlers,
) =>
  SearchListFieldGenerator<
    CoreManagement.ListCashHandlers,
    EVA.Core.Management.ListCashHandlersResponse.CashHandlerDto,
    EVA.Core.Management.ListCashHandlersResponse.CashHandlerDto["ID"]
  >({
    getItemFromResponse: (resp) => resp?.Result?.Page,
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.cash-handler",
      defaultMessage: "Cash handler",
    }),
    useItemByID: (id, listItems) => {
      const itemFromList = useMemo(() => {
        if (!id || !listItems) {
          return undefined;
        }
        return listItems?.find((item) => item.ID === id);
      }, [id, listItems]);

      const { data, isFetching } = useGetCashHandlerByIdQuery(
        id && !itemFromList ? { ID: id } : undefined,
        { denyEmptyRequest: true },
      );
      const result = useMemo(() => {
        if (!id) {
          return { data: undefined, isLoading: false };
        }
        if (itemFromList) {
          return { data: itemFromList, isLoading: false };
        }
        return { data, isLoading: isFetching };
      }, [data, id, isFetching, itemFromList]);
      return result;
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listCashHandlersQuery,
        initialRequest: {
          ...initialRequest,
          PageConfig: {
            ...initialRequest?.PageConfig,
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...initialRequest?.PageConfig?.Filter, Name: undefined },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, query) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: { ...req?.PageConfig?.Filter, Name: emptyStringToUndefined(query) },
          },
        }),
        configureLoadMoreButton: (resp) => ({
          shouldShowLoadMoreButton:
            (resp?.Result.Total ?? 0) > (resp?.Result.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit:
                (req?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
