import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import useSetRequest from "hooks/suite-react-hooks/use-set-request";

import { productRequirementByIDServiceState } from ".";

const useProductRequirementByID = (ID?: number) => {
  const request = useMemo<EVA.Core.Management.GetProductRequirementByID | undefined>(() => {
    if (ID) {
      return {
        ID,
      };
    }
    return undefined;
  }, [ID]);

  useSetRequest(productRequirementByIDServiceState.request(ID), request);

  const productRequirementByID = hooks.useGetState(productRequirementByIDServiceState.response(ID));

  const productRequirementByIDName = useMemo(
    () => productRequirementByID?.Name,
    [productRequirementByID?.Name],
  );

  const productRequirementByIDLoading = hooks.useIsLoading({
    state: productRequirementByIDServiceState.response(ID),
  });

  return {
    productRequirementByID,
    productRequirementByIDName,
    productRequirementByIDLoading,
  };
};

export default useProductRequirementByID;
