import { useCallback, useMemo } from "react";

import { useAppSetting } from "../use-app-setting";

export interface IProduct {
  product_id?: number;
  custom_id?: string;
  backend_id?: string;
}

export interface IProductCamelCase {
  ProductID?: number;
  CustomID?: string;
  BackendID?: string;
}

const PRODUCT_ID_PROPERTY_APP_SETTING = "App:CustomerProductValue";

const useProductIDProperty = (fallbackPropertyName?: string) => {
  const { appSettingValue: itemIDKeySetting } = useAppSetting(
    PRODUCT_ID_PROPERTY_APP_SETTING,
    fallbackPropertyName ?? "ProductID",
  );

  const itemIDKey = useMemo(
    () => (itemIDKeySetting ? `${itemIDKeySetting}` : "ProductID").toLowerCase(),
    [itemIDKeySetting],
  );

  const idProperty = useMemo(() => {
    if (itemIDKey === "customid") {
      return "custom_id" as const;
    }
    if (itemIDKey === "backendid") {
      return "backend_id" as const;
    }
    return "product_id" as const;
  }, [itemIDKey]);

  const idPropertyCamelCase = useMemo(() => {
    if (itemIDKey === "customid") {
      return "CustomID" as const;
    }
    if (itemIDKey === "backendid") {
      return "BackendID" as const;
    }
    return "ProductID" as const;
  }, [itemIDKey]);

  const getProductIDPropertyValue = useCallback(
    (productDetails?: IProduct) => {
      if (productDetails) {
        return productDetails?.[idProperty];
      }
      return undefined;
    },
    [idProperty],
  );

  const getProperty = useCallback(
    (
      ids: [productId?: number | string, customId?: number | string, backendId?: number | string],
    ) => {
      if (itemIDKey === "customid") {
        return ids[1];
      }
      if (itemIDKey === "backendid") {
        return ids[2];
      }
      return ids[0];
    },
    [itemIDKey],
  );

  return {
    getProductIDPropertyValue,
    idProperty,
    itemIDKeySetting,
    idPropertyCamelCase,
    getProperty,
  };
};

export default useProductIDProperty;
