import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const listStationsForOrganizationUnitServiceState = builders.buildServiceFamilyState({
  key: "Core:ListStationsForOrganizationUnit",
  service: Core.ListStationsForOrganizationUnit,
  requireLoggedIn: true,
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.OrganizationUnitID) {
      return req;
    }
    return {
      ...req,
      OrganizationUnitID: parameter,
    };
  },
});

export const listStationsForOrganizationUnitResultSelector = selectorFamily({
  key: "Core:ListStationsForOrganizationUnit:Result",
  get:
    (organizationUnitID: number | undefined) =>
    ({ get }) =>
      get(listStationsForOrganizationUnitServiceState.response(organizationUnitID))?.Result?.Page,
});
