import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Badge, BadgeProps } from "@new-black/lyra";

import { LoyaltyProgramStatus } from "./loyalty-program-form";

export const LoyaltyProgramStatusBadge = ({ value }: { value?: LoyaltyProgramStatus }) => {
  const intl = useIntl();

  const badgeContent = useMemo<
    { label: string; variant: BadgeProps["variant"] } | undefined
  >(() => {
    switch (value) {
      case LoyaltyProgramStatus.Inactive:
        return {
          label: intl.formatMessage({ id: "generic.label.disabled", defaultMessage: "Disabled" }),
          variant: "gray",
        };
      case LoyaltyProgramStatus.Active:
        return {
          label: intl.formatMessage({ id: "generic.label.enabled", defaultMessage: "Enabled" }),
          variant: "green",
        };
      default:
        return undefined;
    }
  }, [intl, value]);

  if (!badgeContent) return null;

  return <Badge variant={badgeContent.variant}>{badgeContent.label}</Badge>;
};
