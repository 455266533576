import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useHasFunctionality } from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { IChapter } from "../chapter-finder.types";

export const useOrganizationsChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: organizationsChapterAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNITS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: organizationSetsChapterAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: visibilityGroupsChapterAllowed } = useHasFunctionality(
    Functionalities.VISIBILITY_GROUPS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: openingHoursTemplatesChapterAllowed } = useHasFunctionality(
    Functionalities.OPENING_HOURS_TEMPLATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: openingHoursTypesChapterAllowed } = useHasFunctionality(
    Functionalities.OPENING_HOURS_TYPES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: companiesChapterAllowed } = useHasFunctionality(
    Functionalities.COMPANIES,
    FunctionalityScope.View,
  );

  const organizationsChapters = useMemo<IChapter[]>(() => {
    let chapters: IChapter[] = [];

    if (organizationsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.organizations.chapters.organizations",
            defaultMessage: "Organizations",
          }),
          path: "/organizations",
        },
      ];
    }

    if (organizationSetsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.organization-sets",
            defaultMessage: "Organization Sets",
          }),
          path: "/organization-sets",
        },
      ];
    }

    if (openingHoursTemplatesChapterAllowed || openingHoursTypesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.opening-hours",
            defaultMessage: "Opening Hours",
          }),
          path: "/opening-hours",
        },
      ];
    }

    if (companiesChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.organizations.chapters.companies",
            defaultMessage: "Companies",
          }),
          path: "/companies",
        },
      ];
    }

    if (visibilityGroupsChapterAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.visibility-groups",
            defaultMessage: "Visibility Groups",
          }),
          path: "/visibility-groups",
        },
      ];
    }

    return chapters;
  }, [
    companiesChapterAllowed,
    intl,
    openingHoursTemplatesChapterAllowed,
    openingHoursTypesChapterAllowed,
    organizationSetsChapterAllowed,
    organizationsChapterAllowed,
    visibilityGroupsChapterAllowed,
  ]);

  return organizationsChapters;
};
