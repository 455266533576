import Grid from "components/suite-ui/grid";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import { Authorized } from "../../authorized";

import { EntityTranslationButton } from "./entity-translation-button";
import { IEntityTranslationIconProps } from "./types";

export const EntityTranslationIcon = ({
  insideContainer,
  ...props
}: IEntityTranslationIconProps) => (
  <Authorized
    disableRedirect
    scope={FunctionalityScope.View}
    functionality={Functionalities.TRANSLATIONS}
  >
    {insideContainer ? (
      <Grid item>
        <EntityTranslationButton {...props} />
      </Grid>
    ) : (
      <EntityTranslationButton {...props} />
    )}
  </Authorized>
);
