import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listTimeZonesQuery } from "models/application";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

const TimeZoneAutocomplete = AutocompleteGenerator<
  Core.ListAvailableTimeZones,
  { ID: string; Name: string }
>({
  getItemFromResponse: (response) =>
    response?.Result?.map((item) => ({ Name: item.ID, ID: item.ID })) ?? [],
  idKey: "ID",
  labelKey: "Name",
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.time-zone",
    defaultMessage: "Time zone",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: listTimeZonesQuery,
      staleTime: ONE_HOUR,
      initialRequest: {},
    }),
});

export const TimeZoneSearchListField = SearchListFieldGenerator<
  Core.ListAvailableTimeZones,
  { ID: string; Name: string },
  string
>({
  getItemFromResponse: (response) =>
    response?.Result?.map((item) => ({ Name: item.ID, ID: item.ID })) ?? [],
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.time-zone",
    defaultMessage: "Time zone",
  }),
  selectRenderElements: (item) => ({
    label: item.Name,
  }),
  useItemByID: (id, listItems) => {
    const data = useMemo(() => {
      if (!id) {
        return undefined;
      }
      return listItems?.find((item) => item.ID === id);
    }, [id, listItems]);
    return { data, isLoading: false };
  },
  useItemsByID: (ids, listItems) => {
    const data = useMemo(() => {
      if (!ids || !ids.length) {
        return undefined;
      }
      return ids
        .map((id) => listItems?.find((item) => item.ID === id))
        .filter((item): item is NonNullable<typeof item> => item !== undefined);
    }, [ids, listItems]);
    return { data, isLoading: false };
  },
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      refetchOnFocus: false,
      query: listTimeZonesQuery,
      staleTime: ONE_HOUR,
      initialRequest: {},
    }),
});

export default TimeZoneAutocomplete;
