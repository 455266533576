import { useIntl } from "react-intl";

import { Dialog } from "@new-black/lyra";

import { IProductSearchTemplateModalProps } from "./product-search-template-modal.types";
import { ProductSearchTemplateModalContent } from "./product-search-template-modal-content";

export const ProductSearchTemplateModal = ({
  "aria-label": ariaLabel,
  isOpen,
  maxWidth,
  onOpenChange,
  ...transitionalProps
}: IProductSearchTemplateModalProps) => {
  const intl = useIntl();

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={onOpenChange}
      maxWidth={maxWidth}
      aria-label={
        ariaLabel ||
        intl.formatMessage({
          id: "generic.label.add-product-search-template",
          defaultMessage: "Add product search template",
        })
      }
    >
      {({ close }) => <ProductSearchTemplateModalContent close={close} {...transitionalProps} />}
    </Dialog>
  );
};

export { ProductSearchTemplateModalContent };
export type { IProductSearchTemplateModalProps };
