import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardActions,
  CardHeader,
  CardProps,
  SvgIcon,
  Text,
} from "@new-black/lyra";
import classNames from "classnames";
import { isNil } from "lodash";

import { ContentDimensions, MeasureContent } from "components/shared/measure-content";

import { ExpanseCardHeader } from "./expanse-card-header";

export interface ExpanseCardProps {
  componentClassNames?: {
    cardHeader?: string;
    expanseCard?: string;
    expanseButton?: string;
  };
  children: React.ReactNode;
  title?: string;
  subTitle?: string;
  initialOpen?: boolean;
  disabled?: boolean;
  open?: boolean;
  setOpen?: (open: boolean) => void;
  customActions?: React.ReactNode;
  titleCustomActions?: React.ReactNode;
  withoutContentPadding?: boolean;
  toggleOnHeaderClick?: boolean;
  disableInitialAnimation?: boolean;
  /** Content displayed instead of the children when the card is collapsed  */
  closedContent?: ReactNode;
  variant?: CardProps["variant"];
}

const ExpanseCard = ({ variant, ...props }: ExpanseCardProps) => (
  <Card variant={variant} className={props.componentClassNames?.expanseCard}>
    <ExpanseSection {...props} />
  </Card>
);

export const ExpanseSection = ({
  children,
  closedContent = null,
  componentClassNames,
  customActions,
  disabled,
  disableInitialAnimation,
  initialOpen,
  open,
  setOpen,
  subTitle,
  title,
  titleCustomActions,
  toggleOnHeaderClick,
  withoutContentPadding,
}: ExpanseCardProps) => {
  const intl = useIntl();
  const [isExpanded, setExpanded] = useState<boolean>(open ?? initialOpen ?? false);

  const onExpand = useCallback(() => {
    if (setOpen) {
      setOpen(!open);
    } else {
      setExpanded((state) => !state);
    }
  }, [open, setOpen]);

  useEffect(() => {
    if (!isNil(open) && open !== isExpanded) {
      setExpanded(open);
    }
  }, [isExpanded, open]);

  const canToggleOnHeaderClick = useMemo(
    () => !disabled && toggleOnHeaderClick,
    [disabled, toggleOnHeaderClick],
  );

  const closedChildren = useMemo(
    () => (isNil(closedContent) ? null : closedContent),
    [closedContent],
  );

  return (
    <>
      {title && (
        <div
          className={classNames(canToggleOnHeaderClick ? "cursor-pointer" : "cursor-default")}
          onClick={() => {
            canToggleOnHeaderClick && onExpand();
          }}
        >
          <CardHeader
            className={classNames("rounded-b-lg", componentClassNames?.cardHeader)}
            title={
              <>
                <Text
                  variant="heading-2"
                  className={classNames(disabled && "text-[color:var(--eva-color-disabled)]")}
                >
                  {title}
                </Text>
                {titleCustomActions}
              </>
            }
            description={subTitle}
            actions={
              <CardActions>
                <ButtonGroup>
                  {customActions ? (
                    <div onClick={(e) => e.stopPropagation()}>{customActions}</div>
                  ) : null}
                  <Button
                    variant="icon"
                    isDisabled={disabled}
                    onPress={onExpand}
                    tooltip={
                      isExpanded
                        ? intl.formatMessage({ id: "generic.label.close", defaultMessage: "Close" })
                        : intl.formatMessage({ id: "generic.label.open", defaultMessage: "Open" })
                    }
                    className={componentClassNames?.expanseButton}
                  >
                    <SvgIcon
                      name="chevron-down"
                      className={classNames("duration-200", isExpanded && "rotate-180")}
                    />
                  </Button>
                </ButtonGroup>
              </CardActions>
            }
          />
        </div>
      )}

      <MeasureContent
        ParentComponent={ExpanseCardContent}
        parentComponentProps={{
          withoutContentPadding,
          isExpanded,
          disableInitialAnimation,
          hasContentToDisplayWhenCollapsed: !!closedContent,
        }}
      >
        {isExpanded ? children : closedChildren}
      </MeasureContent>
    </>
  );
};

interface IExpanseCardContent extends ContentDimensions {
  withoutContentPadding?: boolean;
  isExpanded: boolean;
  children: React.ReactNode;
  hasContentToDisplayWhenCollapsed?: boolean;
  disableInitialAnimation?: boolean;
}
const ExpanseCardContent = ({
  children,
  contentHeight,
  disableInitialAnimation,
  hasContentToDisplayWhenCollapsed,
  isExpanded,
  withoutContentPadding,
}: IExpanseCardContent) => {
  const [isInitialAnimation, setIsInitialAnimation] = useState(true);
  const paddingHeight = useMemo(() => (withoutContentPadding ? 0 : 20), [withoutContentPadding]);

  useEffect(() => setIsInitialAnimation(false), []);

  return (
    <div
      className={classNames(
        "overflow-hidden  ease-in-out",
        disableInitialAnimation && isInitialAnimation ? "duration-0" : "duration-300",
      )}
      style={{
        height:
          (isExpanded || hasContentToDisplayWhenCollapsed) && contentHeight
            ? `${contentHeight + paddingHeight}px`
            : 0,
      }}
    >
      <div className={classNames(withoutContentPadding ? "px-0 pb-0" : "px-5 pb-5")}>
        {children}
      </div>
    </div>
  );
};

ExpanseCard.Header = ExpanseCardHeader;

export default ExpanseCard;
