import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { SegmentedControl, SegmentedControlItem } from "@new-black/lyra";

export interface IBooleanSegmentedButtonProps {
  label?: string;
  trueLabel?: string;
  falseLabel?: string;
  undefinedLabel?: string;
  value: boolean | undefined;
  setValue: (newState: boolean | undefined) => void;
  hideFalse?: boolean;
  hideUndefined?: boolean;
  name?: string;
  className?: string;
  hideInputHeader?: boolean;
}

export const BooleanSegmentedButton = ({
  className,
  falseLabel,
  hideFalse,
  hideInputHeader = false,
  hideUndefined,
  label,
  name,
  setValue,
  trueLabel,
  undefinedLabel,
  value,
}: IBooleanSegmentedButtonProps) => {
  const intl = useIntl();

  const handleChange = useCallback(
    (newValue?: string) => {
      if (newValue === "true") {
        setValue(true);
      } else if (newValue === "false") {
        setValue(false);
      } else {
        setValue(undefined);
      }
    },
    [setValue],
  );

  const valueToString = useMemo(() => {
    if (value === true) {
      return "true";
    }
    if (value === false) {
      return "false";
    }
    return "undefined";
  }, [value]);

  const options = useMemo(
    () => [
      {
        value: "true",
        label:
          trueLabel ?? intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" }),
      },
      ...(hideFalse
        ? []
        : [
            {
              value: "false",
              label:
                falseLabel ??
                intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" }),
            },
          ]),
      ...(hideUndefined
        ? []
        : [
            {
              value: "undefined",
              label:
                undefinedLabel ??
                intl.formatMessage({ id: "generic.label.n/a", defaultMessage: "N/A" }),
            },
          ]),
    ],
    [falseLabel, hideFalse, hideUndefined, intl, trueLabel, undefinedLabel],
  );

  return (
    <SegmentedControl
      className={className}
      name={name}
      label={label}
      hideInputHeader={hideInputHeader}
      onChange={(newValue) => {
        handleChange(newValue);
      }}
      value={valueToString}
    >
      {options.map((option) => (
        <SegmentedControlItem key={option.value} value={option.value} textValue={option.label}>
          {option.label}
        </SegmentedControlItem>
      ))}
    </SegmentedControl>
  );
};
