import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listReturnToSupplierRequestsLoaderQuery,
  serviceQueryKeys: listReturnToSupplierRequestsQueryKeys,
  useServiceQueryHook: useListReturnToSupplierRequestsQuery,
} = createServiceQuery(Core.ListReturnToSupplierRequests);

export const {
  serviceLoaderQuery: getReturnToSupplierRequestByIDLoaderQuery,
  serviceQueryKeys: getReturnToSupplierRequestByIDQueryKeys,
  useServiceQueryHook: useGetReturnToSupplierRequestByIDQuery,
} = createServiceQuery(Core.GetReturnToSupplierRequestByID);

export const {
  serviceLoaderQuery: validateReturnToSupplierRequestLoaderQuery,
  serviceQueryKeys: validateReturnToSupplierRequestQueryKeys,
  useServiceQueryHook: useValidateReturnToSupplierRequestIDQuery,
} = createServiceQuery(Core.ValidateReturnToSupplierRequest);
