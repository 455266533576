import { useCallback, useRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Menu, MenuItem } from "@material-ui/core";
import { Menu as LyraMenu, MenuTrigger } from "@new-black/lyra";

import AddActionButton from "components/shared/action-buttons/add-action-button";
import { AddBulkProductsModal } from "components/suite-composite/add-bulk-products-modal";

import { useProductFilterContext } from "../product-filter-context";
import {
  PropertyClashConfirmationModal,
  usePropertyClashConfirmationModalState,
} from "../property-clash-confirmation-modal";

enum AddProductFilterAction {
  AddBulkProducts = "add-bulk-products",
  AddProductFilter = "add-product-filter",
}

/**
 * Component that can be used in order to trigger the add filter product filter modal or the add bulk products modal via a menu
 */
const ProductFilterAddProductsAction = ({
  variant = "material",
}: {
  variant?: "lyra" | "material";
}) => {
  const intl = useIntl();
  const [showMenu, setShowMenu] = useState(false);
  const [showBulkProductsModal, setShowBulkProductsModal] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const {
    isUpdateProductFilterLoading,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();

  const { propertyClashConfirmationModalState, setPropertyClashConfirmationModalState } =
    usePropertyClashConfirmationModalState();

  const handleAddBulkProducts = useCallback(
    (property: string, values: string[]) => {
      updateProductFilter({ ...productFilter, [property]: { Values: values } }).then(() => {
        setShowBulkProductsModal(false);
      });
    },
    [productFilter, updateProductFilter],
  );

  const checkPropertyClash = useCallback(
    (property: string, values: string[]) => {
      const currentKeys = Object.keys(productFilter ?? {});
      if (currentKeys.includes(property)) {
        setPropertyClashConfirmationModalState({
          show: true,
          message: intl.formatMessage(
            {
              id: "generic.question.overwrite-clashing-properties.singular",
              defaultMessage: "Are you sure you want to overwrite this property: {property}?",
            },
            { property },
          ),
          onSubmit: () => {
            handleAddBulkProducts(property, values);
            setPropertyClashConfirmationModalState({ show: false });
          },
          onCancel: () => {
            setPropertyClashConfirmationModalState({ show: false });
          },
        });
        return;
      }
      handleAddBulkProducts(property, values);
    },
    [handleAddBulkProducts, intl, productFilter, setPropertyClashConfirmationModalState],
  );

  return (
    <>
      {variant === "material" ? (
        <>
          <AddActionButton
            ref={anchorRef}
            onPress={() => setShowMenu(true)}
            tooltip={intl.formatMessage({
              id: "product-filter.actions.add-products",
              defaultMessage: "Add products",
            })}
          />
          {showMenu ? (
            <Menu
              anchorEl={anchorRef.current}
              open={showMenu}
              onClose={() => setShowMenu(false)}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <MenuItem
                className="w-full"
                onClick={() => {
                  setShowBulkProductsModal(true);
                  setShowMenu(false);
                }}
              >
                <FormattedMessage
                  id="product-filter.actions.add-products.menu.add-bulk-products"
                  defaultMessage="Add products in bulk"
                />
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenProductFilterModal(true);
                  setCurrentProductFilterModalValues({ keyName: undefined });
                  setShowMenu(false);
                }}
                className="w-full"
              >
                <FormattedMessage
                  id="product-filter.actions.add-products.menu.add-product-filter"
                  defaultMessage="Add product filter"
                />
              </MenuItem>
            </Menu>
          ) : null}
        </>
      ) : null}

      {variant === "lyra" ? (
        <MenuTrigger>
          <AddActionButton
            ref={anchorRef}
            onPress={() => setShowMenu(true)}
            tooltip={intl.formatMessage({
              id: "product-filter.actions.add-products",
              defaultMessage: "Add products",
            })}
          />

          <LyraMenu
            getItemId={({ id }) => id}
            selectRenderElements={(item) => ({ label: item.name })}
            aria-label={intl.formatMessage({
              id: "product-filter.actions.add-products",
              defaultMessage: "Add products",
            })}
            onAction={(action) => {
              switch (action) {
                case AddProductFilterAction.AddBulkProducts: {
                  setShowBulkProductsModal(true);
                  return;
                }
                case AddProductFilterAction.AddProductFilter: {
                  setOpenProductFilterModal(true);
                  setCurrentProductFilterModalValues({ keyName: undefined });
                  return;
                }
              }
            }}
            items={[
              {
                id: AddProductFilterAction.AddBulkProducts,
                name: intl.formatMessage({
                  id: "product-filter.actions.add-products.menu.add-bulk-products",
                  defaultMessage: "Add products in bulk",
                }),
              },
              {
                id: AddProductFilterAction.AddProductFilter,
                name: intl.formatMessage({
                  id: "product-filter.actions.add-products.menu.add-product-filter",
                  defaultMessage: "Add product filter",
                }),
              },
            ]}
          />
        </MenuTrigger>
      ) : null}

      {showBulkProductsModal ? (
        <AddBulkProductsModal
          onSave={checkPropertyClash}
          closeModal={() => setShowBulkProductsModal(false)}
          isLoading={isUpdateProductFilterLoading}
        />
      ) : null}

      <PropertyClashConfirmationModal {...propertyClashConfirmationModalState} />
    </>
  );
};

export default ProductFilterAddProductsAction;
