import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox, CheckboxGroup } from "@new-black/lyra";

import { ReducerStateProps } from "../product-filter-modal.types";

type Option = "Negation" | "ExactMatch" | "IncludeMissing";

export function ProductFilterOptions({ dispatch, state }: ReducerStateProps) {
  const intl = useIntl();

  const optionsValue = useMemo<Option[]>(() => {
    const options: Option[] = [];
    if (state.Negation) {
      options.push("Negation");
    }
    if (state.ExactMatch) {
      options.push("ExactMatch");
    }
    if (state.IncludeMissing) {
      options.push("IncludeMissing");
    }
    return options;
  }, [state.ExactMatch, state.IncludeMissing, state.Negation]);

  const onChange = useCallback(
    (newOptions: string[]) => {
      const optionsList = ["Negation", "ExactMatch", "IncludeMissing"] as Option[];

      optionsList.forEach((option) => {
        dispatch({
          type: "SET_OPTION",
          payload: { field: option, checked: newOptions.includes(option) },
        });
      });
    },
    [dispatch],
  );

  return (
    <CheckboxGroup
      onChange={onChange}
      value={optionsValue}
      label={intl.formatMessage({
        id: "generic.label.advanced-settings",
        defaultMessage: "Advanced settings",
      })}
    >
      <Checkbox value="ExactMatch">
        <FormattedMessage
          id="product-filter.list.settings.exact-match"
          defaultMessage="Exact match"
        />
      </Checkbox>
      <Checkbox value="Negation">
        <FormattedMessage id="product-filter.list.settings.negation" defaultMessage="Negation" />
      </Checkbox>
      <Checkbox value="IncludeMissing">
        <FormattedMessage
          id="product-filter.list.settings.include-missing"
          defaultMessage="Include missing"
        />
      </Checkbox>
    </CheckboxGroup>
  );
}
