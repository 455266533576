import { useCallback } from "react";

const useOpenBlobUrlList = () => {
  const openBlobUrlList = useCallback((blobUrls: Array<string | undefined>) => {
    blobUrls.forEach((blobUrl) => {
      if (blobUrl) {
        window.open(blobUrl, "_blank");
      }
    });
  }, []);

  return openBlobUrlList;
};

export default useOpenBlobUrlList;
