import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { discountCampaignsResultSelector, discountCampaignsServiceState } from ".";

const useDiscountCampaigns = () => {
  const isLoading = hooks.useIsLoading({ state: discountCampaignsServiceState.response });
  const discountCampaigns = hooks.useGetState(discountCampaignsResultSelector);

  const isLoadingWithoutResponse = useMemo(
    () => !discountCampaigns && isLoading,
    [discountCampaigns, isLoading],
  );

  return {
    isLoading,
    discountCampaigns,
    isLoadingWithoutResponse,
  };
};

export default useDiscountCampaigns;
