import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

const settingState = builders.buildServiceFamilyState({
  key: "store:setting",
  requireEmployee: true,
  allowEmptyRequest: false,
  service: CoreManagement.GetSetting,
});

export default settingState;
