import { useEffect, useRef } from "react";

type IntervalFunction = () => unknown | void;

/**
 * Executes callback on interval based on given delay.
 * @param callback What to execute on interval.
 * @param delay Defaults to 30 seconds.
 */
function useInterval(callback: IntervalFunction, delay = 30000) {
  const savedCallback = useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current?.();
    }
    const id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export default useInterval;
