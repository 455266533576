import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { CardHeader, TableColumnDef } from "@new-black/lyra";
import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import CustomQuery from "./custom-query";
import { ProductsOverviewTable } from "./products-overview-table";
import { productsListSelector, productsListTotalSelector, productsState } from "./state";
import { ILyraProductsOverview, IMaterialProductsOverview } from "./types";
import useProductsListColumns from "./use-products-list-columns";

const DEFAULT_INCLUDED_FIELDS = [
  "product_id",
  "custom_id",
  "backend_id",
  "display_value",
  "primary_image",
];

/**
 * # ProductsOverview
 * Component thats shows a list of products based on the filters it gets passed.
 * The request to EVA will be done by this component.
 * 
 * ## Customization
 * You can override the `includedFields`, `tableColumns`, or `title` .
 * Pass a `filter` if you want to pre-filter the data.
 * 
 * 
 * ## Add these translations to your translations files:
 * ```json
 * {
    "composite.products-overview.list.title": "Products overview",
    "composite.products-overview.list.header.product": "Product",
    "composite.products-overview.list.header.custom-id": "Custom ID",
    "composite.products-overview.list.header.eva-id": "EVA ID",
    "composite.products-overview.list.search.input.placeholder": "Search product",
    "composite.products-overview.list.search.button": "Search product",
  }
 * ```
 * 
 */
export default function ProductsOverview({
  filter,
  hideTableHeader,
  includedFields = DEFAULT_INCLUDED_FIELDS,
  placeholderImageUrl,
  ...props
}: IMaterialProductsOverview | ILyraProductsOverview) {
  const intl = useIntl();
  const productsList = hooks.useGetState(productsListSelector);
  const productsListTotal = hooks.useGetState(productsListTotalSelector);
  const [query, setQuery] = useState<string | undefined>();
  const [limit, setLimit] = useState(10);
  const [start, setStart] = useState(0);
  const defaultTableColumns = useProductsListColumns(placeholderImageUrl);

  const newRequest = useMemo<EVA.Core.SearchProducts | undefined>(
    () => ({
      Filters: filter,
      Query: query,
      PageSize: limit,
      Page: start / limit + 1,
      IncludedFields: includedFields,
    }),
    [filter, includedFields, limit, query, start],
  );

  const setProductsListRequest = useSetRecoilState(productsState.request);

  const updateQuery = useCallback((value: string | undefined) => {
    setQuery(value);
    setStart(0);
  }, []);

  useEffect(() => {
    setProductsListRequest(newRequest);
  }, [newRequest, setProductsListRequest]);

  if (props.variant === "lyra") {
    return (
      <>
        {hideTableHeader ? null : (
          <CardHeader
            title={
              props.title ??
              intl.formatMessage(
                {
                  id: "generic.label.filtered-products-count",
                  defaultMessage: "Filtered products ({count})",
                },
                { count: productsListTotal ?? 0 },
              )
            }
            actions={<CustomQuery variant="lyra" query={query} updateQuery={updateQuery} />}
          />
        )}
        <div className="max-h-[calc(80vh-5rem)] overflow-y-auto">
          <ProductsOverviewTable
            variant="lyra"
            data={productsList ?? []}
            limit={limit}
            setLimit={setLimit}
            start={start}
            setStart={setStart}
            total={productsListTotal ?? 0}
            columns={
              (props.tableColumns as TableColumnDef<any>[] | undefined) ??
              (defaultTableColumns.lyra as TableColumnDef<any>[])
            }
          />
        </div>
      </>
    );
  }

  return (
    <ProductsOverviewTable
      data={productsList ?? []}
      limit={limit}
      setLimit={setLimit}
      skip={start}
      setSkip={setStart}
      total={productsListTotal ?? 0}
      columns={
        (props.tableColumns as Column<any>[] | undefined) ??
        (defaultTableColumns.material as Column<any>[])
      }
      options={{
        title: props.title,
        hideHeader: hideTableHeader,
      }}
      customActions={<CustomQuery query={query} updateQuery={updateQuery} />}
    />
  );
}
