import { useCallback } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import { Badge, Text } from "@new-black/lyra";

import { IScriptHistoryItem } from "./script-code-editor.types";
import { ScriptEditorProps } from "./script-editor";
import ScriptHistoryButtons from "./script-history-buttons";

type ScriptDiffEditorVersionHeaderProps = Pick<
  ScriptEditorProps,
  | "currentRevisionComment"
  | "hideCurrentRevisionComment"
  | "isHistoryLoading"
  | "onLoadMoreHistory"
  | "scriptHistory"
> & {
  selectedHistoryItem?: IScriptHistoryItem;
  setSelectedHistoryItem: (newValue?: IScriptHistoryItem) => void;
  onSelectHistoryItem: () => void;
};

export const ScriptDiffEditorVersionHeader = ({
  currentRevisionComment,
  hideCurrentRevisionComment,
  isHistoryLoading,
  onLoadMoreHistory,
  onSelectHistoryItem: externalOnSelectHistoryItem,
  scriptHistory,
  selectedHistoryItem,
  setSelectedHistoryItem,
}: ScriptDiffEditorVersionHeaderProps) => {
  const onSelectHistoryItem = useCallback(
    (item?: IScriptHistoryItem) => {
      if (item) {
        if (item.Revision !== scriptHistory?.[0]?.Revision) {
          setSelectedHistoryItem(item);
          externalOnSelectHistoryItem();
        } else {
          setSelectedHistoryItem(undefined);
        }
      }
    },
    [externalOnSelectHistoryItem, scriptHistory, setSelectedHistoryItem],
  );

  return (
    <div className="flex gap-4 py-4">
      <div className="flex flex-1 items-center justify-between gap-2">
        <div className="ml-7 flex flex-col gap-1">
          <Text className="font-medium">
            {selectedHistoryItem ? (
              <FormattedMessage
                id="generic.label.history-version"
                defaultMessage="History version"
              />
            ) : (
              <FormattedMessage
                id="generic.label.current-version"
                defaultMessage="Current version"
              />
            )}
          </Text>

          <div className="h-[0.8125rem]">
            {selectedHistoryItem?.Date ? (
              <Text variant="body-small">
                <FormattedDate
                  value={selectedHistoryItem?.Date}
                  dateStyle="short"
                  timeStyle="short"
                />
              </Text>
            ) : null}
          </div>
        </div>

        <div className="mr-6 flex items-center gap-4">
          <Badge variant="gray">
            <FormattedMessage id="generic.label.read-only" defaultMessage="Read-only" />
          </Badge>

          {(scriptHistory && onSelectHistoryItem) || !hideCurrentRevisionComment ? (
            <ScriptHistoryButtons
              currentRevisionComment={currentRevisionComment}
              onSelectHistoryItem={onSelectHistoryItem}
              selectedHistoryItem={selectedHistoryItem}
              hideCurrentRevisionComment={hideCurrentRevisionComment}
              scriptHistory={scriptHistory}
              isHistoryLoading={isHistoryLoading}
              onLoadMoreHistory={onLoadMoreHistory}
            />
          ) : null}
        </div>
      </div>

      <div className="flex-1">
        <div className="ml-1 flex flex-col gap-1">
          <Text className="font-medium">
            <FormattedMessage
              id="generic.label.editable-version"
              defaultMessage="Editable version"
            />
          </Text>
        </div>
      </div>
    </div>
  );
};
