import { useMemo } from "react";
import { useNavigation } from "react-router-dom";

export const useLoaderOrQueryIsFetching = (isFetching?: boolean) => {
  const { state } = useNavigation();

  const isLoading = useMemo(() => isFetching || state === "loading", [isFetching, state]);

  return isLoading;
};
