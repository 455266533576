import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { useFormikContext } from "formik";
import { isEqual, omit } from "lodash";

import { ProductFilterTable } from "components/suite-composite/product-filter";
import { EvaFilterModel } from "components/suite-composite/product-filter/types";
import ErrorBoundary from "components/suite-ui/error-boundary";
import { IEvaDiscountProductFilter } from "features/discount-edit/edit-discount.types";
import usePrevious from "hooks/suite-react-hooks/use-previous";

export interface IProductSetProductFilter {
  setRef: string;
  productFilters?: IEvaDiscountProductFilter[];
  passive?: boolean;
  productFiltersUpdated: (filters: IEvaDiscountProductFilter[], setRef: string) => void;
}

const ProductSetProductFilter = ({
  passive,
  productFilters,
  productFiltersUpdated,
  setRef,
}: IProductSetProductFilter) => {
  const intl = useIntl();
  const previousFilters = usePrevious(productFilters);

  const { submitForm } = useFormikContext();

  // Submit form whenever product filters change
  useEffect(() => {
    if (!isEqual(previousFilters, productFilters)) {
      submitForm();
    }
  }, [submitForm, previousFilters, productFilters]);

  const filters = useMemo(() => {
    const localFilters: EvaFilterModel = {};

    productFilters?.forEach(
      (filter) =>
        (localFilters[filter.PropertyName] = {
          // Composite component works with Negation property instead of Exclude
          ...omit(filter, "PropertyName", "Exclude"),
          Negation: filter.Exclude,
        }),
    );

    return localFilters;
  }, [productFilters]);

  const handleProductFilters = useCallback(
    (newFilters?: EvaFilterModel): void => {
      const newMappedFilters: IEvaDiscountProductFilter[] = [];

      if (newFilters) {
        // Map to external interface
        const filterKeys = Object.keys(newFilters);
        filterKeys.map((key) => {
          const filterProps = newFilters?.[key];
          const mappedFilter: IEvaDiscountProductFilter = {
            PropertyName: key,
            ExactMatch: !!filterProps?.ExactMatch,
            From: filterProps?.From,
            To: filterProps.To,
            Values: filterProps.Values,
            IncludeMissing: filterProps.IncludeMissing,
            // External interface works with Exclude property instead of Negation
            Exclude: filterProps?.Negation,
          };
          return newMappedFilters.push(mappedFilter);
        });
      }

      // check against previous filters to update only when changes occur
      if (!isEqual(newMappedFilters, previousFilters)) {
        productFiltersUpdated(newMappedFilters, setRef);
      }
    },
    [previousFilters, productFiltersUpdated, setRef],
  );

  return (
    <ErrorBoundary>
      <ProductFilterTable
        disableAdd={passive}
        disableEdit={passive}
        disableDelete={passive}
        productFilter={filters}
        title={intl.formatMessage({
          id: "generic.label.filter-products",
          defaultMessage: "Filter products",
        })}
        updateProductFilter={handleProductFilters}
        tableOptions={{
          backgroundColor: "secondary",
        }}
      />
    </ErrorBoundary>
  );
};

export default ProductSetProductFilter;
