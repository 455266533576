import { Dispatch, SetStateAction, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Column } from "react-table";

import { noop } from "lodash";

import { IInitialRoles } from "components/shared/roles-input/types";
import Table from "components/suite-ui/table";
import { ITableProps } from "components/suite-ui/table/table-types";
import { Checkbox } from "components/ui/checkbox";

export interface ISharedWorkspaceRole extends IInitialRoles {
  isDefault?: boolean;
}

export const SharedWorkspacePermissionsTable = ({
  roles,
  setRoles,
}: {
  roles?: ISharedWorkspaceRole[];
  setRoles: Dispatch<SetStateAction<ISharedWorkspaceRole[] | undefined>>;
}) => {
  const columns = useMemo<Column<ISharedWorkspaceRole>[]>(
    () => [
      {
        accessor: "isDefault",
        Header: <FormattedMessage id="generic.label.is-default" defaultMessage="Is default" />,
        Cell: ({ row }) => (
          <Checkbox
            name={`is-role-default-${row.original.ID}`}
            value={row.original.isDefault ?? false}
            onChange={(newValue) =>
              setRoles((current) =>
                current?.map((role) =>
                  role.ID === row.original.ID ? { ...role, isDefault: newValue } : role,
                ),
              )
            }
          />
        ),
        width: "20%",
      },

      {
        accessor: "Name",
        Header: <FormattedMessage id="generic.label.role" defaultMessage="Role" />,
      },
    ],
    [setRoles],
  );

  const tableConfig = useMemo<ITableProps<ISharedWorkspaceRole>>(
    () => ({
      data: roles ?? [],
      skip: 0,
      setSkip: noop,
      limit: roles?.length ?? 0,
      setLimit: noop,
      total: roles?.length ?? 0,
      columns,
    }),
    [columns, roles],
  );

  return <Table {...tableConfig} options={{ hideHeader: true, hidePagination: true }} />;
};
