import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getOrganizationUnitSetsLoaderQuery,
  serviceQuery: getOrganizationUnitSetsQuery,
  serviceQueryKeys: getOrganizationUnitSetsQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitSetsQuery,
} = createServiceQuery(Core.GetSetsByOrganizationUnit);

export const {
  serviceLoaderQuery: getOrganizationUnitSetDetailsLoaderQuery,
  serviceQuery: getOrganizationUnitSetDetailsQuery,
  serviceQueryKeys: getOrganizationUnitSetDetailsQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitSetDetailsQuery,
} = createServiceQuery(CoreManagement.GetOrganizationUnitSetDetails, true);

export const { serviceLoaderQuery: getOrganizationUnitSetLoaderQuery } = createServiceQuery(
  CoreManagement.GetOrganizationUnitSet,
  true,
);

export const {
  serviceLoaderQuery: getOrganizationUnitSetScopesLoaderQuery,
  serviceQuery: getOrganizationUnitSetScopesQuery,
  serviceQueryKeys: getOrganizationUnitSetScopesQueryKeys,
  useServiceQueryHook: useGetOrganizationUnitSetScopesQuery,
} = createServiceQuery(CoreManagement.GetOrganizationUnitSetScopes);

export const {
  serviceLoaderQuery: listOrganizationUnitSetsLoaderQuery,
  serviceQuery: listOrganizationUnitSetsQuery,
  serviceQueryKeys: listOrganizationUnitSetsQueryKeys,
  useServiceQueryHook: useListOrganizationUnitSetsQuery,
} = createServiceQuery(CoreManagement.ListOrganizationUnitSets, true);
