import {
  getApplicationConfigurationLoaderQuery,
  getAvailableCurrenciesLoaderQuery,
} from "models/application";
import { getAuthorizationStructureLoaderQuery } from "models/auth";
import { adminGetAllModulesLoaderQuery } from "models/modules";
import { getCurrentUserLoaderQuery } from "models/users";
import { ONE_HOUR } from "util/base-values";
import { queryClient } from "util/query-client";

const ROOT_LOADER_QUERY_KEY = "root" as const;

export const getCurrentUserRootLoaderQuery = () => {
  const request = {};
  const loaderQuery = getCurrentUserLoaderQuery(queryClient, request, [ROOT_LOADER_QUERY_KEY], {
    staleTime: ONE_HOUR,
    cacheTime: ONE_HOUR,
  });

  return { request, loaderQuery };
};

export const getAuthorizationStructureRootLoaderQuery = () => {
  const request = {};
  const loaderQuery = getAuthorizationStructureLoaderQuery(
    queryClient,
    request,
    [ROOT_LOADER_QUERY_KEY],
    {
      staleTime: ONE_HOUR,
      cacheTime: ONE_HOUR,
    },
  );

  return { request, loaderQuery };
};

export const getApplicationConfigurationRootLoaderQuery = () => {
  const request = {};
  const loaderQuery = getApplicationConfigurationLoaderQuery(
    queryClient,
    request,
    [ROOT_LOADER_QUERY_KEY],
    {
      staleTime: ONE_HOUR,
      cacheTime: ONE_HOUR,
    },
  );

  return { request, loaderQuery };
};

export const getAvailableCurrenciesRootLoaderQuery = () => {
  const request = {};
  const loaderQuery = getAvailableCurrenciesLoaderQuery(queryClient, request, undefined, {
    staleTime: ONE_HOUR,
    cacheTime: ONE_HOUR,
  });

  return { request, loaderQuery };
};

export const adminGetAllModulesRootLoaderQuery = () => {
  const loaderQuery = adminGetAllModulesLoaderQuery(
    queryClient,
    undefined,
    [ROOT_LOADER_QUERY_KEY],
    {
      staleTime: ONE_HOUR,
      cacheTime: ONE_HOUR,
    },
  );

  return { loaderQuery };
};
