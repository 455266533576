export enum CustomFieldDataType {
  String = 0,
  Bool = 1,
  Integer = 2, // Whole numbers, no fractions
  Decimal = 3,
  Enum = 4,
  Text = 5, // Multiline string
  DateTime = 6,
  Date = 7,
  Blob = 8,
}
