import { ReactNode } from "react";

import { Table, TableProps } from "@new-black/lyra";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";

import useListTableSettings from "./use-list-table-settings";

export interface IProductFilterList {
  customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode;
  tableOptions?: TableProps<any>["options"];
}

const ProductFilterList = ({ customRowActions, tableOptions }: IProductFilterList) => {
  const listTableConfig = useListTableSettings(customRowActions);

  return <Table options={tableOptions} {...listTableConfig} />;
};

export default ProductFilterList;
