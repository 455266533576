import { useMemo } from "react";

import type { SortingStrategy } from "@dnd-kit/sortable";
import {
  horizontalListSortingStrategy,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

const useSortingStrategy = (direction?: "vertical" | "horizontal" | "grid") => {
  const sortingStrategy = useMemo<SortingStrategy>(() => {
    switch (direction) {
      case "vertical": {
        return verticalListSortingStrategy;
      }
      case "horizontal": {
        return horizontalListSortingStrategy;
      }
      default: {
        return rectSortingStrategy;
      }
    }
  }, [direction]);

  return sortingStrategy;
};

export default useSortingStrategy;
