import { useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";

import { Table, TableColumnDef, TableProps } from "@new-black/lyra";

import { LoyaltyProgramStatusBadge } from ".";

type TPickedRequiredTableProps =
  | "data"
  | "onNext"
  | "showNext"
  | "onPrevious"
  | "showPrevious"
  | "limit"
  | "setLimit";

type TRequiredLoyaltyProgramsTableProps = Required<
  Pick<
    TableProps<EVA.Core.Management.ListLoyaltyProgramsResponse.LoyaltyProgramDto>,
    TPickedRequiredTableProps
  >
>;

type TOptionalLoyaltyProgramsTableProps = Pick<
  TableProps<EVA.Core.Management.ListLoyaltyProgramsResponse.LoyaltyProgramDto>,
  "onRowPress"
>;

export const LoyaltyProgramsTable = ({
  data,
  limit,
  onNext,
  onPrevious,
  onRowPress,
  setLimit,
  showNext,
  showPrevious,
}: TRequiredLoyaltyProgramsTableProps & TOptionalLoyaltyProgramsTableProps) => {
  const intl = useIntl();

  const columns = useMemo<
    TableColumnDef<EVA.Core.Management.ListLoyaltyProgramsResponse.LoyaltyProgramDto>[]
  >(
    () => [
      {
        accessorKey: "ID",
        id: "ID",
        header: intl.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
      },

      {
        accessorKey: "Name",
        id: "Name",
        header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
      },

      {
        accessorKey: "BackendID",
        id: "BackendID",
        header: intl.formatMessage({ id: "generic.label.backend-id", defaultMessage: "BackendID" }),
      },

      {
        accessorKey: "Handler",
        id: "Handler",
        header: intl.formatMessage({ id: "generic.label.handler", defaultMessage: "Handler" }),
      },

      {
        accessorKey: "BudgetCurrencyID",
        id: "BudgetCurrencyID",
        header: intl.formatMessage({
          id: "generic.label.currency",
          defaultMessage: "Currency",
        }),
      },

      {
        accessorKey: "StartDate",
        id: "StartDate",
        header: intl.formatMessage({
          id: "generic.label.start-date",
          defaultMessage: "Start date",
        }),
        cell: ({ row }) =>
          row.original.StartDate ? (
            <FormattedDate value={row.original.StartDate} dateStyle="short" />
          ) : (
            ""
          ),
      },

      {
        accessorKey: "EndDate",
        id: "EndDate",
        header: intl.formatMessage({
          id: "generic.label.end-date",
          defaultMessage: "End date",
        }),
        cell: ({ row }) =>
          row.original.EndDate ? (
            <FormattedDate value={row.original.EndDate} dateStyle="short" />
          ) : (
            ""
          ),
      },

      {
        accessorKey: "Status",
        id: "Status",
        header: intl.formatMessage({
          id: "generic.label.status",
          defaultMessage: "Status",
        }),
        cellType: "badge",
        cell: ({ getValue }) => (
          <LoyaltyProgramStatusBadge value={getValue() as number | undefined} />
        ),
      },
    ],
    [intl],
  );

  return (
    <Table
      data={data}
      onNext={onNext}
      showNext={showNext}
      onPrevious={onPrevious}
      showPrevious={showPrevious}
      limit={limit}
      setLimit={setLimit}
      aria-label={intl.formatMessage({
        id: "generic.label.loyalty-programs",
        defaultMessage: "Loyalty programs",
      })}
      columns={columns}
      onRowPress={onRowPress}
    />
  );
};
