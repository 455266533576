import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";

import TitleHelmetContext from "./provider";

interface ITitleHelmet {
  title: string;
}

export const TitleHelmet = ({ title }: ITitleHelmet) => {
  const { moduleName } = useContext(TitleHelmetContext);

  useEffect(() => {
    if (moduleName === "unset") {
      console.error(
        "You need to set up a TitleHelmetContextProvider before using the TitleHelmet component, " +
          "where you specify the module name (e.g. " +
          "<TitleHelmetContextProvider moduleName='EVA Suite Orders'> ... </TitleHelmetContextProvider>`)",
      );
    }
  }, [moduleName]);

  return (
    <Helmet titleTemplate={`%s | ${moduleName}`}>
      <title>{title}</title>
    </Helmet>
  );
};
