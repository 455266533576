import { FormattedNumber, IntlShape } from "react-intl";

// There is an issue with the Intl API and the Albanian Lek currency (ALL).
// The API returns the wrong number of decimal digits for this currency.
// We added a workaround for this issue in the `formatAmount` function and the `Amount` component.

export interface AmountProps {
  /** Currency Code ISO 4217 format */
  currency?: string;
  /** Amount value */
  value?: number;
  /** Use this if you want a compact notation (e.g. `1K`, `1.1M` etc.) */
  compactNotation?: boolean;
}

/**
 * This function is used to format an amount value with a currency.
 */
const formatAmount =
  (intl: IntlShape) =>
  ({ compactNotation, currency, value }: AmountProps) =>
    currency
      ? intl.formatNumber(value ?? 0, {
          currency,
          style: "currency",
          currencyDisplay: "narrowSymbol",
          notation: compactNotation ? "compact" : undefined,
          minimumFractionDigits: currency === "ALL" ? 2 : undefined,
        })
      : undefined;

/**
 * This component is used to format an amount value with a currency.
 */
const Amount = ({ compactNotation, currency, value }: AmountProps) =>
  currency ? (
    <FormattedNumber
      style="currency"
      value={value ?? 0}
      currency={currency}
      currencyDisplay="narrowSymbol"
      notation={compactNotation ? "compact" : undefined}
      minimumFractionDigits={currency === "ALL" ? 2 : undefined}
    />
  ) : null;

Amount.formatAmount = formatAmount;

export default Amount;
