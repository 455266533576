import { useState } from "react";

import { Separator } from "@new-black/lyra";

import { IProductSetFilter } from "components/suite-composite/product-set-modal/types";

import { SidePaneProductsFilterHeader } from "./side-pane-products-filter-header";
import { SidePaneProductsFilterProductsSummaries } from "./side-pane-products-filter-products-summaries";
import { SidePaneProductsModal } from "./side-pane-products-modal";

export type SidePaneProductsFilterProps = {
  productsIDs?: number[];
  setProductsIDs: (newValues?: number[]) => void;
  isDisabled?: boolean;
};

export const SidePaneProductsFilter = (props: SidePaneProductsFilterProps) => {
  const { productsIDs, setProductsIDs } = props;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [initialQuerySearchValue, setInitialQuerySearchValue] = useState<string | undefined>();
  const [initialFilters, setInitialFilters] = useState<IProductSetFilter[] | undefined>();

  return (
    <>
      <div className="flex flex-col">
        <SidePaneProductsFilterHeader {...props} onActionButtonClick={() => setIsModalOpen(true)} />

        {productsIDs ? (
          <div className="max-h-[375px] overflow-auto">
            <Separator />

            <SidePaneProductsFilterProductsSummaries productsIDs={productsIDs} />
          </div>
        ) : null}
      </div>

      <SidePaneProductsModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
        setProductsIDs={setProductsIDs}
        productsIDs={productsIDs}
        initialFilters={initialFilters}
        setInitialFilters={setInitialFilters}
        initialQuerySearchValue={initialQuerySearchValue}
        setInitialQuerySearchValue={setInitialQuerySearchValue}
      />
    </>
  );
};
