import { useCallback } from "react";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

import useCallService from "hooks/suite-react-hooks/use-call-service";

import { entityTranslationsState } from "../state";
import { EntityTranslationFieldIdentifiers, EntityTranslationFieldUpdating } from "../types";

import useUpdateEntityTranslationWithoutInitialCountryId from "./use-update-entity-translation-without-initial-country-id";

const useManageEntityTranslations = ({
  entityField,
  entityID,
  entityType,
  translations,
}: EntityTranslationFieldIdentifiers & { translations?: EntityTranslationFieldUpdating }) => {
  const intl = useIntl();
  const setListEntitiesResponseStale = hooks.useSetStale({
    staleState: entityTranslationsState.stale({
      entityType,
      entityID,
      entityField,
    }),
  });
  const {
    callService: replaceEntityTranslationService,
    serviceLoading: replaceEntityTranslationLoading,
  } = useCallService({
    service: Core.ReplaceEntityTranslation,
    options: { onSuccess: () => setListEntitiesResponseStale() },
    messages: {
      successMessage:
        translations?.successfullyCreatedUpdated ??
        intl.formatMessage({
          id: "generic.label.entity-translation.create-updated.success",
          defaultMessage: "Successfully updated/created entity translation",
        }),
      loadingMessage:
        translations?.loadingCreateUpdate ??
        intl.formatMessage({
          id: "generic.label.entity-translation.create-update.loading",
          defaultMessage: "Updating/creating entity translation...",
        }),
    },
  });

  const replaceOrCreateEntityTranslation = useCallback(
    (countryID: string, languageID: string, value: string) => {
      replaceEntityTranslationService({
        EntityField: entityField,
        EntityID: entityID,
        EntityType: entityType,
        CountryID: countryID,
        LanguageID: languageID,
        Value: value,
      });
    },
    [entityField, entityID, entityType, replaceEntityTranslationService],
  );

  const {
    callService: deleteEntityTranslationService,
    serviceLoading: deleteEntityTranslationLoading,
  } = useCallService({
    service: Core.DeleteEntityTranslation,
    options: { onSuccess: () => setListEntitiesResponseStale() },
    messages: {
      successMessage:
        translations?.successfullyDeleted ??
        intl.formatMessage({
          id: "generic.label.entity-translation.delete.success",
          defaultMessage: "Entity translation successfully deleted",
        }),
      loadingMessage:
        translations?.loadingDelete ??
        intl.formatMessage({
          id: "generic.label.entity-translation.delete.loading",
          defaultMessage: "Deleting entity translation...",
        }),
    },
  });

  const deleteEntityTranslation = useCallback(
    (countryID: string, languageID: string) => {
      deleteEntityTranslationService({
        EntityField: entityField,
        EntityID: entityID,
        EntityType: entityType,
        CountryID: countryID,
        LanguageID: languageID,
      });
    },
    [deleteEntityTranslationService, entityField, entityID, entityType],
  );

  const {
    isLoading: updateEntityTranslationWithoutInitialCountryIdLoading,
    updateEntityTranslationWithoutInitialCountryId,
  } = useUpdateEntityTranslationWithoutInitialCountryId({
    entityField,
    entityID,
    entityType,
    translations,
  });

  return {
    replaceOrCreateEntityTranslation,
    replaceEntityTranslationLoading,
    deleteEntityTranslation,
    deleteEntityTranslationLoading,
    updateEntityTranslationWithoutInitialCountryId,
    updateEntityTranslationWithoutInitialCountryIdLoading,
  };
};

export default useManageEntityTranslations;
