import { Dispatch } from "react";

import { z } from "zod";
import { zfd } from "zod-form-data";

import { ProductFilterAction, ProductFilterState } from "./product-filter-modal.reducer";

export interface ProductFilterModalMessages {
  title?: string;
  cancel?: string;
  save?: string;
}

export interface EVAFilterModel extends EVA.Core.FilterModel {
  PropertyName?: string;
}

export interface FilterModelOptions {
  Negation?: boolean;
  ExactMatch?: boolean;
  IncludeMissing?: boolean;
}

interface FilterModelBase extends FilterModelOptions {
  PropertyName?: string;
}

interface ValuesFilterModel extends FilterModelBase {
  type: "values";
  Values?: any[];
  From?: never;
  To?: never;
}

interface RangeFilterModel extends FilterModelBase {
  type: "range";
  Values?: never;
  From?: string;
  To?: string;
}

export type FilterModel = ValuesFilterModel | RangeFilterModel;

export type FilterModelErrors = Partial<Record<keyof FilterModel, string>>;

export interface IProductFilterValue extends EVA.Core.FilterModel {
  propertyName?: string;
}

export interface ReducerStateProps {
  state: ProductFilterState;
  dispatch: Dispatch<ProductFilterAction>;
}

// validation schemas
const commonSchema = z.object({
  PropertyName: z.string(),
});

export const valuesSchema = commonSchema.extend({
  Values: z.array(z.string()).min(1),
});

export const rangeSchema = commonSchema.extend({
  From: z.string(),
  To: z.string(),
});

export const filterOptionsSchema = z.object({
  Negation: zfd.checkbox(),
  ExactMatch: zfd.checkbox(),
  IncludeMissing: zfd.checkbox(),
});
