import { PIM } from "@springtree/eva-services-pim";

import { listRevisionsQuery } from "models/products";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

import { IRevisionSearchListFieldItem } from "./types";

export const generateRevisionSearchListField = (filters?: Partial<EVA.PIM.ListRevisionsFilter>) =>
  SearchListFieldGenerator<PIM.ListRevisions, IRevisionSearchListFieldItem, number>({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listRevisionsQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: filters,
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? item.ID?.toString() ?? "",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((revision) => ({ ID: revision.ID, Name: revision.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.revision",
      defaultMessage: "Revision",
    }),
  });
