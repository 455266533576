import { useEffect } from "react";

import { useSetRecoilState } from "recoil";

import { showOrganizationUnitSelectorAtom } from "../organization-unit-selector.state";
import { IOrganizationUnitStructure } from "../organization-unit-selector.types";

import { useSelectedOrganizationUnit } from "./use-selected-organization-unit";

export interface IUseOrganizationUnitSelector {
  /** Use this to set whether or not the selector will hide itself after your component unmounts. Defaults to true.  */
  hideOnUnamount?: boolean;
  /** Use this to set whether or not to reset the selected organization unit after your component unmounts. Defaults to false. */
  resetOnUnmount?: boolean;
  /** Use this if you need to do something else when selected organization unit changes */
  onSelectedOrganizationUnitChanged?: (newOrganizationUnit?: IOrganizationUnitStructure) => void;
}

/**
 * Enables the organization unit selector dropdown
 */
export const useOrganizationUnitSelector = ({
  hideOnUnamount = true,
  onSelectedOrganizationUnitChanged,
  resetOnUnmount = false,
}: IUseOrganizationUnitSelector) => {
  const setShowOrganizationUnitSelector = useSetRecoilState(showOrganizationUnitSelectorAtom);
  const { previousSelectedOrganizationUnit, resetSelectedOrganization, selectedOrganizationUnit } =
    useSelectedOrganizationUnit();

  useEffect(() => {
    if (
      previousSelectedOrganizationUnit &&
      selectedOrganizationUnit !== previousSelectedOrganizationUnit
    ) {
      onSelectedOrganizationUnitChanged?.(selectedOrganizationUnit);
    }
  }, [
    onSelectedOrganizationUnitChanged,
    previousSelectedOrganizationUnit,
    selectedOrganizationUnit,
  ]);

  useEffect(() => {
    setShowOrganizationUnitSelector(true);

    if (hideOnUnamount) {
      return () => {
        if (hideOnUnamount) {
          setShowOrganizationUnitSelector(false);
        }
      };
    }

    return undefined;
  }, [hideOnUnamount, setShowOrganizationUnitSelector]);

  useEffect(
    () => (resetOnUnmount ? resetSelectedOrganization : undefined),
    [resetOnUnmount, resetSelectedOrganization],
  );
};
