import { useId } from "react";
import { FormattedMessage } from "react-intl";

import { ListItemIcon, ListItemSecondaryAction, MenuItem } from "@material-ui/core";
import { Radio, RadioGroup } from "@new-black/lyra";
import classNames from "classnames";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import Text from "components/suite-ui/text";

import { UnsavedLabel } from "../menu-workspace-selector";

interface IWorkspaceSelectorItemProps {
  label: string;
  isSelected: boolean;
  onClick: () => void;
  onDelete?: () => void;
  hasUnsavedChanges?: boolean;
  isCurrentWorkspace?: boolean;
}

const WorkspaceSelectorItem = ({
  hasUnsavedChanges,
  isCurrentWorkspace,
  isSelected,
  label,
  onClick,
  onDelete,
}: IWorkspaceSelectorItemProps) => {
  const selectorItemID = useId();

  return (
    <div className="group">
      <MenuItem className="flex h-[56px] w-full items-center" component="div" onClick={onClick}>
        <ListItemIcon
          className={classNames(
            "min-w-0 pr-3",
            isSelected
              ? "text-[color:var(--legacy-eva-color-primary)]"
              : "text-[color:var(--legacy-eva-color-dark-2,_#666666)]",
          )}
        >
          <RadioGroup
            onChange={onClick}
            label=""
            hideInputHeader
            value={isSelected ? selectorItemID : ""}
          >
            <Radio value={selectorItemID} />
          </RadioGroup>
        </ListItemIcon>

        <div
          className={classNames(
            "flex w-full items-center justify-between gap-3 overflow-hidden",
            onDelete && "mr-3",
          )}
        >
          <span className="truncate">{label}</span>

          <div className="flex items-center gap-3">
            {isCurrentWorkspace ? (
              <Text variant="body2">
                <FormattedMessage
                  id="generic.label.current-workspace"
                  defaultMessage="Current workspace"
                />
              </Text>
            ) : null}

            {hasUnsavedChanges ? <UnsavedLabel className="w-1/2" /> : null}
          </div>
        </div>

        {onDelete ? (
          <ListItemSecondaryAction>
            <DeleteActionButton onPress={onDelete} />
          </ListItemSecondaryAction>
        ) : null}
      </MenuItem>
    </div>
  );
};

export default WorkspaceSelectorItem;
