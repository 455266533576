export enum ZonedCycleCountResultStatus {
  None = 0,
  /**
   * The results of the PreCounts matched the expected stock, so no stockmutations were created
   */
  Correct = 1,
  /**
   * The cyclecount results were discarded and a new one is created
   */
  RecountRequested = 2,
  /**
   * The results of the PreCounts are modified during finalizing
   */
  Modified = 4,
  /**
   * The finalizing resulted in at least one stockmutation
   */
  Deviated = 8,
}
