import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { TAllowedFunctionalities } from "hooks/suite-react-hooks/use-allowed-functionalities";

import { IWorkspaceSelectorItem, IWorkspaceSelectorStrings } from "./workspace-selector";
import WorkspaceSelectorActionItem from "./workspace-selector-action-item";

interface IWorkspaceSelectorItemActions {
  onReset?: () => void;
  onResetToDefault?: () => void;
  onSave?: (workspace: IWorkspaceSelectorItem) => void;
  onSaveAsNewWorkspace?: (workspace: IWorkspaceSelectorItem) => void;
  onAdd?: () => void;
  onAddShared?: () => void;
  onEditSharePermissions?: (workspace: IWorkspaceSelectorItem) => void;
  onDelete?: () => void;
  hasUnsavedChanges?: boolean;
  workspaceItem: IWorkspaceSelectorItem;
  sharedWorkspaceFunctionality?: TAllowedFunctionalities;
  selectorStrings?: IWorkspaceSelectorStrings;
}

export const WorkspaceSelectorItemActions = ({
  hasUnsavedChanges,
  onAdd,
  onAddShared,
  onDelete,
  onEditSharePermissions,
  onReset,
  onResetToDefault,
  onSave,
  onSaveAsNewWorkspace,
  selectorStrings,
  sharedWorkspaceFunctionality,
  workspaceItem,
}: IWorkspaceSelectorItemActions) => {
  const intl = useIntl();

  return onReset ||
    onResetToDefault ||
    onSave ||
    onSaveAsNewWorkspace ||
    onDelete ||
    onEditSharePermissions ||
    onAdd ||
    onAddShared ? (
    <div className="flex flex-col gap-1 px-4">
      {onSave ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.saveWorkspaceActionLabel ??
            intl.formatMessage({
              id: "generic.label.save-changes-to-this-workspace",
              defaultMessage: "Save changes to this workspace",
            })
          }
          disabled={!hasUnsavedChanges}
          onClick={() => onSave(workspaceItem)}
          icon={<SvgIcon name="alert-success" className="h-5 w-5 pl-1" />}
        />
      ) : null}

      {onSaveAsNewWorkspace ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.newWorkspaceActionLabel ??
            intl.formatMessage({
              id: "generic.label.save-as-new-workspace",
              defaultMessage: "Save as new workspace",
            })
          }
          onClick={() => onSaveAsNewWorkspace(workspaceItem)}
          icon={<SvgIcon name="plus" className="h-5 w-5 pl-1" />}
        />
      ) : null}

      {onReset ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.resetWorkspaceActionLabel ??
            intl.formatMessage({
              id: "generic.label.reset-to-saved-layout",
              defaultMessage: "Reset to saved layout",
            })
          }
          onClick={onReset}
          icon={<SvgIcon name="retry" className="h-5 w-5 pl-1" />}
          disabled={!hasUnsavedChanges}
        />
      ) : null}

      {onResetToDefault ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.resetToDefaultWorkspaceActionLabel ??
            intl.formatMessage({
              id: "generic.label.reset-to-default-workspace",
              defaultMessage: "Reset to default workspace",
            })
          }
          onClick={onResetToDefault}
          icon={<SvgIcon name="retry" className="h-5 w-5 pl-1" />}
        />
      ) : null}

      {!workspaceItem?.IsShared &&
      onAddShared &&
      sharedWorkspaceFunctionality?.createFunctionalityAllowed ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.newWorkspaceActionLabel ??
            intl.formatMessage({
              id: "generic.label.create-shared-workspace-from-this-layout",
              defaultMessage: "Create shared workspace from this layout",
            })
          }
          onClick={onAddShared}
          icon={<SvgIcon name="employees" className="h-5 w-5 pl-1" />}
        />
      ) : null}

      {workspaceItem?.IsShared &&
      onEditSharePermissions &&
      sharedWorkspaceFunctionality?.editFunctionalityAllowed ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.editWorkspacePermissionsActionLabel ??
            intl.formatMessage({
              id: "generic.label.edit-share-permissions",
              defaultMessage: "Edit share permissions",
            })
          }
          onClick={() => onEditSharePermissions(workspaceItem)}
          icon={<SvgIcon name="employees" className="h-5 w-5 pl-1" />}
        />
      ) : null}

      {onDelete ? (
        <WorkspaceSelectorActionItem
          label={
            selectorStrings?.editWorkspacePermissionsActionLabel ??
            intl.formatMessage({
              id: "generic.label.delete-workspace",
              defaultMessage: "Delete workspace",
            })
          }
          onClick={onDelete}
          icon={<SvgIcon name="delete" className="h-5 w-5 pl-1" />}
        />
      ) : null}
    </div>
  ) : null;
};
