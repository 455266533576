import { useCallback, useState } from "react";

import { PDF } from "@springtree/eva-services-pdf";

import useCallService from "./suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "./suite-react-hooks/use-handle-error-response";

const useMergePdfBlobs = () => {
  const [isMergeLoading, setIsMergeLoading] = useState(false);
  const handleError = useHandleErrorResponse();

  const { callService: mergePdfBlobsMutation } = useCallService({
    service: PDF.MergePdfBlobs,
    options: {
      onError: (error) => {
        setIsMergeLoading(false);
        if (error) {
          handleError(error, "PDF.MergePdfBlobs");
        }
      },
      onSuccess: () => {
        setIsMergeLoading(false);
      },
    },
    disableNotifications: true,
  });

  const mergePdfBlobs = useCallback(
    (blobIDs: string[]) => {
      setIsMergeLoading(true);
      return mergePdfBlobsMutation({ Blobs: blobIDs });
    },
    [mergePdfBlobsMutation],
  );

  return { mergePdfBlobs, isMergeLoading };
};

export default useMergePdfBlobs;
