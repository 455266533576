import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { LyraProductOverviewModal } from "../lyra-product-overview-modal";
import { useProductFilterContext } from "../product-filter-context";

export const LyraProductOverviewAction = () => {
  const intl = useIntl();

  const { productFilter, setOpenProductOverviewModal } = useProductFilterContext();

  const disableProductOverviewButton = useMemo(() => {
    const filters = productFilter ? Object.keys(productFilter) : [];

    return !filters?.length;
  }, [productFilter]);

  return (
    <>
      <Button
        variant="icon"
        isDisabled={disableProductOverviewButton}
        onPress={() => setOpenProductOverviewModal(true)}
        tooltip={intl.formatMessage({
          id: "product-filter.actions.show-selected-products",
          defaultMessage: "Show selected products",
        })}
      >
        <SvgIcon name="preview" />
      </Button>

      <LyraProductOverviewModal />
    </>
  );
};
