import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

const FAMILY_KEY_PREFIX = "Composite:UseCustomFieldByID";

const customFieldByIDServiceState = builders.buildServiceFamilyState({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.GetCustomFieldByID`,
  service: CoreManagement.GetCustomFieldByID,
  allowEmptyRequest: false,
  requireEmployee: true,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.ID === parameter) {
      return req;
    }
    return { ...req, ID: parameter };
  },
});

const customFieldByIDResponseSelector = selectorFamily({
  key: `${FAMILY_KEY_PREFIX}:CoreManagement.GetCustomFieldByID:response`,
  get:
    (param) =>
    ({ get }) =>
      get(customFieldByIDServiceState.response(param)),
});

export const customFieldByIDService = {
  serviceState: customFieldByIDServiceState,
  response: customFieldByIDResponseSelector,
};
