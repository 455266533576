import { useEffect } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import type { IServiceFamilyState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-family-state";
import type { IServiceState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-state";
import type { IEvaServiceDefinition } from "@springtree/eva-services-core";
import type { SerializableParam } from "recoil";

export const useHandleSWR = <SVC extends IEvaServiceDefinition>(
  serviceState: IServiceState<SVC>,
) => {
  const refetch = hooks.useSetStale({ staleState: serviceState.stale });
  const loading = hooks.useIsLoading({ state: serviceState.responseRaw });

  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export const useHandleSWRFamily = <SVC extends IEvaServiceDefinition>(
  serviceState: IServiceFamilyState<SVC>,
  parameter: SerializableParam,
) => {
  const refetch = hooks.useSetStale({
    staleState: serviceState.stale(parameter),
  });
  const loading = hooks.useIsLoading({
    state: serviceState.responseRaw(parameter),
  });

  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
