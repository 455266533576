import { useMemo } from "react";

import { ModuleIconTile } from "./module-icon-tile";
import { useChapterFinderData } from "./use-chapter-finder-data";

export const ModuleGrid = ({ onModuleIconClick }: { onModuleIconClick?: () => void }) => {
  const modules = useChapterFinderData();

  const filteredModules = useMemo(
    () => modules.filter((module) => module.chapters.length >= 1),
    [modules],
  );

  return (
    <div className="grid grid-cols-2 items-start justify-center justify-items-center gap-x-3 gap-y-10 sm:grid-cols-3 md:grid-cols-4">
      {filteredModules.map((module) => (
        <div className="inline-flex flex-shrink justify-center" key={module.path}>
          <ModuleIconTile
            orientation="vertical"
            icon={module.icon}
            label={module.title}
            to={`/${module.path}${module.chapters[0].path}`}
            onClick={onModuleIconClick}
          />
        </div>
      ))}
    </div>
  );
};

ModuleGrid.defaultProps = {
  offsetColor: "neutral-5",
};
