import type { SvgIconProps } from "@material-ui/core";

import StyledSvgIcon from "./styled-svg-icon";

const InformationIcon = (props: SvgIconProps) => (
  <StyledSvgIcon width="24px" height="24px" {...props}>
    <path
      // eslint-disable-next-line max-len
      d="m 10.9 6.5 h 2.2 v 2.2 h -2.2 v -2.2 z m 0 4.4 h 2.2 v 6.6 h -2.2 v -6.6 z m 1.1 -9.9 c -6.072 0 -11 4.928 -11 11 c 0 6.072 4.928 11 11 11 c 6.072 0 11 -4.928 11 -11 c 0 -6.072 -4.928 -11 -11 -11 z m 0 19.8 c -4.851 0 -8.8 -3.949 -8.8 -8.8 c 0 -4.851 3.949 -8.8 8.8 -8.8 c 4.851 0 8.8 3.949 8.8 8.8 c 0 4.851 -3.949 8.8 -8.8 8.8 z"
    />
  </StyledSvgIcon>
);

export default InformationIcon;
