import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Button, Popover, PopoverTrigger, SvgIcon, Text } from "@new-black/lyra";
import { clsx } from "clsx";

export type TruncatedTextWithPopoverProps = {
  value: string;
  usedInTable?: boolean;
  maxLength?: number;
  title?: string;
};

/**
 * TruncatedTextWithPopover Component
 *
 * The TruncatedTextWithPopover component is used to display a text value that may be truncated if it exceeds a specified maximum length.
 * If the text is truncated, a popover button is displayed, allowing users to view the full text in a popover.
 *
 * @param {TruncatedTextWithPopoverProps} props - Component props.
 * @returns {JSX.Element} The rendered component.
 */
export const TruncatedTextWithPopover = ({
  maxLength = 48,
  title,
  usedInTable = true,
  value,
}: TruncatedTextWithPopoverProps): JSX.Element => {
  const intl = useIntl();
  const [textRef, setTextRef] = useState<HTMLElement | null>(null);

  const shouldShowPopover = useMemo(() => {
    return textRef && textRef.scrollWidth > textRef.clientWidth;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textRef, maxLength, value]);

  return (
    <div className="flex gap-2">
      <Text className="truncate" style={{ maxWidth: `${maxLength}ch` }} ref={setTextRef}>
        {value}
      </Text>
      {shouldShowPopover ? (
        <div className="ms-2">
          <PopoverTrigger>
            <div className={clsx(usedInTable && "-my-2")}>
              <Button
                variant="icon"
                tooltip={intl.formatMessage({
                  id: "generic.label.view",
                  defaultMessage: "View",
                })}
              >
                <SvgIcon name="password-visible" />
              </Button>
            </div>
            <Popover>
              <Text variant="heading-2">{title}</Text>
              <Text>{value}</Text>
            </Popover>
          </PopoverTrigger>
        </div>
      ) : null}
    </div>
  );
};
