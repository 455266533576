import { cva } from "class-variance-authority";

import { NavLink } from "components/routing";

import CountIndicator from "../count-indicator";

import { IMenuRoute } from "./menu.types";

export const menuItemStyle = cva(
  [
    "no-underline",
    "relative",
    "p-2",
    "rounded-md",
    "min-w-[32px]",
    "h-8",
    "w-full",
    "select-none",
    "font-normal",
    "font-sans",
    "text-base",
    "transition",
    "duration-200",
    "group",
    "focus-visible:shadow-highlight",
    "focus-visible:border",
    "focus-visible:border-focus",
    "focus-visible:border-solid",
  ],
  {
    variants: {
      active: {
        true: ["bg-action-default", "text-inverted"],
        false: ["hover:bg-overlay-subtle", "active:bg-overlay-medium", "text-primary"],
      },
    },
  },
);

export const MenuItemLink = ({
  count,
  icon,
  label,
  menuState: navbarState,
  routeDefinition,
  setFocused,
  setMouseEntered,
}: IMenuRoute) => {
  return (
    <NavLink
      className={({ isActive }) => menuItemStyle({ active: isActive })}
      to={routeDefinition.path}
      onMouseEnter={() => {
        if (navbarState === "closed") {
          setMouseEntered(true);
        }
      }}
      onMouseLeave={() => {
        // If navbarState is closed, we make sure that mouse entered also is set back to false.
        // This handles the case where the user hovers an link for a brief moment and then moves the mouse away. We don't want to expand the sidebar in this case.
        if (navbarState === "closed") {
          setMouseEntered(false);
        }
      }}
      onPointerEnter={() => {
        setMouseEntered(true);
      }}
      onPointerLeave={() => {
        setMouseEntered(false);
      }}
      onFocus={() => {
        setFocused(true);
      }}
      onBlur={() => {
        setFocused(false);
      }}
      onClick={() => {
        setMouseEntered(false);
        setFocused(false);
      }}
    >
      {() => (
        <div className="flex items-center">
          <div className="text-current relative flex items-center justify-center">
            <CountIndicator count={count} />
            {icon}
          </div>
          <span className="truncate pl-2">{label}</span>
        </div>
      )}
    </NavLink>
  );
};
