import { IntlShape } from "react-intl";

import { OrganizationUnitStatus } from "types/eva-core";

export const getOrganizationUnitStatusTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.OrganizationUnitStatus, string> = {
    [OrganizationUnitStatus.Default]: intl.formatMessage({
      id: "generic.label.default",
      defaultMessage: "Default",
    }),
    [OrganizationUnitStatus.Open]: intl.formatMessage({
      id: "generic.label.open",
      defaultMessage: "Open",
    }),
    [OrganizationUnitStatus.Closed]: intl.formatMessage({
      id: "generic.label.closed",
      defaultMessage: "Closed",
    }),
    [OrganizationUnitStatus.OpeningSoon]: intl.formatMessage({
      id: "generic.label.opening-soon",
      defaultMessage: "Opening soon",
    }),
    [OrganizationUnitStatus.TemporarilyClosed]: intl.formatMessage({
      id: "generic.label.temporarily-closed",
      defaultMessage: "Temporarily closed",
    }),
    [OrganizationUnitStatus.Sale]: intl.formatMessage({
      id: "generic.label.sale",
      defaultMessage: "Sale",
    }),
    [OrganizationUnitStatus.Hidden]: intl.formatMessage({
      id: "generic.label.hidden",
      defaultMessage: "Hidden",
    }),
  };

  return (status: EVA.Core.OrganizationUnitStatus) => {
    return translationMap[status];
  };
};
