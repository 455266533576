/* eslint-disable no-useless-escape */
import { ScriptEditorLanguage } from "types/monaco-editor-language";

// Function to get the tokens for the Script dialect editor. We pass the dialect and keywords as parameters
// to allow for making changes to the syntax highlighting based on dialect.
export const getScriptConfigTokens = (
  dialect: ScriptEditorLanguage,
  keywords: string[] = [],
): any => {
  return {
    keywords,

    typeKeywords: [],

    parenFollows: ["if", "for"],

    operators: ["=", ">", "<", "!", "~", "?", ":", "==", "<=", ">=", "<>", "+", "-", "*", "/"],

    // we include these common regular expressions
    symbols: /[=><!~?:&|+\-*\/\^%]+/,

    // C# style strings
    escapes: /\\(?:[abfnrtv\\"']|x[0-9A-Fa-f]{1,4}|u[0-9A-Fa-f]{4}|U[0-9A-Fa-f]{8})/,

    // The main tokenizer for our languages
    tokenizer: {
      root: [
        [/\[[\w\.]+\]/, "variable"],

        // identifiers and keywords
        [
          /[a-z_$][\w$]*/,
          {
            cases: {
              "@typeKeywords": "keyword",
              "@keywords": "keyword",
              "@default": "identifier",
            },
          },
        ],
        [/[A-Z][\w\$]*/, "type.identifier"], // to show class names nicely

        // whitespace
        { include: "@whitespace" },

        // delimiters and operators
        [/[{}()\[\]]/, "@brackets"],
        [/[<>](?!@symbols)/, "@brackets"],
        [
          /@symbols/,
          {
            cases: {
              "@operators": "operator",
              "@default": "",
            },
          },
        ],

        // numbers
        [/\d*\.\d+([eE][\-+]?\d+)?/, "number.float"],
        [/\d+/, "number"],

        // delimiter: after number because of .\d floats
        [/[;,.]/, "delimiter"],

        // strings
        [/'([^'\\]|\\.)*$/, "string.invalid"], // non-teminated string
        [/'/, { token: "string.quote", bracket: "@open", next: "@string" }],
      ],

      string: [
        [/[^\\']+/, "string"],
        [/@escapes/, "string.escape"],
        [/\\./, "string.escape.invalid"],
        [/'/, { token: "string.quote", bracket: "@close", next: "@pop" }],
      ],

      whitespace: [
        [/[ \t\r\n]+/, "white"],
        [/#.*$/, "comment"],
      ],
    },
  };
};
