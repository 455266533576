import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import { toast } from "components/suite-ui/toast";

import { createEVAService } from "./http";
import { intlAccessor } from "./intl-accessor";
import { IFunctionalUseCallServiceMessages, IUseCallServiceMessages, MutateParams } from "./mutate";

type TMessages<SVC extends IEvaServiceDefinition> =
  | IUseCallServiceMessages
  | IFunctionalUseCallServiceMessages<SVC>
  | undefined;

const loadingMessage = <SVC extends IEvaServiceDefinition>(messages?: TMessages<SVC>) =>
  messages?.loadingMessage ??
  intlAccessor.formatMessage({ id: "generic.label.loading", defaultMessage: "Loading..." });

const successMessage = <SVC extends IEvaServiceDefinition>(
  response: SVC["response"],
  messages?: TMessages<SVC>,
) =>
  (messages?.successMessage && typeof messages?.successMessage === "function"
    ? messages?.successMessage(response)
    : messages?.successMessage) ??
  intlAccessor.formatMessage({
    id: "generic.message.action-success",
    defaultMessage: "Action successfully executed.",
  });

export const mutatePromiseLike =
  <SVC extends IEvaServiceDefinition>({
    disabledNotifications,
    disableErrorNotification,
    messages,
    onError,
    onSuccess,
    service,
  }: MutateParams<SVC>) =>
  async (request: SVC["request"], options?: IEvaServiceCallOptions, throwOnError?: boolean) => {
    const evaService = createEVAService(service);
    let toastID: string | undefined;

    try {
      if (!disabledNotifications) {
        toastID = toast.loading(loadingMessage(messages));
      }

      const response = await evaService.call(
        request,
        options,
        throwOnError ?? true,
        disableErrorNotification ?? disabledNotifications,
      );

      await onSuccess?.(response, request);

      if (!disabledNotifications) {
        toast.success(successMessage(response), { id: toastID });
      }

      return Promise.resolve({ success: true, response });
    } catch (error) {
      console.error(`[Error: ${service?.name}]`, error);

      toast.dismiss(toastID);

      onError?.(error);

      if (throwOnError) {
        throw error;
      }

      return Promise.reject({ success: false, response: error as SVC["response"] });
    }
  };

export type MutatePromiseLikeResult<SVC extends IEvaServiceDefinition> = Awaited<
  ReturnType<ReturnType<typeof mutatePromiseLike<SVC>>>
>;
