import { ReactNode, useEffect, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { isNil } from "lodash";

import { useNavigate } from "components/routing";
import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "types/functionalities";

import Text from "../../suite-ui/text";

export interface IAuthorizedTranslations {
  loadingTranslationKey?: TranslationKey;
}

export interface IAuthorizedProps {
  /** The functionality that should be checked */
  functionality: Functionalities;
  /** The functionality scope that should be checked */
  scope?: FunctionalityScope;
  /** Optional organization unit to use when checking the functionality. */
  organizationUnitID?: number;
  /** Will disable redirecting and simply not render anything.
   *  Use this to conditionally render based on required functionality
   */
  disableRedirect?: boolean;
  /** Overrides the default "/401" route path used when redirecting */
  unauthorizedRoutePath?: string;
  /** Overrides the translation keys under the hood */
  translations?: IAuthorizedTranslations;
  children: ReactNode;
}

/**
 * Component used to restrict the underlying component tree based on user functionality & scope.
 * Use this to redirect to an unauthorized route when the functionality requirement is not met
 * or simply not render the underlying component tree with the `disableRedirect` property.
 *
 * @translations
 * Make sure to add the default translations used by this component to your project. The translations can be overridden using the `translations` prop.
 * ```json
 * {
 *  "generic.label.loading": "Loading...",
 * }
 * ```
 */
export const Authorized = ({
  children,
  disableRedirect,
  functionality,
  organizationUnitID,
  scope,
  translations,
  unauthorizedRoutePath,
}: IAuthorizedProps) => {
  const { functionalityAllowed, loading: scopedFunctionalityLoading } = useHasFunctionality(
    functionality,
    scope ?? FunctionalityScope.None,
    organizationUnitID,
  );
  const {
    functionalityAllowed: isUnscopedFunctionalityAllowed,
    loading: unscopedFunctionalityLoading,
  } = useHasFunctionalityWithoutScope(functionality, organizationUnitID);

  const navigate = useNavigate();

  const isLoading = useMemo(
    () => scopedFunctionalityLoading || unscopedFunctionalityLoading,
    [scopedFunctionalityLoading, unscopedFunctionalityLoading],
  );

  const isAuthorized = useMemo(
    () =>
      (!isNil(scope) && functionalityAllowed) || (isNil(scope) && isUnscopedFunctionalityAllowed),
    [functionalityAllowed, isUnscopedFunctionalityAllowed, scope],
  );

  useEffect(() => {
    if (!isLoading && !isAuthorized && !disableRedirect) {
      navigate(unauthorizedRoutePath ?? "/401");
    }
  }, [disableRedirect, isAuthorized, isLoading, navigate, unauthorizedRoutePath]);

  if (isLoading) {
    return (
      <Text>
        <FormattedMessage
          id={translations?.loadingTranslationKey ?? "generic.label.loading"}
          defaultMessage="Loading..."
        />
      </Text>
    );
  } else if (isAuthorized) {
    return children;
  }
  return null;
};
