import { useMemo } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";

import { useSelectedOrganizationUnit } from "components/suite-composite/organization-unit-selector";

const useGetCurrentOrganizationUnitId = () => {
  const currentUserResponse = hooks.useGetState(state.currentUser.currentUserState.response);
  const { selectedOrganizationUnit } = useSelectedOrganizationUnit();

  const currentOrganizationUnitId = useMemo(
    () => selectedOrganizationUnit?.ID ?? currentUserResponse?.User?.CurrentOrganizationID,
    [currentUserResponse?.User?.CurrentOrganizationID, selectedOrganizationUnit?.ID],
  );

  return currentOrganizationUnitId;
};

export default useGetCurrentOrganizationUnitId;
