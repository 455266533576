import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

export const rolesListState = builders.buildServiceState({
  service: CoreManagement.ListRoles,
  key: "rolesListState",
  requireEmployee: true,
  allowEmptyRequest: true,
  defaultRequest: {
    PageConfig: {},
  },
});

export const rolesListSelector = selector({
  key: "rolesListState:rolesListSelector",
  get: ({ get }) => {
    const rolesResponse = get(rolesListState.response);
    return rolesResponse?.Result?.Page;
  },
});
