import { InputLabel } from "@material-ui/core";

import Chip from "../../chip";
import Input from "../../input";

import {
  IAutocompleteBaseProps,
  IMultiAutocompleteProps,
  ISingleAutocompleteProps,
} from "./autocomplete.types";

export const PassiveAutocomplete = <T,>({
  error,
  helperText,
  label,
  LabelKey: labelKey,
  multi,
  name,
  onBlur,
  required,
  small,
  value,
  values,
}: Omit<
  (ISingleAutocompleteProps<T> | IMultiAutocompleteProps<T>) &
    IAutocompleteBaseProps<T, keyof T, keyof T>,
  "passive"
>) =>
  multi ? (
    <>
      <div className="flex flex-col items-center">
        <InputLabel className="mx-5" shrink={!!values?.length} required={required} error={error}>
          {label}
        </InputLabel>
        {values?.map((value: any, index) => (
          <div className="my-1 mr-[6px]" key={index}>
            <Chip>{value?.[labelKey] as string}</Chip>
          </div>
        ))}
      </div>
    </>
  ) : (
    <Input
      passive
      name={name}
      error={error}
      small={small}
      label={label}
      onBlur={onBlur}
      required={required}
      helperText={helperText}
      value={value ? value[labelKey] : ""}
    />
  );
