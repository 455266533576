import type {
  CSSProperties,
  ElementType,
  MouseEvent,
  ReactNode,
  ReactText,
  Ref,
  SyntheticEvent,
} from "react";

import Typography from "@material-ui/core/Typography";

export interface TextProps {
  /** Text alignment */
  align?: "left" | "center" | "right" | "inherit" | "justify";
  /** Choose color */
  color?:
    | "inherit"
    | "initial"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  variant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "inherit"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "overline"
    | "srOnly";
  /** Use this if you want to use another HTML element. Like span, p, h1 to h6 */
  as?: ElementType<any>;
  style?: CSSProperties;
  className?: string;
  innerRef?: Ref<any>;
  key?: ReactText;
  /** Hide elmement */
  hidden?: boolean;
  display?: "block" | "inline" | "initial";
  children: ReactNode;
  /** Disable text selection */
  unselectable?: "on" | "off";
  onClick?: (event: MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  onSelect?: (event: SyntheticEvent<HTMLSpanElement, Event>) => void;
}

const Text = ({ as, ...props }: TextProps) => <Typography {...props} component={as!} />;

export default Text;
