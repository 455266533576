import { CostPriceCalculationMethodEnum } from "types/translations-enums";

export const costPriceCalculationMethodsDefaultMessages: Record<
  CostPriceCalculationMethodEnum,
  string
> = {
  [CostPriceCalculationMethodEnum.Manual]: "Manual",
  [CostPriceCalculationMethodEnum.WeightedAverageCost]: "Weighted average cost",
  [CostPriceCalculationMethodEnum.LastIn]: "Last In",
  [CostPriceCalculationMethodEnum.Fifo]: "First in, first out",
};
