import { SegmentedControl, SegmentedControlItem, SvgIcon } from "@new-black/lyra";

export type EditorType = "normal" | "diff";

export const SegmentedButtonEditorSwitch = ({
  editorType,
  isDisabled = true,
  setEditorType,
}: {
  editorType: EditorType;
  setEditorType: (item: EditorType) => void;
  isDisabled?: boolean;
}) => {
  return (
    <SegmentedControl
      className="w-auto shrink-0"
      value={editorType}
      onChange={(value) => {
        if (value) {
          setEditorType(value as EditorType);
        }
      }}
      disabledKeys={isDisabled ? ["normal", "diff"] : undefined}
    >
      <SegmentedControlItem value="normal">
        <SvgIcon name="code-editor" />
      </SegmentedControlItem>
      <SegmentedControlItem value="diff">
        <SvgIcon name="diff-editor" />
      </SegmentedControlItem>
    </SegmentedControl>
  );
};
