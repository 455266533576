import { IntlShape } from "react-intl";
import { EntityFieldValidatorUserTypes } from "types/eva-core";

export const getEntityFieldValidatorUserTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.EntityFieldValidatorUserTypes, string> = {
    [EntityFieldValidatorUserTypes.All]: intl.formatMessage({
      id: "generic.label.all",
      defaultMessage: "All",
    }),
    [EntityFieldValidatorUserTypes.Api]: intl.formatMessage({
      id: "user-type-select.enum-value-labels.2048",
      defaultMessage: "Api",
    }),
    [EntityFieldValidatorUserTypes.Customer]: intl.formatMessage({
      id: "user-type-select.enum-value-labels.2",
      defaultMessage: "Customer",
    }),
    [EntityFieldValidatorUserTypes.Employee]: intl.formatMessage({
      id: "user-type-select.enum-value-labels.1",
      defaultMessage: "Employee",
    }),
  };

  return (status: EVA.Core.EntityFieldValidatorUserTypes) => {
    return translationMap[status];
  };
};
