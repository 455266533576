import { z } from "zod";
import { zfd } from "zod-form-data";

import { loyaltyProgramProductLimitationQuantitiesSchema } from "../loyalty-program-product-limitation-data";

export const loyaltyProgramProductLimitationFormSchema = z
  .object({
    Name: zfd.text(),
    BackendID: zfd.text(z.string().optional()),
  })
  .merge(loyaltyProgramProductLimitationQuantitiesSchema);

export const loyaltyProgramProductLimitationFormKeys =
  loyaltyProgramProductLimitationFormSchema.keyof().Values;

export type LoyaltyProgramProductLimitationForm = z.infer<
  typeof loyaltyProgramProductLimitationFormSchema
>;

export type LoyaltyProgramProductLimitationFormErrors = Partial<
  Record<keyof LoyaltyProgramProductLimitationForm, string>
>;
