import * as React from "react";
import { SortingRule } from "react-table";

import { ITableColumnSort } from "../table-types";

const useSorting = (
  sortingRules: SortingRule<any>[],
  onSortByChange?: (sortBy: ITableColumnSort[]) => void,
) => {
  React.useEffect(() => {
    if (onSortByChange) {
      onSortByChange(
        sortingRules
          ? sortingRules.map(
              (sortingRule): ITableColumnSort => ({
                columnId: sortingRule.id,
                direction: sortingRule.desc ? "Descending" : "Ascending",
              }),
            )
          : [],
      );
    }
  }, [onSortByChange, sortingRules]);
};

export default useSorting;
