import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getAddressRequirementsLoaderQuery,
  serviceQuery: getAddressRequirementsQuery,
  serviceQueryKeys: getAddressRequirementsKeys,
  useServiceQueryHook: useGetAddressRequirementsQuery,
  useServiceQueryWithRequest: useGetAddressRequirementsQueryWithRequest,
} = createServiceQuery(Core.GetAddressRequirements, true);
