import { memo, useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Text } from "@new-black/lyra";

import { DenominationFormProps, UpdateDenominationsWithTypeFunction } from "./denomination.types";
import DenominationTable from "./denomination-table";

const DenominationForm = memo(
  ({ cashDenominations, currencyID, updateCashDenominations }: DenominationFormProps) => {
    const [isDirty, setIsDirty] = useState<boolean>(false);

    const updateDenominations: UpdateDenominationsWithTypeFunction = useCallback(
      (type, denomination) => {
        setIsDirty(true);

        if (type === "Coins") {
          updateCashDenominations({
            ...cashDenominations,
            Coins: denomination,
            BankNotes: { ...cashDenominations.BankNotes },
          });
        } else {
          updateCashDenominations({
            ...cashDenominations,
            BankNotes: denomination,
            Coins: { ...cashDenominations.Coins },
          });
        }
      },
      [cashDenominations, updateCashDenominations],
    );

    return (
      <div>
        <div className="mt-4 px-4 pb-2">
          <Text variant="heading-2">
            <FormattedMessage id="generic.label.coins" defaultMessage="Coins" />
          </Text>
        </div>
        <DenominationTable
          denominations={cashDenominations?.Coins ?? {}}
          type="Coins"
          currencyID={currencyID}
          updateDenominations={(denominations) => updateDenominations("Coins", denominations)}
          autoFocusFirstInput={!isDirty}
        />
        <div className="mt-10 px-4 pb-2">
          <Text variant="heading-2">
            <FormattedMessage id="generic.label.banknotes" defaultMessage="Notes" />
          </Text>
        </div>
        <DenominationTable
          denominations={cashDenominations?.BankNotes ?? {}}
          type="BankNotes"
          currencyID={currencyID}
          updateDenominations={(denominations) => updateDenominations("BankNotes", denominations)}
        />
      </div>
    );
  },
);
DenominationForm.displayName = "DenominationForm";

export default DenominationForm;
