import { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";

import { uniq } from "lodash";

import { ProductSearchModal } from "components/suite-composite/product-search-modal";
import { useIsTotalSelectionOfProductsExceedingLimit } from "components/suite-composite/product-set-modal/hooks/use-is-total-selection-of-products-exceeding-limit";
import { toast } from "components/suite-ui/toast";
import useProductIDProperty from "hooks/suite-react-hooks/use-product-id-property";
import { IProduct } from "types/product";

interface IStockAvailabilityOrReplenishmentsProductSetModalProps {
  isOpen: boolean;
  productIDs: number[];
  setIsOpen: (value: boolean) => void;
  setProductIDs?: Dispatch<SetStateAction<number[]>>;
  productSelectionLimit?: number;
}

export function StockAvailabilityOrReplenishmentsProductSetModal({
  isOpen,
  productIDs,
  productSelectionLimit,
  setIsOpen,
  setProductIDs,
}: IStockAvailabilityOrReplenishmentsProductSetModalProps) {
  const intl = useIntl();

  const { getProductIDPropertyValue } = useProductIDProperty();
  const exceededLimitCondition = useIsTotalSelectionOfProductsExceedingLimit(
    productSelectionLimit,
    productIDs?.length,
  );

  const showWarningToast = useCallback(() => {
    toast.error(
      intl.formatMessage(
        {
          id: "generic.label.limit-selection-to-a-limit-of-products",
          defaultMessage: "Please limit your selection to {limit} products.",
        },
        { limit: productSelectionLimit },
      ),
    );
  }, [intl, productSelectionLimit]);

  useEffect(() => {
    if (isOpen && exceededLimitCondition) {
      showWarningToast();
    }
  }, [exceededLimitCondition, isOpen, showWarningToast]);

  return (
    <ProductSearchModal.Multi
      maxWidth="6xl"
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      selectedProducts={[] as IProduct[]}
      isSaveDisabled={exceededLimitCondition}
      isProductSelectable={(product) =>
        !productIDs.includes(product.product_id) && !exceededLimitCondition
      }
      onSave={(products) => {
        const newProductIds = uniq([
          ...productIDs,
          ...products.map((product) => product.product_id),
        ]);
        if (productSelectionLimit && newProductIds.length > productSelectionLimit) {
          showWarningToast();
          return;
        }
        setProductIDs?.([...productIDs, ...products.map((product) => product.product_id)]);
        setIsOpen(false);
      }}
      leftPaneListItemRenderElements={(item) => ({
        title: item?.display_value ?? item?.product_name ?? "-",
        imageBlobId: item.primary_image?.blob,
        description: getProductIDPropertyValue(item),
      })}
      rightPaneListItemRenderElements={(item) => ({
        title: item?.display_value ?? item?.product_name ?? "-",
        imageBlobId: item.primary_image?.blob,
        description: getProductIDPropertyValue(item),
      })}
    />
  );
}
