import type { SvgIconProps } from "@material-ui/core";
import { SvgIcon } from "@material-ui/core";
import classNames from "classnames";

const StyledSvgIcon = ({ color, ...props }: SvgIconProps) => (
  <SvgIcon
    {...props}
    className={classNames(
      color === "primary"
        ? "[&_path]:fill-[color:var(--legacy-eva-color-primary)]"
        : "[&_path]:fill-[color:var(--legacy-eva-color-dark-2)]",
    )}
  />
);

export default StyledSvgIcon;
