import LinkButton from "components/suite-ui/link-button";
import Spinner from "components/suite-ui/spinner";
import Text from "components/suite-ui/text";

export const InteractionLogEmailPreviewMetadataLine = ({
  isLoading,
  label,
  values,
}: {
  label: string;
  values: { label: string; onClick?: () => void }[];
  isLoading?: boolean;
}) => (
  <div className="grid grid-cols-2 md:grid-cols-4">
    <span className="flex items-center gap-2">
      <Text className="font-semibold">{label}</Text>

      {isLoading ? <Spinner size={10} /> : null}
    </span>

    <Text className="text-[color:var(--legacy-eva-color-dark-2)]">
      {values.map((value, index) =>
        value.onClick ? (
          <>
            <LinkButton onClick={value.onClick} disabled={isLoading}>
              {value.label}
            </LinkButton>
            {index !== values.length - 1 ? ", " : null}
          </>
        ) : (
          value.label
        ),
      )}
    </Text>
  </div>
);
