import { FormattedMessage, useIntl } from "react-intl";

import { Button, Radio, RadioGroup } from "@new-black/lyra";

import { useBarcode } from "util/barcode";

import { IdentificationMethod } from "./types";

export const IdentificationMethodSaveButton = ({
  isDisabled,
  isLoading,
  method,
  onCreatePINCode,
  onGenerateQRCode,
}: {
  method: IdentificationMethod;
  onGenerateQRCode: () => void;
  onCreatePINCode: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}) => {
  if (method === "qr") {
    return (
      <Button onPress={onGenerateQRCode} isLoading={isLoading} isDisabled={isDisabled}>
        <FormattedMessage id="generic.label.generate-qr-code" defaultMessage="Generate QR code" />
      </Button>
    );
  }

  return (
    <Button onPress={onCreatePINCode} isLoading={isLoading} isDisabled={isDisabled}>
      <FormattedMessage id="generic.label.save" defaultMessage="Save" />
    </Button>
  );
};

export const IdentificationMethodRadioButtons = ({
  onChange,
  value,
}: {
  value: IdentificationMethod;
  onChange: (newValue: IdentificationMethod) => void;
}) => {
  const intl = useIntl();

  return (
    <RadioGroup
      hideInputHeader
      label=""
      aria-label={intl.formatMessage({
        id: "generic.label.identification-code",
        defaultMessage: "Identification code",
      })}
      value={value}
      onChange={(newValue) => onChange(newValue as IdentificationMethod)}
    >
      <Radio value="qr">
        <FormattedMessage id="generic.label.qr-code" defaultMessage="QR code" />
      </Radio>
      <Radio value="pin">
        <FormattedMessage id="generic.label.pin-code" defaultMessage="Pin code" />
      </Radio>
    </RadioGroup>
  );
};

export const IdentificationCodeQRImage = ({
  className,
  identificationCode,
}: {
  identificationCode?: string;
  className?: string;
}) => {
  const qrCodeImgProps = useBarcode({
    Width: 160,
    Height: 160,
    NoMargin: true,
    alt: "img-barcode",
    Code: identificationCode,
  });

  return <img {...qrCodeImgProps} className={className} />;
};
