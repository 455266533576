/* eslint-disable react/no-danger */
import { Dispatch, KeyboardEvent, ReactNode, SetStateAction, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Combobox } from "@headlessui/react";
import { Separator, SvgIcon } from "@new-black/lyra";
import classNames from "classnames";
import Fuse from "fuse.js";

import { Link, useNavigate } from "components/routing";
import { QueryableLabel } from "components/shared/queryable-label";

import { ModuleGrid } from "./module-grid";
import { useChapterFinderData } from "./use-chapter-finder-data";

interface ComboBoxItem {
  label: string;
  path: string;
  category: string;
  icon: ReactNode;
}

const ChapterFinderInput = ({
  isInModal,
  query,
  setQuery,
}: {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  isInModal?: boolean;
}) => {
  const intl = useIntl();
  return (
    <div
      className={classNames(
        "relative flex h-[60px] w-full items-center gap-2 px-5",
        "border border-solid border-default bg-surface-primary",
        query || (!query && isInModal) ? "rounded-t-xl" : "rounded-xl",
        isInModal ? "border-x-0 border-t-0" : "",
      )}
    >
      <SvgIcon
        name="search"
        className="pointer-events-none h-6 w-6 text-[color:var(--color-primary-3)]"
      />
      <Combobox.Input
        className={classNames(
          "text-legacy-base placeholder:text-legacy-base h-10 w-full border-0 bg-transparent text-primary placeholder:text-tertiary",
        )}
        autoFocus
        onKeyUp={(e: KeyboardEvent) => {
          if (e.key === "Escape") {
            setQuery("");
          }
        }}
        placeholder={intl.formatMessage({
          id: "generic.label.search",
          defaultMessage: "Search",
        })}
        onChange={(event) => setQuery(event.target.value)}
      />
    </div>
  );
};

const ChapterFinderOption = ({ item, query }: { item: ComboBoxItem; query: string }) => (
  <Combobox.Option
    value={item.path}
    className={({ active }) =>
      classNames(
        "-mx-2 cursor-default select-none rounded-md px-2 py-2",
        active && "bg-overlay-medium",
      )
    }
  >
    <div className="flex items-center gap-2">
      <svg className="h-4 w-4" viewBox="0 0 56 56">
        {item.icon}
      </svg>
      <QueryableLabel label={item.label} query={query} />
    </div>
  </Combobox.Option>
);

const ChapterFinderOptions = ({
  filteredItems,
  isInModal,
  query,
}: {
  query: string;
  filteredItems: ComboBoxItem[];
  isInModal?: boolean;
}) => {
  const groupItems = filteredItems.reduce(
    (groups, item) => ({ ...groups, [item.category]: [...(groups[item.category] || []), item] }),
    {} as { [key: string]: ComboBoxItem[] },
  );

  const noResultsAvailable = query && filteredItems.length === 0;

  return (
    <div className="relative h-full">
      <Combobox.Options
        className={classNames(
          "absolute z-10 my-0 w-full scroll-pb-2 scroll-pt-11 list-none overflow-y-auto bg-surface-primary pl-0",
          query && !isInModal && "rounded-b-xl border border-t-0 border-solid border-default",
          !isInModal ? "max-h-80" : "max-h-[calc(90vh-63px)] overflow-y-auto md:max-h-[557px]",
        )}
      >
        {!noResultsAvailable ? (
          Object.entries(groupItems).map(
            ([category, groupedItems]: [string, ComboBoxItem[]], index) => (
              <li className="mx-5 mt-5" key={category}>
                <h2 className="my-0 text-xs font-normal text-secondary">{category}</h2>
                <ul className="text-legacy-sm mt-2 list-none pl-0 text-primary">
                  {groupedItems.map((item) => (
                    <ChapterFinderOption key={item.path} item={item} query={query} />
                  ))}
                </ul>
                {index + 1 !== Object.keys(groupItems).length ? ( // Make sure we don't render a divider at the end
                  <div className="mb-5 mt-3">
                    <Separator />
                  </div>
                ) : (
                  <div className="mb-5" />
                )}
              </li>
            ),
          )
        ) : (
          <div
            className={classNames(
              "flex h-[19.9rem] w-full flex-col items-center justify-center bg-surface-primary p-5",
            )}
          >
            <SvgIcon name="search" className="mb-5 h-20 w-20 text-tertiary" />
            <h3 className="text-legacy-base mb-2 text-center text-primary">
              <FormattedMessage
                id="chapter-finder.no-results"
                defaultMessage="Sorry, no matches were found."
              />
            </h3>
            <span className="text-center text-xs text-secondary">
              <FormattedMessage
                id="chapter-finder.no-results-description"
                defaultMessage="Please try a less specific search term."
              />
            </span>
          </div>
        )}
      </Combobox.Options>
    </div>
  );
};

export const ChapterFinderViewAllChapters = ({ onClick }: { onClick?: () => void }) => (
  <div className="flex flex-col items-center p-5 pb-4">
    <Link
      className="ring-[color:var(--color-primary-3)]-1 rounded-md p-1 text-[color:var(--color-primary-3)] no-underline focus-visible:ring-2"
      to="/dashboard/overview"
      onClick={onClick}
    >
      <FormattedMessage id="chapter-finder.view-all-chapters" defaultMessage="View all chapters" />
    </Link>
  </div>
);

export const ChapterFinderComboBox = ({
  isInModal,
  onSelect,
}: {
  isInModal?: boolean;
  /** Callback fired after selecting an option */
  onSelect?: () => void;
}) => {
  const modules = useChapterFinderData();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");

  const items = useMemo(() => {
    let comboBoxItems: ComboBoxItem[] = [];

    modules.forEach((module) => {
      module.chapters.forEach((chapter) => {
        comboBoxItems = [
          ...comboBoxItems,
          {
            label: chapter.title,
            path: `/${module.path}${chapter.path}`,
            category: module.title,
            icon: module.icon,
          },
        ];
      });
    });
    return comboBoxItems;
  }, [modules]);

  const fuse = useMemo(
    () =>
      new Fuse(items, {
        keys: ["label", "category"],
        threshold: 0.2,
      }),
    [items],
  );

  const filteredItems = fuse.search(query).map((result) => result.item);

  return (
    <>
      <div className="relative w-full">
        <Combobox
          value=""
          onChange={(item) => {
            if (item) {
              navigate(item);
              onSelect?.();
            }
          }}
        >
          <ChapterFinderInput setQuery={setQuery} query={query} isInModal={isInModal} />

          {query ? (
            <ChapterFinderOptions
              filteredItems={filteredItems}
              query={query}
              isInModal={isInModal}
            />
          ) : null}
        </Combobox>
      </div>

      {(isInModal && !query) || !isInModal ? (
        <div className="flex h-full flex-col">
          <div
            className={classNames(
              "flex h-full items-center justify-center",
              isInModal ? "py-5" : "py-16",
            )}
          >
            <ModuleGrid onModuleIconClick={onSelect} />
          </div>

          {isInModal ? (
            <div className="px-5">
              <Separator />
              <ChapterFinderViewAllChapters onClick={onSelect} />
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
