import { IntlShape } from "react-intl";
import { EntityFieldValidatorType } from "types/eva-core";

export const getEntityFieldValidatorTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.EntityFieldValidatorType, string> = {
    [EntityFieldValidatorType.DefaultValidator]: intl.formatMessage({
      id: "generic.label.default-validator",
      defaultMessage: "Default validator",
    }),
    [EntityFieldValidatorType.StringValidator]: intl.formatMessage({
      id: "generic.label.string-validator",
      defaultMessage: "String validator",
    }),
  };

  return (status: EVA.Core.EntityFieldValidatorType) => {
    return translationMap[status];
  };
};
