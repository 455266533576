import { CircularProgress, CircularProgressProps } from "@material-ui/core";

export type SpinnerProps = Pick<
  CircularProgressProps,
  | "color"
  | "size"
  | "value"
  | "defaultValue"
  | "variant"
  | "className"
  | "style"
  | "id"
  | "hidden"
  | "ref"
  | "innerRef"
>;

const Spinner: React.FC<SpinnerProps> = (props) => <CircularProgress {...props} />;

export default Spinner;
