import { createGetterSetterSelectorFamily } from "@springtree/eva-sdk-react-recoil/lib/helpers/create-selectors";

import { discountOrTemplateSelector } from "../../edit-discount.state";
import { IDiscount, IFinancialInfo } from "../../edit-discount.types";

/**
 * Selector to access the financial info of a discount
 */
export const financialInfoSelector = createGetterSetterSelectorFamily<
  IDiscount,
  IFinancialInfo | undefined
>({
  state: discountOrTemplateSelector,
  key: "DiscountDetails:Discount:FinancialInfo",
  defaultValue: undefined,
  getData: (data) => data?.financialInfo,
  setData: (data, newValue) => ({ ...data, financialInfo: newValue }),
});

export const financialInfoCurrencySelector = createGetterSetterSelectorFamily<
  IFinancialInfo,
  string | undefined
>({
  state: financialInfoSelector,
  key: "DiscountDetails:Discount:FinancialInfo:Currency",
  defaultValue: undefined,
  getData: (data) => data?.currency,
  setData: (data, newValue) => ({ ...data, currency: newValue }),
});
