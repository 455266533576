import { useEffect, useState } from "react";
import { useNavigation } from "react-router-dom";

import { useBlockedRouting } from "components/routing";

export const useFormTouchedBlockRouting = (enabled: boolean) => {
  const [formTouched, setFormTouched] = useState(false);
  const { location, state } = useNavigation();

  // Block routing when form is dirty but not submitting
  useBlockedRouting(enabled && formTouched && state !== "submitting");

  // Make sure form is not touched if succesfully navigated
  useEffect(() => {
    setFormTouched(false);
  }, [location?.pathname]);

  return setFormTouched;
};
