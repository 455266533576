export const ComplianceIconInner = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#5856D6" />
    <path
      d="M12.9243 43.8783C12.1377 43.8783 11.5 43.3156 11.5 42.6215C11.5 41.9274 12.1377 41.3647 12.9243 41.3647H27.0757C27.8623 41.3647 28.5 41.9274 28.5 42.6215C28.5 43.3156 27.8623 43.8783 27.0757 43.8783H12.9243Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.5328 11.566C31.9276 10.9608 30.9464 10.9608 30.3412 11.566L27.6017 14.3055C27.1596 14.7477 27.0404 15.3905 27.2443 15.9408C26.3919 17.3851 25.3222 18.7768 24.0405 20.0584C22.9797 21.1192 21.8436 22.0348 20.6646 22.8022C20.0778 22.4867 19.3306 22.5766 18.8353 23.072L16.0958 25.8115C15.4906 26.4167 15.4906 27.3979 16.0958 28.0031L21.0269 32.9342C21.6321 33.5394 22.6133 33.5394 23.2185 32.9342L25.958 30.1947C26.4533 29.6994 26.5432 28.9521 26.2278 28.3654C26.5374 27.8896 26.8712 27.4208 27.229 26.9611L39.0739 39.9189C40.1166 41.0596 41.9004 41.0996 42.9932 40.0068L44.5364 38.4636C45.6292 37.3708 45.5892 35.587 44.4485 34.5443L31.5736 22.7752C32.0705 22.4181 32.5765 22.0882 33.0892 21.7856C33.6395 21.9895 34.2823 21.8704 34.7244 21.4283L37.4639 18.6887C38.0691 18.0836 38.0691 17.1023 37.4639 16.4971L32.5328 11.566ZM31.437 13.7576L29.7933 15.4013L33.6286 19.2366L35.2723 17.5929L31.437 13.7576ZM31.2757 20.1711L28.8588 17.7543C27.9771 19.1403 26.9169 20.4694 25.6842 21.7021C24.6483 22.738 23.5443 23.6521 22.3957 24.4408L24.5892 26.6342C25.3778 25.4856 26.2919 24.3816 27.3278 23.3457C28.5605 22.113 29.8897 21.0528 31.2757 20.1711ZM29.7452 24.2532C29.4833 24.4903 29.2252 24.7357 28.9715 24.9894C28.9025 25.0585 28.8341 25.1278 28.7663 25.1975L40.7896 38.3505C40.9386 38.5135 41.1934 38.5192 41.3495 38.3631L42.8927 36.8199C43.0488 36.6638 43.0431 36.409 42.8801 36.26L29.7452 24.2532ZM18.2874 26.9073L19.9311 25.2636L23.7664 29.0989L22.1227 30.7426L18.2874 26.9073Z"
      fill="white"
    />
  </>
);
