import { ReactNode } from "react";

import { TooltipProvider } from "@radix-ui/react-tooltip";

import ThemeProvider from "components/suite-ui/theme-provider";

export const UIProviders = ({ children }: { children: ReactNode }) => {
  return (
    <ThemeProvider>
      <TooltipProvider delayDuration={200} skipDelayDuration={200}>
        {children}
      </TooltipProvider>
    </ThemeProvider>
  );
};
