import { Core } from "@springtree/eva-services-core";

import { getEventExportTargetTypesQuery } from "models/event-exports";
import { EventExportTarget } from "types/eva-core";
import enumToArray, { EnumArrayItem } from "util/enum-to-array";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateEventExportTargetSearchListField = () =>
  SearchListFieldGenerator.LocalSearchListFieldGenerator<EnumArrayItem, number>({
    getItemId: (item) => item.value,
    getLabel: (item) => item.key,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.event-export-target",
      defaultMessage: "Event export target",
    }),
    items: enumToArray(EventExportTarget),
  });

export const generateEventExportTargetTypeSearchListField = (eventExportTargetID: number) =>
  SearchListFieldGenerator<Core.GetEventExportTargetTypes, { Name: string }, string>({
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) => resp?.EventTypes?.map((eventType) => ({ Name: eventType })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.event-type",
      defaultMessage: "Event type",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getEventExportTargetTypesQuery,
        initialRequest: { EventTarget: eventExportTargetID },
      }),
  });
