import { Core } from "@springtree/eva-services-core";

import {
  getStockLabelsForFeatureQuery,
  getStockLabelsForFeatureQueryKeys,
} from "models/stock-locations";
import { StockLabelFeature } from "types/stock-label-feature";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { THIRTY_SECONDS } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";

export const generateStockLabelsForFeatureAutocomplete = ({
  feature,
  ouId,
}: {
  feature: StockLabelFeature;
  ouId?: number;
}) => {
  const {
    SingleAutocomplete: StockLabelsAutocomplete,
    SingleIDAutocomplete: StockLabelsIDAutocomplete,
  } = AutocompleteGenerator<Core.GetStockLabelsForFeature, { ID: number; Name: string }, "ID">({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.StockLabels?.map((stockLabel) => ({ ID: stockLabel.ID, Name: stockLabel.DisplayName })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.stock-label",
      defaultMessage: "Stock label",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: getStockLabelsForFeatureQuery,
        queryKey: getStockLabelsForFeatureQueryKeys.withKey(["autocomplete", feature.toString()]),
        initialRequest: {
          Feature: feature as number,
          OrganizationUnitID: ouId,
        },
        staleTime: THIRTY_SECONDS,
        cacheTime: THIRTY_SECONDS,
      }),
    useItemByID: (id, stockLabels) => {
      const stockLabel = stockLabels?.find((stockLabel) => stockLabel.ID === id);

      return {
        data: stockLabel,
      };
    },
  });

  return {
    StockLabelsAutocomplete,
    StockLabelsIDAutocomplete,
  };
};
