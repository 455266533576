import { IConditionProductRequirementFormValue } from "./product-requirement.types";

export const getProductRequirementLabel = (
  productRequirement: EVA.Core.Management.ProductRequirementDto,
) => {
  if (productRequirement.ProductName) {
    return `${productRequirement.ProductName} - ${productRequirement.Name}`;
  }

  return productRequirement.Name;
};

export const getProductRequirementFieldName = (
  index: number,
  property: keyof IConditionProductRequirementFormValue,
) => `${index}.${property}`;
