export default {
  module: {
    path: "/tasks-new",
  },

  shipFromStore: {
    overview: {
      path: "/tasks-new/ship-from-store",
    },
    taskAssigneeWarning: {
      pick: {
        simpleTask: {
          path: "/tasks-new/ship-from-store/pick/:id/task-assignee-warning",
        },
        compositeTask: {
          path: "/tasks-new/ship-from-store/pick/:compositeId/:id/task-assignee-warning",
        },
      },
      ship: {
        simpleTask: {
          path: "/tasks-new/ship-from-store/ship/:id/task-assignee-warning",
        },
        compositeTask: {
          path: "/tasks-new/ship-from-store/ship/:compositeId/:id/task-assignee-warning",
        },
      },
      print: {
        simpleTask: {
          path: "/tasks-new/ship-from-store/print/:id/task-assignee-warning",
        },
        compositeTask: {
          path: "/tasks-new/ship-from-store/print/:compositeId/:id/task-assignee-warning",
        },
      },
      deliver: {
        simpleTask: {
          path: "/tasks-new/ship-from-store/deliver/:id/task-assignee-warning",
        },
        compositeTask: {
          path: "/tasks-new/ship-from-store/deliver/:compositeId/:id/task-assignee-warning",
        },
      },
      pack: {
        simpleTask: {
          path: "/tasks-new/ship-from-store/pack/:id/task-assignee-warning",
        },
        compositeTask: {
          path: "/tasks-new/ship-from-store/pack/:compositeId/:id/task-assignee-warning",
        },
      },
    },
    taskDetailsRedirecter: {
      simpleTask: { path: "/tasks-new/ship-from-store/details/:id" },
      compositeTask: { path: "/tasks-new/ship-from-store/details/:compositeId/:id" },
    },

    pick: {
      overview: {
        path: "/tasks-new/ship-from-store/pick",
      },
      details: {
        compositeTask: {
          path: "/tasks-new/ship-from-store/pick/:compositeId/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/pick/:compositeId/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/pick/:compositeId/:id/edit-shipping-address",
          },
        },
        simpleTask: {
          path: "/tasks-new/ship-from-store/pick/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/pick/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/pick/:id/edit-shipping-address",
          },
        },
      },
    },
    ship: {
      overview: {
        path: "/tasks-new/ship-from-store/ship",
      },
      details: {
        compositeTask: {
          path: "/tasks-new/ship-from-store/ship/:compositeId/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/ship/:compositeId/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/ship/:compositeId/:id/edit-shipping-address",
          },
        },
        simpleTask: {
          path: "/tasks-new/ship-from-store/ship/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/ship/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/ship/:id/edit-shipping-address",
          },
        },
      },
    },

    deliver: {
      overview: {
        path: "/tasks-new/ship-from-store/deliver",
      },
      details: {
        compositeTask: {
          path: "/tasks-new/ship-from-store/deliver/:compositeId/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/deliver/:compositeId/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/deliver/:compositeId/:id/edit-shipping-address",
          },
        },
        simpleTask: {
          path: "/tasks-new/ship-from-store/deliver/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/deliver/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/deliver/:id/edit-shipping-address",
          },
        },
      },
    },

    print: {
      overview: {
        path: "/tasks-new/ship-from-store/print",
      },
      details: {
        compositeTask: {
          path: "/tasks-new/ship-from-store/print/:compositeId/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/print/:compositeId/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/print/:compositeId/:id/edit-shipping-address",
          },
        },
        simpleTask: {
          path: "/tasks-new/ship-from-store/print/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/print/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/print/:id/edit-shipping-address",
          },
        },
      },
    },

    pack: {
      overview: {
        path: "/tasks-new/ship-from-store/pack",
      },
      details: {
        compositeTask: {
          path: "/tasks-new/ship-from-store/pack/:compositeId/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/pack/:compositeId/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/pack/:compositeId/:id/edit-shipping-address",
          },
        },
        simpleTask: {
          path: "/tasks-new/ship-from-store/pack/:id",

          editContactDetails: {
            path: "/tasks-new/ship-from-store/pack/:id/edit-contact-details",
          },
          editShippingAddress: {
            path: "/tasks-new/ship-from-store/pack/:id/edit-shipping-address",
          },
        },
      },
    },
  },

  incomingShipment: {
    overview: {
      path: "/tasks-new/incoming-shipment",
    },
    details: {
      path: "/tasks-new/incoming-shipment/:id",

      taskAssigneeWarning: {
        path: "/tasks-new/incoming-shipment/:id/task-assignee-warning",
      },

      receiveProducts: {
        path: "/tasks-new/incoming-shipment/:id/receive-products",
      },

      fullyReceiveProducts: {
        path: "/tasks-new/incoming-shipment/:id/fully-receive-products",
      },
    },
  },

  reservationCleanup: {
    overview: {
      path: "/tasks-new/reservation-cleanup",
    },
    details: {
      path: "/tasks-new/reservation-cleanup/:id",
    },
  },

  fullStockCount: {
    overview: {
      path: "/tasks-new/full-stock-counts",
    },
    details: {
      path: "/tasks-new/full-stock-counts/:id",
    },
  },

  repairs: {
    overview: {
      path: "/tasks-new/repairs",
    },
    createRepair: {
      path: "/tasks-new/repairs/new",

      addProductOrOrderLine: {
        searchProduct: {
          path: "/tasks-new/repairs/new/search-product",
        },
        searchConsumerOrders: {
          path: "/tasks-new/repairs/new/search-consumer-orders/:consumerId",
        },
        searchOwnedProducts: {
          path: "/tasks-new/repairs/new/search-owned-products/:consumerId",
        },
      },

      addConsumer: {
        searchConsumer: {
          path: "/tasks-new/repairs/new/search-consumer",
        },
        createConsumer: {
          path: "/tasks-new/repairs/new/create-consumer",
        },
      },
    },

    details: {
      path: "/tasks-new/repairs/:id/:ouId",
      repairDetails: {
        path: "/tasks-new/repairs/:id/:ouId/repair-details",
        addProduct: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/add-products-to-repair/:repairOrderId",
        },
        deleteMedia: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/delete-media/:caseId/:blobId",
        },
        addMedia: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/addMedia/:caseId",
        },
        editRepair: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/edit-repair",
        },
        printRepair: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/print",
        },
        cancelOrderline: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/cancel-orderline/:orderlineId",
        },
        finishUserTask: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/finish-user-task/:userTaskId",
        },
        startUserTask: {
          path: "/tasks-new/repairs/:id/:ouId/repair-details/start-user-task/:userTaskId",
        },
      },
      interactionLogs: {
        path: "/tasks-new/repairs/:id/:ouId/interaction-logs",
        delete: {
          path: "/tasks-new/repairs/:id/:ouId/interaction-logs/delete/:interactionLogId",
        },
        edit: {
          path: "/tasks-new/repairs/:id/:ouId/interaction-logs/edit/:interactionLogId",
        },
        create: {
          path: "/tasks-new/repairs/:id/:ouId/interaction-logs/create",
        },
      },
    },
  },

  reservation: {
    overview: {
      path: "/tasks-new/reservations",
    },
    details: {
      path: "/tasks-new/reservations/:id",
    },
  },

  print: {
    overview: {
      path: "/tasks-new/print",

      completeTasks: {
        path: "/tasks-new/print/complete-tasks",
      },

      completeSingularTask: {
        path: "/tasks-new/print/complete-singular-tasks",
      },
    },
  },

  zonesAndZoneGroups: {
    path: "/tasks-new/zones-and-zone-groups",
    zones: {
      overview: {
        path: "/tasks-new/zones-and-zone-groups/zones",

        create: {
          path: "/tasks-new/zones-and-zone-groups/zones/new",
        },
        edit: {
          path: "/tasks-new/zones-and-zone-groups/zones/:id/edit",
        },
        delete: {
          path: "/tasks-new/zones-and-zone-groups/zones/:id/delete",
        },
      },
    },
    zoneGroups: {
      overview: {
        path: "/tasks-new/zones-and-zone-groups/zone-groups",
      },
      edit: {
        path: "/tasks-new/zones-and-zone-groups/zone-groups/:id/edit",
      },
      create: {
        path: "/tasks-new/zones-and-zone-groups/zone-groups/new",
      },
    },
  },

  zonedCycleCountsAndSchedules: {
    path: "/tasks-new/zoned-cycle-counts-and-schedules",

    zonedCycleCounts: {
      path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts",
      uploadExcel: {
        path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/upload-excel",
      },
      details: {
        path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/:id",
      },
      delete: {
        path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/delete",
      },
      create: {
        path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/new",
        createCountForSingleProductAndSingleOU: {
          path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/new/single-ou/single-product",
        },
        selectAsyncEmail: {
          singleOU: {
            path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/new/single-ou/select-async-email",
          },
          ouSet: {
            path: "/tasks-new/zoned-cycle-counts-and-schedules/cycle-counts/new/ou-set/select-async-email",
          },
        },
      },
    },

    zonedCycleCountsSchedules: {
      path: "/tasks-new/zoned-cycle-counts-and-schedules/schedules",
      details: {
        path: "/tasks-new/zoned-cycle-counts-and-schedules/schedules/:id",
      },
    },
  },
} as const;
