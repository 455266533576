import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

export const blobsForOrderShipmentsServiceState = builders.buildServiceFamilyState({
  service: Core.ListBlobsForOrder,
  key: "OrderShipmentBlobs",
  requireLoggedIn: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.OrderID) {
      return req;
    }
    return {
      ...req,
      OrderID: parameter as number,
    };
  },
});
