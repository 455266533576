import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

import InvoicesOverviewDefaultFilters from "features/invoices/list/sidebar/state/invoices-overview-default-filters";
import { DEFAULT_LIMIT, DEFAULT_START } from "util/base-values";

export const listInvoicesServiceState = builders.buildServiceState({
  requireLoggedIn: true,
  allowEmptyRequest: false,
  key: "Core:ListInvoices",
  defaultRequest: {
    PageConfig: {
      Start: DEFAULT_START,
      Limit: DEFAULT_LIMIT,
      Filter: InvoicesOverviewDefaultFilters,
    },
  },
  service: Core.ListInvoices,
});

export const listInvoices = selector({
  key: "Core.ListInvoices:response:selector",
  get: ({ get }) => {
    const invoicesList = get(listInvoicesServiceState.response);

    return invoicesList?.Result?.Page;
  },
});
