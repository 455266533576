import { useEffect, useMemo } from "react";

import { SearchListField, SearchListFieldWithCustomTrigger } from "@new-black/lyra";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniqBy } from "lodash";

import ErrorBoundary from "components/suite-ui/error-boundary";

import { SingleSearchListField } from "./search-list-field-generator.single.types";
import {
  ILocalSearchListFieldGeneratorProps,
  ISearchListFieldGeneratorProps,
  ItemKey,
  SpyCallback,
} from "./search-list-field-generator.types";

export const generateSingleSearchListField = <
  SVC extends IEvaServiceDefinition,
  Item extends object,
  IDType extends ItemKey,
>({
  defaultLabel,
  frontendFilter,
  getDescription,
  getItemFromResponse,
  getItemId,
  getLabel,
  initialData,
  selectRenderElements,
  useServiceQuery,
}: ISearchListFieldGeneratorProps<SVC, Item, IDType>) => {
  const SingleSearchListField = ({
    children,
    disableClearLogic,
    errorMessage,
    isDisabled,
    isInvalid,
    isLoading: isOutsideLoading,
    isRequired,
    label,
    name,
    onChange,
    selectRenderElements: componentSelectRenderElements,
    spyCallback,
    value,
    ...props
  }: SingleSearchListField<Item>["Props"] & SpyCallback<Item>) => {
    const [
      [, setInput],
      { data, isFetching: isLoading },
      [request, setRequest],
      isLocalFiltering,
      [configureLoadMoreButton, isLoadMoreLoading, setIsLoadMoreLoading],
    ] = useServiceQuery();

    const responseItems = useMemo(() => getItemFromResponse(data), [data]);

    const items = useMemo(
      () =>
        frontendFilter
          ? uniqBy([...(initialData ?? []), ...(responseItems ?? [])], getItemId).filter(
              frontendFilter,
            )
          : uniqBy([...(initialData ?? []), ...(responseItems ?? [])], getItemId),
      [responseItems],
    );

    useEffect(
      () => spyCallback?.({ listItems: uniqBy([...(value ? [value] : []), ...items], getItemId) }),
      [items, spyCallback, value],
    );

    const loadMoreButtonConfig = useMemo(
      () => configureLoadMoreButton?.(data),
      [configureLoadMoreButton, data],
    );

    if (children !== undefined) {
      return (
        <ErrorBoundary>
          <SearchListFieldWithCustomTrigger<Item>
            value={value}
            onQueryChange={isLocalFiltering ? undefined : setInput}
            items={items ?? []}
            onChange={onChange}
            isDisabled={isDisabled}
            label={label ?? defaultLabel ?? ""}
            getItemId={getItemId}
            getLabel={getLabel}
            name={name}
            isLoading={(isOutsideLoading ?? false) || isLoading || isLoadMoreLoading}
            onLoadMore={
              loadMoreButtonConfig?.shouldShowLoadMoreButton ?? false
                ? () => {
                    setRequest(loadMoreButtonConfig?.onLoadMore(request));
                    setIsLoadMoreLoading(true);
                  }
                : undefined
            }
            selectRenderElements={(item) => ({
              label: getLabel(item),
              description: getDescription?.(item),
              ...selectRenderElements?.(item),
              ...componentSelectRenderElements?.(item),
            })}
            {...props}
          >
            {children}
          </SearchListFieldWithCustomTrigger>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <SearchListField<Item>
          value={value}
          onQueryChange={isLocalFiltering ? undefined : setInput}
          items={items ?? []}
          onChange={onChange}
          isRequired={isRequired}
          isDisabled={isDisabled}
          errorMessage={errorMessage}
          label={label ?? defaultLabel}
          getItemId={getItemId}
          getLabel={getLabel}
          name={name}
          isLoading={(isOutsideLoading ?? false) || isLoading || isLoadMoreLoading}
          disableClearLogic={disableClearLogic}
          onLoadMore={
            loadMoreButtonConfig?.shouldShowLoadMoreButton ?? false
              ? () => {
                  setRequest(loadMoreButtonConfig?.onLoadMore(request));
                  setIsLoadMoreLoading(true);
                }
              : undefined
          }
          selectRenderElements={(item) => ({
            label: getLabel(item),
            description: getDescription?.(item),
            ...selectRenderElements?.(item),
            ...componentSelectRenderElements?.(item),
          })}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  return SingleSearchListField;
};

export const generateSingleLocalSearchListField = <Item extends object, IDType extends ItemKey>({
  defaultLabel,
  getDescription,
  getItemId,
  getLabel,
  items: itemsArray,
  selectRenderElements,
}: ILocalSearchListFieldGeneratorProps<Item, IDType>) => {
  const SingleSearchListField = ({
    children,
    disableClearLogic,
    errorMessage,
    isDisabled,
    isInvalid,
    isLoading: isOutsideLoading,
    isRequired,
    label,
    name,
    onChange,
    selectRenderElements: componentSelectRenderElements,
    spyCallback,
    value,
    ...props
  }: SingleSearchListField<Item>["Props"] & SpyCallback<Item>) => {
    const items = useMemo(() => uniqBy([...(itemsArray ?? [])], getItemId), []);

    useEffect(
      () => spyCallback?.({ listItems: [...(value ? [value] : []), ...items] }),
      [items, spyCallback, value],
    );

    if (children !== undefined) {
      return (
        <ErrorBoundary>
          <SearchListFieldWithCustomTrigger<Item>
            name={name}
            value={value}
            items={items ?? []}
            onChange={onChange}
            isDisabled={isDisabled}
            label={label ?? defaultLabel ?? ""}
            getItemId={getItemId}
            getLabel={getLabel}
            isLoading={isOutsideLoading}
            selectRenderElements={(item) => ({
              label: getLabel(item),
              description: getDescription?.(item),
              ...selectRenderElements?.(item),
              ...componentSelectRenderElements?.(item),
            })}
            {...props}
          >
            {children}
          </SearchListFieldWithCustomTrigger>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <SearchListField<Item>
          name={name}
          value={value}
          isDisabled={isDisabled}
          items={items ?? []}
          onChange={onChange}
          isRequired={isRequired}
          errorMessage={errorMessage}
          label={label ?? defaultLabel}
          isLoading={isOutsideLoading}
          getLabel={getLabel}
          getItemId={getItemId}
          disableClearLogic={disableClearLogic}
          selectRenderElements={(item) => ({
            label: getLabel(item),
            description: getDescription?.(item),
            ...selectRenderElements?.(item),
            ...componentSelectRenderElements?.(item),
          })}
          {...props}
        />
      </ErrorBoundary>
    );
  };

  return SingleSearchListField;
};
