import { Monitor } from "@springtree/eva-services-monitor";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getAvailableMonitorHandlersLoaderQuery,
  serviceQuery: getAvailableMonitorHandlersService,
  serviceQueryKeys: getAvailableMonitorHandlersQueryKeys,
  useServiceQueryHook: useGetAvailableMonitorHandlersQuery,
  useServiceQueryWithRequest: useGetAvailableMonitorHandlersQueryWithRequest,
} = createServiceQuery(Monitor.GetAvailableMonitorHandlers, true, undefined, true);

export const {
  serviceLoaderQuery: getUserMonitorsLoaderQuery,
  serviceQuery: getUserMonitorsService,
  serviceQueryKeys: getUserMonitorsQueryKeys,
  useServiceQueryHook: useGetUserMonitorsQuery,
  useServiceQueryWithRequest: useGetUserMonitorsQueryWithRequest,
} = createServiceQuery(Monitor.GetUserMonitors, true, undefined, true);

export const {
  serviceLoaderQuery: getMonitorResultLoaderQuery,
  serviceQuery: getMonitorResultService,
  serviceQueryKeys: getMonitorResultQueryKeys,
  useServiceQueryHook: useGetMonitorResultQuery,
  useServiceQueryWithRequest: useGetMonitorResultQueryWithRequest,
} = createServiceQuery(Monitor.GetMonitorResult, true, undefined, true);

export const {
  serviceLoaderQuery: getMonitorLoaderQuery,
  serviceQuery: getMonitorService,
  serviceQueryKeys: getMonitorQueryKeys,
  useServiceQueryHook: useGetMonitorQuery,
  useServiceQueryWithRequest: useGetMonitorQueryWithRequest,
} = createServiceQuery(Monitor.GetMonitor, true, undefined, true);

export const {
  serviceLoaderQuery: getMonitorNotificationsLoaderQuery,
  serviceQuery: getMonitorNotificationsService,
  serviceQueryKeys: getMonitorNotificationsQueryKeys,
  useServiceQueryHook: useGetMonitorNotificationsQuery,
  useServiceQueryWithRequest: useGetMonitorNotificationsQueryWithRequest,
} = createServiceQuery(Monitor.GetMonitorNotifications, true, undefined, true);

export const {
  serviceLoaderQuery: listScriptedMonitorResultsLoaderQuery,
  serviceQuery: listScriptedMonitorResultsService,
  serviceQueryKeys: listScriptedMonitorResultsQueryKeys,
  useServiceQueryHook: useListScriptedMonitorResultsQuery,
  useServiceQueryWithRequest: useListScriptedMonitorResultsQueryWithRequest,
} = createServiceQuery(Monitor.ListScriptedMonitorResults, true, undefined, true);

export const {
  serviceLoaderQuery: getAvailableConditionsForMonitorHandlerLoaderQuery,
  serviceQuery: getAvailableConditionsForMonitorHandlerService,
  serviceQueryKeys: getAvailableConditionsForMonitorHandlerQueryKeys,
  useServiceQueryHook: useGetAvailableConditionsForMonitorHandlerQuery,
  useServiceQueryWithRequest: useGetAvailableConditionsForMonitorHandlerQueryWithRequest,
} = createServiceQuery(Monitor.GetAvailableConditionsForMonitorHandler, true, undefined, true);
