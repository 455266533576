import { CoreManagement } from "@springtree/eva-services-core-management";
import { PIM } from "@springtree/eva-services-pim";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getAssortmentContentCulturesLoaderQuery,
  serviceQuery: getAssortmentContentCulturesQuery,
  serviceQueryKeys: getAssortmentContentCulturesKeys,
  useServiceQueryHook: useGetAssortmentContentCulturesQuery,
  useServiceQueryWithRequest: useGetAssortmentContentCulturesQueryWithRequest,
} = createServiceQuery(PIM.GetAssortmentContentCultures, true);

export const {
  serviceLoaderQuery: listContentCultureMappingLoaderQuery,
  serviceQuery: listContentCultureMappingQuery,
  serviceQueryKeys: listContentCultureMappingKeys,
  useServiceQueryHook: useListContentCultureMappingQuery,
  useServiceQueryWithRequest: useListContentCultureMappingQueryWithRequest,
} = createServiceQuery(PIM.ListContentCultureMapping, true);

export const {
  serviceLoaderQuery: listApplicationCulturesLoaderQuery,
  serviceQuery: listApplicationCulturesQuery,
  serviceQueryKeys: listApplicationCulturesKeys,
  useServiceQueryHook: useListApplicationCulturesQuery,
  useServiceQueryWithRequest: useListApplicationCulturesQueryWithRequest,
} = createServiceQuery(PIM.ListApplicationCultures, true);

export const { serviceQuery: listCulturesQuery, serviceQueryWithOU: listCulturesQueryWithOU } =
  createServiceQuery(CoreManagement.ListCultures, true);
