import { Functionalities, FunctionalityScope } from "types/functionalities";
import { queryClient } from "util/query-client";

import { getFunctionalityRequirements } from "./get-functionality-requirements";

export const authorizeFunctionalityRequirement = async (
  functionality: Functionalities,
  scope?: FunctionalityScope,
  ouId?: number,
) => {
  const requirements = await getFunctionalityRequirements(queryClient, functionality, ouId);

  const getValue = () => {
    switch (scope) {
      case FunctionalityScope.View:
        return requirements.hasViewRights;
      case FunctionalityScope.Edit:
        return requirements.hasEditRights;
      case FunctionalityScope.Create:
        return requirements.hasCreateRights;
      case FunctionalityScope.Delete:
        return requirements.hasDeleteRights;
      case FunctionalityScope.Manage:
        return requirements.hasManageRights;
      case FunctionalityScope.Scripting:
        return requirements.hasScriptingRights;
      case FunctionalityScope.Settings:
        return requirements.hasSettingsRights;
      default:
        return requirements.hasUnscopedRights;
    }
  };

  if (getValue() === false) {
    throw new Response("Forbidden", { status: 403 });
  }
};
