import { Dispatch, SetStateAction, useCallback } from "react";
import { useIntl } from "react-intl";

import { CardHeader, Dialog, DialogCard } from "@new-black/lyra";

import { CustomFieldSettingsForm } from "./custom-field-settings-form";

interface ICustomFieldSettingsModalProps {
  isOpen: boolean;
  customFieldId: number | undefined;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  optionToEdit?: EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto;
  setOptionToEdit: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
}

export const CustomFieldSettingsModal = ({
  customFieldId,
  isOpen,
  optionToEdit,
  setIsOpen,
  setOptionToEdit,
}: ICustomFieldSettingsModalProps) => {
  const intl = useIntl();
  const closeCFSettingsModal = useCallback(() => {
    setOptionToEdit(undefined);
    setIsOpen(false);
  }, [setIsOpen, setOptionToEdit]);

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => {
        if (!open) {
          closeCFSettingsModal();
        }
      }}
      maxWidth="xl"
    >
      <DialogCard stickyFooter stickyHeader>
        <CardHeader
          title={intl.formatMessage({
            id: "custom-field.options.add.title",
            defaultMessage: "Custom field settings",
          })}
        />

        <CustomFieldSettingsForm
          optionToEdit={optionToEdit}
          customFieldId={customFieldId}
          closeCFSettingsModal={closeCFSettingsModal}
        />
      </DialogCard>
    </Dialog>
  );
};

CustomFieldSettingsModal.defaultProps = {
  optionToEdit: undefined,
};
