import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState } from "recoil";

import { customFieldsListService } from "./use-custom-fields-list.state";

export const useCustomFieldsList = (cfTypeId?: number) => {
  const customFieldsList = hooks.useGetState(customFieldsListService.response(cfTypeId));

  const isLoading = hooks.useIsLoading({
    state: customFieldsListService.serviceState.response(cfTypeId),
  });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !customFieldsList,
    [isLoading, customFieldsList],
  );

  const totalFromResponse = hooks.useGetState(customFieldsListService.total(cfTypeId));
  const total = useMemo(() => totalFromResponse ?? 0, [totalFromResponse]);

  const [skip, setSkip] = useRecoilState(customFieldsListService.start(cfTypeId));
  const [limit, setLimit] = useRecoilState(customFieldsListService.limit(cfTypeId));
  const [name, setName] = useRecoilState(customFieldsListService.nameFilter(cfTypeId));

  return {
    cfTypeId,
    isLoading,
    customFieldsList,
    isLoadingWithoutPreviousResponse,
    filter: { name, setName },
    pagination: {
      skip,
      limit,
      total,
      setSkip,
      setLimit,
    },
  };
};

useCustomFieldsList.service = customFieldsListService;
