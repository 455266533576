import { CoreManagement } from "@springtree/eva-services-core-management";

import { listProductRequirementsQuery } from "models/product-requirements";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";

export interface IProductRequirementAutocompleteItem {
  Name: string;
  ID: number;
}

export const generateProductRequirementAutocomplete = (
  initialFilters?: Partial<EVA.Core.ListProductRequirementsFilter>,
  getItemOption?: (
    item: EVA.Core.Management.ProductRequirementDto,
  ) => IProductRequirementAutocompleteItem,
) => {
  const {
    MultiAutocomplete: ProductRequirementMultiAutocomplete,
    SingleAutocomplete: ProductRequirementAutocomplete,
  } = AutocompleteGenerator<
    CoreManagement.ListProductRequirements,
    IProductRequirementAutocompleteItem
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map(
        (productRequirement) =>
          getItemOption?.(productRequirement) ?? {
            ID: productRequirement.ID,
            Name: productRequirement.Name,
          },
      ),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product-requirement",
      defaultMessage: "Product requirement",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listProductRequirementsQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,

            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return {
    ProductRequirementMultiAutocomplete,
    ProductRequirementAutocomplete,
  };
};
