/**
 * Helper function that computes the closest option from available options based on the given value.
 * @param value value to search the closest option for
 * @param options list of options to search from
 * @returns one of the `options` that is closest to the given `value`
 */
export const getClosestFromList = (value: number, options: number[]) => {
  return options.reduce((prev, curr) =>
    Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev,
  );
};

/**
 * Helper function that computes the next multiple of given value.
 * @param value value to search the next multiple for
 * @param multiple target multiple
 * @returns the next `multiple` from the given `value`
 */
export const getNextMultiple = (value: number, multiple: number) => {
  return Math.ceil(value / multiple) * multiple;
};
