import moment, { Moment } from "moment";

export const endOfDayWithoutMilliseconds = (date?: Moment) =>
  date ? moment(date).endOf("day").set("milliseconds", 0) : undefined;

export const isDateBetweenBounds = (date: Moment | undefined, displayedDays: Moment[]) =>
  !!date &&
  date.isSameOrAfter(displayedDays[0]) &&
  date.isSameOrBefore(endOfDayWithoutMilliseconds(displayedDays[displayedDays.length - 1]));

export const isDateToday = (date: Moment) => {
  const today = date.isUTC() ? moment.utc() : moment();

  return moment(date).startOf("day").isSame(today.startOf("day"));
};
