import { ComponentPropsWithoutRef, ReactNode, useMemo } from "react";

import { cva } from "class-variance-authority";
import classNames from "classnames";
import moment, { Moment } from "moment";

import useCalendarItemPosition from "./hooks/use-calendar-item-position";
import { IBaseCalendarItem } from "./types";

interface ItemBoxProps extends ComponentPropsWithoutRef<"span"> {
  isStartDateBetweenBounds: boolean;
  isEndDateBetweenBounds: boolean;
  startColumn: number;
  endColumn: number;
  marginLeft: number;
  marginRight: number;
  snapToFullDay?: boolean;
  isStartTimeAfterMidnight: boolean;
  isEndTimeAfterMidnight: boolean;
  height: number;
}

export interface ICalendarItem<T extends IBaseCalendarItem> {
  item: T;
  displayedDays: Moment[];
  snapToFullDay?: boolean;
  renderItem: (item: T) => ReactNode;
  height: number;
  cellWidth?: number;
}

const CalendarItem = <T extends IBaseCalendarItem>({
  cellWidth,
  displayedDays,
  height,
  item,
  renderItem,
  snapToFullDay,
}: ICalendarItem<T>) => {
  const {
    endColumn,
    isEndDateBetweenBounds,
    isStartDateBetweenBounds,
    leftMargin,
    rightMargin,
    startColumn,
  } = useCalendarItemPosition({
    item,
    displayedDays,
    snapToFullDay,
    cellWidth,
  });
  const isStartTimeAfterMidnight = useMemo(
    () => !!item.StartDate && item.StartDate.isAfter(moment(item.StartDate).startOf("day")),
    [item.StartDate],
  );
  const isEndTimeAfterMidnight = useMemo(
    () => !!item.EndDate && item.EndDate.isAfter(moment(item.EndDate).startOf("day")),
    [item.EndDate],
  );

  return (
    <ItemBox
      isStartDateBetweenBounds={isStartDateBetweenBounds}
      isEndDateBetweenBounds={isEndDateBetweenBounds}
      startColumn={startColumn}
      endColumn={endColumn}
      marginLeft={leftMargin}
      marginRight={rightMargin}
      snapToFullDay={snapToFullDay}
      isStartTimeAfterMidnight={isStartTimeAfterMidnight}
      isEndTimeAfterMidnight={isEndTimeAfterMidnight}
      height={height}
    >
      {renderItem(item)}
    </ItemBox>
  );
};

const itemBoxClassName = cva(
  [
    "bg-[color:#fff] px-3 py-0 relative self-center inline-block",
    "row-span-1 row-start-1 ",
    "overflow-hidden text-ellipsis whitespace-nowrap",
    "rounded border border-solid border-[color:var(--legacy-eva-color-light-3)]",
  ],
  {
    variants: {
      isStartDateBetweenBounds: {
        true: "before:absolute before:left-0 before:w-[4px] before:content-['']",
      },
      isEndDateBetweenBounds: {
        true: "after:absolute after:right-0 after:w-[4px] after:content-['']",
      },
      snapToFullDayAndIsStartAfterMidnight: { true: "" },
      snapToFullDayAndIsEndTimeAfterMidnight: { true: "" },
    },
    compoundVariants: [
      {
        isStartDateBetweenBounds: true,
        snapToFullDayAndIsStartAfterMidnight: true,
        class:
          "before:top-1/2 before:h-1/2 before:translate-y-[-50%] before:bg-[color:var(--legacy-eva-apple-color-orange)]",
      },
      {
        isStartDateBetweenBounds: true,
        snapToFullDayAndIsStartAfterMidnight: false,
        class: "before:top-0 before:bottom-0 before:bg-[color:var(--legacy-eva-color-primary)]",
      },
      {
        isEndDateBetweenBounds: true,
        snapToFullDayAndIsEndTimeAfterMidnight: true,
        class:
          "after:top-1/2 after:h-1/2 after:translate-y-[-50%] after:bg-[color:var(--legacy-eva-apple-color-orange)]",
      },
      {
        isEndDateBetweenBounds: true,
        snapToFullDayAndIsEndTimeAfterMidnight: false,
        class: "after:top-0 after:bottom-0 after:bg-[color:var(--legacy-eva-color-primary)]",
      },
    ],
  },
);

const ItemBox = ({
  children,
  className,
  endColumn,
  height,
  isEndDateBetweenBounds,
  isEndTimeAfterMidnight,
  isStartDateBetweenBounds,
  isStartTimeAfterMidnight,
  marginLeft,
  marginRight,
  snapToFullDay,
  startColumn,
}: ItemBoxProps) => (
  <span
    style={{
      gridColumnStart: startColumn,
      gridColumnEnd: endColumn,
      height: `${height}px`,
      marginLeft: isStartDateBetweenBounds && !snapToFullDay ? `${marginLeft}px` : "0px",
      marginRight: isEndDateBetweenBounds && !snapToFullDay ? `${marginRight}px` : "0px",
    }}
    className={classNames(
      itemBoxClassName({
        isStartDateBetweenBounds,
        isEndDateBetweenBounds,
        snapToFullDayAndIsEndTimeAfterMidnight: snapToFullDay && isEndTimeAfterMidnight,
        snapToFullDayAndIsStartAfterMidnight: snapToFullDay && isStartTimeAfterMidnight,
      }),
      className,
    )}
  >
    {children}
  </span>
);

export default CalendarItem;
