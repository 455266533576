import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const paymentTransactionsForOrderState = builders.buildServiceFamilyState({
  key: "paymentTransactionsForOrderState",
  service: Core.GetPaymentTransactionsForOrder,
  allowEmptyRequest: false,
  requireLoggedIn: true,
});

export const paymentTransactionsForOrder = selectorFamily({
  key: "paymentTransactionsForOrderState:selector",
  get:
    (param) =>
    ({ get }) =>
      get(paymentTransactionsForOrderState.response(param))?.PaymentTransactions,
});
