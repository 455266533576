import { FormattedMessage } from "react-intl";

import { Badge, SvgIcon } from "@new-black/lyra";

export interface IEmailValidatedDisplay {
  isValidated?: boolean;
}

const EmailValidatedDisplay = ({ isValidated }: IEmailValidatedDisplay) => {
  return isValidated ? (
    <SvgIcon name="checkmark" className="text-success" />
  ) : (
    <Badge variant="orange">
      <FormattedMessage id="generic.label.unverified" defaultMessage="Unverified" />
    </Badge>
  );
};

export default EmailValidatedDisplay;
