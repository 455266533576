import { useMemo, useRef } from "react";

import { isEqual } from "lodash";
import { RecoilValue, useRecoilValueLoadable } from "recoil";

export const useLoadableState = <T>(state: RecoilValue<T>, defaultValue?: T) => {
  const loadable = useRecoilValueLoadable(state);

  const isLoading = loadable.state === "loading";

  const value = useRef<T | undefined>(defaultValue);

  if (loadable.state === "hasValue" && !isEqual(value.current, loadable.contents)) {
    value.current = loadable.contents;
  }

  const isLoadingWithoutResponse = useMemo(() => isLoading && !value.current, [isLoading]);

  return {
    isLoading,
    isLoadingWithoutResponse,
    value: value.current,
  };
};
