import { useCallback, useState } from "react";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import { DeleteModal } from "components/suite-composite/confirmation-modal/delete-modal";

import { useProductFilterContext } from "../product-filter-context";
import { IProductFilterModalValues } from "../types";

export interface IProductFilterDeleteRowActionProps {
  rowData?: IProductFilterModalValues;
  variant?: "material" | "lyra";
}

/**
 * Component that can be used to trigger the deletion of a product filter using a confirmation modal
 */
const ProductFilterDeleteRowAction = ({ rowData, variant }: IProductFilterDeleteRowActionProps) => {
  const { isUpdateProductFilterLoading, productFilter, updateProductFilter } =
    useProductFilterContext();

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  const handleDelete = useCallback(() => {
    const filterToArr = productFilter
      ? Object.entries(productFilter)?.map(([key, value]) => ({
          ...value,
          keyName: key,
        }))
      : [];

    const newFilters = filterToArr?.reduce((accumulator, current) => {
      if (rowData?.keyName === current.keyName) return accumulator;

      return {
        ...accumulator,
        [current.keyName]: productFilter?.[current.keyName],
      };
    }, {});

    updateProductFilter(newFilters);
  }, [productFilter, rowData?.keyName, updateProductFilter]);

  return (
    <>
      <DeleteActionButton onPress={() => setShowDeleteConfirmationModal(true)} />

      <DeleteModal
        isOpen={showDeleteConfirmationModal}
        onCancel={() => setShowDeleteConfirmationModal(false)}
        onConfirm={handleDelete}
        isLoading={isUpdateProductFilterLoading}
        variant={variant}
      />
    </>
  );
};

export default ProductFilterDeleteRowAction;
