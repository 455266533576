import { ElementRef, ForwardedRef, forwardRef, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { Checkbox, FieldGroup, KeyValueRow, TextField } from "@new-black/lyra";

import { CustomFieldDataType } from "types/custom-field-data-types";

import DataTypeSelectorControlled from "./field-groups/data-type-selector-controlled";
import EnumOptionsControlled from "./field-groups/enum-options-controlled";
import {
  SingleCustomFieldSchemaKeyNames,
  TCustomFieldAllowedArrayDataTypes,
  TSingleCustomFieldFormSchemaErrors,
} from "./single-custom-field.types";
import { EnumValuesType, ICustomFieldValues } from "./types";

export interface ISingleCustomFieldProps {
  passive?: boolean;
  formID?: string;
  defaultValues: ICustomFieldValues;
  ID?: number;
  errors?: TSingleCustomFieldFormSchemaErrors;
}

const _SingleCustomFieldForm = (
  { defaultValues, errors, formID, ID, passive }: ISingleCustomFieldProps,
  ref: ForwardedRef<ElementRef<typeof Form>>,
) => {
  return (
    <Form id={formID} method="POST" ref={ref}>
      <SingleCustomFieldFormFields
        defaultValues={defaultValues}
        errors={errors}
        ID={ID}
        passive={passive}
      />
    </Form>
  );
};

export const SingleCustomFieldFormFields = ({
  defaultValues,
  errors,
  ID,
  passive,
}: ISingleCustomFieldProps) => {
  const intl = useIntl();
  const [dataType, setDataType] = useState<EVA.Core.CustomFieldDataTypes>(defaultValues.DataType);
  const [isArray, setIsArray] = useState<boolean | undefined>(defaultValues.IsArray);
  const [enums, setEnums] = useState<EnumValuesType | undefined>(defaultValues.EnumValues);
  return (
    <FieldGroup>
      <TextField
        name={SingleCustomFieldSchemaKeyNames.Name}
        label={intl.formatMessage({
          id: "custom-field.input.instruction.label",
          defaultMessage: "Instruction / Name",
        })}
        isRequired
        defaultValue={defaultValues.Name}
      />

      <TextField
        name={SingleCustomFieldSchemaKeyNames.BackendID}
        label={intl.formatMessage({
          id: "generic.label.backend-id",
          defaultMessage: "Backend ID",
        })}
        defaultValue={defaultValues.BackendID}
      />

      <TextField
        label={intl.formatMessage({
          id: "custom-field.input.display-name",
          defaultMessage: "Display name",
        })}
        name={SingleCustomFieldSchemaKeyNames.DisplayName}
        defaultValue={defaultValues.DisplayName}
      />

      <DataTypeSelectorControlled
        passive={passive}
        dataType={dataType}
        setDataType={setDataType}
        isArray={isArray}
        ID={ID}
        setIsArray={setIsArray}
      />
      <input hidden name={SingleCustomFieldSchemaKeyNames.DataType} value={dataType} readOnly />

      {(dataType as unknown as CustomFieldDataType) === CustomFieldDataType.Enum ? (
        <>
          <EnumOptionsControlled
            multiple={isArray}
            passive={passive}
            enums={enums}
            setEnums={setEnums}
            error={errors?.EnumValues}
          />
          <input
            hidden
            name={SingleCustomFieldSchemaKeyNames.EnumValues}
            value={
              enums && dataType === (CustomFieldDataType.Enum as number)
                ? JSON.stringify(enums)
                : ""
            }
            readOnly
          />
        </>
      ) : (
        <>
          {Object.values(TCustomFieldAllowedArrayDataTypes).includes(dataType as number) ? (
            ID ? (
              <KeyValueRow
                label={intl.formatMessage({
                  id: "generic.label.is-array",
                  defaultMessage: "Is array",
                })}
              >
                {intl.formatMessage(
                  isArray
                    ? { id: "generic.label.true", defaultMessage: "True" }
                    : { id: "generic.label.false", defaultMessage: "False" },
                )}
              </KeyValueRow>
            ) : (
              <Checkbox name="IsArrayCheckbox" isSelected={isArray} onChange={setIsArray}>
                <FormattedMessage id="generic.label.is-array" defaultMessage="Is array" />
              </Checkbox>
            )
          ) : null}
        </>
      )}
      <input
        hidden
        name={SingleCustomFieldSchemaKeyNames.TypeID}
        value={defaultValues.TypeID}
        readOnly
      />
      <input
        hidden
        name={SingleCustomFieldSchemaKeyNames.IsArray}
        value={isArray !== undefined ? isArray.toString() : ""}
        readOnly
      />
      <input
        hidden
        name={SingleCustomFieldSchemaKeyNames.Order}
        value={defaultValues.Order}
        readOnly
      />
    </FieldGroup>
  );
};

const SingleCustomFieldForm = forwardRef(_SingleCustomFieldForm);

export default SingleCustomFieldForm;
