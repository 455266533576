import { useMemo } from "react";
import { useIntl } from "react-intl";

import * as Yup from "yup";

import { extractPropertyValues } from "./add-bulk-products-modal.helpers";
import {
  IAddBulkProductsModalFormValues,
  IAddBulkProductsModalValues,
} from "./add-bulk-products-modal.types";

export default function useAddBulkProductsModalForm(
  controlledInitialValues?: IAddBulkProductsModalValues,
) {
  const intl = useIntl();

  const initialValues = useMemo<IAddBulkProductsModalFormValues>(
    () => ({
      productProperty: controlledInitialValues?.productProperty ?? undefined,
      propertyValues: controlledInitialValues?.values?.join(", ") ?? undefined,
    }),
    [controlledInitialValues?.productProperty, controlledInitialValues?.values],
  );

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        productProperty: Yup.string().required(
          intl.formatMessage({
            id: "add-bulk-products-modal.product-property.error.required",
            defaultMessage: "A product property is required.",
          }),
        ),
        propertyValues: Yup.string().test({
          name: "comma-separated-values",
          message: intl.formatMessage({
            id: "add-bulk-products-modal.values.error.required",
            defaultMessage: "Please enter at least one value.",
          }),
          test: (value) => {
            if (!value) {
              return false;
            }
            return extractPropertyValues(value).length > 0;
          },
        }),
      }),
    [intl],
  );

  return { initialValues, validationSchema };
}
