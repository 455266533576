import { forwardRef, useEffect, useRef } from "react";

import { Checkbox } from "@material-ui/core";

const IndeterminateCheckbox = forwardRef(({ indeterminate, onClick, ...rest }: any, ref: any) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox
      className="p-0"
      ref={resolvedRef}
      indeterminate={indeterminate}
      color="primary"
      onClick={onClick ?? undefined}
      {...rest}
    />
  );
});

IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

export default IndeterminateCheckbox;
