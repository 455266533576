import { atom, DefaultValue, RecoilState, selector } from "recoil";

export const autocompleteProxyOUStateGenerator = ({
  key,
  ouIdFieldSelector,
}: {
  key: string;
  ouIdFieldSelector: RecoilState<number | undefined>;
}) => {
  const baseKey = `${key}:AutocompleteProxy`;

  const selectedOUAtom = atom<{ ID?: number; Name?: string; BackendID?: string } | undefined>({
    key: `${baseKey}:Atom`,
    default: undefined,
  });

  const selectedOUSelector = selector({
    key: `${baseKey}:Selector`,
    get: ({ get }) => get(selectedOUAtom),
    set: ({ reset, set }, newValue) => {
      if (!(newValue instanceof DefaultValue)) {
        set(selectedOUAtom, newValue);
        set(ouIdFieldSelector, newValue?.ID);
      } else {
        reset(selectedOUAtom);
        reset(ouIdFieldSelector);
      }
    },
  });

  return { selectedOUAtom, selectedOUSelector };
};
