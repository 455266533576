import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { SearchProductPropertyTypesService } from "./search-product-property-types.state";

export { SearchProductPropertyTypesService } from "./search-product-property-types.state";

export const useSearchProductPropertyTypes = () => {
  const searchProductPropertyTypes = hooks.useGetState(SearchProductPropertyTypesService.result);

  const isLoading = hooks.useIsLoading({
    state: SearchProductPropertyTypesService.serviceState.response,
  });

  const isLoadingWithoutResponse = useMemo(
    () => !searchProductPropertyTypes && isLoading,
    [searchProductPropertyTypes, isLoading],
  );

  return { searchProductPropertyTypes, isLoading, isLoadingWithoutResponse };
};
