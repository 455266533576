import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listCycleCountZoneGroupsLoaderQuery,
  serviceQuery: listCycleCountZoneGroupsQuery,
  serviceQueryKeys: listCycleCountZoneGroupsQueryKeys,
  useServiceQueryHook: useListCycleCountZoneGroupsQuery,
} = createServiceQuery(Core.ListCycleCountZoneGroups);

export const {
  serviceLoaderQuery: validateCycleCountZoneGroupLoaderQuery,
  serviceQuery: validateCycleCountZoneGroupQuery,
  serviceQueryKeys: validateCycleCountZoneGroupQueryKeys,
  useServiceQueryHook: useValidateCycleCountZoneGroupQuery,
} = createServiceQuery(Core.ValidateCycleCountZoneGroup, true, true, false);
