/**
 * Helper that transforms the an object in an `URLSearchParams` object
 * @param paramsObject `object`
 * @returns `URLSearchParams`
 */
export const mapObjectToURLSearchParams = (paramsObject: object) => {
  const urlSearchParams = new URLSearchParams();

  Object.entries(paramsObject)
    .filter(([, value]) => value !== undefined)
    .forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((itemFromArraySearchParam) =>
          urlSearchParams.append(
            key,
            typeof itemFromArraySearchParam === "string"
              ? itemFromArraySearchParam
              : itemFromArraySearchParam?.toString(),
          ),
        );
      } else {
        urlSearchParams.append(key, typeof value === "string" ? value : value?.toString());
      }
    });

  return urlSearchParams;
};
