import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { DataTypes } from "../types";

import {
  useDatatypeResolvers,
  useDataTypeSelectorMenuItems,
} from "./hooks/use-data-type-selectors";

interface IDataTypeSelectorControlled {
  passive?: boolean;
  dataType: EVA.Core.CustomFieldDataTypes;
  setDataType: (newValue: EVA.Core.CustomFieldDataTypes) => void;
  isArray?: boolean;
  setIsArray: (newValue?: boolean) => void;
  ID?: number;
}
const DataTypeSelectorControlled = ({
  dataType,
  ID,
  isArray,
  passive,
  setDataType,
  setIsArray,
}: IDataTypeSelectorControlled) => {
  const intl = useIntl();

  const { boolOrMultiOptionMenuItems, dateMenuItems, lineOrNumberMenuItems } =
    useDataTypeSelectorMenuItems();

  const selectItems = useMemo(
    () => [...lineOrNumberMenuItems, ...boolOrMultiOptionMenuItems, ...dateMenuItems],
    [boolOrMultiOptionMenuItems, dateMenuItems, lineOrNumberMenuItems],
  );

  const { resolveDataTypeToMenuItem, resolveMenuItemToDataType } = useDatatypeResolvers(isArray);

  const handleChange = useCallback(
    (newValue: DataTypes) => {
      switch (newValue) {
        case "line":
        case "multiline":
        case "decimal":
        case "toggle":
        case "date":
        case "datetime":
          setDataType(resolveMenuItemToDataType(newValue));
          setIsArray(undefined);
          break;
        case "number":
          setDataType(resolveMenuItemToDataType(newValue));
          setIsArray(undefined);
          break;
        case "radiobuttons":
          setDataType(resolveMenuItemToDataType(newValue));
          setIsArray(false);
          break;
        case "checkboxes":
          setDataType(resolveMenuItemToDataType(newValue));
          setIsArray(true);
          break;
        default: {
          setDataType(0);
          setIsArray(false);
        }
      }
    },
    [resolveMenuItemToDataType, setDataType, setIsArray],
  );

  return (
    <Select
      label={intl.formatMessage({
        id: "custom-field.input.data-type.label",
        defaultMessage: "Field type",
      })}
      isRequired
      isDisabled={!!ID || passive}
      value={selectItems.find((item) => item.value === resolveDataTypeToMenuItem(dataType))}
      onChange={(item) => {
        if (item) {
          handleChange(item.value);
        }
      }}
      getItemId={(item) => item.value}
      getLabel={(item) => item.label}
      selectRenderElements={(item) => ({
        label: item.label,
      })}
      items={selectItems}
    />
  );
};

export default DataTypeSelectorControlled;
