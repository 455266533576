import { builders } from "@springtree/eva-sdk-react-recoil";
import { Symphony } from "@springtree/eva-services-symphony";

const listSheetHistoryFamilyState = builders.buildServiceFamilyState({
  service: Symphony.ListSheetHistory,
  key: "Symphony:ListSheetHistory",
  requireEmployee: true,
  allowEmptyRequest: false,
});

export default listSheetHistoryFamilyState;
