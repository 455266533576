import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceQuery: listFullStockCountLabelSummaryQuery,
  serviceQueryKeys: listFullStockCountLabelSummarySummaryQueryKeys,
  useServiceQueryHook: useListFullStockCountLabelSummaryQuery,
  useServiceQueryWithRequest: useListFullStockCountLabelSummaryWithRequest,
} = createServiceQuery(Core.ListFullStockCountLabelSummary);
