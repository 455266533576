import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const orderShipmentsState = builders.buildServiceFamilyState({
  key: "orderShipment",
  service: Core.ListOrderShipments,
  allowEmptyRequest: false,
  requireEmployee: true,
  requireLoggedIn: true,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.OrderID) {
      return req;
    }

    return {
      ...req,
      OrderID: parameter as number,
    };
  },
});

export const orderShipments = selectorFamily({
  key: "orderShipmentsState:selector",
  get:
    (param) =>
    ({ get }) =>
      get(orderShipmentsState.response(param))?.Result,
});
