import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  DialogCard,
} from "@new-black/lyra";

import { generateProductSearchTemplateLyraSearchListField } from "../generate-product-search-template-autocomplete";

import { IProductSearchTemplateModalContentProps } from "./product-search-template-modal.types";

const ProductSearchTemplateSearchListField = generateProductSearchTemplateLyraSearchListField();

export function ProductSearchTemplateModalContent({
  close,
  closeOnSave = true,
  errorMessage,
  isCancelDisabled,
  isLoading,
  isSaveDisabled,
  messages,
  onSave,
  productSearchTemplateId: initialProductSearchTemplateId,
}: IProductSearchTemplateModalContentProps) {
  const intl = useIntl();

  const [productSearchTemplateId, setProductSearchTemplateId] = useState<number | undefined>(
    initialProductSearchTemplateId,
  );

  return (
    <DialogCard>
      <CardHeader
        title={
          messages?.title ||
          intl.formatMessage({
            id: "generic.label.add-product-search-template",
            defaultMessage: "Add product search template",
          })
        }
      />

      <CardContent>
        <ProductSearchTemplateSearchListField.SingleIDSearchListField.Controlled
          hideHintLabel
          value={productSearchTemplateId}
          onChange={setProductSearchTemplateId}
          errorMessage={errorMessage}
        />
      </CardContent>

      <CardFooter>
        <ButtonGroup>
          <Button isDisabled={isCancelDisabled || isLoading} onPress={close} variant="secondary">
            {messages?.cancel || (
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            )}
          </Button>
          <Button
            isDisabled={isSaveDisabled}
            onPress={() => {
              onSave(productSearchTemplateId);

              if (closeOnSave) {
                close();
              }
            }}
            isLoading={isLoading}
          >
            {messages?.save || <FormattedMessage id="generic.label.save" defaultMessage="Save" />}
          </Button>
        </ButtonGroup>
      </CardFooter>
    </DialogCard>
  );
}
