/**
 * Utility method to check if a bitwise enum value contains a flag
 * @param value The enum value
 * @param flag The flag to check
 * @returns `true` if the enum value contains the flag; othewrwise `false`
 * @example
 * enum TestEnum {
 *  Flag1 = 1,
 *  Flag2 = 2,
 *  Flag3 = 4,
 *  Flag4 = 8,
 * }
 * const value = TestEnum.Flag1 | TestEnum.Flag2;
 * checkFlaggedEnumValue(value, TestEnum.Flag1); // true
 * checkFlaggedEnumValue(value, TestEnum.Flag3); // false
 * checkFlaggedEnumValue(value, TestEnum.Flag1 | TestEnum.Flag2); // true
 * checkFlaggedEnumValue(value, TestEnum.Flag1 | TestEnum.Flag3); // false
 * checkFlaggedEnumValue(value, TestEnum.Flag1 | TestEnum.Flag2 | TestEnum.Flag3); // false
 */
export const checkFlaggedEnumValue = (value: number, flag: number) => (value & flag) === flag;

/**
 * Utility method to check if a bitwise enum value contains any of the flags
 * @param value The enum value
 * @param flags The flags to check
 * @returns `true` if the enum value contains any of the flags; othewrwise `false`
 * @example
 * enum TestEnum {
 *  Flag1 = 1,
 *  Flag2 = 2,
 *  Flag3 = 4,
 *  Flag4 = 8,
 * }
 * const value = TestEnum.Flag1 | TestEnum.Flag2;
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag1]); // true
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag3]); // false
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag1, TestEnum.Flag2]); // true
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag1, TestEnum.Flag3]); // true
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag1, TestEnum.Flag2, TestEnum.Flag3]); // true
 * checkFlaggedEnumValuesOr(value, [TestEnum.Flag3, TestEnum.Flag4]); // false
 */
export const checkFlaggedEnumValuesOr = (value: number, flags: number[]) =>
  flags.some((flag) => checkFlaggedEnumValue(value, flag));

/**
 * Utility method to check if a bitwise enum value contains all of the flags
 * @param value The enum value
 * @param flags The flags to check
 * @returns `true` if the enum value contains all of the flags; othewrwise `false`
 * @example
 * enum TestEnum {
 *  Flag1 = 1,
 *  Flag2 = 2,
 *  Flag3 = 4,
 *  Flag4 = 8,
 * }
 * const value = TestEnum.Flag1 | TestEnum.Flag2;
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag1]); // true
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag3]); // false
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag1, TestEnum.Flag2]); // true
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag1, TestEnum.Flag3]); // false
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag1, TestEnum.Flag2, TestEnum.Flag3]); // false
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag3, TestEnum.Flag4]); // false
 * checkFlaggedEnumValuesAnd(value, [TestEnum.Flag1, TestEnum.Flag2, TestEnum.Flag4]); // false
 */
export const checkFlaggedEnumValuesAnd = (value: number, flags: number[]) =>
  flags.every((flag) => checkFlaggedEnumValue(value, flag));
