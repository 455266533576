export default {
  module: {
    path: "/promotions",
  },
  promotions: {
    overview: {
      path: "/promotions/promotions",

      searchCoupons: { path: "/promotions/promotions/search-coupons" },
    },

    details: {
      path: "/promotions/promotions/:id",
    },
  },
  personalizedPromotions: {
    overview: {
      path: "/promotions/personalized-promotions",

      create: {
        path: "/promotions/personalized-promotions/new",
      },

      edit: {
        path: "/promotions/personalized-promotions/:id/edit",
      },

      delete: {
        path: "/promotions/personalized-promotions/:id/delete",
      },
    },
  },
  campaigns: {
    wrapper: {
      path: "/promotions/campaigns",
    },
    overview: {
      path: "/promotions/campaigns",
    },
    create: {
      path: "/promotions/campaigns/new",
    },
    details: {
      path: "/promotions/campaigns/:id",
      editInformation: {
        path: "/promotions/campaigns/:id/edit-information",
      },
      editBudget: {
        path: "/promotions/campaigns/:id/edit-budget",
      },
      delete: {
        path: "/promotions/campaigns/:id/delete",
      },
    },
  },

  templates: {
    overview: {
      path: "/promotions/discount-templates",
    },
    details: {
      path: "/promotions/discount-templates/:id",
    },
  },

  loyaltyPrograms: {
    path: "/promotions/loyalty-programs",

    loyaltyProgramsOverview: {
      path: "/promotions/loyalty-programs/loyalty-programs-overview",

      create: {
        path: "/promotions/loyalty-programs/loyalty-programs-overview/new",
      },

      details: {
        path: "/promotions/loyalty-programs/loyalty-programs-overview/:id",

        generalInformation: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information",

          editGeneralInformation: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/edit-general-information",
          },

          customFields: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/custom-fields/new",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/custom-fields/:customFieldID/delete",
            },
          },

          priceLists: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/price-lists/new",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/price-lists/:priceListID/delete",
            },
          },

          ouSets: {
            add: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/new",
            },

            edit: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/:ouSetID/edit",
            },

            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/general-information/ou-sets/:ouSetID/delete",
            },
          },
        },

        pointSavingRules: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/delete",
          },

          conditions: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/delete/:conditionID",
            },

            orderAmount: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/order-amount/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/order-amount/:conditionID/edit",
              },
            },

            productSet: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/product-set/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/product-set/:conditionID/edit",
              },
            },

            consumer: {
              add: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/consumer/new",
              },
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/point-saving-rules/:ruleID/conditions/consumer/:conditionID/edit",
              },
            },
          },
        },

        paymentTypes: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/delete",
          },

          productLimitationData: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/delete-products",
            },

            productSearchTemplate: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-search-template",
            },

            productIDs: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-ids",

              deleteProduct: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-ids/:productID/delete",
              },
            },

            productFilters: {
              createOrEdit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/:propertyName?",
              },

              createBulk: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/new-bulk",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/payment-types/:paymentTypeID/product-filters/:propertyName/delete",
              },
            },
          },
        },

        conditions: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions",

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/delete/:conditionID",
          },

          customer: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/edit-general-information",
              },
            },

            filters: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/:filter",

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/customer/:conditionID/:filter/delete",
              },
            },
          },

          orderType: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-type/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-type/:conditionID/edit-general-information",
              },
            },
          },

          orderProperties: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-properties/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-properties/:conditionID/edit-general-information",
              },
            },
          },

          userCustomFields: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/edit-general-information",
              },
            },

            customFields: {
              create: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/custom-fields/new",
              },

              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/custom-fields/:customFieldID/:index/edit",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/user-custom-fields/:conditionID/:customFieldID/:index/delete",
              },
            },
          },

          orderCustomFields: {
            create: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/new",
            },

            generalInformation: {
              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/edit-general-information",
              },
            },

            customFields: {
              create: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/custom-fields/new",
              },

              edit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/custom-fields/:customFieldID/:index/edit",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/conditions/order-custom-fields/:conditionID/:customFieldID/:index/delete",
              },
            },
          },
        },

        productLimitations: {
          path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation",

          create: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/new",
          },

          edit: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/edit",
          },

          delete: {
            path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/delete",
          },

          productLimitationData: {
            delete: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/delete-products",
            },

            productSearchTemplate: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-search-template",
            },

            productIDs: {
              path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-ids",

              deleteProduct: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-ids/:productID/delete",
              },
            },

            productFilters: {
              createOrEdit: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/:propertyName?",
              },

              createBulk: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/new-bulk",
              },

              delete: {
                path: "/promotions/loyalty-programs/loyalty-programs-overview/:id/product-limitation/:productLimitationID/product-filters/:propertyName/delete",
              },
            },
          },
        },
      },
    },

    loyaltyProgramGroups: {
      path: "/promotions/loyalty-programs/loyalty-program-groups",

      create: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/new",
      },

      edit: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/:id/edit",
      },

      delete: {
        path: "/promotions/loyalty-programs/loyalty-program-groups/:id/delete",
      },
    },
  },
} as const;
