import { Dispatch, SetStateAction, useCallback } from "react";
import { useIntl } from "react-intl";

import { Core } from "@springtree/eva-services-core";
import { useMutation } from "@tanstack/react-query";

import { toast } from "components/suite-ui/toast";
import { mutate } from "util/mutate";

import { AVAILABILITY_LIMIT } from "../../../features/stock-availability/stock-availability.types";

export const mutateSearchProducts = () =>
  mutate({
    service: Core.SearchProducts,
    disabledNotifications: true,
  });

export function useAddBulkProducts({
  limit,
  productIDs,
  setProductIDs,
}: {
  productIDs: number[];
  setProductIDs: Dispatch<SetStateAction<number[]>>;
  /** Limit to be added on `SearchProducts` request. */
  limit: number;
}) {
  const intl = useIntl();

  const { mutateAsync: searchProductsAsync } = useMutation(mutateSearchProducts());

  const handleAddProductsInBulk = useCallback(
    async (productProperty: string, values: string[]) => {
      const result = await searchProductsAsync({
        Filters: { [productProperty]: { Values: values } },
        PageSize: limit + 1,
        IncludedFields: ["product_id"],
      });

      if (result?.success) {
        const productIDsResponse =
          result.response?.Products?.map((product) => parseInt(product.product_id)) ?? [];

        if (productIDsResponse.length + (productIDs?.length ?? 0) > AVAILABILITY_LIMIT) {
          toast.error(
            intl.formatMessage(
              {
                id: "generic.label.limit-selection-to-a-limit-of-products",
                defaultMessage: "Please limit your selection to {limit} products.",
              },
              {
                limit: AVAILABILITY_LIMIT,
              },
            ),
          );
          return;
        }
        setProductIDs?.((prev) => [...prev, ...productIDsResponse]);
      }
    },
    [intl, limit, productIDs?.length, searchProductsAsync, setProductIDs],
  );

  return handleAddProductsInBulk;
}
