import { EnumArray } from "util/enum-to-array";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const EnumLyraSearchListFieldGenerator = (
  items: EnumArray,
  defaultLabel?: string,
  excludeNone = true,
) =>
  SearchListFieldGenerator.LocalSearchListFieldGenerator<{ value: number; key: string }, number>({
    items: items?.filter((item) => (excludeNone ? item.value !== 0 : true)),
    getItemId: (item) => item.value,
    getLabel: (item) => item.key,
    defaultLabel,
    useItemByID: (id, items) => {
      const item = items?.find((item) => item.value === id);

      return {
        data: id ? item : undefined,
        isLoading: false,
      };
    },
  });
