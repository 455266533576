import { useReducer } from "react";

const WORKSPACE_MODALS_CLOSED_STATE = {
  SaveAsNewWorkspace: false,
  SaveFromCurrentLayout: false,
  EditSharePermissions: false,
  Add: false,
  AddFromDefault: false,
  AddShared: false,
  AddSharedFromDefault: false,
  Delete: false,
};

const workspaceModalsReducer = (
  state: typeof WORKSPACE_MODALS_CLOSED_STATE,
  action: keyof typeof WORKSPACE_MODALS_CLOSED_STATE | "Close",
) => {
  switch (action) {
    case "Add":
    case "AddFromDefault":
    case "AddShared":
    case "AddSharedFromDefault":
    case "SaveFromCurrentLayout":
    case "SaveAsNewWorkspace":
    case "EditSharePermissions":
    case "Delete":
      return { ...WORKSPACE_MODALS_CLOSED_STATE, [action]: true };

    case "Close":
      return WORKSPACE_MODALS_CLOSED_STATE;

    default:
      return state;
  }
};

export const useWorkspaceModals = () => {
  const [workspaceModalOpen, toggleWorkspaceModal] = useReducer(
    workspaceModalsReducer,
    WORKSPACE_MODALS_CLOSED_STATE,
  );

  return { workspaceModalOpen, toggleWorkspaceModal };
};
