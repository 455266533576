import { useCallback, useMemo } from "react";

import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";
import { omit } from "lodash";

import { getCustomFieldByIDLoaderQuery, getCustomFieldByIDQuery } from "models/custom-fields";
import { ICustomField } from "types/custom-field";
import { CustomFieldDataType } from "types/custom-field-data-types";

import useApplicationConfiguration, {
  getApplicationConfiguration,
} from "./use-application-configuration";

export interface IAppConfigCustomField extends Omit<ICustomField, "ID" | "DataType"> {
  CustomFieldID: number;
  DataTypeID: CustomFieldDataType;
}

export type ExtendedCustomFields = Record<string, IAppConfigCustomField[]>;

export interface CustomFieldWithTypeID extends ICustomField {
  CFType: string;
}

// This type is used because the typing of the GetCustomFieldByIDResponse is incorrect
// It should be the same as the CustomFieldByIDWithOptions type below
interface CustomFieldByIDWithOptions
  extends Omit<EVA.Core.Management.GetCustomFieldByIDResponse, "DataType"> {
  Options?: EVA.Core.CustomFieldOptions;
  DataType: CustomFieldDataType;
  DataTypeID: CustomFieldDataType;
}

const appConfigCustomFieldToCustomFieldWithTypeIDMapper =
  (customFieldType: string) => (customField: IAppConfigCustomField) => ({
    ...omit(customField, ["CustomFieldID", "DataTypeID"]),
    ID: customField.CustomFieldID,
    CFType: customFieldType,
    DataType: customField.DataTypeID as number,
    EnumValues: customField.EnumValues ?? (customField.Options as any)?.EnumValues,
  });

const useAppConfigCustomFields = (organizationUnitId?: number) => {
  const { data: appConfig, isLoading } = useApplicationConfiguration(organizationUnitId);
  const allCustomFieldsByType = useMemo<ExtendedCustomFields | undefined>(
    () => appConfig?.Configuration?.["ExtendedCustomFields"],
    [appConfig],
  );

  const customFieldTypes = useMemo<(keyof ExtendedCustomFields)[]>(
    () => Object.keys(allCustomFieldsByType ?? {}),
    [allCustomFieldsByType],
  );

  const getCustomFieldsByType = useCallback(
    (customFieldType: string): CustomFieldWithTypeID[] => {
      if (!customFieldTypes.includes(customFieldType)) {
        return [];
      }
      return (
        allCustomFieldsByType?.[customFieldType]?.map(
          appConfigCustomFieldToCustomFieldWithTypeIDMapper(customFieldType),
        ) ?? []
      );
    },
    [customFieldTypes, allCustomFieldsByType],
  );

  const allCustomFields = useMemo<CustomFieldWithTypeID[]>(
    () =>
      customFieldTypes.reduce(
        (acc, customFieldType) => [
          ...acc,
          ...(allCustomFieldsByType?.[customFieldType].map(
            appConfigCustomFieldToCustomFieldWithTypeIDMapper(customFieldType),
          ) ?? []),
        ],
        [] as CustomFieldWithTypeID[],
      ),
    [allCustomFieldsByType, customFieldTypes],
  );

  const getCustomFieldById = useCallback(
    (id?: number) => {
      if (allCustomFieldsByType && id) {
        return allCustomFields.find((cf) => cf.ID === id);
      }
      return undefined;
    },
    [allCustomFields, allCustomFieldsByType],
  );

  return {
    isLoading,
    allCustomFields,
    customFieldTypes,
    getCustomFieldById,
    allCustomFieldsByType,
    getCustomFieldsByType,
  };
};

/** Same as useAppConfigCustomFields, but it can be used in loaders / actions / outside of React */
export const getAppConfigCustomFields =
  (queryClient: QueryClient) => async (organizationUnitId?: number) => {
    const { value: appConfig } = await getApplicationConfiguration(queryClient)(organizationUnitId);
    const allCustomFieldsByType = appConfig?.Configuration?.["ExtendedCustomFields"];

    const customFieldTypes = Object.keys(allCustomFieldsByType ?? {});

    const getCustomFieldsByType = (customFieldType: string): CustomFieldWithTypeID[] => {
      if (!customFieldTypes.includes(customFieldType)) {
        return [];
      }
      return (
        allCustomFieldsByType?.[customFieldType]?.map(
          appConfigCustomFieldToCustomFieldWithTypeIDMapper(customFieldType),
        ) ?? []
      );
    };

    const allCustomFields = customFieldTypes.reduce(
      (acc, customFieldType) => [
        ...acc,
        ...(allCustomFieldsByType?.[customFieldType].map(
          appConfigCustomFieldToCustomFieldWithTypeIDMapper(customFieldType),
        ) ?? []),
      ],
      [] as CustomFieldWithTypeID[],
    );

    const getCustomFieldById = (id?: number) => {
      if (allCustomFieldsByType && id) {
        return allCustomFields.find((cf) => cf.ID === id);
      }
      return undefined;
    };

    return {
      allCustomFieldsByType,
      customFieldTypes,
      getCustomFieldsByType,
      allCustomFields,
      getCustomFieldById,
    };
  };

/**
 * This hook is used to get a custom field by ID. It will first try to get the custom field from the app config
 * and if it is not found there, it will fall back to try to get it from the API (GetCustomFieldByID service)
 */
export const useGetCustomFieldByIdWithFallback = (
  customFieldId?: number,
  organizationUnitId?: number,
) => {
  const { getCustomFieldById: getAppConfigCustomFieldById } =
    useAppConfigCustomFields(organizationUnitId);

  const { data: getCustomFieldByIDResponse } = useQuery({
    ...getCustomFieldByIDQuery(
      customFieldId ? { ID: customFieldId } : undefined,
      undefined,
      organizationUnitId,
    ),
    enabled: !!customFieldId,
  });

  const customField = useMemo(() => {
    const customFieldFromAppConfig = getAppConfigCustomFieldById(customFieldId);
    const customFieldByID = getCustomFieldByIDResponse;
    if (customFieldFromAppConfig) {
      return customFieldFromAppConfig;
    }
    if (customFieldByID) {
      return {
        ...customFieldByID,
        DataType: customFieldByID.DataType as number,
        DataTypeID: customFieldByID.DataType as number,
      } as CustomFieldByIDWithOptions;
    }
    return undefined;
  }, [getAppConfigCustomFieldById, customFieldId, getCustomFieldByIDResponse]);

  return customField;
};

/** Same as useGetCustomFieldByIdWithFallback, but it can be used in loaders / actions / outside of React */
export const getCustomFieldByIdWithFallback =
  (queryClient: QueryClient) => async (customFieldId?: number, organizationUnitId?: number) => {
    const { getCustomFieldById: getAppConfigCustomFieldById } = await getAppConfigCustomFields(
      queryClient,
    )(organizationUnitId);

    const customFieldFromAppConfig = getAppConfigCustomFieldById(customFieldId);

    const { value: customFieldByID } = await getCustomFieldByIDLoaderQuery(
      queryClient,
      customFieldId ? { ID: customFieldId } : undefined,
      undefined,
      { ouId: organizationUnitId },
    )();

    if (customFieldFromAppConfig) {
      return customFieldFromAppConfig;
    }

    if (customFieldByID) {
      return {
        ...customFieldByID,
        DataType: customFieldByID.DataType as number,
        DataTypeID: customFieldByID.DataType as number,
      } as CustomFieldByIDWithOptions;
    }

    return undefined;
  };

/**
 * This hook is used to get a custom field by ID. It will first try to get the custom field from the app config
 * and if it is not found there, it will fall back to try to get it from the API (GetCustomFieldByID service)
 * This hook returns a function that can be used to get the custom field by ID (wrapped in a Promise)
 * This is useful when you need to get a custom field by ID in a callback
 */
export const useGetCustomFieldByIdFnWithFallback = (organizationUnitId?: number) => {
  const queryClient = useQueryClient();
  const { getCustomFieldById: getAppConfigCustomFieldById } =
    useAppConfigCustomFields(organizationUnitId);

  const getCustomFieldById = useCallback(
    async (customFieldId?: number) => {
      const customFieldFromAppConfig = getAppConfigCustomFieldById(customFieldId);
      if (customFieldFromAppConfig) {
        return customFieldFromAppConfig;
      }
      const customFiueldByID = await queryClient.fetchQuery({
        ...getCustomFieldByIDQuery(
          customFieldId ? { ID: customFieldId } : undefined,
          undefined,
          organizationUnitId,
        ),
      });
      if (customFiueldByID) {
        return {
          ...customFiueldByID,
          DataType: customFiueldByID.DataType as number,
          DataTypeID: customFiueldByID.DataType as number,
        } as CustomFieldByIDWithOptions;
      }
      return undefined;
    },
    [getAppConfigCustomFieldById, organizationUnitId, queryClient],
  );

  return getCustomFieldById;
};

/** Same as useGetCustomFieldByIdFnWithFallback, but it can be used in loaders / actions / outside of React */
export const getCustomFieldByIdFnWithFallback =
  (queryClient: QueryClient) => async (organizationUnitId?: number) => {
    const { getCustomFieldById: getAppConfigCustomFieldById } = await getAppConfigCustomFields(
      queryClient,
    )(organizationUnitId);

    return async (customFieldId?: number) => {
      const customFieldFromAppConfig = getAppConfigCustomFieldById(customFieldId);
      if (customFieldFromAppConfig) {
        return customFieldFromAppConfig;
      }
      const { value: customFieldByID } = await getCustomFieldByIDLoaderQuery(
        queryClient,
        customFieldId ? { ID: customFieldId } : undefined,
        undefined,
        { ouId: organizationUnitId },
      )();
      if (customFieldByID) {
        return {
          ...customFieldByID,
          DataType: customFieldByID.DataType as number,
          DataTypeID: customFieldByID.DataType as number,
        } as CustomFieldByIDWithOptions;
      }
      return undefined;
    };
  };

export default useAppConfigCustomFields;
