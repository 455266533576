import { useEffect } from "react";

import { useGetProductsQuery } from "models/products";

import { IProductSetProduct } from "../types";

export const useInitializeProductSetModalIncludedProducts = ({
  productIDs,
  setIncludedProducts,
}: {
  productIDs?: number[];
  setIncludedProducts: React.Dispatch<React.SetStateAction<IProductSetProduct[]>>;
}) => {
  const { data: productsDetails } = useGetProductsQuery(
    productIDs
      ? {
          ProductIDs: productIDs,
          IncludedFields: [
            "product_id",
            "backend_id",
            "display_value",
            "custom_id",
            "primary_image",
          ],
        }
      : undefined,
    {},
  );

  useEffect(() => {
    if (productsDetails) {
      setIncludedProducts(
        productIDs?.map((id) => ({
          ID: id,
          Name: productsDetails?.Products?.[id]?.["display_value"],
          ImageBlobID: productsDetails?.Products?.[id]?.["primary_image"]?.["blob"],
          BackendID: productsDetails?.Products?.[id]?.["backend_id"],
          CustomID: productsDetails?.Products?.[id]?.["custom_id"],
        })) ?? [],
      );
    }
  }, [productIDs, productsDetails, setIncludedProducts]);
};
