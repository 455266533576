import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { customFieldByIDService } from "./use-custom-field-by-id.state";

export const useCustomFieldByID = (customFieldId?: number) => {
  const customField = hooks.useGetState(customFieldByIDService.response(customFieldId));

  const isLoading = hooks.useIsLoading({
    state: customFieldByIDService.serviceState.response(customFieldId),
  });
  const isLoadingWithoutPreviousRespose = useMemo(
    () => isLoading && !customField,
    [isLoading, customField],
  );

  return {
    isLoading,
    customField,
    isLoadingWithoutPreviousRespose,
  };
};

useCustomFieldByID.service = customFieldByIDService;
