import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getEntityTranslationLoaderQuery,
  serviceQuery: getEntityTranslationQuery,
  serviceQueryKeys: GetEntityTranslationQueryKeys,
  useServiceQueryHook: useGetEntityTranslationQuery,
} = createServiceQuery(Core.GetEntityTranslation, true);

export const {
  serviceLoaderQuery: listEntityTranslationsLoaderQuery,
  serviceQuery: listEntityTranslationsQuery,
  serviceQueryKeys: ListEntityTranslationsQueryKeys,
  useServiceQueryHook: useListEntityTranslationsQuery,
  useServiceQueryWithRequest: useListEntityTranslationsQueryWithRequest,
} = createServiceQuery(Core.ListEntityTranslations, true);
