import { IntlShape } from "react-intl";

import { ProductPropertyTypeDataTypes } from "./eva-core";

export const translateProductPropertyTypeDataTypes = (intl: IntlShape) => {
  const lut: Record<ProductPropertyTypeDataTypes, string> = {
    [ProductPropertyTypeDataTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [ProductPropertyTypeDataTypes.Integer]: intl.formatMessage({
      id: "generic.label.integer",
      defaultMessage: "Integer",
    }),
    [ProductPropertyTypeDataTypes.Double]: intl.formatMessage({
      id: "generic.label.double",
      defaultMessage: "Double",
    }),
    [ProductPropertyTypeDataTypes.Boolean]: intl.formatMessage({
      id: "generic.label.boolean",
      defaultMessage: "Boolean",
    }),
    [ProductPropertyTypeDataTypes.String]: intl.formatMessage({
      id: "generic.label.string",
      defaultMessage: "String",
    }),
    [ProductPropertyTypeDataTypes.Blob]: intl.formatMessage({
      id: "generic.label.blob",
      defaultMessage: "Blob",
    }),
    [ProductPropertyTypeDataTypes.Date]: intl.formatMessage({
      id: "generic.label.date",
      defaultMessage: "Date",
    }),
    [ProductPropertyTypeDataTypes.RichText]: intl.formatMessage({
      id: "generic.label.rich-text",
      defaultMessage: "Rich Text",
    }),
  };

  return (type: ProductPropertyTypeDataTypes) => {
    if (type in lut) {
      return lut[type];
    }
    return undefined;
  };
};
