import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MenuItemProps } from "components/suite-ui/select";
import { DiscountOrderType } from "types/discount-order-type";

const useDiscountOrderTypes = () => {
  const intl = useIntl();

  const discountOrderTypes = useMemo<MenuItemProps[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "discounts.order-types.sales",
          defaultMessage: "Sales",
        }),
        value: DiscountOrderType.Sales,
      },
      {
        label: intl.formatMessage({
          id: "discounts.order-types.purchase",
          defaultMessage: "Purchase",
        }),
        value: DiscountOrderType.Purchase,
      },
      {
        label: intl.formatMessage({
          id: "discounts.order-types.all",
          defaultMessage: "All",
        }),
        value: DiscountOrderType.All,
      },
    ],
    [intl],
  );

  return { discountOrderTypes };
};

export default useDiscountOrderTypes;
