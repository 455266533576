import { useEffect, useState } from "react";

import type { MenuItemProps } from "../select";

import TableCountablePagination from "./table-countable-pagination";
import type {
  IPaginationTableKeys,
  ITableCountablePaginationProps,
  ITableUncountablePaginationProps,
} from "./table-pagination-types";
import TableUncountablePagination from "./table-uncountable-pagination";

export interface ITablePaginationProps {
  pageOptions: number[];
  showPagination: boolean;
  disablePagination?: boolean;
  isCountablePaginationPossible: boolean;
  paginationTableStrings: IPaginationTableKeys;
  hidePageOptionsSelect?: boolean;
  countablePaginationProps: Omit<
    ITableCountablePaginationProps,
    "paginationTableStrings" | "pageOptionsMenuItems"
  >;
  uncountablePaginationProps: Omit<
    ITableUncountablePaginationProps,
    "paginationTableStrings" | "pageOptionsMenuItems"
  >;
}

const TablePagination = ({
  countablePaginationProps,
  disablePagination,
  hidePageOptionsSelect,
  isCountablePaginationPossible,
  pageOptions,
  paginationTableStrings,
  showPagination,
  uncountablePaginationProps,
}: ITablePaginationProps) => {
  const [pageOptionsMenuItems, setPageOptionsMenuItems] = useState<MenuItemProps[]>();

  useEffect(() => {
    if (pageOptions.length) {
      const mappedOptions: MenuItemProps[] = pageOptions.map((item) => ({
        label: item.toString(),
        value: item,
      }));
      setPageOptionsMenuItems(mappedOptions);
    }
  }, [pageOptions, setPageOptionsMenuItems]);

  if (showPagination) {
    if (isCountablePaginationPossible) {
      return (
        <TableCountablePagination
          {...countablePaginationProps}
          disabled={disablePagination}
          paginationTableStrings={paginationTableStrings}
          pageOptionsMenuItems={pageOptionsMenuItems}
          hidePageOptionsSelect={hidePageOptionsSelect}
        />
      );
    } else if (!!uncountablePaginationProps.onNext && !!uncountablePaginationProps.onPrevious) {
      return (
        <TableUncountablePagination
          {...uncountablePaginationProps}
          paginationTableStrings={paginationTableStrings}
          pageOptionsMenuItems={pageOptionsMenuItems}
          hidePageOptionsSelect={hidePageOptionsSelect}
        />
      );
    }
  }
  return null;
};

export default TablePagination;
