import { MutableRefObject } from "react";
import { useIntl } from "react-intl";

import { Combobox } from "@headlessui/react";
import { SvgIcon } from "@new-black/lyra";
import classnames from "classnames";

import IconButton from "components/suite-ui/icon-button";

import {
  ISearchListFieldOptionsProps,
  SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD,
  TSearchListFieldKeyType,
} from "../types";

export const SearchListFieldComboboxInput = <T extends object>({
  autoFocus,
  inputRef,
  loadMoreButtonRef,
  onClear,
  query,
  setQuery,
}: Pick<
  ISearchListFieldOptionsProps<T, TSearchListFieldKeyType<T>, TSearchListFieldKeyType<T>>,
  "query" | "setQuery" | "autoFocus"
> & {
  onClear?: () => void;
  inputRef: MutableRefObject<HTMLInputElement | null>;
  loadMoreButtonRef: MutableRefObject<HTMLButtonElement | null>;
}) => {
  const intl = useIntl();

  return (
    <div
      className={classnames(
        "relative flex items-center gap-2 px-5",
        "border border-x-0 border-t-0 border-solid border-default bg-surface-primary",
        "rounded-t-xl",
      )}
    >
      <SvgIcon
        name="search"
        className="pointer-events-none h-5 w-5 shrink-0 text-[color:var(--color-primary-3)]"
      />

      <Combobox.Input
        ref={inputRef}
        className="text-legacy-base placeholder:text-legacy-base w-full border-0 bg-transparent text-primary placeholder:text-tertiary"
        style={{ height: SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD }}
        autoFocus={autoFocus}
        placeholder={intl.formatMessage({
          id: "generic.label.search",
          defaultMessage: "Search",
        })}
        displayValue={() => query ?? ""}
        onKeyDownCapture={(event) => {
          // Prevents navigating out of the modal
          if (event.code === "Tab") {
            event.preventDefault();

            loadMoreButtonRef.current?.focus();
          }
        }}
        onChange={(event) => {
          setQuery(event.target.value);
        }}
      />

      {query && onClear ? (
        <IconButton size="small" edge="end" onClick={onClear}>
          <SvgIcon name="alert-error" className="h-5 w-5" />
        </IconButton>
      ) : null}
    </div>
  );
};
