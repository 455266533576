import { useIntl } from "react-intl";

import { FieldGroup, SearchField } from "@new-black/lyra";

import { LoyaltyPointUsageOptionsSearchListField } from "components/suite-composite/loyalty-point-usage-options-search-list-field";
import { generateLoyaltyProgramGroupSearchListField } from "components/suite-composite/loyalty-program-group-search-list-field";
import { LoyaltyProgramHandlerSearchListField } from "components/suite-composite/loyalty-program-handler-search-list-field";
import { LoyaltyProgramStatus } from "features/loyalty-programs/loyalty-program-form";

import { LoyaltyProgramStatusSegmentedControl } from ".";

export interface ILoyaltyProgramTableFilters {
  name?: string;
  setName: (newValue?: string) => void;
  handler?: string;
  setHandler: (newValue?: string) => void;
  loyaltyPointUsageOptions?: number[];
  setLoyaltyPointUsageOptions: (newValue?: number[]) => void;
  status?: LoyaltyProgramStatus;
  setStatus: (newValue?: LoyaltyProgramStatus) => void;
  loyaltyProgramGroup?: number;
  setLoyaltyProgramGroup: (newValue?: number) => void;
}

export const LoyaltyProgramTableFilters = ({
  handler,
  loyaltyPointUsageOptions,
  loyaltyProgramGroup,
  name,
  setHandler,
  setLoyaltyPointUsageOptions,
  setLoyaltyProgramGroup,
  setName,
  setStatus,
  status,
}: ILoyaltyProgramTableFilters) => {
  const intl = useIntl();

  return (
    <FieldGroup>
      <SearchField
        label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
        value={name ?? ""}
        onChange={(newValue) => setName(newValue || undefined)}
        hideHintLabel
      />

      <LoyaltyProgramHandlerSearchListField.Controlled
        hideHintLabel
        value={handler ? { Name: handler } : undefined}
        onChange={(newValue) => setHandler(newValue?.Name || undefined)}
      />

      <LoyaltyPointUsageOptionsSearchListField
        value={loyaltyPointUsageOptions}
        onChange={setLoyaltyPointUsageOptions}
        hideHintLabel
      />

      <LoyaltyProgramGroupIDSearchListField.Controlled
        hideHintLabel
        value={loyaltyProgramGroup}
        onChange={setLoyaltyProgramGroup}
      />

      <LoyaltyProgramStatusSegmentedControl value={status} onChange={setStatus} hideInputHeader />
    </FieldGroup>
  );
};

const { SingleIDSearchListField: LoyaltyProgramGroupIDSearchListField } =
  generateLoyaltyProgramGroupSearchListField();
