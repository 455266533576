import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonProps,
  Popover,
  PopoverProps,
  PopoverTrigger,
  SvgIcon,
  Text,
} from "@new-black/lyra";

export interface IRevisionCommentPopoverButton {
  revisionComment?: string;
  buttonVariant?: Extract<ButtonProps["variant"], "icon" | "icon-xs">;
  popoverPlacement?: PopoverProps["placement"];
}

const RevisionCommentPopoverButton = ({
  buttonVariant,
  popoverPlacement,
  revisionComment,
}: IRevisionCommentPopoverButton) => {
  const intl = useIntl();

  return (
    <PopoverTrigger>
      <Button
        variant={buttonVariant ?? "icon"}
        tooltip={intl.formatMessage({
          id: "generic.label.revision-comment",
          defaultMessage: "Revision comment",
        })}
      >
        <SvgIcon name="speech-bubble" />
      </Button>
      <Popover placement={popoverPlacement}>
        <Text slot="title" variant="heading-2">
          <FormattedMessage id="generic.label.revision-comment" defaultMessage="Revision comment" />
        </Text>
        <Text variant="body-medium">
          {revisionComment || (
            <FormattedMessage
              id="generic.label.no-revision-comment-specified"
              defaultMessage="No revision comment specified."
            />
          )}
        </Text>
      </Popover>
    </PopoverTrigger>
  );
};

export default RevisionCommentPopoverButton;
