import { ReactNode } from "react";

export const Container = ({ children }: { children: ReactNode }) => (
  <div className="flex w-full items-center justify-end">{children}</div>
);

export const Item = ({ children }: { children: ReactNode }) => (
  <div className="ml-[1.5rem] flex flex-wrap items-center">{children}</div>
);

export const ItemText = ({ children }: { children: ReactNode }) => (
  <span className="mr-[16px] text-xs leading-[0.875rem] text-[rgba(0,0,0,.6)] [font-family:var(--font-family)]">
    {children}
  </span>
);

export const Panel = ({ children }: { children: ReactNode }) => (
  <div className="px-4 py-3">{children}</div>
);
