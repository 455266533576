import { FormattedMessage } from "react-intl";

import { Button, ButtonGroup, CardFooter } from "@new-black/lyra";

import { filterModelToEVAFilterModel, validateFilterModalState } from "../helpers";
import {
  EVAFilterModel,
  ProductFilterModalMessages,
  ReducerStateProps,
} from "../product-filter-modal.types";

interface IProductFilterModalFooterProps extends ReducerStateProps {
  close?: () => void;
  messages?: ProductFilterModalMessages;
  onChange: (newFilter: EVAFilterModel) => void;
  isLoading?: boolean;
  closeOnSave?: boolean;
}

export const ProductFilterModalFooter = ({
  close,
  closeOnSave,
  dispatch,
  isLoading,
  messages,
  onChange,
  state,
}: IProductFilterModalFooterProps) => (
  <CardFooter>
    <ButtonGroup>
      <Button variant="secondary" onPress={close}>
        {messages?.cancel || <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />}
      </Button>
      <Button
        onPress={() => {
          validateFilterModalState(state, dispatch, (validState) => {
            onChange(filterModelToEVAFilterModel(validState));
            if (closeOnSave) {
              close?.();
            }
          });
        }}
        isLoading={isLoading}
      >
        {messages?.save || <FormattedMessage id="generic.label.save" defaultMessage="Save" />}
      </Button>
    </ButtonGroup>
  </CardFooter>
);
