import { Cookbook } from "@springtree/eva-services-cookbook";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: previewAccountingRecipeLoaderQuery,
  useServiceQueryHook: usePreviewAccountingRecipeQuery,
} = createServiceQuery(Cookbook.PreviewAccountingRecipe);

export const {
  serviceLoaderQuery: listAccountingRecipesLoaderQuery,
  useServiceQueryHook: useListAccountingRecipesQuery,
  serviceQueryKeys: listAccountingRecipesQueryKeys,
} = createServiceQuery(Cookbook.ListAccountingRecipes);

export const {
  serviceLoaderQuery: getAccountingRecipeLoaderQuery,
  useServiceQueryHook: useGetAccountingRecipeQuery,
  serviceQueryKeys: getAccountingRecipeQueryKeys,
} = createServiceQuery(Cookbook.GetAccountingRecipe);

export const {
  serviceLoaderQuery: getAccountingRecipeHistoryLoaderQuery,
  useServiceQueryHook: useGetAccountingRecipeHistoryQuery,
  serviceQueryKeys: getAccountingRecipeHistoryQueryKeys,
} = createServiceQuery(Cookbook.GetAccountingRecipeHistory);
