import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Badge } from "@new-black/lyra";

import { YesNoStatusLabel } from "components/shared/yes-no-status-label";

const useRenderPimPropertyTableCell = () => {
  const intl = useIntl();

  const renderPimPropertyTableCell = useCallback(
    ({
      pimProperty,
      renderArraysInBadges,
      value,
    }: {
      pimProperty: string;
      value: any;
      renderArraysInBadges: boolean;
    }) => {
      if (value === undefined) {
        return "";
      }
      if (typeof value === "boolean") {
        return <YesNoStatusLabel value={value} />;
      }
      if (
        typeof value === "string" &&
        pimProperty.toLowerCase().includes("date") &&
        !pimProperty.toLowerCase().includes("daysago")
      ) {
        return `${intl.formatDate(value)} ${intl.formatTime(value)}`;
      }
      if (typeof value === "number" || typeof value === "string") {
        return `${value}`;
      }
      if (Array.isArray(value)) {
        if (renderArraysInBadges) {
          return (
            <div className="flex flex-wrap gap-2">
              {value.map((item, index) => (
                <Badge variant="transparent" key={index}>{`${item}`}</Badge>
              ))}
            </div>
          );
        }
        return value.map(String).join(", ");
      }
      return "";
    },
    [intl],
  );

  return renderPimPropertyTableCell;
};

export default useRenderPimPropertyTableCell;
