import classNames from "classnames";
import { v4 as uuid } from "uuid";

import Grid, { GridProps } from "components/suite-ui/grid";

import ProductBasicInformation from "./product-basic-information";
import ProductDetails from "./product-detailed-information";
import ProductImageGallery from "./product-image-gallery";
import { IProductInformationProps } from "./product-information.types";
import ProductInformationHeader from "./product-information-header";

const GridItemWidthVerticalDivider = ({ className, ...props }: GridProps) => (
  <Grid
    {...props}
    className={classNames(
      "border-0 min-960:border-r min-960:border-solid min-960:border-[color:var(--legacy-eva-color-light-3)]",
      className,
    )}
  />
);

/**
 * Component that allows displaying information aboud a product
 *
 */
const ProductInformation = ({
  onOpenCallback,
  product,
  productNameProperty,
}: IProductInformationProps) => {
  return (
    <>
      <div className="min-h-[48px] p-5 pb-2.5 pr-1 pt-1">
        <ProductInformationHeader onOpenCallback={onOpenCallback} />
      </div>
      <div className="p-5 pt-0">
        <Grid container>
          <GridItemWidthVerticalDivider item xs={12} md={6} lg={9}>
            <div className="mr-5">
              <ProductBasicInformation
                product={product}
                productNameProperty={productNameProperty}
              />
            </div>
            <div className="mr-5 max-w-[500px]">
              <ProductDetails product={product} />
            </div>
          </GridItemWidthVerticalDivider>
          <Grid item xs={12} md={6} lg={3}>
            <Grid container justifyContent="center">
              <div className="overflow-hidden pl-5">
                <ProductImageGallery key={uuid()} product={product} />
              </div>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default ProductInformation;
