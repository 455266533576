import { toast as reactHotToast, ToasterProps } from "react-hot-toast";

import { warningToast } from "./warning-toast";

/**
 * Use this configuration when using react-hot-toast in the Toaster component.
 * Place the Toaster component as a child of the BootstrapProvider
 * @docs https://react-hot-toast.com/docs/version-2
 */
export const ToastOptions: ToasterProps["toastOptions"] = {
  position: "bottom-center",
  style: {
    backgroundColor: "#3F3F42",
    color: "#fff",
    fontSize: "0.875rem",
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Inter', 'Helvetica Neue', sans-serif",
    borderRadius: "0.375rem",
    minWidth: "200px",
    wordBreak: "break-word",
  },
  error: { duration: 6000 },
  success: { duration: 6000 },
  blank: { duration: 6000 },
};

export const toast = {
  ...reactHotToast,
  warning: warningToast,
};
