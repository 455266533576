import { NumericFormat, NumericFormatProps } from "react-number-format";

import { InputBaseComponentProps } from "@material-ui/core";

export interface NumericFormatCustomProps {
  inputRef: (instance: NumericFormatProps<InputBaseComponentProps> | null) => void;
  onChange: (event: { target: { value: string; floatValue?: number } }) => void;
  currencyPrefix: string;
}

export interface IAmountInput extends NumericFormatProps<InputBaseComponentProps> {
  CurrencyID: string | undefined;
  fullWidth?: boolean;
  helperText?: string;
  precision: number;
  inputRef?: any;
  placeholder?: string;
}

export const AmountInputComponentWrapper = (props: { [x: string]: any; component: any }) => {
  const { component: Component, ...other } = props;

  return <Component {...other} />;
};

export const NumberFormatCustomComponent: React.FC<NumericFormatCustomProps> = (
  props: NumericFormatCustomProps,
) => {
  const { currencyPrefix, inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            floatValue: values.floatValue,
          },
        });
      }}
      thousandSeparator={(other as any).thousandSeparator ?? undefined}
      valueIsNumericString
      prefix={currencyPrefix}
      decimalScale={(other as any).decimalScale ?? 2}
      fixedDecimalScale={(other as any).fixedDecimalScale ?? false}
      decimalSeparator={(other as any).decimalSeparator ?? undefined}
    />
  );
};
