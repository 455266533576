import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";
import { ProductSearchModalListItem } from "../product-search-modal-list-item";
import type { IProductMultiSearchModalProps, IProductSearchModalResultBase } from "../types";

interface IProductSearchModalRightPaneListItemProps<TProduct extends IProductSearchModalResultBase>
  extends Pick<IProductMultiSearchModalProps<TProduct>, "rightPaneListItemRenderElements"> {
  product: TProduct;
}

export function ProductSearchModalRightPaneListItem<
  TProduct extends IProductSearchModalResultBase,
>({
  product,
  rightPaneListItemRenderElements,
}: IProductSearchModalRightPaneListItemProps<TProduct>) {
  const intl = useIntl();

  const { customContent, ...renderElements } = useMemo(
    () => rightPaneListItemRenderElements(product),
    [product, rightPaneListItemRenderElements],
  );

  const { setSelectedProducts } = useProductSearchModalContext<TProduct>();

  return (
    <div className="flex items-center gap-4 px-4">
      <div className="min-w-0 flex-1">
        <ProductSearchModalListItem {...renderElements} />
      </div>

      {customContent}

      <Button
        variant="icon"
        onPress={() => {
          setSelectedProducts((current) =>
            current.filter((x) => x.product_id !== product.product_id),
          );
        }}
        tooltip={intl.formatMessage({
          id: "generic.label.delete",
          defaultMessage: "Delete",
        })}
      >
        <SvgIcon name="delete" />
      </Button>
    </div>
  );
}
