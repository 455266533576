import { AuthenticationOpenID } from "@springtree/eva-services-authentication-openid";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listOpenIDProvidersLoaderQuery,
  serviceQuery: listOpenIDProvidersQuery,
  serviceQueryKeys: listOpenIDProvidersQueryKeys,
  useServiceQueryHook: useListOpenIDProvidersQuery,
} = createServiceQuery(AuthenticationOpenID.ListOpenIDProviders, true);

export const {
  serviceLoaderQuery: getOpenIDProviderByIDLoaderQuery,
  serviceQuery: getOpenIDProviderByIDQuery,
  serviceQueryKeys: getOpenIDProviderByIDQueryKeys,
  useServiceQueryHook: useGetOpenIDProviderByIDQuery,
} = createServiceQuery(AuthenticationOpenID.GetOpenIDProviderByID, true);
