import { FixedSizeList, FixedSizeListProps, ListChildComponentProps } from "react-window";

import classNames from "classnames";
import { AnimatePresence } from "framer-motion";
import { min } from "lodash";

import { Separator } from "@new-black/lyra";

import useAnimationProps from "./hooks/use-animation-props";
import NoResultsMenuItem from "./no-results-menu-item";
import OrganizationUnitFilter from "./organization-unit-filter";
import OrganizationUnitMenuItem from "./organization-unit-menu-item";
import {
  IOrganizationUnitMenuItem,
  IOrganizationUnitStructure,
} from "./organization-unit-selector.types";
import ParentOrganizationUnit from "./parent-organization-unit";

export const StyledFixedSizeList = ({ className, ...props }: FixedSizeListProps) => (
  <FixedSizeList {...props} className={classNames("!overflow-x-hidden", className)} />
);

export const OrganizationUnitSelectorMenu = ({
  debouncedInputValue,
  direction,
  handleItemClick,
  menuItems,
  onGoBack,
  onGoNext,
  parentOrganizationUnit,
  previousMenuItemsLength,
  selectedOrganizationUnit,
}: {
  parentOrganizationUnit?: IOrganizationUnitStructure;
  previousMenuItemsLength?: number;
  menuItems?: IOrganizationUnitMenuItem[];
  debouncedInputValue?: string;
  direction: number;
  onGoBack: () => void;
  onGoNext: (item: IOrganizationUnitMenuItem) => void;
  selectedOrganizationUnit?: IOrganizationUnitStructure;
  handleItemClick: (item: IOrganizationUnitMenuItem) => void;
}) => {
  const { animationProps } = useAnimationProps(
    direction,
    !!parentOrganizationUnit,
    previousMenuItemsLength,
  );

  return (
    <div>
      <div>
        <OrganizationUnitFilter />
        <Separator className="my-2" />
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <div className="h-[500px]" key={menuItems?.length} {...animationProps}>
            {parentOrganizationUnit && !debouncedInputValue ? (
              <ParentOrganizationUnit
                name={parentOrganizationUnit?.Name}
                onGoBack={parentOrganizationUnit ? onGoBack : undefined}
              />
            ) : null}
            {menuItems?.length ? (
              <StyledFixedSizeList
                height={(min([menuItems.length, 9]) ?? 9) * 50}
                width="100%"
                itemSize={50}
                itemCount={menuItems.length}
                itemKey={(index: number) => menuItems?.[index]?.ID}
              >
                {({ index, style }: ListChildComponentProps) => (
                  <OrganizationUnitMenuItem
                    style={style}
                    key={menuItems?.[index]?.ID}
                    id={menuItems?.[index]?.ID}
                    name={menuItems?.[index]?.Name}
                    backendId={menuItems?.[index]?.BackendID}
                    isSelected={menuItems?.[index]?.ID === selectedOrganizationUnit?.ID}
                    onClick={() => handleItemClick(menuItems?.[index])}
                    onGoNext={
                      menuItems?.[index]?.HasChildren
                        ? () => onGoNext(menuItems?.[index])
                        : undefined
                    }
                  />
                )}
              </StyledFixedSizeList>
            ) : (
              <NoResultsMenuItem />
            )}
          </div>
        </AnimatePresence>
      </div>
    </div>
  );
};
