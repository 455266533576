import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

export const getDiscountTemplateByIDServiceState = builders.buildServiceFamilyState({
  service: CoreManagement.GetDiscountTemplateByID,
  key: "CoreManagement:GetDiscountTemplateByID",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.ID) {
      return req;
    }

    return {
      ...req,
      ID: parameter,
    };
  },
});

export const getDiscountTemplateByIDResultSelector = selectorFamily({
  key: "CoreManagement:GetDiscountTemplateByID:Result",
  get:
    (discountTemplateId) =>
    ({ get }) =>
      get(getDiscountTemplateByIDServiceState.response(discountTemplateId)),
});
