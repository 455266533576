import { useEffect } from "react";

import { connect, FormikProps } from "formik";

const OnSubmitValidationError = (props: { callback: () => void; formik: FormikProps<any> }) => {
  const { callback, formik } = props;

  // validate set on submit and on mount (when initialValues change)
  useEffect(() => {
    if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.submitCount, formik.isSubmitting]);

  return null;
};

export default connect(OnSubmitValidationError);
