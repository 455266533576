import { FormattedMessage } from "react-intl";

import { Text as LyraText, TextProps as LyraTextProps } from "@new-black/lyra";

import Text, { TextProps } from "components/suite-ui/text";

interface IBooleanStringProps {
  value?: boolean;
  textProps?: Omit<TextProps, "children">;
}

/**
 * Translates a boolean value to `True` or `False` translated strings. It is wrapped in a `Text` compo
 * @param value boolean
 * @param textProps Omit<TextProps, "children">
 */
export const BooleanString = ({ textProps, value }: IBooleanStringProps) => (
  <Text variant="body2" color="inherit" {...textProps}>
    {value ? (
      <FormattedMessage id="generic.label.true" defaultMessage="True" />
    ) : (
      <FormattedMessage id="generic.label.false" defaultMessage="False" />
    )}
  </Text>
);

/**
 * Translates a boolean value to `True` or `False` translated strings.
 * @param value boolean
 * @param textProps Omit<TextProps, "children">
 */
export const YesNoString = ({
  textProps,
  value,
}: {
  value?: boolean;
  textProps?: Omit<TextProps, "children">;
}) => (
  <Text variant="body2" color="inherit" {...textProps}>
    {value ? (
      <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
    ) : (
      <FormattedMessage id="generic.label.no" defaultMessage="No" />
    )}
  </Text>
);

/**
 * Translates a boolean value to `True` or `False` translated strings.
 * @param value boolean
 * @param textProps Omit<TextProps, "children">
 */
export const LyraYesNoString = ({
  textProps,
  value,
}: {
  value?: boolean;
  textProps?: Omit<LyraTextProps, "children">;
}) => (
  <LyraText {...textProps}>
    {value ? (
      <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
    ) : (
      <FormattedMessage id="generic.label.no" defaultMessage="No" />
    )}
  </LyraText>
);
