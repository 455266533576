export enum AcceptedExpirationPolicies {
  DEFAULT,
  EXPIREENDOFYEAR,
  EXPIREENDOFMONTH,
  EXPIRESETDATE,
  CUSTOM,
}

export enum AcceptedPendingPolicies {
  DEFAULT,
  PENDINGRETURNPERIOD,
  CUSTOM,
}
