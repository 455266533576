import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { listRolesResultSelector, listRolesServiceState } from ".";

const useListRoles = () => {
  const roles = hooks.useGetState(listRolesResultSelector);
  const isLoading = hooks.useIsLoading({ state: listRolesServiceState.response });

  const isLoadingWithoutResponse = useMemo(() => isLoading && !roles, [isLoading, roles]);

  return { roles, isLoading, isLoadingWithoutResponse };
};

export default useListRoles;
