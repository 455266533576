import { DayPickerRangeController, DayPickerRangeControllerShape } from "react-dates";

import Popover, { PopoverOrigin } from "@material-ui/core/Popover";

import useSafeDatePickerDates from "./use-safe-date-picker-dates";

export interface IDateRangePicker extends DayPickerRangeControllerShape {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  anchorEl: HTMLElement | null;
  id: string;
  absolutePosition?: { top: number; left: number };
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableAutoClose?: boolean;
}

const DateRangePicker: React.FC<IDateRangePicker> = ({
  absolutePosition,
  anchorEl,
  anchorOrigin,
  disableAutoClose,
  id,
  open,
  setOpen,
  transformOrigin,
  ...props
}) => {
  const { endDate, startDate } = props;

  // avoid sending dates outside 1900-2100 to the DayPickerRangeController
  // because it has performance issues with large start date - end date intervals
  // see https://github.com/airbnb/react-dates/issues/1027
  const { safeEndDate, safeStartDate } = useSafeDatePickerDates({
    startDate,
    endDate,
  });

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={!absolutePosition ? anchorEl : undefined}
      anchorReference={absolutePosition ? "anchorPosition" : undefined}
      anchorPosition={absolutePosition}
      anchorOrigin={
        !absolutePosition
          ? anchorOrigin ?? {
              vertical: "bottom",
              horizontal: "left",
            }
          : undefined
      }
      transformOrigin={
        transformOrigin ?? {
          vertical: "top",
          horizontal: "left",
        }
      }
      onClose={() => {
        setOpen(false);
      }}
      disableRestoreFocus
      disableAutoFocus
      disableEnforceFocus
    >
      <div className="date-picker-wrapper">
        <DayPickerRangeController
          hideKeyboardShortcutsPanel
          weekDayFormat="dd"
          daySize={34}
          {...props}
          startDate={safeStartDate}
          endDate={safeEndDate}
          onFocusChange={(focus) => {
            props?.onFocusChange?.(focus);

            // close popover after selecting the end date
            if (focus === null && !disableAutoClose) {
              setOpen(false);
            }
          }}
        />
      </div>
    </Popover>
  );
};

export default DateRangePicker;
