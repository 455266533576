export default {
  module: {
    path: "/compliance",
  },
  eventLedger: {
    overview: {
      path: "/compliance/event-ledger",
    },
    details: {
      path: "/compliance/event-ledger/:id",
    },
  },
  manualInvoices: {
    overview: {
      path: "/compliance/manual-invoices",
    },
    new: {
      path: "/compliance/manual-invoices/new",
    },
  },
  controlDocuments: {
    overview: {
      path: "/compliance/control-documents",
    },
    details: {
      path: "/compliance/control-documents/:id",
    },
  },

  fraud: {
    path: "/compliance/fraud",
    oldAdminPath: "/management/fraud",
  },

  auditFiles: {
    overview: { path: "/compliance/audit-files" },

    create: {
      path: "/compliance/audit-files/new",
    },
  },

  eInvoices: {
    overview: {
      path: "/compliance/e-invoices",
    },

    details: {
      path: "/compliance/e-invoices/:id",

      generalInformation: {
        path: "/compliance/e-invoices/:id/general-information",

        editUserData: {
          path: "/compliance/e-invoices/:id/general-information/edit-user-data",
        },
      },

      orderInformation: {
        path: "/compliance/e-invoices/:id/order-information",
      },

      documents: {
        path: "/compliance/e-invoices/:id/documents",
      },

      history: {
        path: "/compliance/e-invoices/:id/history",
      },

      notifications: {
        path: "/compliance/e-invoices/:id/notifications",
      },

      exports: {
        path: "/compliance/e-invoices/:id/exports",

        details: {
          path: "/compliance/e-invoices/:id/exports/:exportId",
        },
      },
    },
  },
} as const;
