import { FormattedMessage } from "react-intl";


import { IAvailableUserTaskDto } from "./types";
import { Badge } from "@new-black/lyra";

export default function AvailableUserTasksTablePrintStatusCell({
  task,
}: {
  task: IAvailableUserTaskDto;
}) {
  if (task.Data?.IsError) {
    return (
      <div className="w-20">
        <Badge variant="red">
          <FormattedMessage id="generic.label.error" defaultMessage="Error" />
        </Badge>
      </div>
    );
  }

  return task.User ? (
    <div className="w-20">
      <Badge variant="yellow">
        <FormattedMessage id="generic.label.in-progress" defaultMessage="In progress" />
      </Badge>
    </div>
  ) : (
    <div className="w-20">
      <Badge variant="blue">
        <FormattedMessage id="generic.label.to-do" defaultMessage="To do" />
      </Badge>
    </div>
  );
}
