/* eslint-disable max-len */
export const CRMIconInner = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#FF9500" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 12C26.3273 12 24.7129 12.5764 23.5312 13.9029C22.3626 15.2146 21.75 17.1096 21.75 19.5C21.75 21.8904 22.3626 23.7855 23.5312 25.0972C24.7129 26.4236 26.3273 27 28 27C29.6728 27 31.2872 26.4236 32.4689 25.0972C33.6375 23.7855 34.2501 21.8904 34.2501 19.5C34.2501 17.1096 33.6375 15.2146 32.4689 13.9029C31.2872 12.5764 29.6728 12 28 12ZM24.25 19.5C24.25 17.5154 24.7568 16.2854 25.3978 15.5659C26.0257 14.8611 26.9114 14.5 28 14.5C29.0887 14.5 29.9744 14.8611 30.6023 15.5659C31.2433 16.2854 31.7501 17.5154 31.7501 19.5C31.7501 21.4846 31.2433 22.7146 30.6023 23.4342C29.9744 24.1389 29.0887 24.5 28 24.5C26.9114 24.5 26.0257 24.1389 25.3978 23.4342C24.7568 22.7146 24.25 21.4846 24.25 19.5Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.0001 28.25C23.9513 28.25 20.2702 29.5099 17.5771 31.3313C14.9483 33.109 13 35.6196 13 38.2268L13 38.2623C12.9999 38.5497 12.9999 38.8862 13.0345 39.1978C13.072 39.5352 13.1584 39.9412 13.3956 40.333C13.9021 41.1695 14.8048 41.4919 15.7431 41.6641C16.7136 41.8422 18.0957 41.9211 20.0253 41.9604C21.9717 42.0001 24.56 42.0001 27.9837 42.0001H28.0164C31.4401 42.0001 34.0284 42.0001 35.9748 41.9604C37.9044 41.9211 39.2865 41.8422 40.257 41.6641C41.1953 41.4919 42.098 41.1695 42.6045 40.333C42.8417 39.9412 42.9281 39.5352 42.9656 39.1978C43.0002 38.8862 43.0002 38.5497 43.0001 38.2623L43.0001 38.2268C43.0001 35.6196 41.0518 33.109 38.4231 31.3313C35.7299 29.5099 32.0488 28.25 28.0001 28.25ZM15.5 38.2268C15.5 36.8427 16.6298 34.9899 18.9776 33.4021C21.2609 31.858 24.4549 30.7501 28.0001 30.7501C31.5452 30.7501 34.7392 31.858 37.0225 33.4021C39.3703 34.9899 40.5001 36.8427 40.5001 38.2268C40.5001 38.5626 40.4985 38.763 40.4809 38.9215C40.4762 38.9635 40.4711 38.994 40.4668 39.0153C40.3959 39.0537 40.213 39.1305 39.8057 39.2052C39.0574 39.3425 37.8614 39.4214 35.9238 39.4609C34.0061 39.5 31.4432 39.5001 28.0001 39.5001C24.5569 39.5001 21.9941 39.5 20.0763 39.4609C18.1387 39.4214 16.9427 39.3425 16.1944 39.2052C15.7871 39.1305 15.6042 39.0537 15.5333 39.0154C15.529 38.994 15.5239 38.9635 15.5192 38.9215C15.5016 38.763 15.5 38.5626 15.5 38.2268Z"
      fill="white"
    />
  </>
);
