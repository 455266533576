export type AdjustmentTypeAndData =
  | IExistingPriceListAdjustment
  | IMarkupAdjustment
  | ISupplierAdjustment
  | IManualAdjustment
  | IRoundingAdjustment
  | ISetAdjustment;

export type IPriceListAdjustment = Omit<
  EVA.Core.Management.ListPriceListAdjustmentsResponse.PriceListAdjustmentDto,
  "Type" | "Data"
> &
  AdjustmentTypeAndData;

type ISetAdjustment = {
  childAdjustments: IPriceListAdjustment[];
  Type: PriceListAdjustmentType.Set;
  Data?: never;
};

type IExistingPriceListAdjustment = {
  Type: PriceListAdjustmentType.Pricelist;
  Data: IPriceListAdjustmentData;
};

type IMarkupAdjustment = {
  Type: PriceListAdjustmentType.Markup;
  Data: IMarkupAdjustmentData;
};

type ISupplierAdjustment = {
  Type: PriceListAdjustmentType.SupplierPricing;
  Data: ISupplierAdjustmentData;
};

type IManualAdjustment = {
  Type: PriceListAdjustmentType.Manual;
  Data: IManualAdjustmentData;
};

type IRoundingAdjustment = {
  Type: PriceListAdjustmentType.Rounding;
  Data: IRoundingAdjustmentData;
};
export interface IPriceListAdjustmentData {
  PriceListID?: number;
}

export interface IMarkupAdjustmentData {
  Type: MarkupAdjustmentType;
  Filters: IMarkupAdjustmentProductFilter[];
  PriceChangeDirection?: MarkupAdjustmentDirection;
  PriceChangeValue?: number;
  ClearPriceOnMismatch?: boolean;
}

export interface ISupplierAdjustmentData {
  PricelistUsageTypeID: SupplierAdjustmentUsageType;
  MultipleSuppliersHandling: SupplierAdjustmentMultipleSuppliersHandling;
}

export interface IManualAdjustmentData {
  ClearPriceOnMismatch?: boolean;
}

export enum SupplierAdjustmentUsageType {
  RecommendedRetail,
  Cost,
}

export enum SupplierAdjustmentMultipleSuppliersHandling {
  Cheapest,
  MostExpensive,
}

export interface IPriceListManualAdjustment {
  ID?: number;
  PriceListAdjustmentID: number;
  ProductName?: string;
  ProductID?: number;
  EffectiveDate?: string;
  ExpireDate?: string;
  Value?: number;
}

export const DEPRECATED_SUPPLIER_PRICING_TYPE = "SUPPLIER_PRICING";

export enum PriceListAdjustmentType {
  Manual = "MANUAL_INPUT",
  Pricelist = "PRICELIST",
  Markup = "MARKUP",
  SupplierPricing = "SUPPLIERPRICING",
  Set = "SET",
  Rounding = "ROUNDING",
}

export enum PriceListSection {
  GeneralInfo,
  Adjustments,
  Organizations,
}

export enum MarkupAdjustmentType {
  Amount,
  Percentage,
}
export enum MarkupAdjustmentDirection {
  Discount = -1,
  Increase = 1,
}

export enum RoundingDirection {
  Up = "Up",
  Down = "Down",
  Closest = "Closest",
}

export enum RoundingAmountType {
  Fraction = "Fraction",
  Divisor = "Divisor",
}

export interface IRoundingAdjustmentData {
  Direction?: RoundingDirection;
  AmountType?: RoundingAmountType;
  Targets?: IRoundingAdjustmentTarget[];
}

export interface IRoundingAdjustmentTarget {
  Amount?: number;
  Direction?: RoundingDirection | "default";
  AmountType?: RoundingAmountType;
  MaximumTolerance?: number;
}

export interface IMarkupAdjustmentProductFilter {
  PropertyName: string;
  Values?: any[];
  ExactMatch: boolean;
  Exclude?: boolean;
  From?: string;
  To?: string;
}
