import { z } from "zod";

export const dateWithValidYear = z
  .string()
  .optional()
  .transform((value) => {
    if (value) {
      const dateSchema = z.date();
      const parsedDate = dateSchema.safeParse(new Date(value));

      if (!parsedDate.success) {
        return undefined;
      }

      const year = new Date(value).getFullYear();

      if (year.toString().length !== 4) {
        return undefined;
      }
    }
    return value;
  });
