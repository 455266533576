import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const RadioButtonIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Radiobutton</title>
    <g id="Icon-/-Radiobutton" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        // eslint-disable-next-line max-len
        d="M12,7 C9.24,7 7,9.24 7,12 C7,14.76 9.24,17 12,17 C14.76,17 17,14.76 17,12 C17,9.24 14.76,7 12,7 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.58,20 4,16.42 4,12 C4,7.58 7.58,4 12,4 C16.42,4 20,7.58 20,12 C20,16.42 16.42,20 12,20 Z"
        id="Shape"
        fillOpacity="0.6"
        fill="#000000"
        fillRule="nonzero"
      />
      <rect id="Shape" x="0" y="0" width="24" height="24" />
    </g>
  </SvgIcon>
);
