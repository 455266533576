import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  CardContent,
  CardFooter,
  CardHeader,
  Checkbox,
  Dialog,
  DialogCard,
  FieldGroup,
  SvgIcon,
  Text,
  TextField,
} from "@new-black/lyra";

import { toast } from "components/suite-ui/toast";
import { SafeClipboard } from "util/clipboard";

interface SecureApiKeyDialogProps {
  isOpen: boolean;
  onDone: () => void;
  apiKey?: string;
}

export const SecureApiKeyDialog = ({ apiKey, isOpen, onDone }: SecureApiKeyDialogProps) => {
  return (
    <Dialog isOpen={isOpen} maxWidth="xl" isDismissable={false} isKeyboardDismissDisabled={true}>
      <SecureApiKeyDialogContent onDone={onDone} apiKey={apiKey} />
    </Dialog>
  );
};

export const SecureApiKeyDialogContent = ({
  apiKey,
  onDone,
}: Omit<SecureApiKeyDialogProps, "isOpen">) => {
  const intl = useIntl();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const copyToClipboard = useCallback(() => {
    if (apiKey) {
      SafeClipboard.writeText(apiKey)
        .then(() =>
          toast.success(
            intl.formatMessage({
              id: "generic.label.copied",
              defaultMessage: "Copied to clipboard",
            }),
          ),
        )
        .catch((err) => console.error(err));
    }
  }, [apiKey, intl]);

  return (
    <DialogCard>
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.secure-the-api-key",
          defaultMessage: "Secure the API key",
        })}
      />
      <CardContent className="flex flex-col gap-4">
        <Text variant="body-medium">
          <FormattedMessage
            id="secure-watchtower-api-key.description"
            defaultMessage="This API key will only be shown once here. Make sure to copy and save it securely, as you will not be able to retrieve it again once you navigate away from this page."
          />
        </Text>
        <FieldGroup>
          <div className="flex items-end gap-2">
            <TextField
              hideHintLabel
              isDisabled
              label={intl.formatMessage({
                id: "generic.label.api-key",
                defaultMessage: "API key",
              })}
              value={apiKey ?? ""}
            />

            <Button
              variant="icon"
              onPress={copyToClipboard}
              tooltip={intl.formatMessage({
                id: "generic.label.copy-to-clipboard",
                defaultMessage: "Copy to clipboard",
              })}
              isDisabled={!apiKey}
            >
              <SvgIcon name="clipboard" />
            </Button>
          </div>

          <Checkbox onChange={setIsConfirmed}>
            <FormattedMessage
              id="secure-watchtower-api-key.confirmation"
              defaultMessage="I have copied and securely saved the API key."
            />
          </Checkbox>
        </FieldGroup>
      </CardContent>

      <CardFooter>
        <Button onPress={onDone} isDisabled={!isConfirmed}>
          <FormattedMessage id="generic.label.done" defaultMessage="Done" />
        </Button>
      </CardFooter>
    </DialogCard>
  );
};
