import { useIntl } from "react-intl";

import { Card, CardContent, CardHeader, Dialog } from "@new-black/lyra";

import LoadingStateBox from "components/shared/loading-state-box";
import { useGetOrderExportDocumentsQuery } from "models/orders";

import TransputJobDocuments from "../transput-job-documents/transput-job-documents";

interface IOrderExportDetailsModalProps {
  open: boolean;
  close: () => void;
  orderExportId?: number;
}

export const OrderExportDetailsModal = ({
  close,
  open,
  orderExportId,
}: IOrderExportDetailsModalProps) => {
  const intl = useIntl();

  const { data, isFetching: isLoading } = useGetOrderExportDocumentsQuery(
    orderExportId ? { ID: orderExportId } : undefined,
    { loaderKey: orderExportId ? [orderExportId] : undefined },
  );

  return (
    <Dialog isOpen={open} onOpenChange={(open) => (!open ? close() : undefined)} maxWidth="3xl">
      <Card>
        <CardHeader
          title={intl.formatMessage({
            id: "order-export-details-modal.title",
            defaultMessage: "Order export documents",
          })}
        />
        <CardContent>
          {isLoading ? (
            <LoadingStateBox limit={2} />
          ) : (
            <div className="flex flex-col gap-5">
              <TransputJobDocuments type="requests" documents={data?.RequestDocuments ?? []} />

              <TransputJobDocuments type="responses" documents={data?.ResponseDocuments ?? []} />
            </div>
          )}
        </CardContent>
      </Card>
    </Dialog>
  );
};
