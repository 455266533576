import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import { ScriptCodeEditorProvider, useInitialEditorModels } from "components/shared/script-editor";
import useScriptEditorHistory from "components/shared/script-editor/hooks/use-script-editor-history";
import {
  IMonacoEditorModelProps,
  IScriptHistoryItem,
} from "components/shared/script-editor/script-code-editor.types";
import { EditorType } from "components/shared/segmented-button-editor-switch";
import { useGetScriptDialectsQuery } from "models/scripts";
import { ScriptEditorLanguage } from "types/monaco-editor-language";
import { ScriptType } from "types/script-type";
import { contextGenerator } from "util/context-generator";

import { IScriptSelectorPrivateScript, ScriptSelectorOption } from "./types";

export type IScriptSelectorContext = {
  isLoading?: boolean;
  activeOption: ScriptSelectorOption;
  setActiveOption: (option: ScriptSelectorOption) => void;
  initialCustomScript?: IScriptSelectorPrivateScript;
  scriptType: ScriptType;
  scriptId?: number;
  onScriptIdChanged: (scriptId?: number) => void;
  customScriptSource?: string;
  onCustomScriptSourceChange: (source?: string) => void;
  isCreatingNewCustomScript: boolean;
  customScriptRevisionComment?: string;
  onCustomScriptRevisionCommentChange?: (comment: string) => void;
  scriptHistory?: IScriptHistoryItem[];
  isScriptHistoryLoading?: boolean;
  onLoadMoreHistory?: () => void;
  editorType: EditorType;
  setEditorType: (newValue: EditorType) => void;
  ModelProps: IMonacoEditorModelProps;
};

const { Provider: ScriptSelectorContextProvider, useContext: useScriptSelectorContext } =
  contextGenerator<IScriptSelectorContext>();

export { useScriptSelectorContext };

export const ScriptSelectorProvider = ({
  children,
  customScriptRevisionComment,
  customScriptSource,
  initialScript,
  onActiveOptionChanged,
  onCustomScriptRevisionCommentChange,
  onCustomScriptSourceChange,
  onScriptIdChanged,
  scriptEditorDialect,
  scriptId,
  scriptType,
}: PropsWithChildren<
  Pick<
    IScriptSelectorContext,
    | "scriptType"
    | "scriptId"
    | "onScriptIdChanged"
    | "onCustomScriptSourceChange"
    | "customScriptSource"
    | "onCustomScriptRevisionCommentChange"
    | "customScriptRevisionComment"
  > & {
    scriptEditorDialect: ScriptEditorLanguage;
    initialScript?: IScriptSelectorPrivateScript;
    onActiveOptionChanged?: (newCustomScriptSource?: string) => void;
  }
>) => {
  const [activeOption, setActiveOption] = useState<ScriptSelectorOption>(
    initialScript?.IsPrivate ? "custom" : "existing",
  );
  const [isCreatingNewCustomScript, setIsCreatingNewCustomScript] = useState(!initialScript);

  useEffect(() => {
    setActiveOption(initialScript?.IsPrivate ? "custom" : "existing");
    setIsCreatingNewCustomScript(!initialScript);
  }, [initialScript]);

  const handleSetActiveOption = useCallback(
    (value: ScriptSelectorOption) => {
      setActiveOption(value);
      onActiveOptionChanged?.(value === "custom" ? `extend ${scriptType}\n\n` : undefined);
      setIsCreatingNewCustomScript(true);
    },
    [onActiveOptionChanged, scriptType],
  );

  const dialects = useGetScriptDialectsQuery({}, { refetchOnWindowFocus: false });

  const dialectKeywords = useMemo(
    () =>
      dialects?.data?.Dialects?.find((dialect) => dialect.Name === scriptEditorDialect)?.Keywords ??
      [],
    [dialects, scriptEditorDialect],
  );

  const {
    isLoading: isScriptHistoryLoading,
    onLoadMore: onLoadMoreHistory,
    scriptHistory,
  } = useScriptEditorHistory(initialScript?.ID);

  const [editorType, setEditorType] = useState<EditorType>("normal");

  const {
    initialDiffEditorModel,
    initialNormalEditorModel,
    setInitialDiffEditorModel,
    setInitialNormalEditorModel,
  } = useInitialEditorModels(editorType);

  const ModelProps = useMemo<IMonacoEditorModelProps>(
    () => ({
      normalEditor: {
        initialModel: initialNormalEditorModel,
        onBlur: (model) => setInitialNormalEditorModel(model ?? null),
      },
      diffEditor: {
        initialModel: initialDiffEditorModel,
        onBlur: (model) => setInitialDiffEditorModel(model ?? null),
      },
    }),
    [
      initialDiffEditorModel,
      initialNormalEditorModel,
      setInitialDiffEditorModel,
      setInitialNormalEditorModel,
    ],
  );

  const contextValue = useMemo<IScriptSelectorContext>(
    () => ({
      initialCustomScript: initialScript?.IsPrivate ? initialScript : undefined,
      scriptType,
      onCustomScriptSourceChange,
      onScriptIdChanged,
      customScriptSource,
      scriptId,
      activeOption,
      setActiveOption: handleSetActiveOption,
      isCreatingNewCustomScript,
      customScriptRevisionComment,
      onCustomScriptRevisionCommentChange,
      isScriptHistoryLoading,
      onLoadMoreHistory,
      scriptHistory: !initialScript || isCreatingNewCustomScript ? undefined : scriptHistory,
      editorType,
      setEditorType,
      ModelProps,
    }),
    [
      initialScript,
      scriptType,
      onCustomScriptSourceChange,
      onScriptIdChanged,
      customScriptSource,
      scriptId,
      activeOption,
      handleSetActiveOption,
      isCreatingNewCustomScript,
      customScriptRevisionComment,
      onCustomScriptRevisionCommentChange,
      isScriptHistoryLoading,
      onLoadMoreHistory,
      scriptHistory,
      editorType,
      ModelProps,
    ],
  );

  return (
    <ScriptSelectorContextProvider value={contextValue}>
      <ScriptCodeEditorProvider dialectKeywords={dialectKeywords} dialect={scriptEditorDialect}>
        {children}
      </ScriptCodeEditorProvider>
    </ScriptSelectorContextProvider>
  );
};
