import { useCallback, useEffect } from "react";

import { useRecoilState } from "recoil";

import useParsedRouteParam from "hooks/suite-react-hooks/use-parsed-route-param";

import { discountEditedAtom } from "../edit-discount.state";

/**
 * Hook to update the editMode prop in the store when something changes. If you are working within a Formik form
 * you probably want to use the EditDiscountEdited component instead of this.
 * @param edited
 */
const useEditDiscountEditMode = (edited?: boolean) => {
  // TODO: maybe this can be refactored to a recoil selector for isDirty between the discount atom and the service response
  const discountId = useParsedRouteParam();
  const [currentEditModeInStore, setCurrentEditModeInStore] = useRecoilState(
    discountEditedAtom(discountId),
  );

  const editDiscountEditModeToFalse = useCallback(() => {
    setCurrentEditModeInStore(false);
  }, [setCurrentEditModeInStore]);

  const editDiscountEditModeToTrue = useCallback(() => {
    setCurrentEditModeInStore(true);
  }, [setCurrentEditModeInStore]);

  useEffect(() => {
    if (edited && !currentEditModeInStore) {
      setCurrentEditModeInStore(true);
    }
  }, [currentEditModeInStore, edited, setCurrentEditModeInStore]);

  return {
    editDiscountEditModeToFalse,
    editDiscountEditModeToTrue,
  };
};

export default useEditDiscountEditMode;
