import { useCallback, useMemo, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";

import ImageGalleryDialog from "components/shared/image-gallery-dialog";

import Image, { ImageProps } from "../image";

import Arrow from "./arrow";
import { ImageButton, Previews } from "./helper-components";

interface IImageSliderProps {
  images: ImageProps[];
  placeholderImageUrl?: string;
  useGalleryModal?: boolean;
}

const ImageSlider = ({ images, placeholderImageUrl, useGalleryModal }: IImageSliderProps) => {
  const swipeConfidenceThreshold = useMemo(() => 100, []);
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [parentRef, setParentRef] = useState<HTMLDivElement | null>(null);
  const parentWidth = useMemo(() => parentRef?.clientWidth ?? 0, [parentRef]);
  const [direction, setDirection] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [initialZoomedImage, setInitialZoomedImage] = useState<number>();

  const sliderImageVariants = useMemo(
    () => ({
      enter: {
        opacity: 0,
        x: direction,
        position: "absolute" as "absolute" | "relative",
      },
      center: {
        x: 0,
        zIndex: 1,
        opacity: 1,
        position: "relative" as "absolute" | "relative",
      },
      exit: {
        zIndex: 0,
        opacity: 0,
        x: -1 * direction,
        position: "absolute" as "absolute" | "relative",
      },
    }),
    [direction],
  );

  const swipePower = useCallback(
    (offset: number, velocity: number) => Math.abs(offset) * velocity,
    [],
  );

  const paginate = useCallback(
    (idx: number) => {
      if (idx >= 0 && idx <= images.length - 1 && idx !== currentSlide) {
        setDirection(idx > currentSlide ? 100 : -100);
        setCurrentSlide(idx);
        return;
      }
      if (idx === currentSlide) {
        setDirection(0);
      }
    },
    [currentSlide, images.length],
  );

  return (
    <div ref={(instance) => setParentRef(instance)}>
      <div className="relative">
        <Arrow
          type="left"
          disabled={currentSlide === 0}
          onClick={() => paginate(currentSlide - 1)}
        />
        <AnimatePresence initial={false}>
          <motion.div
            drag="x"
            exit="exit"
            initial="enter"
            animate="center"
            dragElastic={0}
            key={currentSlide}
            variants={sliderImageVariants}
            onDragStart={() => setIsDragging(true)}
            dragConstraints={{ left: 0, right: 0 }}
            style={{ width: "100%" }}
            transition={{
              opacity: { duration: 0.2 },
              x: { type: "spring", stiffness: 300, damping: 30 },
            }}
            dragTransition={{
              min: 0,
              max: 0,
              bounceStiffness: 100,
              bounceDamping: 50,
              power: 0,
              restDelta: 100,
            }}
            onDragEnd={(_e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              if (swipe < -swipeConfidenceThreshold) {
                paginate(currentSlide + 1);
              } else if (swipe > swipeConfidenceThreshold) {
                paginate(currentSlide - 1);
              }
              setIsDragging(false);
            }}
          >
            <Image
              onZoomEvent={() => !isDragging && !useGalleryModal}
              enlarge={!useGalleryModal}
              errorFallbackSrc={placeholderImageUrl}
              {...images[currentSlide]}
              style={{
                width: "100%",
                display: "block",
                cursor: useGalleryModal ? "zoom-in" : undefined,
              }}
              onClick={() => {
                if (useGalleryModal) {
                  setInitialZoomedImage(currentSlide);
                }
              }}
            />
          </motion.div>
        </AnimatePresence>
        <Arrow
          type="right"
          onClick={() => paginate(currentSlide + 1)}
          disabled={currentSlide === images.length - 1}
        />
      </div>
      {images.length > 1 && (
        <Previews width="100%" parentWidth={parentWidth}>
          {images.map((image, idx) => (
            <ImageButton
              type="button"
              key={image.src}
              onClick={() => paginate(idx)}
              className={currentSlide === idx ? "opacity-100" : "opacity-60"}
            >
              <Image
                src={image.src}
                alt={image.alt}
                errorFallbackSrc={placeholderImageUrl}
                enlarge={false}
              />
            </ImageButton>
          ))}
        </Previews>
      )}
      {initialZoomedImage !== undefined && useGalleryModal ? (
        <ImageGalleryDialog
          items={images.map((img) => ({
            src: img.src,
            alt: img.alt,
            name: img.alt,
          }))}
          initialSelectedIndex={initialZoomedImage}
          isOpen
          onOpenChange={(open) => {
            if (!open) {
              setInitialZoomedImage(undefined);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default ImageSlider;
