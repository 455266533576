import { ErrorTypes } from "types/error-types";

/**
 * Utility function that handles error response. Might be useful in loaders.
 */
export const handleErrorResponse = (error?: EVA.Core.ServiceError) => {
  if (error?.Type === ErrorTypes.EntityNotFound) {
    throw new Response("Not found", { status: 404 });
  }
};
