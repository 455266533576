import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

import { ALL_ROWS_LIMIT } from "util/base-values";

const { createSelectors } = helpers;

export const orderServiceState = builders.buildServiceFamilyState({
  key: "Order",
  service: Core.GetOrder,
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.OrderID === parameter) {
      return req;
    }
    return {
      ...req,
      OrderID: parameter,
      IncludeAllPayments: true,
      AdditionalOrderDataOptions: {
        ProductProperties: ["primary_image"],
        IncludeProductRequirements: true,
        IncludeGiftWrapping: false,
        IncludePrefigureDiscounts: false,
        IncludePickProductOptions: false,
        IncludeAvailableShippingMethods: false,
        IncludeAvailablePaymentMethods: true,
        IncludeAvailableRefundPaymentMethods: true,
        IncludeValidateShipment: false,
        IncludePaymentTransactionActions: false,
        IncludeCheckoutOptions: false,
        IncludeOrganizationUnitData: true,
        IncludeCustomerCustomFields: true,
        IncludeGiftCardBusinessRules: false,
        RequiredFor: 7, // PlaceOrder (1) + Payment (2) + Shipment (4) = 7
      },
    };
  },
});

export const orderResultSelector = selectorFamily<
  EVA.Core.OrderDto | undefined,
  number | undefined
>({
  key: "Order:Result",
  get:
    (params) =>
    ({ get }) => {
      const response = get(orderServiceState.response(params));
      const result = response?.Result;
      return result;
    },
});

export const orderAdditionalOrderDataSelector = selectorFamily<
  EVA.Core.AdditionalOrderData | undefined,
  number | undefined
>({
  key: "Order:Result",
  get:
    (params) =>
    ({ get }) =>
      get(orderServiceState.response(params))?.AdditionalOrderData,
});

export const orderAmountsSelector = selectorFamily<
  EVA.Core.OpenOrderAmounts | undefined,
  number | undefined
>({
  key: "Order:Amounts",
  get:
    (params) =>
    ({ get }) => {
      const response = get(orderServiceState.response(params));
      const amounts = response?.Amounts;
      return amounts;
    },
});

export const orderIDSelector = selectorFamily<number | undefined, number | undefined>({
  key: "Order:ID",
  get:
    (params) =>
    ({ get }) => {
      const result = get(orderResultSelector(params));
      return result?.ID;
    },
});

export const orderAdditionalProductRequirementsSelector = selectorFamily<
  EVA.Core.ProductRequirementModel[] | undefined,
  number | undefined
>({
  key: "Order:AdditionalOrderData:ProductRequirements",
  get:
    (params) =>
    ({ get }) => {
      const result = get(orderServiceState.response(params));
      return result?.AdditionalOrderData?.ProductRequirements;
    },
});

type OrderLinesParameters = {
  OrderID?: number;
  UI: string;
};

export const orderLinesServiceState = builders.buildServiceFamilyState({
  key: "OrderLines",
  service: Core.ListOrderLines,
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const orderLinesResultSelector = selectorFamily<
  EVA.Core.OrderLineDto[] | undefined,
  OrderLinesParameters | undefined
>({
  key: "OrderLines:Result",
  get:
    (params) =>
    ({ get }) => {
      const response = get(orderLinesServiceState.response(params));
      const result = response?.Result?.Page;
      return result;
    },
});

export const orderLinesResultTotalSelector = selectorFamily<
  number | undefined,
  OrderLinesParameters | undefined
>({
  key: "OrderLines:Result",
  get:
    (params) =>
    ({ get }) => {
      const response = get(orderLinesServiceState.response(params));
      const result = response?.Result.Total;
      return result;
    },
});

export const orderLinesRequestLimitSelector = createSelectors.createGetterSetterSelectorFamily<
  EVA.Core.ListOrderLines,
  number,
  OrderLinesParameters
>({
  state: (params) => orderLinesServiceState.request(params),
  key: "OrderLines:Request:Limit",
  getData: (req) => req?.PageConfig?.Limit ?? 50,
  setData: (req, data) => {
    if (!req) return undefined;
    return {
      ...req,
      PageConfig: {
        ...req?.PageConfig,
        Limit: data ?? 50,
      },
    };
  },
  defaultValue: 50,
});
export const orderLinesRequestStartSelector = createSelectors.createGetterSetterSelectorFamily<
  EVA.Core.ListOrderLines,
  number,
  OrderLinesParameters
>({
  state: (params) => orderLinesServiceState.request(params),
  key: "OrderLines:Request:Start",
  getData: (req) => req?.PageConfig?.Start ?? 0,
  setData: (req, data) => {
    if (!req) return undefined;
    return {
      ...req,
      PageConfig: {
        ...req?.PageConfig,
        Start: data ?? 0,
      },
    };
  },
  defaultValue: 0,
});
export const orderLinesRequestShowCancelledLinesFilterSelector =
  createSelectors.createGetterSetterSelectorFamily<
    EVA.Core.ListOrderLines,
    boolean | undefined,
    OrderLinesParameters
  >({
    state: (params) => orderLinesServiceState.request(params),
    key: "OrderLines:Request:OnlyShippableLines",
    getData: (req) => req?.PageConfig?.Filter?.OnlyShippableLines,
    setData: (req, data) => {
      if (!req) return undefined;
      return {
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Start: 0,
          Filter: {
            ...req?.PageConfig?.Filter,
            OnlyShippableLines: data ?? false,
          },
        },
      };
    },
    defaultValue: false,
  });

export const allOrderLinesForOrderServiceState = builders.buildServiceFamilyState({
  service: Core.ListOrderLines,
  key: "Order:AllOrderLines",
  allowEmptyRequest: false,
  requireEmployee: true,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || req?.OrderID === parameter) {
      return req;
    }
    return {
      ...req,
      OrderID: parameter,
      PageConfig: {
        Limit: ALL_ROWS_LIMIT,
        Start: 0,
      },
    };
  },
});

export const allOrderLinesByOrderIDSelector = selectorFamily({
  key: "Order:AllOrderLines:Result",
  get:
    (orderID: number | undefined) =>
    ({ get }) => {
      const orderResponse = get(orderServiceState.response(orderID));

      if (orderResponse) {
        if (orderResponse.HasMoreLines) {
          return get(allOrderLinesForOrderServiceState.response(orderID))?.Result?.Page;
        }
        return orderResponse.Result?.Lines;
      }

      return undefined;
    },
});
