import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup, Table, TableColumnDef, TableProps } from "@new-black/lyra";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import { OpenInNewTabActionButton } from "components/shared/action-buttons/open-in-new-tab-action-button";

type TPickedRequiredTableProps =
  | "data"
  | "onNext"
  | "showNext"
  | "onPrevious"
  | "showPrevious"
  | "limit"
  | "setLimit";

type TRequiredLoyaltyProgramPriceListsTableProps = Required<
  Pick<
    TableProps<EVA.Core.Management.ListLoyaltyProgramPriceListsResponse.Item>,
    TPickedRequiredTableProps
  >
>;

export const LoyaltyProgramPriceListsTable = ({
  data,
  limit,
  onDelete,
  onNext,
  onOpenInNewTab,
  onPrevious,
  setLimit,
  showNext,
  showPrevious,
}: TRequiredLoyaltyProgramPriceListsTableProps & {
  onDelete?: (id: number) => void;
  onOpenInNewTab?: (id: number) => void;
}) => {
  const intl = useIntl();

  const columns = useMemo(() => {
    const result: TableColumnDef<EVA.Core.Management.ListLoyaltyProgramPriceListsResponse.Item>[] =
      [
        {
          accessorKey: "PriceListID",
          id: "PriceListID",
          header: intl.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
        },

        {
          accessorKey: "Name",
          id: "Name",
          header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
        },

        {
          accessorKey: "CurrencyID",
          id: "CurrencyID",
          header: intl.formatMessage({ id: "generic.label.currency", defaultMessage: "Currency" }),
        },
      ];

    if (onDelete || onOpenInNewTab) {
      result.push({
        id: "actions",
        cellType: "button",
        align: "end",
        header: "",
        cell: ({ row }) => (
          <ButtonGroup>
            {onOpenInNewTab ? (
              <OpenInNewTabActionButton onPress={() => onOpenInNewTab(row.original.PriceListID)} />
            ) : null}

            {onDelete ? (
              <DeleteActionButton onPress={() => onDelete(row.original.PriceListID)} />
            ) : null}
          </ButtonGroup>
        ),
      });
    }

    return result;
  }, [intl, onDelete, onOpenInNewTab]);

  return (
    <Table
      data={data}
      onNext={onNext}
      showNext={showNext}
      onPrevious={onPrevious}
      showPrevious={showPrevious}
      limit={limit}
      setLimit={setLimit}
      aria-label={intl.formatMessage({
        id: "generic.label.price-lists",
        defaultMessage: "Price lists",
      })}
      columns={columns}
    />
  );
};
