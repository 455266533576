import { atom, atomFamily, selector } from "recoil";

import { EvaDisputeType } from "../invoices-details.types";
import { currentDisputeAtom } from "../view-invoice/disputes/dispute-dialog.state";

export const currentInvoiceAtom = atom<EVA.Core.InvoiceDetailsDto | undefined>({
  default: undefined,
  key: "ViewInvoice:CurrentInvoice",
});

export const getInvoiceLinesWithConflictsSelector = selector({
  key: "Core:GetInvoiceLinesWithConflicts:selector",
  get: ({ get }) => {
    const currentInvoice = get(currentInvoiceAtom);

    return (
      currentInvoice?.Lines?.filter(
        (line) => line.TotalAmountInvoicedInTax !== line.ExpectedTotalAmountInTax,
      ) ?? []
    );
  },
});

export const selectedAdditionalAmountAtom = atom<
  EVA.Core.GetInvoiceAdditionalAmountsForInvoiceResponse.InvoiceAdditionalAmountDto | undefined
>({
  default: undefined,
  key: "selectedAdditionalAmount",
});

export const selectedShipmentsAtom = atom<
  (EVA.Core.ListShipmentsToInvoiceResponse.Model & { deleted?: boolean })[] | undefined
>({
  default: undefined,
  key: "selectedShipments",
});

export const availableSelectedShipmentsSelector = selector<
  (EVA.Core.ListShipmentsToInvoiceResponse.Model & { deleted?: boolean })[] | undefined
>({
  key: "selectedShipments:available",
  get: ({ get }) => {
    const allSelectedShipments = get(selectedShipmentsAtom);

    return allSelectedShipments?.filter((selectedShipment) => !selectedShipment.deleted);
  },
});

export const shipmentLinesForSelectedShipments = atomFamily<
  EVA.Core.ListShipmentLinesToInvoiceResponse.Model[] | undefined,
  number | undefined
>({
  default: undefined,
  key: "selectedShipments:shipmentLines",
});

export const disputeTypeSelector = selector<number>({
  key: "disputeTypeSelector",
  get: ({ get }) => {
    const dispute = get(currentDisputeAtom);

    if (dispute?.InvoiceLineID) {
      return EvaDisputeType.INVOICE_LINE;
    } else if (dispute?.InvoiceAdditionalAmountID) {
      return EvaDisputeType.ADDITIONAL_AMOUNT;
    } else {
      return EvaDisputeType.INVOICE;
    }
  },
});

export const disputeTypeAtom = atom<number>({
  key: "disputeTypeAtom",
  default: disputeTypeSelector,
});

export const selectedShipmentsTotalAmountSelector = selector<number>({
  key: "selectedShipments:totalAmount",
  get: ({ get }) => {
    const shipments = get(selectedShipmentsAtom);

    const shipmentLinesTotalAmount = shipments
      ?.map((shipment) => {
        const shipmentLines = get(shipmentLinesForSelectedShipments(shipment.ID));

        return (
          shipmentLines
            ?.map(
              (shipmentLine) => shipmentLine.QuantityAvailableForInvoice * shipmentLine.UnitPrice,
            )
            ?.reduce((a, b) => a + b, 0) ?? 0
        );
      })
      ?.reduce((a, b) => a + b, 0);

    return shipmentLinesTotalAmount ?? 0;
  },
});

export const selectedInvoiceLineAtom = atom<EVA.Core.InvoiceDetailLineDto | undefined>({
  default: undefined,
  key: "selectedInvoiceLine",
});

export const selectedOrInitialInvoiceLineSelector = selector<
  EVA.Core.InvoiceDetailLineDto | undefined
>({
  key: "selectedOrInitialInvoiceLineSelector",
  get: ({ get }) => {
    const selectedInvoiceLine = get(selectedInvoiceLineAtom);
    const disputeType = get(disputeTypeAtom);

    if (disputeType !== EvaDisputeType.INVOICE_LINE) {
      return undefined;
    }

    if (selectedInvoiceLine) {
      return selectedInvoiceLine;
    }

    const dispute = get(currentDisputeAtom);
    const conflictingLines = get(getInvoiceLinesWithConflictsSelector);

    if (dispute && dispute.InvoiceLineID) {
      return conflictingLines.find((line) => line.ID === dispute.InvoiceLineID) || undefined;
    }

    return conflictingLines.length > 0 ? conflictingLines[0] : undefined;
  },
  set: ({ set }, newValue) => {
    set(selectedInvoiceLineAtom, newValue);
  },
});
