export const getOuSetSubsetsCounts = (
  firstLayerOfSubsets?: EVA.Core.Management.OrganizationUnitSetDetails[],
  initialCount = 0,
) => {
  if (firstLayerOfSubsets?.length) {
    initialCount += firstLayerOfSubsets.length;

    firstLayerOfSubsets.forEach((subset) => {
      if (subset.Subsets) {
        initialCount += getOuSetSubsetsCounts(subset.Subsets);
      }
    });
  }

  return initialCount;
};
