/** Builds a redirect URL to Admin 1.0 */
export const buildAdminRedirectURL = (adminURL: string, token: string, path?: string) => {
  const result = new URL(adminURL);

  if (path) {
    result.pathname = path;
  }

  result.searchParams.append("token", token);

  return result.toString();
};
