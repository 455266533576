import type { ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Text, Tooltip } from "@new-black/lyra";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";

import ErrorFallback from "./error-fallback";

const SmallFallbackComponent = ({
  error,
  fallbackMessage = (
    <FormattedMessage
      id="generic.message.something-went-wrong"
      defaultMessage="Something went wrong!"
    />
  ),
}: {
  error: Error;
  fallbackMessage?: ReactNode | string;
}) => (
  <Tooltip tooltip={<>{error.message ?? ""}</>}>
    <Text color="error">{fallbackMessage}</Text>
  </Tooltip>
);

function ErrorBoundary({
  children,
  errorMessage,
  small,
}: {
  small?: boolean;
  children: ReactNode;
  errorMessage?: string | ReactNode;
}) {
  if (small) {
    return (
      <SentryErrorBoundary
        fallback={({ error }) => (
          <SmallFallbackComponent error={error} fallbackMessage={errorMessage} />
        )}
      >
        {children || null}
      </SentryErrorBoundary>
    );
  }

  return <SentryErrorBoundary fallback={ErrorFallback}>{children || null}</SentryErrorBoundary>;
}

export default ErrorBoundary;
