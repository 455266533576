import { useMemo } from "react";

import { useGetOrganizationUnitSetDetailsQuery } from "models/organization-unit-sets";

const useOUSetDetails = (ouSetID?: number) => {
  const {
    data: ouSetDetailsResponse,
    isFetching: isLoading,
    isLoading: isLoadingWithoutResponse,
    refetch,
  } = useGetOrganizationUnitSetDetailsQuery(ouSetID ? { ID: ouSetID } : undefined, {
    refetchOnWindowFocus: false,
  });
  const ouSetDetails = useMemo(() => ouSetDetailsResponse?.Set, [ouSetDetailsResponse]);
  const subsets = useMemo(() => ouSetDetails?.Subsets ?? [], [ouSetDetails]);

  return {
    refetch,
    subsets,
    isLoading,
    ouSetDetails,
    ouSetDetailsResponse,
    isLoadingWithoutResponse,
  };
};

export default useOUSetDetails;
