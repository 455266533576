import { useMemo } from "react";

import { state } from "@springtree/eva-sdk-react-recoil";
import { EvaIntentBuilder, IEvaIntent } from "@springtree/eva-suite-uri-parser";
import { useRecoilValue } from "recoil";

type ModuleCode =
  | "CONFIGURATION"
  | "CRM"
  | "DISCOUNTS"
  | "FINANCIALS"
  | "LEGALAUDITING"
  | "MANAGEMENT"
  | "ORDERS"
  | "ORGANIZATIONS"
  | "PIM"
  | "PRICELISTSPROMOTIONS"
  | "PROMOTIONS"
  | "SETTINGS"
  | "STOCKMANAGEMENT"
  | "TASKS"
  | "TEMPLATING";

/**
 * Utility method to prevent double slashes in a URL
 */
function preventDoubleSlashes(url: string) {
  return url.replace(/([^:]\/)\/+/g, "$1");
}

/**
 * Hook to use the urlBuilder of the intentBuilder.
 * @param intentType
 * @param moduleCode
 * @param payload
 * @returns
 */
const useBuildIntentUrl = (
  intentType: new () => IEvaIntent,
  moduleCode: ModuleCode,
  payload?: { [key: string]: any },
) => {
  const intentBuilder = useMemo(() => new EvaIntentBuilder(), []);
  const evaSuiteUrl = useRecoilValue(state.core.applicationConfigurationState)?.Configuration[
    "EvaSuite:Url"
  ];

  const adminModuleList = useRecoilValue(state.admin.adminModuleListState.response)?.Modules;

  const adminModuleUrl: string | undefined = useMemo(
    () =>
      adminModuleList?.find(
        (module: EVA.Admin.AdminListModulesResponse.ModuleDto) => module.Code === moduleCode,
      ),
    [adminModuleList, moduleCode],
  )?.Data?.Url;

  const currentUserToken = useRecoilValue(state.currentUser.currentUserTokenState);

  const url = useMemo(() => {
    if (evaSuiteUrl && adminModuleUrl && currentUserToken && intentType) {
      return intentBuilder.buildUrl(
        intentType,
        preventDoubleSlashes(`${evaSuiteUrl}/${adminModuleUrl}/link`),
        payload ?? {},
        currentUserToken,
      );
    }
    return undefined;
  }, [evaSuiteUrl, adminModuleUrl, currentUserToken, intentBuilder, intentType, payload]);

  return url;
};

export default useBuildIntentUrl;
