import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Menu, MenuTrigger } from "@new-black/lyra";

import EditActionButton from "../action-buttons/edit-action-button";

const CaseInformationConsumerAddressActions = ({
  onAdd,
  onEdit,
  onSelect,
}: {
  onAdd?: () => void;
  onEdit?: () => void;
  onSelect?: () => void;
}) => {
  const intl = useIntl();

  const contextMenuItems = useMemo(() => {
    const items = [];

    if (onAdd) {
      items.push({
        itemData: undefined,
        id: "new-address",
        label: intl.formatMessage({
          id: "generic.label.new-address",
          defaultMessage: "New address",
        }),
        onContextItemClick: onAdd,
      });
    }

    if (onEdit) {
      items.push({
        itemData: undefined,
        id: "edit-address",
        label: intl.formatMessage({
          id: "generic.label.edit-address",
          defaultMessage: "Edit address",
        }),
        onContextItemClick: onEdit,
      });
    }

    if (onSelect) {
      items.push({
        itemData: undefined,
        id: "select-address",
        label: intl.formatMessage({
          id: "generic.label.select-address-from-list",
          defaultMessage: "Select address from list",
        }),
        onContextItemClick: onSelect,
      });
    }

    return items;
  }, [intl, onAdd, onEdit, onSelect]);

  return contextMenuItems?.length ? (
    <MenuTrigger>
      <EditActionButton />
      <Menu
        items={contextMenuItems}
        getItemId={(item) => item.id}
        aria-label="Edit address"
        selectRenderElements={(item) => ({ label: item.label })}
        onAction={(value) =>
          contextMenuItems.find((item) => item.id === value)?.onContextItemClick?.()
        }
      />
    </MenuTrigger>
  ) : null;
};

export default CaseInformationConsumerAddressActions;
