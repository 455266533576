import { Button, SvgIcon } from "@new-black/lyra";

import { StyledListItemText } from "./styled-parent-organization-unit-components";

export interface IParentOrganizationUnitProps {
  name?: string;
  onGoBack?: () => void;
}

const ParentOrganizationUnit = ({ name, onGoBack }: IParentOrganizationUnitProps) => (
  <div className="relative flex h-10 w-full items-center">
    <div className="flex w-full items-center">
      <StyledListItemText className="justify-self-center">{name}</StyledListItemText>
    </div>
    {onGoBack ? (
      <div className="absolute h-6">
        <Button className="ml-4" variant="icon-xs" onPress={onGoBack}>
          <SvgIcon name="chevron-left" />
        </Button>
      </div>
    ) : null}
  </div>
);

export default ParentOrganizationUnit;
