import { useEffect } from "react";
import type { Row } from "react-table";

import { last } from "lodash";

const useNewRowEditable = (
  page: Row<any>[],
  rows: Row<any>[],
  firstRowEditable: boolean,
  lastRowEditable: boolean,
) => {
  // Effect to turn the newly created row editable
  useEffect(() => {
    if (firstRowEditable && (!!page || !!rows)) {
      const firstRow = page?.[0] ?? rows?.[0];
      firstRow?.setState(() => ({ editing: true }));
    }
    return () => {
      if (firstRowEditable && (!!page || !!rows)) {
        const firstRow = page?.[0] ?? rows?.[0];
        firstRow?.setState(() => ({ editing: false }));
      }
    };
  }, [firstRowEditable, page, rows]);

  // Effect to turn the newly created row editable
  useEffect(() => {
    if (lastRowEditable && rows) {
      const lastRow = last(rows ?? []);
      lastRow?.setState(() => ({ editing: true }));
    }
    return () => {
      if (lastRowEditable && rows) {
        const lastRow = last(rows ?? []);
        lastRow?.setState(() => ({ editing: false }));
      }
    };
  }, [lastRowEditable, rows, page]);
};

export default useNewRowEditable;
