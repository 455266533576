import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listVisibilityGroupsLoaderQuery,
  serviceQuery: listVisibilityGroupsQuery,
  serviceQueryKeys: listVisibilityGroupsQueryKeys,
  useServiceQueryHook: useListVisibilityGroupsQuery,
} = createServiceQuery(CoreManagement.ListVisibilityGroups);

export const {
  serviceLoaderQuery: getVisibilityGroupByIDLoaderQuery,
  serviceQuery: getVisibilityGroupByIDQuery,
  serviceQueryKeys: getVisibilityGroupByIDQueryKeys,
  useServiceQueryHook: useGetVisibilityGroupByIDQuery,
} = createServiceQuery(CoreManagement.GetVisibilityGroupByID, true);

export const {
  serviceLoaderQuery: listVisibilityGroupConfigurationsLoaderQuery,
  serviceQuery: listVisibilityGroupConfigurationsQuery,
  serviceQueryKeys: listVisibilityGroupConfigurationsQueryKeys,
  useServiceQueryHook: useListVisibilityGroupConfigurationsQuery,
} = createServiceQuery(CoreManagement.ListVisibilityGroupConfigurations, true);

export const {
  serviceLoaderQuery: getVisibilityGroupConfigurationLoaderQuery,
  serviceQuery: getVisibilityGroupConfigurationQuery,
  serviceQueryKeys: getVisibilityGroupConfigurationQueryKeys,
  useServiceQueryHook: useGetVisibilityGroupConfigurationQuery,
} = createServiceQuery(CoreManagement.GetVisibilityGroupConfiguration, true);
