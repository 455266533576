import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";
import { Button, Separator } from "@new-black/lyra";
import { FormikHelpers } from "formik";
import * as yup from "yup";

import { EVAFormik } from "components/suite-composite/eva-formik";
import { FormikInput } from "components/suite-composite/formik-inputs";
import ErrorBoundary from "components/suite-ui/error-boundary";
import Grid from "components/suite-ui/grid";
import Text from "components/suite-ui/text";

import useResendUserInteractionCommunication from "./hooks/use-resend-user-interaction-communication";
import { IResendFormValues } from "./types";

interface IResendInteractionEmailModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedInteractionLog: EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined;
  setSelectedInteractionLog: Dispatch<
    SetStateAction<EVA.Core.ListUserInteractionsResponse.UserInteractionDto | undefined>
  >;
  onSuccess?: () => void;
}

export const ResendInteractionEmailModal = ({
  onSuccess,
  open,
  selectedInteractionLog,
  setOpen,
  setSelectedInteractionLog,
}: IResendInteractionEmailModalProps) => {
  const intl = useIntl();

  const initialFormValues = useMemo(
    () =>
      ({
        ID: selectedInteractionLog?.ID,
        EmailAddress: selectedInteractionLog?.User?.EmailAddress,
      } as IResendFormValues),
    [selectedInteractionLog],
  );

  const { isLoading, resendUnserInteractionCommunication } =
    useResendUserInteractionCommunication(onSuccess);

  const handleClose = useCallback(() => {
    setOpen(false);
    setSelectedInteractionLog(undefined);
  }, [setOpen, setSelectedInteractionLog]);

  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        EmailAddress: yup
          .string()
          .email(
            intl.formatMessage({
              id: "validation.email",
              defaultMessage: "This field must be a valid email address",
            }),
          )
          .required(
            intl.formatMessage({
              id: "validation.required",
              defaultMessage: "This field is required",
            }),
          ),
      }),
    [intl],
  );

  const handleSubmit = useCallback(
    (
      { EmailAddress, ID }: IResendFormValues,
      { setSubmitting }: FormikHelpers<IResendFormValues>,
    ) => {
      if (ID !== undefined && EmailAddress !== undefined) {
        resendUnserInteractionCommunication({
          ID,
          EmailAddress,
        })
          .then(() => {
            setSubmitting(false);
          })
          .then(handleClose);
      }
    },
    [handleClose, resendUnserInteractionCommunication],
  );

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
      <ErrorBoundary>
        <div className="p-5">
          <Text variant="h2">
            <FormattedMessage id="generic.label.resend-email" defaultMessage="Resend email" />
          </Text>
        </div>
        <EVAFormik<IResendFormValues>
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialFormValues}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, submitForm }) => (
            <>
              <div className="p-5 pt-0">
                <FormikInput
                  name="EmailAddress"
                  label={intl.formatMessage({
                    id: "generic.label.recipient",
                    defaultMessage: "Recipient",
                  })}
                  helperText={intl.formatMessage({
                    id: "orders.detail.interaction-logs.resend-email-modal.email-input.helper-text",
                    defaultMessage:
                      "Modifying the recipient will resend the email to the new recipient, but it will not change the customer's email address.",
                  })}
                />
              </div>
              <Separator />
              <div className="p-5">
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  alignContent="center"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    <Button
                      variant="secondary"
                      onPress={handleClose}
                      isDisabled={isSubmitting || isLoading}
                    >
                      <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      onPress={submitForm}
                      isLoading={isLoading}
                      isDisabled={isSubmitting || isLoading}
                    >
                      <FormattedMessage id="generic.label.send" defaultMessage="Send" />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          )}
        </EVAFormik>
      </ErrorBoundary>
    </Dialog>
  );
};
