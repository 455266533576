import { z } from "zod";
import { zfd } from "zod-form-data";

import { CustomFieldDataType } from "./custom-field-data-types";
import { UserType } from "./user-type";

export interface ICustomFieldOptions {
  IsRequired?: boolean;
  MinimumValue?: number;
  MaximumValue?: number;
  MinimumLength?: number;
  MaximumLength?: number;
  MinimumDate?: string;
  MaximumDate?: string;
  DefaultCustomFieldValue?: EVA.Core.CustomFieldValue;
  VisibleByUserTypes?: UserType;
  EditableByUserTypes?: UserType;
  OrganizationUnitSet?: {
    /** Entity type: OrganizationUnitSet */
    ID: number;
    Name: string;
  };
}

export interface ICustomField {
  ID: number;
  Name: string;
  Order?: number;
  TypeID?: number;
  TypeName?: string;
  TypeKey?: string;
  IsArray?: boolean;
  BackendID?: string;
  InputHint?: string;
  DisplayName?: string;
  Options?: ICustomFieldOptions;
  DataType: CustomFieldDataType;
  EnumValues?: { [key: string]: string };
}

export enum CustomFieldType {
  Order = "Order",
  OrderLine = "OrderLine",
  User = "User",
  Shipment = "Shipment",
  PaymentMethod = "PaymentMethod",
  OrganizationUnit = "OrganizationUnit",
  Case = "Case",
  UserTasks = "UserTask",
  Repair = "Repair",
  Wishlist = "Wishlist",
  Discount = "Discount",
  ShippingMethod = "ShippingMethod",
}

export const customFieldPrimitiveArrayTypeSchema = z.union([
  z.array(z.string().optional()),
  z.array(z.boolean().optional()),
  z.array(z.number().optional()),
]);

export type CustomFieldPrimitiveArrayType = z.infer<typeof customFieldPrimitiveArrayTypeSchema>;

export const customFieldPrimitiveTypeSchema = z
  .union([z.string(), z.number(), z.boolean(), customFieldPrimitiveArrayTypeSchema])
  .optional();

export type CustomFieldPrimitiveType = z.infer<typeof customFieldPrimitiveTypeSchema>;

export enum CustomFieldValueOperator {
  Equals = 0,
  NotEquals = 1,
  LowerThen = 2,
  LowerThenOrEqualTo = 3,
  HigherThenOrEqualTo = 4,
  HigherThen = 5,
  Contains = 7,
  DoesNotContain = 8,
  StartsWith = 9,
  DoesNotStartWith = 10,
  EndsWith = 11,
  DoesNotEndWith = 12,
  HasValue = 13,
  DoesntHaveValue = 14,
}

export const CUSTOM_FIELD_OPERATORS_THAT_DONT_REQUIRE_VALUE = [
  CustomFieldValueOperator.HasValue,
  CustomFieldValueOperator.DoesntHaveValue,
];

export const CUSTOM_FIELD_OPERATORS_DEFAULT_MESSAGE_MAP: Record<CustomFieldValueOperator, string> =
  {
    [CustomFieldValueOperator.Equals]: "Equals",
    [CustomFieldValueOperator.NotEquals]: "Does not equal",
    [CustomFieldValueOperator.LowerThen]: "Lower than",
    [CustomFieldValueOperator.LowerThenOrEqualTo]: "Lower than or equal to",
    [CustomFieldValueOperator.HigherThenOrEqualTo]: "Higher than or equal to",
    [CustomFieldValueOperator.HigherThen]: "Higher than",
    [CustomFieldValueOperator.Contains]: "Contains",
    [CustomFieldValueOperator.DoesNotContain]: "Does not contain",
    [CustomFieldValueOperator.StartsWith]: "Starts with",
    [CustomFieldValueOperator.DoesNotStartWith]: "Does not start with",
    [CustomFieldValueOperator.EndsWith]: "Ends with",
    [CustomFieldValueOperator.DoesNotEndWith]: "Does not end with",
    [CustomFieldValueOperator.HasValue]: "Has value",
    [CustomFieldValueOperator.DoesntHaveValue]: "Does not have value",
  };

export const customFieldValueSchema = z.object({
  StringValue: zfd.text(z.string().optional()),
  BlobValue: zfd.text(z.string().optional()),
  NumberValue: zfd.numeric(z.number().optional()),
  BoolValue: z.boolean().optional(),
  DateTimeValue: zfd.text(z.string().optional()),
});

export const customFieldValueWithArraysSchema = customFieldValueSchema.extend({
  ArrayValues: z.array(customFieldValueSchema).optional(),
});

export const customFieldOptionsSchema = z.object({
  IsRequired: z.boolean().optional(),
  MinimumValue: z.number().optional(),
  MaximumValue: z.number().optional(),
  MinimumLength: z.number().optional(),
  MaximumLength: z.number().optional(),
  MinimumDate: z.string().optional(),
  MaximumDate: z.string().optional(),
  DefaultCustomFieldValue: customFieldValueWithArraysSchema.optional(),
  IsEditable: z.boolean().optional(),
  IsArray: z.boolean().optional(),
  EnumValues: z.record(z.string(), z.string()).optional(),
  DefaultValue: z.string().optional(),
});

export const customFieldOperatorSchema = z.union([
  zfd.numeric(z.literal(CustomFieldValueOperator.Equals)),
  zfd.numeric(z.literal(CustomFieldValueOperator.NotEquals)),
  zfd.numeric(z.literal(CustomFieldValueOperator.LowerThen)),
  zfd.numeric(z.literal(CustomFieldValueOperator.LowerThenOrEqualTo)),
  zfd.numeric(z.literal(CustomFieldValueOperator.HigherThenOrEqualTo)),
  zfd.numeric(z.literal(CustomFieldValueOperator.HigherThen)),
  zfd.numeric(z.literal(CustomFieldValueOperator.Contains)),
  zfd.numeric(z.literal(CustomFieldValueOperator.DoesNotContain)),
  zfd.numeric(z.literal(CustomFieldValueOperator.StartsWith)),
  zfd.numeric(z.literal(CustomFieldValueOperator.DoesNotStartWith)),
  zfd.numeric(z.literal(CustomFieldValueOperator.EndsWith)),
  zfd.numeric(z.literal(CustomFieldValueOperator.DoesNotEndWith)),
  zfd.numeric(z.literal(CustomFieldValueOperator.HasValue)),
  zfd.numeric(z.literal(CustomFieldValueOperator.DoesntHaveValue)),
]);
