export enum MonacoEditorLanguage {
  Orchestration = "Orchestration",
  Stencil = "Stencil",
  Cookbook = "Cookbook",
}

export enum ScriptEditorLanguage {
  Extension = "Extension",
  OrderOrchestration = "OrderOrchestration",
  Cookbook = "Cookbook",
}

export enum ParseTypes {
  None = 0,
  Parse = 1,
  Fast = 1,
  TypeCheck = 3,
  Transpile = 7,
  Full = 7,
}
