import { useIntl } from "react-intl";

import { ConfirmationModal } from "components/suite-composite/confirmation-modal";

interface ITaskAssigneeWarningDialogLyra {
  open: boolean;
  onClose: (accept: boolean) => void;
}

const TaskAssigneeWarningDialogLyra = ({ onClose, open }: ITaskAssigneeWarningDialogLyra) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      variant="lyra"
      isOpen={open}
      onCancel={() => onClose(false)}
      onConfirm={() => onClose(true)}
      messages={{
        confirmButtonMessage: intl.formatMessage({
          id: "generic.label.yes",
          defaultMessage: "Yes",
        }),
        cancelButtonMessage: intl.formatMessage({ id: "generic.label.no", defaultMessage: "No" }),
        titleMessage: intl.formatMessage({
          id: "generic.label.warning",
          defaultMessage: "Warning",
        }),
        descriptionMessage: intl.formatMessage({
          id: "task-assigned-warning-dialog.text",
          defaultMessage:
            "This task is assigned to a different user, would you like to continue and assign it to yourself?",
        }),
      }}
    />
  );
};

export default TaskAssigneeWarningDialogLyra;
