export default {
  login: {
    path: "/login",
  },
  logout: {
    path: "/logout",
    loader: {
      path: "/logout/loader",
    },
  },
  forgotPassword: {
    path: "/forgot-password",
    changePassword: {
      path: "/forgot-password/:token",
    },
  },
  selectEndpoint: {
    path: "/select-endpoint",
  },
  notFound: {
    path: "/404",
  },
  notAuthorized: {
    path: "/401",
  },
} as const;
