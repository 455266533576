import useEventListener from "./suite-react-hooks/use-event-listener";

const useSaveShortcut = (handler?: () => void) => {
  useEventListener("keydown", (event: KeyboardEvent) => {
    if (event.key === "s" && (event.metaKey || event.ctrlKey)) {
      handler?.();
      event.preventDefault();
    }
  });
};

export default useSaveShortcut;
