import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";
import { defer } from "lodash";

import { TableBox } from "./table-helper-components";
import type { TableRowSaveAndCancelActionsProps } from "./table-types";

const TableRowSaveAndCancelActions = ({
  cancelTooltipTitle,
  firstRowEditable,
  lastRowEditable,
  props,
  removeByIndex,
  rows,
  saveTooltipTitle,
  setFirstRowEditable,
  setLastRowEditable,
  updateRow,
}: TableRowSaveAndCancelActionsProps) => (
  <TableBox flex justifyEnd>
    <ButtonGroup>
      <Button
        variant="icon"
        type="submit"
        tooltip={saveTooltipTitle}
        onPress={() => {
          const disabledEditing = () => {
            setFirstRowEditable(false);
            setLastRowEditable(false);
            props.row.setState(() => ({
              editing: !props.row.state.editing,
            }));
          };

          if (updateRow) {
            const result = updateRow(props.row?.original, props.row?.index);
            if (result === true) {
              disabledEditing();
            }
          } else {
            disabledEditing();
          }
        }}
      >
        <SvgIcon name="alert-success" />
      </Button>

      <Button
        variant="icon"
        tooltip={cancelTooltipTitle}
        onPress={() => {
          setFirstRowEditable(false);
          setLastRowEditable(false);
          props.row.setState(() => ({
            editing: !props.row.state.editing,
          }));

          defer(() => {
            // Delete the line if it's a newly created row that has not yet been saved yet.
            //
            if (firstRowEditable && removeByIndex && props.row.index === 0) {
              removeByIndex([0]);
            } else if (lastRowEditable && removeByIndex && props.row.index === rows.length - 1) {
              removeByIndex([rows.length - 1]);
            }
          });
        }}
      >
        <SvgIcon name="alert-error" />
      </Button>
    </ButtonGroup>
  </TableBox>
);

export default TableRowSaveAndCancelActions;
