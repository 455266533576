import { TouchEvent, TouchEventHandler, useRef } from "react";

interface UseSwipeResult {
  ref: React.RefObject<any>;
  onTouchStart: TouchEventHandler<HTMLElement>;
  onTouchMove: TouchEventHandler<HTMLElement>;
  onTouchEnd: TouchEventHandler<HTMLElement>;
}

type UseSwipeProps = {
  onSwipeLeft?: (event: TouchEvent<HTMLElement>) => void;
  onSwipeRight?: (event: TouchEvent<HTMLElement>) => void;
  onSwipeUp?: (event: TouchEvent<HTMLElement>) => void;
  onSwipeDown?: (event: TouchEvent<HTMLElement>) => void;
  threshold?: number;
};

export const useSwipe = ({
  onSwipeDown,
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  threshold = 50,
}: UseSwipeProps): UseSwipeResult => {
  // Create a ref to track the initial touch position
  const initialTouchPosition = useRef<{ x: number; y: number }>();

  // Set up event handlers to handle swipe gestures
  const onTouchStart: TouchEventHandler<HTMLElement> = (event) => {
    // Save the initial touch position when the user touches the screen
    initialTouchPosition.current = { x: event.touches[0].clientX, y: event.touches[0].clientY };
  };

  const onTouchMove: TouchEventHandler<HTMLElement> = (event) => {
    // Check if there is an initial touch position saved
    if (initialTouchPosition.current) {
      // Calculate the distance the user has moved their finger on the screen
      const deltaX = event.touches[0].clientX - initialTouchPosition.current.x;
      const deltaY = event.touches[0].clientY - initialTouchPosition.current.y;

      // Check if the movement exceeds the threshold in either direction
      if (Math.abs(deltaX) > threshold || Math.abs(deltaY) > threshold) {
        // Check the direction of the swipe
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
          // Swipe left or right
          if (deltaX > 0) {
            onSwipeRight?.(event);
          } else {
            onSwipeLeft?.(event);
          }
        } else {
          // Swipe up or down
          if (deltaY > 0) {
            onSwipeDown?.(event);
          } else {
            onSwipeUp?.(event);
          }
        }

        // Reset the initial touch position
        initialTouchPosition.current = undefined;
      }
    }
  };

  const onTouchEnd: TouchEventHandler<HTMLElement> = () => {
    // Reset the initial touch position
    initialTouchPosition.current = undefined;
  };

  // Return the ref and the event handlers
  return { ref: initialTouchPosition, onTouchStart, onTouchMove, onTouchEnd };
};
