import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

export const productRequirementsForOrderServiceState = builders.buildServiceFamilyState({
  key: "productRequirementsForOrder",
  service: Core.GetProductRequirementValuesForOrder,
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const productRequirementsForOrderValuesSelector = selectorFamily({
  key: "productRequirementsForOrder:Values",
  get:
    (params) =>
    ({ get }) => {
      const response = get(productRequirementsForOrderServiceState.response(params));
      return response?.Requirements;
    },
});
