import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";
import useSetRequest from "hooks/suite-react-hooks/use-set-request";
import settingState from "store/setting";

const useGetSetting = (key: string, organizationUnitID?: number) => {
  const familyKey = useMemo(
    () => `${key}${organizationUnitID ? `-${organizationUnitID}` : "-global"}`,
    [key, organizationUnitID],
  );

  const request = useMemo(
    () => ({
      Key: key,
      OrganizationUnitID: organizationUnitID,
    }),
    [key, organizationUnitID],
  );

  useSetRequest(settingState.request(familyKey), request);

  const setting = hooks.useGetState(settingState.response(familyKey));

  useHandleSWRFamily(settingState, familyKey);

  return setting;
};

export default useGetSetting;
