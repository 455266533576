import { PropsWithChildren, useEffect } from "react";

import { ScriptEditorLanguage } from "types/monaco-editor-language";
import { contextGenerator } from "util/context-generator";

import useConfigureScriptLanguage from "./hooks/use-configure-script-language";

type ScriptCodeEditorProviderProps = {
  dialect: ScriptEditorLanguage;
  dialectKeywords: string[];
};

const { Provider, useContext } = contextGenerator<ScriptCodeEditorProviderProps | undefined>(
  undefined,
);

/**
 * Component that handles initializing and disposing all the Script language features on the monaco editor.
 * It will initialize the language features when mounted and dispose all providers when unmounted.
 * Whenever using an `ScriptCodeEditor` or `ScriptDiffEditor` make sure it is a children of this provider for syntax highlighting and code completion to work.
 */
export const ScriptCodeEditorProvider = ({
  children,
  dialect,
  dialectKeywords,
}: PropsWithChildren<ScriptCodeEditorProviderProps>) => {
  const { disposeScriptLanguage, registerScriptLanguage } = useConfigureScriptLanguage(
    dialect,
    dialectKeywords,
  );

  // Register language on mount
  useEffect(() => registerScriptLanguage(), [registerScriptLanguage]);

  // Dispose language on unmount
  useEffect(() => () => disposeScriptLanguage(), [disposeScriptLanguage]);

  return <Provider value={{ dialect, dialectKeywords }}>{children}</Provider>;
};

export const useScriptCodeEditorContext = useContext;
