import { useCallback } from "react";
import { IntlShape, useIntl } from "react-intl";

export enum OrderProperties {
  None = "none",
  IsPickup = "ispickup",
  CreatedByEmployee = "createdbyemployee",
  IsInterbranch = "isinterbranch",
  IsB2B = "isb2b",
  Duplicated = "duplicated",
  Autocompleted = "autocompleted",
  CreatedFromWishList = "createdfromwishlist",
  ReturnToSupplier = "returntosupplier",
  CustomTaxesApplied = "customtaxesapplied",
  Replenishment = "replenishment",
  SoldByOverridden = "soldbyoverridden",
  ImportedFromSentinel = "importedfromsentinel",
  IsTransferOrder = "istransferorder",
  IsUnified = "isunified",
  IsOffer = "isoffer",
  IsReturnsTransferOrder = "isreturnstransferorder",
  IsReturned = "isreturned",
  HasReturnLines = "hasreturnlines",
  Resitution = "restitution",
  DownPayment = "downpayment",
  Repairs = "repairs",
}

export const getOrderPropertyLabel = (property: OrderProperties, intlObject: IntlShape) =>
  ((
    {
      none: intlObject.formatMessage({ id: "generic.label.none", defaultMessage: "None" }),
      autocompleted: intlObject.formatMessage({
        id: "order-property-labels.autocompleted",
        defaultMessage: "Autocompleted",
      }),
      createdbyemployee: intlObject.formatMessage({
        id: "order-property-labels.createdbyemployee",
        defaultMessage: "Created by employee",
      }),
      createdfromwishlist: intlObject.formatMessage({
        id: "order-property-labels.createdfromwishlist",
        defaultMessage: "Created from wishList",
      }),
      customtaxesapplied: intlObject.formatMessage({
        id: "order-property-labels.customtaxesapplied",
        defaultMessage: "Custom taxes applied",
      }),
      downpayment: intlObject.formatMessage({
        id: "order-property-labels.downpayment",
        defaultMessage: "Down payment",
      }),
      duplicated: intlObject.formatMessage({
        id: "order-property-labels.duplicated",
        defaultMessage: "Duplicated",
      }),
      hasreturnlines: intlObject.formatMessage({
        id: "order-property-labels.hasreturnlines",
        defaultMessage: "Has return lines",
      }),
      importedfromliveguard: intlObject.formatMessage({
        id: "order-property-labels.importedfromliveguard",
        defaultMessage: "Imported from LiveGuard",
      }),
      importedfromsentinel: intlObject.formatMessage({
        id: "order-property-labels.importedfromsentinel",
        defaultMessage: "Imported from sentinel",
      }),
      isb2b: intlObject.formatMessage({ id: "order-property-labels.isb2b", defaultMessage: "B2B" }),
      isinterbranch: intlObject.formatMessage({
        id: "order-property-labels.isinterbranch",
        defaultMessage: "Interbranch",
      }),
      isoffer: intlObject.formatMessage({
        id: "order-property-labels.isoffer",
        defaultMessage: "Is offer",
      }),
      ispickup: intlObject.formatMessage({
        id: "order-property-labels.ispickup",
        defaultMessage: "Pickup",
      }),
      isreturned: intlObject.formatMessage({
        id: "order-property-labels.isreturned",
        defaultMessage: "Is returned",
      }),
      isreturnstransferorder: intlObject.formatMessage({
        id: "order-property-labels.isreturnstransferorder",
        defaultMessage: "Is return transfer order",
      }),
      istransferorder: intlObject.formatMessage({
        id: "order-property-labels.istransferorder",
        defaultMessage: "Transfer order",
      }),
      isunified: intlObject.formatMessage({
        id: "order-property-labels.isunified",
        defaultMessage: "Is unified",
      }),
      repairs: intlObject.formatMessage({
        id: "order-property-labels.repairs",
        defaultMessage: "Repairs",
      }),
      replenishment: intlObject.formatMessage({
        id: "order-property-labels.replenishment",
        defaultMessage: "Replenishment",
      }),
      restitution: intlObject.formatMessage({
        id: "order-property-labels.restitution",
        defaultMessage: "Restitution",
      }),
      returntosupplier: intlObject.formatMessage({
        id: "order-property-labels.returntosupplier",
        defaultMessage: "Return to supplier",
      }),
      soldbyoverridden: intlObject.formatMessage({
        id: "order-property-labels.soldbyoverridden",
        defaultMessage: "Sold by overridden",
      }),
    } as Record<OrderProperties, string>
  )[property]);

export function useGetOrderPropertyLabel() {
  const intl = useIntl();

  const getOrderPropertyLabelCallback = useCallback(
    (orderProperty: OrderProperties) => getOrderPropertyLabel(orderProperty, intl),
    [intl],
  );

  return getOrderPropertyLabelCallback;
}
