import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

export const listCustomFieldDataTypesOperatorsServiceState = builders.buildServiceState({
  key: "CoreManagement:ListCustomFieldDataTypesOperators",
  service: CoreManagement.ListCustomFieldDataTypesOperators,
  requireEmployee: true,
  allowEmptyRequest: true,
});

export const listCustomFieldDataTypesOperatorsResultSelector = selector({
  key: "CoreManagement:ListCustomFieldDataTypesOperators:Result",
  get: ({ get }) => get(listCustomFieldDataTypesOperatorsServiceState.response)?.DataTypeOperators,
});
