import { redirect } from "react-router-dom";

export const redirectWithSearchParams = (targetUrl: string, requestUrl: string) => {
  const url = new URL(requestUrl);

  if (url.search) {
    return redirect(`${targetUrl}${url.search.toString()}`);
  }

  return redirect(targetUrl);
};
