import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import { toast } from "components/suite-ui/toast";

import { createEVAService } from "./http";
import { intlAccessor } from "./intl-accessor";

export interface IUseCallServiceMessages {
  /** Shown while the call is in progress */
  loadingMessage?: string;
  /** Shown when the call is successful */
  successMessage?: string;
}

export interface IFunctionalUseCallServiceMessages<SVC extends IEvaServiceDefinition> {
  /** Shown while the call is in progress */
  loadingMessage?: string;
  /** Shown when the call is successful */
  successMessage?: string | ((data: SVC["response"]) => string);
}

export type MutateParams<SVC extends IEvaServiceDefinition> = {
  service: new () => SVC;
  disabledNotifications?: boolean;
  /** Pass custom strings to be displayed inside the toast. */
  messages?: IUseCallServiceMessages | IFunctionalUseCallServiceMessages<SVC>;
  onSuccess?: (response: SVC["response"], request: SVC["request"]) => Promise<void>;
  onError?: (error?: unknown) => Promise<void>;
  disableErrorNotification?: boolean;
  disableRedirectOn401?: boolean;
};

export const mutate =
  <SVC extends IEvaServiceDefinition>({
    disabledNotifications,
    disableErrorNotification,
    disableRedirectOn401 = false,
    messages,
    onError,
    onSuccess,
    service,
  }: MutateParams<SVC>) =>
  async (request: SVC["request"], options?: IEvaServiceCallOptions, throwOnError?: boolean) => {
    const evaService = createEVAService(service);
    let toastID: string | undefined;

    const loadingMessage =
      messages?.loadingMessage ??
      intlAccessor.formatMessage({ id: "generic.label.loading", defaultMessage: "Loading..." });
    const successMessage = (response: SVC["response"]) =>
      (messages?.successMessage && typeof messages?.successMessage === "function"
        ? messages?.successMessage(response)
        : messages?.successMessage) ??
      intlAccessor.formatMessage({
        id: "generic.message.action-success",
        defaultMessage: "Action successfully executed.",
      });

    try {
      if (!disabledNotifications) toastID = toast.loading(loadingMessage);
      const response = await evaService.call(
        request,
        options,
        throwOnError ?? true,
        disableErrorNotification,
        disableRedirectOn401,
      );

      await onSuccess?.(response, request);
      if (!disabledNotifications)
        toast.success(successMessage(response), { id: toastID, duration: 6000 });

      return {
        success: true,
        response,
      };
    } catch (error) {
      console.error(`[Error: ${service?.name}]`, error);
      // make sure we have a toastID to dismiss instead of dismissing all toasts when toastID is undefined
      if (toastID) {
        toast.dismiss(toastID);
      }
      onError?.(error);
      if (throwOnError) {
        throw error;
      }
    }
  };
