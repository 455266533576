import { useCallback, useEffect, useMemo, useState } from "react";
import type { Column } from "react-table";

import type { IOrderableColumn } from "../table-types";

// This function needs to be declared outside of the hook to maintain referential stability & equality
function defaultGetOrderableColumnValue(column: Column<any>) {
  return (column?.accessor ?? undefined) as string | undefined;
}

/* State that handles columns order & visibility
 *
 */
const useOrganizeColumns = (
  columns?: Column<any>[],
  onColumnsOrderChange?: (newOrderedColumns?: IOrderableColumn[]) => void,
  showOrganizeColumns?: boolean,
  getOrderableColumnValue: (
    column: Column<any>,
  ) => string | undefined = defaultGetOrderableColumnValue,
) => {
  const orderedColumns: IOrderableColumn[] | undefined = useMemo(
    () =>
      columns
        ?.filter((column) => getOrderableColumnValue(column))
        ?.map((column) => ({
          label: column.Header as string,
          value: getOrderableColumnValue(column)!,
        })),
    [columns, getOrderableColumnValue],
  );
  const [localOrderedColumns, setLocalOrderedColumns] = useState<IOrderableColumn[] | undefined>(
    orderedColumns,
  );

  useEffect(() => setLocalOrderedColumns(orderedColumns), [orderedColumns]);

  const [isOrganizeColumnsActive, setIsOrganizeColumnsActive] = useState(false);

  useEffect(() => {
    if (showOrganizeColumns !== undefined) {
      setIsOrganizeColumnsActive((previous) => {
        if (previous && !showOrganizeColumns) {
          onColumnsOrderChange?.(localOrderedColumns ?? []);
        }
        return showOrganizeColumns;
      });
    }
  }, [localOrderedColumns, onColumnsOrderChange, showOrganizeColumns]);

  const toggleOrganizeColumns = useCallback(() => {
    if (isOrganizeColumnsActive) {
      onColumnsOrderChange?.(localOrderedColumns ?? []);
    }
    setIsOrganizeColumnsActive((previous) => !previous);
  }, [isOrganizeColumnsActive, localOrderedColumns, onColumnsOrderChange]);

  return {
    localOrderedColumns,
    setLocalOrderedColumns,
    isOrganizeColumnsActive,
    toggleOrganizeColumns,
  };
};

export default useOrganizeColumns;
