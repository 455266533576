import { ControlledEnumSelect, ControlledLyraEnumSelect } from "./controlled-enum-select";
import { FormikEnumSelect, FormikLyraEnumSelect } from "./formik-enum-select";
import { RecoilEnumSelect, RecoilLyraEnumSelect } from "./recoil-enum-select";
import { UncontrolledEnumSelect, UncontrolledLyraEnumSelect } from "./uncontrolled-enum-select";

export const EnumSelect = {
  Formik: FormikEnumSelect,
  Controlled: ControlledEnumSelect,
  Uncontrolled: UncontrolledEnumSelect,
  Recoil: RecoilEnumSelect,
};

export const LyraEnumSelect = {
  Formik: FormikLyraEnumSelect,
  Controlled: ControlledLyraEnumSelect,
  Uncontrolled: UncontrolledLyraEnumSelect,
  Recoil: RecoilLyraEnumSelect,
};

export * from "./enum-select.types";
