import { useMemo, useState } from "react";

import useFuseInstance from "hooks/use-fuse-instance";

const useFilteredSubsets = (subsets: EVA.Core.Management.OrganizationUnitSetDetails[]) => {
  const [term, setTerm] = useState<string | undefined>();
  const fuseInstance = useFuseInstance(["ID", "Name"], subsets);

  const filteredSubsets = useMemo(() => {
    if (fuseInstance && term) {
      return fuseInstance
        .search({ $or: [{ ID: term }, { Name: term }] })
        .map((result) => result.item);
    }
    return subsets;
  }, [fuseInstance, subsets, term]);

  return { term, setTerm, filteredSubsets };
};

export default useFilteredSubsets;
