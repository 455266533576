import { useState } from "react";

import { z } from "zod";

type UseLocalStorageType<T> = [T, (item: T) => void];

export default function useLocalStorage<T>(
  key: string,
  initialValue: T,
  zodSchema?: z.ZodSchema<T>,
): UseLocalStorageType<T> {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (!item) {
        return initialValue;
      }
      const parsedValue = JSON.parse(item);
      if (zodSchema) {
        const result = zodSchema.safeParse(parsedValue);
        if (result.success) {
          return result.data;
        }
        return initialValue;
      }
      return parsedValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };

  return [storedValue, setValue];
}

export const SafeLocalStorage = {
  get: <T>(key: string, initialValue: T, zodSchema?: z.ZodSchema<T>): T => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (!item) {
        return initialValue;
      }
      const parsedValue = JSON.parse(item);
      if (zodSchema) {
        const result = zodSchema.safeParse(parsedValue);
        if (result.success) {
          return result.data;
        }
        return initialValue;
      }
      return parsedValue as T;
    } catch (error) {
      // If error, also return initialValue
      console.error(error);
      return initialValue;
    }
  },

  set: <T>(key: string, value: T, zodSchema?: z.ZodSchema<T>) => {
    try {
      const prevValue = SafeLocalStorage.get(key, value, zodSchema);
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(prevValue) : value;
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  },

  unset: (key: string) => {
    try {
      window.localStorage.removeItem(key);
      return { success: true };
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
      return { success: false };
    }
  },
};
