import { useEffect, useState } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";
import ky from "ky";

/**
 * Hook that tries to get the image of a product based on its ID.
 * @param productID EVA ID of the product
 * @returns the URL of the product image or `undefined` if the image cannot be found
 */
const useProductImageById = (productID?: number) => {
  const [imageUrl, setImageUrl] = useState<string>();

  const endpointUrl = hooks.useGetState(state.core.evaEndpointUrlState);

  useEffect(() => {
    if (endpointUrl && productID) {
      ky.get(`${endpointUrl}/productimage/500/500/${productID}.png`, {
        throwHttpErrors: false, // we don't want do do anything if the image cannot be found so we can disable throwing errors
      }).then(async (response) => {
        if (response?.ok) {
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          setImageUrl(url);
        }
      });
    }
  }, [endpointUrl, productID]);

  useEffect(
    () => () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl);
      }
    },
    [imageUrl],
  );

  return imageUrl;
};

export default useProductImageById;
