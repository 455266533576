import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { ButtonLink } from "components/routing";

import { IActionButtonLink } from "./types";

const AddActionButtonLink = forwardRef<HTMLAnchorElement, IActionButtonLink>(
  (
    {
      className,
      force,
      isDisabled,
      keepSearchParams,
      onLinkClick,
      state,
      target,
      to,
      tooltip,
    }: IActionButtonLink,
    ref,
  ) => {
    const intl = useIntl();

    return (
      <ButtonLink
        to={to}
        keepSearchParams={keepSearchParams}
        target={target}
        onClick={onLinkClick}
        force={force}
        variant="icon"
        state={state}
        ref={ref}
        className={className}
        isDisabled={isDisabled}
        tooltip={tooltip ?? intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })}
      >
        <SvgIcon name="plus" />
      </ButtonLink>
    );
  },
);

AddActionButtonLink.displayName = "AddActionButtonLink";

export default AddActionButtonLink;
