import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import usePrevious from "hooks/suite-react-hooks/use-previous";
import { DEFAULT_LIMIT } from "util/base-values";
import { contextGenerator } from "util/context-generator";

import { IProductSearchModalResultBase, IProductSetFilter } from "./types";
import useProductSearchModalProductResults from "./use-product-search-modal-product-results";

export interface IProductSearchModalContext<TProduct extends IProductSearchModalResultBase> {
  type: "single" | "multi";
  filters: IProductSetFilter[];
  initialFilters?: Record<string, EVA.Core.FilterModel>;
  setPropertyFilter: (property: string, values: string[]) => void;
  removePropertyFilter: (property: string) => void;
  querySearchValue?: string;
  setQuerySearchValue: (query: string | undefined) => void;
  limit: number;
  incrementLimit: () => void;
  productResults: TProduct[];
  setProductResults: Dispatch<SetStateAction<TProduct[]>>;
  selectedProducts: TProduct[];
  setSelectedProducts: Dispatch<SetStateAction<TProduct[]>>;
  selectedProductResults: TProduct[];
  selectableProductResults: TProduct[];
  isProductSelectable?: (product: TProduct) => boolean;
  setSelectedProductResults: Dispatch<SetStateAction<TProduct[]>>;
  isProductResultsLoading: boolean;
  productsTotal: number;
}

const { Provider, useContext } =
  contextGenerator<IProductSearchModalContext<IProductSearchModalResultBase>>();

export const ProductSearchModalProvider = <TProduct extends IProductSearchModalResultBase>({
  children,
  filters,
  includedFields,
  isProductSelectable,
  onSelectedProductsChange,
  selectedProducts,
  type,
}: {
  type: "single" | "multi";
  children: ReactNode | ((props: IProductSearchModalContext<TProduct>) => ReactNode);
  includedFields?: string[];
  filters?: Record<string, EVA.Core.FilterModel>;
  selectedProducts?: TProduct[];
  onSelectedProductsChange?: (products: TProduct[]) => void;
  isProductSelectable?: (product: TProduct) => boolean;
}) => {
  // local selection state
  const [_selectedProducts, _setSelectedProducts] = useState<TProduct[]>(selectedProducts ?? []);
  const {
    incrementLimit,
    isProductResultsLoading,
    productResults,
    removePropertyFilter,
    request,
    setProductResults,
    setPropertyFilter,
    setQuerySearchValue,
    total,
  } = useProductSearchModalProductResults<TProduct>({ includedFields, filters });
  const previousProductResults = usePrevious(productResults);
  const [selectedProductResults, setSelectedProductResults] = useState<TProduct[]>([]);

  /** Set the selected products and call the onSelectedProductsChange callback if it exists,
   * so that we update both the local state and the external state.
   * @param updater - The new value or an updater function
   * @returns void
   */
  const setSelectedProducts = useCallback<Dispatch<SetStateAction<TProduct[]>>>(
    (updater) => {
      const newValue = typeof updater === "function" ? updater(_selectedProducts) : updater;
      _setSelectedProducts(newValue);
      onSelectedProductsChange?.(newValue);
    },
    [onSelectedProductsChange, _selectedProducts],
  );

  const selectableProductResults = useMemo(
    () =>
      productResults
        .filter(
          (productResult) =>
            !_selectedProducts.some(
              (selectedProduct) => selectedProduct.product_id === productResult.product_id,
            ),
        )
        .filter((product) => {
          if (isProductSelectable) {
            return isProductSelectable(product);
          }
          return true;
        }),
    [productResults, _selectedProducts, isProductSelectable],
  );

  // update the local state when the selectedProducts prop changes
  // so that we can keep the local state in sync with the external state
  useEffect(() => _setSelectedProducts(selectedProducts ?? []), [selectedProducts]);

  // reset selected products when the product results change
  useEffect(() => {
    if (
      previousProductResults?.some(
        (item, index) => item.product_id !== productResults?.[index]?.product_id,
      )
    ) {
      setSelectedProductResults([]);
    }
  }, [previousProductResults, productResults]);

  const memoizedFilters = useMemo(() => {
    return Object.entries(request?.Filters ?? {}).map(([ProductProperty, filter]) => ({
      ProductProperty,
      ...filter,
    }));
  }, [request?.Filters]);

  const contextValue = useMemo<IProductSearchModalContext<TProduct>>(
    () => ({
      type,
      initialFilters: filters,
      filters: memoizedFilters,
      setPropertyFilter,
      querySearchValue: request?.Query,
      limit: request?.PageSize ?? DEFAULT_LIMIT,
      removePropertyFilter,
      setQuerySearchValue,
      incrementLimit,
      productResults,
      selectedProductResults,
      setSelectedProductResults,
      selectedProducts: _selectedProducts,
      selectableProductResults,
      isProductSelectable,
      setSelectedProducts,
      isProductResultsLoading,
      productsTotal: total,
      setProductResults,
    }),
    [
      type,
      filters,
      memoizedFilters,
      setPropertyFilter,
      request?.Query,
      request?.PageSize,
      removePropertyFilter,
      setQuerySearchValue,
      incrementLimit,
      productResults,
      selectedProductResults,
      _selectedProducts,
      selectableProductResults,
      isProductSelectable,
      setSelectedProducts,
      isProductResultsLoading,
      total,
      setProductResults,
    ],
  );

  return (
    <Provider
      value={contextValue as unknown as IProductSearchModalContext<IProductSearchModalResultBase>}
    >
      {typeof children === "function" ? children(contextValue) : children}
    </Provider>
  );
};

export const useProductSearchModalContext = <
  T extends IProductSearchModalResultBase = IProductSearchModalResultBase,
>() => {
  const contextValue = useContext();
  return contextValue as unknown as IProductSearchModalContext<T>;
};
