import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Cell } from "react-table";

import AmountInput, { IAmountInput } from "components/suite-ui/amount-input";
import usePrevious from "hooks/suite-react-hooks/use-previous";
import { useCurrencyPrecision } from "util/hooks/use-currency-precision";

export interface ITableInlineAmountInput
  extends Omit<IAmountInput, "onChange" | "small" | "value"> {
  onFieldUpdate: (value?: number) => void;
  cell: Cell<any>;
  propertyName: string;
}

const TableInlineAmountInput = ({
  cell,
  onFieldUpdate,
  propertyName,
  ...props
}: ITableInlineAmountInput) => {
  const previousCellValue = usePrevious(cell.value);

  const [shouldValidate, setShouldValidate] = useState(false);

  useEffect(() => {
    if (previousCellValue !== cell.value) {
      onFieldUpdate(cell.value);
    }
  }, [cell.value, onFieldUpdate, previousCellValue]);

  const [localValue, setLocalValue] = useState(cell?.row?.original?.[propertyName]);
  const handleChange = useCallback(
    (newValue?: number) => {
      setLocalValue(newValue || undefined);
      onFieldUpdate(newValue || undefined);
    },
    [onFieldUpdate],
  );

  const precision = useCurrencyPrecision(props.currency);

  useEffect(() => {
    setLocalValue(cell.value);
  }, [cell.value]);

  return (
    <AmountInput
      small
      precision={precision}
      CurrencyID={props.CurrencyID}
      value={localValue ?? ""}
      onChange={(e: ChangeEvent<HTMLInputElement>) =>
        handleChange(e.target.value ? parseFloat(e.target.value) : undefined)
      }
      onBlur={props.required ? () => setShouldValidate(true) : undefined}
      error={(!localValue || `${localValue}` === "") && shouldValidate && props.required}
      {...props}
    />
  );
};

export default TableInlineAmountInput;
