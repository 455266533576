import { Skeleton as MuiSkeleton } from "@material-ui/lab";

import type { ITableLoadingState } from "./table-types";

const TableLoadingState = ({ footerAvailable, limit }: ITableLoadingState) => {
  const rows: number[] = Array.from(Array(Math.max(limit, 5)).keys());

  return (
    <div className="p-5">
      <div className="px-5 py-1.5">
        <MuiSkeleton width="100%" height="36px" variant="rect" />
      </div>

      {rows.map((row) => (
        <div className="px-5 py-1.5" key={row}>
          <MuiSkeleton width="100%" height="36px" variant="rect" />
        </div>
      ))}
      {footerAvailable ? (
        <div className="flex justify-end px-5 py-1.5">
          <MuiSkeleton width="50%" height="36px" variant="rect" />
        </div>
      ) : null}
    </div>
  );
};

export default TableLoadingState;
