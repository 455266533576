import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import {
  IAutocompleteGeneratorProps,
  ILocalAutocompleteGeneratorProps,
} from "./autocomplete-generator.types";
import {
  generateMultiAutocomplete,
  generateMultiLocalAutocomplete,
} from "./generate-multi-autocomplete";
import {
  generateSingleAutocomplete,
  generateSingleLocalAutocomplete,
} from "./generate-single-autocomplete";
import {
  IMultiAutocompleteWrapperReturnValues,
  MultiAutocompleteIDWrapper,
  MultiAutocompleteWrapper,
} from "./multi-autocomplete-wrapper";
import {
  ISingleAutocompleteWrapperReturnValues,
  SingleAutocompleteIDWrapper,
  SingleAutocompleteWrapper,
} from "./single-autocomplete-wrapper";
import { useAutocompleteService } from "./use-autocomplete-service";

export interface IAutocompleteGeneratorReturnValues<
  Item = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
> {
  SingleAutocomplete: ISingleAutocompleteWrapperReturnValues<Item>;
  SingleIDAutocomplete: Omit<
    ISingleAutocompleteWrapperReturnValues<Item[IDKey]>,
    "RecoilIDOnlyWithQuery"
  >;
  MultiAutocomplete: IMultiAutocompleteWrapperReturnValues<Item>;
  MultiIDAutocomplete: IMultiAutocompleteWrapperReturnValues<Item[IDKey]>;
}

/** Utility for generating **Single** and a **Multi Autocomplete** components based on a service, with filtering capabilities
 * Both of these Autocompletes have different Wrapped variants as well:
 * - Single
 *     - Controlled
 *     - Uncontrolled
 *     - Formik
 *     - Recoil
 * - Multi
 *     - Controlled
 *     - Uncontrolled
 *     - Formik
 *     - Recoil
 * 
 * ### Example
 * 
 * ```tsx
  export const {
      MultiAutocomplete: GeneratedMultiOUAutocomplete,
        // GeneratedMultiOUAutocomplete.Controlled
        // GeneratedMultiOUAutocomplete.Uncontrolled
        // GeneratedMultiOUAutocomplete.Formik
        // GeneratedMultiOUAutocomplete.Recoil
      SingleAutocomplete: GeneratedSingleOUAutocomplete,
        // GeneratedSingleOUAutocomplete.Controlled
        // GeneratedSingleOUAutocomplete.Uncontrolled
        // GeneratedSingleOUAutocomplete.Formik
        // GeneratedSingleOUAutocomplete.Recoil
        // GeneratedSingleOUAutocomplete.RecoilIDOnlyWithQuery
      MultiIDAutocomplete: GeneratedMultiIDOUAutocomplete,
        // GeneratedMultiIDOUAutocomplete.Controlled
        // GeneratedMultiIDOUAutocomplete.Uncontrolled
        // GeneratedMultiIDOUAutocomplete.Formik
        // GeneratedMultiIDOUAutocomplete.Recoil
      SingleIDAutocomplete: GeneratedSingleIDOUAutocomplete,
        // GeneratedSingleIDOUAutocomplete.Controlled
        // GeneratedSingleIDOUAutocomplete.Uncontrolled
        // GeneratedSingleIDOUAutocomplete.Formik
        // GeneratedSingleIDOUAutocomplete.Recoil
  } = AutocompleteGenerator<Core.ListOrganizationUnitSummaries, { ID: number; Name: string }>({
    // The generic parameters are optional and automatically inferred by the values provided (service typing), but they can also be overriden manually
    // The `{ ID: number; Name: string }` structure is the default type of the Autocomplete Items - of course, you can override this as needed
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.organization-unit",
      defaultMessage: "Organization unit",
    }),
    useItemByID: (id) => {
      const { item: data, isLoading } = useOrganizationUnitDetailsById(id);
      return { data, isLoading };
    },
    useItemsByID: (ids) => {
      const { items: data, isLoading } = useOrganizationUnitDetailsById(
        ids?.filter((id): id is number => !!id),
      );
      return { data, isLoading };
    },
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: ListOrganizationUnitSummariesQuery,
        initialRequest: { PageConfig: { Start: 0, Limit: 10 } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });
 * ```
 */
const AutocompleteGenerator = <
  SVC extends IEvaServiceDefinition,
  Item = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
>({
  useItemByID,
  useItemsByID,
  ...props
}: IAutocompleteGeneratorProps<SVC, Item, IDKey>): IAutocompleteGeneratorReturnValues<
  Item,
  IDKey
> => {
  const fallbackGetItemHook = (type: "useItemByID" | "useItemsByID") => () => {
    throw new Error(
      `You must provide the ${type} hook as a prop to the generator if you want to use the ${
        type === "useItemByID" ? "SingleIDAutocomplete" : "MultiIDAutocomplete"
      } component`,
    );
  };

  const SingleAutocomplete = generateSingleAutocomplete<SVC, Item>(props);
  const WrappedSingleAutocomplete = SingleAutocompleteWrapper(SingleAutocomplete);
  const WrappedSingleIDAutocomplete = SingleAutocompleteIDWrapper(
    SingleAutocomplete,
    props.idKey,
    useItemByID ?? fallbackGetItemHook("useItemByID"),
  );

  const MultiAutocomplete = generateMultiAutocomplete<SVC, Item>(props);
  const WrappedMultiAutocomplete = MultiAutocompleteWrapper(MultiAutocomplete);
  const WrappedMultiIDAutocomplete = MultiAutocompleteIDWrapper(
    MultiAutocomplete,
    props.idKey,
    useItemsByID ?? fallbackGetItemHook("useItemsByID"),
  );

  return {
    SingleAutocomplete: WrappedSingleAutocomplete,
    SingleIDAutocomplete: WrappedSingleIDAutocomplete,
    MultiAutocomplete: WrappedMultiAutocomplete,
    MultiIDAutocomplete: WrappedMultiIDAutocomplete,
  };
};

AutocompleteGenerator.useAutocompleteService = useAutocompleteService;

/** Utility for generating **Single** and a **Multi Autocomplete** components based on a local array, with filtering capabilities
 * Both of these Autocompletes have different Wrapped variants, as well as ID-based variants (so that you can work with the Autocomplete based on the ID only):
 * - Single
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 * - Single (ID-based)
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 * - Multi
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 * - Multi (ID-based)
 *    - Controlled
 *    - Uncontrolled
 *    - Formik
 *    - Recoil
 *
 * ### Example
 *
 * ```tsx
  const list = [
      { ID: 1, Name: "Item 1" },
      { ID: 2, Name: "Item 2" },
      { ID: 3, Name: "Item 3" },
      { ID: 4, Name: "Item 4" },
      { ID: 5, Name: "Item 5" },
      { ID: 6, Name: "Item 6" },
      { ID: 7, Name: "Item 7" },
      { ID: 8, Name: "Item 8" },
      { ID: 9, Name: "Item 9" },
      { ID: 10, Name: "Item 10" },
    ];

  function useGetExampleItemByID(
    id: number | undefined,
    autocompleteList: IExampleItem[] | undefined,
  ) {
    const item = useMemo(
      () => autocompleteList?.find((item) => item.ID === id),
      [autocompleteList, id],
    );
    return { data: item, isLoading: false };
  }

  function useGetExampleItemsByID(
    ids: number[] | undefined,
    autocompleteList: IExampleItem[] | undefined,
  ) {
    const items = useMemo(
      () =>
        ids
          ?.map((id) => autocompleteList?.find((item) => item.ID === id))
          .filter((item): item is IExampleItem => !!item),
      [autocompleteList, ids],
    );
    return { data: items, isLoading: false };
  }

  export const {
   MultiAutocomplete: GeneratedMultiItemAutocomplete,
    // GeneratedMultiItemAutocomplete.Controlled
    // GeneratedMultiItemAutocomplete.Uncontrolled
    // GeneratedMultiItemAutocomplete.Formik
    // GeneratedMultiItemAutocomplete.Recoil
   MultiIDAutocomplete: GeneratedMultiIDItemAutocomplete,
    // GeneratedMultiIDItemAutocomplete.Controlled
    // GeneratedMultiIDItemAutocomplete.Uncontrolled
    // GeneratedMultiIDItemAutocomplete.Formik
    // GeneratedMultiIDItemAutocomplete.Recoil
  SingleAutocomplete: GeneratedSingleItemAutocomplete,
    // GeneratedSingleItemAutocomplete.Controlled
    // GeneratedSingleItemAutocomplete.Uncontrolled
    // GeneratedSingleItemAutocomplete.Formik
    // GeneratedSingleItemAutocomplete.Recoil
  SingleIDAutocomplete: GeneratedSingleIDItemAutocomplete,
    // GeneratedSingleIDItemAutocomplete.Controlled
    // GeneratedSingleIDItemAutocomplete.Uncontrolled
    // GeneratedSingleIDItemAutocomplete.Formik
    // GeneratedSingleIDItemAutocomplete.Recoil
  } = AutocompleteGenerator.LocalAutocompleteGenerator({
  // The generic parameters are optional and automatically inferred by the values provided, but they can also be overriden manually
  // The `{ ID: number; Name: string }` structure is the default type of the Autocomplete Items - of course, you can override this as needed
    idKey: "ID",
    items: list,
    labelKey: "Name",
    defaultLabel: "Item",
    // The following hooks are optional, but if you want to use the ID-based variants, you must provide them
    getItemByID: useGetExampleItemByID, // needed for SingleIDAutocomplete
    getItemsByIDs: useGetExampleItemsByID, // needed for MultiIDAutocomplete
  });
 * ```
 **/
AutocompleteGenerator.LocalAutocompleteGenerator = <
  Item extends object = { ID: number; Name: string },
  IDKey extends keyof Item = keyof Item,
>({
  useItemByID,
  useItemsByID,
  ...props
}: ILocalAutocompleteGeneratorProps<Item, IDKey>): IAutocompleteGeneratorReturnValues<
  Item,
  IDKey
> => {
  const fallbackGetItemHook = (type: "useItemByID" | "useItemsByID") => () => {
    throw new Error(
      `You must provide the ${type} hook as a prop to the generator if you want to use the ${
        type === "useItemByID" ? "SingleIDAutocomplete" : "MultiIDAutocomplete"
      } component`,
    );
  };

  const SingleAutocomplete = generateSingleLocalAutocomplete(props);
  const WrappedSingleAutocomplete = SingleAutocompleteWrapper(SingleAutocomplete);
  const WrappedSingleIDAutocomplete = SingleAutocompleteIDWrapper(
    SingleAutocomplete,
    props.idKey,
    useItemByID ?? fallbackGetItemHook("useItemByID"),
  );

  const MultiAutocomplete = generateMultiLocalAutocomplete(props);
  const WrappedMultiAutocomplete = MultiAutocompleteWrapper(MultiAutocomplete);
  const WrappedMultiIDAutocomplete = MultiAutocompleteIDWrapper(
    MultiAutocomplete,
    props.idKey,
    useItemsByID ?? fallbackGetItemHook("useItemsByID"),
  );

  return {
    SingleAutocomplete: WrappedSingleAutocomplete,
    SingleIDAutocomplete: WrappedSingleIDAutocomplete,
    MultiAutocomplete: WrappedMultiAutocomplete,
    MultiIDAutocomplete: WrappedMultiIDAutocomplete,
  };
};

export { AutocompleteGenerator };
