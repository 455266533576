import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

export const searchProductsServiceState = builders.buildServiceState({
  service: Core.SearchProducts,
  key: "Core.SearchProducts",
  allowEmptyRequest: false,
  requireEmployee: true,
});

const searchProductsServiceResultSelector = selector({
  key: "Core.SearchProducts:Result",
  get: ({ get }) => get(searchProductsServiceState.response)?.Products,
});

const searchProductsServiceResultOptionsSelector = selector({
  key: "Core.SearchProducts:Result:Options",
  get: ({ get }) => get(searchProductsServiceState.response)?.Options,
});

export const SearchProductsService = {
  serviceState: searchProductsServiceState,
  result: searchProductsServiceResultSelector,
  resultOptions: searchProductsServiceResultOptionsSelector,
};
