import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export type EditorType = "normal" | "diff";

export const EditorSwitch = ({
  editorType,
  initialValue,
  readonly,
  setEditorType,
}: {
  readonly?: boolean;
  initialValue?: string;
  editorType: EditorType;
  setEditorType: (item: EditorType) => void;
}) => {
  const intl = useIntl();
  if (readonly || !initialValue) {
    return null;
  }

  return (
    <Button
      variant="icon"
      tooltip={
        editorType === "normal"
          ? intl.formatMessage({
              id: "generic.label.switch-to-diff-editor",
              defaultMessage: "Switch to diff editor",
            })
          : intl.formatMessage({
              id: "generic.label.switch-to-code-editor",
              defaultMessage: "Switch to code editor",
            })
      }
      onPress={() => {
        if (editorType === "normal") {
          setEditorType("diff");
        } else {
          setEditorType("normal");
        }
      }}
    >
      {editorType === "normal" ? <SvgIcon name="code-editor" /> : <SvgIcon name="diff-editor" />}
    </Button>
  );
};
