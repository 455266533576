import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { ButtonLink } from "components/routing";

import { IActionButtonLink } from "./types";

const EditActionButtonLink = forwardRef<HTMLAnchorElement, IActionButtonLink>(
  (
    {
      className,
      force,
      keepSearchParams,
      onLinkClick,
      state,
      target,
      to,
      tooltip,
    }: IActionButtonLink,
    ref,
  ) => {
    const intl = useIntl();

    return (
      <ButtonLink
        to={to}
        keepSearchParams={keepSearchParams}
        target={target}
        state={state}
        onClick={onLinkClick}
        force={force}
        ref={ref}
        className={className}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.edit", defaultMessage: "Edit" })
        }
        variant="icon"
      >
        <SvgIcon name="edit" />
      </ButtonLink>
    );
  },
);

EditActionButtonLink.displayName = "EditActionButtonLink";

export default EditActionButtonLink;
