import { Dispatch } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@new-black/lyra";

import ErrorBoundary from "components/suite-ui/error-boundary";
import { ChooseTwoFactorAuthenticatorList } from "features/login/choose-two-factor-authenticator-list";

import { LoginAction } from "./login.state";
import { LoginActionData } from "./login.types";

type TwoFactorChooseMethodViewProps = {
  actionData?: LoginActionData;
  dispatchLoginState: Dispatch<LoginAction>;
  twoFactorAuthenticators?: EVA.Core.TwoFactorAuthenticatorData[] | undefined;
};

export function TwoFactorChooseMethodView({
  dispatchLoginState,
  twoFactorAuthenticators,
}: TwoFactorChooseMethodViewProps) {
  return (
    <ErrorBoundary>
      <ChooseTwoFactorAuthenticatorList
        onClick={(authenticator) => {
          dispatchLoginState({
            type: "setAll",
            state: { view: "2faConfirmation", twoFactorMethod: authenticator.Name },
          });
        }}
        twoFactorAuthenticators={twoFactorAuthenticators}
      />
      <Button
        variant="secondary"
        fullWidth
        onPress={() =>
          dispatchLoginState({
            type: "setAll",
            state: { view: "main", twoFactorMethod: undefined },
          })
        }
      >
        <FormattedMessage id="generic.label.back" defaultMessage="Back" />
      </Button>
    </ErrorBoundary>
  );
}
