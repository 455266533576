import { useMemo } from "react";

const useCustomFieldMinMaxField = (dataType?: EVA.Core.CustomFieldDataTypes) => {
  const field = useMemo(() => {
    switch (dataType) {
      case 0:
      case 5:
        return "Length";
      case 2:
      case 3:
        return "Value";
      case 6:
      case 7:
        return "Date";
      case 1:
      case 4:
      default:
        return undefined;
    }
  }, [dataType]);

  const minField = useMemo(() => (field ? (`Minimum${field}` as const) : undefined), [field]);
  const maxField = useMemo(() => (field ? (`Maximum${field}` as const) : undefined), [field]);

  return { field, minField, maxField };
};

export default useCustomFieldMinMaxField;
