import { Core } from "@springtree/eva-services-core";
import { Waldo } from "@springtree/eva-services-waldo";

import { createServiceQuery } from "util/query";

// Legacy - this is the old way of WALDO, no scripting support
// Types
export const {
  serviceLoaderQuery: listLegacyOrderTypesLoaderQuery,
  serviceQuery: listLegacyOrderTypesQuery,
  serviceQueryKeys: listLegacyOrderTypesKeys,
  useServiceQueryHook: useListLegacyOrderTypesQuery,
  useServiceQueryWithRequest: useListLegacyOrderTypesQueryWithRequest,
} = createServiceQuery(Waldo.ListOrderTypes, true);

export const {
  serviceLoaderQuery: getLegacyOrderTypeLoaderQuery,
  serviceQuery: getLegacyOrderTypeQuery,
  serviceQueryKeys: getLegacyOrderTypeKeys,
  useServiceQueryHook: useGetLegacyOrderTypeQuery,
  useServiceQueryWithRequest: useGetLegacyOrderTypeQueryWithRequest,
} = createServiceQuery(Waldo.GetOrderType, true);

// Statuses
export const {
  serviceLoaderQuery: listLegacyOrderStatusesLoaderQuery,
  serviceQuery: listLegacyOrderStatusesQuery,
  serviceQueryKeys: listLegacyOrderStatusesKeys,
  useServiceQueryHook: useListLegacyOrderStatusesQuery,
  useServiceQueryWithRequest: useListLegacyOrderStatusesQueryWithRequest,
} = createServiceQuery(Waldo.ListOrderStatuses, true);

export const {
  serviceLoaderQuery: getLegacyOrderStatusLoaderQuery,
  serviceQuery: getLegacyOrderStatusQuery,
  serviceQueryKeys: getLegacyOrderStatusKeys,
  useServiceQueryHook: useGetLegacyOrderStatusQuery,
  useServiceQueryWithRequest: useGetLegacyOrderStatusQueryWithRequest,
} = createServiceQuery(Waldo.GetOrderStatus, true);

// NEW - WALDO 2.0; supports scripting, replaces legacy models
// Types
export const {
  serviceLoaderQuery: listNewOrderTypesLoaderQuery,
  serviceQuery: listNewOrderTypesQuery,
  serviceQueryKeys: listNewOrderTypesKeys,
  useServiceQueryHook: useListNewOrderTypesQuery,
  useServiceQueryWithRequest: useListNewOrderTypesQueryWithRequest,
} = createServiceQuery(Core.ListOrderCustomTypes, true);

// Statuses
export const {
  serviceLoaderQuery: listNewOrderStatusesLoaderQuery,
  serviceQuery: listNewOrderStatusesQuery,
  serviceQueryKeys: listNewOrderStatusesKeys,
  useServiceQueryHook: useListNewOrderStatusesQuery,
  useServiceQueryWithRequest: useListNewOrderStatusesQueryWithRequest,
} = createServiceQuery(Core.ListOrderCustomStatus, true);
