import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SegmentedControl, SegmentedControlItem } from "@new-black/lyra";

import { LoyaltyProgramStatus } from "./loyalty-program-form";

export const LoyaltyProgramStatusSegmentedControl = ({
  hideInputHeader,
  onChange,
  value,
}: {
  value?: LoyaltyProgramStatus;
  onChange: (newValue?: LoyaltyProgramStatus) => void;
  hideInputHeader?: boolean;
}) => {
  const intl = useIntl();

  const segmentedControlValue = useMemo(
    () => (value === undefined ? "undefined" : value.toString()),
    [value],
  );

  const handleChange = useCallback(
    (value: string | undefined) => {
      if (value === "undefined" || value === undefined) {
        onChange(undefined);
      } else {
        const parsedValue = parseInt(value, 10);

        onChange(isNaN(parsedValue) ? undefined : parsedValue);
      }
    },
    [onChange],
  );

  return (
    <SegmentedControl
      label={intl.formatMessage({ id: "generic.label.status", defaultMessage: "Status" })}
      hideInputHeader={hideInputHeader}
      onChange={handleChange}
      value={segmentedControlValue}
    >
      <SegmentedControlItem
        value={LoyaltyProgramStatus.Active.toString()}
        textValue={intl.formatMessage({ id: "generic.label.enabled", defaultMessage: "Enabled" })}
      >
        <FormattedMessage id="generic.label.enabled" defaultMessage="Enabled" />
      </SegmentedControlItem>

      <SegmentedControlItem
        value={LoyaltyProgramStatus.Inactive.toString()}
        textValue={intl.formatMessage({
          id: "generic.label.disabled",
          defaultMessage: "Disabled",
        })}
      >
        <FormattedMessage id="generic.label.disabled" defaultMessage="Disabled" />
      </SegmentedControlItem>

      <SegmentedControlItem
        value={"undefined"}
        textValue={intl.formatMessage({ id: "generic.label.all", defaultMessage: "All" })}
      >
        <FormattedMessage id="generic.label.all" defaultMessage="All" />
      </SegmentedControlItem>
    </SegmentedControl>
  );
};
