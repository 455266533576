import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Badge } from "@new-black/lyra";

import { EOrderStatus, getBadgeColor, getOrderStatus, IOrderItem } from "./order-status-label.util";

export interface IOrderStatusLabelProps {
  order: IOrderItem | undefined;
}

export function OrderStatusLabel({ order }: IOrderStatusLabelProps) {
  const intl = useIntl();
  const status = useMemo(() => getOrderStatus(order), [order]);

  const labelMapping = useMemo<Record<EOrderStatus, string>>(
    () => ({
      [EOrderStatus.Open]: intl.formatMessage({
        id: "generic.label.open",
        defaultMessage: "Open",
      }),
      [EOrderStatus.Completed]: intl.formatMessage({
        id: "generic.label.completed",
        defaultMessage: "Completed",
      }),
      [EOrderStatus.Paid]: intl.formatMessage({
        id: "generic.label.paid",
        defaultMessage: "Paid",
      }),
      [EOrderStatus.Shipped]: intl.formatMessage({
        id: "generic.label.shipped",
        defaultMessage: "Shipped",
      }),
    }),
    [intl],
  );

  const color = useMemo(() => getBadgeColor(status), [status]);
  const label = useMemo(
    () => (status && status in labelMapping ? labelMapping[status as EOrderStatus] : undefined),
    [labelMapping, status],
  );

  if (!status || !color || !label) {
    return null;
  }

  return <Badge variant={color}>{label}</Badge>;
}
