import { useField } from "formik";
import { RecoilState, useRecoilState } from "recoil";

import { PhoneNumberSelectorBase, TPhoneNumberSelectorBase } from "./phone-number-selector-base";

/** TODO: add docs */
export const PhoneNumberSelector = {
  Uncontrolled: (
    props: Omit<TPhoneNumberSelectorBase, "value" | "onChange"> & { name: string },
  ) => <PhoneNumberSelectorBase {...props} />,

  Controlled: (
    props: Omit<TPhoneNumberSelectorBase, "name" | "defaultValue"> & {
      onChange: (newValue?: string) => void;
    },
  ) => <PhoneNumberSelectorBase {...props} />,

  Recoil: ({
    recoilState,
    ...props
  }: Omit<TPhoneNumberSelectorBase, "name" | "defaultValue" | "value" | "onChange"> & {
    recoilState: RecoilState<string | undefined>;
  }) => {
    const [value, setValue] = useRecoilState(recoilState);

    return <PhoneNumberSelectorBase value={value} onChange={setValue} {...props} />;
  },

  Formik: (
    props: Omit<TPhoneNumberSelectorBase, "value" | "onChange" | "defaultValue"> & { name: string },
  ) => {
    const [field, meta, form] = useField<string | undefined>(props.name ?? "EmailAddress");

    return (
      <PhoneNumberSelectorBase
        {...field}
        {...props}
        name={undefined}
        value={field.value}
        onChange={(newValue) => form.setValue(newValue)}
        onBlur={field.onBlur}
        error={props.error || (!!meta.error && meta.touched)}
        helperText={meta.error && meta.touched ? meta.error : props.helperText}
      />
    );
  },
};
