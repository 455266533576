import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

const { createGetterSetterSelectorFamily } = helpers.createSelectors;

const defaultListOrganizationUnitsSummariesRequest = {
  PageConfig: {
    Start: 0,
    Limit: 10,
    Filter: { Name: undefined },
  },
};

export const organizationUnitSummariesListServiceState = builders.buildServiceFamilyState({
  key: "Composite:Core.ListOrganizationUnitSummaries",
  service: Core.ListOrganizationUnitSummaries,
  allowEmptyRequest: false,
  requireEmployee: true,
});

const organizationUnitSummariesResponseErrorSelector = selectorFamily({
  key: "Composite:Core.ListOrganizationUnitSummaries:response:Error",
  get:
    (param) =>
    ({ get }) =>
      get(organizationUnitSummariesListServiceState.response(param))?.Error,
});

const organizationUnitSummariesPageConfigSelector = createGetterSetterSelectorFamily<
  EVA.Core.ListOrganizationUnitSummaries,
  EVA.Core.PageConfig<EVA.Core.ListOrganizationUnitsFilter> | undefined
>({
  getData: (data) => data?.PageConfig ?? defaultListOrganizationUnitsSummariesRequest.PageConfig,
  key: "Composite:Core.ListOrganizationUnitSummaries:request:page-config",
  defaultValue: defaultListOrganizationUnitsSummariesRequest.PageConfig,
  state: organizationUnitSummariesListServiceState.request,
  setData: (request, newValue) => {
    if (newValue !== undefined) {
      return { ...request, PageConfig: newValue };
    }
    return request;
  },
});

const organizationUnitSummariesPageConfigFilterSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListOrganizationUnitsFilter>,
  Partial<EVA.Core.ListOrganizationUnitsFilter> | undefined
>({
  defaultValue: defaultListOrganizationUnitsSummariesRequest.PageConfig.Filter,
  key: "Composite:Core.ListOrganizationUnitSummaries:request:page-config",
  getData: (data) => data?.Filter ?? defaultListOrganizationUnitsSummariesRequest.PageConfig.Filter,
  state: organizationUnitSummariesPageConfigSelector,
  setData: (request, newValue) => {
    if (newValue !== undefined) {
      return { ...request, Start: 0, Filter: newValue };
    }
    return request;
  },
});

export const organizationUnitSummariesTotalSelector = selectorFamily({
  key: "Composite:Core.ListOrganizationUnitSummaries:total",
  get:
    (param) =>
    ({ get }) =>
      get(organizationUnitSummariesListServiceState.response(param))?.Result.Total,
});

export const organizationUnitSummariesResultSelector = selectorFamily({
  key: "Composite:Core.ListOrganizationUnitSummaries:result",
  get:
    (param) =>
    ({ get }) =>
      get(organizationUnitSummariesListServiceState.response(param))?.Result?.Page,
});

export const organizationUnitSummariesListStartSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListOrganizationUnitsFilter>,
  number
>({
  key: "Composite:Core.ListOrganizationUnitSummaries:request:start",
  defaultValue: defaultListOrganizationUnitsSummariesRequest.PageConfig.Start,
  state: organizationUnitSummariesPageConfigSelector,
  getData: (data) => data?.Start ?? defaultListOrganizationUnitsSummariesRequest.PageConfig.Start,
  setData: (request, newValue) => {
    if (newValue !== request?.Start) {
      return { ...request, Start: newValue };
    }
    return request;
  },
});

export const organizationUnitSummariesListLimitSelector = createGetterSetterSelectorFamily<
  EVA.Core.PageConfig<EVA.Core.ListOrganizationUnitsFilter>,
  number
>({
  defaultValue: defaultListOrganizationUnitsSummariesRequest.PageConfig.Limit,
  key: "Composite:Core.ListOrganizationUnitSummaries:request:limit",
  state: organizationUnitSummariesPageConfigSelector,
  getData: (data) => data?.Limit ?? defaultListOrganizationUnitsSummariesRequest.PageConfig.Limit,
  setData: (request, newValue) => {
    if (newValue !== request?.Limit) {
      return { ...request, Limit: newValue };
    }
    return request;
  },
});

export const organizationUnitSummariesFilterNameSelector = createGetterSetterSelectorFamily<
  Partial<EVA.Core.ListOrganizationUnitsFilter>,
  string | undefined
>({
  key: "Composite:Core.ListOrganizationUnitSummaries:request:filter:name",
  defaultValue: defaultListOrganizationUnitsSummariesRequest.PageConfig.Filter.Name,
  state: organizationUnitSummariesPageConfigFilterSelector,
  getData: (data) => data?.Name,
  setData: (request, newValue) => {
    if (newValue !== request?.Name) {
      return { ...request, Name: newValue };
    }
    return request;
  },
});

export const OrganizationUnitSummariesService = {
  defaultRequest: defaultListOrganizationUnitsSummariesRequest,
  filter: organizationUnitSummariesPageConfigFilterSelector,
  nameFilter: organizationUnitSummariesFilterNameSelector,
  pageConfig: organizationUnitSummariesPageConfigSelector,
  serviceState: organizationUnitSummariesListServiceState,
  error: organizationUnitSummariesResponseErrorSelector,
  limit: organizationUnitSummariesListLimitSelector,
  start: organizationUnitSummariesListStartSelector,
  result: organizationUnitSummariesResultSelector,
  total: organizationUnitSummariesTotalSelector,
};
