import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listCompaniesLoaderQuery,
  serviceQuery: listCompaniesQuery,
  serviceQueryKeys: listCompaniesQueryKeys,
  useServiceQueryHook: useListCompaniesQuery,
} = createServiceQuery(CoreManagement.ListCompanies);

export const {
  serviceLoaderQuery: getCompanyLoaderQuery,
  serviceQuery: getCompanyQuery,
  serviceQueryKeys: getCompanyQueryKeys,
  useServiceQueryHook: useGetCompanyQuery,
} = createServiceQuery(CoreManagement.GetCompany, true);
