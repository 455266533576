import { useIntl } from "react-intl";

import {
  ButtonGroup,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  KeyValueRow,
} from "@new-black/lyra";

import DeleteActionButton from "components/shared/action-buttons/delete-action-button";
import EditActionButton from "components/shared/action-buttons/edit-action-button";

import { TLoyaltyProgramOrderAmountForm } from "./add-edit-loyalty-program-rule-order-amonut-condition.types";

export const LoyaltyProgramRuleOrderAmountConditionCard = ({
  conditionID,
  data,
  onDelete,
  onEdit,
}: {
  conditionID: number;
  data?: TLoyaltyProgramOrderAmountForm;
  onEdit?: (conditionID: number) => void;
  onDelete?: (conditionID: number) => void;
}) => {
  const intl = useIntl();

  return (
    <Card variant="secondary">
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.order-amount",
          defaultMessage: "Order amount",
        })}
        actions={
          onEdit || onDelete ? (
            <CardActions>
              <ButtonGroup>
                {onDelete ? <DeleteActionButton onPress={() => onDelete(conditionID)} /> : null}
                {onEdit ? <EditActionButton onPress={() => onEdit(conditionID)} /> : null}
              </ButtonGroup>
            </CardActions>
          ) : null
        }
      />

      <CardContent className="flex flex-col gap-2">
        <KeyValueRow
          label={intl.formatMessage({
            id: "generic.label.when-the-order-amount-is-greater-than",
            defaultMessage: "When the order amount is greater than",
          })}
        >
          {data?.Amount}
        </KeyValueRow>
      </CardContent>
    </Card>
  );
};
