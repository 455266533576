enum ProductStatus {
  None = 0,
  PreRelease = 1,
  DeliveryOnly = 4,
  DisableDelivery = 8,
  DisablePickup = 16,
  DisableBackorder = 32,
  UseUp = 34,
  NonSellable = 64,
}

export default ProductStatus;
