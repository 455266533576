import { forwardRef, useCallback, useMemo } from "react";
import { Link as RouterLink, LinkProps, useSearchParams } from "react-router-dom";

import { buttonStyles, CustomTooltipTriggerWrapper, Tooltip } from "@new-black/lyra";
import { VariantProps } from "tailwind-variants";

import { usePrompt } from "./provider";

type Props = LinkProps &
  VariantProps<typeof buttonStyles> & {
    keepSearchParams?: boolean;
    force?: boolean;
    tooltip?: string;
    isDisabled?: boolean;
  };

export const ButtonLink = forwardRef<HTMLAnchorElement, Props>((props, ref) => {
  const {
    children,
    className,
    force,
    fullWidth,
    isDisabled,
    isLoading,
    keepSearchParams,
    onClick,
    replace = false,
    state,
    target,
    to,
    tooltip,
    variant,
    ...linkProps
  } = props;
  const { setConfirmRoute, when } = usePrompt();
  const [searchParams] = useSearchParams();

  const newRoute = useMemo(() => {
    let route = to;
    if (keepSearchParams) {
      route = route + "?" + searchParams.toString();
    }
    return route;
  }, [keepSearchParams, searchParams, to]);

  const renderLink = useCallback(() => {
    return (
      <RouterLink
        {...linkProps}
        ref={ref}
        to={newRoute}
        replace={replace}
        state={state}
        target={target}
        onClick={(e) => {
          if (isDisabled) {
            e.preventDefault();
            return;
          }

          if (when && !force) {
            e?.preventDefault();
            setConfirmRoute(newRoute);
          } else {
            onClick?.(e);
          }
        }}
        aria-disabled={isDisabled}
        className={buttonStyles({ variant, fullWidth, className, isDisabled, isLoading })}
      >
        {children}
      </RouterLink>
    );
  }, [props]);

  if (tooltip) {
    return (
      <Tooltip tooltip={tooltip}>
        <CustomTooltipTriggerWrapper>{renderLink()}</CustomTooltipTriggerWrapper>
      </Tooltip>
    );
  }

  return renderLink();
});

ButtonLink.displayName = "ButtonLink";
