import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listGeneralLedgersLoaderQuery,
  serviceQueryKeys: listGeneralLedgersLoaderQueryKeys,
  useServiceQueryHook: useListGeneralLedgersQuery,
  useServiceQueryWithRequest: useListGeneralLedgersQueryWithRequest,
} = createServiceQuery(Core.ListGeneralLedgers);
