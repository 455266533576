import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { QueryClient } from "@tanstack/react-query";

import EN from "assets/i18n/en.json";
import { LOCALE_LANGUAGES_FALLBACKS, SUPPORTED_EXTRA_LANGUAGES } from "util/base-values";
import { createEVAService } from "util/http";
import { createServiceQuery } from "util/query";

// translations
// fetch translation files in i18n folder based on a locale
const getTranslationMessages = async (
  locale: string,
): Promise<{ [key in TranslationKey]: string }> => {
  let newTranslations = { ...EN };

  if (SUPPORTED_EXTRA_LANGUAGES.includes(locale)) {
    const extraLanguage = await fetchTranslationFile(locale);
    newTranslations = { ...newTranslations, ...extraLanguage };
    return newTranslations;
  }
  const language = locale.split("-")[0];
  if (SUPPORTED_EXTRA_LANGUAGES.includes(language)) {
    const extraLanguage = await fetchTranslationFile(language);
    newTranslations = { ...newTranslations, ...extraLanguage };
    return newTranslations;
  }

  // if the language is a key of LOCALE_LANGUAGES_FALLBACKS, we need to fetch the translation file for the value of that key
  const fallbackLanguage = LOCALE_LANGUAGES_FALLBACKS?.[language];
  if (fallbackLanguage) {
    const extraLanguage = await fetchTranslationFile(fallbackLanguage);
    newTranslations = { ...newTranslations, ...extraLanguage };
  }

  return newTranslations;
};

const fetchTranslationFile = async (name: string) => {
  try {
    const response = await fetch(`/i18n/${name}.json`);
    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    console.error("Language file not found", error);
  }
  return {};
};

export const translationLoaderQuery = async (queryClient: QueryClient, locale: string) => {
  const valueInCache: { [key in TranslationKey]: string } | undefined = queryClient.getQueryData([
    "translation",
    locale,
  ]);

  return (
    valueInCache ??
    (await queryClient.fetchQuery({
      queryFn: () => getTranslationMessages(locale),
      queryKey: ["translation", locale],
      cacheTime: Infinity,
      staleTime: Infinity,
    }))
  );
};

// GetApplicationConfiguration
export const getApplicationConfigurationService = createEVAService(
  Core.GetApplicationConfiguration,
);

export const {
  serviceLoaderQuery: getApplicationConfigurationLoaderQuery,
  serviceQuery: getApplicationConfigurationQuery,
  serviceQueryKeys: getApplicationConfigurationKeys,
  serviceQueryWithOU: getApplicationConfigurationWithOUQuery,
  useServiceQueryHook: useGetApplicationConfigurationQuery,
} = createServiceQuery(Core.GetApplicationConfiguration);

export const {
  serviceLoaderQuery: listTimeZonesLoaderQuery,
  serviceQuery: listTimeZonesQuery,
  serviceQueryKeys: listTimeZonesQueryKeys,
  useServiceQueryHook: useListTimeZonesQuery,
} = createServiceQuery(Core.ListAvailableTimeZones);

export const {
  serviceLoaderQuery: listSettingsLoaderQuery,
  serviceQuery: listSettingsQuery,
  serviceQueryKeys: listSettingsQueryKeys,
  useServiceQueryHook: useListSettingsQuery,
} = createServiceQuery(CoreManagement.ListSettings);

export const {
  serviceLoaderQuery: listCountriesLoaderQuery,
  serviceQuery: listCountriesQuery,
  serviceQueryKeys: listCountriesQueryKeys,
  useServiceQueryHook: useListCountriesQuery,
} = createServiceQuery(Core.ListCountries);

export const {
  serviceLoaderQuery: getLanguagesLoaderQuery,
  serviceQuery: getLanguagesQuery,
  serviceQueryKeys: getLanguagesQueryKeys,
  useServiceQueryHook: useGetLanguagesQuery,
} = createServiceQuery(CoreManagement.GetLanguages);

export const { serviceQuery: getCurrentApplicationQuery } = createServiceQuery(
  Core.GetCurrentApplication,
);

export const {
  serviceLoaderQuery: getAvailableCurrenciesLoaderQuery,
  serviceQuery: getAvailableCurrenciesQuery,
  serviceQueryKeys: getAvailableCurrenciesQueryKeys,
  useServiceQueryHook: useGetAvailableCurrenciesQuery,
} = createServiceQuery(Core.GetAvailableCurrencies);
