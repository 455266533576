import { builders } from "@springtree/eva-sdk-react-recoil";
import { Symphony } from "@springtree/eva-services-symphony";

export const parseSheetState = builders.buildServiceFamilyState({
  service: Symphony.ParseSheet,
  key: "parseSheet",
  requireEmployee: true,
  defaultRequest: undefined,
  allowEmptyRequest: false,
});
