import { z } from "zod";
import { zfd } from "zod-form-data";

export const loyaltyProgramOrderAmountFormSchema = z.object({
  Amount: zfd.numeric(),
});

export type TLoyaltyProgramOrderAmountForm = z.infer<typeof loyaltyProgramOrderAmountFormSchema>;

export type TLoyaltyProgramOrderAmountFormErrors = Partial<
  Record<keyof TLoyaltyProgramOrderAmountForm, string>
>;

export const loyaltyProgramOrderAmountKeys = loyaltyProgramOrderAmountFormSchema.keyof().Values;
