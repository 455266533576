/* eslint-disable @typescript-eslint/no-unused-vars */
import { cloneElement, isValidElement, useEffect, useMemo, useRef, useState } from "react";

import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import SvgIcon from "@material-ui/core/SvgIcon";
import type { TextFieldProps as MuiTextFieldProps } from "@material-ui/core/TextField";
import classNames from "classnames";

import { TextField } from "./styled-text-field";

export type OmittedMuiTextFieldProps = Omit<
  MuiTextFieldProps,
  "variant" | "margin" | "size" | "color"
>;

export interface InputProps extends OmittedMuiTextFieldProps {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  passive?: boolean;
  small?: boolean;
  warning?: boolean;
}

const Input = ({
  defaultValue,
  endIcon,
  fullWidth,
  InputProps,
  onBlur,
  onFocus,
  onWheelCapture,
  passive,
  small = false,
  startIcon,
  type,
  value,
  ...props
}: InputProps) => {
  const { error, warning } = props;
  const startAdornmentRef = useRef<HTMLDivElement>(null);
  const [startAdornmentWidth, setStartAdornmentWidth] = useState(0);
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (startAdornmentRef.current) {
      setStartAdornmentWidth(startAdornmentRef.current.offsetWidth);
    }
  }, [startAdornmentRef]);

  const iconColor = useMemo(() => {
    if (error) {
      return "error";
    }
    if (focused) {
      return "primary";
    }
    return "secondary";
  }, [error, focused]);

  const computedValue = useMemo(() => (passive && !value ? "-" : value), [passive, value]);

  return (
    <TextField
      fullWidth={fullWidth ?? true}
      // @ts-ignore
      margin={small ? "dense" : undefined}
      small={small}
      onWheelCapture={(e: any) => {
        if (onWheelCapture) {
          onWheelCapture(e);
        }
        if (type === "number") {
          (e.target as HTMLInputElement).blur();
        }
      }}
      type={type}
      value={computedValue}
      passive={passive}
      defaultValue={defaultValue}
      warning={warning}
      {...props}
      startAdornmentWidth={startAdornmentWidth}
      // TODO: fix shrink not working as expected with the code below in custom dropdowns
      // Because the value is ommited in a lot of instnaces, therefore the labe is not shrunk
      // InputLabelProps={{
      //   shrink: !!computedValue || focused,
      // }}
      className={classNames(
        "[&_.MuiInputLabel-shrink]:max-w-[calc(133.33%)] [&_label]:max-w-full [&_label]:truncate [&_label]:!transition-all",
        props.className,
      )}
      InputProps={{
        readOnly: passive,
        disableUnderline: passive,
        onFocus: (event: any) => {
          onFocus?.(event);
          setFocused(true);
        },
        onBlur: (event: any) => {
          onBlur?.(event);
          setFocused(false);
        },
        startAdornment: startIcon ? (
          <InputAdornment position="start" ref={startAdornmentRef}>
            {startIcon &&
            isValidElement(startIcon) &&
            (startIcon.type === Icon || startIcon.type === SvgIcon)
              ? cloneElement(startIcon, {
                  // @ts-ignore
                  color: iconColor,
                })
              : startIcon}
          </InputAdornment>
        ) : undefined,
        endAdornment: endIcon ? (
          <InputAdornment position="end">
            {endIcon &&
            isValidElement(endIcon) &&
            (endIcon.type === Icon || endIcon.type === SvgIcon)
              ? cloneElement(endIcon, {
                  // @ts-ignore
                  color: iconColor,
                })
              : endIcon}
          </InputAdornment>
        ) : undefined,
        ...InputProps,
      }}
    />
  );
};

export default Input;
