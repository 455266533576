import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { LoyaltyPaymentTaxHandlingType } from "types/eva-core";
import { getLoyaltyPaymentTaxHandlingTranslator } from "types/translations-enums/loyalty-payment-tax-handling";

export const LoyaltyPaymentTaxHandlingSelect = ({
  errorMessage,
  initialValue,
  name,
}: {
  name: string;
  initialValue?: number;
  errorMessage?: string;
}) => {
  const intl = useIntl();

  const [value, setValue] = useState(initialValue);

  const translateLoyaltyPaymentTaxHandling = useMemo(
    () => getLoyaltyPaymentTaxHandlingTranslator(intl),
    [intl],
  );

  const valuesArray = useMemo(
    () => [
      {
        Value: LoyaltyPaymentTaxHandlingType.InTax,
        Name: translateLoyaltyPaymentTaxHandling(LoyaltyPaymentTaxHandlingType.InTax) as string,
      },
      {
        Value: LoyaltyPaymentTaxHandlingType.UpToTax,
        Name: translateLoyaltyPaymentTaxHandling(LoyaltyPaymentTaxHandlingType.UpToTax) as string,
      },
    ],
    [translateLoyaltyPaymentTaxHandling],
  );

  return (
    <>
      <Select
        items={valuesArray ?? []}
        getItemId={(item) => item.Value}
        getLabel={(item) => item.Name}
        selectRenderElements={(item) => ({ label: item.Name })}
        value={valuesArray?.find((item) => item.Value === value)}
        onChange={(newValue) => setValue(newValue?.Value)}
        errorMessage={errorMessage}
        label={intl.formatMessage({
          id: "generic.label.tax-handling",
          defaultMessage: "Tax handling",
        })}
      />

      {value !== undefined ? <input hidden readOnly name={name} value={value} /> : null}
    </>
  );
};
