import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const MultiLineIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Multiline</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
    </defs>
    <g id="Icon-/-Multiline" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g filter="url(#filter-1)" id="notes_black_24dp">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
          <path
            d="M21,11.01 L3,11 L3,13 L21,13 L21,11.01 Z M3,16 L15,16 L15,18 L3,18 L3,16 Z M21,6 L3,6 L3,8.01 L21,8 L21,6 Z"
            id="Shape"
            fill="#000000"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </SvgIcon>
);
