/* eslint-disable max-len */
export const TasksInnerIcon = () => (
  <>
    <rect width="56" height="56" rx="14" fill="#34C759" />
    <path
      d="M34.4962 22.724C33.9022 22.229 33.0195 22.3092 32.5245 22.9032L26.6 30.0125L24.1754 27.1032C23.6805 26.5092 22.7977 26.429 22.2037 26.924C21.6098 27.4189 21.5294 28.3017 22.0245 28.8958L25.3094 32.8376C25.981 33.6436 27.2189 33.6436 27.8906 32.8376L34.6754 24.6958C35.1705 24.1017 35.0903 23.2189 34.4962 22.724Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6001 17.2196C12.6001 14.6681 14.6685 12.5996 17.2201 12.5996H38.7801C41.3317 12.5996 43.4001 14.6681 43.4001 17.2196V38.7796C43.4001 41.3312 41.3317 43.3996 38.7801 43.3996H17.2201C14.6685 43.3996 12.6001 41.3312 12.6001 38.7796V17.2196ZM17.2201 15.6796H38.7801C39.6306 15.6796 40.3201 16.3691 40.3201 17.2196V38.7796C40.3201 39.6301 39.6306 40.3196 38.7801 40.3196H17.2201C16.3696 40.3196 15.6801 39.6301 15.6801 38.7796V17.2196C15.6801 16.3691 16.3696 15.6796 17.2201 15.6796Z"
      fill="white"
    />
  </>
);
