import { z } from "zod";

export const loginCredentialsFormSchema = z.object({
  Username: z.string(),
  Password: z.string(),
});

export const loginCredentialsFormSchemaKeys = loginCredentialsFormSchema.keyof().Values;

export const loginCredentialsFormSchemaErrors = z
  .record(loginCredentialsFormSchema.keyof(), z.string().optional())
  .optional();

export type LoginCredentialsFormSchemaErrors = z.infer<typeof loginCredentialsFormSchemaErrors>;

export type LoginCredentialsFormOrServiceErrors = {
  formErrors?: LoginCredentialsFormSchemaErrors;
  serviceError?: string;
};

export type LoginCredentialsFormProps = {
  errors?: LoginCredentialsFormOrServiceErrors;
};
