import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const CheckboxIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Checkbox</title>
    <defs>
      <path
        // eslint-disable-next-line max-len
        d="M19,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.89,21 5,21 L19,21 C20.11,21 21,20.1 21,19 L21,5 C21,3.9 20.11,3 19,3 Z M10,17 L5,12 L6.41,10.59 L10,14.17 L17.59,6.58 L19,8 L10,17 Z"
        id="path-1"
      />
    </defs>
    <g id="Icon-/-Checkbox" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Shape" x="0" y="0" width="24" height="24" />
      <mask id="mask-2" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <use
        id="ic_check_box_24px"
        fillOpacity="0.6"
        fill="#000000"
        fillRule="nonzero"
        xlinkHref="#path-1"
      />
    </g>
  </SvgIcon>
);
