import { useGenerateCustomFieldLyraSearchListField } from "components/suite-composite/generate-custom-field-lyra-search-list-field";
import { CustomFieldType } from "types/custom-field";

import { ILoyaltyProgramCustomFieldInput } from "./types";

export const LoyaltyProgramCustomFieldWithOUIDInput = ({
  errorMessage,
  name,
  onChange,
  organizationUnitID,
  value,
}: ILoyaltyProgramCustomFieldInput & { organizationUnitID: number }) => {
  const { CustomFieldSearchListField } = useGenerateCustomFieldLyraSearchListField(
    CustomFieldType.User,
    organizationUnitID,
  );

  return (
    <>
      <CustomFieldSearchListField.Controlled
        isRequired
        value={value}
        onChange={onChange}
        errorMessage={errorMessage}
      />
      {name && value ? <input type="hidden" name={name} value={value?.CustomFieldID} /> : null}
    </>
  );
};
