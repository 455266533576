import { ChangeEvent, FocusEvent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NumericFormatProps } from "react-number-format";

import { InputBaseComponentProps } from "@material-ui/core";
import { isNil } from "lodash";

import Input from "../input";
import Text from "../text";

import { NumberFormatCustomComponent, NumberInputComponentWrapper } from "./number-input-helpers";

export interface INumberInput extends NumericFormatProps<InputBaseComponentProps> {
  name?: string;
  fullWidth?: boolean;
  helperText?: string;
  precision?: number;
  inputRef?: any;
  small?: boolean;
  placeholder?: string;
  percentage?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  className?: {
    inputClassName?: string;
    inputPropsClassName?: string;
  };
}

const NumberInput = (props: INumberInput) => {
  const intl = useIntl();
  const {
    className,
    decimalSeparator,
    defaultValue,
    disabled,
    endIcon,
    error,
    fullWidth,
    helperText,
    inputRef,
    label,
    name,
    onChange,
    passive,
    percentage,
    placeholder,
    precision,
    required,
    small,
    thousandSeparator,
    value,
    ...other
  } = props;

  const [localThousandSeparator, setLocalThousandSeparator] = useState<
    string | boolean | undefined
  >(thousandSeparator);
  const [localDecimalSeparator, setLocalDecimalSeparator] = useState<string | undefined>(
    decimalSeparator,
  );

  useEffect(() => {
    if (isNil(thousandSeparator)) {
      const numberParts = intl.formatNumberToParts(1000.0);
      const thousandGroup = numberParts.find((part) => part.type === "group");
      setLocalThousandSeparator(thousandGroup?.value);
    }
  }, [intl, setLocalThousandSeparator, thousandSeparator]);

  useEffect(() => {
    if (!decimalSeparator) {
      const numberParts = intl.formatNumberToParts(1000.95, undefined);
      const decimalsGroup = numberParts.find((part) => part.type === "decimal");
      setLocalDecimalSeparator(decimalsGroup?.value);
    }
  }, [decimalSeparator, intl]);

  return (
    <Input
      name={name}
      label={label}
      defaultValue={defaultValue}
      value={value}
      className={className?.inputClassName}
      small={small}
      disabled={disabled}
      autoComplete="off"
      fullWidth={fullWidth}
      required={required}
      error={error}
      helperText={helperText}
      placeholder={placeholder}
      passive={passive}
      endIcon={
        <span className="flex items-center gap-2">
          {endIcon}
          {percentage ? <Text>%</Text> : null}
        </span>
      }
      InputProps={{ inputComponent: NumberInputComponentWrapper as any }}
      inputProps={{
        component: NumberFormatCustomComponent as any,
        onChange,
        decimalScale: precision,
        fixedDecimalScale: true,
        thousandSeparator: localThousandSeparator,
        decimalSeparator: localDecimalSeparator,
        className: className?.inputPropsClassName,
        ...other,
      }}
      inputRef={inputRef}
    />
  );
};

NumberInput.defaultProps = {
  fullWidth: true,
  percentage: false,
};

export default NumberInput;
