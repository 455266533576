import { Dispatch } from "react";

import { parseZodFormErrors } from "util/validators/parse-zod-form-errors";

import { ProductFilterAction, ProductFilterState } from "./product-filter-modal.reducer";
import {
  EVAFilterModel,
  FilterModel,
  rangeSchema,
  valuesSchema,
} from "./product-filter-modal.types";

export const parseEVAFilterModel = (input: EVAFilterModel): FilterModel =>
  input.Values
    ? {
        type: "values",
        Values: input.Values,
        Negation: input.Negation,
        ExactMatch: input.ExactMatch,
        PropertyName: input.PropertyName,
        IncludeMissing: input.IncludeMissing,
      }
    : {
        type: "range",
        From: input.From,
        To: input.To,
        Negation: input.Negation,
        ExactMatch: input.ExactMatch,
        PropertyName: input.PropertyName,
        IncludeMissing: input.IncludeMissing,
      };

export const filterModelToEVAFilterModel = (input: FilterModel): EVAFilterModel => ({
  Negation: input.Negation,
  ExactMatch: input.ExactMatch,
  PropertyName: input.PropertyName,
  IncludeMissing: input.IncludeMissing,
  ...(input.type === "values" ? { Values: input.Values } : { From: input.From, To: input.To }),
});

export async function validateFilterModalState(
  state: ProductFilterState,
  dispatch: Dispatch<ProductFilterAction>,
  callback: (validState: ProductFilterState) => void,
) {
  const validationVerdict = await (state.type === "values"
    ? valuesSchema
    : rangeSchema
  ).safeParseAsync(state);

  if (!validationVerdict.success) {
    dispatch({ type: "SET_ERRORS", payload: parseZodFormErrors(validationVerdict.error) });
    return;
  }

  callback(state);
  return;
}
