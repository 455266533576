import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const CheckboxOffIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Checkbox / Off</title>
    <g id="Checkbox-/-Off" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect id="Shape" x="0" y="0" width="24" height="24" />
      <path
        // eslint-disable-next-line max-len
        d="M19,5 L19,19 L5,19 L5,5 L19,5 L19,5 Z M19,3 L5,3 C3.9,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,5 C21,3.9 20.1,3 19,3 Z"
        id="ic_check_box_outline_blank_24px"
        fillOpacity="0.6"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);

export default CheckboxOffIcon;
