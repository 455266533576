import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Spinner, SvgIcon, TextField, TextFieldProps } from "@new-black/lyra";
import { Core } from "@springtree/eva-services-core";
import { useMutation } from "@tanstack/react-query";
import { isNil } from "lodash";

import { toast } from "components/suite-ui/toast";
import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { intlAccessor } from "util/intl-accessor";
import { mutate } from "util/mutate";

export const mutateCheckNicknameAvailability = mutate({
  service: Core.CheckNicknameAvailability,
  disabledNotifications: true,
  onError: async () => {
    toast.error(
      intlAccessor.formatMessage({
        id: "generic.label.nickname-availability.error.message",
        defaultMessage:
          "The availability check for nickname wasn’t completed successfully. Please make sure the value is unique and try again later.",
      }),
    );
  },
});

type NicknameAvailabilityInputProps = TextFieldProps & {
  /* The name of the field that will be used to check the availability of the nickname. */
  nickNameAvailableName?: string;
  /* A callback that will be called when the availability of the nickname changes, this is optional */
  updateNickNameIsAvailable?: (value?: boolean) => void;
  /** Value to be excluded from validation. */
  validationException?: string;
  /** When checking for Employee accounts, the `AsEmployee` property should be set to true. */
  asEmployee?: boolean;
};

export const NicknameAvailabilityTextField = ({
  asEmployee,
  nickNameAvailableName,
  updateNickNameIsAvailable,
  validationException,
  ...props
}: NicknameAvailabilityInputProps) => {
  const intl = useIntl();
  const { data, isLoading, mutate } = useMutation(mutateCheckNicknameAvailability);
  const [changedInputValue, setChangedInputValue] = useState<string | undefined>(
    (props.value ?? props.defaultValue) as string | undefined,
  );
  const debouncedChangedInputValue = useDebounce(changedInputValue, 500);

  const [isAvailable, setIsAvailable] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    setIsAvailable(data?.response?.IsAvailable);
  }, [data?.response?.IsAvailable]);

  useEffect(() => {
    if (debouncedChangedInputValue && debouncedChangedInputValue.length) {
      mutate({ Nickname: debouncedChangedInputValue, AsEmployee: asEmployee });
    }
  }, [asEmployee, debouncedChangedInputValue, mutate]);

  const isValidationException = useMemo(
    () => changedInputValue === validationException,
    [changedInputValue, validationException],
  );

  useEffect(() => {
    if (updateNickNameIsAvailable) {
      if (isValidationException) {
        updateNickNameIsAvailable(true);
      } else {
        updateNickNameIsAvailable(isAvailable);
      }
    }
  }, [isValidationException, isAvailable, updateNickNameIsAvailable]);

  const endIcon = useMemo(() => {
    if (isLoading) {
      return <Spinner size="small" />;
    } else if (isAvailable && !isValidationException) {
      return <SvgIcon name="alert-success" className="text-success" />;
    } else if (isAvailable === false && !isValidationException) {
      return <SvgIcon name="alert-error" className="text-error" />;
    }
    return undefined;
  }, [isValidationException, isAvailable, isLoading]);

  return (
    <>
      <TextField
        {...props}
        label={
          props.label ??
          intl.formatMessage({ id: "generic.label.nickname", defaultMessage: "Nickname" })
        }
        endSlot={endIcon}
        onChange={(newValue) => {
          setChangedInputValue(newValue);
          setIsAvailable(undefined);
          props.onChange?.(newValue);
        }}
        errorMessage={
          !isNil(isAvailable) && isAvailable === false && !isValidationException
            ? intl.formatMessage({
                id: "users.nickname.already-in-use-error",
                defaultMessage: "The nickname is already in use.",
              })
            : props.errorMessage
        }
      />
      {nickNameAvailableName && (isAvailable || isAvailable === undefined) ? (
        <input type="hidden" name={nickNameAvailableName} value="true" />
      ) : null}
    </>
  );
};
