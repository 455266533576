import type { SvgIconProps } from "@material-ui/core";

import StyledSvgIcon from "./styled-svg-icon";

const HelpIcon = (props: SvgIconProps) => (
  <StyledSvgIcon width="24px" height="24px" {...props}>
    <g
      id="Icon-/-question-hook-focused"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g id="help_outline_black_24dp">
        <g>
          <polygon id="question-hook-focused-Path" points="0 0 24 0 24 24 0 24" />
          <path
            // eslint-disable-next-line max-len
            d="M13,16 L13,16.032 L14.035581,18.0233331 C13.343083,18.3031135 12.664556,18.4430037 12,18.4430037 C11.335444,18.4430037 10.6716795,18.3031135 10.0087062,18.0233331 L11,16.062 L11,16 L13,16 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 Z M12,6 C9.79,6 8,7.79 8,10 C8,10.6969489 8,11.0454233 8,11.0454233 C8,11.0454233 8.66666667,10.6969489 10,10 C10,8.9 10.9,8 12,8 C13.1,8 14,8.9 14,10 C14,12 11,11.75 11,15 L13,15 C13,12.75 16,12.5 16,10 C16,7.79 14.21,6 12,6 Z"
            id="question-hook-focused-Shape"
            fill="none"
            fillRule="nonzero"
          />
        </g>
      </g>
    </g>
  </StyledSvgIcon>
);

export default HelpIcon;
