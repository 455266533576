import { useCallback, useEffect, useRef, useState } from "react";

export const useSearchListFieldPopoverHandlers = (
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
  portalRef: React.MutableRefObject<HTMLDivElement | null>,
  onOpen?: () => void,
  onClose?: () => void,
) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // Here we'll store the element that was focused prior to opening the popover
  const previousFocusedElement = useRef<Element | null>(null);

  const openPopover = useCallback(() => {
    previousFocusedElement.current = document.activeElement;

    onOpen?.();

    setIsPopoverOpen(true);
  }, [onOpen]);

  const closePopover = useCallback(
    (restoreFocusOnElement?: "button" | "previousFocusedElement") => {
      if (
        previousFocusedElement.current instanceof HTMLElement &&
        restoreFocusOnElement === "previousFocusedElement"
      ) {
        previousFocusedElement.current.focus();
      } else if (restoreFocusOnElement === "button") {
        // Defer focus to prevent focusing the body element when clicking SearchListField's fixed backdrop
        setTimeout(() => buttonRef.current?.focus(), 0);
      }

      previousFocusedElement.current = null;

      onClose?.();

      setIsPopoverOpen(false);
    },
    [buttonRef, onClose],
  );

  // Close popover when clicking outside
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (portalRef.current && !portalRef.current.contains(event.target as Node)) {
        closePopover("button");

        // Prevent reopening the popover
        if (
          event?.target &&
          event.target instanceof Node &&
          (buttonRef?.current?.contains(event.target) || buttonRef?.current === event.target)
        ) {
          event.preventDefault();
          event.stopPropagation();
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [buttonRef, closePopover, onClose, portalRef]);

  // Close popover on escape key
  useEffect(() => {
    const portalElement = portalRef.current;

    function handleEscapeKeydown(e: KeyboardEvent) {
      if (isPopoverOpen && e.code === "Escape") {
        closePopover("previousFocusedElement");
      }
    }

    // Bind the event listener
    portalElement?.addEventListener("keydown", handleEscapeKeydown);

    // Unbind the event listener on unmount
    return () => portalElement?.removeEventListener("keydown", handleEscapeKeydown);
  }, [closePopover, isPopoverOpen, portalRef]);

  return { isPopoverOpen, openPopover, closePopover };
};
