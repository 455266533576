import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup, SvgIcon } from "@new-black/lyra";

import Grid from "../grid";
import Select from "../select";

import { Container, Item, ItemText, Panel } from "./table-pagination-helper-components";
import type { ITableCountablePaginationProps } from "./table-pagination-types";

const TableCountablePagination = ({
  canNextPage,
  canPreviousPage,
  dataSize,
  disabled,
  goToPage,
  hidePageOptionsSelect,
  nextPage,
  pageCount,
  pageIndex,
  pageOptionsMenuItems,
  pageSize,
  paginationTableStrings,
  previousPage,
  setPageSize,
}: ITableCountablePaginationProps) => {
  const intl = useIntl();
  return (
    <Panel>
      {/* TODO: Implement this: https://codesandbox.io/s/github/tannerlinsley/react-table/tree/master/examples/pagination-controlled */}
      <Grid container justifyContent="flex-end">
        <Grid item>
          <Container>
            {!hidePageOptionsSelect ? (
              <Item>
                <div>
                  <ItemText>
                    {paginationTableStrings.rowsPerPage ?? (
                      <span>
                        <FormattedMessage
                          id="generic.label.rows-per-page"
                          defaultMessage="rows per page"
                        />
                        :
                      </span>
                    )}
                  </ItemText>
                </div>
                <div>
                  {pageOptionsMenuItems ? (
                    <Select
                      name="selectRowSize"
                      items={pageOptionsMenuItems ?? []}
                      small
                      value={pageSize}
                      onChange={(e) => {
                        if (setPageSize && e.target.value) {
                          setPageSize(Number.parseInt(`${e.target.value}`, 10));
                        }
                      }}
                    />
                  ) : null}
                </div>
              </Item>
            ) : null}
            <Item>
              <ItemText>
                <FormattedMessage
                  id="generic.label.table-page-indicator"
                  defaultMessage="{pageStart}-{pageEnd} of {total}"
                  values={{
                    pageStart: ((pageIndex + 1) * pageSize - (pageSize - 1)).toString(),
                    pageEnd: ((pageIndex + 1) * pageSize < dataSize
                      ? (pageIndex + 1) * pageSize
                      : dataSize
                    ).toString(),
                    total: dataSize.toString(),
                  }}
                />
              </ItemText>
            </Item>
            <Item>
              <ButtonGroup>
                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.firstPage ??
                    intl.formatMessage({
                      id: "generic.label.first-page",
                      defaultMessage: "First page",
                    })
                  }
                  onPress={() => goToPage(0)}
                  isDisabled={pageIndex === 0 || disabled}
                >
                  <SvgIcon name="chevron-first-page" />
                </Button>

                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.previousPage ??
                    intl.formatMessage({
                      id: "generic.label.previous-page",
                      defaultMessage: "Previous page",
                    })
                  }
                  onPress={() => previousPage()}
                  isDisabled={!canPreviousPage || disabled}
                >
                  <SvgIcon name="chevron-left" />
                </Button>

                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.nextPage ??
                    intl.formatMessage({
                      id: "generic.label.next-page",
                      defaultMessage: "Next page",
                    })
                  }
                  onPress={() => nextPage()}
                  isDisabled={!canNextPage || disabled}
                >
                  <SvgIcon name="chevron-right" />
                </Button>

                <Button
                  variant="icon"
                  tooltip={
                    paginationTableStrings.lastPage ??
                    intl.formatMessage({
                      id: "generic.label.last-page",
                      defaultMessage: "Last page",
                    })
                  }
                  onPress={() => goToPage(pageCount - 1)}
                  isDisabled={pageIndex >= Math.ceil(dataSize / pageSize) - 1 || disabled}
                >
                  <SvgIcon name="chevron-last-page" />
                </Button>
              </ButtonGroup>
            </Item>
          </Container>
        </Grid>
      </Grid>
    </Panel>
  );
};
export default TableCountablePagination;
