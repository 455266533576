import type { ComponentType, ReactNode } from "react";
import { useMemo } from "react";
import { createPortal } from "react-dom";

import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement } from "@dnd-kit/modifiers";
import { SortableContext, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import classNames from "classnames";

import useDragEvents from "./hooks/use-drag-events";
import useSortingStrategy from "./hooks/use-sorting-strategy";
import type IDraggable from "./drag-and-drop.types";
import DroppableContainer from "./droppable-container";

interface IDragDropContainerProps<TItemProps extends { value: string }, TItemComponentProps> {
  items: TItemProps[];
  setItems: (newItems: TItemProps[]) => void;
  direction?: "vertical" | "horizontal" | "grid";
  disableRestrictToParent?: boolean;
  onDragStart?: () => void;
  onDragEnd?: () => void;
  hasAdditionalTopPadding?: boolean;
  OverlayComponent: ComponentType<Partial<TItemProps> & Partial<TItemComponentProps> & IDraggable>;
  overlayComponentProps?: Partial<TItemComponentProps>;
  children: ReactNode;
  parentNode?: HTMLElement | null;
}

const DragDropContainer = <
  TItemProps extends { value: string; items?: TItemProps[] },
  TItemComponentProps,
>({
  children,
  direction,
  disableRestrictToParent,
  hasAdditionalTopPadding,
  items,
  onDragEnd,
  onDragStart,
  OverlayComponent,
  overlayComponentProps,
  parentNode,
  setItems,
}: IDragDropContainerProps<TItemProps, TItemComponentProps>) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const {
    activeItem,
    onDragCancel,
    onDragEnd: handleDrag,
    onDragOver,
    onDragStart: handleDragStart,
  } = useDragEvents(items, setItems, onDragStart, onDragEnd);

  const sortingStrategy = useSortingStrategy(direction);

  const itemIds = useMemo(() => items.map((item) => item.value), [items]);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={handleDrag}
      onDragOver={onDragOver}
      onDragCancel={onDragCancel}
    >
      <SortableContext items={itemIds} strategy={sortingStrategy}>
        <DroppableContainer id="top-level">
          <div
            className={classNames(hasAdditionalTopPadding ? "-mt-[50px] pt-[50px]" : "mt-0 pt-0")}
          >
            {children}
          </div>
        </DroppableContainer>
        {createPortal(
          <DragOverlay modifiers={disableRestrictToParent ? undefined : [restrictToParentElement]}>
            {activeItem ? (
              <OverlayComponent
                {...activeItem}
                {...(overlayComponentProps as TItemComponentProps)}
                isDragging
              />
            ) : null}
          </DragOverlay>,
          parentNode ?? document.body,
        )}
      </SortableContext>
    </DndContext>
  );
};

export default DragDropContainer;
