import { useIntl } from "react-intl";

export const ISONameColumnValue = ({
  type,
  value,
}: {
  type: "region" | "language";
  value?: string;
}) => {
  const intl = useIntl();
  const name =
    value &&
    intl.formatDisplayName(value, {
      type,
    });

  if (name && value) {
    return (
      <div>
        {name} ({value})
      </div>
    );
  }

  return <div />;
};
