import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

const UploadActionButton = forwardRef<HTMLButtonElement, IActionButton>(
  ({ className, isDisabled, isLoading, onPress, tooltip }: IActionButton, ref) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        className={className}
        onPress={onPress}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.upload", defaultMessage: "Upload" })
        }
        isDisabled={isDisabled}
        isLoading={isLoading}
        variant="icon"
      >
        <SvgIcon name="upload" />
      </Button>
    );
  },
);

UploadActionButton.displayName = "UploadActionButton";

export default UploadActionButton;
