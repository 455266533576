import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import Chip from "components/suite-ui/chip";
import IconButton from "components/suite-ui/icon-button";
import Input from "components/suite-ui/input";
import LinkButton from "components/suite-ui/link-button";
import useParseStringToInteger from "hooks/suite-react-hooks/use-parse-string-to-integer";

type FilterOnOrderIDsProps = {
  orderIDs: number[] | undefined;
  setOrderIDs: (newValue: number[] | undefined) => void;
};

export const FilterOnOrderIDs = ({ orderIDs, setOrderIDs }: FilterOnOrderIDsProps) => (
  <div>
    <FilteredOnOrderIDsChipsHeader orderIDs={orderIDs} setOrderIDs={setOrderIDs} />
    <FilterOnOrderIDsChipList orderIDs={orderIDs} setOrderIDs={setOrderIDs} />
    <FilterOnOrderIDsInput orderIDs={orderIDs} setOrderIDs={setOrderIDs} />
  </div>
);

const FilteredOnOrderIDsChipsHeader = ({ orderIDs, setOrderIDs }: FilterOnOrderIDsProps) => {
  const clearAllSelectedOrderIDs = useCallback(() => {
    setOrderIDs(undefined);
  }, [setOrderIDs]);

  return orderIDs?.length ? (
    <div className="flex justify-end">
      <LinkButton variant="body2" onClick={clearAllSelectedOrderIDs}>
        <FormattedMessage
          id="generic.label.clear-all-order-ids"
          defaultMessage="Clear all order IDs"
        />
      </LinkButton>
    </div>
  ) : null;
};

const FilterOnOrderIDsChipList = ({ orderIDs, setOrderIDs }: FilterOnOrderIDsProps) => {
  const removedOrderID = useCallback(
    (selectedOrderID: number) => {
      const updatedOrderIDs = orderIDs?.filter((orderID) => orderID !== selectedOrderID);

      setOrderIDs(updatedOrderIDs?.length ? updatedOrderIDs : undefined);
    },
    [orderIDs, setOrderIDs],
  );

  return (
    <div className="flex flex-wrap gap-1">
      {orderIDs?.map((val) => (
        <Chip key={val} onDelete={() => removedOrderID(val)}>
          {val?.toString()}
        </Chip>
      ))}
    </div>
  );
};

const FilterOnOrderIDsInput = ({ orderIDs, setOrderIDs }: FilterOnOrderIDsProps) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState<string | undefined>();
  const numberInput = useParseStringToInteger(inputValue);

  const addOrderID = useCallback(
    (orderID: number) => {
      const orderIDAlreadyOnList = orderIDs?.includes(orderID);

      if (!orderIDAlreadyOnList && orderID) {
        setInputValue("");

        if (orderIDs) {
          setOrderIDs([...orderIDs, orderID]);
        } else {
          setOrderIDs([orderID]);
        }
      }
    },
    [orderIDs, setOrderIDs],
  );

  return (
    <Input
      value={inputValue ?? ""}
      label={
        !orderIDs?.length
          ? intl.formatMessage({ id: "generic.label.order-ids", defaultMessage: "Order ID's" })
          : undefined
      }
      placeholder={
        orderIDs?.length
          ? intl.formatMessage({ id: "generic.label.order-ids", defaultMessage: "Order ID's" })
          : undefined
      }
      onKeyUp={(event) => {
        if (event.code === "Comma" || event.code === "Enter" || event.code === "NumpadEnter") {
          if (numberInput) {
            addOrderID(numberInput);
          }
        }
      }}
      helperText={intl.formatMessage({
        id: "orders.overview.filters.orderids.helper-text",
        defaultMessage: "Press 'enter' or 'comma' to add the order ID",
      })}
      onChange={(event) => setInputValue(event.target.value)}
      endIcon={
        numberInput ? (
          <IconButton onClick={() => addOrderID(numberInput)}>
            <SvgIcon name="plus" />
          </IconButton>
        ) : null
      }
      type="number"
    />
  );
};
