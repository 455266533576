import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

export interface IOrganizeColumnsAction {
  isReorganizeActive?: boolean;
  setIsReorganizeActive: (newShowOrganizeColumns: boolean) => void;
  tooltipTitle?: string;
  className?: string;
}

const ReorganizeAction = ({
  className,
  isReorganizeActive,
  setIsReorganizeActive,
  tooltipTitle,
}: IOrganizeColumnsAction) => {
  const intl = useIntl();

  return (
    <Button
      variant="icon"
      onPress={() => setIsReorganizeActive(!isReorganizeActive)}
      tooltip={
        tooltipTitle ??
        intl.formatMessage({ id: "generic.label.reorganize", defaultMessage: "Reorganize" })
      }
      className={className}
    >
      {isReorganizeActive ? <SvgIcon name="checkmark" /> : <SvgIcon name="workspaces" />}
    </Button>
  );
};

export default ReorganizeAction;
