import classNames from "classnames";

import { Link } from "components/routing";

interface IconTileProps {
  orientation?: "horizontal" | "vertical";
  to: string;
  icon: React.ReactNode;
  label: string;
  description?: string;
  onClick?: () => void;
}

export const ModuleIconTile = ({
  description,
  icon,
  label,
  onClick,
  orientation = "horizontal",

  to,
}: IconTileProps) => {
  if (orientation === "horizontal") {
    return (
      <Link to={to} className="group no-underline" onClick={onClick}>
        <div
          className={classNames(
            "flex gap-3 rounded-[20px] hover:scale-103",
            "transition-all duration-200 ease-in-out",
            description ? "items-start" : "items-center",
          )}
        >
          <div className="ring-[color:var(--color-primary-3)]-1 h-12 w-12 rounded-md p-1 group-focus-visible:ring-2">
            <svg width="40" height="40" viewBox="0 0 56 56">
              {icon}
            </svg>
          </div>
          <div>
            <span className="text-legacy-base block pt-1 font-semibold leading-5 text-primary">
              {label}
            </span>
            {description && <span className="text-xs text-secondary">{description}</span>}
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link to={to} className="group no-underline" onClick={onClick}>
      <div
        className={classNames(
          "flex w-[150px] flex-col items-center justify-center gap-3 group-hover:scale-103 group-focus-visible:scale-103",
          "transition-all duration-200 ease-in-out",
        )}
      >
        <div
          className={classNames(
            "ring-[color:var(--color-primary-3)]-1 h-16 w-16 rounded-xl p-1 text-center group-focus-visible:ring-2",
          )}
        >
          <svg width="56" height="56" viewBox="0 0 56 56">
            {icon}
          </svg>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-legacy-base block text-center font-semibold leading-5 text-primary">
            {label}
          </span>
          {description && <span className="text-xs text-secondary">{description}</span>}
        </div>
      </div>
    </Link>
  );
};

ModuleIconTile.defaultProps = {
  orientation: "horizontal",
  description: undefined,
};
