import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { PIM } from "@springtree/eva-services-pim";
import { selector } from "recoil";

import { IProductPropertyTypes } from "./types";

export const searchProductPropertyTypesState = builders.buildServiceState({
  service: PIM.SearchProductPropertyTypes,
  key: "Composite:ProductFilter:ProductPropertyTypes",
  requireLoggedIn: true,
  allowEmptyRequest: false,
});

export const productPropertyTypesList = selector<IProductPropertyTypes[] | undefined>({
  key: "Composite:ProductFilter:ProductPropertyTypes:List",
  get: ({ get }) => {
    const response = get(searchProductPropertyTypesState.response);
    const mapped: IProductPropertyTypes[] | undefined = response?.Result?.Page.map(
      (propertyType) => ({
        data_type: propertyType.data_type,
        type_id: propertyType.type_id,
        search_type: propertyType.search_type,
      }),
    );

    return mapped;
  },
});

export const productSearchTemplatesState = builders.buildServiceState({
  service: CoreManagement.ListProductSearchTemplates,
  key: "Composite:ProductFilter:ProductSearchTemplates",
  requireLoggedIn: true,
  allowEmptyRequest: false,
});

export const productSearchTemplatesSelector = selector({
  key: "Composite:ProductFilter:ProductSearchTemplates:List",
  get: ({ get }) => {
    const response = get(productSearchTemplatesState.response);
    return response?.Result?.Page;
  },
});

export const searchProductsServiceState = builders.buildServiceFamilyState({
  service: Core.SearchProducts,
  key: "Composite:ProductFilter:SearchProducts",
  requireEmployee: true,
  allowEmptyRequest: false,
});
