import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getPriceListByIDLoaderQuery,
  serviceQuery: getPriceListByIDQuery,
  serviceQueryKeys: getPriceListByIDQueryKeys,
  useServiceQueryHook: useGetPriceListByIDQuery,
} = createServiceQuery(CoreManagement.GetPriceListByID, true);

export const {
  serviceLoaderQuery: listPriceListsLoaderQuery,
  serviceQuery: listPriceListsQuery,
  serviceQueryKeys: listPriceListsQueryKeys,
  useServiceQueryHook: useListPriceListsQuery,
  useServiceQueryWithRequest: useListPriceListsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPriceLists);

export const {
  serviceLoaderQuery: listPriceListAdjustmentsLoaderQuery,
  serviceQueryKeys: listPriceListAdjustmentsQueryKeys,
  useServiceQueryHook: useListPriceListAdjustmentsQuery,
} = createServiceQuery(CoreManagement.ListPriceListAdjustments);

export const {
  serviceLoaderQuery: listPriceListManualInputAdjustmentsLoaderQuery,
  serviceQueryKeys: listPriceListManualInputAdjustmentsQueryKeys,
  useServiceQueryHook: useListPriceListManualInputAdjustmentsQuery,
  useServiceQueryWithRequest: useListPriceListManualInputAdjustmentsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPriceListManualInputAdjustments);

export const {
  serviceLoaderQuery: listPriceListOrganizationUnitsLoaderQuery,
  serviceQuery: listPriceListOrganizationUnitsQuery,
  serviceQueryKeys: listPriceListOrganizationUnitsQueryKeys,
  useServiceQueryHook: useListPriceListOrganizationUnitsQuery,
  useServiceQueryWithRequest: useListPriceListOrganizationUnitsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPriceListOrganizationUnits);

export const {
  serviceLoaderQuery: getPriceListUsageTypesLoaderQuery,
  useServiceQueryHook: useGetPriceListUsageTypesQuery,
} = createServiceQuery(CoreManagement.GetPriceListUsageTypes);

export const {
  serviceLoaderQuery: getPriceListOrganizationUnitByIDLoaderQuery,
  useServiceQueryHook: useGetPriceListOrganizationUnitByIDQuery,
} = createServiceQuery(CoreManagement.GetPriceListOrganizationUnitByID);

export const {
  serviceLoaderQuery: getPriceListManualInputAdjustmentByIDLoaderQuery,
  serviceQuery: getPriceListManualInputAdjustmentByIDQuery,
  serviceQueryKeys: getPriceListManualInputAdjustmentByIDQueryKeys,
  useServiceQueryHook: useGetPriceListManualInputAdjustmentByIDQuery,
} = createServiceQuery(CoreManagement.GetPriceListManualInputAdjustmentByID);
