import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { ButtonLink } from "components/routing";

import { IActionButtonLink } from "./types";

const OpenInNewTabActionButtonLink = forwardRef<
  HTMLAnchorElement,
  IActionButtonLink & { small?: boolean }
>(
  (
    {
      className,
      force,
      keepSearchParams,
      onLinkClick,
      small,
      state,
      target = "_blank",
      to,
      tooltip,
      variant,
    }: IActionButtonLink & { small?: boolean },
    ref,
  ) => {
    const intl = useIntl();

    return (
      <ButtonLink
        to={to}
        keepSearchParams={keepSearchParams}
        target={target}
        onClick={onLinkClick}
        force={force}
        state={state}
        ref={ref}
        className={className}
        tooltip={
          tooltip ??
          intl.formatMessage({
            id: "generic.label.open-in-new-tab",
            defaultMessage: "Open in new tab",
          })
        }
        variant={variant === "icon-xs" || small ? "icon-xs" : "icon"}
      >
        <SvgIcon name="open-in-new-tab" />
      </ButtonLink>
    );
  },
);

OpenInNewTabActionButtonLink.displayName = "OpenInNewTabActionButtonLink";

export default OpenInNewTabActionButtonLink;
