import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { discountLayersResultSelector } from ".";

const useDiscountLayers = () => {
  const discountLayers = hooks.useGetState(discountLayersResultSelector);

  const isLoading = hooks.useIsLoading({ state: discountLayersResultSelector });
  const isLoadingWithoutResponse = useMemo(
    () => !discountLayers && isLoading,
    [discountLayers, isLoading],
  );

  return {
    discountLayers,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export default useDiscountLayers;
