import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listInquiriesLoaderQuery,
  serviceQuery: listInquiriesQuery,
  serviceQueryKeys: listInquiriesQueryKeys,
  useServiceQueryHook: useListInquiriesQuery,
} = createServiceQuery(CoreManagement.ListInquiries);
