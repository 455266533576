import { useCallback, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState } from "recoil";

import { customFieldOptionsService } from "./use-custom-field-options.state";

const useCustomFieldOptions = (customfieldId?: number) => {
  const customFieldOptions = hooks.useGetState(customFieldOptionsService.response(customfieldId));

  const isLoading = hooks.useIsLoading({
    state: customFieldOptionsService.serviceState.response(customfieldId),
  });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !customFieldOptions,
    [customFieldOptions, isLoading],
  );

  const refetch = hooks.useSetStale({
    staleState: customFieldOptionsService.serviceState.stale(customfieldId),
  });

  const [limit, setLimit] = useRecoilState(customFieldOptionsService.limit(customfieldId));
  const [pageToken, setPageToken] = useRecoilState(
    customFieldOptionsService.currentPageToken(customfieldId),
  );

  const previousPageToken = hooks.useGetState(
    customFieldOptionsService.previousPageToken(customfieldId),
  );

  const nextPageToken = hooks.useGetState(customFieldOptionsService.nextPageToken(customfieldId));

  const hasPreviousPage = useMemo(() => !!previousPageToken, [previousPageToken]);
  const hasNextPage = useMemo(() => !!nextPageToken, [nextPageToken]);

  const goToPreviousPage = useCallback(
    () => setPageToken(previousPageToken),
    [previousPageToken, setPageToken],
  );

  const goToNextPage = useCallback(
    () => setPageToken(nextPageToken),
    [nextPageToken, setPageToken],
  );

  return {
    limit,
    refetch,
    setLimit,
    isLoading,
    pageToken,
    hasNextPage,
    goToNextPage,
    setPageToken,
    nextPageToken,
    hasPreviousPage,
    goToPreviousPage,
    previousPageToken,
    customFieldOptions,
    isLoadingWithoutPreviousResponse,
  };
};

useCustomFieldOptions.service = customFieldOptionsService;

export default useCustomFieldOptions;
