import { AutocompleteGetTagProps } from "@material-ui/lab";

import Chip, { ChipListWrapper } from "../../chip";

interface IAutocompleteTagProps<T> {
  id?: string;
  name?: string;
  idKey: keyof T;
  chipValues: T[];
  labelKey: keyof T;
  getTagProps: AutocompleteGetTagProps;
  handleMultiDeselect: (itemToDeselect: T) => void;
}

export const AutocompleteTag = <T,>({
  chipValues,
  getTagProps,
  handleMultiDeselect,
  id,
  idKey,
  labelKey,
  name,
}: IAutocompleteTagProps<T>) => (
  <ChipListWrapper>
    {chipValues.map((option, index) => (
      <Chip
        color="default"
        key={`chipOption-${id ?? name}-${option[idKey]}`}
        {...getTagProps({ index })}
        onDelete={(event) => {
          event?.stopPropagation();
          handleMultiDeselect(option);
        }}
      >
        {option[labelKey] ? `${option[labelKey]}` : ""}
      </Chip>
    ))}
  </ChipListWrapper>
);
