import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listPersonalizedPromotionsLoaderQuery,
  serviceQuery: listPersonalizedPromotionsQuery,
  serviceQueryKeys: listPersonalizedPromotionsQueryKeys,
  useServiceQueryHook: useListPersonalizedPromotionsQuery,
} = createServiceQuery(CoreManagement.ListPersonalizedPromotions);

export const {
  serviceLoaderQuery: getPersonalizedPromotionLoaderQuery,
  serviceQuery: getPersonalizedPromotionByIDQuery,
  serviceQueryKeys: getPersonalizedPromotionQueryKeys,
  useServiceQueryHook: useGetPersonalizedPromotionQuery,
} = createServiceQuery(CoreManagement.GetPersonalizedPromotionByID, true);
