import { Core } from "@springtree/eva-services-core";

import { getUserTaskTypesQuery } from "models/tasks";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateUserTaskSubtypesAutocomplete = (userTaskTypeId?: number) => {
  const {
    SingleAutocomplete: UserTaskSubTypesAutocomplete,
    SingleIDAutocomplete: UserTaskSubTypesIDAutocomplete,
  } = AutocompleteGenerator<Core.GetUserTaskTypes, EVA.Core.EnumDto, "ID">({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.Types?.find((userTaskType) => userTaskType.ID === userTaskTypeId)?.SubTypes,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user-task-sub-type",
      defaultMessage: "User task sub type",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: getUserTaskTypesQuery,
        initialRequest: {
          IncludeSubTypes: true,
        },
      }),
    useItemByID: (id, allSubtypes) => {
      return {
        data: allSubtypes?.find((subtype) => subtype.ID === id),
      };
    },
  });

  return {
    UserTaskSubTypesAutocomplete,
    UserTaskSubTypesIDAutocomplete,
  };
};

export type UserTaskSubTypeSearchListFieldItem = Pick<
  EVA.Core.UserTaskTypeDto["SubTypes"][number],
  "ID" | "Name"
>;

export const generateUserTaskSubtypesLyraSearchListField = (userTaskTypeId?: number) => {
  const {
    SingleIDSearchListField: UserTaskSubTypesIDSearchListField,
    SingleSearchListField: UserTaskSubTypesSearchListField,
  } = SearchListFieldGenerator<Core.GetUserTaskTypes, UserTaskSubTypeSearchListFieldItem, number>({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,

    getItemFromResponse: (resp) =>
      resp?.Types?.find((userTaskType) => userTaskType.ID === userTaskTypeId)?.SubTypes,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user-task-sub-type",
      defaultMessage: "User task sub type",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getUserTaskTypesQuery,
        initialRequest: {
          IncludeSubTypes: true,
        },
      }),
    useItemByID: (id, allSubtypes) => {
      return {
        data: allSubtypes?.find((subtype) => subtype.ID === id),
      };
    },
  });

  return {
    UserTaskSubTypesIDSearchListField,
    UserTaskSubTypesSearchListField,
  };
};
