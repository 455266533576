import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const ToggleIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Toggle_outlined</title>
    <defs>
      <filter id="filter-1">
        <feColorMatrix
          in="SourceGraphic"
          type="matrix"
          values="0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0 0.000000 0 0 0 0.600000 0"
        />
      </filter>
    </defs>
    <g id="Icon-/-Toggle" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g filter="url(#filter-1)" id="indeterminate_check_box-24px">
        <g>
          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
        </g>
      </g>
      <path
        // eslint-disable-next-line max-len
        d="M15,6 C18.3137085,6 21,8.6862915 21,12 C21,15.3137085 18.3137085,18 15,18 L9,18 C5.6862915,18 3,15.3137085 3,12 C3,8.6862915 5.6862915,6 9,6 L15,6 Z M15,8 C12.790861,8 11,9.790861 11,12 C11,14.209139 12.790861,16 15,16 C17.209139,16 19,14.209139 19,12 C19,9.790861 17.209139,8 15,8 Z"
        id="Combined-Shape"
        fillOpacity="0.6"
        fill="#000000"
        fillRule="nonzero"
      />
    </g>
  </SvgIcon>
);
