import { Dispatch, ReactNode, SetStateAction, useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import { useSetRecoilState } from "recoil";

import { useNavigate } from "components/routing";
import ErrorBoundary from "components/suite-ui/error-boundary";
import Table from "components/suite-ui/table";
import useCurrentUser from "hooks/use-current-user";
import routeDefinitions from "routes/route-definitions";

import { UserIdAssignedToTask } from "../state";
import TaskAssigneeWarningDialog from "../task-assignee-warning-dialog";

import AvailableUserTasksTableActions from "./available-user-tasks-table-actions";
import { AvailableUserTasksTableColumns, IAvailableUserTaskDto } from "./types";
import useAvailableUserTasksTableColumns from "./use-available-user-tasks-table-columns";

export interface IAvailableUserTasksTable {
  tasks: IAvailableUserTaskDto[];
  refetchTasks?: () => void;
  loading: boolean;
  enablePrinting?: boolean;
  enableDeclining?: boolean;
  enableCompleting?: boolean;
  enableSelection?: boolean;
  completeTasksCustomIcon?: ReactNode;
  title?: string;
  columns: AvailableUserTasksTableColumns[];
  customActions?: React.ReactNode;
  customRowActions?: ({ rowData }: { rowData: IAvailableUserTaskDto }) => React.ReactNode;
  limit?: number;
  start?: number;
  setStart?: (value: number) => void;
  setLimit?: (value: number) => void;
  onRowClick?: (task: IAvailableUserTaskDto) => void;
  onSelectedLinesChange?: Dispatch<SetStateAction<IAvailableUserTaskDto[]>>;
  completeMultipleAtOnce?: boolean;
  disableRowClick?: boolean;
}

const AvailableUserTasksTable = ({
  columns,
  completeMultipleAtOnce,
  completeTasksCustomIcon,
  customActions,
  customRowActions,
  disableRowClick,
  enableCompleting,
  enableDeclining,
  enablePrinting,
  enableSelection,
  limit,
  loading,
  onRowClick,
  onSelectedLinesChange,
  refetchTasks,
  setLimit,
  setStart,
  start,
  tasks,
  title,
}: IAvailableUserTasksTable) => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useCurrentUser();
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [localTaskID, setLocalTaskID] = useState<number | undefined>();
  const [localCompositeTaskID, setLocalCompositeTaskID] = useState<number | undefined>();
  const setUserIdAssignedToTask = useSetRecoilState(UserIdAssignedToTask(location.pathname));
  const [selectedLines, setSelectedLines] = useState<IAvailableUserTaskDto[]>([]);
  const emptySelectedLines = useCallback(() => {
    setSelectedLines([]);
    onSelectedLinesChange?.([]);
  }, [onSelectedLinesChange]);
  const [tableLimit, setTableLimit] = useState(10);
  const [tableStart, setTableStart] = useState(0);

  const handleTaskClick = useCallback(
    (taskID: number, assigneeUserId?: number, compositeTaskID?: number) => {
      if (assigneeUserId === currentUser?.ID || assigneeUserId === undefined) {
        navigate(`${compositeTaskID ? `${compositeTaskID}/${taskID}` : taskID}`);
      } else {
        setLocalTaskID(taskID);
        setLocalCompositeTaskID(compositeTaskID);
        setShowWarningDialog(true);
        setUserIdAssignedToTask(assigneeUserId);
      }
    },
    [currentUser?.ID, navigate, setUserIdAssignedToTask],
  );

  const handleWarningDialogClose = useCallback(
    (accept: boolean) => {
      setShowWarningDialog(false);

      if (accept) {
        setUserIdAssignedToTask(currentUser?.ID);
        navigate(
          `${localCompositeTaskID ? `${localCompositeTaskID}/${localTaskID}` : localTaskID}`,
          {
            state: {
              fromOverview:
                location.pathname === routeDefinitions.tasks.reservation.overview.path
                  ? true
                  : undefined,
            },
          },
        );
      }
    },
    [
      setUserIdAssignedToTask,
      currentUser?.ID,
      navigate,
      localCompositeTaskID,
      localTaskID,
      location.pathname,
    ],
  );

  const tableColumns = useAvailableUserTasksTableColumns(columns);

  return (
    <ErrorBoundary>
      <div>
        <Table
          columns={tableColumns}
          data={tasks ?? []}
          isLoading={loading}
          limit={limit ?? tableLimit}
          skip={start ?? tableStart}
          setLimit={setLimit ?? setTableLimit}
          setSkip={setStart ?? setTableStart}
          total={tasks?.length ?? 0}
          customActions={
            <ErrorBoundary>
              {selectedLines.length && refetchTasks ? (
                <AvailableUserTasksTableActions
                  refetchTasks={refetchTasks}
                  selectedLines={selectedLines}
                  emptySelectedLines={emptySelectedLines}
                  enablePrinting={enablePrinting}
                  enableDeclining={enableDeclining}
                  enableCompleting={enableCompleting}
                  completeTasksCustomIcon={completeTasksCustomIcon}
                  completeMultipleAtOnce={completeMultipleAtOnce}
                />
              ) : (
                <div>{customActions || null}</div>
              )}
            </ErrorBoundary>
          }
          customRowActions={customRowActions}
          selectedData={selectedLines}
          onSelectionChangeData={(value) => {
            setSelectedLines(value);
            onSelectedLinesChange?.(value);
          }}
          onRowClick={
            disableRowClick
              ? undefined
              : (rowData: IAvailableUserTaskDto) => {
                  onRowClick
                    ? onRowClick(rowData)
                    : handleTaskClick(rowData.ID, rowData.User?.ID, rowData?.compositeUserTaskID);
                }
          }
          options={{
            hideHeader: !title,
            title,
            selectRows: enablePrinting || enableDeclining || enableSelection,
            selectAll: enablePrinting || enableDeclining || enableSelection,
          }}
        />
        <ErrorBoundary>
          <TaskAssigneeWarningDialog open={showWarningDialog} onClose={handleWarningDialogClose} />
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
};

AvailableUserTasksTable.defaultProps = {
  title: undefined,
  idColumn: undefined,
  refetchTasks: undefined,
  enablePrinting: undefined,
  enableDeclining: undefined,
};

export default AvailableUserTasksTable;
