export enum OrchestrationOperatorTypes {
  Equals = "Equals",
  NotEquals = "NotEquals",
  In = "In",
  GreaterThan = "GreaterThan",
  LessThan = "LessThan",
  NotIn = "NotIn",
  GreaterThanOrEquals = "GreaterThanOrEquals",
  LessThanOrEquals = "LessThanOrEquals",
}
