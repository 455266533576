import { MutableRefObject, useEffect, useRef } from "react";

type UseActionOnUnmountReturnType<T> = MutableRefObject<T | undefined>;

/**
 * Hook to kick of an action before the component unmounts.
 *
 * @param currentShape
 * @param action
 */
const useActionOnUnmount = <T>(
  currentShape: T | undefined,
  action: (value: T) => void,
): UseActionOnUnmountReturnType<T> => {
  const data = useRef<T | undefined>(currentShape);

  useEffect(() => {
    data.current = currentShape;
  }, [currentShape]);

  useEffect(
    () => () => {
      if (data.current) {
        action(data.current);
      }
    },
    [action],
  );

  return data;
};

export default useActionOnUnmount;
