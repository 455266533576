import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { ALL_ROWS_LIMIT } from "components/suite-composite/utils/types";
import { useHandleSWR } from "hooks/suite-react-hooks/use-handle-swr";
import useSetRequest from "hooks/suite-react-hooks/use-set-request";

import { listCustomFieldsResultSelector, listCustomFieldsServiceState } from ".";

const useListCustomFields = (
  Filter?: Partial<EVA.Core.ListCustomFieldsFilter>,
  disableRequest?: boolean,
) => {
  const data = hooks.useGetState(listCustomFieldsResultSelector);

  const isLoading = hooks.useIsLoading({ state: listCustomFieldsServiceState.response });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  const request = useMemo<EVA.Core.Management.ListCustomFields | undefined>(
    () =>
      !disableRequest
        ? {
            PageConfig: {
              Start: 0,
              Limit: ALL_ROWS_LIMIT,
              Filter,
            },
          }
        : undefined,
    [Filter, disableRequest],
  );

  useSetRequest(listCustomFieldsServiceState.request, request);
  useHandleSWR(listCustomFieldsServiceState);

  return { data, isLoading, isLoadingWithoutResponse };
};

export default useListCustomFields;
