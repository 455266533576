import { useIntl } from "react-intl";

import { generateLyraProductSearchPropertySearchListField } from "../generate-product-search-property-search-list-field";

const ProductPropertySearchListField = generateLyraProductSearchPropertySearchListField({});

export default function ProductPropertyAutocomplete() {
  const intl = useIntl();

  return (
    <ProductPropertySearchListField.SingleIDSearchListField.Formik
      isRequired
      name="productProperty"
      label={intl.formatMessage({ id: "generic.label.property", defaultMessage: "Property" })}
    />
  );
}
