import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import IconButton from "components/suite-ui/icon-button";

export const InputClearIcon = ({ onClear, shown }: { shown: boolean; onClear: () => void }) => {
  const intl = useIntl();

  if (shown) {
    return (
      <IconButton
        size="small"
        label={intl.formatMessage({ id: "generic.label.clear", defaultMessage: "Clear" })}
        onClick={() => {
          onClear();
        }}
      >
        <SvgIcon name="alert-error" />
      </IconButton>
    );
  }

  return null;
};
