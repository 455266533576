import { Dispatch, SetStateAction } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, SvgIcon, Text } from "@new-black/lyra";

import packageJson from "../../../../package.json";

import { MenuState } from "./menu.types";
import { MenuProfile } from "./menu-profile";

type MenuFooterProps = {
  menuState: MenuState;
  setMenuState: (newValue: MenuState) => void;
  setMouseEntered: Dispatch<SetStateAction<boolean>>;
  setIsFocused: Dispatch<SetStateAction<boolean>>;
};
export const MenuFooter = ({
  menuState,
  setIsFocused,
  setMenuState,
  setMouseEntered,
}: MenuFooterProps) => {
  return (
    <div className="flex h-[65px] w-full items-center justify-between gap-4 border-t border-solid border-default bg-surface-tertiary p-4">
      <MenuOpenCloseButton menuState={menuState} setMenuState={setMenuState} />
      <VersionText />
      <MenuProfile
        menuState={menuState}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
      />
    </div>
  );
};

interface MenuOpenCloseButtonProps {
  menuState: MenuState;
  setMenuState: (newValue: MenuState) => void;
}

const MenuOpenCloseButton = ({
  menuState: navbarState,
  setMenuState: setNavbarState,
}: MenuOpenCloseButtonProps) => {
  const intl = useIntl();
  return (
    <Button
      variant="icon"
      tooltip={
        navbarState === "closed" || navbarState === "hovered"
          ? intl.formatMessage({
              id: "generic.label.expand-sidebar",
              defaultMessage: "Collapse sidebar",
            })
          : intl.formatMessage({
              id: "generic.label.collapse-sidebar",
              defaultMessage: "Expand sidebar",
            })
      }
      onPress={() => {
        if (navbarState === "closed" || navbarState === "hovered") {
          setNavbarState("open");
        } else {
          setNavbarState("closed");
        }
      }}
    >
      {navbarState === "closed" || navbarState === "hovered" ? (
        <SvgIcon name="sidebar-left-collapsed" />
      ) : (
        <SvgIcon name="sidebar-left" />
      )}
    </Button>
  );
};

const VersionText = () => {
  return (
    <Text variant="body-small" color="secondary" className="truncate">
      <FormattedMessage id="generic.label.version" defaultMessage="Version" /> {packageJson.version}
    </Text>
  );
};
