import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: ListFlightsLoaderQuery,
  serviceQuery: ListFlightsQuery,
  serviceQueryKeys: ListFlightsQueryKeys,
  useServiceQueryHook: useListFlightsQuery,
  useServiceQueryWithRequest: useListFlightsQueryWithRequest,
} = createServiceQuery(Core.ListFlights);
