import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCustomFieldsQuery, listCustomFieldsQueryKeys } from "models/custom-fields";
import { CustomFieldDataType } from "types/custom-field-data-types";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

import { useCustomFieldByID } from "../hooks/use-custom-field-by-id";

export interface CustomFieldLyraSearchListFieldItem {
  Name: string;
  ID: number;
  DataType?: CustomFieldDataType;
  IsArray?: boolean;
  DisplayName?: string;
}

export const generateCustomFieldLyraSearchListField = (
  initialRequest: EVA.Core.Management.ListCustomFields,
  getLabel?: (item: CustomFieldLyraSearchListFieldItem) => string,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListCustomFields,
    CustomFieldLyraSearchListFieldItem,
    number
  >({
    getItemFromResponse: (response) =>
      response?.Result?.Page?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name,
        ID: item.ID,
        DataType: (item?.DataType as number) ?? undefined,
        IsArray: item?.IsArray ?? undefined,
        DisplayName: item.DisplayName,
      })) ?? [],
    getItemId: (item) => item.ID,
    getLabel: getLabel ?? ((item) => item.DisplayName ?? item.Name),
    selectRenderElements: (item) => ({
      label: getLabel ? getLabel(item) : item.DisplayName ?? item.Name,
    }),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.custom-field",
      defaultMessage: "Custom Field",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listCustomFieldsQuery,
        queryKey: (req) => listCustomFieldsQueryKeys.withRequest(req, ["autocomplete"]),
        initialRequest: {
          ...initialRequest,
          PageConfig: {
            ...initialRequest.PageConfig,
            Limit: initialRequest.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          PageConfig: {
            ...prev?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...prev?.PageConfig?.Filter, Name: newValue },
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    useItemByID: (id) => {
      const { customField, isLoading } = useCustomFieldByID(id);

      const result = useMemo(
        () => ({
          data: customField
            ? { ...customField, DataType: customField?.DataType as number }
            : undefined,
          isLoading,
        }),
        [customField, isLoading],
      );

      return result;
    },
  });

export const generateCustomFieldSearchListField = (
  initialRequest: EVA.Core.Management.ListCustomFields,
  frontendFilter?: (item: CustomFieldLyraSearchListFieldItem) => boolean,
) =>
  SearchListFieldGenerator<
    CoreManagement.ListCustomFields,
    CustomFieldLyraSearchListFieldItem,
    "ID"
  >({
    getItemFromResponse: (response) =>
      response?.Result?.Page?.filter((item) => item.Name && item.ID)?.map((item) => ({
        DisplayName: item.DisplayName ?? item.Name,
        Name: item.Name,
        ID: item.ID,
        DataType: (item?.DataType as number) ?? undefined,
        IsArray: item?.IsArray ?? undefined,
      })) ?? [],
    idKey: "ID",
    labelKey: "DisplayName",
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.custom-field",
      defaultMessage: "Custom Field",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listCustomFieldsQuery,
        queryKey: (req) => listCustomFieldsQueryKeys.withRequest(req, ["autocomplete"]),
        initialRequest: {
          ...initialRequest,
          PageConfig: {
            ...initialRequest.PageConfig,
            Limit: initialRequest.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          PageConfig: {
            ...prev?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...prev?.PageConfig?.Filter, Name: newValue },
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    useItemByID: (id) => {
      const { customField, isLoading } = useCustomFieldByID(id);

      const item = useMemo(
        () => ({
          data: customField
            ? {
                ...customField,
                DisplayName: customField.DisplayName ?? customField.Name,
                DataType: customField?.DataType as number,
              }
            : undefined,
          isLoading,
        }),
        [customField, isLoading],
      );

      return item;
    },
    frontendFilter,
  });
