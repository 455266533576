import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Menu, MenuTrigger } from "@new-black/lyra";

import AddActionButton from "components/shared/action-buttons/add-action-button";

import { useProductFilterContext } from "../product-filter-context";

import { ProductFilterAddBulkProductsModal } from "./product-filter-add-bulk-products-modal";

export const LyraProductFilterAddProductsAction = () => {
  const intl = useIntl();

  const [showBulkProductsModal, setShowBulkProductsModal] = useState(false);

  const {
    isUpdateProductFilterLoading,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();

  const menuItems = useMemo(
    () => [
      {
        id: "add-products-bulk",
        label: intl.formatMessage({
          id: "product-filter.actions.add-products.menu.add-bulk-products",
          defaultMessage: "Add products in bulk",
        }),
        action: () => {
          setShowBulkProductsModal(true);
        },
      },
      {
        id: "add-product-filter",
        label: intl.formatMessage({
          id: "product-filter.actions.add-products.menu.add-product-filter",
          defaultMessage: "Add product filter",
        }),
        action: () => {
          setOpenProductFilterModal(true);
          setCurrentProductFilterModalValues({ keyName: undefined });
        },
      },
    ],
    [intl, setCurrentProductFilterModalValues, setOpenProductFilterModal],
  );

  return (
    <>
      <MenuTrigger>
        <AddActionButton />

        <Menu
          placement="bottom start"
          items={menuItems}
          aria-label={intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })}
          getItemId={(item) => item.id}
          selectRenderElements={(item) => ({ label: item.label })}
          onAction={(id) => menuItems.find((item) => item.id === id)?.action()}
        />
      </MenuTrigger>

      <ProductFilterAddBulkProductsModal
        isOpen={showBulkProductsModal}
        onClose={() => setShowBulkProductsModal(false)}
        isLoading={isUpdateProductFilterLoading}
        currentProductFilter={productFilter}
        onSave={updateProductFilter}
      />
    </>
  );
};
