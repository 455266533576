import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox, FieldGroup, Text, TextField } from "@new-black/lyra";

import {
  ConfirmTwoFactorAuthenticationTokenFormFieldsProps,
  confirmTwoFactorAuthenticationTokenFormKeys,
} from "./confirm-two-factor-authentication-token-form-fields.types";

const formKeys = confirmTwoFactorAuthenticationTokenFormKeys;

export const ConfirmTwoFactorAuthenticationTokenFormFields = ({
  authenticatorProviderName,
  errors,
  loginData,
}: ConfirmTwoFactorAuthenticationTokenFormFieldsProps) => {
  const intl = useIntl();

  const description = useMemo(() => {
    switch (authenticatorProviderName) {
      case "Email":
        return intl.formatMessage({
          id: "two-factor-authentication.login.email.description",
          defaultMessage: "We just sent an email with the confirmation token.",
        });
      case "SMS":
        return intl.formatMessage({
          id: "two-factor-authentication.login.sms.description",
          defaultMessage: "We just sent you a text message with the confirmation token.",
        });
      case "TOTP":
        return intl.formatMessage({
          id: "two-factor-authentication.login.totp.description",
          defaultMessage: "Please enter the confirmation token from your authenticator app.",
        });
      default:
        return undefined;
    }
  }, [authenticatorProviderName, intl]);

  return (
    <>
      {description && (
        <div className="-mt-4 mb-4">
          <Text variant="body-medium">{description}</Text>
        </div>
      )}
      <FieldGroup>
        <TextField
          name={formKeys.TwoFactorAuthenticationToken}
          label={intl.formatMessage({
            id: "generic.label.confirmation-token",
            defaultMessage: "Confirmation token",
          })}
          isRequired
          autoFocus
          errorMessage={errors?.formErrors?.TwoFactorAuthenticationToken}
        />
        <Checkbox name={formKeys.TwoFactorAuthenticationRememberMe}>
          <FormattedMessage id="generic.label.remember-me" defaultMessage="Remember me" />
        </Checkbox>
        <input
          type="hidden"
          name={formKeys.UserName}
          value={loginData?.Nickname ?? loginData?.EmailAddress ?? ""}
        />
        <input
          type="hidden"
          name={formKeys.AuthenticationToken}
          value={loginData?.AuthenticationToken ?? ""}
        />
        <input
          type="hidden"
          name={formKeys.TwoFactorAuthenticator}
          value={authenticatorProviderName}
        />
      </FieldGroup>
      {!errors?.formErrors?.TwoFactorAuthenticationToken &&
      errors?.formErrors?.TwoFactorAuthenticator ? (
        <Text variant="body-small" color="error">
          {errors?.formErrors?.TwoFactorAuthenticator}
        </Text>
      ) : null}
      {!errors?.formErrors?.TwoFactorAuthenticationToken &&
      errors?.formErrors?.AuthenticationToken ? (
        <Text variant="body-small" color="error">
          {errors?.formErrors?.AuthenticationToken}
        </Text>
      ) : null}
      {!errors?.formErrors?.TwoFactorAuthenticationToken && errors?.formErrors?.UserName ? (
        <Text variant="body-small" color="error">
          {errors?.formErrors?.UserName}
        </Text>
      ) : null}
      {errors?.serviceError ? (
        <Text variant="body-small" color="error">
          {errors?.serviceError}
        </Text>
      ) : null}
    </>
  );
};
