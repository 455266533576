import { useMemo } from "react";
import { useIntl } from "react-intl";

import { SearchListField } from "@new-black/lyra";

import { UserAccountType } from "types/eva-core";
import enumToArray from "util/enum-to-array";

interface UserAccountTypeSearchListField {
  value?: number;
  onChange: (val?: number) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const UserAccountTypeSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: UserAccountTypeSearchListField) => {
  const intl = useIntl();

  const items = useMemo(() => enumToArray(UserAccountType), []);

  const selectedValue = useMemo(() => items?.find((item) => item.value === value), [items, value]);

  return (
    <SearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.value}
      getLabel={(item) => item.key}
      selectRenderElements={(item) => ({ label: item.key })}
      value={selectedValue}
      items={items ?? []}
      onChange={(newValue) => onChange(newValue?.value)}
      label={intl.formatMessage({
        id: "generic.label.account-type",
        defaultMessage: "Account type",
      })}
      errorMessage={errorMessage}
    />
  );
};
