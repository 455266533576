export interface IProductSetProduct {
  ID: number;
  Name: string;
  ImageBlobID?: string;
  CustomID?: string;
  BackendID?: string;
  Quantity?: number;
  Barcode?: string;
}
export interface IProductSetFilter extends EVA.Core.FilterModel {
  ProductProperty?: string;
}

export type IDProperty = "ID" | "CustomID" | "BackendID";

export const PRODUCT_SET_MODAL_HEIGHT = 640;
export const PRODUCT_SET_MODAL_AVAILABLE_ITEMS_HEADER_HEIGHT = 51;
export const PRODUCT_SET_MODAL_TITLE_HEIGHT = 68;

export const PRODUCT_SET_MODAL_SUB_HEADER_HEIGHT = 40;

export const LIST_ITEM_SIZE = 61;
export const BARCODE_INPUT_SIZE = 55;
