import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useTransition,
} from "react";
import { Outlet, OutletProps, To, useLocation, useNavigation } from "react-router-dom";

import { Dialog, DialogProps } from "@material-ui/core";
import {
  Card,
  Dialog as LyraDialog,
  DialogProps as LyraDialogProps,
  DialogTrigger,
} from "@new-black/lyra";

import LoadingStateBox, { ILoadingStateBox } from "./suite-ui/loading-state-box";
import { NavigateOptionsWithForce, useNavigate } from "./routing";

type DialogOutletPropVariants =
  | ({
      variant?: "material";
    } & Omit<DialogProps, "children" | "open" | "onClose">)
  | ({
      variant: "lyra";
    } & Omit<LyraDialogProps, "children" | "isOpen" | "onOpenChange">);

export type DialogOutletProps = DialogOutletPropVariants &
  OutletProps & {
    loadingStateBoxProps?: ILoadingStateBox;
    loadingFallback?: ReactNode;
    keepSearchParams?: boolean;
  };

export type DialogOutletContextProps = {
  /** Dialog is open state */
  open: boolean;
  /** Set dialog open state */
  setOpen: (open: boolean) => void;
  /** The base path of the place where the DialogOutlet is situated. */
  basePath: string;
  /** Navigate to the base path of the DialogOutlet. Typically used for cancel buttons */
  navigateToBasePath: (options?: NavigateOptionsWithForce) => void;
  /** Navigate to a route that should be shown inside the dialog and open the dialog */
  openAndNavigate: (
    to: number | To,
    options?: NavigateOptionsWithForce & { onPress?: () => void },
  ) => void;
  /** Can navigate to other routes */
  canNavigate: boolean;
  /** Is the dialog initially loading */
  isInitiallyLoading: boolean;
  /** Set the isInitiallyLoading state */
  setIsInitiallyLoading: (isInitiallyLoading: boolean) => void;
};

/**
 * Context for managing the state of the dialog outlet.
 */
const DialogOutletContext = createContext<DialogOutletContextProps | undefined>(undefined);

type DialogOutletProviderProps = DialogOutletProps & {
  basePath: DialogOutletContextProps["basePath"];
  /** Enhanced support for the render prop pattern is also provided.*/
  children: ReactNode | ((props: DialogOutletContextProps) => ReactNode);
  /** On default a  loadingStateBox will be shown, but you can provide your own loadingFallback as well */
  loadingFallback?: ReactNode;
};

export const DialogOutletProvider = ({
  basePath,
  children,
  ...props
}: DialogOutletProviderProps) => {
  const location = useLocation();
  // The dialog is open if the current path is not the base path.
  const [open, setOpen] = useState(location.pathname !== basePath);
  const [isInitiallyLoading, setIsInitiallyLoading] = useState(true);
  const { state } = useNavigation();

  const canNavigate = state === "idle";

  const navigate = useNavigate();

  const [, startNavigateToBasePathTransition] = useTransition();
  /**
   * Navigates to the base path if the state is "idle".
   * Closes the dialog, sets isInitiallyLoading to true, and navigates to the base path.
   */
  const navigateToBasePath = useCallback(
    (options?: NavigateOptionsWithForce) => {
      startNavigateToBasePathTransition(() => {
        if (state === "idle") {
          setOpen(false);
          setIsInitiallyLoading(true);
          navigate(basePath, options);
        }
      });
    },
    [basePath, navigate, state],
  );

  const [, startNavigateAndOpenTransition] = useTransition();
  /**
   * Navigates to the given route if the state is "idle" and opens the dialog.
   */
  const openAndNavigate = useCallback<
    (to: number | To, options?: NavigateOptionsWithForce & { onPress?: () => void }) => void
  >(
    (to, options) => {
      startNavigateAndOpenTransition(() => {
        if (state === "idle") {
          options?.onPress?.();
          setOpen(true);
          navigate(to, options);
        }
      });
    },
    [navigate, state],
  );
  const [, startEffectTransition] = useTransition();

  /**
   * If the location pathname is the base path and the dialog is open, close the dialog.
   */
  useEffect(() => {
    if (location.pathname === basePath && state === "idle" && open) {
      startEffectTransition(() => {
        setOpen(false);
        /** Dialog gets closed, so next time we open it, we need the initiallyIsLoading propr to be set to true so it can render loading state */
        setIsInitiallyLoading(true);
      });
    }
  }, [basePath, location.pathname, open, state]);

  /**  If the previous navigation state was loading and the current state is idle and the dialog is open, the dialog is no longer initially loading.*/
  useEffect(() => {
    if (state === "idle" && open) {
      setIsInitiallyLoading(false);
    }
  }, [open, state]);

  return (
    <DialogOutletContext.Provider
      value={{
        open,
        setOpen,
        basePath,
        navigateToBasePath,
        canNavigate,
        openAndNavigate,
        isInitiallyLoading,
        setIsInitiallyLoading,
      }}
    >
      {typeof children === "function"
        ? children({
            open,
            setOpen,
            basePath,
            navigateToBasePath,
            canNavigate,
            openAndNavigate,
            isInitiallyLoading,
            setIsInitiallyLoading,
          })
        : children}
      <DialogOutlet {...props} />
    </DialogOutletContext.Provider>
  );
};

const DialogSkeleton = (
  props: ILoadingStateBox & { variant?: DialogOutletPropVariants["variant"] },
) => {
  const { state } = useNavigation();

  if (state === "loading") {
    return props.variant === "lyra" ? (
      <Card>
        <LoadingStateBox {...props} />
      </Card>
    ) : (
      <LoadingStateBox {...props} />
    );
  }
  return null;
};

const DialogOutlet = ({
  context,
  keepSearchParams,
  loadingFallback,
  loadingStateBoxProps = { limit: 5, headerAvailable: true, footerAvailable: true },
  ...props
}: DialogOutletProps) => {
  const { canNavigate, isInitiallyLoading, navigateToBasePath, open } = useDialogOutletContext();

  const loading = useCallback(() => {
    if (loadingFallback) {
      return loadingFallback;
    } else {
      return <DialogSkeleton {...loadingStateBoxProps} variant={props.variant} />;
    }
  }, [loadingFallback, loadingStateBoxProps, props.variant]);

  if (props.variant === "lyra") {
    return (
      <DialogTrigger>
        <LyraDialog
          {...props}
          isOpen={open}
          onOpenChange={
            canNavigate
              ? () => {
                  navigateToBasePath({ keepSearchParams });
                }
              : undefined
          }
        >
          {isInitiallyLoading ? loading() : <Outlet context={context} />}
        </LyraDialog>
      </DialogTrigger>
    );
  }

  return (
    <Dialog
      {...props}
      open={open}
      onClose={
        canNavigate
          ? () => {
              navigateToBasePath({ keepSearchParams });
            }
          : undefined
      }
    >
      {isInitiallyLoading ? loading() : <Outlet context={context} />}
    </Dialog>
  );
};

export const useDialogOutletContext = () => {
  const context = useContext(DialogOutletContext);
  if (context === undefined) {
    throw new Error("useDialogOutletContext must be used within a DialogOutletProvider");
  }
  return context;
};
