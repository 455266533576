import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SegmentedControl, SegmentedControlItem, SegmentedControlProps } from "@new-black/lyra";

import { TSingleMultiOptionValue } from "../types";

interface ISingleMultiSwitchProps
  extends Pick<SegmentedControlProps<any>, "hideInputHeader" | "hideHintLabel"> {
  resetSelection: () => void;
  activeOption: TSingleMultiOptionValue;
  setActiveOption: (newValue: TSingleMultiOptionValue) => void;
  label?: string;
  optionsLabels?: { single?: string; multi?: string };
}

const SingleMultiSwitch = ({
  activeOption,
  hideHintLabel,
  hideInputHeader,
  label,
  optionsLabels,
  resetSelection,
  setActiveOption,
}: ISingleMultiSwitchProps) => {
  const intl = useIntl();

  const handleChange = useCallback(
    (newValue: TSingleMultiOptionValue | undefined) => {
      if (newValue) {
        resetSelection();
        setActiveOption(newValue);
      }
    },
    [setActiveOption, resetSelection],
  );

  return (
    <SegmentedControl
      value={activeOption}
      onChange={(newValue) => {
        const val = newValue as TSingleMultiOptionValue | undefined;
        handleChange(val);
      }}
      label={
        label ??
        intl.formatMessage({
          id: "ouset-selector.switch.label",
          defaultMessage: "Select a single or multiple organization units",
        })
      }
      hideInputHeader={hideInputHeader}
      hideHintLabel={hideHintLabel}
    >
      <SegmentedControlItem
        value="single"
        textValue={
          optionsLabels?.single ??
          intl.formatMessage({
            id: "generic.label.single-organization-unit",
            defaultMessage: "Single organization unit",
          })
        }
      >
        {optionsLabels?.single ? (
          optionsLabels.single
        ) : (
          <FormattedMessage
            id="ouset-selector.switch.single"
            defaultMessage="Single organization unit"
          />
        )}
      </SegmentedControlItem>
      <SegmentedControlItem
        value="multi"
        textValue={
          optionsLabels?.multi ??
          intl.formatMessage({
            id: "generic.label.organization-unit-sets",
            defaultMessage: "Organization unit sets",
          })
        }
      >
        {optionsLabels?.multi ? (
          optionsLabels.multi
        ) : (
          <FormattedMessage
            id="ouset-selector.switch.multi"
            defaultMessage="Organization unit sets"
          />
        )}
      </SegmentedControlItem>
    </SegmentedControl>
  );
};

export default SingleMultiSwitch;
