import { PIM } from "@springtree/eva-services-pim";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getProductRelationTypeByIdLoaderQuery,
  serviceQuery: getProductRelationTypeByIdQuery,
  serviceQueryKeys: getProductRelationTypeByIdKeys,
  useServiceQueryHook: useGetProductRelationTypeByIdQuery,
} = createServiceQuery(PIM.GetProductRelationTypeByID, true);
