import { useCallback, useMemo, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { Autocomplete, FilterOptionsState } from "@material-ui/lab";
import { Core } from "@springtree/eva-services-core";
import Fuse from "fuse.js";

import { Autocomplete as EvaAutocomplete } from "components/suite-ui/autocomplete";
import Chip from "components/suite-ui/chip";
import Input from "components/suite-ui/input";
import { listCountriesQuery } from "models/countries";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { ONE_HOUR } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "util/lyra-search-list-field-generator/search-list-field-generator.types";
import { SearchListFieldGenerator } from "util/search-list-field-generator";

import {
  ICountrySelectorFormikProps,
  ICountrySelectorStateProps,
  ICountrySelectorTextProps,
} from "./types";
import useCountrySelector from "./use-country-selector";

export const CountrySelector = ({
  autoComplete,
  autoFocus,
  chipOnClick,
  defaultHelperText,
  disableCloseOnSelect,
  endIcon,
  error,
  helperText,
  label,
  name,
  onBlur,
  passive,
  placeholder,
  required,
  small,
  ...stateProps
}: ICountrySelectorTextProps & ICountrySelectorFormikProps & ICountrySelectorStateProps) => {
  const [focused, setFocused] = useState(false);

  const { multi } = stateProps;
  const {
    countrySelectorItems,
    handleDeselectMultiCountry,
    handleSelectMultiCountry,
    handleSelectSingleCountry,
    isLoading,
    selectedMultiCountries,
    selectedSingleCountry,
  } = useCountrySelector(stateProps);

  const filterOptions = useCallback(
    (optionIDs: string[], state: FilterOptionsState<string>) => {
      const fuse = new Fuse(
        (countrySelectorItems ?? []).filter((country) => optionIDs.includes(country.ID)),
        {
          keys: ["Name", "ID"],
          threshold: 0.2,
          useExtendedSearch: true,
        },
      );
      return (
        (state.inputValue === ""
          ? countrySelectorItems?.map((ouSet) => ouSet.ID)
          : fuse.search(state.inputValue).map((result) => result.item.ID)) ?? []
      );
    },
    [countrySelectorItems],
  );

  return passive ? (
    <EvaAutocomplete
      passive
      small={small}
      multi={multi}
      error={error}
      label={label}
      onBlur={onBlur}
      optionIDKey="ID"
      helperText={helperText}
      matchKeys={["ID", "Name"]}
      renderOptionValueKey="Name"
      items={countrySelectorItems ?? []}
      renderTag={
        multi
          ? (item) => (
              <div className="my-[3px] mr-1.5">
                <Chip>{item.Name}</Chip>
              </div>
            )
          : undefined
      }
      controlledSelectedItem={(multi ? selectedMultiCountries : selectedSingleCountry) ?? ""}
    />
  ) : (
    <div className="group mt-[-4px]">
      <Autocomplete
        clearOnBlur
        autoComplete
        autoSelect={false}
        selectOnFocus
        autoHighlight
        handleHomeEndKeys
        includeInputInList
        disableCloseOnSelect={disableCloseOnSelect}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={(event) => {
          setFocused(false);
          onBlur?.(event);
        }}
        loading={isLoading}
        multiple={multi ?? false}
        filterOptions={filterOptions}
        id={name ?? "country-autocomplete"}
        key={name ?? "country-autocomplete"}
        options={countrySelectorItems?.map((item) => item.ID) ?? []}
        getOptionSelected={(option, currentValue) => option === currentValue}
        onInputChange={(_, newInputValue) => {
          const options = newInputValue.split(",");
          if (options?.length > 1 && multi) {
            const current = selectedMultiCountries ?? [];
            const newOptions = options
              .map((option) =>
                countrySelectorItems?.find(
                  (country) => country.Name === option.trim() || `${country.ID}` === option.trim(),
                ),
              )
              .filter((country): country is EVA.Core.CountryDto => !!country);
            handleSelectMultiCountry([...current, ...newOptions].map((country) => country.ID));
          }
        }}
        value={
          multi
            ? selectedMultiCountries?.map((country) => country.ID) ?? []
            : selectedSingleCountry?.ID ?? null
        }
        onChange={(_, newValue) => {
          if (multi) {
            handleSelectMultiCountry((newValue ?? []) as string[]);
            return;
          }
          handleSelectSingleCountry((newValue ?? undefined) as string | undefined);
        }}
        getOptionLabel={(option) =>
          countrySelectorItems?.find((country) => country.ID === option)?.Name ?? ""
        }
        renderTags={(value, getTagProps) =>
          multi ? (
            <div className="mb-1 mt-2 flex flex-wrap gap-1">
              {value.map((option, index) => (
                <Chip
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  color={focused ? "primary" : "default"}
                  {...getTagProps({ index })}
                  onDelete={(event) => {
                    event?.stopPropagation();
                    handleDeselectMultiCountry(option);
                  }}
                  onClick={() => chipOnClick?.(option)}
                >
                  {countrySelectorItems?.find((country) => country.ID === option)?.Name ?? ""}
                </Chip>
              ))}
            </div>
          ) : null
        }
        renderInput={(params) => (
          <Input
            {...params}
            name={name}
            small={small}
            error={error}
            label={label}
            required={required}
            autoFocus={autoFocus}
            placeholder={placeholder}
            helperText={helperText ?? defaultHelperText}
            InputProps={{
              ...params.InputProps,
              inputProps: { ...params.inputProps, autoComplete },
              endAdornment: (
                <>
                  {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                  {!isLoading && endIcon ? (
                    <div className={focused ? "opacity-100" : "opacity-0 group-hover:opacity-100"}>
                      {endIcon}
                    </div>
                  ) : null}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export const {
  MultiAutocomplete: MultiCountryAutocomplete,
  // GeneratedMultiCountryAutocomplete.Controlled
  // GeneratedMultiCountryAutocomplete.Uncontrolled
  // GeneratedMultiCountryAutocomplete.Formik
  // GeneratedMultiCountryAutocomplete.Recoil
  MultiIDAutocomplete: MultiCountryIDAutocomplete,
  // GeneratedSingleCountryAutocomplete.Controlled
  // GeneratedSingleCountryAutocomplete.Uncontrolled
  // GeneratedSingleCountryAutocomplete.Formik
  // GeneratedSingleCountryAutocomplete.Recoil
  SingleAutocomplete: SingleCountryAutocomplete,
  // GeneratedSingleCountryIDAutocomplete.Controlled
  // GeneratedSingleCountryIDAutocomplete.Uncontrolled
  // GeneratedSingleCountryIDAutocomplete.Formik
  // GeneratedSingleCountryIDAutocomplete.Recoil
  SingleIDAutocomplete: SingleCountryIDAutocomplete,
  // GeneratedMultiCountryIDAutocomplete.Controlled
  // GeneratedMultiCountryIDAutocomplete.Uncontrolled
  // GeneratedMultiCountryIDAutocomplete.Formik
  // GeneratedMultiCountryIDAutocomplete.Recoil
} = AutocompleteGenerator<Core.ListCountries, { ID: string; Name: string }>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (resp) =>
    resp?.Result?.map((country) => ({ ID: country.ID, Name: country.Name })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.country",
    defaultMessage: "Country",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      staleTime: ONE_HOUR,
      refetchOnFocus: false,
      query: listCountriesQuery,
      initialRequest: { FetchAll: true },
    }),
  useItemByID: (id, allCountries) => {
    const data = useMemo(
      () => allCountries?.find((country) => country.ID === id),
      [allCountries, id],
    );
    return { data, isLoading: false };
  },
  useItemsByID(ids, allCountries) {
    const data = useMemo(
      () =>
        ids
          ?.map((id) => allCountries?.find((country) => country.ID === id))
          ?.filter((country): country is { ID: string; Name: string } => !!country),
      [ids, allCountries],
    );
    return { data, isLoading: false };
  },
});

export const {
  MultiIDSearchListField: MultiCountryIDSearchListField,
  MultiSearchListField: MultiCountrySearchListField,
  SingleIDSearchListField: SingleCountryIDSearchListField,
  SingleSearchListField: SingleCountrySearchListField,
} = SearchListFieldGenerator<Core.ListCountries, { ID: string; Name: string }>({
  idKey: "ID",
  labelKey: "Name",
  filterOptions: { filterKeys: ["ID", "Name"] },
  getItemFromResponse: (resp) =>
    resp?.Result?.map((country) => ({ ID: country.ID, Name: country.Name })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.country",
    defaultMessage: "Country",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      staleTime: ONE_HOUR,
      refetchOnFocus: false,
      query: listCountriesQuery,
      initialRequest: { FetchAll: true },
    }),
  useItemByID: (id, allCountries) => {
    const data = useMemo(
      () => allCountries?.find((country) => country.ID === id),
      [allCountries, id],
    );
    return { data, isLoading: false };
  },
  useItemsByID(ids, allCountries) {
    const data = useMemo(
      () =>
        ids
          ?.map((id) => allCountries?.find((country) => country.ID === id))
          ?.filter((country): country is { ID: string; Name: string } => !!country),
      [ids, allCountries],
    );
    return { data, isLoading: false };
  },
});

export const generateLyraCountrySearchListField = (
  props?: Partial<
    ISearchListFieldGeneratorProps<Core.ListCountries, { ID: string; Name: string }, string>
  >,
) =>
  LyraSearchListFieldGenerator<Core.ListCountries, { ID: string; Name: string }, string>({
    getItemId: props?.getItemId ?? ((item) => item.ID),
    getLabel: props?.getLabel ?? ((item) => item.Name),
    getItemFromResponse: (resp) =>
      resp?.Result?.map((country) => ({ ID: country.ID, Name: country.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.country",
      defaultMessage: "Country",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        staleTime: ONE_HOUR,
        refetchOnFocus: false,
        query: listCountriesQuery,
        initialRequest: { FetchAll: true },
      }),
    useItemByID: (id, allCountries) => {
      const data = useMemo(
        () => allCountries?.find((country) => country.ID === id),
        [allCountries, id],
      );
      return { data, isLoading: false };
    },
    useItemsByID: (ids, allCountries) => {
      const data = useMemo(
        () =>
          ids
            ?.map((id) => allCountries?.find((country) => country.ID === id))
            ?.filter((country): country is { ID: string; Name: string } => !!country),
        [ids, allCountries],
      );
      return { data, isLoading: false };
    },
    ...props,
  });

export const CountryIDLyraSearchListField = generateLyraCountrySearchListField();
export const CountryLyraSearchListFieldWithFormattedNames = generateLyraCountrySearchListField({
  getLabel: (country) =>
    `${intlAccessor.formatDisplayName(country.ID, { type: "region" })} (${country.ID})`,
});
