import { ReactNode, useMemo } from "react";
import { useIntl } from "react-intl";

import { TableProps } from "@new-black/lyra";

import useConvertEVAFilterToArray, {
  IEVAFilterArrayItem,
} from "../hooks/use-convert-filter-to-array";
import { useProductFilterContext } from "../product-filter-context";

import useListTableColumns from "./use-list-table-columns";

const useListTableSettings = (customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode) => {
  const intl = useIntl();

  const { productFilter } = useProductFilterContext();
  const filterToArr = useConvertEVAFilterToArray(productFilter);

  const columns = useListTableColumns(customRowActions);

  const listConfig = useMemo<TableProps<any>>(() => {
    return {
      "aria-label": intl.formatMessage({
        id: "generic.label.product-filters",
        defaultMessage: "Product filters",
      }),
      data: filterToArr ?? [],
      columns,
      limit: (filterToArr?.length ?? 0) < 10 ? 10 : filterToArr?.length,
      start: 0,
      total: filterToArr?.length ?? 0,
    };
  }, [columns, filterToArr, intl]);

  return listConfig;
};

export default useListTableSettings;
