import { useMemo, useState } from "react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";

import { TableColumnDef } from "@new-black/lyra";

import useFilteredSubsets from "./use-filtered-subsets";
import useOUSetDetails from "./use-ou-set-details";

export function getOUSetSubsetTypeTranslationFunction(intl: IntlShape) {
  const translationMapping: Record<EVA.Core.OrganizationUnitSetTypes, string> = {
    0: intl.formatMessage({
      id: "ouset-subsets-list.table.headers.type.0",
      defaultMessage: "System",
    }),
    1: intl.formatMessage({
      id: "ouset-subsets-list.table.headers.type.1",
      defaultMessage: "Custom",
    }),
    2: intl.formatMessage({
      id: "ouset-subsets-list.table.headers.type.2",
      defaultMessage: "Ad-hoc",
    }),
  };
  return (type: EVA.Core.OrganizationUnitSetTypes) => translationMapping[type];
}

export default function useOUSetSubsetsTableConfig(ouSetId: number) {
  const intl = useIntl();
  const { ouSetDetails, subsets } = useOUSetDetails(ouSetId);

  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);

  const { filteredSubsets, setTerm, term } = useFilteredSubsets(subsets);

  const getOUSetSubsetTypeTranslation = useMemo(
    () => getOUSetSubsetTypeTranslationFunction(intl),
    [intl],
  );

  const tableColumns = useMemo<TableColumnDef<EVA.Core.Management.OrganizationUnitSetDetails>[]>(
    () => [
      {
        header: intl.formatMessage({
          id: "generic.label.id",
          defaultMessage: "ID",
        }),
        accessorKey: "ID",
        id: "ID",
      },
      {
        header: intl.formatMessage({
          id: "generic.label.organization-unit-id",
          defaultMessage: "Organization unit ID",
        }),
        accessorKey: "OrganizationUnitID",
        id: "OrganizationUnitID",
      },
      {
        header: intl.formatMessage({
          id: "generic.label.name",
          defaultMessage: "Name",
        }),
        accessorKey: "Name",
        id: "Name",
      },
      {
        header: intl.formatMessage({
          id: "ouset-subsets-list.table.headers.type",
          defaultMessage: "Type",
        }),
        accessorKey: "Type",
        id: "Type",
        cell: ({ getValue }) => getOUSetSubsetTypeTranslation(getValue()),
      },
      {
        header: intl.formatMessage({
          id: "ouset-subsets-list.table.headers.has-subsets",
          defaultMessage: "Has Subsets",
        }),
        id: "HasSubsets",
        accessorKey: "ID",
        cell: ({ row }) =>
          row.original.Subsets?.length ? (
            <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
          ) : (
            <FormattedMessage id="generic.label.no" defaultMessage="No" />
          ),
      },
    ],
    [getOUSetSubsetTypeTranslation, intl],
  );

  const tableConfig = useMemo(
    () => ({
      start,
      limit,
      setStart,
      setLimit,
      data: filteredSubsets,
      columns: tableColumns,
      total: filteredSubsets.length,
    }),
    [start, limit, setStart, setLimit, tableColumns, filteredSubsets],
  );

  return {
    tableConfig,
    term,
    setTerm,
    setStart,
    title: intl.formatMessage(
      {
        id: "generic.key-value.overview-of-organization-units-in-set",
        defaultMessage: "Overview of organization units in set {ouSetName}",
      },
      {
        ouSetName: ouSetDetails?.Name ?? "",
      },
    ),
  };
}
