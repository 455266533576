import { FormattedMessage } from "react-intl";

import Text from "components/suite-ui/text";

import TransputJobDocument from "./transput-job-document";

interface ITransputJobDocumentsProps {
  type: "requests" | "responses";
  documents: EVA.Core.TransputJobDocument[];
}

const TransputJobDocuments = ({ documents, type }: ITransputJobDocumentsProps) => (
  <div>
    <div className={type === "responses" && !documents.length ? undefined : "pb-5"}>
      <Text variant="h4">
        <FormattedMessage
          id={`generic.label.${type}${documents.length ? "" : "-none"}`}
          defaultMessage={`${type.charAt(0).toUpperCase()}${type.slice(1)}${
            documents.length ? "" : " (none)"
          }`}
        />
      </Text>
    </div>
    {documents.map((document, index, arr) => (
      <div key={document.CreationTime} className={index < arr.length - 1 ? "pb-5" : undefined}>
        <TransputJobDocument document={document} />
      </div>
    ))}
  </div>
);

export default TransputJobDocuments;
