export type ScriptSelectorOption = "existing" | "custom";

export type IScriptSelectorPrivateScript = Pick<
  EVA.Core.GetScriptResponse,
  "ID" | "Name" | "Source" | "RevisionComment"
> & {
  IsPrivate?: boolean;
};

export const DEFAULT_PRIVATE_SCRIPT_NAME = "Private script";
