import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ComplianceIconInner } from "assets/icons/components/module-icons/compliance-icon";
import { CRMIconInner } from "assets/icons/components/module-icons/crm-icon";
import { FinancialsInnerIcon } from "assets/icons/components/module-icons/financials-icon";
import { MonitorsInnerIcon } from "assets/icons/components/module-icons/monitors-icon";
import { OrdersIconInner } from "assets/icons/components/module-icons/orders-icon";
import { OrganizationsInnerIcon } from "assets/icons/components/module-icons/organizations-icon";
import { PIMInnerIcon } from "assets/icons/components/module-icons/pim-icon";
import { PromotionsInnerIcon } from "assets/icons/components/module-icons/promotions-icon";
import { StockInnerIcon } from "assets/icons/components/module-icons/stock-icon";
import { TasksInnerIcon } from "assets/icons/components/module-icons/tasks-icon";
import { useAppSetting } from "hooks/suite-react-hooks/use-app-setting";
import { useRootRouteData } from "routes/root";
import { LEGACY_TASKS_MODULE_SETTING } from "routes/tasks/tasks/tasks.types";
import { isBooleanAppSettingTrue } from "util/application-configuration";

import { useComplianceChapters } from "./chapter-data-hooks/use-compliance-chapters";
import { useControlRoomChapters } from "./chapter-data-hooks/use-control-room-chapters";
import { useFinancialsChapters } from "./chapter-data-hooks/use-financials-chapters";
import { useOrdersChapters } from "./chapter-data-hooks/use-orders-chapters";
import { useOrganizationsChapters } from "./chapter-data-hooks/use-organizations-chapters";
import { usePeopleChapters } from "./chapter-data-hooks/use-people-chapters";
import { usePimChapters } from "./chapter-data-hooks/use-pim-chapters";
import { usePromotionsChapters } from "./chapter-data-hooks/use-promotions-chapters";
import { useStockChapters } from "./chapter-data-hooks/use-stock-chapters";
import { useTasksChapters } from "./chapter-data-hooks/use-tasks-chapters";
import { EModuleCode, IModule } from "./chapter-finder.types";

export const useChapterFinderData = () => {
  const intl = useIntl();

  const financialsChapters = useFinancialsChapters();

  const ordersChapters = useOrdersChapters();

  const organizationsChapters = useOrganizationsChapters();

  const peopleChapters = usePeopleChapters();

  const pimChapters = usePimChapters();

  const promotionsChapters = usePromotionsChapters();

  const stockChapters = useStockChapters();

  const tasksChapters = useTasksChapters();

  const controlRoomChapters = useControlRoomChapters();

  const complainceChapters = useComplianceChapters();

  const { appSettingValue: uesLegacyTaskModule } = useAppSetting(
    LEGACY_TASKS_MODULE_SETTING,
    false,
  );

  const modules = useMemo<IModule[]>(
    () => [
      {
        title: intl.formatMessage({ id: "module.orders.title", defaultMessage: "Orders" }),
        path: "orders",
        code: EModuleCode.ORDERS,
        icon: <OrdersIconInner />,
        chapters: ordersChapters,
        moduleColor: "#922DA9",
      },
      {
        title: intl.formatMessage({ id: "module.people.title", defaultMessage: "People" }),
        path: "people",
        code: EModuleCode.PEOPLE,
        icon: <CRMIconInner />,
        chapters: peopleChapters,
        moduleColor: "#FF9500",
      },
      {
        title: intl.formatMessage({ id: "module.financials.title", defaultMessage: "Financials" }),
        path: "financials",
        code: EModuleCode.FINANCIALS,
        icon: <FinancialsInnerIcon />,
        chapters: financialsChapters,
        moduleColor: "#007AFF",
      },
      {
        title: intl.formatMessage({
          id: "module.organizations.title",
          defaultMessage: "Organizations",
        }),
        code: EModuleCode.ORGANIZATIONS,
        path: "organizations",
        icon: <OrganizationsInnerIcon />,
        chapters: organizationsChapters,
        moduleColor: "#FFCC00",
      },
      {
        title: intl.formatMessage({
          id: "module.pim.title",
          defaultMessage: "PIM",
        }),
        code: EModuleCode.PIM,
        path: "pim",
        icon: <PIMInnerIcon />,
        chapters: pimChapters,
        moduleColor: "#30B0C7",
      },
      {
        title: intl.formatMessage({
          id: "module.promotions.title",
          defaultMessage: "Promotions",
        }),
        code: EModuleCode.PROMOTIONS,
        path: "promotions",
        icon: <PromotionsInnerIcon />,
        chapters: promotionsChapters,
        moduleColor: "#FF315F",
      },
      {
        title: intl.formatMessage({
          id: "module.stock.title",
          defaultMessage: "Stock",
        }),
        code: EModuleCode.STOCKMANAGEMENT,
        path: "stock-management",
        icon: <StockInnerIcon />,
        chapters: stockChapters,
        moduleColor: "#A2845E",
      },
      {
        title: intl.formatMessage({
          id: "module.tasks.title",
          defaultMessage: "Tasks",
        }),
        code: EModuleCode.TASKS,
        path: isBooleanAppSettingTrue(uesLegacyTaskModule) ? "module/tasks" : "tasks-new",
        icon: <TasksInnerIcon />,
        chapters: tasksChapters,
        moduleColor: "#34C759",
      },
      {
        title: intl.formatMessage({
          id: "module.control-room.title",
          defaultMessage: "Control room",
        }),
        code: EModuleCode.CONTROLROOM,
        path: "control-room",
        icon: <MonitorsInnerIcon />,
        chapters: controlRoomChapters,
        moduleColor: "#A6DC07",
      },
      {
        title: intl.formatMessage({
          id: "module.compliance.title",
          defaultMessage: "Compliance",
        }),
        code: EModuleCode.COMPLIANCE,
        path: "compliance",
        icon: <ComplianceIconInner />,
        chapters: complainceChapters,
        moduleColor: "#5856D6",
      },
    ],
    [
      intl,
      ordersChapters,
      peopleChapters,
      financialsChapters,
      organizationsChapters,
      pimChapters,
      promotionsChapters,
      stockChapters,
      uesLegacyTaskModule,
      tasksChapters,
      controlRoomChapters,
      complainceChapters,
    ],
  );

  const { availableModulesResponse } = useRootRouteData();

  const sortedModulesForUser = useMemo(() => {
    const availableToUserCodes = availableModulesResponse?.Modules?.map((item) => item.Code) ?? [];
    const filteredModules = modules.filter((module) => availableToUserCodes.includes(module.code));
    return filteredModules.sort((a, b) => a.title.localeCompare(b.title));
  }, [availableModulesResponse?.Modules, modules]);
  return sortedModulesForUser;
};
