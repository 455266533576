import { useMemo, useState } from "react";

import { MultiSelect as LyraMultiSelect } from "@new-black/lyra";
import { sum } from "lodash";

import Select from "components/suite-ui/select";
import useMapToEnumValues from "features/organizations/hooks/use-map-to-enum-values";

interface EVAEnumMultiSelectProps {
  name: string;
  label: string;
  defaultValue?: number;
  error?: boolean;
  helperText?: string;
  enumValues?: { Name: string; Value: number }[];
  passive?: boolean;
  variant?: "material" | "lyra";
}

export function EVAEnumMultiSelect({
  defaultValue,
  enumValues,
  error,
  helperText,
  label,
  name,
  passive,
  variant = "material",
}: EVAEnumMultiSelectProps) {
  const mapToTypeOptions = useMapToEnumValues(
    enumValues?.map((x) => ({ key: x.Name, value: x.Value })),
  );

  const [value, setValue] = useState<number[]>(defaultValue ? mapToTypeOptions(defaultValue) : []);

  const typeOptions = useMemo(
    () =>
      enumValues
        ?.filter(
          (type) =>
            // Filter out 'None' option
            type.Value !== 0,
        )
        ?.map((type) => ({
          label: type.Name,
          value: type.Value,
        })),
    [enumValues],
  );

  const selectedValues = useMemo(
    () =>
      value
        .map((value) => typeOptions?.find((option) => option.value === value))
        .filter((item): item is NonNullable<typeof item> => item !== undefined),
    [typeOptions, value],
  );

  if (variant === "lyra") {
    return (
      <>
        <LyraMultiSelect
          label={label}
          isDisabled={passive}
          value={selectedValues}
          items={typeOptions ?? []}
          errorMessage={helperText}
          getLabel={(item) => item.label}
          getItemId={(item) => item.value}
          selectRenderElements={(item) => ({ label: item.label })}
          onChange={(newValues) => {
            setValue(newValues?.map((value) => value.value) ?? []);
          }}
        />
        {value?.length ? <input type="hidden" name={name} value={sum(value)} /> : null}
      </>
    );
  }

  return (
    <>
      <Select
        multiple
        name={name}
        label={label}
        items={typeOptions ?? []}
        value={value !== undefined && typeOptions?.length ? value : []}
        onChange={(event) => {
          setValue(event.target.value as any);
        }}
        error={error}
        helpertext={helperText}
        passive={passive}
      />
      <input type="hidden" name={name} value={value?.length ? sum(value) : undefined} />
    </>
  );
}
