import { useMemo, useState } from "react";

import { SvgIcon, Text } from "@new-black/lyra";
import cn from "classnames";

import useGetAssetsUrl from "hooks/use-get-assets-url";

export const ProductSearchModalListItem = ({
  classNames,
  description,
  imageBlobId,
  title,
}: {
  title?: string;
  description?: string | number;
  imageBlobId?: string;
  classNames?: {
    container?: string;
  };
}) => {
  const [errorFetchingImage, setErrorFetchingImage] = useState(false);

  const assetsUrl = useGetAssetsUrl();

  const imageUrl = useMemo(() => {
    if (!!assetsUrl && !!imageBlobId) {
      return `${assetsUrl}/image/${48}/${48}/${imageBlobId}.png`;
    }
  }, [assetsUrl, imageBlobId]);

  return (
    <div className={cn("flex items-center gap-4", classNames?.container)}>
      <div className="flex h-12 w-12 shrink-0 items-center justify-center overflow-hidden rounded-xs border border-default">
        {errorFetchingImage || !imageUrl ? (
          <SvgIcon name="images" className="h-6 w-6 text-secondary" />
        ) : (
          <img
            src={imageUrl}
            alt={title}
            className="aspect-square h-12 w-12 object-cover"
            width="48px"
            height="48px"
            onError={() => setErrorFetchingImage(true)}
          />
        )}
      </div>

      <div className={cn("flex min-w-0 flex-col self-start", {})}>
        <Text variant="body-medium" className="line-clamp-2">
          {title ?? "-"}
        </Text>
        <Text variant="body-small" color="secondary" className="line-clamp-2">
          {description ?? "-"}
        </Text>
      </div>
    </div>
  );
};
