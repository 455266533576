import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "components/suite-composite/utils/types";

export const currenciesServiceState = builders.buildServiceState({
  service: Core.ListCurrencies,
  key: "Core:ListCurrencies",
  allowEmptyRequest: false,
  requireEmployee: true,
  defaultRequest: {
    PageConfig: {
      Limit: ALL_ROWS_LIMIT,
    },
  },
});

export const currenciesResult = selector<EVA.Core.CurrencyDto[] | undefined>({
  key: "Core:ListCurrencies:Result",
  get: ({ get }) => get(currenciesServiceState.response)?.Result?.Page,
});
