import { SearchListFieldControlled } from "./search-list-field-controlled";
import {
  SearchListFieldUncontrolled,
  UncontrolledSearchListFieldHiddenInputs,
} from "./search-list-field-uncontrolled";

export const SearchListField = {
  Controlled: SearchListFieldControlled,
  Uncontrolled: SearchListFieldUncontrolled,
  HiddenInputs: UncontrolledSearchListFieldHiddenInputs,
};
