import { useState } from "react";

/**
 * Hook for expanse options table
 * @param expanded
 */
const useExpandedState = (expanded?: "open" | "closed") => {
  // Set state for expanse options table
  const [expandedState, setExpandedState] = useState<"open" | "closed" | undefined>(expanded);

  // Change Expanse State to the opposite
  const toggleExpandedState = () => {
    if (expandedState === "open") {
      setExpandedState("closed");
    } else {
      setExpandedState("open");
    }
  };

  // Framer Animation variant for rotating the arrow icon
  const expandedButtonAnimationVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
  };

  return {
    expandedState,
    toggleExpandedState,
    expandedButtonAnimationVariants,
  };
};

export default useExpandedState;
