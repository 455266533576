import { useCallback, useMemo, useState } from "react";

import { SortingState, Updater } from "@tanstack/react-table";
import { reverse, sortBy } from "lodash";

export const useClientTableSorting = <T,>(
  unorderedTableData: T[],
  defaultSortProperty?: keyof T,
) => {
  const [sortProperty, setSortProperty] = useState<keyof T | undefined>(defaultSortProperty);
  const [sortDirection, setSortDirection] = useState<"Ascending" | "Descending">("Ascending");

  const tableData = useMemo(() => {
    const data = unorderedTableData;

    if (data.length && sortProperty) {
      const sorted = sortBy(data, sortProperty);
      return sortDirection === "Ascending" ? sorted : reverse(sorted);
    }
    return data;
  }, [sortDirection, sortProperty, unorderedTableData]);

  const handleSortByChange = useCallback(
    (updater: Updater<SortingState>) => {
      const newSorting =
        typeof updater === "function"
          ? updater([
              {
                id: sortProperty?.toString() ?? defaultSortProperty?.toString() ?? "ID",
                desc: sortDirection === "Descending",
              },
            ])
          : updater;

      setSortProperty(newSorting[0].id as keyof T);
      setSortDirection(newSorting[0].desc ? "Descending" : "Ascending");
    },
    [sortDirection, sortProperty, defaultSortProperty],
  );

  return {
    tableData,
    onSortByChange: handleSortByChange,
    sortProperty,
    sortDirection,
  };
};
