import { useCallback, useEffect, useMemo, useState } from "react";

import useDebounce from "hooks/suite-react-hooks/use-debounce";
import { ServiceQueryWithRequest } from "types/query.types";

interface IMinimalRequest<Filters> {
  PageConfig?: EVA.Core.PageConfig<Filters>;
}

const useCountablePaginationFilters = <Filters>(
  serviceQueryWithRequest: ServiceQueryWithRequest<IMinimalRequest<Filters> | undefined, unknown>,
) => {
  const [filterState, setFilterState] = useState(
    serviceQueryWithRequest.request?.PageConfig?.Filter,
  );

  const debouncedFilterState = useDebounce(filterState, 500);

  const setRequest = useMemo(
    () => serviceQueryWithRequest.setRequest,
    [serviceQueryWithRequest.setRequest],
  );

  // Set request when debounced filter state changes
  useEffect(() => {
    setRequest((current) => ({
      ...current,
      PageConfig: { ...current?.PageConfig, Filter: debouncedFilterState, Start: 0 },
    }));
  }, [debouncedFilterState, setRequest]);

  // Update request when filters are changed from outside
  useEffect(() => {
    setRequest((current) => ({
      ...current,
      PageConfig: { ...current?.PageConfig, Filter: debouncedFilterState, Start: 0 },
    }));
  }, [debouncedFilterState, setRequest]);

  const getFilter = useCallback(
    <T extends keyof Filters>(filterName: T) => filterState?.[filterName],
    [filterState],
  );

  const setFilter = useCallback(
    <T extends keyof Filters>(filterName: T, newValue?: Filters[T]) =>
      setFilterState((current) => ({ ...current, [filterName]: newValue })),
    [],
  );

  return { getFilter, setFilter, setFilterState };
};

export default useCountablePaginationFilters;
