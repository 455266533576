import { SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";

import IconButton, { IconButtonProps } from "../icon-button";

const styledArrowIconButton = cva(
  "!absolute top-1/2 z-[2] border border-solid border-[color:#e3e3e3] bg-[color:#ffffff] disabled:opacity-0",
  {
    variants: {
      align: {
        left: "left-2.5",
        right: "right-2.5",
      },
    },
  },
);
const ArrowIconButton = ({
  alignDirection,
  ...props
}: IconButtonProps & { alignDirection: "left" | "right" }) => (
  <IconButton {...props} className={styledArrowIconButton({ align: alignDirection })} />
);

interface ArrowProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled: boolean;
  type: "left" | "right";
}

const Arrow = ({ disabled, onClick, type }: ArrowProps) => (
  <ArrowIconButton
    size="small"
    disabled={disabled}
    onClick={onClick}
    alignDirection={type}
    style={{ backgroundColor: "white" }}
  >
    {type === "left" ? (
      <SvgIcon name="chevron-left" className="h-5 w-5" />
    ) : (
      <SvgIcon name="chevron-right" className="h-5 w-5" />
    )}
  </ArrowIconButton>
);

export default Arrow;
