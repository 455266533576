import { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { useNavigate } from "components/routing";
import { toast } from "components/suite-ui/toast";
import useCallService from "hooks/suite-react-hooks/use-call-service";
import { useHandleErrorResponse } from "hooks/suite-react-hooks/use-handle-error-response";

interface ICustomFieldHanldeDeleteProps {
  ID: number;
}

const useDeleteCustomField = (onSuccess?: () => void) => {
  const intl = useIntl();
  const handleError = useHandleErrorResponse();
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();
  const customFieldsOverviewPath = location.pathname.replace(/\/[0-9]+/, "");

  const { callService: deleteMutation } = useCallService({
    service: CoreManagement.DeleteCustomField,
    options: {
      onSuccess: () => {
        toast.success(
          intl.formatMessage({
            id: "custom-user-fields.custom-field.actions.delete.success",
            defaultMessage: "Custom field(s) deleted successfully.",
          }),
        );

        onSuccess ? onSuccess() : navigate(customFieldsOverviewPath, { force: true });
      },
      onError: (error) => {
        handleError(error, "CoreManagement.DeleteCustomField");
      },
    },
    disableNotifications: true,
  });

  const handleDeleteCustomField = useCallback(
    async ({ ID }: ICustomFieldHanldeDeleteProps) => {
      setIsLoading(true);
      await deleteMutation({ ID });
      setIsLoading(false);
    },
    [deleteMutation],
  );

  return { handleDeleteCustomField, isLoading };
};

export default useDeleteCustomField;
