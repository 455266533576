import { Separator } from "@new-black/lyra";

import type { IProductMultiSearchModalProps, IProductSearchModalResultBase } from "../types";

import { ProductSearchModalRightPaneFooter } from "./product-search-modal-right-pane-footer";
import { ProductSearchModalRightPaneHeader } from "./product-search-modal-right-pane-header";
import { ProductSearchModalRightPaneList } from "./product-search-modal-right-pane-list";

interface IProductSearchModalRightPaneProps<TProduct extends IProductSearchModalResultBase>
  extends Pick<
    IProductMultiSearchModalProps<TProduct>,
    | "messages"
    | "rightPaneListItemRenderElements"
    | "onSave"
    | "customRightPaneContent"
    | "isSaveDisabled"
    | "isSaveLoading"
    | "saveButtonName"
    | "saveButtonValue"
    | "isCancelDisabled"
    | "form"
    | "closeOnSave"
  > {
  onClose: () => void;
}

export const ProductSearchModalRightPane = <TProduct extends IProductSearchModalResultBase>({
  customRightPaneContent,
  rightPaneListItemRenderElements,
  ...props
}: IProductSearchModalRightPaneProps<TProduct>) => (
  <>
    <ProductSearchModalRightPaneHeader<TProduct> />
    <Separator />

    <ProductSearchModalRightPaneList<TProduct>
      rightPaneListItemRenderElements={rightPaneListItemRenderElements}
    />

    <Separator />
    <div className="space-y-4 p-4">
      {customRightPaneContent}
      <ProductSearchModalRightPaneFooter<TProduct> {...props} />
    </div>
  </>
);
