import { PropsWithChildren } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonIndicator,
  Popover,
  PopoverTrigger,
  Separator,
  SvgIcon,
  Text,
} from "@new-black/lyra";

export interface IFilterPopover {
  hasValue?: boolean;
  onReset?: () => void;
}

const FilterPopover = ({ children, hasValue, onReset }: PropsWithChildren<IFilterPopover>) => {
  const intl = useIntl();
  return (
    <PopoverTrigger>
      <Button
        variant="icon"
        tooltip={intl.formatMessage({ id: "generic.label.filters", defaultMessage: "Filters" })}
      >
        {hasValue ? <ButtonIndicator /> : null}
        <SvgIcon name="filter" />
      </Button>
      <Popover className="w-80 p-0" placement="bottom end">
        {({ close }) => (
          <>
            <div className="flex flex-col">
              <div className="p-4">
                <Text variant="heading-2">
                  <FormattedMessage id="generic.label.filters" defaultMessage="Filters" />
                </Text>
              </div>
              <div className="p-4 pt-0">{children}</div>
            </div>
            <Separator />
            <div className="flex justify-between p-4">
              {onReset ? (
                <Button
                  variant="secondary"
                  isDisabled={!hasValue}
                  onPress={() => {
                    onReset?.();
                    close();
                  }}
                >
                  <FormattedMessage
                    id="generic.label.clear-filters"
                    defaultMessage="Clear Filter"
                  />
                </Button>
              ) : (
                <div />
              )}
              <Button
                onPress={() => {
                  close();
                }}
              >
                <FormattedMessage id="generic.label.done" defaultMessage="Done" />
              </Button>
            </div>
          </>
        )}
      </Popover>
    </PopoverTrigger>
  );
};

export default FilterPopover;
