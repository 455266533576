import { Dispatch, SetStateAction, useMemo } from "react";
import { useIntl } from "react-intl";

import { TableProps } from "@new-black/lyra";

import useCustomFieldOptions from "./use-custom-field-options";
import useCustomFieldSettingsTableColumns from "./use-custom-field-settings-table-columns";
import useUnsetCustomFieldSettings from "./use-unset-custom-field-settings";

interface IUseCustomFieldSettingsTableConfig {
  customFieldId: number | undefined;
  setIsAddEditOpen: Dispatch<SetStateAction<boolean>>;
  setOptionToEdit: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
  optionToDelete:
    | EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto
    | undefined;
  setOptionToDelete: Dispatch<
    SetStateAction<
      EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto | undefined
    >
  >;
}

const useCustomFieldSettingsTableConfig = ({
  customFieldId,
  optionToDelete,
  setIsAddEditOpen,
  setOptionToDelete,
  setOptionToEdit,
}: IUseCustomFieldSettingsTableConfig) => {
  const intl = useIntl();
  const {
    customFieldOptions,
    goToNextPage: nextPage,
    goToPreviousPage: previousPage,
    hasNextPage,
    hasPreviousPage,
    isLoading,
    isLoadingWithoutPreviousResponse,
    limit,
    setLimit,
  } = useCustomFieldOptions(customFieldId);

  const { handleUnset, isUnsetLoading } = useUnsetCustomFieldSettings({
    customFieldId,
    optionToDelete,
    setOptionToDelete,
  });

  const tableColumns = useCustomFieldSettingsTableColumns({
    customFieldId,
    setOptionToEdit,
    setIsAddEditOpen,
    setOptionToDelete,
  });

  const tableConfig = useMemo<
    TableProps<EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto>
  >(
    () => ({
      limit,
      setLimit,
      onNext: nextPage,
      columns: tableColumns,
      showNext: hasNextPage,
      onPrevious: previousPage,
      showPrevious: hasPreviousPage,
      data: customFieldOptions ?? [],
      isLoading: isLoadingWithoutPreviousResponse,
      options: {
        hidePagination: (customFieldOptions ?? []).length <= 5 && !hasNextPage && !hasPreviousPage,
      },
      "aria-label": intl.formatMessage({
        id: "generic.label.settings",
        defaultMessage: "Settings",
      }),
    }),
    [
      limit,
      setLimit,
      nextPage,
      tableColumns,
      hasNextPage,
      previousPage,
      hasPreviousPage,
      customFieldOptions,
      isLoadingWithoutPreviousResponse,
      intl,
    ],
  );

  return {
    isLoading,
    tableConfig,
    handleUnset,
    isUnsetLoading,
  };
};

export default useCustomFieldSettingsTableConfig;
