import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listProductBundlesLoaderQuery,
  serviceQueryKeys: listProductBundlesQueryKeys,
  useServiceQueryHook: useListProductBundlesQuery,
} = createServiceQuery(Core.ListProductBundles, true);

export const {
  serviceLoaderQuery: getProductBundleLoaderQuery,
  serviceQueryKeys: getProductBundleQueryKeys,
  useServiceQueryHook: useGetProductBundleQuery,
} = createServiceQuery(Core.GetProductBundle, true);
