import { useCallback, useState } from "react";

import {
  IOrganizationUnitMenuItem,
  IOrganizationUnitStructure,
} from "../organization-unit-selector.types";

import useOrganizationUnitStructure from "./use-organization-unit-structure";

const useNavigationButtons = (
  setMenuItems: React.Dispatch<React.SetStateAction<IOrganizationUnitMenuItem[] | undefined>>,
  parentOrganizationUnit?: IOrganizationUnitStructure,
) => {
  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();
  const [direction, setDirection] = useState<number>(0);
  // Go forward one level if there is one
  const onGoNext = useCallback(
    (item: IOrganizationUnitMenuItem) => {
      if (item?.HasChildren) {
        setMenuItems(
          flattenedOrganizationUnits
            ?.filter((ou) => ou.ParentID === item?.ID)
            ?.map((x) => ({
              ID: x.ID,
              Name: x.Name,
              ParentID: x.ParentID,
              BackendID: x.BackendID,
              HasChildren: flattenedOrganizationUnits.some((ou) => ou.ParentID === x.ID),
            })),
        );
        setDirection(1);
      }
    },
    [flattenedOrganizationUnits, setMenuItems],
  );

  // Go back one level or fallback to root organization units
  const onGoBack = useCallback(() => {
    if (parentOrganizationUnit) {
      const OUs =
        flattenedOrganizationUnits?.filter(
          (ou) => ou.ParentID === parentOrganizationUnit?.ParentID,
        ) ?? flattenedOrganizationUnits?.filter((ou) => !ou.ParentID);
      setMenuItems(
        OUs?.map((x) => ({
          ID: x.ID,
          Name: x.Name,
          ParentID: x.ParentID,
          BackendID: x.BackendID,
          HasChildren: flattenedOrganizationUnits?.some((ou) => ou.ParentID === x.ID) ?? false,
        })),
      );
      setDirection(-1);
    }
  }, [flattenedOrganizationUnits, parentOrganizationUnit, setMenuItems]);

  return { onGoNext, onGoBack, direction };
};

export default useNavigationButtons;
