export enum StockLabelFeature {
  Sellable = 0,
  Movable = 1,
  Adjustable = 2,
  Returnable = 3,
  CycleCountable = 4,
  FullStockCountable = 5,
  ReturnToSupplierable = 6,
}

export enum StockLabelFeatureProperties {
  Sellable = "Sellable",
  Movable = "Movable",
  Adjustable = "Adjustable",
  Returnable = "Returnable",
  CycleCountable = "CycleCountable",
  FullStockCountable = "FullStockCountable",
  ReturnToSupplierable = "ReturnToSupplierable",
}
