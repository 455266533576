export type TElementPositionInArray = "none" | "lastElement" | "first" | "middle" | "last";

/**
 * Get the position of a specific element in an array.
 * @param arrayLength
 * @param index
 * @returns `TElementPositionInArray = "none" | "lastElement" | "first" | "middle" | "last"`
 */
export const getElementPositionInArray = (
  arrayLength: number,
  index: number,
): TElementPositionInArray => {
  // If index or arrayLength are not valid, return "none"
  if (arrayLength <= 0 || index < 0 || index > arrayLength - 1) {
    return "none";
  }

  // If it's the last element in the array, return "lastElement"
  if (arrayLength === 1) {
    return "lastElement";
  }

  if (index === 0) {
    return "first";
  }

  if (0 < index && index < arrayLength - 1) {
    return "middle";
  }

  if (index === arrayLength - 1) {
    return "last";
  }

  return "none";
};
