import { useListProductRequirementsQuery } from "models/product-requirements";
import { ALL_ROWS_LIMIT, FIVE_MINUTES } from "util/base-values";

export const useDiscountProductRequirements = (enabled = true) => {
  const { data, isFetching: isLoading } = useListProductRequirementsQuery(
    enabled ? { PageConfig: { Limit: ALL_ROWS_LIMIT } } : undefined,
    { staleTime: FIVE_MINUTES, refetchOnMount: false },
  );

  return { productRequirements: data?.Result.Page, isLoading };
};
