import { builders } from "@springtree/eva-sdk-react-recoil";
import { Admin } from "@springtree/eva-services-admin";
import { selectorFamily } from "recoil";

export const allowedModulesForRoleState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  requireEmployee: true,
  key: "allowedModulesForRole",
  allowEmptyRequest: false,
  service: Admin.AdminGetRoleAllowedModules,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number" || parameter === req?.RoleID) {
      return req;
    }

    return {
      ...req,
      RoleID: parameter as number,
    };
  },
});

export const allowedModulesForRoleSelector = selectorFamily<
  EVA.Admin.AdminGetRoleAllowedModulesResponse.AllowedModule[] | undefined,
  number | undefined
>({
  key: "allowedModulesForRole:response",
  get:
    (param) =>
    ({ get }) =>
      get(allowedModulesForRoleState.response(param))?.Modules,
});
