import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import { getProductWarrantyQuery, verifySerialNumberQuery } from "models/products";

const useProductSerialNumberAndWarranty = (
  serialNumber?: string,
  orderLineID?: number,
  requestWarrantyInformation?: boolean,
  validateSerialNumber?: boolean,
) => {
  const {
    data: verifySerialNumberQueryData,
    isFetching: isVerifySerialNumberLoading,
    isStale: isVerifySerialNumberStale,
  } = useQuery({
    ...verifySerialNumberQuery(
      serialNumber
        ? ({
            SerialNumber: serialNumber,
          } as EVA.Core.VerifySerialNumber)
        : undefined,
      undefined,
      undefined,
      true,
    ),
    enabled: !!serialNumber && !!validateSerialNumber,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const verifySerialNumberData = useMemo(
    () => (serialNumber && !!validateSerialNumber ? verifySerialNumberQueryData : undefined),
    [serialNumber, validateSerialNumber, verifySerialNumberQueryData],
  );

  const isGetProductWarrantyEnabled = useMemo(
    () =>
      !!requestWarrantyInformation &&
      !!serialNumber &&
      // Get product warranty information if either there is no validation required for the serial number
      // or we have a verified and valid serial number
      (!validateSerialNumber || (!!verifySerialNumberData && !verifySerialNumberData?.Error)),
    [requestWarrantyInformation, serialNumber, validateSerialNumber, verifySerialNumberData],
  );

  const {
    data: getProductWarrantyData,
    isFetching: isGetProductWarrantyLoading,
    isStale: isGetProductWarrantyStale,
  } = useQuery({
    ...getProductWarrantyQuery(
      serialNumber || orderLineID
        ? { SerialNumber: serialNumber, OrderLineID: orderLineID }
        : undefined,
      undefined,
      undefined,
      true,
    ),
    enabled: !!isGetProductWarrantyEnabled,
    keepPreviousData: isGetProductWarrantyEnabled,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const isLoading = useMemo(
    () => isVerifySerialNumberLoading || isGetProductWarrantyLoading,
    [isGetProductWarrantyLoading, isVerifySerialNumberLoading],
  );

  const isStale = useMemo(
    () => isVerifySerialNumberStale || isGetProductWarrantyStale,
    [isGetProductWarrantyStale, isVerifySerialNumberStale],
  );

  return { verifySerialNumberData, getProductWarrantyData, isLoading, isStale };
};

export default useProductSerialNumberAndWarranty;
