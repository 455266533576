import { useCallback } from "react";

import { useMonaco } from "@monaco-editor/react";

import { ScriptEditorLanguage } from "types/monaco-editor-language";

import { getScriptConfigTokens } from "../config/get-script-config-tokens";

/**
 * Returns a callback that can be used to configure the monaco editor to use the Script language tokens
 */
const useRegisterScriptLanguageTokens = (
  dialect: ScriptEditorLanguage,
  dialectKeywords: string[] = [],
) => {
  const monaco = useMonaco();

  /**
   * Configures the monaco editor to use the Script language tokens
   *
   * @returns an `IDisposable` object that can be used to un-register the language tokens
   */
  const registerScriptLanguageTokens = useCallback(() => {
    return monaco?.languages?.setMonarchTokensProvider(
      dialect,
      getScriptConfigTokens(dialect, dialectKeywords),
    );
  }, [dialect, dialectKeywords, monaco]);

  return registerScriptLanguageTokens;
};

export default useRegisterScriptLanguageTokens;
