import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getCycleCountZonesLoaderQuery,
  serviceQuery: getCycleCountZonesQuery,
  serviceQueryKeys: getCycleCountZonesQueryKeys,
  useServiceQueryHook: useGetCycleCountZonesQuery,
} = createServiceQuery(Core.GetCycleCountZones);
