import { ButtonGroup } from "@new-black/lyra";

import { useProductFilterContext } from "../product-filter-context";
import { IProductFilterModalValues } from "../types";

import ProductFilterDeleteRowAction from "./product-filter-delete-row-action";
import ProductFilterEditRowAction from "./product-filter-edit-row-action";

export interface IDefaultProductFilterRowActionsProps {
  disableEdit?: boolean;
  disableDelete?: boolean;
  rowData?: IProductFilterModalValues;
  deleteModalVariant?: "material" | "lyra";
}

/**
 * Component that can be used to render the default row actions for the product filter table
 */
const DefaultProductFilterRowActions = ({
  deleteModalVariant,
  disableDelete: disableDeleteProp,
  disableEdit: disableEditProp,
  rowData,
}: IDefaultProductFilterRowActionsProps) => {
  const { disableDelete, disableEdit } = useProductFilterContext();

  return (
    <ButtonGroup>
      {disableEditProp ?? disableEdit ? null : <ProductFilterEditRowAction rowData={rowData} />}

      {disableDeleteProp ?? disableDelete ? null : (
        <ProductFilterDeleteRowAction rowData={rowData} variant={deleteModalVariant} />
      )}
    </ButtonGroup>
  );
};

export default DefaultProductFilterRowActions;
