import { Tooltip } from "@new-black/lyra";
import { Button } from "react-aria-components";
import type { CSSProperties } from "react";
import { useCallback } from "react";
import toast from "react-hot-toast";
import { tv } from "tailwind-variants";

const textStyled = tv({
  base: "box-border px-1.5 py-1  border-solid truncate rounded-xs border border-default",
  variants: {
    textCanBeCopied: {
      true: "cursor-copy",
      false: "cursor-default",
    },
  },
});

interface ICodeBoxProps {
  /** Text to display in the code box */
  text: string;
  /** Is the code box copyable (on click)? */
  copyable?: boolean;
  /** Text to copy when copyable is true */
  textToCopy?: string;
  /** Tooltip text for the code box (when copyable is true) */
  copyTooltip?: string;
  /** CSS max-width property for the code box (199px by default) */
  maxWidth?: CSSProperties["maxWidth"];
  /** Notification message to display when copied to clipboard */
  copiedNotification?: string;
  /** CSS style object for the code box */
  style?: CSSProperties;
  /** CSS class name for the code box */
  className?: string;
}

export const LyraCodeBox = ({
  className,
  copiedNotification = "Copied to clipboard!",
  copyable = false,
  copyTooltip = "Copy to clipboard",
  maxWidth = "199px",
  style,
  text,
  textToCopy,
}: ICodeBoxProps) => {
  const handleClick = useCallback(() => {
    if (navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy ?? text)
        .then(() => toast.success(copiedNotification))
        .catch((err) => console.error(err));
    }
  }, [copiedNotification, text, textToCopy]);

  return copyable ? (
    <Tooltip tooltip={copyTooltip}>
      <Button
        className={textStyled({ textCanBeCopied: true, className })}
        style={{
          ...style,
          maxWidth,
        }}
        onPress={handleClick}
      >
        {text}
      </Button>
    </Tooltip>
  ) : (
    <Button
      className={textStyled({ textCanBeCopied: false, className })}
      style={{ ...style, maxWidth }}
    >
      {text}
    </Button>
  );
};
