import { useCallback } from "react";

import { Dialog, DialogCard } from "@new-black/lyra";

import { LyraSplitDialogContent } from "components/shared/lyra-split-dialog";

import { ProductSearchModalProvider } from "./product-search-modal-context";
import {
  ProductMultiSearchModalLeftPane,
  ProductSingleSearchModalLeftPane,
} from "./product-search-modal-left-pane";
import { ProductSearchModalRightPane } from "./product-search-modal-right-pane";
import type {
  IProductMultiSearchModalProps,
  IProductSearchModalResultBase,
  IProductSingleSearchModalProps,
} from "./types";

const ProductMultiSearchModal = <TProduct extends IProductSearchModalResultBase>({
  closeOnSave,
  customRightPaneContent,
  filters,
  form,
  includedFields,
  isCancelDisabled,
  isProductSelectable,
  isSaveDisabled,
  isSaveLoading,
  leftPaneListItemRenderElements,
  messages,
  onSave,
  onSelectedProductsChange,
  paneProportions = "1/3-2/3",
  rightPaneListItemRenderElements,
  saveButtonName,
  saveButtonValue,
  selectedProducts,
  ...dialogProps
}: IProductMultiSearchModalProps<TProduct>) => (
  <Dialog {...dialogProps}>
    {({ close }) => (
      <ProductSearchModalProvider
        type="multi"
        filters={filters}
        selectedProducts={selectedProducts}
        includedFields={includedFields}
        isProductSelectable={isProductSelectable}
        onSelectedProductsChange={onSelectedProductsChange}
      >
        <LyraSplitDialogContent
          paneProportions={paneProportions}
          startPaneContent={
            <ProductMultiSearchModalLeftPane
              leftPaneListItemRenderElements={leftPaneListItemRenderElements}
              messages={messages}
            />
          }
          endPaneContent={
            <ProductSearchModalRightPane
              rightPaneListItemRenderElements={rightPaneListItemRenderElements}
              messages={messages}
              onClose={close}
              isCancelDisabled={isCancelDisabled}
              customRightPaneContent={customRightPaneContent}
              isSaveDisabled={isSaveDisabled}
              isSaveLoading={isSaveLoading}
              onSave={onSave}
              form={form}
              saveButtonName={saveButtonName}
              saveButtonValue={saveButtonValue}
              closeOnSave={closeOnSave}
            />
          }
        />
      </ProductSearchModalProvider>
    )}
  </Dialog>
);

function ProductSingleSearchModal<TProduct extends IProductSearchModalResultBase>({
  customContent,
  filters,
  form,
  includedFields,
  isProductSelectable,
  isSaveDisabled,
  isSaveLoading,
  leftPaneListItemRenderElements,
  messages,
  onSave,
  onSelectedProductChange,
  saveButtonName,
  saveButtonValue,
  selectedProduct,
  ...dialogProps
}: IProductSingleSearchModalProps<TProduct>) {
  const handleSelectionChange = useCallback(
    (products: TProduct[]) => {
      if (products.length !== 1) {
        onSelectedProductChange?.(undefined);
        return;
      }
      onSelectedProductChange?.(products[0]);
    },
    [onSelectedProductChange],
  );

  return (
    <Dialog {...dialogProps}>
      {({ close }) => (
        <ProductSearchModalProvider
          type="single"
          filters={filters}
          includedFields={includedFields}
          isProductSelectable={isProductSelectable}
          onSelectedProductsChange={handleSelectionChange}
          selectedProducts={selectedProduct ? [selectedProduct] : []}
        >
          <DialogCard>
            <ProductSingleSearchModalLeftPane
              leftPaneListItemRenderElements={leftPaneListItemRenderElements}
              messages={messages}
              onClose={close}
              isSaveDisabled={isSaveDisabled}
              isSaveLoading={isSaveLoading}
              onSave={onSave}
              form={form}
              saveButtonName={saveButtonName}
              saveButtonValue={saveButtonValue}
              customContent={customContent}
            />
          </DialogCard>
        </ProductSearchModalProvider>
      )}
    </Dialog>
  );
}

export const ProductSearchModal = {
  Multi: ProductMultiSearchModal,
  Single: ProductSingleSearchModal,
};
