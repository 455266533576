import { ReactNode, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { TableColumnDef } from "@new-black/lyra";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";
import { useProductFilterContext } from "../product-filter-context";

export default function useListTableColumns({
  customRowActions,
  total,
}: {
  total: number;
  customRowActions?: (rowData: IEVAFilterArrayItem, isLastRow: boolean) => ReactNode;
}) {
  const intl = useIntl();
  const { maximumDisplayedValuesInList } = useProductFilterContext();

  const createTypeColumn = useCallback(
    (values?: string[]) => {
      return values?.length
        ? intl.formatMessage({
            id: "product-filter.list.header.values",
            defaultMessage: "Values",
          })
        : intl.formatMessage({
            id: "product-filter.list.header.range",
            defaultMessage: "Range",
          });
    },
    [intl],
  );

  const createDataColumn = useCallback(
    (values?: string[], from?: string, to?: string) => {
      if (values?.length) {
        const hasEllipsis =
          !!maximumDisplayedValuesInList && values.length > maximumDisplayedValuesInList;

        return `${values?.slice(0, maximumDisplayedValuesInList)?.join(", ")}${
          hasEllipsis ? "..." : ""
        }`;
      }

      return `${from} - ${to}`;
    },
    [maximumDisplayedValuesInList],
  );

  const createSettingsColumn = useCallback(
    (exactMatch?: boolean, negation?: boolean, includeMissing?: boolean) => {
      const settings: string[] = [];

      if (exactMatch) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.exact-match",
            defaultMessage: "Exact match",
          }),
        );
      }

      if (negation) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.negation",
            defaultMessage: "Negation",
          }),
        );
      }

      if (includeMissing) {
        settings.push(
          intl.formatMessage({
            id: "product-filter.list.settings.include-missing",
            defaultMessage: "Include missing",
          }),
        );
      }
      return settings.join(", ");
    },
    [intl],
  );

  const actionColumns = useMemo<TableColumnDef<IEVAFilterArrayItem>[]>(() => {
    if (customRowActions) {
      return [
        {
          header: "",
          align: "end",
          id: "actions",
          cellType: "button",
          accessorKey: "keyName",
          cell: ({ row }) => customRowActions?.(row.original, total === 1 && row.index === 0),
        },
      ];
    }
    return [];
  }, [customRowActions, total]);

  const columns = useMemo<TableColumnDef<IEVAFilterArrayItem>[]>(
    () => [
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.property-name",
          defaultMessage: "Property name",
        }),
        id: "keyName",
        accessorKey: "keyName",
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.type",
          defaultMessage: "Type",
        }),
        id: "Values",
        accessorKey: "Values",
        cell: ({ getValue }) => <span>{createTypeColumn(getValue())}</span>,
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.data",
          defaultMessage: "Data",
        }),
        id: "From",
        accessorKey: "From",
        cell: ({ row }) => (
          <span>{createDataColumn(row.original.Values, row.original.From, row.original.To)}</span>
        ),
      },
      {
        header: intl.formatMessage({
          id: "product-filter.list.header.settings",
          defaultMessage: "Settings",
        }),
        id: "ExactMatch",
        accessorKey: "ExactMatch",
        cell: ({ row }) => (
          <span>
            {createSettingsColumn(
              row.original.ExactMatch,
              row.original.Negation,
              row.original.IncludeMissing,
            )}
          </span>
        ),
        width: "100px",
      },
      ...actionColumns,
    ],
    [actionColumns, createDataColumn, createSettingsColumn, createTypeColumn, intl],
  );

  return columns;
}
