import { useEffect } from "react";

const useWindowResize = (handler: () => void) => {
  useEffect(() => {
    handler();
    window.addEventListener("resize", handler);

    return () => window.removeEventListener("resize", handler);
  }, [handler]);
};

export default useWindowResize;
