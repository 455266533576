import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

const useDateFormat = () => {
  const intl = useIntl();
  const [dateFormat, setDateFormat] = useState<string>();
  const formatDate = useCallback(
    (dateString: string) => {
      if (dateString.length === 10) {
        return intl.formatDate(dateString, {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });
      }
      return dateString;
    },
    [intl],
  );

  const getDateParts = useCallback(() => intl.formatDateToParts(new Date()), [intl]);

  useEffect(() => {
    const dateParts = getDateParts();

    const mapped = dateParts.map((part) => {
      switch (part.type) {
        case "day":
          return "DD";
        case "month":
          return "MM";
        case "year":
          return "YYYY";
        case "literal":
          return part.value;
        default:
          return "";
      }
    });

    setDateFormat(mapped.join(""));
  }, [setDateFormat, getDateParts]);

  return {
    formatDate,
    dateFormat,
  };
};

export default useDateFormat;
