import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { useHandleSWRFamily } from "hooks/suite-react-hooks/use-handle-swr";
import useSetRequest from "hooks/suite-react-hooks/use-set-request";

import {
  productRequirementsForOrderLineServiceState,
  productRequirementsForOrderLineValuesSelector,
} from ".";

const useProductRequirementsForOrderLine = (orderID?: number, orderLineID?: number) => {
  const request = useMemo<EVA.Core.GetProductRequirementValuesForOrderLine | undefined>(() => {
    if (orderID && orderLineID) {
      return {
        OrderID: orderID,
        OrderLineID: orderLineID,
      };
    }
    return undefined;
  }, [orderID, orderLineID]);

  useSetRequest(
    productRequirementsForOrderLineServiceState.request({ orderID, orderLineID }),
    request,
  );

  const productRequirementsForOrderLine = hooks.useGetState(
    productRequirementsForOrderLineValuesSelector({ orderID, orderLineID }),
  );

  const productRequirementsForOrderLineLoading = hooks.useIsLoading({
    state: productRequirementsForOrderLineServiceState.response({ orderID, orderLineID }),
  });

  useHandleSWRFamily(productRequirementsForOrderLineServiceState, { orderID, orderLineID });

  return {
    productRequirementsForOrderLine,
    productRequirementsForOrderLineLoading,
  };
};

export default useProductRequirementsForOrderLine;
