import { useProductFilterContext } from "../product-filter-context";

import ProductOverviewModalContent from "./product-overview-modal-content";

export default function ProductOverviewModal({
  variant = "material",
}: {
  variant?: "lyra" | "material";
}) {
  const {
    openProductOverviewModal,
    placeholderImageUrl,
    productFilter,
    setOpenProductOverviewModal,
  } = useProductFilterContext();

  return (
    <ProductOverviewModalContent
      variant={variant}
      productFilter={productFilter}
      openModal={openProductOverviewModal}
      setOpenModal={setOpenProductOverviewModal}
      placeholderImageUrl={placeholderImageUrl}
    />
  );
}
