import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listEntityFieldValidatorsLoaderQuery,
  serviceQueryKeys: listEntityFieldValidatorsQueryKeys,
  useServiceQueryHook: useListEntityFieldValidatorsQuery,
  useServiceQueryWithRequest: useListEntityFieldValidatorsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListEntityFieldValidators);

export const {
  serviceLoaderQuery: getEntityFieldValidatorLoaderQuery,
  serviceQueryKeys: getEntityFieldValidatorQueryKeys,
  useServiceQueryHook: useGetEntityFieldValidatorQuery,
} = createServiceQuery(CoreManagement.GetEntityFieldValidator);

export const {
  serviceQuery: listEntityFieldValidatorEntityFieldNamesQuery,
  useServiceQueryHook: useListEntityFieldValidatorEntityFieldNamesQuery,
} = createServiceQuery(CoreManagement.ListEntityFieldValidatorEntityFieldNames);
