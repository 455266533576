export const getStringSafeDisplay = (
  value = "",
  {
    endCharactersNumber = 3,
    matchAsterisksToStringLength = false,
    numberOfAsterisks = 5,
    startCharactersNumber = 3,
  },
) =>
  `${value.slice(0, startCharactersNumber)}${"*".repeat(
    matchAsterisksToStringLength
      ? value.length - startCharactersNumber - endCharactersNumber
      : numberOfAsterisks,
  )}${value.slice(value.length - endCharactersNumber, value.length)}`;

export const removeTrailingSlash = (value: string) => value.replace(/\/$/, "");
