import { DefaultToastOptions, toast } from "react-hot-toast";

import { SvgIcon } from "@new-black/lyra";

export const warningToast = (message: string, options?: DefaultToastOptions) => {
  return toast(message, {
    ...options,
    icon: <SvgIcon name="alert-warning" className="h-5 w-5 text-[#fdda0d]" />,
  });
};
