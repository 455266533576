export interface IAvailableUserTaskDto extends EVA.Core.AvailableUserTaskDto {
  compositeUserTaskID?: number;
  hasOpenAmount?: boolean;
}

export enum AvailableUserTasksTableColumns {
  Deadline,
  Urgency,
  Status,
  Description,
  SpecialProducts,
  Assignee,
  AssigneeAvatar,
  NumberOfProducts,
  Label,
  OrderID,
  PrintStatus,
  TaskID,
  ShippingMethod,
  ShipmentID,
  StartTime,
  RelatedOrderID,
  HasOpenAmount,
}
