import { useMemo } from "react";

import useFormattedAddressParts from "hooks/use-formatted-address-parts";

export const useAddressDisplay = (address?: EVA.Core.AddressDto, showAddressName?: boolean) => {
  const { countryPart, namePart, part1, part2 } = useFormattedAddressParts(address, ", ");

  const addressDisplay = useMemo(() => {
    const result = [showAddressName ? namePart : "", part1, part2, countryPart].filter(
      (part) => part !== "",
    );

    return result?.length ? result : undefined;
  }, [countryPart, namePart, part1, part2, showAddressName]);

  return addressDisplay;
};

export const AddressDisplayProvider = ({
  address,
  children,
  showAddressName,
}: {
  children: (addressDisplay: string[] | undefined) => React.ReactNode;
  address: EVA.Core.AddressDto;
  showAddressName?: boolean;
}) => {
  const addressDisplay = useAddressDisplay(address, showAddressName);
  return <>{children(addressDisplay)}</>;
};
