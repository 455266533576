import { useCallback, useState } from "react";

const useWarningDialog = (onConfirm: () => void) => {
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  const onCloseWarningDialog = useCallback(
    (isConfirmed: boolean) => {
      setShowWarningDialog(false);

      if (isConfirmed) {
        onConfirm();
      }
    },
    [onConfirm],
  );
  return {
    showWarningDialog,
    setShowWarningDialog,
    onCloseWarningDialog,
  };
};

export default useWarningDialog;
