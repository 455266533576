import { builders, hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const getCycleCountOriginTypesServiceState = builders.buildServiceState({
  service: Core.GetCycleCountOriginTypes,
  key: "Core:GetCycleCountOriginTypes",
  requireEmployee: true,
  allowEmptyRequest: true,
});

export const useGetCycleCountOriginTypes = () => {
  const data = hooks.useGetState(getCycleCountOriginTypesServiceState.response);

  const isLoading = hooks.useIsLoading({ state: getCycleCountOriginTypesServiceState.response });

  return { data: data?.Result, isLoading };
};
