import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Table, TableColumnDef, TableProps } from "@new-black/lyra";

import { ProductSummary } from "../product-summary/lyra";

type TProductsOverviewTable = Required<
  Pick<TableProps<any>, "data" | "start" | "setStart" | "limit" | "setLimit" | "total">
> &
  Partial<Pick<TableProps<any>, "columns">>;

export const LyraProductsOverviewTable = ({
  columns,
  data,
  limit,
  placeholderImageUrl,
  setLimit,
  setStart,
  start,
  total,
}: TProductsOverviewTable & {
  placeholderImageUrl?: string;
}) => {
  const intl = useIntl();

  const defaultColumns = useMemo<TableColumnDef<any>[]>(
    () => [
      {
        header: intl.formatMessage({
          id: "composite.products-overview.list.header.product",
          defaultMessage: "Product",
        }),
        accessorKey: "primary_image.blob",
        id: "primary_image.blob",

        cell: ({ row }) => (
          <ProductSummary
            productID={row.original?.product_id}
            customID={row.original?.custom_id}
            backendID={row.original?.backend_id}
            productName={row.original?.display_value}
            productImageBlobID={row.original.primary_image?.blob}
            enlargeImage
            placeholderImageUrl={placeholderImageUrl}
            imageSize={30}
          />
        ),
      },
    ],
    [intl, placeholderImageUrl],
  );

  return (
    <Table
      aria-label={intl.formatMessage({ id: "generic.label.products", defaultMessage: "Products" })}
      data={data}
      limit={limit}
      setLimit={setLimit}
      start={start}
      setStart={setStart}
      total={total ?? 0}
      columns={columns ?? defaultColumns}
    />
  );
};
