import { ComponentProps, useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";
import { isEqual, uniq } from "lodash";

import useRecoilDebouncedValue from "hooks/suite-react-hooks/use-recoil-debounced-value";

import { MultiIDSearchListField } from "./search-list-field-generator.multi.types";
import {
  ItemKey,
  MultiSearchListField,
  SpyCallback,
  SpyFunction,
} from "./search-list-field-generator.types";

type Controlled<T> = Omit<T, "defaultValue">;
type Uncontrolled<T> = Omit<T, "values" | "onChange">;
type UncontrolledWithChangeCallback<T> = Omit<T, "values">;

export interface IMultiSearchListFieldWrapperReturnValues<Item> {
  Controlled: (inputProps: Controlled<MultiSearchListField<Item>["Props"]>) => JSX.Element;
  Uncontrolled: (
    inputProps: Uncontrolled<MultiSearchListField<Item>["Uncontrolled"]>,
  ) => JSX.Element;
  Formik: (inputProps: Controlled<MultiSearchListField<Item>["Formik"]>) => JSX.Element;
  Recoil: (inputProps: Controlled<MultiSearchListField<Item>["Recoil"]>) => JSX.Element;
}

/** Wrapper component that based on a basic MultiSearchListField
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const MultiSearchListFieldWrapper = <Item,>(
  SearchListField: (props: MultiSearchListField<Item>["Props"]) => JSX.Element,
): IMultiSearchListFieldWrapperReturnValues<Item> => {
  const Controlled = (
    inputProps: Controlled<
      ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Controlled"]>
    >,
  ) => (
    <SearchListField
      {...inputProps}
      onClear={() => {
        if (inputProps.onClear) {
          inputProps.onClear();
          return;
        }
        inputProps.onChange?.(undefined);
      }}
    />
  );

  const Uncontrolled = (
    inputProps: Uncontrolled<
      ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Uncontrolled"]>
    >,
  ) => <SearchListField {...inputProps} />;

  const Formik = ({
    name,
    ...inputProps
  }: Controlled<ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Formik"]>>) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[] | undefined>(name);
    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        setFieldValue(name, newValue);
      },
      [name, setFieldValue],
    );
    return (
      <SearchListField
        {...inputProps}
        values={field.value}
        onChange={handleChange}
        errorMessage={meta.touched ? meta.error : undefined}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: Controlled<ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Recoil"]>>) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue);
      },
      [customOnChange, setRecoilValue],
    );
    return (
      <Controlled
        {...inputProps}
        values={recoilValue}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};

export interface IMultiSearchListFieldWrapperWithIDReturnValues<
  Item extends object,
  IDType extends ItemKey,
> {
  Controlled: (
    inputProps: Controlled<MultiIDSearchListField<Item, IDType>["Props"]>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: UncontrolledWithChangeCallback<
      MultiIDSearchListField<Item, IDType>["Uncontrolled"]
    >,
  ) => JSX.Element;
  Formik: (inputProps: Controlled<MultiIDSearchListField<Item, IDType>["Formik"]>) => JSX.Element;
  Recoil: (inputProps: Controlled<MultiIDSearchListField<Item, IDType>["Recoil"]>) => JSX.Element;
}

/** Wrapper component that is based on a basic, ID-based MultiSearchListField
 * that allows to pass a hook that retrievs an item by ID */
export const MultiSearchListFieldIDWrapper = <Item extends object, IDType extends ItemKey>(
  SearchListField: (props: MultiSearchListField<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  getItemKey: (item: Item) => IDType,
  useItemsByID: (
    ids: IDType[] | undefined,
    listItems: Item[] | undefined,
  ) => {
    data: Item[] | undefined;
    isLoading?: boolean;
  },
): IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDType> => {
  const Controlled = (
    inputProps: Controlled<
      ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Controlled"]>
    >,
  ) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: items, isLoading } = useItemsByID(inputProps.values, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = uniq(listItems?.map(getItemKey));
        const itemsIDs = uniq(args.listItems?.map(getItemKey));

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        inputProps.onChange?.(newValue?.map(getItemKey));
      },
      [inputProps],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        defaultValue={undefined}
        isLoading={isLoading || inputProps.isLoading}
        spyCallback={invokeSpy}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: UncontrolledWithChangeCallback<
    ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Uncontrolled"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemsIds, setCurrentItemsIds] = useState(defaultValue);

    useEffect(() => setCurrentItemsIds(defaultValue), [defaultValue]);

    const { data: currentItems, isLoading } = useItemsByID(currentItemsIds, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = uniq(listItems?.map(getItemKey));
        const itemsIDs = uniq(args.listItems?.map(getItemKey));

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );

    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        setCurrentItemsIds(newValue?.map(getItemKey));
        inputProps.onChange?.(newValue?.map(getItemKey));
      },
      [inputProps],
    );

    return (
      <>
        <SearchListField
          {...inputProps}
          name={undefined}
          values={currentItems}
          onChange={handleChange}
          isLoading={isLoading || inputProps.isLoading}
          spyCallback={invokeSpy}
          onClear={() => {
            if (inputProps.onClear) {
              inputProps.onClear();
              return;
            }
            handleChange(undefined);
          }}
        />
        {currentItems?.length
          ? currentItems
              .map(getItemKey)
              .filter((id) => id !== undefined)
              .map((id, idx) => (
                <input readOnly key={idx} type="hidden" name={inputProps.name} value={id} />
              ))
          : null}
      </>
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: Controlled<
    ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Formik"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<IDType[] | undefined>(name);
    const { data: items, isLoading } = useItemsByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = uniq(listItems?.map(getItemKey));
        const itemsIDs = uniq(args.listItems?.map(getItemKey));

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        setFieldValue(name, newValue?.map(getItemKey));
      },
      [name, setFieldValue],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        isLoading={isLoading || inputProps.isLoading}
        spyCallback={invokeSpy}
        defaultValue={undefined}
        errorMessage={meta.touched ? meta.error : undefined}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: Controlled<
    ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDType>["Recoil"]>
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: items, isLoading } = useItemsByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = uniq(listItems?.map(getItemKey));
        const itemsIDs = uniq(args.listItems?.map(getItemKey));

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    const handleChange = useCallback(
      (newValue: Item[] | undefined) => {
        customOnChange
          ? customOnChange(setRecoilValue, newValue?.map(getItemKey))
          : setRecoilValue(newValue?.map(getItemKey));
      },
      [customOnChange, setRecoilValue],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        defaultValue={undefined}
        isLoading={isLoading || inputProps.isLoading}
        spyCallback={invokeSpy}
        onChange={handleChange}
        onClear={() => {
          if (inputProps.onClear) {
            inputProps.onClear();
            return;
          }
          handleChange(undefined);
        }}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
