import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

const getDisputesForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:GetInvoiceDisputesForInvoice",
  allowEmptyRequest: false,
  service: Core.GetInvoiceDisputesForInvoice,
});

export default getDisputesForInvoiceServiceState;
