import { useState } from "react";

import { cva } from "class-variance-authority";
import { Separator, Text } from "@new-black/lyra";
import { Button } from "react-aria-components";

import { Link } from "components/routing";
import useEventListener from "hooks/suite-react-hooks/use-event-listener";
import routeDefinitions from "routes/route-definitions";

import { ChapterFinderDialog } from "../chapter-finder-dialog";

import { MenuHeaderProps } from "./menu.types";
import { MenuLogo } from "./menu-logo";

const menuHeaderTopbarStyle = cva(
  ["absolute", "h-1", "top-0", "w-full", "transition-opacity", "duration-200", "delay-200"],
  {
    variants: {
      state: {
        open: ["opacity-0"],
        closed: ["opacity-100"],
        hovered: ["opacity-100"],
      },
    },
  },
);

const menuHeaderModuleLogoStyle = cva(
  ["flex", "gap-3", "items-center", "transition", "ease-in-out", "py-2"],
  {
    variants: {
      state: {
        open: ["opacity-100", "duration-200", "delay-200"],
        closed: ["opacity-0", "duration-200"],
        hovered: ["opacity-100", "duration-200", "delay-200"],
      },
    },
  },
);

export const MenuHeader = ({
  menuState,
  moduleColor,
  ModuleLogo,
  moduleName,
  setMouseEntered,
  setIsFocused,
}: MenuHeaderProps) => {
  const [chapterFinderDialogOpen, setChapterFinderDialogOpen] = useState(false);

  // listener for command + k or control + k. It opens or closes the chapter finder dialog
  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "k" && e.metaKey) {
      setMouseEntered(false);
      setIsFocused(false);
      setChapterFinderDialogOpen((open) => !open);
    }
  });

  return (
    <>
      <div
        className="relative h-[70px]"
        onMouseEnter={() => {
          if (menuState === "closed") setMouseEntered(true);
        }}
        onMouseLeave={() => {
          if (menuState === "closed") setMouseEntered(false);
        }}
      >
        <div
          className={menuHeaderTopbarStyle({ state: menuState })}
          style={{ backgroundColor: moduleColor }}
        />
        <div className="flex h-full items-center gap-4 p-4">
          <Link to={routeDefinitions.dashboard.search.path}>
            <MenuLogo />
          </Link>
          <Separator orientation="vertical" className="h-[30px]" />
          <Button
            className="cursor-pointer rounded-md border-0 bg-transparent outline-none ring-[color:var(--color-primary-3)] transition-transform duration-200 ease-in-out focus-visible:border focus-visible:border-focus focus-visible:shadow-highlight"
            type="button"
            onPress={() => {
              setMouseEntered(false);
              setChapterFinderDialogOpen(true);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          >
            <div className={menuHeaderModuleLogoStyle({ state: menuState })}>
              <svg width="24" height="24" viewBox="0 0 56 56">
                {ModuleLogo}
              </svg>
              <Text variant="heading-2" elementType="span">
                {moduleName}
              </Text>
            </div>
          </Button>
        </div>
      </div>
      <ChapterFinderDialog
        isOpen={chapterFinderDialogOpen}
        closeModal={() => {
          setChapterFinderDialogOpen(false);
        }}
      />
    </>
  );
};
