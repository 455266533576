import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "@new-black/lyra";

import Dropzone from "components/suite-ui/dropzone";
import useDropzone from "components/suite-ui/dropzone/use-dropzone";
import LinkButton from "components/suite-ui/link-button";
import Text from "components/suite-ui/text";
import { Checkbox } from "components/ui/checkbox";

import { TUploadModalProps } from "./upload-modal.types";

const UploadModalContent = ({
  accept,
  allowAsync,
  error,
  formID,
  isAsync,
  isLoading,
  messages,
  onClose,
  onDownloadTemplate,
  onUpload,
  setFileData,
  setFileMimeType,
  setFileName,
  title,
}: Omit<TUploadModalProps, "open">) => {
  const intl = useIntl();

  const [processAsync, setProcessAsync] = useState(false);

  const { handleFileDrop, isFileDropped, uploadedData, ...dropzoneStrings } = useDropzone(
    setFileData,
    setFileMimeType,
    setFileName,
  );

  return (
    <div className="flex flex-col gap-5 p-5">
      <Text variant="h2">
        {title ?? <FormattedMessage id="generic.label.upload-data" defaultMessage="Upload data" />}
      </Text>

      {onDownloadTemplate ? (
        <Text>
          {messages?.downloadTemplate ?? (
            <FormattedMessage
              id="generic.label.upload-modal.download-template"
              defaultMessage="Use the {template} to import data easily and quickly."
              values={{
                template: (
                  <LinkButton onClick={onDownloadTemplate}>
                    <FormattedMessage
                      id="generic.label.excel-template"
                      defaultMessage="Excel template"
                    />
                  </LinkButton>
                ),
              }}
            />
          )}
        </Text>
      ) : null}

      <Dropzone
        readAsDataUrl
        accept={accept}
        handleFileDrop={handleFileDrop}
        isFileDropped={isFileDropped}
        dropzoneStrings={dropzoneStrings}
      />

      {error ? (
        <div>
          <Text color="error">{error}</Text>
        </div>
      ) : null}

      <div className="flex items-center justify-between gap-5">
        <div className="flex flex-col">
          {allowAsync ? (
            <Checkbox
              name="ProcessAsync"
              label={intl.formatMessage({
                id: "generic.label.process-data-asynchronously",
                defaultMessage: "Process data asynchronously",
              })}
              value={processAsync}
              onChange={() => setProcessAsync((current) => !current)}
            />
          ) : null}

          {isAsync ? (
            <Text>
              <FormattedMessage
                id="generic.label.upload-modal.async-upload"
                defaultMessage="The upload of the file will be done asynchronously."
              />
            </Text>
          ) : null}
        </div>

        <div className="flex gap-5">
          <Button variant="secondary" onPress={onClose} isDisabled={isLoading}>
            <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
          </Button>

          <Button
            type={formID ? "submit" : "button"}
            form={formID}
            variant="primary"
            onPress={() => {
              if (uploadedData && onUpload) {
                onUpload(uploadedData, processAsync);
              }
            }}
            isDisabled={isLoading || !isFileDropped}
            isLoading={isLoading}
          >
            <FormattedMessage id="generic.label.upload" defaultMessage="Upload" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UploadModalContent;
