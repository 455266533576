import { useCallback } from "react";
import { useIntl } from "react-intl";

import { toast } from "components/suite-ui/toast";

export const useCopyToClipboard = () => {
  const intl = useIntl();
  const copyToClipboard = useCallback(
    (value: string) => {
      if (value) {
        navigator.clipboard.writeText(value);
        toast.success(
          intl.formatMessage({
            id: "generic.label.copied",
            defaultMessage: "Copied to clipboard",
          }),
        );
      }
    },
    [intl],
  );
  return copyToClipboard;
};
