import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { IRoute, MenuRouteDefinition } from "components/shared/menu";

export const filterConditionalRoutes = (routeDefinitionsArray: MenuRouteDefinition[]): IRoute[] => {
  return routeDefinitionsArray.reduce<IRoute[]>((acc, route) => {
    if (route.condition) {
      acc.push({
        label: route.label,
        routeDefinition: route.routeDefinition,
        icon: <SvgIcon name={route.iconName} />,
        end: route.end ?? true,
        count: route.count,
      });
    }
    return acc;
  }, []);
};

export const useConditionalRoutes = <TData extends object, TCounts extends object>(
  data: TData | undefined | null,
  getMenuRouteDefinitions: (
    intl: IntlShape,
    data: TData | undefined,
    counts?: TCounts,
  ) => MenuRouteDefinition[],
  counts?: TCounts,
): IRoute[] => {
  const intl = useIntl();

  const routeDefinitionsArray = useMemo(
    () => (data ? getMenuRouteDefinitions(intl, data, counts) : []),
    [getMenuRouteDefinitions, intl, data, counts],
  );

  const routes = useMemo(
    () => filterConditionalRoutes(routeDefinitionsArray),
    [routeDefinitionsArray],
  );

  return routes;
};
