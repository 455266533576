import { useCallback } from "react";

import { useMonaco } from "@monaco-editor/react";

import { ScriptEditorLanguage } from "types/monaco-editor-language";

export function useSetScriptLanguageConfiguration(dialect: ScriptEditorLanguage) {
  const monaco = useMonaco();

  const setScriptLanguageConfiguration = useCallback(() => {
    return monaco?.languages.setLanguageConfiguration(dialect, {
      autoClosingPairs: [
        { open: "'", close: "'" },
        { open: "(", close: ")" },
        { open: "[", close: "]" },
      ],
      indentationRules: {
        increaseIndentPattern: /^.*(then|do)\b/,
        decreaseIndentPattern: /^.*end$/,
      },
      comments: {
        lineComment: "#",
      },
    });
  }, [dialect, monaco?.languages]);

  return setScriptLanguageConfiguration;
}
