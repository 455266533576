import { Field, FieldProps, FormikProps } from "formik";

import NumberInput from "components/suite-ui/number-input";

export interface IEvaProductSetRequiredQuantityOfProductsInput {
  onFieldBlur: (form: FormikProps<any>) => void;
  label: string;
  required?: boolean;
  passive?: boolean;
}

const EvaProductSetRequiredQuantityOfProductsInput = ({
  label,
  onFieldBlur,
  passive,
  required,
}: IEvaProductSetRequiredQuantityOfProductsInput) => {
  return (
    <Field name="requiredQuantityOfProducts">
      {(props: FieldProps) => (
        <NumberInput
          label={label}
          precision={0}
          {...props.field}
          passive={passive}
          required={required}
          value={props.field.value ?? ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.form.setFieldValue(
              props.field.name,
              e.target.value ? parseInt(e.target.value, 10) : undefined,
            );
          }}
          error={props.meta.error && props.meta.touched ? true : undefined}
          helperText={props.meta.error && props.meta.touched ? props.meta.error : undefined}
          allowNegative={false}
          onBlur={() => onFieldBlur(props.form)}
        />
      )}
    </Field>
  );
};

export default EvaProductSetRequiredQuantityOfProductsInput;
