import { useCallback, useEffect, useMemo, useState } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { uniqBy } from "lodash";

import ErrorBoundary from "components/suite-ui/error-boundary";
import { SearchListField } from "components/ui/search-list-field";
import { TSearchListFieldKeyType } from "components/ui/search-list-field/types";

import {
  ILocalSearchListFieldGeneratorProps,
  ISearchListFieldGeneratorProps,
  MultiSearchListField,
  SpyCallback,
  TSearchListFieldGeneratorOptionsProps,
  TUncontrolledValueSelectorProp,
} from "./search-list-field-generator.types";

export const generateMultiSearchListField = <SVC extends IEvaServiceDefinition, Item>({
  defaultLabel,
  filterOptions = { disabled: true },
  getItemFromResponse,
  getSecondaryLabel,
  idKey,
  initialData,
  labelKey,
  renderOption,
  rightAdornment,
  useServiceQuery,
}: ISearchListFieldGeneratorProps<SVC, Item>) => {
  const MultiSearchListField = ({
    autoFocus,
    classNames,
    disabled,
    disablePopoverPortal,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onChange,
    required,
    showClearIcon,
    showOptionEndAdornment,
    small,
    spyCallback,
    uncontrolledValueSelector,
    values,
    warning,
  }: MultiSearchListField<Item>["Props"] &
    SpyCallback<Item> &
    TUncontrolledValueSelectorProp<Item> &
    TSearchListFieldGeneratorOptionsProps<Item>) => {
    const [
      [input, setInput],
      { data, isFetching: isLoading },
      [request, setRequest],
      [configureLoadMoreButton, isLoadMoreLoading, setIsLoadMoreLoading],
    ] = useServiceQuery();

    const items = useMemo(
      () =>
        uniqBy(
          [...(initialData ?? []), ...(values ?? []), ...(getItemFromResponse(data) ?? [])],
          idKey,
        ),
      [data, values],
    );

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    const loadMoreButtonConfig = useMemo(
      () => configureLoadMoreButton?.(data),
      [configureLoadMoreButton, data],
    );

    return (
      <ErrorBoundary>
        <SearchListField.Controlled<Item>
          multiple
          query={input}
          small={small}
          error={error}
          values={values}
          disabled={disabled}
          setQuery={setInput}
          required={required}
          items={items ?? []}
          setValues={onChange}
          autoFocus={autoFocus}
          helperText={helperText}
          label={label ?? defaultLabel}
          filterOptions={filterOptions}
          endAdornment={rightAdornment}
          idKey={idKey as TSearchListFieldKeyType<Item>}
          labelKey={labelKey as TSearchListFieldKeyType<Item>}
          isLoading={{
            options: isOutsideLoading?.options || isLoading || isLoadMoreLoading,
            ...isOutsideLoading,
          }}
          getSecondaryLabel={getSecondaryLabel}
          renderValue={renderOption ? (value) => renderOption(value) : undefined}
          onLoadMore={
            loadMoreButtonConfig?.shouldShowLoadMoreButton ?? false
              ? () => {
                  setIsLoadMoreLoading(true);
                  setRequest(loadMoreButtonConfig?.onLoadMore(request));
                }
              : undefined
          }
          showClearIcon={showClearIcon}
          showOptionEndAdornment={showOptionEndAdornment}
          disablePopoverPortal={disablePopoverPortal}
          warning={warning}
          classNames={classNames}
        />

        {name ? (
          <SearchListField.HiddenInputs
            multiple
            name={name}
            values={values}
            uncontrolledValueSelector={uncontrolledValueSelector}
            idKey={idKey}
          />
        ) : null}
      </ErrorBoundary>
    );
  };

  return MultiSearchListField;
};

export const generateMultiLocalSearchListField = <Item, IDKey extends keyof Item>({
  defaultLabel,
  frontendFilter,
  getSecondaryLabel,
  idKey,
  items: itemsArray,
  labelKey,
}: ILocalSearchListFieldGeneratorProps<Item, IDKey>) => {
  const MultiSearchListField = ({
    autoFocus,
    disabled,
    disablePopoverPortal,
    error,
    helperText,
    isLoading: isOutsideLoading,
    label,
    name,
    onChange,
    required,
    showClearIcon,
    showOptionEndAdornment,
    small,
    spyCallback,
    uncontrolledValueSelector,
    values,
    warning,
  }: MultiSearchListField<Item>["Props"] &
    SpyCallback<Item> &
    TUncontrolledValueSelectorProp<Item> &
    TSearchListFieldGeneratorOptionsProps<Item>) => {
    const [input, setLocalQuery] = useState<string | undefined>();

    const setInput = useCallback(
      (newValue: string | undefined) => {
        if (newValue !== input) {
          setLocalQuery(newValue);
        }
      },
      [input],
    );

    const items = useMemo(
      () =>
        frontendFilter
          ? uniqBy([...(values ?? []), ...(itemsArray ?? [])], idKey).filter(frontendFilter)
          : uniqBy([...(values ?? []), ...(itemsArray ?? [])], idKey),
      [values],
    );

    useEffect(() => spyCallback?.({ listItems: items }), [items, spyCallback]);

    return (
      <ErrorBoundary>
        <SearchListField.Controlled<Item>
          multiple
          small={small}
          error={error}
          query={input}
          values={values}
          disabled={disabled}
          setQuery={setInput}
          required={required}
          items={items ?? []}
          setValues={onChange}
          autoFocus={autoFocus}
          helperText={helperText}
          label={label ?? defaultLabel}
          isLoading={isOutsideLoading}
          labelKey={labelKey as TSearchListFieldKeyType<Item>}
          idKey={idKey as unknown as TSearchListFieldKeyType<Item>}
          getSecondaryLabel={getSecondaryLabel}
          showClearIcon={showClearIcon}
          showOptionEndAdornment={showOptionEndAdornment}
          disablePopoverPortal={disablePopoverPortal}
          warning={warning}
        />

        {name ? (
          <SearchListField.HiddenInputs
            multiple
            name={name}
            values={values}
            uncontrolledValueSelector={uncontrolledValueSelector}
            idKey={idKey}
          />
        ) : null}
      </ErrorBoundary>
    );
  };

  return MultiSearchListField;
};
