import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import {
  listPaymentTypesQuery,
  listPaymentTypesQueryKeys,
  useGetPaymentTypeByIDQuery,
  useListPaymentTypesQuery,
} from "models/payment-types";
import { AutocompleteGenerator } from "util/autocomplete-generator";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

function useGetPaymentTypeByID(id: number | undefined) {
  const { data, isFetching } = useGetPaymentTypeByIDQuery(id ? { ID: id } : undefined, {
    loaderKey: id ? ["autocomplete", id.toString()] : undefined,
  });
  const item = useMemo(
    () => (data && data?.ID === id ? { ID: data.ID, Name: data?.Name } : undefined),
    [data, id],
  );
  return { data: item, isLoading: isFetching };
}

function useGetPaymentTypesByID(ids: number[] | undefined) {
  const { data, isFetching } = useListPaymentTypesQuery(
    ids && ids.length
      ? { PageConfig: { Start: 0, Limit: ids.length, Filter: { IDs: ids } } }
      : undefined,
    {
      loaderKey:
        ids && ids.length ? ["autocomplete", ids.map((id) => id.toString()).join(",")] : undefined,
    },
  );
  const items = useMemo(() => {
    if (!data || !ids || !ids.length) return undefined;
    const items = data?.Result?.Page?.filter((item) => ids?.includes(item.ID))?.map((item) => ({
      ID: item.ID,
      Name: item.Name,
    }));
    return items;
  }, [data, ids]);
  return { data: items, isLoading: isFetching };
}

export const {
  MultiIDAutocomplete: MultiPaymentTypeIDAutocomplete,
  SingleIDAutocomplete: SinglePaymentTypeIDAutocomplete,
} = AutocompleteGenerator<CoreManagement.ListPaymentTypes, { ID: number; Name: string }, "ID">({
  idKey: "ID",
  labelKey: "Name",
  useItemByID: useGetPaymentTypeByID,
  useItemsByID: useGetPaymentTypesByID,
  getItemFromResponse: (resp) =>
    resp?.Result?.Page?.map((item) => ({ ID: item.ID, Name: item.Name })),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      query: listPaymentTypesQuery,
      getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
      initialRequest: { PageConfig: { Start: 0, Limit: 10, Filter: { Name: undefined } } },
      queryKey: (req) => listPaymentTypesQueryKeys.withRequest(req, ["autocomplete"]),
      setQueryRequest: (req, newValue) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: { Name: newValue === "" ? undefined : newValue },
        },
      }),
    }),
});

export const generatePaymentTypeSearchListField = (filters?: EVA.Core.ListPaymentTypesFilter) =>
  SearchListFieldGenerator<CoreManagement.ListPaymentTypes, { ID: number; Name: string }, number>({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    useItemByID: useGetPaymentTypeByID,
    useItemsByID: useGetPaymentTypesByID,
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((item) => ({ ID: item.ID, Name: item.Name })),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listPaymentTypesQuery,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...filters,
              Name: undefined,
            },
          },
        },
        queryKey: (req) => listPaymentTypesQueryKeys.withRequest(req, ["autocomplete"]),
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...filters, Name: newValue === "" ? undefined : newValue },
          },
        }),
        configureLoadMoreButton: (response) => ({
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
        }),
      }),
  });
