import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getCycleCountZonesQuery, getCycleCountZonesQueryKeys } from "models/cycle-count-zones";
import { THIRTY_SECONDS } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateCycleCountZonesSearchListField = () =>
  LyraSearchListFieldGenerator<
    Core.GetCycleCountZones,
    {
      ID: number;
      Name: string;
    },
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Result.map((zone) => ({
        ID: zone.ID as number,
        Name: zone.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.cycle-count-zones",
      defaultMessage: "Cycle count zones",
    }),
    useItemByID: (id, items) => ({ data: items?.find((item) => item.ID === id) }),
    useItemsByID: (ids, items) => {
      const filteredItems = useMemo(
        () => items?.filter((item) => ids?.includes(item.ID)),
        [ids, items],
      );

      if (!ids || ids.length === 0) {
        return {
          data: undefined,
          isLoading: false,
        };
      }

      return {
        data: filteredItems,
        isLoading: false,
      };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getCycleCountZonesQuery,
        staleTime: THIRTY_SECONDS,
        cacheTime: THIRTY_SECONDS,
        queryKey: getCycleCountZonesQueryKeys.withKey(["search-list-field"]),
      }),
  });
