import { builders } from "@springtree/eva-sdk-react-recoil";
import { Symphony } from "@springtree/eva-services-symphony";
import { atomFamily, selectorFamily } from "recoil";

export const getSheetByIDStateFamily = builders.buildServiceFamilyState({
  service: Symphony.ListSheets,
  key: "getSheetByID",
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const getSheetByIDResultSelector = selectorFamily({
  key: "getSheetByID:Result",
  get:
    (sheetId: number | undefined) =>
    ({ get }) =>
      get(getSheetByIDStateFamily.response(sheetId))?.Results?.[0],
});

export const currentGetSheetByIDStateFamily = atomFamily<
  EVA.Symphony.ListSheetsResponse | undefined,
  number | undefined
>({
  key: "getSheetByID:current",
  default: undefined,
});
