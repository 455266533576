import { z } from "zod";

export interface ICreateSynchronizationFormValues {
  Managers: {
    Name?: string;
    checked: boolean;
  }[];
}

export enum ESynchronizationManager {
  Roles = "Roles",
  Stencil = "Stencil",
  Settings = "Settings",
  Cookbook = "Cookbook",
  Symphony = "Symphony",
  All = "All",
}

export const SUPPORTED_MANAGERS = ["Roles", "Settings", "Symphony"];

export const availableSynchronizationManagersSchema = z
  .array(z.object({ Name: z.string() }).transform((value) => value?.Name))
  .transform((values) => values.filter((value) => SUPPORTED_MANAGERS.includes(value)))
  .optional();

export type AvailableSynchronizationManagers = z.infer<
  typeof availableSynchronizationManagersSchema
>;
