const integer = {
  maximumFractionDigits: 0,
} as const;

const float = {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
} as const;

const percent = {
  maximumFractionDigits: 2,
  style: "percent",
} as const;

const coordinate = {
  maximumFractionDigits: 6,
  useGrouping: false,
} as const;

const second = {
  maximumFractionDigits: 0,
  unit: "second",
  style: "unit",
} as const;

const id = {
  maximumFractionDigits: 0,
  useGrouping: false,
} as const;

/**
 * An object containing various formatting options to be used together with the `NumberField` component
 * from `@new-black/lyra`.
 *
 * @property integer - format integer values.
 * @property float - format floating-point values.
 * @property second - format time in seconds.
 * @property coordinate - format coordinates, useful for latitude and longitude.
 * @property percent - format percentage values.
 * @property id - format IDs (disables digits grouping).
 */
export const NUMBER_FORMAT_OPTIONS = {
  integer,
  float,
  second,
  coordinate,
  percent,
  id,
};
