import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import { IActionButton } from "./types";

const BlockActionButton = forwardRef<HTMLButtonElement, IActionButton>(
  ({ className, isDisabled, isLoading, onPress, tooltip }: IActionButton, ref) => {
    const intl = useIntl();

    return (
      <Button
        ref={ref}
        className={className}
        onPress={onPress}
        isDisabled={isDisabled}
        isLoading={isLoading}
        tooltip={
          tooltip ?? intl.formatMessage({ id: "generic.label.block", defaultMessage: "Block" })
        }
        variant="icon"
      >
        <SvgIcon name="block" />
      </Button>
    );
  },
);

BlockActionButton.displayName = "BlockActionButton";

export default BlockActionButton;
