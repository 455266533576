import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

export const organizationUnitDetailsState = builders.buildServiceState({
  service: Core.GetOrganizationUnitDetailed,
  key: "organizationUnitDetails",
  requireEmployee: true,
  allowEmptyRequest: false,
});

export const organizationUnitDetailsFamilyState = builders.buildServiceFamilyState({
  service: Core.GetOrganizationUnitDetailed,
  key: "organizationUnitDetailsFamily",
  requireEmployee: true,
  allowEmptyRequest: false,
  beforeRequest: ({ parameter, req }) => {
    if (!parameter || typeof parameter !== "number") return req;

    return {
      ...req,
      ID: parameter,
    };
  },
});
