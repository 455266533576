import { ReactNode } from "react";

import classNames from "classnames";

/**
 * @deprecated Since introduction of the new design system, this component is deprecated.
 * Use the `<Card>` of `@new-black/lyra` instead.
 */
export const Card = ({ children, className }: { children: ReactNode; className?: string }) => (
  <div className={classNames("rounded-lg bg-surface-primary", className)}>{children}</div>
);

Card.defaultProps = {
  className: undefined,
};
