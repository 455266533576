import { FormattedMessage, useIntl } from "react-intl";

import { Dialog, DialogProps } from "@material-ui/core";
import {
  Button,
  CardFooter,
  Dialog as LyraDialog,
  DialogCard,
  DialogProps as LyraDialogProps,
  Separator,
} from "@new-black/lyra";

import { ProductsOverview } from "../../products-overview";

interface Props {
  openModal: boolean;
  setOpenModal: (isOpen: boolean) => void;
  productFilter?: { [key: string]: EVA.Core.FilterModel };
  placeholderImageUrl?: string;
}

type MaterialProps = Props & { variant?: "material"; maxWidth?: DialogProps["maxWidth"] };
type LyraProps = Props & { variant: "lyra"; maxWidth?: LyraDialogProps["maxWidth"] };

export default function ProductOverviewModalContent({
  maxWidth,
  openModal,
  placeholderImageUrl,
  productFilter,
  setOpenModal,
  variant,
}: MaterialProps | LyraProps) {
  const intl = useIntl();

  if (variant === "lyra") {
    return (
      <LyraDialog
        isOpen={openModal}
        maxWidth={maxWidth ?? "lg"}
        aria-label={intl.formatMessage({
          id: "generic.label.products-overview",
          defaultMessage: "Products overview",
        })}
        onOpenChange={(newIsOpen) => {
          if (!newIsOpen) {
            setOpenModal(false);
          }
        }}
      >
        <DialogCard className="max-h-[calc(100vh-5rem)]">
          <ProductsOverview
            variant="lyra"
            filter={productFilter}
            placeholderImageUrl={placeholderImageUrl}
          />
          <CardFooter>
            <Button
              variant="primary"
              onPress={() => {
                setOpenModal(false);
              }}
            >
              <FormattedMessage id="generic.label.close" defaultMessage="Close" />
            </Button>
          </CardFooter>
        </DialogCard>
      </LyraDialog>
    );
  }

  return (
    <Dialog
      fullWidth
      open={openModal}
      maxWidth={maxWidth ?? "sm"}
      onClose={() => setOpenModal(false)}
    >
      <ProductsOverview filter={productFilter} placeholderImageUrl={placeholderImageUrl} />
      <div className="sticky bottom-0 bg-surface-primary">
        <Separator />
        <div className="flex items-center justify-end p-4">
          <Button
            variant="secondary"
            onPress={() => {
              setOpenModal(false);
            }}
          >
            <FormattedMessage id="generic.label.close" defaultMessage="Close" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
