import { Separator } from "@new-black/lyra";

import { IProductInformationProps } from "../product-information.types";

import ProductIdentityInformation from "./product-identity-information";
import ProductName from "./product-name";

const ProductBasicInformation = ({
  product,
  productNameProperty,
  variant,
}: IProductInformationProps) => {
  if (variant === "lyra") {
    return (
      <>
        <ProductName
          variant="lyra"
          productName={
            productNameProperty === "display_value"
              ? product?.display_value
              : product?.primitive_name
          }
        />
        <ProductIdentityInformation
          productId={product?.product_id}
          backendId={product?.backend_id}
          customId={product?.custom_id}
          barcode={product?.barcodes?.[0]}
          variant={variant}
        />
      </>
    );
  }

  return (
    <>
      <ProductName
        productName={
          productNameProperty === "display_value" ? product?.display_value : product?.primitive_name
        }
      />
      <div className="py-10">
        <Separator />
      </div>
      <ProductIdentityInformation
        productId={product?.product_id}
        backendId={product?.backend_id}
        customId={product?.custom_id}
        barcode={product?.barcodes?.[0]}
        variant={variant}
      />
      <div className="py-10">
        <Separator />
      </div>
    </>
  );
};

export default ProductBasicInformation;
