import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { currenciesResult, currenciesServiceState } from ".";

const useListCurrencies = () => {
  const data = hooks.useGetState(currenciesResult);

  const isLoading = hooks.useIsLoading({ state: currenciesServiceState.response });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  return { data, isLoading, isLoadingWithoutResponse };
};

export default useListCurrencies;
