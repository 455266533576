import { getWorkspacesQueryKeys, useGetWorkspacesQuery } from "models/workspaces";

import { EKeyName } from "../workspaces.types";

const useGetWorkspaces = (keyName?: EKeyName) => {
  const {
    data: workspacesResponse,
    isFetching: workspacesLoading,
    refetch: refreshWorkspaces,
  } = useGetWorkspacesQuery(keyName ? { Key: keyName } : undefined, {
    loaderKey: getWorkspacesQueryKeys.base,
  });

  return { workspacesResponse, workspacesLoading, refreshWorkspaces };
};

export default useGetWorkspaces;
