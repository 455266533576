// EVA.Core.PaymentStatuses
export enum EPaymentStatuses {
  New = 0,
  Pending = 1,
  Partial = 2,
  Confirmed = 10,
  Failed = -1,
}

export const PaymentStatuses = {
  Enum: EPaymentStatuses,
  encode: (paymentStatus: EPaymentStatuses) => paymentStatus as unknown as EVA.Core.PaymentStatuses,
  decode: (paymentStatus: EVA.Core.PaymentStatuses) => paymentStatus as unknown as EPaymentStatuses,
};
