import { editor, IRange } from "monaco-editor";

export function formatTables(model: editor.ITextModel) {
  const tables = model.findMatches("table", true, false, true, null, true);
  return tables.map((tableEntry) => {
    const tableRows: string[] = [];

    let idx = 1;

    const lines: IRange[] = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const tableRow = model.findNextMatch(
        "|",
        { lineNumber: tableEntry.range.startLineNumber + idx, column: 1 },
        false,
        true,
        null,
        true,
      );

      // function to check if the startingLine is not already added
      const lineIsNotAlreadyAdded = lines.every((line) => {
        return line.startLineNumber !== tableRow?.range.startLineNumber;
      });

      // function to check in the next line has a pipe
      const nextLineHasPipe = model
        .getLineContent((tableRow?.range.startLineNumber ?? 0) + 1)
        .includes("|");

      if (tableRow && lineIsNotAlreadyAdded) {
        tableRows.push(model.getLineContent(tableRow.range.startLineNumber));
        lines.push(tableRow.range);
        if (!nextLineHasPipe) {
          break;
        }
        idx++;
      } else {
        break;
      }
    }

    const joinTableRows = tableRows.join("\n");

    const formattedTable = formatTable(joinTableRows);

    const getRange = (): IRange => ({
      startColumn: 1,
      startLineNumber: lines[0].startLineNumber,
      endColumn: lines[lines.length - 1].endColumn,
      endLineNumber: lines[lines.length - 1].endLineNumber + 1,
    });

    return {
      range: getRange(),
      text: formattedTable,
    };
  });
}

export function formatTable(table: string): string {
  const rows = table.split("\n");
  const columnWidths = new Array(rows[0].split("|").length - 1).fill(0);
  for (const row of rows) {
    const cells = row.split("|").map((cell) => cell.trim());
    for (let i = 1; i < cells.length - 1; i++) {
      // start loop with i = 1
      columnWidths[i - 1] = Math.max(columnWidths[i - 1], cells[i].length);
    }
  }
  let formattedTable = "";
  for (const row of rows) {
    const cells = row.split("|").map((cell) => cell.trim());
    for (let i = 1; i < cells.length - 1; i++) {
      formattedTable += `| ${cells[i].padEnd(columnWidths[i - 1])} `;
    }
    formattedTable += "|\n";
  }
  return formattedTable;
}
