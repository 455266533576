import { useOrganizationUnitSelectorContext } from "../organization-unit-selector-provider";

const useOrganizationUnitStructure = () => {
  const { organizationUnits, flattenedOrganizationUnits } = useOrganizationUnitSelectorContext();

  return {
    organizationUnits,
    flattenedOrganizationUnits,
  };
};

export default useOrganizationUnitStructure;
