import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: getUnitOfMeasuresLoaderQuery,
  serviceQueryKeys: getUnitOfMeasuresQueryKeys,
  useServiceQueryHook: useGetUnitOfMeasuresQuery,
  useServiceQueryWithRequest: useGetUnitOfMeasuresQueryWithRequest,
} = createServiceQuery(CoreManagement.GetUnitOfMeasures, true);

export const {
  serviceLoaderQuery: listProductUnitOfMeasuresLoaderQuery,
  serviceQueryKeys: listProductUnitOfMeasuresQueryKeys,
  useServiceQueryHook: useListProductUnitOfMeasuresQuery,
  useServiceQueryWithRequest: useListProductUnitOfMeasuresQueryWithRequest,
} = createServiceQuery(CoreManagement.ListProductUnitOfMeasures, true);

export const {
  serviceLoaderQuery: listProductBarcodesLoaderQuery,
  serviceQueryKeys: listProductBarcodesQueryKeys,
} = createServiceQuery(CoreManagement.ListProductBarcodes);
