import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

export const DateIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <title>Icon / Calendar</title>
    <g id="Icon-/-Calendar" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon id="Shape" points="0 0 24 0 24 24 0 24" />
      <g
        id="calendar"
        transform="translate(3.000000, 2.000000)"
        fill="#000000"
        fillOpacity="0.6"
        fillRule="nonzero"
      >
        <path
          // eslint-disable-next-line max-len
          d="M16,18 L2,18 L2,7 L16,7 L16,18 Z M13,0 L13,2 L5,2 L5,0 L3,0 L3,2 L2,2 C0.89,2 0,2.89 0,4 L0,18 C0,19.1045695 0.8954305,20 2,20 L16,20 C17.1045695,20 18,19.1045695 18,18 L18,4 C18,2.89 17.1,2 16,2 L15,2 L15,0 L13,0 Z M14,11 L9,11 L9,16 L14,16 L14,11 Z"
          id="Shape"
        />
      </g>
    </g>
  </SvgIcon>
);
