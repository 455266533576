import { Column } from "react-table";

import { InputProps } from "components/suite-ui/input";

export const DEFAULT_EXPANDABLE_TEXT_MAX_LENGTH = 75;

export interface EditableCellInputProps {
  editable?: boolean;
  editInputProps?: InputProps;
}

export type ColumnProps<T extends object> = Partial<Column<T>> & Partial<EditableCellInputProps>;
