import { useMemo } from "react";
import { useIntl } from "react-intl";

import * as yup from "yup";

import { EEvaDiscountProductSetType } from "features/discount-edit/edit-discount.types";

const useProductSetValidation = (
  productSetType: EEvaDiscountProductSetType,
  amountOfProductsOption: boolean,
  requiredAmountOption: boolean,
  defaultProductOption: boolean,
  isDiscountableProductSetOption: boolean,
  requiredAmountSpentOption: boolean,
) => {
  const intl = useIntl();

  const validationSchema = useMemo(() => {
    const validationShape: any = {
      setName: yup.string().required(
        intl.formatMessage({
          id: "edit-discount.product-set.from.setname.required",
          defaultMessage: "Product set name is required.",
        }),
      ),
    };

    // type-based validation

    if (productSetType === EEvaDiscountProductSetType.PersonalizedPromotion) {
      validationShape.personalizedPromotionID = yup.number().required(
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required.",
        }),
      );
    }

    if (productSetType === EEvaDiscountProductSetType.ProductSearch) {
      validationShape.productSearchID = yup.number().required(
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required.",
        }),
      );
    }

    if (productSetType === EEvaDiscountProductSetType.ProductRequirement) {
      validationShape.productRequirements = yup
        .array(
          yup.object({
            ProductRequirementID: yup.number().required(
              intl.formatMessage({
                id: "validation.required",
                defaultMessage: "This field is required.",
              }),
            ),
            ProductID: yup.number().required(
              intl.formatMessage({
                id: "validation.required",
                defaultMessage: "This field is required.",
              }),
            ),
            Values: yup.array(yup.mixed()).required(
              intl.formatMessage({
                id: "validation.required",
                defaultMessage: "This field is required.",
              }),
            ),
          }),
        )
        .min(1)
        .required();
    }

    // option-based validation

    if (amountOfProductsOption) {
      if (defaultProductOption) {
        validationShape.requiredQuantityOfProducts = yup.number().when("isDiscountableProductSet", {
          is: false,
          then: yup
            .number()
            .min(
              1,
              intl.formatMessage({
                id: "edit-discount.product-set.from.requiredamountofproducts.required",
                defaultMessage: "At least 1 product is required.",
              }),
            )
            .required(
              intl.formatMessage({
                id: "edit-discount.product-set.from.requiredamountofproducts.required",
                defaultMessage: "At least 1 product is required.",
              }),
            ),
          otherwise: yup.number().min(
            0,
            intl.formatMessage({
              id: "edit-discount.product-set.from.requiredamountofproducts.positive",
              defaultMessage: "If set, the required amount of products must be greater than 0.",
            }),
          ),
        });
      } else if (!requiredAmountSpentOption) {
        validationShape.requiredQuantityOfProducts = yup
          .number()
          .min(
            1,
            intl.formatMessage({
              id: "edit-discount.product-set.from.requiredamountofproducts.required",
              defaultMessage: "At least 1 product is required.",
            }),
          )
          .required(
            intl.formatMessage({
              id: "edit-discount.product-set.from.requiredamountofproducts.required",
              defaultMessage: "At least 1 product is required.",
            }),
          );
      }
    }

    if (requiredAmountSpentOption) {
      validationShape.requiredQuantityOfProducts = yup
        .number()
        .when(
          "requiredAmountSpentOnProducts",
          (requiredAmountSpentOnProducts: number | undefined, schema: any) => {
            if (!requiredAmountSpentOnProducts) {
              return schema.required(
                intl.formatMessage({
                  id: "validation.required",
                  defaultMessage: "This field is required.",
                }),
              );
            }

            return schema.nullable();
          },
        );

      validationShape.requiredAmountSpentOnProducts = yup
        .number()
        .when(
          "requiredQuantityOfProducts",
          (requiredQuantityOfProducts: number | undefined, schema: any) => {
            if (!requiredQuantityOfProducts) {
              return schema.required(
                intl.formatMessage({
                  id: "validation.required",
                  defaultMessage: "This field is required.",
                }),
              );
            }

            return schema.nullable();
          },
        );
    }

    if (isDiscountableProductSetOption) {
      if (requiredAmountOption) {
        validationShape.discountableQuantityOfProducts = yup
          .number()
          .when("isDiscountableProductSet", {
            is: true,
            then: yup
              .number()
              .min(1)
              .required(
                intl.formatMessage({
                  id: "edit-discount.product-set.from.discountableamountofproducts.required",
                  defaultMessage: "At least 1 product is required.",
                }),
              ),
            otherwise: yup.number(),
          });
      }
    }

    return yup
      .object()
      .shape(validationShape, [["requiredAmountSpentOnProducts", "requiredQuantityOfProducts"]]);
  }, [
    intl,
    productSetType,
    amountOfProductsOption,
    requiredAmountSpentOption,
    isDiscountableProductSetOption,
    defaultProductOption,
    requiredAmountOption,
  ]);

  return validationSchema;
};

export default useProductSetValidation;
