import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "util/query";

export const {
  serviceLoaderQuery: listShippingCostsLoaderQuery,
  serviceQueryKeys: listShippingCostsQueryKeys,
  useServiceQueryHook: useListShippingCostsQuery,
} = createServiceQuery(CoreManagement.ListShippingCosts);

export const {
  serviceLoaderQuery: getShippingCostByIDLoaderQuery,
  serviceQueryKeys: getShippingCostByIDQueryKeys,
  useServiceQueryHook: useGetShippingCostByIDQuery,
} = createServiceQuery(CoreManagement.GetShippingCostByID);
