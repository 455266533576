import type { ReactNode } from "react";

import type { Theme } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider as MaterialThemeProvider } from "@material-ui/core/styles";

import "react-dates/initialize";

import defaultTheme from "./eva-theme";

/**
 * Component which needs to wrap the EVA Suite app.
 * You can pass in a custom Material UI theme (https://material-ui.com/customization/theming/)
 * or leave the theme prop blank and use the default theme of EVA Suite UI
 *
 * @interface ThemeProviderProps
 */
export interface ThemeProviderProps {
  theme?: Theme;
}
const ThemeProvider = ({ children, theme }: ThemeProviderProps & { children: ReactNode }) => (
  <StylesProvider injectFirst>
    <MaterialThemeProvider theme={theme ?? defaultTheme}>{children}</MaterialThemeProvider>
  </StylesProvider>
);

export default ThemeProvider;
