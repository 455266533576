import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from "react";
import { To } from "react-router-dom";

interface IPromptRoutingContext {
  when: boolean;
  setWhen: (when: boolean) => void;
  confirmRoute: To | number | undefined;
  setConfirmRoute: Dispatch<SetStateAction<To | number | undefined>>;
}

const PromptRoutingContext = createContext<IPromptRoutingContext>(
  undefined as unknown as IPromptRoutingContext,
);

export const PromptRoutingProvider = ({ children }: { children: ReactNode }) => {
  const [when, setWhen] = useState<boolean>(false);
  const [confirmRoute, setConfirmRoute] = useState<To | number | undefined>(undefined);

  const value = useMemo<IPromptRoutingContext>(
    () => ({
      when,
      setWhen,
      confirmRoute,
      setConfirmRoute,
    }),
    [when, confirmRoute],
  );

  return <PromptRoutingContext.Provider value={value}>{children}</PromptRoutingContext.Provider>;
};

export const usePrompt = () => {
  const context = useContext(PromptRoutingContext);
  if (context === undefined) {
    throw new Error("usePrompt must be used within a PromptRoutingProvider");
  }
  return context;
};
