import { forwardRef } from "react";

import type { TooltipProps as MuiToolTipProps } from "@material-ui/core/Tooltip";
import MuiToolTip from "@material-ui/core/Tooltip";

export type ToolTipProps = Pick<
  MuiToolTipProps,
  | "title"
  | "placement"
  | "open"
  | "onOpen"
  | "onClose"
  | "className"
  | "style"
  | "onFocus"
  | "onBlur"
  | "onClick"
  | "ref"
  | "innerRef"
  | "id"
  | "children"
>;

const ToolTip = forwardRef<JSX.Element, ToolTipProps>((props, ref) => (
  <MuiToolTip ref={ref} {...props} arrow />
));

ToolTip.displayName = "ToolTip";

export default ToolTip;
