import { useMemo } from "react";

import { hooks, state } from "@springtree/eva-sdk-react-recoil";

export const useAppSetting = (key: string, fallbackValue?: any) => {
  const applicationConfiguration = hooks.useGetState(state.core.applicationConfigurationState);
  const isLoading = hooks.useIsLoading({
    state: state.core.applicationConfigurationState,
  });
  const isLoadingWithoutPreviousResponse = useMemo(
    () => isLoading && !applicationConfiguration,
    [applicationConfiguration, isLoading],
  );

  const appSetting = useMemo(() => {
    const config = applicationConfiguration?.Configuration;
    if (config) {
      if (Object.keys(config).includes(key)) {
        return config[key];
      }
    }
    return fallbackValue ?? undefined;
  }, [applicationConfiguration?.Configuration, fallbackValue, key]);

  return { appSetting, isLoading, isLoadingWithoutPreviousResponse };
};
