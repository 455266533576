import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";

/**
 * Builds service state for the ListOrganizationUnitSets service.
 * It is used in the organization sets chapter overview.
 */

export const organizationUnitSetDetailsState = builders.buildServiceFamilyState({
  service: CoreManagement.GetOrganizationUnitSetDetails,
  key: "organizationUnitSetsDetails",
  requireEmployee: true,
  allowEmptyRequest: false,
});
