import { z } from "zod";

import { DEFAULT_TABLE_PAGINATION_OPTIONS } from "components/suite-ui/table/table-types";
import { DEFAULT_LIMIT } from "util/base-values";
import { getClosestFromList } from "util/numbers.util";

export const limitFromListSchema = z
  .number()
  .int()
  .optional()
  .transform((limit) =>
    limit ? getClosestFromList(limit, DEFAULT_TABLE_PAGINATION_OPTIONS) : limit,
  );

export const limitFromListSchemaWithFallback = (fallbackValue = DEFAULT_LIMIT) =>
  z
    .number()
    .int()
    .optional()
    .transform((limit) => {
      if (!limit) {
        return fallbackValue;
      }
      return getClosestFromList(limit, DEFAULT_TABLE_PAGINATION_OPTIONS);
    });
