import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  FieldGroup,
} from "@new-black/lyra";

import { EVAFormik } from "../eva-formik";

import { extractPropertyValues } from "./add-bulk-products-modal.helpers";
import {
  IAddBulkProductsModalFormValues,
  IAddBulkProductsModalValues,
} from "./add-bulk-products-modal.types";
import ProductPropertyAutocomplete from "./product-property-autocomplete";
import ProductPropertyValuesTextarea from "./product-property-values-textarea";
import useAddBulkProductsModalForm from "./use-add-bulk-products-modal-form";

export interface IAddBulkProductsModal {
  isOpen?: boolean;
  /** Callback fired when pressing the cancel button / clicking outside the modal. */
  closeModal: () => void;
  /** Callback fired when pressing the save button. */
  onSave: (productProperty: string, values: string[]) => void;
  /** Loading state for the `onSave` callback. */
  isLoading?: boolean;
  /** Optional values to initialize the form with. */
  initialValues?: IAddBulkProductsModalValues;
  "aria-label"?: string;
  /** Defaults to `true`. */
  closeOnSave?: boolean;
}

/**
 * Component used to allow adding a product filter with a large number of comma-separated values inside a free input textarea.
 */
export function AddBulkProductsModal({
  closeModal,
  closeOnSave = true,
  initialValues: controlledInitialValues,
  isLoading, // fallback to true for backwards compatibility
  isOpen = true,
  onSave,
  ...props
}: IAddBulkProductsModal) {
  const intl = useIntl();
  const { initialValues, validationSchema } = useAddBulkProductsModalForm(controlledInitialValues);

  const onSubmit = useCallback(
    (values: IAddBulkProductsModalFormValues, close: () => void) => {
      onSave(values.productProperty!, extractPropertyValues(values.propertyValues!));

      if (closeOnSave) {
        close();
      }
    },
    [closeOnSave, onSave],
  );

  return (
    <Dialog
      maxWidth="lg"
      isOpen={isOpen}
      aria-label={
        props["aria-label"] ||
        intl.formatMessage({
          id: "add-bulk-products-modal.title",
          defaultMessage: "Add products in bulk",
        })
      }
      onOpenChange={(newIsOpen) => {
        if (!newIsOpen) {
          closeModal();
        }
      }}
    >
      {({ close }) => (
        <DialogCard>
          <CardHeader
            title={intl.formatMessage({
              id: "add-bulk-products-modal.title",
              defaultMessage: "Add products in bulk",
            })}
          />
          <EVAFormik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values) => onSubmit(values, close)}
          >
            {({ submitForm }) => (
              <>
                <CardContent>
                  <FieldGroup>
                    <ProductPropertyAutocomplete />
                    <ProductPropertyValuesTextarea />
                  </FieldGroup>
                </CardContent>
                <CardFooter>
                  <ButtonGroup>
                    <Button onPress={closeModal} isDisabled={isLoading} variant="secondary">
                      <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                    </Button>
                    <Button onPress={submitForm} isLoading={isLoading} variant="primary">
                      <FormattedMessage id="generic.label.save" defaultMessage="Save" />
                    </Button>
                  </ButtonGroup>
                </CardFooter>
              </>
            )}
          </EVAFormik>
        </DialogCard>
      )}
    </Dialog>
  );
}
