import { useCallback } from "react";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useMutation } from "@tanstack/react-query";

import { toast } from "components/suite-ui/toast";

import { mutateCompleteMultipleShipFromStoreTasks } from "./mutate-complete-multiple-shipf-from-store-tasks";

const useCompleteTasks = (
  deselectRows: () => void,
  refetchTasks: () => void,
  completeMultipleAtOnce?: boolean,
) => {
  const intl = useIntl();
  const completeUserTask = hooks.useCallService({
    service: Core.CompleteShipFromStoreTask,
  });

  const { mutateAsync: completeMultipleUserTasksMutation } = useMutation(
    mutateCompleteMultipleShipFromStoreTasks(),
  );

  const completeTasks = useCallback(
    async (userTaskIds?: number[]) => {
      if (userTaskIds?.length) {
        const toastId = toast.loading(
          intl.formatMessage({
            id: "complete-tasks.loading",
            defaultMessage: "Completing task(s)...",
          }),
        );

        const results = await Promise.all(
          userTaskIds.map((id) => completeUserTask({ UserTaskID: id })),
        );

        // at least one task completed, refetch the list
        if (results.some((result) => result && !result.Error)) {
          refetchTasks();
          deselectRows();
        }

        if (results.some((result) => !result || result.Error)) {
          // if at least one task failed, show error toaster
          toast.error(
            intl.formatMessage({
              id: "generic.message.something-went-wrong",
              defaultMessage: "Something went wrong!",
            }),
            { id: toastId },
          );
        } else {
          // otherwise show success message
          toast.success(
            intl.formatMessage({
              id: "complete-tasks.success",
              defaultMessage: "Successfully completed tasks.",
            }),
            { id: toastId },
          );
        }
      }
    },
    [completeUserTask, deselectRows, intl, refetchTasks],
  );

  const completeMultipleTasksAtOnce = useCallback(
    async (userTaskIds?: number[]) => {
      if (userTaskIds?.length) {
        const result = await completeMultipleUserTasksMutation({ UserTaskIDs: userTaskIds });

        if (result?.success) {
          refetchTasks();
          deselectRows();
        }
      }
    },
    [completeMultipleUserTasksMutation, deselectRows, refetchTasks],
  );

  return completeMultipleAtOnce ? completeMultipleTasksAtOnce : completeTasks;
};

export default useCompleteTasks;
