import { useCallback } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

export const useCopyImageToClipboard = () => {
  const intl = useIntl();

  const onCopyToClipboard = useCallback(
    async (blobURL: string) => {
      try {
        const img = new Image();
        img.crossOrigin = "anonymous";
        const c = document.createElement("canvas");
        const ctx = c.getContext("2d");

        img.onload = function () {
          c.width = img.naturalWidth;
          c.height = img.naturalHeight;
          ctx?.drawImage(img, 0, 0);
          c.toBlob(async (imgBlob) => {
            if (imgBlob) {
              await navigator.clipboard.write([new ClipboardItem({ "image/png": imgBlob })]);
              toast.success(
                intl.formatMessage({
                  id: "generic.label.succesfully-copied-to-clipboard",
                  defaultMessage: "Succesfully copied to clipboard",
                }),
              );
            }
          }, "image/png");
        };

        img.src = blobURL;
      } catch {
        toast.error(
          intl.formatMessage({
            id: "generic.message.something-went-wrong",
            defaultMessage: "Something went wrong",
          }),
        );
      }
    },
    [intl],
  );

  return onCopyToClipboard;
};
