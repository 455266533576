import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

export const blobsForOrderServiceState = builders.buildServiceFamilyState({
  allowEmptyRequest: false,
  defaultRequest: undefined,
  requireLoggedIn: true,
  service: Core.ListBlobsForOrder,
  key: "Core.BlobsForOrder",
});
