import { Core } from "@springtree/eva-services-core";

import { listStationsForOrganizationUnitQuery } from "models/stations";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "util/base-values";
import { intlAccessor } from "util/intl-accessor";
import { SearchListFieldGenerator } from "util/lyra-search-list-field-generator";

export const generateStationsForOULyraSearchListField = (ouID: number, deviceType?: number) =>
  SearchListFieldGenerator<
    Core.ListStationsForOrganizationUnit,
    {
      ID: number;
      Name?: string;
    },
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listStationsForOrganizationUnitQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
          OrganizationUnitID: ouID,
          DeviceTypeID: deviceType,
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          OrganizationUnitID: ouID,
          DeviceTypeID: deviceType,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue,
            },
          },
        }),
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((station) => ({ ID: station.ID, Name: station.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.station",
      defaultMessage: "Station",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = itemsFromList?.find((item) => item.ID === id);
      return {
        data: item,
      };
    },
  });
