import { useEffect } from "react";
import { useIntl } from "react-intl";

import type Monaco from "monaco-editor";

const useDisableFirstLineEditing = (
  editor: Monaco.editor.IStandaloneCodeEditor | null,
  disableFirstLineEditing?: boolean,
  source?: string,
) => {
  const intl = useIntl();

  useEffect(() => {
    if (!disableFirstLineEditing || !editor) return;

    const applyReadOnly = (isReadOnly: boolean) => {
      editor.updateOptions({
        readOnly: isReadOnly,
        // update readonly message shown by monaco when user attempts input
        readOnlyMessage: isReadOnly
          ? {
              value: intl.formatMessage({
                id: "generic.label.cannot-edit-locked-lines",
                defaultMessage: "Cannot edit locked lines.",
              }),
            }
          : undefined,
        // remove the current line highlight because it adds borders that look weird with the locked line design
        renderLineHighlight: isReadOnly ? "none" : "all",
      });
    };

    const firstLineLength = source?.split("\n")?.[0]?.length ?? 0;

    const decorations = editor.createDecorationsCollection([
      {
        range: {
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: 1,
          endColumn: 1,
        },
        options: {
          isWholeLine: true,
          inlineClassName: "locked-line-text",
        },
      },
      {
        range: {
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: 1,
          endColumn: firstLineLength + 1,
        },
        options: {
          className: "locked-line",
        },
      },
    ]);

    const disposables = [
      // whenever the selection changes, check if the first line is selected and apply readonly
      editor.onDidChangeCursorSelection(() => {
        const isFirstLineSelected = editor.getSelections()?.some((selection) =>
          selection.intersectRanges({
            startLineNumber: 1,
            startColumn: 0,
            endLineNumber: 1,
            endColumn: firstLineLength + 1,
          }),
        );
        applyReadOnly(!!isFirstLineSelected);
      }),

      // make sure users can input a new line at the end of the first line
      // this includes cases where the user selects everything up to and including the end of the first line
      editor.onKeyDown((e) => {
        const isCursorOnFirstLineLastColumn =
          editor.getPosition()?.lineNumber === 1 &&
          (editor.getPosition()?.column ?? 0) === firstLineLength + 1;

        const isPartOfFirstLineSelected = editor.getSelections()?.some((selection) =>
          selection.intersectRanges({
            startLineNumber: 1,
            startColumn: 0,
            endLineNumber: 1,
            endColumn: firstLineLength,
          }),
        );

        // prevent input if part of the code on the first line is selected
        // or we have the cursor at the end of the first line and user is not adding a new line
        const disallowInput =
          isPartOfFirstLineSelected ||
          (isCursorOnFirstLineLastColumn && (e.code !== "Enter" || e.metaKey || e.ctrlKey));

        applyReadOnly(disallowInput);
      }),
    ];

    return () => {
      decorations.clear();
      disposables.forEach((d) => d.dispose());
    };
  }, [disableFirstLineEditing, editor, intl, source]);
};

export default useDisableFirstLineEditing;
