import { builders, helpers } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selectorFamily } from "recoil";

const { createGetterSetterSelectorFamily } = helpers.createSelectors;

const SERVICE_KEY = "CustomFields:Composite:CoreManagement.ListCustomFieldOptions";

const defaultRequest: EVA.Core.Management.ListCustomFieldOptions = {
  PageToken: undefined,
  InitialPageConfig: {
    Limit: 25,
    Filter: { CustomFieldID: undefined },
  },
};

const customFieldOptionsServiceState = builders.buildServiceFamilyState({
  key: SERVICE_KEY,
  requireLoggedIn: true,
  allowEmptyRequest: false,
  service: CoreManagement.ListCustomFieldOptions,
  beforeRequest: ({ parameter, req }) => {
    if (
      !parameter ||
      typeof parameter !== "number" ||
      req?.InitialPageConfig?.Filter?.CustomFieldID === parameter
    ) {
      return req;
    }
    return {
      ...defaultRequest,
      ...req,
      InitialPageConfig: {
        ...defaultRequest.InitialPageConfig,
        ...req?.InitialPageConfig,
        Filter: {
          ...defaultRequest.InitialPageConfig?.Filter,
          ...req?.InitialPageConfig?.Filter,
          CustomFieldID: parameter,
        },
      },
    };
  },
});

const customFieldOptionsResponseSelector = selectorFamily({
  key: `${SERVICE_KEY}:Response:Results`,
  get:
    (param: number | undefined) =>
    ({ get }) =>
      get(customFieldOptionsServiceState.response(param))?.Results,
});

const customFieldOptionsNextPageTokenSelector = selectorFamily({
  key: `${SERVICE_KEY}:Response:NextPageToken`,
  get:
    (param: number | undefined) =>
    ({ get }) =>
      get(customFieldOptionsServiceState.response(param))?.NextPageToken,
});

const customFieldOptionsPreviousPageTokenSelector = selectorFamily({
  key: `${SERVICE_KEY}:Response:PreviousPageToken`,
  get:
    (param: number | undefined) =>
    ({ get }) =>
      get(customFieldOptionsServiceState.response(param))?.PreviousPageToken,
});

const customFieldOptionsPageTokenSelector = createGetterSetterSelectorFamily({
  defaultValue: defaultRequest.PageToken,
  key: `${SERVICE_KEY}:Request:PageToken`,
  state: customFieldOptionsServiceState.request,
  getData: (data) => data?.PageToken ?? defaultRequest.PageToken,
  setData: (data, newValue) => ({ ...data, PageToken: newValue }),
});

const customFieldOptionsInitialPageConfigSelector = createGetterSetterSelectorFamily({
  defaultValue: defaultRequest.InitialPageConfig,
  key: `${SERVICE_KEY}:Request:InitialPageConfig`,
  state: customFieldOptionsServiceState.request,
  getData: (data) => data?.InitialPageConfig ?? defaultRequest.InitialPageConfig,
  setData: (data, newValue) => ({ ...data, InitialPageConfig: newValue }),
});

const customFieldOptionsLimitSelector = createGetterSetterSelectorFamily({
  state: customFieldOptionsInitialPageConfigSelector,
  key: `${SERVICE_KEY}:Request:InitialPageConfig:Limit`,
  defaultValue: defaultRequest.InitialPageConfig?.Limit!,
  getData: (data) => data?.Limit ?? defaultRequest.InitialPageConfig?.Limit!,
  setData: (data, newValue) => ({ ...data, Limit: newValue }),
});

export const customFieldOptionsService = {
  defaultRequest,
  limit: customFieldOptionsLimitSelector,
  serviceState: customFieldOptionsServiceState,
  response: customFieldOptionsResponseSelector,
  currentPageToken: customFieldOptionsPageTokenSelector,
  nextPageToken: customFieldOptionsNextPageTokenSelector,
  previousPageToken: customFieldOptionsPreviousPageTokenSelector,
  initialPageConfig: customFieldOptionsInitialPageConfigSelector,
};
