import { useMemo } from "react";

import enumToArray from "util/enum-to-array";
import { ProductRelationDirection } from "types/eva-core";

const useProductRelationDirections = () => {
  const productRelationDirections = useMemo(() => enumToArray(ProductRelationDirection), []);
  return productRelationDirections;
};

export default useProductRelationDirections;
