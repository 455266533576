import { CaseInsensitiveString } from "./string-utils.types";

export const APPLE_COLORS = [
  /** Purple */
  "#af52de",
  /** Indigo */
  "#5856d6",
  /** Cyan */
  "#32ade6",
  /** Teal */
  "#30b0c7",
  /** Mint */
  "#00c7be",
  /** Yellow */
  "#ffcc00",
  /** Brown */
  "#a2845e",
  /** Pink */
  "#ff2d55",
] as const;

export type AppleColor = CaseInsensitiveString<typeof APPLE_COLORS[number]>;

export function getAppleColorClassName(color: AppleColor | string) {
  const colorMapper: Record<typeof APPLE_COLORS[number], string> = {
    /** Purple */
    "#af52de": "bg-[#af52de]", // This color is not defined in Lyra's theme
    /** Indigo */
    "#5856d6": "bg-indication-indigo",
    /** Cyan */
    "#32ade6": "bg-indication-cyan",
    /** Teal */
    "#30b0c7": "bg-indication-teal",
    /** Mint */
    "#00c7be": "bg-indication-mint",
    /** Yellow */
    "#ffcc00": "bg-indication-yellow",
    /** Brown */
    "#a2845e": "bg-indication-brown",
    /** Pink */
    "#ff2d55": "bg-[#ff2d55]", // This color is not defined in Lyra's theme
  };
  const colorValue = color.toLowerCase();
  if (colorValue in colorMapper) {
    return colorMapper[colorValue as keyof typeof colorMapper];
  }
  return undefined;
}
