import { ReactNode, useMemo } from "react";

import { Separator } from "@new-black/lyra";

import { v4 as uuid } from "uuid";

import Grid from "../grid";

import CompleteItemIcon from "./complete-item-icon";
import IncompleteItemIcon from "./incomplete-item-icon";

export interface ITimelineItem {
  complete?: boolean;
  customIcon?: ReactNode;
  renderComponent: ReactNode;
  oppositeContent?: ReactNode;
}

export interface ITimelineProps {
  /** Show or hide vertical separators between items */
  hideSeparators?: boolean;
  items: ITimelineItem[];
  align?: "left" | "right";
}

const Timeline = ({ align = "left", hideSeparators = false, items }: ITimelineProps) => {
  const hasOppositeContent = useMemo(() => items.some((item) => !!item.oppositeContent), [items]);
  return (
    <Grid container direction="column" spacing={2} alignItems="center">
      {items.map((item, index) => {
        const icon = (
          <Grid
            className="basis-[3%] !pb-0"
            item
            xs={3}
            container
            direction="column"
            alignItems="center"
          >
            <Grid item>
              {item.customIcon ?? (item.complete ? <CompleteItemIcon /> : <IncompleteItemIcon />)}
            </Grid>
            {!hideSeparators && index !== items.length - 1 ? (
              <Grid item container alignItems="center" justifyContent="center" xs>
                <Separator className="h-full min-h-[10px]" orientation="vertical" />
              </Grid>
            ) : null}
          </Grid>
        );
        return (
          <Grid
            item
            container
            spacing={2}
            wrap="nowrap"
            direction="row"
            alignItems="stretch"
            key={`item-${uuid()}`}
            justifyContent={align === "left" ? "flex-start" : "flex-end"}
          >
            {hasOppositeContent && align === "left" ? (
              <Grid item xs>
                {item.oppositeContent}
              </Grid>
            ) : null}
            {align === "left" ? icon : null}
            <Grid item xs>
              {item.renderComponent}
            </Grid>
            {align === "right" ? icon : null}
            {hasOppositeContent && align === "right" ? (
              <Grid item xs>
                {item.oppositeContent}
              </Grid>
            ) : null}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default Timeline;
