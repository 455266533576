import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { discountCouponHandlersResultSelector } from ".";

const useDiscountCouponHandlers = () => {
  const discountCouponHandlers = hooks.useGetState(discountCouponHandlersResultSelector);

  const isLoading = hooks.useIsLoading({ state: discountCouponHandlersResultSelector });
  const isLoadingWithoutResponse = useMemo(
    () => isLoading && !discountCouponHandlers,
    [discountCouponHandlers, isLoading],
  );

  return {
    discountCouponHandlers,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export default useDiscountCouponHandlers;
