import { useIntl } from "react-intl";

import { CardContent, CardHeader } from "@new-black/lyra";
import { FormikProps, useFormikContext } from "formik";

import { IUpdateEvaProductSetOptions } from "./eva-product-set.types";
import { EVAProductSetProductRequirementFormFields } from "./eva-product-set-product-requirement-form-fields";

export const EvaProductSetProductRequirement = ({
  onFieldBlur,
  passive,
}: {
  passive?: boolean;
  onFieldBlur: (form: FormikProps<any>) => void;
}) => {
  const intl = useIntl();

  const { values } = useFormikContext<IUpdateEvaProductSetOptions>();

  return (
    <>
      <CardHeader
        title={intl.formatMessage({
          id: "edit-discount.condition.product-requirement.title",
          defaultMessage: "Specify product requirement",
        })}
      />

      <CardContent>
        {values.productRequirements?.map((productRequirement, index) => (
          <EVAProductSetProductRequirementFormFields
            key={index}
            passive={passive}
            onFieldBlur={onFieldBlur}
            uuid={productRequirement.uuid}
          />
        ))}
      </CardContent>
    </>
  );
};
